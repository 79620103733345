import React, { useState } from 'react';
import { Form } from '@unform/web';
import Input from 'components/Input';
import Select, { SelectPlusButton } from 'components/Select';
import TextArea from 'components/TextArea';
import { BsX } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';

export default function AdicionarAgendamentoExemplo() {
    const [pacienteOptSelecionado, setPacienteOptSelecionado] = useState(null);
    const [terapiasPacienteOptSelecionado, setTerapiasPacienteOptSelecionado] = useState(null);
    const [terapeutaOptSelecionado, setTerapeutaOptSelecionado] = useState(null);
    const [modalidadeOptSelecionado, setModalidadeOptSelecionado] = useState(null);
    const [tipoAtendimentoOptSelecionado, setTipoAtendimentoOptSelecionado] = useState(null);
    const [reservaOptSelecionado, setReservaOptSelecionado] = useState(null);

    const pacienteOptions = [
        { value: 1, label: 'Paciente 1' },
        { value: 2, label: 'Paciente 2' },
    ];

    const terapiasPacienteOptions = [
        { value: 1, label: 'Terapia 1' },
        { value: 2, label: 'Terapia 2' },
    ];

    const terapeutasOptions = [
        { value: 1, label: 'Terapeuta 1' },
        { value: 2, label: 'Terapeuta 2' },
    ];

    const modalidadesOptionsInterno = [
        { value: 1, label: 'Presencial' },
        { value: 2, label: 'Domiciliar' },
    ];

    const tiposAtendimentoOptionsInterno = [
        { value: 1, label: 'Consulta' },
        { value: 2, label: 'Retorno' },
    ];

    const reservaOptions = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ];

    return (
        <div className='div-modal pt-0 mx-auto' style={{ backgroundColor: 'white', width: '700px', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}>
            <nav className='navbar bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div className='w-200px'></div>
                    <h2 className='font-20px'>Adicionar Agendamento</h2>
                    <label className='btn-outline w-200px text-right'>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            <br></br>
            <Form>
                <div className='row'>
                    <div className='col-12 d-flex align-items-end flex-wrap'>
                        <Select
                            options={pacienteOptions}
                            label='Paciente:'
                            id='paciente_select'
                            containerClass='w-100per-i'
                            name='paciente'
                            value={pacienteOptSelecionado}
                            onChange={setPacienteOptSelecionado}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 d-flex align-items-end flex-wrap'>
                        <Select
                            containerClass='w-100per-i'
                            label='Terapia do Paciente:'
                            name='terapia_paciente'
                            options={terapiasPacienteOptions}
                            value={terapiasPacienteOptSelecionado}
                            onChange={setTerapiasPacienteOptSelecionado}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-sm-12 d-flex position-relative'>
                        <SelectPlusButton
                            label='Terapeuta:'
                            name='Terapeuta'
                            options={terapeutasOptions}
                            value={terapeutaOptSelecionado}
                            onChange={setTerapeutaOptSelecionado}
                            required={true}
                        />
                        <button
                            className='btn-icone position-absolute'
                            type='button'
                            style={{ right: '23.5%', top: '33.5px' }}
                            onClick={() => { setTerapeutaOptSelecionado(null); }}
                        >
                            <BsX size={22} />
                        </button>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Select
                            label='Modalidade:'
                            name='Modalidade'
                            options={modalidadesOptionsInterno}
                            value={modalidadeOptSelecionado}
                            onChange={setModalidadeOptSelecionado}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                        <Select
                            label='Tipo de Atendimento:'
                            name='tipoAtendimento'
                            options={tiposAtendimentoOptionsInterno}
                            value={tipoAtendimentoOptSelecionado}
                            onChange={setTipoAtendimentoOptSelecionado}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Select
                            label='Reserva:'
                            name='reserva'
                            options={reservaOptions}
                            value={reservaOptSelecionado}
                            onChange={setReservaOptSelecionado}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12'>
                        <Input
                            defaultValue='Segunda-feira'
                            readOnly={'readOnly'}
                            title='Dia:'
                            name='data'
                            required={true}
                        />
                    </div>
                    <div className='col-lg-4 col-sm-12'>
                        <Input
                            defaultValue='08:00'
                            type='time'
                            title='Hora inicial'
                            name='horaInicial'
                            required={true}
                        />
                    </div>
                    <div className='col-lg-4 col-sm-12'>
                        <Input
                            defaultValue='08:40'
                            type='time'
                            title='Hora final'
                            name='horaFinal'
                            required={true}
                        />
                    </div>
                </div>
                <TextArea
                    title='Comentário:'
                    name='comentario'
                    defaultValue=''
                />
                <div className='col-12 mt-20px text-center'>
                    <button className='btn-padrao' type='submit'>
                        Confirmar
                    </button>
                </div>
            </Form>
        </div>
    );
}