import styled from 'styled-components';

const Detalhamento = styled.div`
    position: absolute;
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
    border-bottom-left-radius: 20%;
    background-color: #fff;
    border: 0px solid #ccc;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15),
        /* Sombra nas laterais e inferior */
        0 8px 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    z-index: 1000; /* Garante que esteja acima de outros elementos */
    min-width: 250px;
    width: fit-content;
    text-align: left;
    left: -55px;
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    li {
        margin-bottom: 5px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const QuadradoStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#27DABE',
    height: '110px',
    width: '110px',
    borderRadius: '20%',
    wordWrap: 'break-word',
    textAlign: 'center',
    marginRight: 20,
    padding: 10
};

const CirculoStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#27DABE',
    height: '110px',
    width: '110px',
    borderRadius: '100%',
    wordWrap: 'break-word',
    textAlign: 'center',
    marginRight: 20,
    padding: 10
};

export { QuadradoStyle, CirculoStyle, Detalhamento };
