export const CustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '70vh',
        width: '60vw',
        backgroundColor: '#FFFFFF'
    },
};

export const ButtonAddStyle = {
    backgroundColor: '#7441BF', 
    color: '#FFFFFF', 
    border: 'none', 
    borderRadius: 8, 
    height: 34, 
    marginBottom: 20, 
    marginTop: 20, 
    paddingLeft: 18, 
    paddingRight: 18
};

export const FormStyle = {
    display: 'flex', 
    flexDirection: 'column', 
    borderWidth: 1, 
    borderTopColor: 'red' 
};