import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronUp } from 'react-icons/fa';

/**
 * Componente de SubMenu.
 * Detecta se o dispositivo é um iPhone e desativa a funcionalidade de colapsar se for.
 * 
 * @param {string} selectedItem - Item selecionado atualmente.
 * @param {Array} links - Links do submenu.
 * @param {boolean} defaultClose - Define se o submenu deve começar fechado.
 * @param {Function} onToggleMinimized - Função de callback para informar se o submenu está minimizado.
 * @returns {JSX.Element} O componente SubMenu.
 */
export default function SubMenu({ selectedItem, links, defaultClose = true, onToggleMinimized = () => { } }) {
    //const [isMinimized, setIsMinimized] = useState(defaultClose);
    const [isMinimized, setIsMinimized] = useState(false);
    const submenuRef = useRef(null);
    const isIPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    useEffect(() => {
        if (!isIPhone) {
            if (isMinimized) {
                submenuRef.current.style.maxHeight = '0';
                submenuRef.current.style.opacity = '0';
            } else {
                submenuRef.current.style.maxHeight = '50px';
                submenuRef.current.style.opacity = '1';
            }
        }
    }, [isMinimized, isIPhone]);

    /* useEffect(() => {
        if (!isIPhone) {
            handleToggle(defaultClose);
        }
    }, [defaultClose, isIPhone]); */

    const handleToggle = () => {
        if (!isIPhone) {
            const newState = !isMinimized;
            setIsMinimized(newState);
            if (onToggleMinimized) {
                onToggleMinimized(newState);
            }
        }
    };

    const selected = {
       
        marginRight: 20,
        marginTop: 10,
        color: '#7340BD',
        fontWeight: 'bold'
    };

    const unSelected = {
       
        marginRight: 20,
        marginTop: 10,
        color: '#000',
        cursor: 'pointer'
    };

    return (
        <div className="div-submenu-container">
            {/* {!isIPhone && (
                <button onClick={handleToggle} className="toggle-button hl-laranja mt-1">
                    <FaChevronUp style={{
                        transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s'
                    }} />
                </button>
            )} */}
            <div ref={submenuRef} className={`submenu`}>
                {links?.map((link, index) => {
                    let array = link.url.split('/');
                    let item = array[array.length - 1];
                    return (
                        <Link key={index} to={link.url}>
                            <label style={(selectedItem === item) ? selected : unSelected}>{link.label}</label>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}