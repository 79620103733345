import React, { useState, useRef, useEffect, useCallback } from "react";

/**
 * VirtualizedList é um componente React que renderiza uma lista virtualizada.
 * Ele renderiza apenas os itens visíveis para melhorar o desempenho com grandes conjuntos de dados.
 *
 * @param {Object} props - O objeto de propriedades.
 * @param {Array} props.items - O array de itens a serem exibidos na lista.
 * @param {Function} props.itemRenderer - Uma função que mapeia um item para um elemento de lista.
 * @param {number} [props.itemHeight=40] - A altura de cada item em pixels.
 * @param {number} [props.containerHeight=400] - A altura do contêiner em pixels.
 *
 * @returns {JSX.Element} O componente de lista virtualizada renderizado.
 */
const VirtualizedList = ({
    items,
    itemRenderer = () => { },
    itemHeight = 40,
    containerHeight = 400
}) => {
    // Estados para posição de rolagem e itens visíveis
    const [scrollTop, setScrollTop] = useState(0);
    const containerRef = useRef(null);

    const totalHeight = items.length * itemHeight;
    const visibleItemCount = Math.ceil(containerHeight / itemHeight) + 1;

    // Calcule o índice dos itens visíveis baseado na rolagem atual
    const startIndex = Math.max(0, Math.floor(scrollTop / itemHeight));
    const endIndex = Math.min(items.length - 1, startIndex + visibleItemCount);

    const handleScroll = useCallback(() => {
        setScrollTop(containerRef.current.scrollTop);
    }, []);

    // Adicione o event listener ao scroll
    useEffect(() => {
        const ref = containerRef.current;
        ref.addEventListener("scroll", handleScroll);
        return () => ref.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    return (
        <div ref={containerRef} style={{ position: "relative", overflowY: "auto", height: containerHeight, border: "1px solid #ccc" }}>
            {/* Div de preenchimento para garantir o scroll */}
            <div style={{ height: totalHeight, position: "relative" }}>
                {/* Renderizar apenas os itens visíveis */}
                <ul style={{ position: "absolute", top: startIndex * itemHeight, width: "100%" }}>
                    {items.slice(startIndex, endIndex + 1).map((item, index) =>
                        itemRenderer({
                            item,
                            key: startIndex + index,
                            styles: { height: itemHeight },
                        })
                    )}
                </ul>
            </div>
        </div>
    );
};

export default VirtualizedList;