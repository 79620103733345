import { Route } from 'react-router-dom';
import PerfilPaciente from './../pages/RecepcaoDashboard/Pacientes/Perfil';
import StatusDiario from './../pages/TerapeutaDashboard/StatusDiario';
import EvolucaoDiaria from './../pages/TerapeutaDashboard/Pendencias';
import PendenciasDiarias from './../pages/TerapeutaDashboard/Pendencias/PendenciasDiarias';
import PendenciasAtrasadas from './../pages/TerapeutaDashboard/Pendencias/PendenciasAtrasadas';
import TerapeutaPacientes from './../pages/TerapeutaDashboard/TerapeutaPacientes';
import TerapeutaProntuarios from './../pages/TerapeutaDashboard/TerapeutaPacientes/Prontuarios';
import Solicitacoes from './../pages/TerapeutaDashboard/Solicitacoes';
import TerapeutaAgenda from './../pages/TerapeutaDashboard/Agenda';
import TerapeutaRelatorios from './../pages/TerapeutaDashboard/Relatorios';
import HeaderPadrao from 'layouts/HeaderPadrao';
import MinhasSolicitacoes from './../pages/TerapeutaDashboard/Solicitacoes/MinhasSolicitacoes';
import TerapeutaDashboard from './../pages/TerapeutaDashboard/Dashboard';
import TerapeutaBloqueado from './../pages/TerapeutaDashboard/BloqueioAcesso';
import Pesquisar from './../pages/TerapeutaDashboard/TerapeutaPacientes/Pesquisar';
import AreaCompartilhadaTerapeuta from './../pages/TerapeutaDashboard/Agenda/AreasCompartilhadas';
import GeradorDeDocumento from 'pages/GeradorDeDocumento';

const routes = [
    { path: '/dashboard/terapeuta', element: <TerapeutaDashboard /> },
    { path: '/dashboard/terapeuta/acesso-bloqueado', element: <TerapeutaBloqueado /> },
    { path: '/dashboard/terapeuta/pendencias', element: <HeaderPadrao><EvolucaoDiaria /></HeaderPadrao> },
    { path: '/dashboard/terapeuta/pendencias/pendencias-gerais', element: <HeaderPadrao><PendenciasDiarias /></HeaderPadrao> },
    { path: '/dashboard/terapeuta/pendencias/pendencias-atrasadas', element: <HeaderPadrao><PendenciasAtrasadas /></HeaderPadrao> },
    { path: '/dashboard/terapeuta/pacientes', element: <TerapeutaPacientes /> },
    { path: '/dashboard/terapeuta/pacientes/perfil/:id', element: <HeaderPadrao><PerfilPaciente /></HeaderPadrao> },
    { path: '/dashboard/terapeuta/pacientes/prontuarios', element: <TerapeutaProntuarios /> },
    { path: '/dashboard/terapeuta/pacientes/prontuarios/:id', element: <TerapeutaProntuarios /> },
    { path: '/dashboard/terapeuta/pacientes/pesquisar', element: <HeaderPadrao><Pesquisar /></HeaderPadrao> },
    { path: '/dashboard/terapeuta/statusdiario', element: <StatusDiario /> },
    { path: '/dashboard/terapeuta/areacompartilhada', element: <AreaCompartilhadaTerapeuta /> },
    { path: '/dashboard/terapeuta/agenda', element: <TerapeutaAgenda /> },
    { path: '/dashboard/terapeuta/ultimos-atendimentos', element: <TerapeutaRelatorios /> },
    { path: '/dashboard/terapeuta/solicitacoes', element: <Solicitacoes /> },
    { path: '/dashboard/terapeuta/minhas_solicitacoes', element: <MinhasSolicitacoes /> },
    { path: '/dashboard/terapeuta/gerador-de-documento/:id', element: <GeradorDeDocumento /> },
];
const TerapeutaRoutes = () => {
    return routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
    ));
};

const getTerapeutaPaths = () => {
    return routes.map(route => route.path);
};

export { TerapeutaRoutes, getTerapeutaPaths };