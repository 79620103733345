import React, { useEffect, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { Container } from './styles';

import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

import SalaService from '../../../../services/SalaService';
import TableSala from './TableSala';
import SalaModal from '../../../../components/Modais/SalaModal';
import ResponsavelSalaModal from '../../../../components/Modais/ResponsavelSalaModal';
import Admin from '../../../../layouts/Admin';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Sala() {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#8ed4ae');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalResponsavelIsOpen, setModalResponsavelIsOpen] = useState(false);
    const [SalaId, setSalaId] = useState(null);
    const [salasNameList, setSalasNameList] = useState(null);
    const [Salas, setSalas] = useState([]);

    const loadSalas = () => {
        SalaService.index('')
            .then(({ data }) => {
                let nameSala = [];
                data.sort((a, b) => {
                    if (a.sala < b.sala) {
                        return -1;
                    }
                    if (a.sala > b.sala) {
                        return 1;
                    }
                    return 0;
                });
                data.forEach((data) => nameSala.push({ sala: data.sala, id: data.id }));
                setSalasNameList(nameSala);
                setSalas(data);
            })
            .finally(() => setLoading(false));
    };

    const editModal = (data) => {
        setModalData(data);
        setIsOpen(true);
    };

    const handleNewSala = () => {
        setModalData(null);
        setIsOpen(true);
    };

    const associarResponsavel = (sala_id) => {
        setSalaId(sala_id);
        setModalResponsavelIsOpen(true);
    };

    useEffect(() => {
        loadSalas();
    }, []);

    return (
        <Container>
            <div>
                <button onClick={() => handleNewSala()} className="btn-padrao justify-content-center d-flex align-content-center">
                    <AiFillFileAdd className="mr-6px" />Nova Sala
                </button>
            </div>
            <div>
                {(Salas.length !== 0 && loading === false)
                    ? <TableSala callback={loadSalas} source={Salas} openModalResponsavel={associarResponsavel} editModal={editModal} />
                    : <ClipLoader color={color} loading={loading} css={override} size={50} />
                }
            </div>
            {modalIsOpen &&
                <SalaModal salasList={salasNameList} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} callback={loadSalas} modalData={modalData} />
            }
            {modalResponsavelIsOpen &&
                <ResponsavelSalaModal modalIsOpen={modalResponsavelIsOpen} setIsOpen={setModalResponsavelIsOpen} salaId={SalaId} />
            }
        </Container>
    );
}
