import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const BotaoAcaoStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    lineHeight: 1,
    border: 'none',
    padding: '2px 22px',
    borderRadius: 8,
    marginRight: 12,
    height: 40,
    width: '17%'
};

export const BotaoInserirAtendimento = {
    alignItems: 'center', 
    justifyContent: 'center', 
    display: 'flex', 
    marginTop: 20, 
    paddingBottom: 50
};

export const ButtonEditStatus = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    border: 'none',
    padding: '2px 8px',
    borderRadius: 8,
    marginRight: 12 
};

export const TdStyle = {
    padding: '10px',
    marginRight: '10px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 3px 0px silver',
};

export const ThStyle = {
    color: 'black', 
    fontWeight: 'bold',
    textAlign: 'left',
};