import React, { useEffect, useMemo } from 'react';
import { AiOutlineDelete, AiOutlineDownload, AiOutlineEdit } from 'react-icons/ai';
import TerapeutaTerapiaService from '../../../../services/TerapeutaTerapiaService';
import { TableDefault } from '../../../../components/TableDefault';
import moment from 'moment/moment';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import ModalTerapia from './ModalTerapia';
import { set } from 'date-fns';

export function TableTerapias({ source, terapeutaId, setTerapias }) {
    /* =============================== */
    /* ===VARIÁVEIS=================== */
    /* =============================== */
    /*==VARS INFORMAÇÕES da TELA==*/
    const titulo = 'TABELA TERAPIAS TERAPEUTA';
    const rota = '/dashboard/{modulo}/terapeuta/';

    const snackbar = useSnackbar();

    const [modalData, setModalData] = React.useState(null);
    const modalTerapiaRef = React.useRef();

    const removeTerapia = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let terapiasLocal = [...source];
                if (terapeutaId === null || terapeutaId === undefined) {
                    const index = terapiasLocal.indexOf(data);
                    terapiasLocal.splice(index, 1);
                    snackbar.displayMessage('Cadastro removido com sucesso!', 'success');
                } else {
                    await TerapeutaTerapiaService.destroy(data.id)
                        .then(() => {
                            snackbar.displayMessage('Cadastro removido com sucesso!', 'success');
                            for (let index = 0; index < terapiasLocal.length; index++) {
                                const e = terapiasLocal[index];
                                if (e.id === data.id) {
                                    terapiasLocal.splice(index, 1);
                                    break;
                                }
                            }
                        }).catch((e) => {
                            Swal.fire('Erro ao remover o cadastro!', 'A terapeia não pode ser removida, aperte F12 e confira o log de erro.', 'error');
                            console.error(e);
                        });
                }
                setTerapias(terapiasLocal);
            }
        }).catch(() => {
            Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
        });
    };

    const editTerapia = (data) => {
        setModalData(data);
        if (modalTerapiaRef.current !== null) {
            modalTerapiaRef.current.openModal();
        }
    };

    const callBackEdicao = async () => {
        let newTerapias = await api.get('api/terapeuta-terapia?filter[terapeuta_id]=' + terapeutaId + '&with=terapium').then(({ data }) => data);
        setTerapias(newTerapias);
    };

    const downloadFile = (id, name) => {
        api.get('api/terapeuta-terapia/download/' + id + '?id=' + id, { responseType: 'blob' })
            .then((response) => {
                download(response, name);
            });
    };

    const download = (response, name) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Use o nome do arquivo do cabeçalho da resposta
        const contentDisposition = response.headers['content-type'];
        let fileName;
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.split('/');
            fileName = 'DIPLOMA - ' + name + '.' + fileNameMatch[1];
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Terapias:',
                accessor: 'terapium.terapia',
            },
            {
                Header: 'Data:',
                accessor: 'data', Cell: ({ cell }) => {
                    return moment.utc(cell.row.original.data).format('DD/MM/YYYY');
                }
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        (<div className="d-flex align-center justify-center" style={{ backgroundColor: 'transparent' }}>
                            <AiOutlineEdit className="icone-padrao" onClick={(e) => editTerapia(cell.row.original)} />
                            <AiOutlineDownload className="icone-padrao" onClick={(e) => downloadFile(cell.row.original.id, cell.row.original.terapium.terapia)} />
                            <AiOutlineDelete className="icone-padrao" title={cell.row.original.id} onClick={e => removeTerapia(cell.row.original)} />
                        </div>)
                    );
                }
            }
        ],
    );

    return (
        <>
            <TableDefault source={source} columns={columns} />
            <ModalTerapia ref={modalTerapiaRef} source={source} modalData={modalData} callBack={callBackEdicao} />
        </>
    );
}