import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from '../../Input';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import { leftContentDivStyle } from '../../../pages/AdminDashboard/Terapeutas/Novo/styles';
import { Delete } from '../styles';
import Arquivos from '../../../services/ArquivosService';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRadius: 10,
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        width: '40vw',
        maxHeight: '70vh',
        verticalAlign: 'middle',
        display: 'grid'
    },
};

const SalaModal = ({ modalIsOpen, setIsOpen, callback, modalData, salasList }, ref) => {

    const [mapaSala, setMapaSala] = useState(null);
    const [mapaSalaBase, setMapaSalaBase] = useState(null);
    const [loading, setLoading] = useState(false);

    let subtitle;
    const formRef = useRef(null);
    const snackbar = useSnackbar();

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(data) {
        try {
            setLoading(true);
            const check = salasList?.find(
                (sala) => {
                    if (modalData === null) {
                        return (sala.sala.toString().toLowerCase() === data.sala.toString().toLowerCase());
                    } else {
                        return modalData.id !== sala.id && (sala.sala.toString().toLowerCase() === data.sala.toString().toLowerCase());
                    }
                });
            if (check !== undefined) {
                Swal.fire('Já existe uma sala com este nome! Por favor use outro nome!', '', 'warning');
            } else {
                let chave = mapaSala ? 'arquivo' : null;
                if (modalData === null) {
                    data = { ...data, 'arquivo': mapaSala };
                    await Arquivos.store(data, 'api/sala', chave)
                        .then(snackbar.displayMessage('Cadastro criado com sucesso!', 'success'))
                        .catch((e) => {
                            snackbar.displayMessage('Erro ao cadastrar!', 'error');
                            console.error(e);
                        });
                    setIsOpen(false);
                    callback();
                } else {
                    await Arquivos.update({ ...data, id: modalData.id }, 'sala', chave, mapaSala)
                        .then(snackbar.displayMessage('Cadastro Alterado com sucesso!', 'success'))
                        .catch((e) => {
                            snackbar.displayMessage('Erro ao alterar o cadastro! ', 'error');
                            console.error(e);
                        });
                    setIsOpen(false);
                    callback();
                }
            }
        } catch (error) {
            Swal.fire('Houve um problema ao cadastrar!', '', 'warning');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRemoveImg = () => {
        Swal.fire({
            title: 'Deseja remover a imagem?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let inputImg = document.querySelector('#image');
                inputImg.value = '';
                setMapaSala(null);
            }
        });
    };

    const handleMapaSala = (e) => {
        var extPermitidas = ['jpg', 'jpeg', 'png'];
        let inputImg = document.querySelector('#image');
        const file = e.target.files[0];
        const size = e.target.files[0].size;
        if (extPermitidas.find((ext) => { return file.name.split('.').pop() === ext; }) === undefined) {
            Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + 'Permitidas: ' + extPermitidas, '', 'warning');
            inputImg.value = '';
        } else {
            if (size < 5242880) { //5MB
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener('load', (e) => {
                        const readerTarget = e.target;
                        inputImg.src = readerTarget.result;
                    });
                    reader.readAsDataURL(file);
                    setMapaSala(file);

                } else {
                    Swal.fire('Houve um problema ao enviar o arquivo', '', 'warning');
                }

            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 5MB)', '', 'warning');
                inputImg.value = '';
            }
            e.preventDefault();
        }
    };
    useEffect(() => {
        if (modalData) {
            setMapaSalaBase(modalData.mapa_sala_base);
        }
    }, [modalData]);
    return (
        <div>
            <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} appElement={document.getElementById('root')} transparent>
                <div className='justify-content-between'>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Sala</h2>
                    <label className='btn-x' onClick={() => setIsOpen(false)}> x </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}>
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <label htmlFor="sala">Nome da Sala</label>
                                <Input name={'sala'} id={'sala'} required={'required'} defaultValue={modalData === null ? '' : modalData.sala} />
                            </div>
                        </div>
                        <div>
                            <label>Mapa da Sala</label>
                        </div>
                        <div style={(mapaSala === null && (modalData === null || modalData.mapa_sala_base === null))
                            ? {}
                            : { display: 'none' }
                        }>
                            <p className='text-center'> Nenhuma imagem encontrada </p>
                        </div>
                        <div style={((modalData === null || modalData.caminho_mapa_full === null) && mapaSala === null)
                            ? { display: 'none' }
                            : { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                        }>
                            {mapaSalaBase
                                ? <img style={{ borderRadius: 10 }} id='image' height={180} width={250} src={mapaSalaBase} alt='' />
                                : <img style={{ borderRadius: 10 }} id='image' height={180} width={250} src={mapaSala} alt='' />
                            }
                            <div style={{ position: 'absolute', right: 80, top: 200 }}>
                                <span style={Delete} onClick={e => handleRemoveImg()}>X</span>
                            </div>
                        </div>
                        <div>
                            <div style={leftContentDivStyle}>
                                <label htmlFor="user_img" className='btn-padrao'>
                                    Adicionar Imagem
                                    <input type="file" name='caminho_mapa' id='user_img' className='d-none cursor-none' onChange={e => handleMapaSala(e)} />
                                </label>
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : modalData === null ? 'Adicionar' : 'Alterar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(SalaModal);
