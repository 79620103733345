import { useState } from 'react';
import api from 'services/api';

const usePlanoSaudeData = () => {
    const [planoSaudeOptions, setPlanoSaudeOptions] = useState([]);

    const fetchPlanoSaude = async () => {
        try {
            const { data } = await api.get('api/plano-saude');
            const options = data.map((planoSaude) => ({
                value: planoSaude.id,
                label: `${planoSaude.id} - ${planoSaude.nome_reduzido}`
            }));
            setPlanoSaudeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde:', error);
        }
    };
    const fetchPlanoSaudeTerapiaData = async () => {
        try {
            const { data } = await api.get('api/plano-saude?with=plano_saude_terapia');
            const options = data.map((planoSaude) => ({
                value: planoSaude.id,
                label: planoSaude.nome_reduzido,
                plano_saude_terapia: planoSaude.plano_saude_terapia
            }));
            setPlanoSaudeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde com terapias:', error);
        }
    };

    return { planoSaudeOptions, fetchPlanoSaude, fetchPlanoSaudeTerapiaData };
};

export default usePlanoSaudeData;