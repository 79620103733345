import useIcons from 'context/icons';
import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import VirtualizedList from 'components/Virtualized/virtualized-list';
import Select from 'components/Select';
import Input from 'components/Input';

/**
 * Componente PickList
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Array} props.todosDefault - Lista de itens disponíveis por padrão.
 * @param {Array} props.selecionadosDefault - Lista de itens selecionados por padrão.
 * @param {Function} props.onSelectedChange - Função chamada quando há alterações nos itens selecionados.
 * @param {string} [props.className] - Classe CSS adicional para o componente.
 * @param {string} [props.selectListKey] - Chave para filtro de seleção.
 * @param {boolean} [props.enableSelectFilter=false] - Habilita ou desabilita o filtro de seleção.
 * @param {React.Ref} ref - Referência para o componente.
 *
 * @returns {JSX.Element} Componente PickList.
 *
 * @example
 * <PickList
 *   todosDefault={[{ value: 1, label: 'Item 1' }, { value: 2, label: 'Item 2' }]}
 *   selecionadosDefault={[{ value: 3, label: 'Item 3' }]}
 *   onSelectedChange={({ added, removed }) => console.log('Adicionados:', added, 'Removidos:', removed)}
 *   className="custom-class"
 *   selectListKey="label"
 *   enableSelectFilter={true}
 * />
 */
const PickList = forwardRef(({
    todosDefault = [],
    selecionadosDefault = [],
    onSelectedChange = () => { },
    className,
    selectListKey,
    enableSelectFilter = false
}, ref) => {
    //#region VARIAVEIS
    const { iconArray } = useIcons();

    // Estado para itens adicionados e removidos
    const [adicionados, setAdicionados] = useState([]);
    const [removidos, setRemovidos] = useState([]);

    // Estado para armazenar as listas
    const [todos, setTodos] = useState([]);
    const [disponiveis, setDisponiveis] = useState([]);
    const [selecionados, setSelecionados] = useState([]);

    // Estados para os filtros
    const [disponiveisFiltro, setDisponiveisFiltro] = useState('');
    const [selecionadosFiltro, setSelecionadosFiltro] = useState('');
    const [disponiveisSelectFiltro, setDisponiveisSelectFiltro] = useState([]);
    const [selecionadosSelectFiltro, setSelecionadosSelectFiltro] = useState([]);

    // Atualiza a lista de disponíveis com base no filtro digitado
    const disponiveisFiltrados = useMemo(() => {
        return disponiveis?.filter(item => {
            const matchesTextFilter = item.label.toLowerCase().includes(disponiveisFiltro.toLowerCase()) ||
                (item.secondLabel && item.secondLabel.toLowerCase().includes(disponiveisFiltro.toLowerCase()));
            const matchesSelectFilter = disponiveisSelectFiltro.length === 0 ||
                disponiveisSelectFiltro.some(filtro => filtro.value === item[selectListKey] || filtro.value === item.secondLabel);
            return matchesTextFilter && matchesSelectFilter;
        });
    }, [disponiveisFiltro, disponiveisSelectFiltro, disponiveis, selectListKey]);

    // Atualiza a lista de selecionados com base no filtro digitado
    const selecionadosFiltrados = useMemo(() => {
        return selecionados?.filter(item => {
            const matchesTextFilter = item.label.toLowerCase().includes(selecionadosFiltro.toLowerCase()) ||
                (item.secondLabel && item.secondLabel.toLowerCase().includes(selecionadosFiltro.toLowerCase()));
            const matchesSelectFilter = selecionadosSelectFiltro.length === 0 ||
                selecionadosSelectFiltro.some(filtro => filtro.value === item[selectListKey] || filtro.value === item.secondLabel);
            return matchesTextFilter && matchesSelectFilter;
        });
    }, [selecionadosFiltro, selecionadosSelectFiltro, selecionados, selectListKey]);

    // Cria uma lista de selecionáveis baeado no secondLabel
    const filtroSelectDisponiveis = useMemo(() => {
        const disponiveisOptions = [];
        disponiveis.forEach(item => {
            if (selectListKey) {
                if (item[selectListKey] && disponiveisOptions.findIndex(d => d.value === item[selectListKey]) === -1) {
                    disponiveisOptions.push({ label: item[selectListKey], value: item[selectListKey] });
                }
            } else {
                if (item.secondLabel && disponiveisOptions.findIndex(d => d.value === item.secondLabel) === -1) {
                    disponiveisOptions.push({ label: item.secondLabel, value: item.secondLabel });
                }
            }
        })
        return disponiveisOptions;
    }, [disponiveis]);

    // Cria uma lista de selecionáveis baeado no secondLabel
    const filtroSelectSelecionados = useMemo(() => {
        const selecionadosOptions = [];
        selecionados.forEach(item => {
            if (selectListKey) {
                if (item[selectListKey] && selecionadosOptions.findIndex(s => s.value === item[selectListKey]) === -1) {
                    selecionadosOptions.push({ label: item[selectListKey], value: item[selectListKey] });
                }
            } else {
                if (item.secondLabel && selecionadosOptions.findIndex(s => s.value === item.secondLabel) === -1) {
                    selecionadosOptions.push({ label: item.secondLabel, value: item.secondLabel });
                }
            }
        })
        return selecionadosOptions;
    }, [selecionados]);
    //#endregion

    //#region FUNCOES

    /**
     * Seleciona um item da lista de disponíveis e o move para a lista de selecionados.
     *
     * @param {Object} item - Item a ser selecionado.
     */
    const selecionar = (item) => {
        setAdicionados([...adicionados, item]); // Marca como adicionado
        setSelecionados([...selecionados, item]); // Atualiza os selecionados
        setDisponiveis(disponiveis.filter(d => d.value !== item.value)); // Remove dos disponíveis
    };

    /**
     * Remove um item da lista de selecionados e o move de volta para a lista de disponíveis.
     *
     * @param {Object} item - Item a ser removido.
     */
    const remover = (item) => {
        setRemovidos([...removidos, item]); // Marca como removido
        setSelecionados(selecionados.filter(s => s.value !== item.value)); // Remove dos selecionados
        setDisponiveis([...disponiveis, item]); // Reinsere nos disponíveis
    };

    /**
     * Seleciona todos os itens disponíveis.
     */
    const selecionaTodos = () => {
        setRemovidos([]); // Remove todos os que foram removidos
        const newTodos = todosDefault.filter(({ value }) =>
            selecionadosDefault.findIndex((find) => find.value === value) === -1
        );
        setAdicionados([...newTodos]); // Marca todos como adicionados
        setSelecionados([...todos]); // Adiciona todos aos selecionados
        setDisponiveis([]); // Limpa a lista de disponíveis
    };

    /**
     * Remove todos os itens selecionados, movendo-os de volta para a lista de disponíveis.
     */
    const removeTodos = () => {
        setAdicionados([]); // Remove todos os adicionados
        setRemovidos([...removidos, ...selecionadosDefault]); // Marca todos como removidos
        setDisponiveis([...todos]); // Reinsere todos nos disponíveis
        setSelecionados([]); // Limpa a lista de selecionados
    };

    /**
     * Altera os itens selecionados com base em um array de novos itens selecionados.
     *
     * @param {Array} arraySelecionados - Array de itens que devem ser os novos selecionados.
     * @param {Function} adicionadosMap - Função opcional para mapear os itens adicionados.
     */
    const alteraSelecionados = (arraySelecionados, adicionadosMap = (map) => map) => {
        const novosSelecionados = todos.filter(({ value }) =>
            arraySelecionados.findIndex((find) => find.value === value) !== -1
        );

        const novosDisponiveis = todos.filter(({ value }) =>
            arraySelecionados.findIndex((find) => find.value === value) === -1
        );

        const novosAdicionados = novosSelecionados.filter(item =>
            selecionadosDefault.findIndex((find) => find.value === item.value) === -1
        ).map(adicionadosMap);
        const novosRemovidos = selecionados.filter(item =>
            arraySelecionados.findIndex((find) => find.value === item.value) === -1
        );

        setSelecionados(novosSelecionados); // Atualiza a lista de selecionados
        setDisponiveis(novosDisponiveis); // Atualiza a lista de disponíveis
        setAdicionados(novosAdicionados); // Atualiza os itens adicionados
        setRemovidos(novosRemovidos);
    };

    useImperativeHandle(ref, () => ({
        alteraSelecionados,
        selecionaTodos,
        removeTodos
    }));
    //#endregion

    //#region USE EFFECTS

    /**
     * Atualiza todos os itens disponíveis a partir das props iniciais.
     */
    useEffect(() => {
        setTodos(todosDefault);
        const newTodos = todosDefault.filter(({ value }) =>
            selecionadosDefault.findIndex((find) => find.value === value) === -1
        );
        setDisponiveis(newTodos);
        setSelecionados(selecionadosDefault);
    }, [todosDefault, selecionadosDefault]);

    /**
     * Notifica o componente pai sobre as alterações nas listas de itens adicionados e removidos.
     */
    useEffect(() => {
        if (onSelectedChange) {
            onSelectedChange({ added: adicionados, removed: removidos });
        }
    }, [adicionados, removidos, onSelectedChange]);
    //#endregion

    return (
        <div className={`ns-picklist-container${className ? ` ${className}` : ''}`}>
            {/* Lista de Itens Disponíveis */}
            <div className='area disponiveis'>
                <h3>Disponíveis ({disponiveis.length})</h3>
                <div className='filtro'>
                    <div className='input-div'>
                        <Input
                            id='filter-disponiveis'
                            name='filter-disponiveis'
                            upperContainerStyle={{ width: '100%' }}
                            type='text'
                            placeholder='Digite...'
                            value={disponiveisFiltro}
                            onChange={(e) => setDisponiveisFiltro(e.target.value)}
                        />
                    </div>

                    {filtroSelectDisponiveis.length > 0 && enableSelectFilter && <div className='input-div'>
                        <Select
                            id='select-selecionaveis'
                            name='select-selecionaveis'
                            placeholder='Selecione...'
                            options={filtroSelectDisponiveis}
                            value={disponiveisSelectFiltro}
                            onChange={setDisponiveisSelectFiltro}
                            isClearable
                            isMulti
                        />
                    </div>
                    }
                </div>
                <VirtualizedList
                    items={disponiveisFiltrados}
                    itemRenderer={({ item }) => (
                        <li key={item.value}>
                            <div>
                                {item?.label ? <p>{item.label}</p> : <p>{item?.secondLabel}</p>}
                                {item?.secondLabel && <span> {item?.secondLabel} </span>}
                            </div>
                            <button type='button' onClick={() => selecionar(item)}>
                                {iconArray['adicionar']}
                            </button>
                        </li>
                    )}
                    itemHeight={40}
                    containerHeight={400}
                />
            </div>
            <div className='toolbox'>
                <div>
                    <button type='button' onClick={selecionaTodos}>
                        {iconArray['dupla-seta-angulo-direita']}
                    </button>
                    <button type='button' onClick={removeTodos}>
                        {iconArray['dupla-seta-angulo-esquerda']}
                    </button>
                </div>
            </div>
            {/* Lista de Itens Selecionados */}
            <div className='area selecionados'>
                <h3>Selecionados ({selecionados.length})</h3>
                <div className='filtro'>
                    <div className='input-div'>
                        <Input
                            id='filter-selecionados'
                            name='filter-selecionados'
                            type='text'
                            placeholder='Digite...'
                            upperContainerStyle={{ width: '100%' }}
                            value={selecionadosFiltro}
                            onChange={(e) => setSelecionadosFiltro(e.target.value)}
                        />
                    </div>

                    {filtroSelectSelecionados.length > 0 && enableSelectFilter && <div className='input-div'>
                        <Select
                            id='select-selecionados'
                            name='select-selecionados'
                            placeholder='Selecione...'
                            options={filtroSelectSelecionados}
                            value={selecionadosSelectFiltro}
                            onChange={setSelecionadosSelectFiltro}
                            isClearable
                            isMulti
                        />
                    </div>
                    }
                </div>
                <VirtualizedList
                    items={selecionadosFiltrados}
                    itemRenderer={({ item }) => (
                        <li key={item.value}>
                            <div>
                                {item?.label ? <p>{item.label}</p> : <p>{item?.secondLabel}</p>}
                                {item?.secondLabel && <span> {item?.secondLabel} </span>}
                            </div>
                            <button type='button' onClick={() => remover(item)}>
                                {iconArray['deletar']}
                            </button>
                        </li>
                    )}
                    itemHeight={40}
                    containerHeight={400}
                />
            </div>
        </div>
    );
});

export default PickList;