import React, {forwardRef, useCallback, useRef} from 'react';
import Modal from 'react-modal';
import {Form} from '@unform/web';
import Input from '../../Input';
import GrupoPermissoesService from '../../../services/GrupoPermissoesService';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import Button from '../../Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '70vh',
    minHeight: '70vh',
    width: '40vw',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF'
  },
};

const GrupoPermissaoModal = ({modalIsOpen, setIsOpen, callback, modalData}, ref) => {
  let subtitle;

  const formRef = useRef(null);
  const snackbar = useSnackbar();

  function afterOpenModal() {
    subtitle.style.color = '#000';
  }

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  async function handleSubmit(data) {
    try {
      if (modalData === null) {
        data.guard_name = 'api';
        await GrupoPermissoesService.store(data);
        snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
        setIsOpen(false);
        callback();
      } else {
        await GrupoPermissoesService.update(data, modalData.id);
        snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
        setIsOpen(false);
        callback();
      }
    } catch (error) {
      Swal.fire('Houve um problema ao cadastrar!', '', 'error');
      console.error(error);
    } 
  }

  return (
    <div>
      
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        transparent>
          <div style={{position: 'relative'}}>
            <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
              right: '10px', fontSize: '25px', top: '-10px' }} onClick={() => setIsOpen(false)}>
              x
            </label>
          </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h2 style={{color: '#442953', fontWeight: 'bold', marginTop: 20}} ref={(_subtitle) => (subtitle = _subtitle)}>
            {modalData === null ? 'Novo Grupo de Permissões' : 'Alterar Grupo de Permissões'}
          </h2>
          <Form ref={formRef} onSubmit={handleSubmit}
                style={{display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red'}}>
            <div style={{paddingTop: 20}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Input name={'name'} title={'Nome do grupo:'} required={'required'} 
                  defaultValue={modalData === null ? '' : modalData.name}/>
              </div>
            </div>
            <Button className='w-300px' type="submit" >
              {modalData === null ? 'Adicionar' : 'Alterar'}
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default forwardRef(GrupoPermissaoModal);