import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import { TableHistoricoDeFerias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableHistoricoDeFerias';
import { TableTerapias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableTerapias';
import TableResponsavel from '../../../RecepcaoDashboard/Pacientes/Perfil/TableResponsavel';
import Select from '../../../../components/Select';
import PacienteService from '../../../../services/PacienteService';
import api from '../../../../services/api';
import Cep from '../../../../services/cep';
import moment from 'moment/moment';
import ModalInformacoesComplementares from '../../../../components/Modais/InformacoesComplementares';
import { Container, StatusAfastado, StatusAguardando, StatusAtivo, StatusFerias, StatusInativo } from '../../../AdminDashboard/Pacientes/Perfil/styles';
import { subHeader } from '../../../AdminDashboard/Terapeutas/Novo/styles';
import { TableEmergenciasPaciente } from './TableEmergencias';
import { TableMedicamentosPaciente } from './TableMedicamentos';
import { TableMedicosAssistentes } from './TableMedicos';
import { useAuth } from '../../../../context/auth';

export default function PerfilPacienteRecepcao() {
    const Usuario = useAuth();//Usuario logado

    const location = useLocation();

    const [terapias, setTerapias] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [documentosAssinados, setDocumentosAssinados] = useState([]);
    const [feriasList, setFeriasList] = useState([]);
    const [feriasPaciente, setFeriasPaciente] = useState([]);
    const [pacienteId, setPacienteId] = useState(null);
    const [pacienteStatus, setPacienteStatus] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [statusColor, setStatusColor] = useState();
    const [emergencias, setEmergencias] = useState([]);
    const [medicos, setMedicos] = useState([]);
    const [medicamentos, setMedicamentos] = useState([]);
    const [imagemPerfil, setImagemPerfil] = useState(null);
    const [direitoImagem, setDireitoImagem] = useState(false);

    const [observacoes, setObservacoes] = useState('');
    const [infoComplementar, setInfoComplementar] = useState([]);
    const [comorbidades, setComorbidades] = useState('');
    const [infoComplModal, setInfoComplModal] = useState(false);
    const [identificadorList, setIdentificadorList] = useState(location.state.pacienteIdentList);

    const [pacienteListaEspera, setPacienteListaEspera] = useState(location.state.modalInfo);

    const [modalData, setModalData] = useState(null);
    const [modalResponsavelData, setModalResponsavelData] = useState(null);
    const [modalEmergenciasData, setModalEmergenciasData] = useState(null);
    const [modalMedicosData, setModalMedicosData] = useState(null);
    const [modalMedicamentosData, setModalMedicamentosData] = useState(null);

    const modalResponsavelRef = useRef(null);
    const modalTerapiaRef = useRef(null);
    const modalEmergenciasRef = useRef(null);
    const modalMedicosRef = useRef(null);
    const modalMedicamentosRef = useRef(null);

    const [permissoes, setPermissoes] = useState([]);
    const coletaPermissoes = () => {
        let permissoes = Usuario.user?.lista_de_permissoes[0]?.permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = 'evolucao_paciente';
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    };

    const { id } = useParams();
    const navigate = useNavigate();
    let day = new Date(moment().format('MM/DD/YYYY'));

    const formRef = useRef(null);

    const handleEditResponsavel = useCallback(() => {
        modalResponsavelRef.current.openModal();
    }, []);

    const handleEditTerapia = useCallback(() => {
        modalTerapiaRef.current.openModal();
    }, []);

    const handleEditEmergencias = useCallback(() => {
        modalEmergenciasRef.current.openModal();
    }, []);

    const handleEditModalMedicos = useCallback(() => {
        modalMedicosRef.current.openModal();
    }, []);

    const handleEditMedicamentos = useCallback(() => {
        modalMedicamentosRef.current.openModal();
    }, []);

    const handleChangeDireito = (e) => {
        if (e.target.checked) {
            setDireitoImagem(true);
        } else {
            setDireitoImagem(false);
        }
    };

    const handleChangeStatus = (e) => {
        setStatusSelecionado(e);
        if (e.label === 'Ativo') setStatusColor(StatusAtivo);
        else if (e.label === 'Afastado') setStatusColor(StatusAfastado);
        else if (e.label === 'Inativo') setStatusColor(StatusInativo);
        else if (e.label === 'Férias') setStatusColor(StatusFerias);
        else {
            setStatusColor(StatusAguardando);
        }
    };
    const getDefaultStatus = (statusPaciente) => {
        if (!paciente) return;
        const status = statusPaciente.filter(data => {
            return data.label === paciente.status;
        })[0];
        setStatusSelecionado(status);
    };

    const carregaPaciente = () => {
        PacienteService.show(id, 'with=documentos,paciente_ferias,terapia_pacientes.especialidade,' +
            'terapia_pacientes.terapium,terapia_pacientes.plano_saude,paciente_contato_emergencia,paciente_medicamentos,' +
            'paciente_medicos_assistentes')
            .then(({ data }) => {
                if (data.responsavels.length > 0) {
                    setResponsaveis(data.responsavels);
                    api.get(`/api/responsavel-assinaturas?with=documentos_administrativo,responsavel&filter[responsavel_id]=
                        ${data.responsavels[data.responsavels.length - 1].id}`)
                        .then(({ data }) => {
                            data.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                            setDocumentosAssinados(data);
                        });
                }
                if (data.documentos.length > 0) {
                    const documentosData = data.documentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setDocumentos(documentosData);
                };
                if (data.paciente_ferias.length > 0) {
                    const feriasData = data.paciente_ferias.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setFeriasPaciente(feriasData);
                };
                if (data.terapia_pacientes.length > 0) {
                    const terapiasData = data.terapia_pacientes.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setTerapias(terapiasData);
                };
                if (data.paciente_contato_emergencia.length > 0) {
                    const emergenciasData = data.paciente_contato_emergencia.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setEmergencias(emergenciasData);
                };
                if (data.paciente_medicamentos.length > 0) {
                    const medicamentosData = data.paciente_medicamentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicamentos(medicamentosData);
                }
                if (data.paciente_medicos_assistentes.length > 0) {
                    const medicoData = data.paciente_medicos_assistentes.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicos(medicoData);
                }
                setPaciente(data);
                setPacienteId(data.id);
                delete data.responsavels;
                delete data.documentos;
                delete data.terapia_pacientes;

                data = {
                    ...data,
                    cpf: data.cpf ? data.cpf.toString() : '',
                    cep: data.cep ? data.cep.toString() : '',
                    nascimento: moment.utc(data.nascimento).format('YYYY-MM-DD'),
                };
                setObservacoes(data.obs);
                setComorbidades(data.comorbidades);
                setInfoComplementar({
                    gosto_de: data.gosto_de,
                    nao_gosto_de: data.nao_gosto_de,
                    meus_reforcadores: data.meus_reforcadores,
                    meu_hiperfoco: data.meu_hiperfoco,
                    informacoes_complementares: data.informacoes_complementares,
                });
                formRef?.current?.setData({
                    ...data,
                });
            })
            .catch((e) => console.error('e', e));
    };

    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro;
        document.getElementById('bairro').value = data.bairro;
        document.getElementById('estado').value = data.uf;
        document.getElementById('cidade').value = data.localidade;
    };

    useEffect(() => {
        if (paciente !== null) {
            if (statusSelecionado.label === 'Ativo') setStatusColor(StatusAtivo);
            else if (statusSelecionado.label === 'Afastado') setStatusColor(StatusAfastado);
            else if (statusSelecionado.label === 'Inativo') setStatusColor(StatusInativo);
            else if (statusSelecionado.label === 'Férias') setStatusColor(StatusFerias);
            else {
                setStatusColor(StatusAguardando);
            }
        }
    }, [statusSelecionado]);

    useEffect(() => {
        getDefaultStatus(pacienteStatus);
        if (paciente !== null && paciente !== undefined) setPacienteId(paciente.id);
        if (feriasPaciente.length > 0) {
            let index = feriasPaciente.length - 1;
            let data_ida = new Date(feriasPaciente[index].dt_ida);
            let data_volta = new Date(feriasPaciente[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [paciente]);

    useEffect(() => {
        setFeriasPaciente(feriasList);
        if (feriasList.length > 0) {
            let index = feriasList.length - 1;
            let data_ida = new Date(feriasList[index].dt_ida);
            let data_volta = new Date(feriasList[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [feriasList]);

    useEffect(() => {
        let statusList = [];
        statusList.push({
            label: 'Ativo',
            value: 'ativo'
        });
        statusList.push({
            label: 'Inativo',
            value: 'inativo'
        });
        statusList.push({
            label: 'Afastado',
            value: 'afastado'
        });
        statusList.push({
            label: 'Férias',
            value: 'ferias'
        });
        statusList.push({
            label: 'Aguardando Liberação',
            value: 'aguardando'
        });
        setPacienteStatus(statusList);
        carregaPaciente();
        coletaPermissoes();
        if (identificadorList === undefined) {
            let idList = [];
            api.get('api/paciente').then(({ data }) => {
                data.forEach((item) => {
                    if (item.status !== 'Inativo') {
                        if (item.identificador !== null) {
                            idList.push(item.identificador);
                        };
                    };
                });
                setIdentificadorList(idList);
            });
        };

    }, []);

    useEffect(() => {
        if (pacienteListaEspera !== undefined) {
            if (pacienteListaEspera.lista_espera_demandas.length > 0) {
                let demandasList = [];
                pacienteListaEspera.lista_espera_demandas.forEach((demanda) => demandasList.push(demanda));
                setTerapias(demandasList);
            }
            if (pacienteListaEspera.lista_espera_responsavels.length > 0) {
                let responsaveisList = [];
                pacienteListaEspera.lista_espera_responsavels.forEach((responsavel) => {
                    let dadosResponsavel = {
                        nome: responsavel.responsavel,
                        parentesco: responsavel.grau_parentesco,
                        telefone1: responsavel.contato,
                    };
                    responsaveisList.push(dadosResponsavel);
                });
                setResponsaveis(responsaveisList);
            }
        }
    }, [pacienteListaEspera]);


    return (
        <Container>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: window.innerWidth > 600 ? '0 70px' : '0 10px' }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 50
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button className="btn-padrao" onClick={e => setInfoComplModal(true)} >
                            Informações Complementares
                        </button>
                        {/*<label>Último atendimento em: 00/00/0001</label>*/}
                    </div>
                    <img className="perfil-image" id='image' src={paciente === null ? user : paciente.foto_perfil === null ? user : imagemPerfil} height={140} width={140} alt=""></img>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            backgroundColor: '#fff', borderRadius: 8, boxShadow: '0 0 3px 0px silver', padding: '2px 15px'
                        }}>
                            {paciente === null ? '' : <div style={statusColor} />}
                            <label style={{ margin: 5 }}>{paciente === null ? '' : statusSelecionado.label}</label>
                        </div>
                    </div>

                </div>
                <Form className="row" ref={formRef}>
                    {/* Linha 1 */}
                    <div className="col-lg-5 col-sm-12">
                        <Input title="Nome:" name="nome" required={'required'} disabled={permissoes ? permissoes['edição'] : false}
                            defaultValue={pacienteListaEspera === undefined ? '' : pacienteListaEspera.nome} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input title="Nome Curto" name="nome_curto" />
                    </div>
                    <div className='col-lg-1 col-sm-12'>
                        <Input maxLength={5} title="Identificador:" name="identificador" required={'required'} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input
                            title="Data de Entrada:"
                            name="data_de_entrada"
                            icon={null}
                            type={'date'}
                            required={'required'}
                            maskFormat={'99/99/9999'}
                            disabled={false}
                            defaultValue={moment.utc().format('YYYY-MM-DD')} />
                    </div>
                    {/* Linha 2 */}
                    <div className="col-lg-3 col-sm-12">
                        <Select label="Status:" name="status" options={pacienteStatus} value={statusSelecionado}
                            required={'required'} onChange={e => handleChangeStatus(e)} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input
                            title="Data de Nascimento:"
                            name="nascimento"
                            icon={null}
                            type={'date'}
                            disabled={false} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <InputMask
                            title="CPF"
                            name="cpf"
                            icon={null}
                            maskFormat={'999.999.999-99'}
                            disabled={false}
                            required={'required'} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input title="RG:" name="rg" required={'required'} />
                    </div>
                    {/* Linha 3 */}
                    <div className="col-lg-3 col-sm-12">
                        <InputMask
                            onBlur={(ev) => getCep(ev.target.value)}
                            title="CEP"
                            name="cep"
                            id="cep"
                            icon={null}
                            maskFormat={'99999-999'}
                            disabled={false}
                            required={'required'} />
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <Input title="Rua:" name="endereco" id="endereco" required={'required'} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input title="Cidade:" name="cidade" id="cidade" required={'required'} />
                    </div>
                    <div className="col-lg-2 col-sm-12">
                        <Input title="Estado:" name="estado" id="estado" required={'required'} />
                    </div>
                    {/* Linha 3 */}
                    <div className="col-lg-3 col-sm-12">
                        <Input title="Complemento:" name="complemento" id="complemento" />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input title="Bairro:" name="bairro" id="bairro" required={'required'} />
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <Input title="Número:" name="numero" required={'required'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                        <label style={subHeader}>
                            Comorbidades do(a) Paciente:</label>
                        <textarea name={'comorbidades'} defaultValue={comorbidades} readOnly={true}
                            style={{
                                height: 120,
                                borderColor: '#F5F6F8',
                                borderRadius: 8,
                                borderWidth: 1,
                                padding: 5
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                        <label style={subHeader}>Observações Gerais do(a) Paciente:</label>
                        <textarea
                            name={'obs'}
                            readOnly={true}
                            style={{
                                height: 120,
                                borderColor: '#F5F6F8',
                                borderRadius: 8,
                                borderWidth: 1,
                                padding: 5
                            }}
                        />
                    </div>
                    <div>
                        <label style={subHeader}>Terapias do(a) Paciente:</label>
                        <TableTerapias source={terapias} callBack={carregaPaciente} setModalData={setModalData} handleOpen={handleEditTerapia} modalData={modalData} paciente_id={pacienteId} setTerapias={setTerapias} readOnly={true} />
                    </div>
                    <div style={{ paddingTop: 30 }}>
                        <label style={subHeader}>Responsáveis do(a) Paciente:</label>
                        <TableResponsavel source={responsaveis} setModalData={setModalResponsavelData} pacienteId={pacienteId} callBack={carregaPaciente} handleOpen={handleEditResponsavel} setResponsaveis={setResponsaveis} readOnly={true} />
                    </div>
                    <div>
                        <label style={subHeader}>Contatos de Emergência:</label>
                        <TableEmergenciasPaciente source={emergencias} setModalEmergenciasData={setModalEmergenciasData} setEmergencias={setEmergencias} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditEmergencias} readOnly={true} />
                    </div>
                    <div>
                        <label style={subHeader}>Medicamentos do(a) Paciente:</label>
                        <TableMedicamentosPaciente source={medicamentos} setMedicamentos={setMedicamentos} setModalMedicamentosData={setModalMedicamentosData} handleOpen={handleEditMedicamentos} callBack={carregaPaciente} pacienteId={pacienteId} readOnly={true} />
                    </div>
                    <div>
                        <label style={subHeader}>Médicos Assistentes do(a) Paciente:</label>
                        <TableMedicosAssistentes source={medicos} setMedicos={setMedicos} setModalMedicosData={setModalMedicosData} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditModalMedicos} readOnly={true} />
                    </div>
                    {/* 
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <label style={subHeader}>Documentos Gerais do(a) Paciente:</label>
                        </div>
                        <TableDocumentosGerais source={documentos} deleteCallback={carregaPaciente} setDocumentos={setDocumentos} pacienteId={pacienteId} />
                        <div className="d-flex align-items-center justify-content-center pt-20px pb-20px">
                            <button onClick={e => { handleOpenModalDocumento() }} type="button" className="btn-padrao"> Adicionar </button>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
                        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <label style={subHeader}>Documentos Assinados Responsável:</label>
                        </div>
                        <TableDocumentosAssinadosResponsavel source={documentosAssinados}></TableDocumentosAssinadosResponsavel>
                    </div>
                */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={subHeader}>Histórico Férias do(a) Paciente: </label>
                        <TableHistoricoDeFerias source={feriasPaciente} feriasList={feriasList} setFeriasList={setFeriasList} paciente_id={pacienteId} readOnly={true} />
                    </div>
                    <div className="d-flex flex-row justify-content-evenly">
                        <button className="btn-padrao" type="button" onClick={e => navigate('/dashboard/' + Usuario.user.tipo_user + '/pacientes/')}>
                            Voltar
                        </button>
                    </div>
                </Form>
            </div>
            <>
                {infoComplModal &&
                    <ModalInformacoesComplementares onClose={() => setInfoComplModal(false)} infoComplementar={infoComplementar} paciente={paciente} setInfoComplementar={setInfoComplementar} readOnly={true} callBack={carregaPaciente} />
                }
            </>
        </Container>
    );
}
