import { Route } from 'react-router-dom';
import Login from './../pages/Login';
import Dashboard from './../pages/RecepcaoDashboard/Dashboard';
import PerfilPaciente from './../pages/RecepcaoDashboard/Pacientes/Perfil';
import NovoTerapeuta from './../pages/RecepcaoDashboard/Terapeutas/Novo';
import GrupoPermissao from './../pages/AdminDashboard/Configuracoes/Permissoes';
import PlanoDeSaude from './../pages/RecepcaoDashboard/PlanoDeSaude';
import TerapeutaDashboard from './../pages/TerapeutaDashboard/Dashboard';
import StatusDiario from './../pages/TerapeutaDashboard/StatusDiario';
import Atendimentos from './../pages/AdminDashboard/Atendimentos';
import Funcionarios from './../pages/AdminDashboard/Funcionarios';
import ListagemPacientes from './../pages/AdminDashboard/Pacientes';
import TiposAtendimento from './../pages/AdminDashboard/Configuracoes/TiposAtendimento';
import Terapias from './../pages/AdminDashboard/Configuracoes/Terapias';
import Especialidade from './../pages/AdminDashboard/Configuracoes/Especialidade';
import Modalidade from './../pages/AdminDashboard/Configuracoes/Modalidade';
import Sala from './../pages/AdminDashboard/Configuracoes/Sala';
import Estacao from './../pages/AdminDashboard/Configuracoes/Estacao';
import Documentos from './../pages/AdminDashboard/Configuracoes/Documentos';
import AreaCompartilhada from './../pages/AdminDashboard/Configuracoes/AreaCompartilhada';
import Status from './../pages/AdminDashboard/Configuracoes/Status';
import AgendaSemanal from './../pages/RecepcaoDashboard/Agenda/Terapeuta';
import AgendaAreaCompartilhada from './../pages/RecepcaoDashboard/Agenda/AreaCompartilhada';
import NovoPaciente from './../pages/RecepcaoDashboard/Pacientes/Novo';
import Guias from './../pages/RecepcaoDashboard/Guias';
import ListaEspera from './../pages/RecepcaoDashboard/Agenda/ListaDeEspera';
import AgendaPacienteId from './../pages/RecepcaoDashboard/Agenda/PacienteId';
import AgendaTerapeutaId from './../pages/RecepcaoDashboard/Agenda/TerapeutaId';
import AgendaTerapeutas from './../pages/RecepcaoDashboard/Agenda/Terapeutas';
import ControleDiario from './../pages/RecepcaoDashboard/Agenda/ControleDiario';
import DocumentosPendentes from './../pages/DocumentosPendentes';
import React, { useEffect } from 'react';
import DocumentoAssinatura from './../pages/DocumentoAssinatura';
import DocumentosAssinados from './../pages/RecepcaoDashboard/DocumentosAssinados';
import PlanosTerapeuticosPendentes from './../pages/PlanosTerapeuticosPendentes';
import Prontuarios from './../pages/RecepcaoDashboard/Pacientes/Prontuarios';
import EvolucaoDiaria from './../pages/TerapeutaDashboard/Pendencias';
import PendenciasDiarias from './../pages/TerapeutaDashboard/Pendencias/PendenciasDiarias';
import PendenciasAtrasadas from './../pages/TerapeutaDashboard/Pendencias/PendenciasAtrasadas';
import TerapeutaPacientes from './../pages/TerapeutaDashboard/TerapeutaPacientes';
import TerapeutaProntuarios from './../pages/TerapeutaDashboard/TerapeutaPacientes/Prontuarios';
import Solicitacoes from './../pages/TerapeutaDashboard/Solicitacoes';
import TerapeutaAgenda from './../pages/TerapeutaDashboard/Agenda';
import TerapeutaRelatorios from './../pages/TerapeutaDashboard/Relatorios';
import HeaderPadrao from 'layouts/HeaderPadrao';
import MinhasSolicitacoes from './../pages/TerapeutaDashboard/Solicitacoes/MinhasSolicitacoes';
import ResponsavelDashboard from './../pages/ResponsavelDashboard/Dashboard';
import TerapeutasResponsavel from './../pages/ResponsavelDashboard/Terapeutas';
import CurriculoTerapeuta from './../pages/ResponsavelDashboard/Terapeutas/CurriculoTerapeuta';
import PacientesResponsavel from './../pages/ResponsavelDashboard/Pacientes';
import DocumentosPendentesResponsavel from './../pages/ResponsavelDashboard/Dashboard/DocumentosPendentes';
import AgendaPacienteResponsavel from './../pages/ResponsavelDashboard/AgendaPaciente';
import CoordenacaoDashboard from './../pages/CoordenacaoDashboard/Dashboard';
import CadastroPerfilTerapeuta from './../pages/CoordenacaoDashboard/Terapeutas/Novo';
import PerfilPacienteResponsavel from './../pages/ResponsavelDashboard/Pacientes/Perfil';
import PerfilPacienteAdmin from './../pages/AdminDashboard/Pacientes/Perfil';
import ListagemTerapeutas from './../pages/AdminDashboard/Terapeutas';
import PlanoDeSaudeAdmin from './../pages/AdminDashboard/PlanoDeSaude';
import ConfiguracoesAdmin from './../pages/AdminDashboard/Configuracoes';
import RelatóriosEstrategicos from './../pages/AdminDashboard/RelatoriosEstrategicos';
import AuditorDashboard from './../pages/AuditorDashboard';
import PerfilPacienteAuditor from './../pages/AuditorDashboard/Paciente/Perfil';
import AvaliacaoDeMateriais from './../pages/CoordenacaoDashboard/SolicitacaoDeMateriais';
import AgendaTerapeutaAdmin from './../pages/AdminDashboard/Terapeutas/AgendaTerapeutaAdmin';
import DocumentosTerapeuticos from './../pages/ResponsavelDashboard/Dashboard/DocumentosTerapeuticos';
import FaltasPacientes from './../pages/CoordenacaoDashboard/FaltasPacientes';
import AreaCompartilhadaTerapeuta from './../pages/TerapeutaDashboard/Agenda/AreasCompartilhadas';
import TiposAvaliacao from './../pages/AdminDashboard/Configuracoes/TiposAvaliacao';
import RelatóriosCoordenacao from './../pages/CoordenacaoDashboard/RelatoriosCoordenacao';
import TerapeutaBloqueado from './../pages/TerapeutaDashboard/BloqueioAcesso';
import Pesquisar from './../pages/TerapeutaDashboard/TerapeutaPacientes/Pesquisar';
import Diagnosticos from './../pages/AdminDashboard/Configuracoes/Diagnosticos';
import ListagemDeGlosas from './../pages/AdminDashboard/Terapeutas/Glosas';
import HorariosAtendimento from './../pages/AdminDashboard/Configuracoes/HorariosAtendimento';
import Testes from './../pages/Testes';
import Testes2 from './../pages/Testes2';
import AgendamentoDashboard from './../pages/AgendamentoDashboard';
import PerfilUsuario from './../pages/Login/Perfil';
import Notificacoes from './../pages/Notificacoes';
import Recados from './../pages/Recados';
import Uploads from './../pages/Uploads';
import ActiveConnections from './../pages/WebSocketManager';
import { GraficosPacientes } from './../pages/Relatorios/Graficos/Pacientes';
import PadraoDocumento from './../pages/Parametros/PadraoDocumento';
import Postman from './../pages/Desenvolvedor/Postman';
import DashboardPadrao from './../pages/Desenvolvedor';

import TipoDemandas from 'pages/AdminDashboard/Configuracoes/TipoDemanda';
import GeradorDeDocumento from 'pages/GeradorDeDocumento';
import RelatoriosAtendimentos from 'pages/Relatorios/Atendimentos';
import PendenciasPagamento from 'components/PendenciasPagamento';
import OfflineIndexDev from 'pages/Desenvolvedor/offline-index-dev';
import DragAndDrop from 'components/DragAndDrop';
import { getTerapeutaPaths } from './terapeuta-route';
import { getResponsavelPaths } from './responsavel-route';
import { getAuditorPaths } from './auditor-route';
import Documentacao from 'components/Documentacao';
import Logs from 'pages/Logs';
import LogModels from 'pages/Logs/LogModels';
import TesteVirtualizador from './../pages/Testes';
import AtendimentosEGlosas from 'pages/AtendimentosEGlosas';

const FuncionariosRoutes = (tipo_user) => {
    let filterLinks = [];
    const linkPrefix = `/dashboard/${tipo_user}`;
    const links = [
        { path: '', component: <DashboardPadrao /> },
        { path: 'documentos-pendentes/usuario/:id', component: <DocumentoAssinatura /> },
        { path: 'documentos-pendentes/usuario', component: <DocumentosPendentes /> },
        { path: 'agenda', component: <AgendaSemanal /> },
        { path: 'agenda/controlediario', component: <ControleDiario /> },
        { path: 'agenda/terapeutas', component: <AgendaTerapeutas /> },
        { path: 'agenda/listadeespera', component: <ListaEspera /> },
        { path: 'agenda/areacompartilhada', component: <AgendaAreaCompartilhada /> },
        { path: 'terapeutas', component: <ListagemTerapeutas /> },
        { path: 'terapeutas/glosas', component: <ListagemDeGlosas /> },
        { path: 'terapeutas/novo', component: <CadastroPerfilTerapeuta /> },
        { path: 'terapeuta/perfil/:id', component: <CadastroPerfilTerapeuta /> },
        { path: 'terapeuta/agenda/:id', component: <AgendaTerapeutaAdmin /> },
        { path: 'guias', component: <Guias /> },
        { path: 'pacientes', component: <ListagemPacientes /> },
        { path: 'pacientes/novo', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/novo/:lista_id', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/perfil/:id', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/prontuarios', component: <Prontuarios /> },
        { path: 'pacientes/prontuarios/:id', component: <Prontuarios /> },
        { path: 'pacientes/pesquisar', component: <Pesquisar /> },
        { path: 'funcionarios', component: <Funcionarios /> },
        { path: 'planodesaude', component: <PlanoDeSaudeAdmin /> },
        { path: 'relatorios', component: <RelatóriosEstrategicos /> },
        { path: 'relatorios/graficos', component: <GraficosPacientes /> },
        { path: 'relatorios/atendimentos', component: <RelatoriosAtendimentos /> },
        { path: 'pendencias-pagamento', component: <PendenciasPagamento /> },
        { path: 'atendimentos', component: <Atendimentos /> },
        { path: 'atendimentos-e-glosas', component: <AtendimentosEGlosas /> },
        { path: 'configuracoes', component: <ConfiguracoesAdmin /> },
        { path: 'configuracoes/documentos', component: <Documentos /> },
        { path: 'configuracoes/grupopermissoes', component: <GrupoPermissao /> },
        { path: 'configuracoes/tipoatendimento', component: <TiposAtendimento /> },
        { path: 'configuracoes/tipodemanda', component: <TipoDemandas /> },
        { path: 'configuracoes/terapias', component: <Terapias /> },
        { path: 'configuracoes/especialidades', component: <Especialidade /> },
        { path: 'configuracoes/modalidades', component: <Modalidade /> },
        { path: 'configuracoes/salas', component: <Sala /> },
        { path: 'configuracoes/estacoes', component: <Estacao /> },
        { path: 'configuracoes/areacompartilhada', component: <AreaCompartilhada /> },
        { path: 'configuracoes/tiposavaliacao', component: <TiposAvaliacao /> },
        { path: 'configuracoes/status', component: <Status /> },
        { path: 'configuracoes/diagnosticos', component: <Diagnosticos /> },
        { path: 'configuracoes/horarios-atendimento', component: <HorariosAtendimento /> },
        { path: 'gerador-de-documento/:id', component: <GeradorDeDocumento /> },
        { path: 'padrao', component: <DashboardPadrao /> },
        { path: 'd', component: <OfflineIndexDev /> },
        { path: 'dnd-testes', component: <DragAndDrop /> },
        { path: 'testes', component: <Testes /> },
        { path: 'testes2', component: <Testes2 /> },
        { path: 'testes3', component: <TesteVirtualizador /> },
        { path: 'homem-postagem', component: <Postman /> },
        { path: 'websocket', component: <ActiveConnections /> },
        { path: 'uploads', component: <Uploads /> },
        { path: 'padrao-documento', component: <PadraoDocumento /> },
        { path: 'notificacoes', component: <Notificacoes /> },
        { path: 'notificacoes/recados', component: <Recados /> },
        { path: 'documentacao', component: <Documentacao /> },
        { path: 'logs', component: <Logs /> },
        { path: 'logs/logs-models', component: <LogModels /> },
        { path: 'logs/logs-models/:id', component: <LogModels /> },
    ];

    switch (tipo_user) {
        case 'terapeuta':
            filterLinks = getTerapeutaPaths();
            break;
        case 'responsavel':
            filterLinks = getResponsavelPaths();
            break;
        case 'auditor':
            filterLinks = getAuditorPaths();
            break;
        default:
            break;
    }

    const filteredLinks = filterLinks.length > 0
        ? links.filter(link => filterLinks.includes(link.path))
        : links;

    return filteredLinks.map((link, index) => (
        <Route key={`funcionario-route-${index}`} path={`${linkPrefix}/${link.path}`} element={link.component} />
    ));
};

export default FuncionariosRoutes;
