import React, { useEffect } from 'react';
import { Delete, Modal } from '../../../../../components/Modais/styles';
import { ModalContainer } from '../../../../../components/Modais/PacienteAltasModal/styles';
import { Form } from '@unform/web';
import TextArea from '../../../../../components/TextArea';
import { useRef } from 'react';
import { useSnackbar } from '../../../../../context/snackbar';
import Swal from 'sweetalert2';
import Input from '../../../../../components/Input';
import { useState } from 'react';
import { InputUpload, LabelUpload, Upload, UploadIcon } from '../../../../RecepcaoDashboard/Terapeutas/ModalDiplomas/styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';

function ModalAvaliacoesPaciente({ onClose, paciente }) {

    const [docAvaliacao, setDocAvaliacao] = useState(null);
    const [path, setPath] = useState([]);

    const id = 'modal-terapeuta-anamnese';
    const formRef = useRef();
    const snackbar = useSnackbar();

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleUploadDiploma = (e) => {
        if (docAvaliacao === null) {
            var arquivo = document.getElementById('upload-avaliacao');
            var size = e.target.files[0].size;
            if (size < 40000000) { //40MB         
                setDocAvaliacao(e.target.files[0]);
                setPath([...path, e.target.value]);
            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                arquivo.value = '';
            }
            e.preventDefault();
        }
        else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'error');
        }
    };

    const handleDeleteUpload = () => {
        setDocAvaliacao(null);
        path.splice(-1);
    };

    const handleSubmit = (data) => {

        if (data.desenvolvimento_social === '' || data.fala_linguagem === '', data.alimentacao === '' || data.historico === '') {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            const submitData = {
                avaliacao: data.avaliacao,
                data_validade: data.alimentacao,
                arquivo: data.fala_linguagem,
                paciente_id: paciente.id,
            };
            // TODO: Criar tabela que recebe as informações da avaliação.
            /* PacienteService.update(submitData, paciente.id_paciente).then(() => {
                snackbar.displayMessage('Cadastro feito com sucesso!', 'success');
                onClose();
            }).catch(e => {
                Swal.fire('Houve um problema ao fazer o cadastro!', '', 'error');
            }); */
        };
    };

    useEffect(() => {

    }, []);

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <ModalContainer style={{ overflowY: 'auto' }}>
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '25px', fontSize: '25px', top: '-10px'
                    }} onClick={() => onClose()}>
                        x
                    </label>
                </div>
                <div className='d-flex align-items.center justify-content-center'>
                    <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Avaliação do paciente</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className='flex-column px-5' style={{ fontSize: '15px', color: '#4906AB', fontWeight: '600' }}>
                        <div>
                            <TextArea title="Avaliação:" name="avaliacao" />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '40%' }}>
                                <Input title='Data de validade:' name="data_validade" type='date' />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                                <div style={{ marginTop: 5, marginLeft: 5, }}>
                                    <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
                                </div>
                                <div style={{ flexDirection: 'column' }}>
                                    <label htmlFor="upload-avaliacao" style={LabelUpload}>Anexar Arquivo
                                        <input type="file" id="upload-avaliacao" style={InputUpload}
                                            onChange={handleUploadDiploma} />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            {path.map((item) => {
                                return <span style={Upload}>{item}
                                    <div style={{}}>
                                        <span style={Delete} onClick={handleDeleteUpload}>
                                            x
                                        </span>
                                    </div>
                                </span>;
                            })}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button className='m-3 align-self-center' style={{
                            backgroundColor: '#7340BD',
                            color: '#FFFFFF',
                           
                            border: 'none',
                            padding: '5px 22px',
                            borderRadius: '10px',
                        }} onClick={() => onClose()}>
                            Voltar
                        </button>
                        <button className='m-3 align-self-center' style={{
                            backgroundColor: '#7340BD',
                            color: '#FFFFFF',
                           
                            border: 'none',
                            padding: '5px 22px',
                            borderRadius: '10px',
                        }} type='submit'>
                            Confirmar
                        </button>
                    </div>
                </Form>
            </ModalContainer>
        </Modal>
    );
}

export default ModalAvaliacoesPaciente;