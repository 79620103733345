import React, { useState, useEffect } from 'react';
import Terapeuta from '../../../layouts/Terapeuta';
import { Container, ContainerSub, FormField } from './styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import api from '../../../services/api';
import moment from 'moment';
import { TableUltimosAtendimentos } from './TableRelatorios';
import { useAuth } from '../../../context/auth';
import A4Page from 'components/Folha/A4-Retrato';

function TerapeutaRelatorios() {
    const [dropDown, setDropDown] = useState(false);
    const [relatorios, setRelatorios] = useState([]);
    const [filterDate, setFilterDate] = useState(moment().subtract(15, 'days').format('YYYY-MM-DD'));
    const [statusAtendimento, setStatusAtendimento] = useState([]);

    const { user } = useAuth();

    const getStatusAtendimento = () => {
        api.get('/api/status-atendimento').then(response => {
            let statusList = response.data;
            statusList = statusList.map(status => {
                return {
                    value: status.id,
                    label: status.status,
                };
            });
            setStatusAtendimento(statusList);
        });
    };

    function handleFilterChange(days) {
        setFilterDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
    }

    const getRelatorios = () => {
        try {
            let url = `api/atendimento?filter[terapeuta_id]=${user.terapeuta[0].id}&with=estacao,estacao.sala,terapium,status_atendimento,evolucao_pacientes,terapia_paciente`;
            //let url = `api/atendimento?filter[start_after]=${filterDate}&filter[terapeuta_id]=${user.terapeuta[0].id}&with=estacao,terapium,status_atendimento,evolucao_pacientes`;
            api.get(url).then(({ data }) => {
                let relatorioOptions = [];
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                data.forEach((atendimento) => {
                    relatorioOptions.push({
                        id_paciente: atendimento.paciente.id,
                        data_atendimento: moment(atendimento.data_atendimento_real).format('DD/MM/YYYY') + ' - ' + moment(atendimento.data_atendimento_final.split('T')[1], 'HH:mm:ss').format('HH:mm'),
                        estacao: atendimento.estacao.estacao,
                        sala: atendimento.estacao.sala.sala,
                        nome: atendimento.paciente.nome,
                        identificador: atendimento.paciente.identificador,
                        plano_saude: atendimento.terapia_paciente.plano_saude.plano,
                        terapia: atendimento.terapia_real.terapia,
                        especialidade: atendimento.especialidade_real.especialidade,
                        status: atendimento.status_atendimento ? atendimento.status_atendimento.status : '',
                        evolucao_paciente: atendimento.status_atendimento ? atendimento.evolucao_pacientes[0] : '',
                    });
                });
                setRelatorios(relatorioOptions);
            });
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
    };

    useEffect(() => {
        getRelatorios();
    }, [filterDate]);

    useEffect(() => {
        getStatusAtendimento();
    }, []);

    return (
        <>
            <Terapeuta>
                <Container>
                    <ContainerSub>
                        <FormField>
                            <span style={{
                                color: '#4906AB',
                                fontSize: '2.0rem',
                               
                                fontWeight: '800',
                                marginBottom: '2rem'
                            }}>
                                Últimos atendimentos
                            </span>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                                <span style={{ color: '#4906AB', fontWeight: '600' }}>Filtro</span>
                                <div onClick={() => dropDown ? setDropDown(false) : setDropDown(true)}
                                    style={{
                                        fontSize: '15', backgroundColor: '#6A3BAF', color: '#FFFFFF',
                                        border: 'none', borderRadius: 8, padding: '3px 30px'
                                    }}><span className='ms-1' style={{ cursor: 'pointer' }}>Selecionar {dropDown ? <IoIosArrowDown className='ms-3' /> : <IoIosArrowUp className='ms-3' />}</span>
                                    {dropDown ?
                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <div onClick={() => handleFilterChange(15)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 15 dias</span></div>
                                            <div onClick={() => handleFilterChange(30)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 30 dias</span></div>
                                            <div onClick={() => handleFilterChange(60)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 60 dias</span></div>
                                            <div onClick={() => handleFilterChange(90)} style={{ cursor: 'pointer' }} className='text-decoration-underline mb-1'><span>Últimos 90 dias</span></div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div style={{ maxWidth: '100vw', marginBottom: 50, overflow: 'auto' }}>
                                <TableUltimosAtendimentos source={relatorios} statusAtendimento={statusAtendimento ? statusAtendimento : null} />
                            </div>
                        </FormField>
                    </ContainerSub>
                </Container>
            </Terapeuta>
        </>
    );
}

export default TerapeutaRelatorios;