import React, { useMemo } from 'react';
import { AiFillFileAdd, AiOutlineDelete, AiOutlineEdit, AiOutlineFileAdd, } from 'react-icons/ai';
import Swal from 'sweetalert2';
import GrupoPermissoesService from '../../../../../services/GrupoPermissoesService';
import { TableDefaultPagination } from '../../../../../components/TableDefaultPagination';
import Button from 'components/Button';

export default function TableGrupoPermissao({ source, callback, openModalAssoc, editModal, handleNewGrupoDePermissao }) {

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                GrupoPermissoesService.destroy(id)
                    .then(() => {
                        Swal.fire('Registro removido com sucesso!', '', 'success');
                        callback();
                    })
                    .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Grupo: ',
                accessor: 'name',
                filterable: true,
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (
                        <div className="d-flex justify-content-center align-items-center">
                            <button className="btn-icone" type="button" onClick={() => handleDelete(cell.row.original.id)} >
                                <AiOutlineDelete className="font-roxo" size={22} />
                            </button>
                            <button className="btn-icone" type="button" onClick={() => editModal(cell.row.original)} >
                                <AiOutlineEdit className="font-roxo" size={22} />
                            </button>
                            <button className="btn-icone" type="button" onClick={() => openModalAssoc(cell.row.original.id)} >
                                <AiOutlineFileAdd size={22} />
                            </button>
                        </div>
                    )
                )
            }
        ]
    );

    return (
        <TableDefaultPagination columns={columns} searchBar source={source}
            createButton={
                <Button className='w-300px' onClick={() => handleNewGrupoDePermissao()}>
                    <AiFillFileAdd style={{ marginRight: 5 }} />
                    Novo Grupo de Permissões
                </Button>
            } />
    );
}
