import api from './api';

class PacienteService {
    pacienteOptions;
    constructor(pacientes) {
        this.pacienteOptions = pacientes;
    }

    static index = (params) => {
        return api.get(`api/pacientes?${params}`);
    };

    static find = (id, params) => {
        if (params) {
            return api.get(`api/pacientes/${id}?${params}`);
        } else {
            return api.get(`api/pacientes/${id}`);
        }
    };

    static store = (obj, returnData = false) => {
        /* exemplo
        {"id_plano_saude":1,"nome":"numquam","identificador":"odio","status":"eligendi","nascimento":"1971-06-21","cpf":"aut","rg":"quisquam","endereco":"voluptates","estado":"ad","complemento":"quod","bairro":"qui","cep":9061,"resp_endereco":true,"foto_perfil":"qui","historico":"Natus minima natus et eligendi omnis est. Voluptatem alias nesciunt nesciunt voluptatum qui hic aliquid. Est repellat dolorem id omnis fugiat.","desenvolvimento_social":"Impedit perferendis corrupti consequatur nobis deserunt officia provident veniam. Deserunt corrupti beatae laudantium id ut. Non qui quo similique at.","fala_linguagem":"Occaecati voluptatem quis porro hic earum omnis. Nulla quisquam necessitatibus asperiores cumque quo. Qui sed iste minima.","alimentacao":"Ullam eum nobis quasi. Aut consequatur unde blanditiis voluptates voluptatibus. Nisi eum deserunt perspiciatis incidunt voluptatum quisquam quas. Aspernatur qui esse accusantium quis laborum amet."}
        */
        if (returnData)
            return api.post('api/pacientes', obj).then(async () => {
                let paciente = await api.get(`api/pacientes?filter[identificador]=${obj.identificador}`).then(({ data }) => data[0]);
                return paciente;
            });
        else
            return api.post('api/pacientes', obj);
    };

    static show = (id, options = '') => {
        return api.get(`api/paciente/${id}?${options}`);
    };

    static getByIdFilterPlano = (id, plano_saude_id, options = '') => {
        return api.get(`api/paciente/filter/plano-saude-id/${plano_saude_id}?paciente_id=${id}&${options}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"id_plano_saude":1,"nome":"autem","identificador":"recusandae","status":"velit","nascimento":"1978-07-17","cpf":"quod","rg":"fugiat","endereco":"molestiae","estado":"aut","complemento":"expedita","bairro":"aperiam","cep":73379221,"resp_endereco":false,"foto_perfil":"at","historico":"Rem quaerat aliquid ut ipsa. Nobis est quam ea ducimus. Et architecto incidunt cumque excepturi ullam ea eum.","desenvolvimento_social":"Placeat labore aut ipsum eveniet quia. Id sed eius rerum blanditiis distinctio quaerat. Tempore sunt ut illum. Voluptatem aut aut deleniti excepturi.","fala_linguagem":"Quia eos ut quidem laudantium beatae ut illum. Voluptate dolorem aut in qui. Quia sapiente itaque earum sed. Aut provident provident est molestias.","alimentacao":"Quae fugit consequatur fuga assumenda et in. Et dicta sed incidunt autem nisi id. Vitae voluptate ea sint iusto reiciendis."}
        */

        return api.patch(`api/pacientes/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/pacientes/${id}`);
    };

    static bloqueiaAcesso = (id) => {
        return api.put(`api/pacientes/${id}`, { status: 'Bloqueado' });
    };
    /**
     * Função que retora todos os pacientes que tem atendimentos o terapeuta do id passado no parâmetro.
     * @param {Number} terapeuta_id Identificador do terapeuta.
     * @param {Boolean} orderBy Função define se sera ordenado por ordem alfabética, ou não.
     * @return {Array}
     */
    static getPacienteByTerapeuta = async (terapeuta_id, orderBy = false) => {
        return await api.get(`api/paciente/list/by/terapeuta/${terapeuta_id}?id=${terapeuta_id}`).then(({ data }) => {
            if (orderBy) {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
            }
            return data;
        });
    };
    /**
     * Função que confere a disponibilidade do paciente no horario através do identificador.
     * @param {Number} paciente_id Identificador do paciente.
     * @param {String} horario Horario que sera utilizado para conferir a disponibilidade.
     * @param {Number} atendimentoId Id do atendimento que deve ser excluído do teste.
     * @param {Array|Object} atendimentos Listagem de atendimentos que sera usada para fazer o teste.
     * @returns {boolean}
     */
    static checkAtendimentosPaciente = (paciente_id, horario, atendimentoId, atendimentos) => {
        let isPaciente = false;
        atendimentos.forEach((atendimento) => {
            if (atendimento.paciente_id === paciente_id && atendimento.data_atendimento_inicial === horario._i && atendimento.id !== atendimentoId)
                isPaciente = true;
        });
        return isPaciente;
    };

    static customPacienteFilter = (option, inputValue) => {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (this.pacienteOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };

    static adicionaStatusIndisponivel = (pacientes, horario, atendimentoId, atendimentos) => {
        /**
         * Edita uma string para substituir ou adicionar "(Indisponível)" conforme necessário.
         *
         * @param {string} input - A string a ser editada.
         * @returns {string} - A string editada com "(Indisponível)".
         */
        function editarString(input) {
            // Verifica se a string já contém parênteses
            if (input.includes('(') && input.includes(')')) {
                // Substitui o conteúdo dos parênteses por "Indisponível"
                return input.replace(/\(.*\)/, '(Indisponível)');
            } else {
                // Se não houver parênteses, adiciona "(Indisponível)" no final da string
                return `${input} (Indisponível)`;
            }
        }
        pacientes.map((paciente) => {
            if (this.checkAtendimentosPaciente(paciente.id, horario, atendimentoId, atendimentos) === false) {
                if (paciente.status === 'Ativo') {
                    return paciente;
                } else {
                    return paciente;
                }
            } else {
                return ({ ...paciente, label: editarString(paciente.label) });
            }
        });
        pacientes.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        return pacientes.sort();
    };
}

export default PacienteService;
