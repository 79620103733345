import React from 'react';
import Container from '../components/Container';
import Header from '../components/Header';

const Admin = ({ children }) => (
    <>
        <Header />
        <Container>
            {children}
        </Container>
    </>
);

export default Admin;