import Recepcao from '../../../layouts/Recepcao';
import React, {useEffect, useState} from 'react';
import {
  DivListaDocumentos,
  DivPrincipal,
  ItemLista,
  NomeDocumento,
  ParagrafoInformativoNumeroDocumentos,
  PesquisarDocumentoStyle,
} from './styles';
import {Link} from 'react-router-dom';
import {useAuth} from '../../../context/auth';
import api from '../../../services/api';
import DocumentoVisualizacaoAssinaturaModal from '../../../components/Modais/DocumentoVisualizacaoAssinaturaModal';
import baseURL from '../../../services/baseUrl';
import { TableDocumentosAssinados } from './TableDocumentosAssinados';
import { Container } from '../Terapeutas/styles';

const DocumentosAssinados = () => {

  const { user } = useAuth();
  const [documentos, setDocumentos] = useState(null);
  const [documentosOriginal, setDocumentosOriginal] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterValue, setFilter] = useState('');
  const [openModalDocumento, setOpenModalDocumento] = useState(false);
  const [modalDocumento, setModalDocumento] = useState(null);

  const handleSeachDocumentos = (data) => {
    setFilter(data);
    setDocumentos(documentosOriginal);
    setDocumentos((documento) =>
        documento.filter((item) => item.documentos_administrativo.nome_documento.toString().toLowerCase().includes(data.toString().toLowerCase()))
    );
};

  useEffect(() => {
    api.get(`/api/${user.tipo_user}-assinaturas?with=documentos_administrativo`)
        .then(({data}) => {
          data.sort((a, b) => {
            return new Date(b.assinado_dia) - new Date(a.assinado_dia);
          });
          setDocumentos(data);
          setDocumentosOriginal(data);
        });
  }, []);

  useEffect(() => {
    if (documentos === null) return;
    setIsLoaded(true);
  }, [documentos]);

  const handleOpenModalDocumento = (documento) => {
    setOpenModalDocumento(true);
    setModalDocumento(documento);
  };

  return (
    <div>
        <Container>
          <div style={{ textAlign: 'center', color: '#7340BD', fontSize: '2rem', marginTop: 50, marginBottom: 20}}>
            <span style={{ fontWeight: 'bold' }}>Histórico de Documentos</span>
          </div>
            {isLoaded ?
                <>
                <div >
                  <div style={{alignItems: 'center', marginBottom: 20}}>
                      <div style={{ paddingBottom: 20, paddingTop: 10, }}>
                          <input style={PesquisarDocumentoStyle} placeholder={'Pesquisar Documento por Nome'}
                              value={filterValue || ''} onChange={(e) => {handleSeachDocumentos(e.target.value);}}/>
                      </div>
                  </div>
                  <div style={{marginBottom: 50,}}>
                    <TableDocumentosAssinados source={documentos} user_id={user.id} setModalDocumento={setModalDocumento}
                      setOpenModalDocumento={setOpenModalDocumento}/>
                  </div>
                </div>
                </>
                :
                <span style={{ display: 'flex', justifyContent: 'center' }}>Sem documentos assinados.</span>
            }

            {openModalDocumento && <DocumentoVisualizacaoAssinaturaModal onClose={() => setOpenModalDocumento(false)} documento={modalDocumento} />}
        </Container>
    </div>
        
  );
};

export default DocumentosAssinados;
