import { cpf, cnpj } from 'cpf-cnpj-validator';
import api from './api';
const errorStype = {
    borderColor: 'rgb(255, 47, 47)',
    color: 'rgb(128, 0, 0)',
};
const sucessStype = {
    borderColor: 'greenyellow',
    color: 'green',
};
export class CPFService {
    static ValidaCPF(value) {
        if (value) {
            let isValid = cpf.isValid(value);
            let response = { isValid: isValid, style: isValid ? sucessStype : errorStype };
            return response;
        } else {
            let response = { isValid: false, style: errorStype };
            return response;
        }
    }
    static ValidaCNPJ(value) {
        if (value) {
            let isValid = cnpj.isValid(value);
            let response = { isValid: isValid, style: isValid ? sucessStype : errorStype };
            return response;
        } else {
            let response = { isValid: false, style: errorStype };
            return response;
        }
    }
}

export class UserServices {
    /**
     * Função que verifica se o email já existe no banco de dados
     * @param {String} email Email a ser comparado com o resto
     * @param {int} id Id da unica exceção de comparação
     * @returns {Array}
     */
    static async validaEmail(email, id = null) {
        if (email !== undefined && email !== null) {
            let isValid;
            await api.get('api/user?filter[email]=' + email.trim().replace(/\s+/g, ''))
                .then(({ data }) => {
                    if (id !== null) {
                        data = data.filter((e) => {
                            return (e.id !== id && e.email === email);
                        });
                    }else{
                        data = data.filter((e) => {
                            return (e.email === email);
                        });
                    }
                    isValid = (data.length === 0);
                });
            let response = { isValid: isValid, style: isValid ? sucessStype : errorStype };
            return response;
        } else {
            let response = { isValid: false, style: errorStype };
            return response;
        }
    }
}
