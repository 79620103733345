import useIcons from 'context/icons';
import RecadosCard from 'pages/DashboardPadrao/RecadosCard';
import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import bgImage from 'assets/feliznatal.jpg';
import 'styles/reparticoes-css/documentacao.css';
import { Mostrador, MostradoresColoridos } from 'components/MostradoresColoridos';
import AcessoRapido from 'components/AcessoRapido';
import AdicionarAgendamentoExemplo from './AdicionarAgendamentoExemplo';
import Input, { InputArquivo, RichTextEditor } from 'components/Input';
import { Form } from '@unform/web';
import Select from 'components/Select';
import { BasicDemo } from 'components/Select/MultiSelect';
import Swal from 'sweetalert2';

const Documentacao = () => {
    //#region HOOKS
    const { iconArray } = useIcons();
    //#endregion
    //#region FAKES
    const [fakePath, setFakePath] = useState([]);
    //#endregion
    //#region FUNCOES DE EXEMPLO
    const handleDeleteUpload = useCallback(() => {
        setFakePath(prev => prev.splice(-1));
    }, []);

    const handleChangeArquivo = useCallback((e) => {
        let arquivo = document.getElementById('exemplo-upload');
        const size = e.target.files[0].size;
        if (size < 40000000) { //40MB         
            setFakePath((prev) => [...prev, e.target.value]);
        } else {
            Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
            arquivo.value = '';
        }
        e.preventDefault();
    }, []);
    //#endregion
    //#region CONTEUDO
    const prefixos = useMemo(() => [
        {
            nome: 'FLU',
            descricao_curta: "Fluxos: Perguntas sobre o fluxo do sistema.",
            descricao: <>
                Se trata de alguma pergunta sobre o algum fluxo do sistema. Por exemplo: <i>Quando eu clicar nesse botão, o que vai acontecer?</i>
            </>
        },
        {
            nome: 'ERR',
            descricao_curta: "Erros: Erros/bugs/problemas que já foram resolvidos.",
            descricao: "São erros que podem ter acontecido e já resolvidos de alguma maneira."
        },
        {
            nome: 'INFO',
            descricao_curta: "Informação: Informações específicas sobre o sistema em geral.",
            descricao: "São informações específicas sobre alguma coisa, algum aviso do passado, alguma novidade, alguma nova funcionalidade, alguma correção importante ou mudança importante."
        },
        {
            nome: 'FUN',
            descricao_curta: "Funcionalidades: Detalhamentos sobre funcionalidades.",
            descricao: <>
                Dúvida sobre alguma funcionalidade. Por exemplo: <i>Como que esse botão funciona?</i> ou <i>Como que eu faço pra limpar esse campo?</i>
            </>
        },
    ], []);
    const topicos = useMemo(() => [
        {
            id: 1, title: 'Introdução', category: 'API', tags: 'introdução,inicial,basico,fun:documentacao,flu:faq,flu:pergunta,flu:duvida',
            content: <div>
                <p>Nesse tópico de introdução, você verá algumas noções básicas do sistema.</p>
                <p>Nessa tela de documentação, você encontrará algumas funcionalidades importantes:</p>
                <ul>
                    <li key={'topico1-sub1'}>
                        <b>Como usar a documentação?</b>
                        <p>Essa tela de documentação é bastante simples. A única coisa que você precisa fazer é pesquisar o que você quer saber no campo de pesquisa que os resultados já aparecerão.</p>
                        <p>Você pode utilizar algumas pesquisas específicas para melhorar seus resultados. Você pode adicionar alguns prefixos na pesquisa, são eles:
                            <ul>
                                {prefixos.map((map, index) => <li key={`topico1-sub1-sub${index}`}><b>'{map.nome}:'</b> - {map.descricao}</li>)}
                            </ul>
                        </p>
                    </li>
                    <li key={'topico1-sub2'}>
                        <b>Perguntas, problemas e soluções:</b>
                        <p>
                            Você pode enviar uma perguta para o nosso time de desenvolvimento caso não tenha encontrado aqui a sua resposta. Lembre-se de que nosso time trabalha sempre para trazer aprimoramentos para o nosso sistema, então sempre tente encontrar a sua dúvida aqui antes de enviar uma pergunta.
                        </p>
                    </li>
                </ul>
            </div>
        },
        {
            id: 2, title: 'Básicos do Sistema', category: '', tags: 'basico,flu:introducao,fun:campos,fun:funcionalidades,info:sistema',
            content: 'Neste tópico, abrodaremos aspectos básicos do sistema.',
            subTopicos: [
                {
                    id: 1, title: 'Campos e Funcionalidades', category: 'FRONT', tags: 'fun:campos,fun:funcionalidades,info:uso,flu:faq',
                    content: 'Vamos passar por todos os tipos de campos que você pode encontrar no sistema, e cada uma de suas funcionalidades:',
                    subTopicos: [
                        {
                            id: 1, title: 'Campo Padrão', category: 'FRONT', tags: 'campos,fun:campo-texto,flu:faq,info:campo-texto',
                            content: (
                                <Form className='w-100per'>
                                    <p>Este é o tipo de campo mais comum que você verá no sistema. Ele pode aparecer em diferentes formatos para inserir variados tipos de informações:</p>
                                    <ul>
                                        <li>
                                            <p>Campo de texto: Este campo permite digitar qualquer informação, como palavras, números ou símbolos. Use este espaço para dados que não precisam de um formato específico.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Input name={'exemplo-texto'} title='Campo de texto' placeholder='Digite algo aqui...' />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Campo de número: Aqui, só é possível inserir números. Digite valores numéricos como quantidades, preços ou valores específicos.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Input name={'exemplo-number'} type={'number'} title='Campo numérico' placeholder='Digite algo aqui...' />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Campo de data: Para inserir uma data. Clique no campo para abrir um calendário e selecione a data desejada ou digite a data diretamente (dia/mês/ano).</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Input name={'exemplo-date'} type={'date'} title='Campo de data' placeholder='Digite algo aqui...' />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Campo de hora: Use este campo para registrar um horário específico, como uma hora de início ou fim. Você pode clicar para selecionar o horário ou digitar diretamente.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Input name={'exemplo-time'} type={'time'} title='Campo de hora' placeholder='Digite algo aqui...' />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Campo obrigatório: Indicado pelo símbolo '*', este campo é obrigatório e deve ser preenchido. Caso deixe em branco, o formulário não será enviado e o campo será destacado.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Input name={'exemplo-required'} title='Campo obrigatório' placeholder='Digite algo aqui...' required />
                                            </div>
                                        </li>
                                    </ul>
                                </Form>
                            ),
                        },
                        {
                            id: 2, title: 'Campo de Seleção', category: 'FRONT', tags: 'campos,fun:campo-selecao,info:campo-selecao',
                            content: (
                                <Form className='w-100per'>
                                    <p>Este campo permite escolher uma ou mais opções de uma lista predefinida:</p>
                                    <ul>
                                        <li>
                                            <p>Seleção de opções: Selecione uma única opção entre as disponíveis, clicando na lista. Ideal para escolher uma única resposta ou categoria.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Select
                                                    name={'exemplo-select'}
                                                    title='Campo de seleção'
                                                    type='select'
                                                    options={[
                                                        { value: '1', label: 'Opção 1' },
                                                        { value: '2', label: 'Opção 2' },
                                                        { value: '3', label: 'Opção 3' },
                                                    ]}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Seleção de múltiplas opções: Permite selecionar várias opções ao mesmo tempo. Clique em cada opção que deseja escolher para que todas sejam incluídas.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <Select
                                                    name={'exemplo-select-multi'}
                                                    title='Campo de seleção múltipla'
                                                    type='select'
                                                    options={[
                                                        { value: '1', label: 'Opção 1' },
                                                        { value: '2', label: 'Opção 2' },
                                                        { value: '3', label: 'Opção 3' },
                                                    ]}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <p>Outra forma de seleção múltipla: Aqui, você também pode escolher várias opções ao mesmo tempo, mas com uma interface diferente.</p>
                                            <div className={'w-100per recorte-exemplo px-4'}>
                                                <span className='legenda'>Exemplo:</span>
                                                <BasicDemo name={'exemplo-select-multi2'} title='Campo de seleção múltipla' />
                                            </div>
                                        </li>
                                    </ul>
                                </Form>
                            ),
                        },
                        {
                            id: 3, title: 'Campo de Texto Complexo', category: 'FRONT', tags: 'campos,fun:campo-texto-complexo,info:campo-texto-complexo',
                            content: (
                                <Form className='w-100per'>
                                    <p>Esse campo permite adicionar texto com diferentes formatações, como negrito, itálico, listas e links. Útil para textos longos ou detalhados onde é necessário organizar melhor as informações.</p>
                                    <div className={'w-100per recorte-exemplo px-4 h-200px'}>
                                        <span className='legenda'>Exemplo:</span>
                                        <RichTextEditor name={'exemplo-texto-complexo'} title='Campo de texto complexo:' />
                                    </div>
                                    <p>Para utiliza-lo, você deve utilizar os botões que aparecem na caixa de ferramentas. Você pode selecionar o texto, e usar os botões para formata-lo.</p>
                                </Form>
                            ),
                        },
                        {
                            id: 4, title: 'Campo de Upload', category: 'FRONT', tags: 'campos,fun:campo-upload,info:arquivo',
                            content: (
                                <Form className='w-100per'>
                                    <p>Esse campo permite enviar arquivos para o sistema. Você pode enviar arquivos de diferentes formatos, como imagens, documentos, planilhas, entre outros.</p>
                                    <div className={'w-100per recorte-exemplo px-4'}>
                                        <span className='legenda'>Exemplo:</span>
                                        <InputArquivo id="exemplo-upload" name="exemplo-upload" title='Campo de upload' path={fakePath} onChange={handleChangeArquivo} handleDeleteUpload={handleDeleteUpload} />
                                    </div>
                                    <p>Para enviar um arquivo, clique no campo e selecione o arquivo desejado. O arquivo será enviado automaticamente para o sistema.</p>
                                </Form>
                            ),
                        }
                    ],
                },
            ]
        },
        {
            id: 3, title: 'Telas', category: 'FRONT',
            content: 'Neste tópico, abordaremos as todas as telas do sistema. Suas funcionalidades e aplicações.',
            tags: 'telas,funcionalidades,front,acesso_rapido,mostradores,recados,dashboard,documentacao',
            subTopicos: [
                {
                    id: 1, title: 'Dashboard', category: 'API', tags: 'dashboard,mostradores,recados,info:tela-inicial,flu:faq',
                    content: 'A primeira tela que você encontra quando acessa o sistema, é a Dashboard. Esse é o local, onde a maioria das informações importantes do sistema são exibidas. Cada acesso tem sua própria dashboard, por isso, pode ser que varie de acesso pra acesso. De qualquer forma, existem pontos em comum, entre todos os acessos, são estes.',
                    subTopicos: [
                        {
                            id: 1, title: 'Recados', category: 'FRONT', tags: 'recados,info:aviso,flu:info-importante',
                            content: <div className='w-100per'>
                                <p>Recados são mensagens globais para toda a clinica, são usados para certificar de que certas informações importantes, sejam lembradas por todos. Por isso, é sempre importante, da uma olhada nos recados, toda vez que acessar o sistema.</p>
                                <div className={'w-100per recorte-exemplo'}>
                                    <span className='legenda'>Exemplo:</span>
                                    <div className={'w-1000px mx-auto'}>
                                        <RecadosCard title={'Recado'} recado={{
                                            imagem: bgImage,
                                            conteudo: `Nos recados, você sempre vai encontrar uma imagem, e um texto, mostrando do que se trata o recado em questão. Normalmente são informações importantes que você deve saber.`
                                        }} />
                                    </div>
                                </div>
                            </div>,
                        },
                        {
                            id: 2, title: 'Mostradores', category: 'FRONT', tags: 'mostradores,info:indicadores,flu:status,flu:info-basica',
                            content: <div className='w-100per'>
                                <p>Mostradores são indicadores importantes, que você pode ver na sua dashboard. Eles são importantes para você ter uma noção básica do que está acontecendo no sistema. Eles podem variar de acordo com o seu acesso, você pode encontrar:</p>
                                <ul>
                                    <li>Quantidade de atendimentos</li>
                                    <li>Quantidade de pacientes</li>
                                    <li>Quantidade de evoluções pendentes</li>
                                    <li>Quantidade de agendamentos</li>
                                    <li>Quantidade de documentos pendentes</li>
                                </ul>
                                <p>Entre outros...</p>
                                <p>Alguns mostradores, podem conter detalhamentos. Quando você passa o mouse por cima deles uma lista de detalhamentos vai aparecer para baixo.</p>
                                <div className={'w-100per recorte-exemplo'}>
                                    <span className='legenda'>Exemplo:</span>
                                    <div className={'w-1000px mx-auto'}>
                                        <MostradoresColoridos mostradores={[
                                            new Mostrador('Quadrado', 'verde', 720, 'quadrado', null, null),
                                            new Mostrador('Redondo', 'azul', 20, 'circulo', null, null),
                                            new Mostrador('Detalhes', 'amarelo', 5, 'quadrado', null, [
                                                { nome: 'Detalhe 1', valor: 10 },
                                                { nome: 'Detalhe 2', valor: 20 },
                                                { nome: 'Detalhe 3', valor: 30 },
                                            ]),
                                        ]} style={{
                                            borderTopLeftRadius: '5%',
                                            borderTopRightRadius: '5%',
                                            gridArea: 'mostradores',
                                            margin: '0',
                                            padding: '0'
                                        }} titulo={'Mostradores de Exemplo'} id="mostradores" className='mostradores' name="mostradores" />
                                    </div>
                                </div>
                            </div>,
                        },
                        {
                            id: 3, title: 'Acesso Rápido', category: 'FRONT', tags: 'acesso_rapido,fun:atalhos,info:links-importantes,flu:facilidade',
                            content: <div className='w-100per'>
                                <p>Acesso rápido é uma lista de links importantes, que você pode acessar rapidamente. Eles são importantes para você ter acesso rápido a funcionalidades importantes do sistema. Esses links são predefinidos com base no seu tipo de acesso, e cada acesso sem seu grupo de acessos rápidos.</p>
                                <div className={'w-100per recorte-exemplo'}>
                                    <span className='legenda'>Exemplo:</span>
                                    <div className={'mx-auto w-fit-content'}>
                                        <AcessoRapido acessos={[
                                            [
                                                { titulo: 'Link 1', link: '/documentacao', icone: React.cloneElement(iconArray['atendimentos'], { size: 30 }) },
                                                { titulo: 'Link 2', link: '/documentacao', icone: React.cloneElement(iconArray['config'], { size: 30 }) },
                                            ],
                                            [
                                                { titulo: 'Link 3', link: '/documentacao', icone: React.cloneElement(iconArray['graficos'], { size: 30 }) },
                                                { titulo: 'Link 4', link: '/documentacao', icone: React.cloneElement(iconArray['analise'], { size: 30 }) },
                                            ]
                                        ]} loading={false} />
                                    </div>
                                </div>
                            </div>,
                        },
                    ]
                },
                {
                    id: 2, title: 'Agenda Semanal', category: 'FRONT', tags: 'agenda,fun:agendamento,info:agenda-semanal',
                    content: <div className='m-0 p-0'>
                        <p>A agenda semanal uma parte crucial da funcionalidade do sistema. Essa tela possue variás funcionalidades que devem ser levadas em conta, na hora de utilizar.</p>
                    </div>,
                    subTopicos: [
                        {
                            id: 1, title: 'Como agendar?', category: 'FRONT', tags: 'agenda,fun:agendamento,flu:pergunta',
                            content: <div className='m-0 p-0'>
                                <p>Para agendar um atendimento comum, você deve clicar em um espaço vazio na agenda, e preencher os campos necessários. O espaço representa o horário e a sala/estação onde será feito o atendimento. Todo agendamento que for feito no mesmo dia, será apenas carregado na outra semana, e não saem da agenda, até serem excluídos, de forma que seja um agendamento permanente. </p>
                                <p>Essa é o registro, que irá aparecer para preenchimento, quando o espaço for selecionado.</p>
                                <div className={'w-100per recorte-exemplo'}>
                                    <span className='legenda'>Exemplo:</span>
                                    <AdicionarAgendamentoExemplo />
                                </div>
                            </div>
                        },
                    ]
                }
            ]
        }
    ], [prefixos, fakePath, handleDeleteUpload, handleChangeArquivo]);
    //#endregion
    //#region VARIAVEIS
    const [topicosFiltrados, setTopicosFiltrados] = useState(topicos);
    const [topicosAbertos, setTopicosAbertos] = useState(topicos.map(() => false));
    const [navTopicosAbertos, setNavTopicosAbertos] = useState(topicos.map(() => false));
    const [filterKey, setFilterKey] = useState('');
    const [sugestoes, setSugestoes] = useState([]);
    const referenciasDeConteudo = useRef([]);
    const referenciasDeConteudoNav = useRef([]);
    //#endregion
    //#region FUNCOES
    const aplicarSugestao = (nomePrefixo) => {
        setFilterKey(prev => `${nomePrefixo}:${prev}`);
        setSugestoes([]);
    };
    const alteraTopico = (index) => {
        setTopicosAbertos((prevState) =>
            prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
    };
    const alteraTopicoNav = (index) => {
        setNavTopicosAbertos((prevState) =>
            prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
    };
    /**
     * Renderiza os sub-tópicos recursivamente, verificando se há sub-tópicos dentro do objeto `topico`.
     * Caso haja sub-tópicos, a função se chama novamente para renderizá-los, criando uma árvore de tópicos e sub-tópicos.
     * 
     * @param {Object} topico - O objeto tópico que contém sub-tópicos.
     * @returns {JSX.Element|null} Um componente JSX contendo os sub-tópicos, ou null caso não haja sub-tópicos.
     */
    const renderizaSubTopicos = (topico, conteudo = true) => {
        if (topico.subTopicos !== undefined && topico.subTopicos.length > 0) {
            return (
                <div className='w-100per px-6'>
                    <ul className='w-100per'>
                        {topico.subTopicos.filter(testaIncidenciaNivel2).map((map, index1) => {
                            return (
                                <li key={`sub-${index1}`}>
                                    <b>{map.title}:</b><br />
                                    {conteudo && <><p>{map.content}</p><br /></>}
                                    {renderizaSubTopicos(map, conteudo)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
        return null;
    };
    /**
     * Testa se o tópico de nível 1 (direto) contém a palavra-chave de filtragem.
     * Verifica se a palavra-chave aparece no campo `category`, `title` ou `content` do tópico.
     * 
     * @param {Object} topico - O tópico a ser verificado.
     * @returns {boolean} Retorna `true` se a palavra-chave for encontrada no tópico, senão `false`.
     */
    const testaIncidenciaNivel1 = (topico) =>
        topico?.category?.toLowerCase()?.includes(filterKey.toLowerCase()) ||
        topico?.title?.toLowerCase()?.includes(filterKey.toLowerCase()) ||
        topico?.tags?.toLowerCase()?.includes(filterKey.toLowerCase());
    /**
     * Verifica se os sub-tópicos de um tópico atendem ao critério de incidência de nível 2.
     * 
     * @param {Object} topico - O tópico a ser verificado.
     * @returns {boolean} Retorna `true` se houver sub-tópicos que atendem ao critério de incidência.
     */
    const verificaSubTopicos = (topico) => {
        const localSubtopicos = topico?.subTopicos;
        if (localSubtopicos) {
            return localSubtopicos.filter(testaIncidenciaNivel2).length > 0;
        }
        return false;
    };
    /**
     * Testa se um tópico ou seus sub-tópicos contêm a palavra-chave de filtragem.
     * Primeiro verifica o nível 1 e, se não houver incidência, verifica o nível 2 (sub-tópicos).
     * 
     * @param {Object} topico - O tópico a ser testado.
     * @returns {boolean} Retorna `true` se a palavra-chave for encontrada no tópico ou em seus sub-tópicos.
     */
    const testaIncidenciaNivel2 = (topico) => {
        const nivel1 = testaIncidenciaNivel1(topico);
        if (nivel1 === false) {
            const nivel2 = verificaSubTopicos(topico);
            return nivel2;
        }
        return nivel1;
    };
    //#endregion
    //#region HANDLES
    const handleInputChange = (e) => {
        const value = e.target.value;
        setFilterKey(value);

        // Filtra tópicos e sugere prefixos
        if (value) {
            const resultadosFiltrados = topicos.filter(testaIncidenciaNivel2);
            const prefixosDisponiveis = new Set();

            // Verifica cada tópico filtrado e coleta os prefixos disponíveis
            resultadosFiltrados.forEach(topico => {
                if (topico.tags) {
                    topico.tags.split(',').forEach(tag => {
                        const prefixo = tag.split(':')[0];
                        if (prefixos.some(p => p.nome.toLowerCase() === prefixo.toLowerCase())) {
                            prefixosDisponiveis.add(prefixo);
                        }
                    });
                }
            });

            const sugestoesFiltradas = [...prefixosDisponiveis].map(prefixo =>
                prefixos.find(p => p.nome.toLowerCase() === prefixo.toLowerCase())
            );
            setSugestoes(sugestoesFiltradas);
        } else {
            setSugestoes([]);
        }
    };
    /**
     * Filtra os tópicos de acordo com a palavra-chave fornecida.
     * Verifica cada tópico e seus sub-tópicos utilizando o critério de incidência de nível 2.
     * Atualiza o estado com os tópicos filtrados.
     */
    const handleFiltraTopicos = () => {
        const resultadosFiltrados = topicos.filter(testaIncidenciaNivel2);
        setTopicosFiltrados(resultadosFiltrados);
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        referenciasDeConteudo.current = referenciasDeConteudo.current.slice(0, topicos.length);
        referenciasDeConteudoNav.current = referenciasDeConteudoNav.current.slice(0, topicos.length);
    }, [topicos.length]);
    useEffect(() => {
        const handler = setTimeout(() => {
            handleFiltraTopicos();
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [filterKey]);
    //#endregion

    //#region HTML
    return (
        <div className='d-flex flex-nowrap'>
            <div className='w-500px-i'>
                <div className='ns-barra-vertical'>
                    <h3>Tópicos</h3>
                    <ul>
                        {topicosFiltrados.map((topico, index) => (
                            <li key={`nav-${topico.id}`} className='m-0'>
                                <p className='m-0 p-2 d-flex justify-content-between pointer align-items-center border-bottom' onClick={() => alteraTopicoNav(index)} >
                                    {topico.title}
                                    <span style={{
                                        fontSize: '10px',
                                        transition: 'transform 0.3s ease',
                                        transform: `rotate(${navTopicosAbertos[index] ? '180deg' : '0deg'})`,
                                    }}>
                                        {React.cloneElement(iconArray['seta-baixo'], { size: 10 })}
                                    </span>
                                </p>
                                <div ref={(el) => (referenciasDeConteudoNav.current[index] = el)}
                                    className={`tema-verde-claro${navTopicosAbertos[index] ? ' px-1 py-2 pt-0' : ' p-0'}`}
                                    style={{
                                        height: navTopicosAbertos[index] ? `${referenciasDeConteudoNav.current[index]?.scrollHeight}px` : '0px',
                                        overflow: 'hidden',
                                        transition: 'height 0.5s ease, padding .5s ease',
                                        backgroundColor: '#fafafa',
                                    }}>
                                    {renderizaSubTopicos(topico, false)}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className=''>
                <h1 className='font-roxo font-30px text-center w-100 mt-2 mb-4'>Documentação do Sistema</h1>
                <div className='w-100per d-flex justify-content-center'>
                    <div className='input-group mb-0'>
                        <input
                            type='text'
                            className='form-control tema-verde py-2 ps-3 pe-2'
                            placeholder='Pesquisar'
                            aria-label='Pesquisar'
                            aria-describedby='pesquisar-icone'
                            onChange={handleInputChange}
                            onBlur={() => setSugestoes([])}
                            onFocus={handleInputChange}
                            style={{
                                borderTopLeftRadius: '18px',
                                borderBottomLeftRadius: '18px',
                            }}
                            value={filterKey}
                        />
                        <span
                            className='input-group-text tema-verde-i pointer px-4 py-2'
                            id='pesquisar-icone'
                            onClick={(e) => handleFiltraTopicos(e.target.value)}
                            style={{
                                borderTopRightRadius: '18px',
                                borderBottomRightRadius: '18px',
                            }}
                        >
                            {iconArray['pesquisar']}
                        </span>
                    </div>
                </div>
                {sugestoes.length > 0 && (
                    <ul className='ns-suggestions-list'>
                        {sugestoes.map((sugestao, index) => (
                            <li key={`suggestions-list${index}`} onClick={() => aplicarSugestao(sugestao.nome)} className='ns-suggestion-item'>
                                <span>{sugestao.nome}:</span>
                                <p className='font-cinza'>{sugestao.descricao_curta}</p>
                            </li>
                        ))}
                    </ul>
                )}
                <br></br>
                <br></br>

                <p className='font-20px'> Bem vindo a tela de dúvidas do sistema Neuro Sistema! </p>
                <p className='font-20px'> Aqui você tera um conjunto personalizado de informações sobre seu acesso, e tudo que você precisa saber para utilizar o sistema da clínica! </p>
                <p className='font-20px'> Caso não encontre sua dúvida aqui, você pode apertar nesse link: <a href='mailto:sistema@neurointensiva.com'>Enviar Dúvida</a> e enviar sua dúvida diretamente ao nosso time de desenvolvimento. </p>
                <p className='font-20px'></p>
                <p className='font-20px'>Utilize o campo abaixo, para consultar o que você deseja. Digite do que se trata.</p>
                <p className='font-20px'></p>
                {topicosFiltrados.map((topico, index) => (
                    <div key={topico.id} className='m-0'>
                        <h3 className='tema-verde m-0 p-2 d-flex justify-content-between pointer align-items-center border-bottom' onClick={() => alteraTopico(index)} >
                            {topico.title}
                            <span style={{
                                transition: 'transform 0.3s ease',
                                transform: `rotate(${topicosAbertos[index] ? '180deg' : '0deg'})`,
                            }}>
                                {iconArray['seta-baixo']}
                            </span>
                        </h3>
                        <div ref={(el) => (referenciasDeConteudo.current[index] = el)}
                            className={`tema-verde-claro${topicosAbertos[index] ? ' px-4 py-2' : ' p-0'}`}
                            style={{
                                height: topicosAbertos[index] ? `${referenciasDeConteudo.current[index]?.scrollHeight}px` : '0px',
                                overflow: 'hidden',
                                transition: 'height 0.5s ease, padding .5s ease',
                                backgroundColor: '#fafafa',
                            }}>
                            <p className='font-20px'>{topico.content}</p>
                            {renderizaSubTopicos(topico)}
                        </div>
                    </div>
                ))}
            </div>
            <div className='w-500px-i'></div>
        </div>
    );
    //#endregion
};

export default Documentacao;