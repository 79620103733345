import React, { useEffect, useRef, useState } from 'react';
import { Container, Modal } from '../styles';
import { useAtendimentoReposicao } from '../../../context/atendimentoReposicao';
import ModalTitulo from '../../ModalTitulo';
import Input from '../../Input';
import { Form } from '@unform/web';
import { MdOutlinePlayCircleOutline } from 'react-icons/md';
import AtendimentoService from '../../../services/AtendimentoService';
import DiaHoraService from '../../../services/DiaHoraService';
import api from '../../../services/api';
import moment from 'moment/moment';
import Select from '../../Select';
import { useSnackbar } from '../../../context/snackbar';
import { ClipLoader } from 'react-spinners';

const ReposicaoModal = ({ atendimentoId, onClose = () => {}, setHouveAlteracaoStatusEdicao = () => {}}) =>{
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
        const [loading, setLoading] = useState(false);
        const [horarios, setHorarios] = useState([]);
        const [horarioEscolhido, setHorarioEscolhido] = useState(null);
        const [diaSemanaEscolhido, setDiaSemanaEscolhido] = useState(null);
        const [atendimento, setAtendimento] = useState(null);
        const { displayMessage } = useSnackbar();
        const { setAtendimentoReposicaoState } = useAtendimentoReposicao();
        const modalId = 'reposicao-modal';
        const diasSemana = DiaHoraService.getDiasSemana();
        const formRef = useRef(null);
    //#endregion
    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
        const handleOutsideClick = (e) => {
            if (e.target.id === modalId) onClose();
            setAtendimentoReposicaoState(null);
        };
        const handleDiaSemanaChange = (e) => {
            const weekDay = e.value;
            const dataAtual = moment();
            const dataAtendimento = moment(dataAtual).day(weekDay);
            setDiaSemanaEscolhido(dataAtendimento.format('DD/MM/YYYY'));
        };
        const handleHorarioChange = (e) => {
            setHorarioEscolhido(e.value);
        };
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
        const getHorariosDia = () => {
            api.get('/api/horario-atendimento?filter[dia_semana]=1').then(response => {
                let horarios = response.data;
                horarios = horarios.map(horario => {
                    let horaAtual = moment(horario.horario.split('T')[1], 'HH:mm');
                    return {
                        value: horaAtual.format('HH:mm'),
                        label: horaAtual.format('HH:mm') + ' - ' + horaAtual.add(50, 'minutes').format('HH:mm'),
                    };
                });
                setHorarios(horarios);
            });
        };
        const handleSubmit = (data) => {
            const novaDataAtendimentoInicial = moment(diaSemanaEscolhido, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T' + horarioEscolhido + ':00';
            const novaDataAtendimentoFinal = moment(novaDataAtendimentoInicial).add(50, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
            const dadosAgendamento = {
                ...atendimento,
                data_atendimento_inicial: novaDataAtendimentoInicial,
                data_atendimento_final: novaDataAtendimentoFinal,
                tipo_atendimento_id: 3,
            };
            api.post('/api/agendamento', dadosAgendamento)
                .then(response => {
                    api.patch(`/api/atendimento/${atendimentoId}`, { reposicao: response.data.id })
                        .then(response => {
                            displayMessage('Reposição realizada com sucesso!');
                            setHouveAlteracaoStatusEdicao();
                            setAtendimentoReposicaoState(null);
                            onClose();
                        })
                        .catch(error => {
                            displayMessage('Erro ao atualizar atendimento!');
                        });
                })
                .catch(error => {
                    displayMessage('Erro ao criar agendamento!');
                });
        };
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
        useEffect(() => {
            AtendimentoService.show(`${atendimentoId}?with=terapium,tipo_atendimento,terapia_paciente,terapia_paciente.plano_saude`).then((response) => {
                let atendimentoResponse = response.data;
                atendimentoResponse.data_atendimento_numero = DiaHoraService.getDiaMesAnoFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
                atendimentoResponse.data_atendimento_extenso = DiaHoraService.getDiaSemanaFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
                atendimentoResponse.hora_atendimento = DiaHoraService.getHoraFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
                atendimentoResponse.weekday = DiaHoraService.getWeekDayFromDiaSemana(atendimentoResponse.data_atendimento_extenso);
                setHorarioEscolhido(atendimentoResponse.hora_atendimento);
                setDiaSemanaEscolhido(atendimentoResponse.data_atendimento_numero);
                setAtendimento(response.data);
            });
        }, []);
        useEffect(() => {
            if (diaSemanaEscolhido === null) return;
            getHorariosDia();
        }, [diaSemanaEscolhido]);
        useEffect(() => {
            if (atendimento === null) return;
            setLoading(true);
        }, [atendimento]);
    //#endregion    
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
        return (
            <Modal id={modalId} onClick={handleOutsideClick}>
                <Container style={{ backgroundColor: '#F6F6F9', width: '60%' }}>
                    <ModalTitulo>Detalhes Reposição</ModalTitulo>
                    {loading
                        ? <Form onSubmit={handleSubmit} ref={formRef}>
                            <div className='row'>
                                <div className='col-5'>
                                    <Input readOnly={true} title='Nome:' name='paciente_nome' style={{ boxSizing: 'border-box', width: '100%' }} defaultValue={atendimento.paciente.nome} required/>
                                </div>
                                <div className='col-4'>
                                    <Select options={diasSemana} name='dia' label={'Dia:'} onChange={handleDiaSemanaChange} defaultValue={diasSemana.filter(dia => { return dia.value === moment(atendimento.data_atendimento_numero, 'DD/MM/YYYY').weekday(); })}  required/>
                                </div>
                                <div className='col-3'>
                                    <Select options={horarios} name='horario' label={'Horário:'} onChange={handleHorarioChange} defaultValue={horarios.filter(horario => { return horario.value === atendimento.hora_atendimento; })} required/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <Input readOnly={true} title='Plano de Saúde:' name='plano_de_saude' defaultValue={atendimento?.terapia_paciente?.plano_saude?.nome_reduzido} required/>
                                </div>
                                <div className='col-6'>
                                    <Input readOnly={true} title='Terapeuta:' name='terapeuta' style={{ boxSizing: 'border-box', width: '100%' }} defaultValue={atendimento?.terapeutum?.nome} required/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <Input readOnly={true} title='Terapia:' name='terapia' defaultValue={atendimento?.terapium?.terapia} required/>
                                </div>
                                <div className='col-6'>
                                    <Input readOnly={true} title='Tipo de Atendimento:' name='tipo_atendimento' defaultValue={atendimento?.tipo_atendimento.tipo_atendimento} required/>
                                </div>
                            </div>
                            <div className='d-flex justify-between align-center w-100per mt-20px flex-row-reverse'>
                                <button type={'submit'} className='btn-padrao btn-executar'>
                                    <MdOutlinePlayCircleOutline style={{ display: 'inline-block', width: '18px', height: '18px', marginRight: '5px' }} />
                                    Executar
                                </button>
                                <button onClick={() => onClose()} className='btn-padrao'>Voltar</button>
                            </div>
                        </Form>
                        : <div className='w-100per text-center justify-center mt-20px'>
                            <ClipLoader className='m-auto' size={30}></ClipLoader>
                        </div>
                    }
                </Container>
            </Modal>
        );
    //#endregion
};
export default ReposicaoModal;