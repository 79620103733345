import React, {useState} from 'react';
import {Modal} from '../../styles';
import {ModalContainer} from '../../PacienteAltasModal/styles';
import Input from '../../../Input';
import { Form } from '@unform/web';
import TextArea from '../../../TextArea';
import {BsCloudArrowUp} from 'react-icons/bs';
import moment from 'moment';
import { RelatorioGenericoService } from '../../../../services/RelatoriosService';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../context/snackbar';

function RelatorioGeral({ onClose, paciente }) {

  
  const [uploadFiles, setUploadFiles] = useState([]);

  const id = 'modal-relatorio-geral';

  let terapeutaLogado = JSON.parse(sessionStorage.getItem('@Neuro:user'));
  let day = moment().format('YYYY-MM-DD');
  const snackbar = useSnackbar();

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  function handleSubmit(data){
    
    if (data.informacao === '') {
      Swal.fire('Por favor preencha todos os campos!', '', 'warning');
    } else {
      const objSubmit = {
        paciente_id: paciente.id_paciente,
        terapeuta_id: paciente.terapeuta_id,
        terapia_id: paciente.terapia_id,
        especialidade_id: paciente.especialidade_id,
        status: paciente.status,
        data: day,
        arquivo:uploadFiles,
        informacao: data.informacao,
        //"user_request":52488
      };
      RelatorioGenericoService.store(objSubmit);
      snackbar.displayMessage('Relatório criado com sucesso!', 'success');
      onClose();
    };
  };
  
  function handleUpload(file_upload){
    setUploadFiles([...uploadFiles, file_upload]);
  };
  
  return (
      <Modal id={id} onClick={handleOutsideClick}>
        <ModalContainer>
          <div style={{position: 'relative'}}>
            <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
              right: '25px', fontSize: '25px', top: '-10px' }} onClick={() => onClose()}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Relatório Geral</label>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className='d-flex flex-wrap justify-content-center p-3 px-5'>
              <div className='p-1 col-6'><Input name="id" title="Identificador:" type="text" value={paciente.identificador} /></div>
              <div className='p-1 col-6'><Input name="paciente" title="Nome do Paciente:" type="text" value={paciente.nome} /></div>
              <div className='p-1 col-6'><Input name="diagnostico" title="Diagnóstico:" type="text" value={paciente.diagnostico} /></div>
              <div className='p-1 col-6'><Input name="data_nascimento" title="Data de Nascimento:" type="text" defaultValue={moment(paciente.data_nascimento).format('YYYY-MM-DD')} /></div>
              <div className='p-1 col-6'><Input name="responsavel" title="Responsável:" type="text" value={paciente.responsavel} /></div>
              <div className='p-1 col-6'><Input name="terapeuta" title="Terapeuta:" type="text" value={terapeutaLogado.name} /></div>
              <div className='p-1 col-6'><Input name="terapia" title="Terapia:" type="text" value={paciente.terapia} /></div>
              <div className='p-1 col-6'><Input name="especialidade" title="Especialidade:" type="text" value={paciente.especialidade} /></div>
              <div className='p-1 col-6'><Input name="data" title="Data:" type="text" defaultValue={moment(paciente.data_atendimento).format('YYYY-MM-DD')} /></div>
              {/*<div className='p-1 col-12'><Input name="estacao" title="Estação:" type="text" /></div>*/}
              <div className='p-1 col-12'><TextArea name="informacao" title="Informações:" /></div>
              <div className='p-1 col-12 d-flex align-items-center justify-content-center'>
                <label className='mb-3 m-1' htmlFor="anexo-btn" style={{cursor: 'pointer', color: '#6A3BAF', fontWeight: '600'}}> 
                  <div className='d-flex flex-column text-muted lh-1 mb-2'>
                    {uploadFiles.map((item) => {return <span>{item}</span>;})}
                  </div>
                  <input className='d-none' type="file" id='anexo-btn' onChange={(e) => handleUpload(e.target.value)} />
                  <BsCloudArrowUp size={30} className='me-2' />Anexar Arquivos
                </label>
              </div>
              <div>
              <button style={{
                    marginRight: 20,
                    backgroundColor: '#7340BD',
                    color: '#FFFFFF',
                   
                    border: 'none',
                    padding: '7px 30px',
                    borderRadius: '10px',
                }} onClick={() => onClose()} >
                    Voltar
                </button>
                <button style={{
                    backgroundColor: '#7340BD',
                    color: '#FFFFFF',
                   
                    border: 'none',
                    padding: '7px 30px',
                    borderRadius: '10px',
                }} type={'submit'} >
                    Confirmar
                </button>
              </div>
            </div>
          </Form>
          {/* <InfoPacienteModal paciente={paciente} /> */}

        </ModalContainer>
      </Modal>
  );
}

export default RelatorioGeral;
