import React, {useEffect, useRef, useState} from 'react';
import {Form} from '@unform/web';
import Select from '../../Select';
import {useAuth} from '../../../context/auth';
import api from '../../../services/api';
import {useSnackbar} from '../../../context/snackbar';
import { BackButtonStyle, ButtonAlignStyle, CenterDivStyle, ContainerStyle, Container, Modal } from './styles';
import AtendimentoService from '../../../services/AtendimentoService';

export default function EditStatus({ onClose, modalData, setUpdate }) {

  const [statusSelectBox, setStatusSelectBox] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState([]);
  const [dadosPaciente, setDadosPaciente] = useState([]);
  const [faltaTerapeuta, setFaltaTerapeuta] = useState([]);
  const [status, setStatus] = useState([]);

  const { user } = useAuth();
  const snackbar = useSnackbar();
  const formRef = useRef();
  const modalId = 'modal-avaliar-solicitacao';

  const handleOutsideClick = (e) => {
    if (e.target.id === modalId) onClose(false);
  };

  const handleChangeStatus = (e) => {
    setStatusSelecionado(e);
    setDadosPaciente(modalData);
  };

  const loadStatus = async () => {
    const response = await api.get('api/status-atendimento');
    setStatus(response.data);
    const selectStatus = response.data.map(d => ({
      'value': d.id,
      'label': d.status,
      'slug' : d.slug
    }));
    setStatusSelectBox(selectStatus);
  };

  useEffect(() => {
    loadStatus();
  }, []);

  const handleSubmit = () => {
    if (dadosPaciente != 0) {
      const id_paciente = dadosPaciente.id;
        dadosPaciente.sugestao_terapeuta = statusSelecionado.value;
        dadosPaciente.sugestao_recepcao = statusSelecionado.value;
      AtendimentoService.update(dadosPaciente, id_paciente)
      .then(() => {
        snackbar.displayMessage('Status salvo com sucesso', 'success');
        onClose();
      });
      setUpdate(true);
    }
  };

  return (
      <Modal id={modalId} onClick={(e) => handleOutsideClick(e)}>
        <Container>
          <div style={CenterDivStyle}>
            <label style={{fontWeight: '900', fontSize: 22, color: '#6A3BAF' }}>Editar Status</label>
          </div>
          <Form ref={formRef} style={{ width: '100%', height: '100%',}}>
            <div style={{width: '100%', margin: 5}}>
              <Select name={'status'} label={'Status:'} options={statusSelectBox} onChange={handleChangeStatus}/>
            </div>
            <div  style={ButtonAlignStyle}>
              <button onClick={() => onClose()} style={BackButtonStyle}>
                Voltar
              </button>
              <button onClick={handleSubmit} style={BackButtonStyle}>
                Salvar
              </button>
            </div>
          </Form>
        </Container>
      </Modal>
  );
}
