import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ReactModal from 'react-modal';
import TextArea from '../../../TextArea';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useAuth } from 'context/auth';
import { CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';

const SolicitacaoDeAlteracaoModal = forwardRef(({ onClose = () => { }, user_id = null, controle_diario_id = null, solicitacaoMode = 'terapeuta' }, ref) => {
    //#region VARIAVEIS
    const title = 'Solicitação de Alteração';
    const [isOpen, setIsOpen] = useState(false);
    const [atendimentoLocal, setAtendimentoLocal] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [motivo, setMotivo] = useState('');
    const [horarioAtendimentoId, setHorarioAtendimentoId] = useState(null);
    const [innerWidth, setInnerWidth] = useState();
    const [styles, setStyles] = useState({
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '0 20px 20px 20px',
            width: '100vw',
            height: '60vh',
            margin: '0px'
        }
    });
    const { user } = useAuth();
    //#endregion

    //#region HANDLES
    const handleChangeAlteracao = (e) => {
        setDescricao(e.target.value);
    };

    const handleChangeMotivo = (e) => {
        setMotivo(e.target.value);
    };
    const handleSubmit = (data, { reset }) => {
        if (atendimentoLocal) {
            data.atendimento_id = atendimentoLocal.id;
        }
        if (horarioAtendimentoId) {
            data.horarios_atendimento_id = horarioAtendimentoId;
        }
        data.user_id = user.id;
        data.controle_diario_id = controle_diario_id;
        data.status = 'Aguardando';
        api.post('/api/solicitacao-alteracao', data).then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Solicitação enviada',
                text: 'Solicitação enviada com sucesso!'
            });
        }).catch((error) => {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Erro ao criar solicitação!',
                text: 'Erro ao criar solicitação, entre em contato com o time técnico.'
            });
        }).finally(() => {
            reset();
            closeModal();
        });
    };
    //#endregion

    //#region FUNCOES DE MODAL
    const openModal = (atendimento) => {
        const { horarios_atendimento_id } = atendimento;
        setAtendimentoLocal(atendimento);
        if (horarios_atendimento_id) {
            setHorarioAtendimentoId(horarios_atendimento_id);
        }
        setIsOpen(true);
    };

    const closeModal = () => {
        onClose();
        setDescricao('');
        setMotivo('');
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
        open: openModal,
        close: closeModal
    }));
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        setStyles(prev => {
            let newStyles = { ...prev };
            newStyles.content.width = innerWidth > 768 ? '60vw' : '100vw';
            newStyles.content.height = '70vh';
            newStyles.content.margin = '0px 0px 0px -40px';
            return newStyles;
        });
    }, [innerWidth]);

    useEffect(() => {
        const handleResize = () => {
            const isIphone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
            const newWidth = isIphone ? window.screen.width : window.innerWidth;
            setInnerWidth(newWidth);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //#endregion

    return (
        <ReactModal style={styles} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
            <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div className='w-20per'></div>
                    <ModalTitulo>{title}</ModalTitulo>
                    <label className='btn-outline w-20per text-right' onClick={() => closeModal()}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            <br></br>
            {(solicitacaoMode === 'agendamento' && atendimentoLocal)
                && (
                    <div className='atendimento-details'>
                        <p><strong>Atendimento ID:</strong> {atendimentoLocal?.id}</p>
                        <p><strong>Nome do Paciente:</strong> {atendimentoLocal?.paciente_nome}</p>
                        <p><strong>Terapeuta:</strong> {atendimentoLocal?.terapeuta_nome}</p>
                        <p><strong>Horario:</strong> {atendimentoLocal?.hora_atendimento?.slice(0, 5)}</p>
                    </div>
                )
            }
            <Form className='div-conteudo' onSubmit={handleSubmit}>
                <TextArea title='Descrição da Alteração:' name='descricao' id='descricao' value={descricao} onChange={(e) => handleChangeAlteracao(e)} />
                <TextArea title='Motivo da Alteração:' name='motivo' id='motivo' value={motivo} onChange={(e) => handleChangeMotivo(e)} />
                <button type='submit' className='btn-padrao mt-20px'>Enviar Solicitação</button>
            </Form>
            {(isOpen && atendimentoLocal?.solicitacao_de_alteracaos?.length > 0) &&
                <div className='w-100per mt-20px'>
                    <label>Histórico de Alterações</label>
                    <table className='table-round'>
                        <thead>
                            <tr>
                                <th>Alteração</th>
                                <th>Motivo</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isOpen && atendimentoLocal.solicitacao_de_alteracaos.map((alteracao, idx) => {
                                let cor;
                                switch (alteracao.status) {
                                    case 'Aguardando':
                                        cor = '#F5D70A';
                                        break;
                                    case 'Reprovado':
                                        cor = '#EC4040';
                                        break;
                                    case 'Aprovado':
                                        cor = '#35D058';
                                        break;
                                    default:
                                        cor = '#BCBCBC';
                                        break;
                                }
                                return (
                                    <tr key={`tbody-${idx}`}>
                                        <td>{alteracao.descricao}</td>
                                        <td>{alteracao.motivo}</td>
                                        <td style={{ color: cor }}>{alteracao.status}</td>
                                    </tr>
                                );

                            })}
                        </tbody>
                    </table>
                </div>
            }
        </ReactModal>
    );
});

export default SolicitacaoDeAlteracaoModal;