import React, { useRef, useState } from 'react';
import Terapeuta from '../../../../layouts/Terapeuta';
import { useEffect } from 'react';
import api from '../../../../services/api';
import { Container, ContainerSub, FormField } from './styles';
import { useParams } from 'react-router-dom';
import { VscGraphLine } from 'react-icons/vsc';
import PacienteAltasModal from '../../../../components/Modais/PacienteAltasModal';
import PacienteRelatoriosModal from '../../../../components/Modais/PacienteRelatoriosModal';
import PacienteLaudosModal from '../../../../components/Modais/PacienteLaudosModal';
import PacienteEvolucaoDiariaModal from '../../../../components/Modais/PacienteEvolucaoDiariaModal';
import PacienteInformacoesComplementaresModal from '../../../../components/Modais/PacienteInformacoesComplementaresModal';
import '../../../../styles/global.css';
import FiltroPaciente from '../../../../components/Filter/FiltroPacientes/FiltroPaciente';
import PacienteService from '../../../../services/PacienteService';
import PacientePlanoTerapeuticoModal from '../../../../components/Modais/Pacientes/PlanoTerapeuticoModal';

function TerapeutaProntuarios() {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const { id } = useParams();
    const [pacientes, setPacientes] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [filterValue, setFilter] = useState([]);

    const [openModalAltas, setOpenModalAltas] = useState(false);
    const [openModalRelatorios, setOpenModalRelatorios] = useState(false);
    const [openModalLaudos, setOpenModalLaudos] = useState(false);
    const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
    const [openModalOutrosDocs, setOpenModalOutrosDocs] = useState(false);
    const terapeuta = JSON.parse(sessionStorage.getItem('@Neuro:user'))?.terapeuta[0];

    const pacientePlanoTerapeuticoModalRef = useRef(null);
    //#endregion
    //#region FUNCOEs
    const getPacientes = async (terapeuta_id) => {
        try {
            const pacienteOptions = await PacienteService.getPacienteByTerapeuta(terapeuta_id, true);
            setPacientes(pacienteOptions);
            setFilter(pacienteOptions);
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
    };
    //#endregion
    //#region USE EFFECTs
    useEffect(() => {
        getPacientes(terapeuta.id);
        async function carregaPacientes() {
            api.get('api/paciente?with=plano_terapeuticos,evolucao_pacientes').then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
            });
        }
        carregaPacientes();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Terapeuta>
            <Container>
                <ContainerSub>
                    <div className='div-titulo mb-40px'>
                        <label>Prontuários</label>
                    </div>
                    <div className='col-12'>
                        <FiltroPaciente resultRef={setFilter} list={pacientes} filtro_responsavel={false} firstFilterId={id}></FiltroPaciente>
                    </div>
                    <FormField>
                        <div className='d-flex flex-wrap justify-arround'>
                            {filterValue.map((item, index) => {
                                return (
                                    <div key={index} className='m-2 mx-lg-5 pt-0 bg-white' style={{ width: '320px', height: 'auto', borderRadius: '10px', boxShadow: '0 0 10px 0px silver' }}>
                                        <div className='d-flex flex-column pt-0'>
                                            <span className='text-center bg-cinza font-roxo w-100 font-20px font-bold m-0 pt-2 pb-2'>
                                                {item.nome}
                                            </span>
                                            <span className='text-muted text-center-i mb-3' style={{ fontSize: '20px', fontWeight: '900' }}>
                                                {item.idade} anos
                                                {item.diagnostico1_nome === null ? '' : <br></br>}
                                                {item.diagnostico1_nome}
                                                {item.diagnostico2_nome === null ? '' : <br></br>}
                                                {item.diagnostico2_nome}
                                            </span>
                                        </div>
                                        <div className='d-flex flex-wrap justify-content-center'>
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalEvolucao(true))}>
                                                <div><VscGraphLine size={25} /></div>
                                                <div>Evolução</div>
                                            </button>
                                            {/* Botões com funcionlidades ainda a terminar
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalRelatorios(true))}>
                                                <div><HiOutlineNewspaper size={25} /></div>
                                                <div>Relatórios</div>
                                            </button>
                                            <button className='btn-quadrado m-3' onClick={() => (handleOpenModalPlanoTerapeutico(item))}>
                                                <div><RiFolderAddLine size={25} /></div>
                                                <div>Plano terapêutico</div>
                                            </button>
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalLaudos(true))}>
                                                <div><RiFileSearchLine size={25} /></div>
                                                <div>Laudos</div>
                                            </button>
                                             */}
                                            {/* 
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalOutrosDocs(true))}>
                                                <div><CgFileDocument size={25} /></div>
                                                <div>Outros documentos</div>
                                            </button> 
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalAltas(true))}>
                                                <div><HiOutlineNewspaper size={25} /></div>
                                                <div>Altas</div>
                                            </button>
                                            */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </FormField>
                </ContainerSub>
            </Container>
            <>
                {openModalEvolucao && <PacienteEvolucaoDiariaModal onClose={() => { setOpenModalEvolucao(false); setModalData(null); }} paciente={modalData} />}
                {openModalAltas && <PacienteAltasModal onClose={() => { setOpenModalAltas(false); setModalData(null); }} paciente={modalData} />}
                {openModalRelatorios && <PacienteRelatoriosModal onClose={() => { setOpenModalRelatorios(false); setModalData(null); }} paciente={modalData} />}
                {openModalLaudos && <PacienteLaudosModal onClose={() => { setOpenModalLaudos(false); setModalData(null); }} paciente={modalData} />}
                {openModalOutrosDocs && <PacienteInformacoesComplementaresModal onClose={() => { setOpenModalOutrosDocs(false); setModalData(null); }} paciente={modalData} />}
                <PacientePlanoTerapeuticoModal ref={pacientePlanoTerapeuticoModalRef}></PacientePlanoTerapeuticoModal>
            </>
        </Terapeuta>
    );
    //#endregion
}
export default TerapeutaProntuarios;