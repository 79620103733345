import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleGoToLogin = () => {
        navigate('/'); // Redireciona para a rota de login
    };
    return (
        <div style={styles.container}>
            <button style={styles.backButton} className='btn-padrao' onClick={handleGoToLogin}>
                Voltar ao Login
            </button>
            <h1 style={styles.errorCode} className='font-roxo-claro'>404</h1>
            <p style={styles.errorMessage} className='font-roxo-claro'>Página não encontrada</p>
        </div>
    );
};

const styles = {
    backButton: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        fontSize: '14px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#000',
        backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)',
        backgroundSize: 'cover',
        backgroundPosition: '0% 0%',
        animation: 'moveBackground 30s linear infinite',
        color: '#fff',
    },
    errorCode: {
        fontSize: '72px',
        margin: '0',
    },
    errorMessage: {
        fontSize: '24px',
    },
};

export default NotFound;