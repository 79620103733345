import styled from 'styled-components';
// import { Hashtag } from 'styled-icons/heroicons-outline';
// import { PersonAdd, Settings } from 'styled-icons/material';

import { AiFillHourglass } from 'react-icons/ai';
import { shade } from 'polished';
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;

  // padding: 5px 3px;
  padding: 20px; 
  margin: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #7442BF;
  height: 56px;
  font-weight: 500;

  transition: background-color 0.2s;

  > div {
    display: flex;
    align-items: center;
  }

  > div span {
    margin-left: 5px;
    color: var(--senary);
  }

  &:hover,
  &.active {
    background: ${shade(0.2, '#7442BF')};

    > div span {
      color: var(--white);
    }
  }
`;

export const HashtagIcon = styled(AiFillHourglass)`
  width: 20px;
  height: 20px;

  color: var(--symbol);
`;
