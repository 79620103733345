import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import api from '../../../../services/api';
import { Container, ContainerSub, FormField, col, table, tbody, thead, title, tr } from './styles';
import { useNavigate } from 'react-router-dom';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import moment from 'moment';
import { useAuth } from '../../../../context/auth';

export default function PendenciasAtrasadas() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [modalData, setModalData] = useState();
    const [pacientes, setPacientes] = useState([]);
    const modalRelatorioEvolucaoRef = useRef(null);

    function handleAbrir(itemPaciente) {
        setModalData(itemPaciente);
        if (modalRelatorioEvolucaoRef) {
            if (modalRelatorioEvolucaoRef.current) {
                modalRelatorioEvolucaoRef.current.openModal();
            }
        }
    }

    useEffect(() => {
        getPacientes();
    }, []);

    const getPacientes = async () => {
        try {
            await api.get(`api/terapeuta/get/evolucoes/pendentes/${user.terapeuta[0].id}?tipo=vencida`).then(async ({ data }) => {
                let pacienteOptions = [];
                await data.sort((a, b) => {
                    return new Date(b.date_create) - new Date(a.date_create);
                });
                data.forEach((atendimento) => {
                    let status_evolucao = moment().utc().format('DD/MM/YYYY') === moment(atendimento.date_create).format('DD/MM/YYYY');
                    pacienteOptions.push({
                        id_paciente: atendimento.id_paciente,
                        nome: atendimento.nome,
                        data_atendimento: atendimento.data_atendimento_real,
                        date_create: atendimento.date_create,
                        status: atendimento.status,
                        estacao: atendimento.estacao,
                        estacao_id: atendimento.estacao_id,
                        status_evolucao: status_evolucao,
                        terapia: atendimento.terapia,
                        terapia_id: atendimento.terapia_id,
                        especialidade: atendimento.especialidade,
                        especialidade_id: atendimento.especialidade_id,
                        atendimento_id: atendimento.id_atendimento,
                    });
                });
                setPacientes(pacienteOptions);
            });
        } catch (e) {
            console.error('Erro ao obter lista de pacientes - ', e);
        }
    };

    return (
        <>
            <Container>
                <div className='div-submenu'>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias')} style={{ marginRight: 20, cursor: 'pointer' }}>Evolução Diária</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-gerais')} style={{ marginRight: 20, cursor: 'pointer' }}>Pendências Gerais</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-atrasadas')} style={{ color: '#7340BD', fontWeight: 'bold', cursor: 'pointer' }}>Pendências Atrasadas</label>
                </div>
                <ContainerSub>
                    <FormField>
                        <span style={title}>
                            Pendencias Atrasadas
                        </span>
                        <table style={table}>
                            <thead>
                                <tr key={'tr-01'}>
                                    <th className='font-titulo'>Paciente</th>
                                    <th className='font-titulo'>Pendência</th>
                                    <th className='font-titulo'>Data e Hora</th>
                                    <th className='font-titulo'>Ações</th>
                                </tr>
                            </thead>
                            <tbody style={tbody}>
                                {pacientes.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <input defaultValue={item.nome} className='form-control w-100per' readOnly />
                                            </td>
                                            <td>
                                                <input defaultValue={'Evolução'} className='form-control w-100per' readOnly />
                                            </td>
                                            <td>
                                                <input defaultValue={moment(item.date_create).format('DD/MM/YYYY') + ' às 23:59'} className='form-control w-100per' title={item.minutos} readOnly />
                                            </td>
                                            <td>
                                                <button className='btn-padrao w-100per p-lg-1 p-sm-0' onClick={() => handleAbrir(item)} title='Criar evolução para esse atendimento, e para esse paciente.'> Evoluir </button>
                                            </td>
                                        </tr>
                                    );

                                })}
                                <tr>
                                    <th scope="row" style={{ textAlign: 'right' }} rowSpan={4}>Linhas: {pacientes.length}</th>
                                </tr>
                            </tbody>
                        </table>
                    </FormField>
                </ContainerSub>
            </Container>
            <RelatorioEvolucaoPaciente ref={modalRelatorioEvolucaoRef} paciente={modalData} onClose={() => { getPacientes(); setModalData([]); }} />
        </>
    );
}
