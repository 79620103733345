import api from './api';

class TerapeutaTerapiaService {
    static index = () => {
        return api.get('/api/terapeuta-terapia');
    };
    static store = (obj) => {
        /* exemplo
        {"terapeuta_id":1,"terapia_id":1}
        */
                
        return api.post('/api/terapeuta-terapia', obj);
    };
    // show = (id) => {
    //     return api.get(`terapeuta-terapia/${id}`);
    // }
    // update = (obj, id) => {
    //     /* exemplo
    //     {"terapeuta_id":1,"especialidade_id":1,"data":"1986-07-03","arquivo":"similique"}
    //     */
    //
    //     return api.put(`terapeuta-terapia/${id}`, obj);
    // }
    static destroy = (id) => {
        return api.delete(`/api/terapeuta-terapia/${id}?id=${id}`);
    };
}

export default TerapeutaTerapiaService;