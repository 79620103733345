import React, {useMemo} from 'react';
import {useTable} from 'react-table';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import Swal from 'sweetalert2';
import AreaCompartilhadaService from '../../../../../services/AreaCompartilhadaService';
import { TableDefaultPagination } from '../../../../../components/TableDefaultPagination';

export default function TableAreaCompartilhada({source, callback, setIsOpen, setModalData}) {

  const handleEdit = (data) => {
    setModalData(data);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        AreaCompartilhadaService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
      }
    });
  };

  const columns = useMemo(
    () => [
          {
            Header: 'Area Compartilhada:',
            accessor: 'area',
          },
          {
            Header: 'Ações',
            accessor: 'acoes',
            Cell: ({cell}) => (
              (<div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent'
                }}>
                  <AiOutlineDelete style={{cursor: 'pointer'}} onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22}/>
                  <AiOutlineEdit style={{cursor: 'pointer'}} onClick={() => handleEdit(cell.row.original)} color="#7340BD" size={22}/>
                </div>
              )
            )
          }
        ],
    []
  );

  return (
    <TableDefaultPagination columns={columns} source={source}/>
  );
}
