export const MainDivStyle = {
    width: '100%',   
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center', 
};

export const BotaoAcaoStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    lineHeight: 1,
    boxShadow: '0 0 10px 0px silver',
    border: 'none',
    padding: '2px 15px',
    borderRadius: 8,
    height: 25
};

export const TextFormat = { 
    marginLeft: 5,
    fontWeight: 'bold',
};

export const InputStyle = {
    width: '100px',
    borderRadius: 8, 
    height: 25, 
    boxShadow: '0 0 10px 0px silver', 
    border: 'none',
    padding: 12, 
};

export const OptionsStyle = {
    width: '150px',
    borderRadius: 8, 
    height: 25, 
    backgroundColor: 'white',
    boxShadow: '0 0 10px 0px silver', 
    border: 'none',
    marginLeft: 5,
    textIndent: 3,
    paddingLeft: 10
};