import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContainerButton } from '../styles';
import { NotificacaoPendenteP } from '../../HeaderTerapeuta/styles';
import Dropdown from 'components/Dropdown';
import useIcons from 'context/icons';

const NavLink = ({ link, name, icon, atualLocal, notificacao = 0, dropDownList = null, clickable = true, disabled = false }) => {
    const selected = {
        backgroundColor: '#5c3498',
    };
    const unselected = {};
    const [isTouchDevice, setIsTouchDevice] = React.useState(false);
    const { getIcon } = useIcons();

    useEffect(() => {
        const checkIfTouchDevice = () => {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        };
        setIsTouchDevice(checkIfTouchDevice());
    }, []);

    useEffect(() => {
        if (dropDownList) {
            dropDownList.sort((a, b) => a.position - b.position);
        }
    }, [dropDownList]);

    const linkContent = (
        <>
            <span> {getIcon(icon)} </span>
            <span>{name}</span>
        </>
    );
    const sublinkContent = (dropDownList !== null)
        ? <ul id={link} className='w-fit-content bg-roxo-dark-i px-2 py-2 ns-lista-sem-estilo'>
            {dropDownList.map((item, index) => (
                <li key={`l:${link}-d-item:${index}`}>
                    <Link key={index} to={item.link} className={'li-padrao font-branca hl-white-bg-laranja justify-content-start'}>
                        {item.icon &&
                            <span className='me-2'>
                                {getIcon(item.icon, true)}
                            </span>
                        }
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
        : null;
    const linkClass = `d-flex flex-col text-center w-100 text-decoration-none align-center justify-center ${disabled === true ? 'font-cinza pointer-none' : 'font-branca'}`;
    return (
        <Dropdown
            clickMode={isTouchDevice}
            button={
                <ContainerButton className={disabled === true ? 'cursor-block' : clickable ? 'cursor-pointer' : 'cursor-default'} style={link.split('/')[3] === atualLocal ? selected : unselected}>
                    {notificacao > 0 &&
                        <NotificacaoPendenteP>{notificacao}</NotificacaoPendenteP>
                    }
                    {clickable ? (
                        <Link to={link} className={linkClass}>
                            {linkContent}
                        </Link>
                    ) : (
                        <div className={`${linkClass} cursor-default`}>
                            {linkContent}
                        </div>
                    )}
                </ContainerButton>
            }
            content={sublinkContent} />
    );
};

export default NavLink;
