import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-day-picker/dist/style.css';
import { AiFillCaretDown, AiFillEye, AiOutlineClear, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { ClipLoader } from 'react-spinners';
import { FiRefreshCcw } from 'react-icons/fi';
import { Form } from '@unform/web';
import { Tooltip } from 'react-tooltip';
import Recepcao from '../../../../layouts/Recepcao';
import api from '../../../../services/api';
import SalaService from '../../../../services/SalaService';
import EstacaoService from '../../../../services/EstacaoService';
import AgendamentoService from '../../../../services/AgendamentoService';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
import { useSnackbar } from '../../../../context/snackbar';
import { useAuth } from 'context/auth';
import Select from '../../../../components/Select';
import AgendaModal from '../../../../components/Modais/Terapeuta/AgendaModal';
import Dropdown from 'components/Dropdown';
import Responsavel from './Modal/Responsavel';
import DetalhesDoAgendamento from '../Terapeuta/Modal/DetalhesDoAgendamento';
import LegendaDefault from '../ControleDiario/Modal/Legenda';
import { Container, DivContentDisplay, filtered, listOfStyles, styles_escuro, styles_filtred } from '../Terapeuta/styles';
import { ChangeCase } from 'services/ChangeCase';
import './styles.css';
import { toPng } from 'html-to-image';
import { BsDownload, BsFullscreen } from 'react-icons/bs';
/**
 * AgendaTerapeutas Component
 * @description Tela de visualização e edição de agendamentos para o dia da semana.
 */
export default function AgendaTerapeutas() {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*==VARS INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'AGENDA SEMANAL DO TERAPEUTA';
    // eslint-disable-next-line no-unused-vars
    const rota = 'dashboard/{modulo}/agenda';
    // eslint-disable-next-line no-unused-vars
    const descricao = 'Tela de visualição e ediçao de agendamentos para o dia da semana.';
    /*==VARS ESTILOS==*/
    const stylesSize = [90, 70, 90, 70];
    const differenceBase = [0, 285];
    const stylesNormal = listOfStyles({
        baseWidth: stylesSize[0],
        baseHeight: stylesSize[1],
        baseWidthHora: stylesSize[2],
        baseHeightHora: stylesSize[3],
        isFullScreen: false,
        esperaMode: false,
        differenceBase: differenceBase
    });
    const stylesNormalFullscreen = listOfStyles({
        baseWidth: stylesSize[0],
        baseHeight: stylesSize[1],
        baseWidthHora: stylesSize[2],
        baseHeightHora: stylesSize[3],
        isFullScreen: true,
        esperaMode: false,
        differenceBase: differenceBase
    });
    const [styles, setStyles] = useState(stylesNormal);
    /*==VARS UTILITÁRIOS==*/
    const snackbar = useSnackbar();
    /*==VARS LOADING==*/
    const [loading, setloading] = useState(true);
    /*==VARS MODAIS==*/
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [esperaMode, setEsperaMode] = useState(false);
    /*==VARS SELEÇÃO==*/
    const agendaModalRef = useRef(null);

    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    /*==VARS DIAS==*/
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
    /*==VARS Listas de Dados==*/
    const [estacaos, setEstacaos] = useState([]);
    const [salas, setSalas] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [newAgendamentos, setNewAgendamentos] = useState([]);
    const usuario = useAuth();

    const [fullScreenState, setfullScreenState] = useState(false);
    const [modalAgendaOpen, setModalAgendaOpen] = useState(false);

    const [expand, setExpand] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
    const [agendamentoId, setAgendamentoId] = useState();
    const [terapeuta, setTerapeuta] = useState({});
    const [idHorario, setIdHorario] = useState();
    /*==VARS DATAS==*/
    const dateNow = new Date();
    const [dataAtual, setDataAtual] = useState(moment(dateNow));
    const [diaAtual, setDiaAtual] = useState(dateNow.getDay());
    const [dataSave, setDataSave] = useState(moment());
    /*==VARS FILTRO==*/
    const [filtroNome, setFiltroNome] = useState('');
    const [salasFiltrada, setSalasFiltrada] = useState([]);
    const filtroDivRef = useRef(null);

    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);

    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [terapiaSelecionadaOpt, setTerapiaSelecionadaOpt] = useState(null);
    const [especialidadeSelecionadaOpt, setEspecialidadeSelecionadaOpt] = useState(null);

    const [pacientesList, setPacientes] = useState([]);
    const [terapeutasList, setTerapeutasList] = useState([]);
    const [terapiasList, setTerapiasList] = useState([]);
    const [especialidadesList, setEspecialidadesList] = useState([]);

    const [terapeutas, setTerapeutas] = useState([]);
    const [terapeutasOriginal, setTerapeutasOriginal] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);

    const tableRef = useRef(null);
    const horaTableBodyRef = useRef(null);
    const agendamentoTableBodyRef = useRef(null);
    const terapeutaTableHeadRef = useRef(null);
    const elementRef = useRef(null);
    //#endregion]
    /*===============================*/
    //#region FUNCOES================*/
    /*===============================*/
    const enterFullscreen = () => {
        const elem = tableRef.current;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            elem.webkitRequestFullscreen();
        }
    };
    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    };
    const fullScreen = () => {
        if (!document.fullscreenElement) {
            enterFullscreen();
            setfullScreenState(true);
        } else {
            exitFullscreen();
            setfullScreenState(false);
        }
    };
    /*==FILTRO==*/
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpand(false);
        }
    };
    /**
     * Função que coleta todos os pacientes cadastrados em uma lista de seleção
     */
    const getPacientes = () => {
        api.get('api/paciente').then(({ data }) => {
            let pacientes = [];
            data.forEach((p) => {
                pacientes.push({ value: p.id, label: `(${p.id}) ${p.nome}` });
            });
            setPacientes(pacientes);
        });
    };

    const getTerapias = () => {
        api.get('api/terapia').then(({ data }) => {
            let terapias = [];
            let terapiasList = [];
            data.forEach((t) => {
                terapias.push({ nome: t.terapia, id: t.id });
                terapiasList.push({ value: t.id, label: `(${t.id}) ${t.terapia}` });
            });
            setTerapias(terapias);
            setTerapiasList(terapiasList);
        });
    };

    /**
     * Função que coleta todas as especialidades em uma lista de seleção
     */
    const getEspecialidades = () => {
        return api.get('api/especialidade').then(({ data }) => {
            // Process the data and set the list of especialidades
            const especialidades = data.map((especialidade) => ({
                value: especialidade.id,
                label: especialidade.nome,
                key: especialidade.id, // Add a unique key prop
            }));
            setEspecialidades(especialidades);
            return especialidades;
        });
    };

    /**
     * Função que coleta todos os terapeutas em uma lista de seleção
     */
    const getTerapeutas = () => {
        api.get('api/terapeuta?with=terapeuta_terapia,terapeuta_terapia.terapium').then(({ data }) => {
            let terapeutas = [];
            let terapeutasList = [];
            data.forEach((p) => {
                terapeutas.push({ nome_curto: p.nome_curto, id: p.id, terapeuta_terapia: p.terapeuta_terapia.filter((filter) => !filter.deleted_at) });
                terapeutasList.push({ value: p.id, label: `(${p.id}) ${p.nome}`, key: p.id }); // Add a unique key prop
            });
            setTerapeutasList(terapeutasList);
            setTerapeutas(terapeutas);
            setTerapeutasOriginal(terapeutas);
        });
    };
    const filtrar = () => {
        let filtro = filtroNome;
        setAgendamentos(agendamentos.map((a) => {
            let novo = [];
            if (filtro === '' || filtro === null) {
                novo = { ...a, filtrado: undefined };
            } else {
                if (String(a.paciente.nome).toLowerCase().includes(String(filtro).toLowerCase())) {
                    novo = { ...a, filtrado: true };
                } else {
                    novo = { ...a, filtrado: false };
                }
            }
            return (novo);
        }));
        updateFilter();
    };
    const updateFilter = () => {
        let fields = document.getElementsByName('salas[]');
        let checks = [];
        fields.forEach((x) => {
            if (x.checked) {
                checks.push(x.value);
            }
        });

        return checks;
    };
    /*==ATUALIZADOR==*/
    const atualizaComponenteData = async (diaAtualLocal = null, dataSaveLocal = null) => {
        if (diaAtualLocal === null && dataSaveLocal === null) {
            dataSaveLocal = moment(dataSave);
            diaAtualLocal = dataSaveLocal.format('e');
        }
        setDiaAtual(diaAtualLocal);
        setDataAtual(dataSaveLocal);
        setDataSave(dataSaveLocal);
        setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
        getAgendamentos(dataSaveLocal.format('YYYY-MM-DD'));
        getAgendaHorarios(diaAtualLocal);
    };
    /*==AGENDAMENTO==*/
    const modalLegendas = () => {
        setIsModalLegendaVisible(true);
    };
    const modalDetalhesAgendamento = (detalhes) => {
        setAgendamentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    /*==GETTERS==*/
    const getAgendaDia = () => {
        let params = 'with=sala.responsavel_salas.terapeutum&';
        let filters = updateFilter();
        if (filters.length > 0) {
            params += 'filter[sala_id]=' + filters.join(',');
        }
        EstacaoService.index(params).then(({ data }) => {
            data.sort((a, b) => Number(a.sala.sala) < Number(b.sala.sala) ? -1 : Number(a.sala.sala) > Number(b.sala.sala) ? 1 : 0);
            setEstacaos(data);
        });
    };

    const getAgendaHorarios = (diaAtualLocal) => {
        api.get('/api/horario-atendimento?filter[dia_semana]=' + diaAtualLocal).then(({ data }) => {
            setHorarios(data);
        });
    };

    const getSalas = () => {
        SalaService.index('')
            .then(({ data }) => {
                data.sort((a, b) => Number(a.sala) < Number(b.sala) ? -1 : a.sala > b.sala ? 1 : 0);
                let salas = [];
                data.forEach((s) => {
                    salas.push({ value: s.id, label: `(${s.id}) ${s.sala}`, key: s.id }); // Add a unique key prop
                });
                setSalas(data);
            });
    };

    const getAgendamentos = async (dataSaveLocal) => {
        setloading(true);
        let day = moment(dataSaveLocal).day();
        const where = 'agendamento.tipo_atendimento_id,=,1|agendamento.tipo_atendimento_id,=,2|agendamento.reserva,=,0';
        AgendamentoService.getByWeekDay(day, 'estacao,estacao.sala,especialidade,terapia', 'estacao.estacao,sala.sala,especialidade.especialidade,terapia.terapia', where)
            .then(({ data }) => {
                setAgendamentos(data);
                let newAgendamentos = [];
                for (let index = 0; index < data.length; index++) {
                    const a = data[index];
                    if (!newAgendamentos[a.terapeuta_id]) {
                        newAgendamentos[a.terapeuta_id] = {};
                    }
                    if (!newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id]) {
                        newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id] = [];
                    }
                    newAgendamentos[a.terapeuta_id][a.horarios_atendimento_id].push(
                        (a.prop && a.prop.constructor === Array)
                            ? a.reverse()
                            : a
                    );
                }
                setNewAgendamentos(newAgendamentos);
                setloading(false);
            });
    };
    //#endregion=====================
    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
    const handleClickNextDay = () => {
        let dataSaveLocal = moment(dataSave).add(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).add(2, 'days');
        }
        let diaAtualLocal = dataSaveLocal.format('e');
        atualizaComponenteData(diaAtualLocal, dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = moment(dataSave).subtract(1, 'days');
        if (dataSaveLocal.day() === 0) {
            dataSaveLocal = moment(dataSave).subtract(2, 'days');
        }
        let diaAtualLocal = dataSaveLocal.format('e');
        atualizaComponenteData(diaAtualLocal, dataSaveLocal);
    };
    const handleExportToImage = () => {
        if (elementRef.current === null) {
            return;
        }
        elementRef.current.classList.add('overflow-y-no-scroll');
        elementRef.current.classList.add('bg-branco');
        elementRef.current.classList.add('w-content-i');
        elementRef.current.classList.add('h-content-i');
        agendamentoTableBodyRef.current.classList.add('w-content-i');
        agendamentoTableBodyRef.current.classList.add('h-content-i');
        terapeutaTableHeadRef.current.classList.add('w-content-i');
        terapeutaTableHeadRef.current.classList.add('h-content-i');
        horaTableBodyRef.current.classList.add('h-content-i');
        terapeutaTableHeadRef.current.classList.add('w-content-i');
        terapeutaTableHeadRef.current.classList.add('h-content-i');
        toPng(elementRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `agenda-${esperaMode ? 'espera-' : ''}${dataSave.format('DD_MM_YYYY-HH_MM')}.jpeg`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error('Error generating image:', err);
            })
            .finally(() => {
                elementRef.current.classList.remove('overflow-y-no-scroll');
                elementRef.current.classList.remove('bg-branco');
                elementRef.current.classList.remove('w-content-i');
                elementRef.current.classList.remove('h-content-i');
                agendamentoTableBodyRef.current.classList.remove('w-content-i');
                agendamentoTableBodyRef.current.classList.remove('h-content-i');
                terapeutaTableHeadRef.current.classList.remove('w-content-i');
                terapeutaTableHeadRef.current.classList.remove('h-content-i');
                horaTableBodyRef.current.classList.remove('h-content-i');
                terapeutaTableHeadRef.current.classList.remove('w-content-i');
                terapeutaTableHeadRef.current.classList.remove('h-content-i');
            });
    };
    //#endregion=====================
    /*===============================*/
    //#region USE EFFECTS============*/
    /*===============================*/
    useEffect(() => {
        if (!houveExclusaoAgendamento) return;
        getAgendaDia();
        setHouveExclusaoAgendamento(false);
    }, [houveExclusaoAgendamento]);
    useEffect(() => {
        try {
            filtrar();
            let newTerapeutas = [];
            terapeutasOriginal.forEach((t) => {
                let novasTerapias = t.terapeuta_terapia;
                let localSelecionados = terapiaSelecionadaOpt;
                if (localSelecionados !== null && localSelecionados !== undefined && localSelecionados.length > 0) {
                    novasTerapias = null;
                    novasTerapias = t.terapeuta_terapia.filter((tt) => {
                        for (let index = 0; index < localSelecionados.length; index++) {
                            const el = localSelecionados[index];
                            if (tt.terapia_id === el.value) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                let newObj = null;
                if (novasTerapias.length > 0) {
                    newObj = { ...t, terapeuta_terapia: novasTerapias };
                }
                if (terapeutaSelecionadoOpt !== null) {
                    if (terapeutaSelecionadoOpt.length === 0) return true;
                    for (let i = 0; i < terapeutaSelecionadoOpt.length; i++) {
                        let fil = terapeutaSelecionadoOpt[i];
                        if (t.id === fil.value) {
                            newTerapeutas.push(newObj);
                        }
                    }
                } else {
                    if (newObj) {
                        newTerapeutas.push(newObj);
                    }
                }
            });
            setTerapeutas(newTerapeutas);
        } catch (e) {
            console.error(e);
        } finally {

        }
    }, [terapeutaSelecionadoOpt, pacienteSelecionado, terapiaSelecionadaOpt]);

    useEffect(() => {
        if (modalAgendaOpen) {
            exitFullscreen();
        } else {
            if (fullScreenState) {
                enterFullscreen();
            }
        }
    }, [modalAgendaOpen]);
    useEffect(() => {
        setStyles(fullScreenState ? stylesNormalFullscreen : stylesNormal);
    }, [fullScreenState]);

    const syncScroll = (source, targetUp, targetLeft) => {
        source = source.current;
        targetUp = targetUp != null ? targetUp.current : null;
        targetLeft = targetLeft != null ? targetLeft.current : null;
        if (targetUp != null)
            targetUp.scrollTop = source.scrollTop;
        if (targetLeft != null)
            targetLeft.scrollLeft = source.scrollLeft;
    };
    useEffect(() => {
        const handleAtalhos = (e) => {
            if (e.key === 'Escape') {
                setfullScreenState(false);
                setExpand(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleAtalhos);

        let t = JSON.parse(sessionStorage.getItem('@Neuro:user'));
        getPacientes();
        getTerapeutas();
        getTerapias();
        getEspecialidades();
        setTerapeuta(t);
        getAgendaDia();
        getAgendaHorarios(diaAtual);
        getSalas();
        getAgendamentos(dataSelecionada);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleAtalhos);
        };
    }, []);
    //#endregion=====================
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <Recepcao>
            <Container>
                <div style={styles.containerFlexColumn}>
                    <div ref={tableRef} className='w-content m-auto'>
                        <div style={styles.tr0}>
                            <div ref={filtroDivRef} className='div-filter-style justify-content-start align-center' style={styles.filtro}>
                                <div className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }} onClick={() => setExpand(!expand)}>
                                    <div id="blank" style={{ height: 'fit-content' }}>{!expand ? 'Expandir' : 'Fechar'} Filtro</div>
                                    <AiFillCaretDown className='pointer' color="white" size={22} />
                                </div>

                                <Form className='div-expand-over' style={{ backgroundColor: 'rgb(149, 134, 172)', marginLeft: '-16px', width: '60vw', ...expand ? {} : { display: 'none' } }}>
                                    <div style={DivContentDisplay} className='d-flex flex-col'>
                                        <div className='row w-100per'>
                                            <div className="col-12">
                                                <label className="label-filtro">Terapeuta:</label>
                                                <div className="d-flex justify-around align-center w-100per">
                                                    <Select
                                                        id={'terapeutas'}
                                                        name={'terapeutas'}
                                                        options={terapeutasList}
                                                        value={terapeutaSelecionadoOpt}
                                                        onChange={(e) => {
                                                            setTerapeutaSelecionadoOpt(e);

                                                        }}
                                                        isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setTerapeutaSelecionadoOpt(null);

                                                    }} size={25} />
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="label-filtro">Paciente:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select
                                                        name={'pacientes'}
                                                        options={pacientesList}
                                                        value={pacienteSelecionadoOpt}
                                                        onChange={(e) => {
                                                            setPacienteSelecionado(e.value);
                                                            setPacienteSelecionadoOpt({ value: e.value, label: e.label });

                                                        }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setPacienteSelecionado(null);
                                                        setPacienteSelecionadoOpt(null);

                                                    }} size={25} />
                                                </div>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <label className="label-filtro">Terapias:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select name={'terapias'} options={terapiasList} value={terapiaSelecionadaOpt} onChange={(e) => {
                                                        setTerapiaSelecionadaOpt(e);
                                                    }} isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" size={25} onClick={(e) => {
                                                        setTerapiaSelecionadaOpt(null);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <label className="label-filtro">Especialidades:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select name={'terapias'} options={especialidadesList} value={especialidadeSelecionadaOpt} onChange={(e) => {
                                                        setEspecialidadeSelecionadaOpt(e);
                                                    }} isMulti={true} />
                                                    <AiOutlineClear className="pointer m-2" size={25} onClick={(e) => {
                                                        setEspecialidadeSelecionadaOpt(null);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className='p-1 justify-content-around d-flex align-center' style={{ width: '300px' }}>
                                <AiOutlineLeft className='pointer' color="black" size={30} onClick={() => handleClickPrevDay()} />
                                <span className='texto-navegacao' title={`${dataAtual.format('DD/MM/YYYY')}`}>{`${dayName[dataAtual.day()]}`}</span>
                                <AiOutlineRight className='pointer' color="black" size={30} onClick={() => handleClickNextDay()} />
                            </div>
                            <div className='d-inline-flex justify-content-end align-center' style={{ width: '300px' }}>
                                {/* BOTÃO EXPORT IMAGEM */}
                                <BsDownload className='pointer' size={30} onClick={() => handleExportToImage()} style={{ marginRight: '10px' }} />
                                {/* BOTÃO DE ATUALIZAR AGENDA */}
                                <FiRefreshCcw className='pointer' onClick={() => atualizaComponenteData()} size={30} style={{ marginRight: '20px' }} />
                                <button onClick={() => modalLegendas()} className='btn-outline' title='Legenda dos tipos de atendimento por cor.'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" fill="none" stroke="rgb(149, 134, 172)" strokeWidth="2" />
                                        <text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
                                    </svg>
                                </button>
                                {/* BOTÃO FULLSCREEN */}
                                <button onClick={(e) => fullScreen()}>
                                    <BsFullscreen size={25} color='rgb(149, 134, 172)' stroke="rgb(149, 134, 172)" strokeWidth="2" fontWeight='bold' fontSize='14' />
                                </button>
                            </div>
                        </div>
                        <div ref={elementRef} id='div-agenda' className='d-inline-flex'>
                            <table className='table-agenda-terapeuta' style={styles.table}>
                                <thead style={{ ...styles.thead, height: '55px' }}>
                                    <tr style={{ ...styles.thead.trS, height: '55px' }} key={'terapeutas'}>
                                        <th className='d-flex' style={{ ...styles.tr1, height: '55px', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center', margin: '0', padding: '0' }}><span style={{ fontSize: '10pt' }}>Terapeutas</span> <br></br> <span>{terapeutas?.length}</span></th>
                                    </tr>
                                </thead>
                                <tbody ref={horaTableBodyRef} style={{ ...styles.tbody0 }} onScrollCapture={(e) => { syncScroll(horaTableBodyRef, agendamentoTableBodyRef, null); }}>
                                    {horarios.map((horario, index) => {
                                        let tarde = (Number(horario.horario.split('T')[1].split(':')[0]) >= 17);
                                        return (
                                            <tr style={styles.tbody.tr} key={'horas-' + index}>
                                                <td className='word-wrap' key={horarios.id} style={tarde ? { ...styles.trHora, ...styles.tarde } : { ...styles.trHora }} align={'center'}>{moment.utc(horario.horario).format('HH:mm')}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className='table-agenda-terapeuta' style={styles.table}>
                                <thead ref={terapeutaTableHeadRef} style={{ ...styles.thead, height: '55px' }} onScrollCapture={(e) => syncScroll(terapeutaTableHeadRef, null, agendamentoTableBodyRef)}>
                                    <tr style={{ ...styles.thead.tr, height: '55px' }} key={'terapeutas-tr'}>
                                        {terapeutas.map((terapeuta, index) => {
                                            if (terapeuta) {
                                                if (terapeuta?.terapeuta_terapia?.length > 0) {
                                                    return (
                                                        <td key={index} align={'center'}
                                                            style={{
                                                                ...styles.tr2T,
                                                                fontSize: '8pt',
                                                                height: '55px'
                                                            }}
                                                            className='font-14px pointer word-break'
                                                            onClick={() => {
                                                                agendaModalRef.current.openModal(terapeuta.id);
                                                                setModalAgendaOpen(true);
                                                            }}>
                                                            {terapeuta?.nome_curto}
                                                        </td>
                                                    );
                                                }
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody ref={agendamentoTableBodyRef} style={styles.tbody} onScrollCapture={(e) => { syncScroll(agendamentoTableBodyRef, horaTableBodyRef, terapeutaTableHeadRef); }}>
                                    {loading === false
                                        ? horarios.map((horario, index) => {
                                            const horaInteira = moment.utc(horario.horario);
                                            const hora = horaInteira.format('HH:mm');
                                            const idHorario = horario.id;
                                            const returns = (
                                                <tr style={styles.tbody.tr} key={index}>
                                                    {terapeutas.map((terapeuta, index2) => {
                                                        let reservaNoTitulo = [];
                                                        let substituicaoNoTitulo = [];
                                                        let titulo;
                                                        let agendaContent;
                                                        let div2style;
                                                        let tooltipInfo = <>
                                                            Horario: {hora}<br></br>
                                                            Terapeuta: {terapeuta.nome_curto}
                                                        </>;
                                                        //#region VARIAVEIS
                                                        let agendamento =
                                                            (newAgendamentos[terapeuta.id] !== undefined)
                                                                ? (newAgendamentos[terapeuta.id][idHorario] !== undefined)
                                                                    ? newAgendamentos[terapeuta.id][idHorario]
                                                                    : null
                                                                : null;
                                                        //#endregion
                                                        if (agendamento !== null && agendamento.length > 0) {
                                                            const agenda = agendamento.filter((a) => {
                                                                const reserva = a.reserva === 0;
                                                                const tipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7;
                                                                const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
                                                                return reserva && tipo && espera;
                                                            });
                                                            if (agenda.length > 0) {
                                                                agendaContent = agenda.map((a) => {
                                                                    //VARS\\
                                                                    let legenda = getLegendaAgendamento(a, agendamentos);
                                                                    let horaFinalDiferente;
                                                                    let horaFinal;
                                                                    const localEstacao = {
                                                                        estacao: a.estacao,
                                                                        sala: a.sala,
                                                                        id: a.estacao_id,
                                                                    };
                                                                    if (a.data_atendimento_final) {
                                                                        if (a.data_atendimento_final.includes('T')) {
                                                                            horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                                                            horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
                                                                        } else {
                                                                            horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                                                            horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
                                                                        }
                                                                    }
                                                                    horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
                                                                    div2style = {
                                                                        cursor: 'default',
                                                                        ...styles.trOcupado,
                                                                    };

                                                                    if (a.filtrado === undefined) {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            ...styles[legenda],
                                                                        };
                                                                    } else if (a.filtrado === true) {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            ...styles_filtred[legenda],
                                                                        };
                                                                    } else {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            ...styles_escuro[legenda],
                                                                        };
                                                                    }
                                                                    tooltipInfo = <>
                                                                        {(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente') &&
                                                                            <h5>{ChangeCase.toTitleCase(legenda)}</h5>
                                                                        }
                                                                        Terapeuta: {a.terapeuta_nome} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}<br></br>
                                                                        Sala/Estação: {localEstacao.sala} / {localEstacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${localEstacao.id})`}<br></br>
                                                                        Paciente: {a.paciente_nome} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}<br></br>
                                                                        Horário: {hora}  {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}
                                                                    </>;
                                                                    titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })}>
                                                                        {a.paciente_identificador}
                                                                    </p>;
                                                                    return (
                                                                        <>
                                                                            <div className='col-12 d-flex justify-content-center align-items-center h-auto-i' id={'agendamento' + a.id}>
                                                                                <AiFillEye className='pointer h-azul' title='Detalhes do agendamento.' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
                                                                            </div>
                                                                            <div className='col-12 h-auto-i'>
                                                                                {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 7) + '.' : '---'}
                                                                            </div>
                                                                            {!horaFinalDiferente &&
                                                                                <div className='hora-diferente d-inline-flex' width={styles.width}>
                                                                                    Hora Final: {horaFinal}
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    );
                                                                });
                                                            }
                                                            const agendaReserva = agendamento.filter((a) => {
                                                                const reserva = a.reserva === 1;
                                                                const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
                                                                return reserva && espera;
                                                            });
                                                            //#region FUNCOES
                                                            const geraDropDownAgenda = (dropdownAgenda, tipo) => {
                                                                return dropdownAgenda.map((a) => {
                                                                    //VARS\\
                                                                    let legenda = getLegendaAgendamento(a, agendamentos);
                                                                    let horaFinalDiferente;
                                                                    let horaFinal;
                                                                    if (a.data_atendimento_final) {
                                                                        if (a.data_atendimento_final.includes('T')) {
                                                                            horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                                                            horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
                                                                        } else {
                                                                            horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                                                                            horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
                                                                        }
                                                                    }
                                                                    horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
                                                                    let div2style = {
                                                                        cursor: 'default',
                                                                        ...styles.trReserva
                                                                    };

                                                                    if (a.filtrado === undefined) {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                                                                            ...styles[legenda],
                                                                        };
                                                                    } else if (a.filtrado === true) {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                                                                            ...styles_filtred[legenda],
                                                                            ...filtered,
                                                                        };
                                                                    } else if (a.filtrado === false) {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                                                                            ...styles_escuro[legenda],
                                                                        };
                                                                    }

                                                                    // Ajustes específicos para reserva e substituição
                                                                    if (tipo === 'substituicao') {
                                                                        div2style = {
                                                                            ...div2style,
                                                                            backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                                                                        };
                                                                    }

                                                                    return (
                                                                        <div style={styles.agendamentoExtras} key={a.id}>
                                                                            <Dropdown
                                                                                button={
                                                                                    <svg
                                                                                        className='p-0 m-0'
                                                                                        style={{
                                                                                            ...styles.extraIcon,
                                                                                            ...(tipo === 'reserva'
                                                                                                ? styles.extraIcon.reserva
                                                                                                : (a.tipo_atendimento_id === 7)
                                                                                                    ? styles.extraIcon.substituicaoFeito
                                                                                                    : styles.extraIcon.substituicao
                                                                                            )
                                                                                        }}
                                                                                        id={`${tipo === 'reserva' ? 'agendamento-substituicao-icone' : 'agendamento-reserva-icone'}${a.id}`}
                                                                                        width="15"
                                                                                        height="15"
                                                                                        fill="currentColor"
                                                                                    >
                                                                                        <polygon points="0,0 15,0 0,15" />
                                                                                    </svg>
                                                                                }
                                                                                content={
                                                                                    <div id={`${tipo === 'reserva' ? 'agendamento-substituicao' : 'agendamento-reserva'}${a.id}`} style={div2style}>
                                                                                        <div className={horaFinalDiferente ? 'd-flex h-100per flex-wrap p-1' : 'd-flex h-100per align-content-between flex-wrap'}>
                                                                                            <div className='col-12' title={a.paciente_nome}>
                                                                                                {a.paciente_identificador}.
                                                                                            </div>
                                                                                            <div className='col-12'>
                                                                                                {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 10) : 'Sem Terapeuta'}.
                                                                                            </div>
                                                                                            {!horaFinalDiferente &&
                                                                                                <div className='hora-diferente d-inline-flex' title={horaFinal}>
                                                                                                    Hora Final: {horaFinal}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    );
                                                                });
                                                            };
                                                            //#endregion
                                                            if (agendaReserva.length > 0) {
                                                                reservaNoTitulo = geraDropDownAgenda(agendaReserva, 'reserva');
                                                            }
                                                            const agendaSubstituicao = !esperaMode ? agendamento.filter((a) => {
                                                                const substituicao = a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7;
                                                                return substituicao;
                                                            }) : [];
                                                            if (agendaSubstituicao.length > 0) {
                                                                substituicaoNoTitulo = geraDropDownAgenda(agendaSubstituicao, 'substituicao');
                                                            }
                                                            //#endregion
                                                        }
                                                        /* FINAL RETURN */
                                                        return (
                                                            <td style={{ ...styles.trVazio }}
                                                                key={`linha-agendamento-terapeuta${terapeuta.id}-horario${idHorario}`}
                                                                id={index2 + '-last-' + index}
                                                                data-tooltip-id={'tooltip-' + hora + '-' + terapeuta.id}>
                                                                <Tooltip
                                                                    id={'tooltip-' + hora + '-' + terapeuta.id}
                                                                    style={styles.agendamentoTooltip}
                                                                    place="top"
                                                                    effect="solid"
                                                                >
                                                                    {tooltipInfo}
                                                                </Tooltip>
                                                                <div className='d-grid' style={{ ...div2style, ...styles.tamanhoPadrao }} id={index2 + '-last-' + index + '-div'}>
                                                                    <div className='w-100per d-flex h-15px m-0 p-0'>
                                                                        <div style={styles.agendamentoExtras}></div>
                                                                        <div style={styles.divTitulo}>{titulo}</div>
                                                                        <div style={styles.agendamentoExtras}></div>
                                                                    </div>
                                                                    {agendaContent}
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                            return returns;
                                        })
                                        : <ClipLoader size={30} className='m-auto' />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>

            {isModalLegendaVisible
                ? <LegendaDefault onClose={() => setIsModalLegendaVisible(false)} />
                : (<></>)
            }
            {isModalResponsavelVisible
                ? <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />
                : (<></>)
            }
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento agendamentoId={agendamentoId} agendaButtons={true} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                : ''
            }
            <AgendaModal ref={agendaModalRef} onClose={() => setModalAgendaOpen(false)} mode={1} />
        </Recepcao >
    );
    //#endregion
}