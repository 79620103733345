import React, { useEffect, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';

import { Container } from './styles';
import TablePlanoSaude from './TablePlanoSaude';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import PlanoSaudeService from '../../../services/PlanoSaudeService';
import PlanoSaudeModal from '../../../components/Modais/PlanoSaudeModal';
import api from '../../../services/api';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function PlanoDeSaudeAdmin() {

    const [loading, setLoading] = useState(true);
    const color = '#8ed4ae';
    const [modalIsOpen, setIsOpen] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [obj, setObj] = useState({});

    const loadPlanos = async (reloadAll = false) => {
        let terapias = await api.get('api/terapia');
        setTerapias(terapias.data);
        let especialidades = await api.get('api/especialidade');
        setEspecialidades(especialidades.data);
        PlanoSaudeService.index('with=plano_saude_terapia,porcentagem_reajuste_planos')
            .then(async ({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPlanos(data);
            })
            .finally(() => setLoading(false));
    };

    const handleNewPlano = () => {
        setObj(undefined);
        setIsOpen(true);
    };
    useEffect(() => {
        loadPlanos();
    }, []);

    return (
        <Container>
            <button onClick={() => handleNewPlano()} className="btn-padrao">
                <AiFillFileAdd style={{ marginRight: 5 }} />
                Novo Plano
            </button>
            {(planos.length !== 0 && loading === false) ?
                <TablePlanoSaude setObj={setObj} setIsOpen={setIsOpen} callback={loadPlanos} source={planos} /> :
                <ClipLoader color={color} loading={loading} css={override} size={50} />
            }
            {modalIsOpen
                && <PlanoSaudeModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} callback={loadPlanos} data={obj} terapias={terapias} especialidades={especialidades} />
            }
        </Container>
    );
}
