import React, { useMemo } from 'react';
import { AiOutlineDelete, AiOutlineDownload, AiOutlineEye } from 'react-icons/ai';
import { TableDefault } from '../../../../components/TableDefault';
import moment from 'moment/moment';
import api from '../../../../services/api';

export function TableEspecialidades({ source, terapeuta, removeEspecialidade }) {

    const data = React.useMemo(() => source, [source]);

    const downloadFile = (id, name) => {
        api.get('api/terapeuta-especialidade/download/' + id + '?id=' + id, { responseType: 'blob' })
            .then((response) => {
                download(response, name);
            });
    };

    const download = (response, name) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Use o nome do arquivo do cabeçalho da resposta
        const contentDisposition = response.headers['content-type'];
        let fileName;
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.split('/');
            fileName = 'DIPLOMA - ' + name + '.' + fileNameMatch[1];
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Especialidades:',
                accessor: 'especialidade.especialidade',
            },
            {
                Header: 'Data:',
                accessor: 'data', Cell: ({ cell }) => {
                    return moment.utc(cell.row.original.data).format('DD/MM/YYYY');
                }
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (
                        <div style={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'center', backgroundColor: 'transparent', margin: 5
                        }}>
                            <AiOutlineDownload className="icone-padrao" onClick={(e) => downloadFile(cell.row.original.id, cell.row.original.especialidade.especialidade)} />
                            <AiOutlineDelete style={{ cursor: 'pointer' }} color="#7340BD" size={22} onClick={e => removeEspecialidade(cell.row.original)} />
                        </div>
                    )
                )
            }
        ],
        [terapeuta]
    );

    return (
        <TableDefault source={source} columns={columns} />
    );
}