import styled from 'styled-components';

export const ModalNovoPaciente = styled.div`
flex-direction: column; 
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 10;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`;


export const Container = styled.div`
display: flex;
flex-direction: column; 
background-color: #FFFFFF;
align-items: stretch;
padding: 30px;
color: #000;
width: 55vw;
height: 70vh;
border-radius: 10px`;

export const ModalPacienteStyle = {
height: 10,
margin: 'auto',
};

export const ButtonStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    height: 25,
    width: '35%',
    fontWeight: 'bold',
};

export const ButtonBuscaStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
    marginRight: 100,
    height: 25,
    width: '35%',
    fontWeight: 'bold',
    };

export const ButtonNovoPaciente = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    marginBottom: 10,
    height: 25,
    width: '35%',
    fontWeight: 'bold',
    };

export const DivButtonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
};

export const SubmitButton = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
    height: 25,
    fontWeight: 'bold',
    width: '15%'
};

export const TextAreaStyle = {
    height: 120,
    width: '100%',
    borderColor: '#F5F6F8',
    borderRadius: 8,
    borderWidth: 1,
    padding: 5
};

export const MainHeader = {
   
    color: '#7441BF',
    textAlign: 'center',
    marginBottom: 20,
};

export const SubHeaders = {
   
    color: '#7441BF',
    textAlign: 'left',
    marginTop: 8,
    fontWeight: 'bold'
};

export const ButtonStyle2 = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    border: 'none',
    padding: '2px 22px',
    borderRadius: 8,
    marginRight: 5,
    cursor: 'pointer'
};
