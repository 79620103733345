import React, { useEffect, useState } from 'react';
import Container from '../../components/Container';
import { useAuth } from '../../context/auth';
import {
  DivListaDocumentos,
  DivPrincipal,
  ItemLista,
  NomeDocumento,
  ParagrafoInformativoNumeroDocumentos,
  ParagrafoSaudacao,
} from '../DocumentoAssinatura/styles';
import { Link, useNavigate } from 'react-router-dom';

const DocumentosPendentes = () => {
  const { user } = useAuth();
  const [documentos, setDocumentos] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [numeroDocumentosPendentes, setNumeroDocumentosPendentes] = useState('00');

  const navigate = useNavigate();

  const handleTipoUser = (documentoId) => {
    let navUrl = `/dashboard/${user.tipo_user.toLowerCase()}/documentos-pendentes/usuario/`;
    if (user.tipo_user === 'responsavel') {
      navigate(navUrl += 'responsavel/' + documentoId);
    } else {
      navigate(navUrl += user.tipo_user.toLowerCase() + '/' + documentoId);
    }
  };

  useEffect(() => {
    let documentosPendentes = sessionStorage.getItem('@Neuro:docs_pending');
    if (documentosPendentes === null || documentosPendentes.length < 1) return;
    documentosPendentes = JSON.parse(documentosPendentes);
    setDocumentos(documentosPendentes);
    if (documentosPendentes.length > 0 && documentosPendentes.length < 10) {
      setNumeroDocumentosPendentes('0' + documentosPendentes.length);
      return;
    }
    setNumeroDocumentosPendentes(documentosPendentes.length);
  }, []);

  useEffect(() => {
    if (documentos === null) return;
    setIsLoaded(true);
  }, [documentos]);


  return (
    <Container>
      <DivPrincipal>
        <ParagrafoSaudacao>
          <span style={{ fontWeight: 'bold' }}>{user.tipo_user === 'responsavel' ? 'Olá, Resposável' : `Olá, ${user.name}`}</span>
        </ParagrafoSaudacao>
        <ParagrafoInformativoNumeroDocumentos>
          Você tem <span style={{ fontWeight: 'bold' }}>{numeroDocumentosPendentes}</span> documentos administrativos pendentes para assinar
        </ParagrafoInformativoNumeroDocumentos>
        {isLoaded &&
          <DivListaDocumentos>
            <span style={{ fontWeight: 'bold', marginLeft: 15 }}>Documentos:</span>
            <ul style={{ width: '100%' }}>
              {documentos.map((documento) => {
                return (
                  <ItemLista>
                    <NomeDocumento>{documento.nome_documento}</NomeDocumento>
                    <button onClick={e => handleTipoUser(documento.id)} style={{
                      backgroundColor: '#7340BD',
                      color: '#FFFFFF',
                      border: 'none',
                      padding: '5px 10px',
                      borderRadius: '8px',
                      width: '25%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      margin: '5px',
                    }}>Abrir</button>
                  </ItemLista>
                );
              })}
            </ul>
          </DivListaDocumentos>
        }
      </DivPrincipal>
    </Container>
  );
};

export default DocumentosPendentes;
