import api from './api';

class DocumentoAdministrativoService {
    static index = () => {
        return api.get('api/documento-administrativo');
    };

    static store = (obj) => {
        /* exemplo
        {"id_paciente":1,"nome":"aut","status":"error","path":"quae","user_request":52488}
        */
        const form = new FormData();
        Object.keys(obj).forEach((key) => {
            if (key === 'arquivo') {
                form.append(key, obj[key], obj[key].name);
            } else {
                form.append(key, obj[key]);
            }
        });
        return api.post('api/documento-administrativo', form);
    };

    static show = (id) => {
        return api.get(`api/documento-administrativo/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"id_paciente":1,"nome":"unde","status":"est","path":"voluptas","user_request":48613}
        */

        return api.put(`api/documento-administrativo/${id}`, obj);
    };

    static destroy = (id, data=null) => {
        return api.delete(`api/documento-administrativo/${id}`, {data});
    };

    static aprovar = (documento) => {
        return api.put(`api/documento/${documento}/aprovar`, documento);
    };
}

export default DocumentoAdministrativoService;
