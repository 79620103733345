import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import api from 'services/api';
import { ClipLoader } from 'react-spinners';

const SelectPaciente = ({
    name,
    defaultValue = null,
    plusWith = 'terapia_paciente',
    plusSelect = '',
    containerStyle,
    containerClass = '',
    maxHeight = '150px',
    label = false,
    hour = null,
    agendamentoId = null,
    agendamentos = null,
    controlStyle = {},
    readOnly,
    options,
    setCarregando = () => { },
    setTerapias = () => { },
    setPaciente = () => { },
    ...rest
}) => {
    //#region VARIAVEIS
    const selectRef = useRef(null);
    const carregaTerapias = plusWith.includes('terapia_paciente');
    const carregaDiagnostico1 = plusWith.includes('diagnostico1');
    const carregaDiagnostico2 = plusWith.includes('diagnostico2');
    const addonSelectTerapia = carregaTerapias ? ',terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude' : '';
    const addonSelectDiagnostico1 = carregaDiagnostico1 ? ',diagnostico1_id,diagnostico1.diagnostico' : '';
    const addonSelectDiagnostico2 = carregaDiagnostico2 ? ',diagnostico2_id,diagnostico2.diagnostico' : '';
    const urlSelect = `id,nome,identificador,status,aviso_agendamento_check${addonSelectTerapia}${addonSelectDiagnostico1}${addonSelectDiagnostico2}`;
    const { fieldName, registerField } = useField(name);
    const [pacienteOptions, setPacienteOptions] = useState();
    const [loading, setloading] = useState(false);
    const [realDefaultValue, setRealDefaultValue] = useState([]);
    //#endregion

    //#region FUNCOES
    //#region CHECKERS
    const checkAgendamentosPaciente = (paciente_id, horario) => {
        let isPaciente = false;
        if (horario) {
            agendamentos.forEach((agendamento) => {
                if (agendamento.paciente_id === paciente_id && agendamento.data_atendimento_inicial === horario._i && agendamento.id !== agendamentoId) isPaciente = true;
            });
        }
        return isPaciente;
    };
    //#endregion

    //#region GETTERS
    const getAddonForPaciente = (paciente) => {
        if (agendamentos && hour && agendamentoId) {
            return checkAgendamentosPaciente(paciente.id, hour)
                ? '(Indisponível)'
                : paciente.status === 'Ativo'
                    ? ''
                    : `(${paciente.status})`;
        }
        return paciente.status === 'Ativo' ? '' : `(${paciente.status})`;
    };

    const getPacientes = async () => {
        try {
            setloading(true);
            const url = `api/paciente/lista/simples?select=${urlSelect}${plusSelect ? ',' + plusSelect : ''}${plusWith ? '&with=' + plusWith : ''}`;
            const { data } = await api.get(url);

            const pacienteOptions = data.map((paciente, index) => {
                const addon = getAddonForPaciente(paciente);

                const diagnostico1 = carregaDiagnostico1 ? { diagnostico1: paciente.diagnostico1[0]?.diagnostico || '...' } : {};
                const diagnostico2 = carregaDiagnostico2 ? { diagnostico2: paciente.diagnostico2[0]?.diagnostico || '...' } : {};
                const terapiaPaciente = carregaTerapias ? { terapia_paciente: paciente.terapia_paciente || [] } : {};

                const plusSelectArray = plusSelect.split(',');
                const plusSelectFields = {};
                plusSelectArray.forEach((selectField) => {
                    plusSelectFields[selectField] = paciente[selectField];
                });

                return {
                    key: index,
                    value: paciente.id,
                    nome: paciente.nome,
                    identificador: paciente.identificador,
                    label: `(${paciente.identificador}) ${paciente.nome} ${addon}`,
                    aviso: paciente.aviso_agendamento_check,
                    ...diagnostico1,
                    ...diagnostico2,
                    ...terapiaPaciente,
                    ...plusSelectFields,
                };
            });

            pacienteOptions.sort((a, b) => a.nome.localeCompare(b.nome));
            setPacienteOptions(pacienteOptions);
        } catch (error) {
            console.error(error);
        } finally {
            setloading(false);
        }
    };
    //#endregion

    //#region HANDLES
    const handleChangePaciente = (e) => {
        setCarregando(true);
        const pacienteData = e;
        setPaciente(pacienteData);
        const terapiaPacientesList = pacienteData.terapia_paciente?.filter(filter => !filter.deleted_at)?.map(el => ({
            value: el.id,
            label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
            terapia_id: el.terapia_id,
            especialidade_id: el.especialidade_id,
            plano_saude_id: el.plano_saude_id,
            plano_saude: el.nome_reduzido,
            limite_diario_terapias: el.limite_diario_terapias,
            deleted_at: el.deleted_at,
        })) || [];
        setTerapias(terapiaPacientesList);
        setCarregando(false);
    };
    //#endregion

    //#region CUSTOM FILTER
    const customPacienteFilter = (option, inputValue) => {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (pacienteOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };
    //#endregion
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (!pacienteOptions) {
            if(options){
                setPacienteOptions(options);
            }else{
                getPacientes();
            }
        }
    }, [pacienteOptions]);

    useEffect(() => {
        if (pacienteOptions && defaultValue) {
            if (typeof defaultValue === 'number') {
                const paciente = pacienteOptions.find(({ value }) => value === defaultValue);
                if (paciente) {
                    handleChangePaciente(paciente);
                    setRealDefaultValue(paciente);
                }
            } else if (typeof defaultValue === 'object') {
                setRealDefaultValue(defaultValue);
            }
        }
    }, [pacienteOptions, defaultValue]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);
    //#endregion

    return (
        <div className={containerClass} style={{ width: '100%', display: 'flex', flexDirection: 'column' }} name='react_select__div'>
            {label && (
                <>
                    <label style={{ marginTop: 10, fontSize: 13 }}>{label}</label>
                </>
            )}
            <div style={{ position: 'relative' }}>
                <ReactSelect
                    defaultValue={realDefaultValue}
                    ref={selectRef}
                    classNamePrefix='react-select'
                    style={{ borderWidth: '2px' }}
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            border: '2px solid #ddd',
                            borderRadius: '8px',
                            height: '40px',
                            margin: 0,
                            ...controlStyle
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            borderRadius: '8px',
                            color: 'black',
                            overflowY: 'auto',
                            maxHeight: maxHeight,
                        }),
                    }}
                    filterOption={customPacienteFilter}
                    onChange={(e) => handleChangePaciente(e)}
                    autoComplete={`new-${fieldName}`}
                    options={pacienteOptions}
                    isDisabled={readOnly}
                    {...rest}
                />
                {loading && (
                    <div style={{ position: 'absolute', top: '57.5%', right: '8px', transform: 'translateY(-50%)', zIndex: 100 }}>
                        <ClipLoader size={22} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectPaciente;