import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 15vh;
`;

export const CelulaCabecalho = styled.th`
  background-color: #7441BF;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 2px;
  border-style: solid;
  font-size: 12px;
  text-align: center;
  align-items: center;
`;

export const LinhaTabela = styled.td`
  background-color: #fff;
  padding: 5px;
  width: 100px;
  height: 80px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 3px;
  border-style: solid;
  font-size: 14px;
`;

export const LinhaTabelaVazia = styled.div`
  background-color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 3px;
  border-style: solid;
  font-size: 14px;
`;

export const MultiSelectFiltro = styled.div`
  background-color: var(--roxo);
  border-radius: 10px;
  border-top-left-radius: 0px;
  margin-top: 12px;
  margin-left: 5px;
  padding: 5px 0px 0px 0px;
  border: 1px solid #ccc;
  min-width: 250px;
  color: white;

  .multiselect-container {
    padding: 15px;
  }

  .title {
     width: 100%;
     text-align: center;
     font-size: 16px;
     margin: 0px;
     border-bottom: 1px double #ccc;
  }

  .toolbox {
    display: grid;
    width: 100%;
    margin: 0px;
    color: white !important;
  }
  ul {
    padding: 0px;
    list-style: none;
    margin: 0px;

    li {
      padding: 0;
    }
  }

  .search-input {
    width: 100%;
    padding: 0px;
    border: none;
    margin-top: 10px;
    margin-bottom: 5px;
    input {
      border-radius: 15px;
    }
  }

  .filter-list {
    padding: 5px 10px 0px 10px;
    margin: 0px;
    list-style: none;
    width: 100%;
    max-height: 100px;
    overflow-y: auto;
    border: none;
  }
`;