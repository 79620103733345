import { useState } from 'react';
import api from 'services/api';

const useModalidadeData = () => {
    const [modalidadesOptions, setModalidadeOptions] = useState([]);

    const fetchModalidadeData = async () => {
        try {
            const { data } = await api.get('api/modalidade/');
            const options = data.map((modalidade) => ({
                value: modalidade.id,
                label: modalidade.modalidade
            }));
            setModalidadeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar modalidades:', error);
        }
    };

    return { modalidadesOptions, fetchModalidadeData };
};

export default useModalidadeData;