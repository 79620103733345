import api from './api';

class SalaService {
  static index = (params) => {
    return api.get(`api/sala?${params}`);
  };
  static store = (obj) => {
    return api.post('api/sala', obj);
  };
  static show = (id) => {
    return api.get(`api/sala/${id}`);
  };
  static update = (obj, id) => {
    return api.put(`api/sala/${id}`, obj);
  };
  static destroy = (id) => {
    return api.delete(`api/sala/${id}`);
  };
}

export default SalaService;
