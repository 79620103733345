import React from 'react';
import {Container, Modal} from './styles';

const LegendaStatusDiario = ({
                               onClose = () => {
                               },
                             }) => {
  const modalId = 'modalLegendaStatusDiario';

  const data = [
    {
      color: '#BCBCBC',
      texto: 'Sem Alteração',
      icon: null
    },
    {
      color: '#F5D70A',
      texto: 'Alteração Pendente',
      icon: null
    },
    {
      color: '#40C5EC',
      texto: 'Alteração Aprovada',
      icon: null
    },
    {
      color: '#35D058',
      texto: 'Alterado pela Recepção',
      icon: null
    },
    {
      color: '#EC4040',
      texto: 'Alteração Negada',
      icon: null
    },
  ];

  const handleOutsideClick = (e) => {
    if (e.target.id === modalId) onClose();
  };

  return (
      <Modal id={modalId} onClick={handleOutsideClick}>
        <Container>
          <div style={{position: 'relative'}}>
            <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
             right: '5px', fontSize: '25px', top: '-10px' }} onClick={() => onClose()}>
            x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <label style={{ fontWeight: '900', fontSize: '18', color: '#6A3BAF' }}>Legenda:</label>
          </div>
          {
            data.map((legendas, idx) =>
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'left',
                  padding: '10px'
                }} key={idx}>
                  <div style={{
                    backgroundColor: legendas.color,
                    height: '24px', width: '36px', borderRadius: '6px',
                    marginRight: '10px'
                  }} />
                  <label>{legendas.texto}</label>
                </div>
            )
          }

        </Container>
      </Modal>
  );
};

export default LegendaStatusDiario;
