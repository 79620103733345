import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import { CgAdd, CgArrowLeft, CgArrowRight, CgCopy } from 'react-icons/cg';
import { BsTrash } from 'react-icons/bs';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';

const styles = {
    method: {
        GET: {
            color: 'rgba(0, 200, 0, 1)',
            border: '1px solid rgba(0, 200, 0, 1)',
        },
        POST: {
            color: 'rgba(0, 200, 200, 1)',
            border: '1px solid rgba(0, 200, 200, 1)',
        },
        PUT: {
            color: 'rgba(0, 0, 200, 1)',
            border: '1px solid rgba(0, 0, 200, 1)',
        },
        DELETE: {
            color: 'rgba(205, 133,0, 1)',
            border: '1px solid rgba(205, 133,0, 1)',
        },
        PATCH: {
            color: 'rgba(100, 0, 200, 1)',
            border: '1px solid rgba(100, 0, 200, 1)',
        },
        HEAD: {
            color: 'rgba(0, 200, 0, 1)',
            border: '1px solid rgba(0, 200, 0, 1)',
        },
        OPTIONS: {
            color: 'rgba(200, 0, 100, 1)',
            border: '1px solid rgba(200, 0, 100, 1)',
        },
    },
};

const Postman = () => {
    const [endpoint, setEndpoint] = useState('');
    const [method, setMethod] = useState('GET');
    const [responseData, setResponseData] = useState(null);
    const [htmlResponse, setHTMLResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [modoVisualizacao, setModoVisualizacao] = useState('json');
    const [responseTime, setResponseTime] = useState();
    const [parameters, setParameters] = useState([{ name: '', value: '' }]);
    const [previewMountedRequest, setPreviewMountedRequest] = useState('');
    const [debugMode, setDebugMode] = useState(false);
    const [statusRespostaHTML, setStatusRespostaHTML] = useState('');
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [respostaMini, setRespostaMini] = useState(false);

    const formRef = React.createRef();
    const snackbar = useSnackbar();

    //#region HANDLES
    const handleParameterChange = (event, index, type) => {
        const values = [...parameters];
        values[index][type] = event.target.value;
        setParameters(values);
    };

    const handleCopy = async () => {
        const text = document.getElementById('p-copiavel').innerText;
        await navigator.clipboard.writeText(text);
        snackbar.displayMessage('Texto copiado para a área de transferência.', 'success');
    };

    const handleChangeQuery = (event) => {
        setEndpoint(event.target.value);
    };
    const handleChangeMethod = (event) => {
        setMethod(event.target.value);
    };

    const handleDeleteParametro = (index) => {
        const values = [...parameters];
        values.splice(index, 1);
        setParameters(values);
    };

    const handleSubmit = async () => {
        let start_time = new Date().getTime();
        const mountedEndpointArrayParam = await buildApiStringNoParameters();
        const mountedEndpoint = await buildApiString(true);
        if (debugMode) console.info(mountedEndpoint);
        setLoading(true);
        setResponseData('');
        try {
            let type = '';
            let response = null;
            switch (method) {
                case 'GET':
                    type = '';
                    response = await
                        api.get(mountedEndpoint)
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                console.error(error);
                                return error;
                            });
                    break;
                case 'POST':
                    type = '';
                    response = await
                        api.post(mountedEndpointArrayParam, getParametersArray())
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                console.error(error);
                                return error;
                            });
                    break;
                case 'PUT':
                    type = '';
                    response = await
                        api.put(mountedEndpointArrayParam)
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                console.error(error);
                                return error;
                            });
                    break;
                case 'DELETE':
                    type = '';
                    response = await
                        api.delete(mountedEndpoint)
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                console.error(error);
                                return error;
                            });
                    break;
                default:
                    break;
            }
            let end_time = new Date().getTime();
            let response_time = end_time - start_time;
            const data = response;
            setResponseTime(response_time);
            setResponseData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    //#endregion
    //#region FUNCOES
    const statusResposta = async (data) => {
        if (data) {
            if (data.status === 200) {
                return (
                    <div className='alert alert-success d-flex justify-content-between m-0 p-2 align-center flex-wrap'>
                        <p className='h-content-i m-0 p-0'>{data.statusText}</p><p className='h-content-i m-0 p-0'>Tempo da requisição: {responseTime} ms</p>
                    </div>
                );
            } else {
                return (
                    <div className='alert alert-danger d-flex justify-content-between m-0 p-0 align-center flex-wrap'>
                        <p>{data.statusText}</p><p>Tempo da requisição: {responseTime} ms</p>
                    </div>
                );
            }
        }
    };
    const randoText = (length = 6) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    const identificaTipo = (data) => {
        if (data) {
            if (typeof data === 'object') {
                return 'json';
            } else {
                return 'text';
            }
        }
    };
    const toCase = (data, type) => {
        if (data) {
            let convertedData = null;
            switch (type) {
                case 'uppercase':
                    convertedData = data.toUpperCase();
                    break;
                case 'lowercase':
                    convertedData = data.toLowerCase();
                    break;
                case 'capitalize':
                    convertedData = data.charAt(0).toUpperCase() + data.slice(1);
                    break;
                case 'titlecase':
                    convertedData = data.split('_').map((word) => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }).join(' ');
                    break;
                default:
                    break;
            }
            return convertedData;
        }
    };
    const tablefy = (data, isCollapsable = false, collapseName = '') => {
        if (data) {
            if (typeof data === 'object') {
                if (isCollapsable) {
                    if (collapseName === '') {
                        collapseName = randoText();
                    }
                    return (
                        <div className="card">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target={'#' + collapseName} aria-expanded="false" aria-controls={collapseName}>
                                            {collapseName}
                                        </button>
                                    </h5>
                                </div>
                                <div id={collapseName} className="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                    <div className="card-body">
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    {Object.keys(data).map((key) => {
                                                        return <th key={key}>{toCase(key, 'titlecase')}</th>;
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data[0] !== undefined
                                                    ? data.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {Object.values(item).map((value, index) => {
                                                                    if (identificaTipo(value) === 'json') {
                                                                        return <td key={index}> {tablefy(value, true, index)} </td>;
                                                                    } else {
                                                                        return <td key={index}>{value || 'Nulo'}</td>;
                                                                    }
                                                                })}
                                                            </tr>
                                                        );
                                                    })
                                                    :
                                                    <tr>
                                                        {Object.keys(data).map((key, index) => {
                                                            const value = data[key];
                                                            if (identificaTipo(value) === 'json') {
                                                                return <td key={index}>{tablefy(value, true, key)}</td>;
                                                            } else {
                                                                return <td key={index}>{value || 'Nulo'}</td>;
                                                            }
                                                        })}
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className='w-100'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        {Object.keys(data).map((key) => {
                                            return <th key={key}>{toCase(key, 'titlecase')}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[0] !== undefined
                                        ? data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    {Object.keys(item).map((value, index) => {
                                                        if (identificaTipo(value) === 'json') {
                                                            return <td key={index}> {tablefy(value, true, index)} </td>;
                                                        } else {
                                                            return <td key={index}>{value || 'Nulo'}</td>;
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        })
                                        :
                                        <tr>
                                            {Object.keys(data).map((key, index) => {
                                                const value = data[key];
                                                if (identificaTipo(value) === 'json') {
                                                    return <td key={index}>{tablefy(value, true, key)}</td>;
                                                } else {
                                                    return <td key={index}>{value || 'Nulo'}</td>;
                                                }
                                            })}
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    );
                }
            }
        }
    };
    const geraResposta = async (data, modoVisualizacao) => {
        if (data) {
            switch (modoVisualizacao) {
                case 'json':
                    return <pre>{JSON.stringify(data.data, null, 2)}</pre>;
                case 'text':
                    return JSON.stringify(data.data);
                case 'table':
                    return tablefy(data.data);
                default:
                    break;
            }
        }
    };
    const addParameter = () => {
        setParameters([...parameters, { name: '', value: '' }]);
    };
    const buildApiString = async (returnMode = false) => {
        let apiString = '';
        if (debugMode) console.info(parameters);
        if (debugMode) console.info(endpoint);
        if (debugMode) console.info(method);
        apiString = `api/${endpoint}`;
        if (parameters.length > 0) {
            const params = parameters.map((param) => {
                if (param.name !== '') {
                    return `${param.name}=${param.value}`;
                }
            }).join('&');
            if (params) {
                apiString += `?${params}`;
            }
        }
        if (returnMode)
            return apiString;
        else
            setPreviewMountedRequest(apiString);
    };
    const revertMountedRequest = async (e) => {
        let mounted = e.target.value.replace(' ', '');
        setPreviewMountedRequest(mounted);
        let endpoint = mounted.replace('api/', '').split('?')[0];
        setEndpoint(endpoint);
        let parameters = mounted.match(/\?([^#]*)/);
        if (parameters) {
            let paramsArray = parameters[0].split('&');
            let paramsObj = {};
            paramsArray.forEach((param) => {
                let [name, value] = param.split('=');
                paramsObj[name] = value;
            });
            setParameters(
                Object.entries(paramsObj).map(([name, value]) => ({ name, value }))
            );
        } else {
            setParameters([]);
        }
    };
    const buildApiStringNoParameters = async () => {
        return `api/${endpoint}`;
    };
    const getParametersArray = () => {
        let arrParameters = [];
        parameters.map((param) => {
            if (param.name !== '') {
                arrParameters.push({ [param.name]: param.value });
            }
        });
        return arrParameters;
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(async () => {
        if (responseData) {
            let html = await geraResposta(responseData, modoVisualizacao);
            let status = await statusResposta(responseData);
            setStatusRespostaHTML(status);
            setHTMLResponse(html);
        }
    }, [responseData, modoVisualizacao]);
    useEffect(() => {
        if (debugMode)
            console.info(responseData, 'responseData');
    }, [responseData]);
    useEffect(() => {
        if (debugMode)
            console.info(modoVisualizacao, 'modoVisualizacao');
    }, [modoVisualizacao]);
    useEffect(() => {
        if (debugMode)
            console.info(method, 'method');
    }, [method]);
    useEffect(() => {
        if (debugMode)
            console.info(parameters, 'parameters');
    }, [parameters]);
    useEffect(() => {
        if (debugMode)
            console.info(endpoint, 'endpoint');
    }, [endpoint]);
    useEffect(() => {
        buildApiString();
    }, [endpoint, parameters, method]);
    //#endregion

    //#region HTML
    return (
        <div className='container'>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwGd2D4dmhD5vJMd7EhLlIiIb5yJ5B3QD3XZMKW" crossOrigin="anonymous"></script>
            <div className='row'>
                <div className='d-flex w-100 flex-wrap align-items-center justify-content-between p-0 m-0'>
                    <h2 className='mt-4 mb-0'>Postman</h2>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="debugMode" name="debugMode" onChange={() => setDebugMode(!debugMode)} />
                        <label className="form-check-label" htmlFor="debugMode">Habilita Modo Debug</label>
                    </div>
                </div>
                <div className='col-12 d-flex justify-content-between p-0 m-0 h-10px'>
                    <button type='button' className='btn-icone card' onClick={() => setSideBarOpen(!sideBarOpen)} style={{ margin: '-5px 0px 0px -10px', zIndex: '100', height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {sideBarOpen
                            ? <CgArrowLeft size={25} />
                            : <CgArrowRight size={25} />
                        }
                    </button>
                    <button type='button' className='btn-icone card' onClick={() => setRespostaMini(!respostaMini)} style={{ margin: '-5px -10px 0px 0px', zIndex: '100', height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {respostaMini
                            ? <CgArrowLeft size={25} />
                            : <CgArrowRight size={25} />
                        }
                    </button>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className={!sideBarOpen ? 'card col-1 p-4' : 'some'}>
                    </div>
                    <form ref={formRef} className={sideBarOpen ? `card p-4 col-${respostaMini ? '11' : '6'}` : 'some'} style={{ height: 'calc(100vh - 100px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                        <div className='col-12 mb-4 mt-2 p-0 d-flex flex-wrap'>
                            <div className='col-2 m-0 p-0'>
                                <label className='form-label' htmlFor='method'>Método:</label>
                                <select className='form-select' id="method" name="method" onChange={handleChangeMethod} style={styles.method[method]} required>
                                    <option value="GET" style={styles.method.GET}>GET</option>
                                    <option value="POST" style={styles.method.POST}>POST</option>
                                    <option value="PUT" style={styles.method.PUT}>PUT</option>
                                    <option value="PATCH" style={styles.method.PATCH}>PATCH</option>
                                    <option value="DELETE" style={styles.method.DELETE}>DELETE</option>
                                    <option value="HEAD" style={styles.method.HEAD}>HEAD</option>
                                    <option value="OPTIONS" style={styles.method.OPTIONS}>OPTIONS</option>
                                </select>
                            </div>
                            <div className='col-10 m-0 p-0'>
                                <label className='form-label' htmlFor='endpoint'>Endpoint:</label>
                                <div className='input-group'>
                                    <span className="input-group-text border-radios-none">api/</span>
                                    <input className='form-control border-radios-none' id='endpoint' name='endpoint' type="text" value={endpoint} onChange={handleChangeQuery} required />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-4 p-0 d-flex flex-wrap">
                            <div className='col-12 d-flex align-items-end justify-content-between mb-2'>
                                <label className='form-label m-0 align-items-end' htmlFor='parameters'>Parâmetros:</label>
                                <button className='btn btn-outline-success m-0' type='button' onClick={addParameter}>
                                    <CgAdd></CgAdd>
                                </button>
                            </div>
                            {parameters.map((param, index) => (
                                <div className='col-12 d-flex justify-content-between p-0 m-0 mt-2' key={index}>
                                    <div className="col-3 p-0 m-0">
                                        <div className='input-group'>
                                            <input type="text" placeholder="Chave..." className='form-control' value={param.name}
                                                onChange={(e) => handleParameterChange(e, index, 'name')} />
                                            <span className="input-group-text border-radios-n-rigth">=</span>
                                        </div>
                                    </div>
                                    <div className='col-9 p-0 m-0'>
                                        <div className='input-group'>
                                            <input
                                                className='form-control border-radios-none'
                                                placeholder='Valor...'
                                                value={param.value}
                                                onChange={(e) => handleParameterChange(e, index, 'value')}
                                            />
                                            <span className="input-group-text border-radios-n-left btn btn-danger pointer" onClick={() => handleDeleteParametro(index)}>
                                                <BsTrash></BsTrash>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='col-12 mb-4 p-0'>
                            <div className="col-12">
                                <label className='form-label' htmlFor='previewMountedRequest'>Requisição Montada:</label>
                            </div>
                            <div className='col-12'>
                                <div className='input-group'>
                                    <p className='form-control border-radios-n-right d-flex flex-row' style={{ minHeight: '38px', height: 'fit-content' }}>
                                        <b className='p-0 m-0 w-10per' style={{ ...styles.method[method], border: 'none' }}>
                                            {method} =
                                        </b>
                                        <textarea className='p-0 m-0 w-90per border-none bg-transparent' id='p-copiavel' name='p-copiavel' value={previewMountedRequest} style={{ wordBreak: 'break-all' }} onChange={(e) => revertMountedRequest(e)}>
                                            {previewMountedRequest}
                                        </textarea>
                                        <span className='input-group-text btn btn-outline-primary h-38px ml-10px' onClick={handleCopy}>
                                            <CgCopy></CgCopy>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button className='btn btn-primary' type='button' onClick={handleSubmit}>
                            {loading ? <ClipLoader color={'#800080'} loading={loading} size={20} /> : 'Consultar API'}
                        </button>
                    </form>
                    <div className={respostaMini ? 'card col-1' : 'some'}>
                    </div>
                    <div className={respostaMini ? 'some' : `card p-4 col-${sideBarOpen ? respostaMini ? '1' : '6' : '11'}`} style={{ height: 'calc(100vh - 100px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                        <div className={respostaMini ? 'some' : 'col-12 d-flex justify-content-between'}>
                            <h4>Resposta</h4>
                            <div className='w-100 d-flex justify-content-end'>
                                <div className="form-check me-4">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="json" onChange={(e) => setModoVisualizacao(e.target.id)} checked={modoVisualizacao === 'json'} />
                                    <label className="form-check-label" htmlFor="json">
                                        JSON
                                    </label>
                                </div>
                                <div className="form-check me-4">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="text" onChange={(e) => setModoVisualizacao(e.target.id)} checked={modoVisualizacao === 'text'} />
                                    <label className="form-check-label" htmlFor="text">
                                        Texto
                                    </label>
                                </div>
                                <div className="form-check me-4">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="table" onChange={(e) => setModoVisualizacao(e.target.id)} checked={modoVisualizacao === 'table'} />
                                    <label className="form-check-label" htmlFor="table">
                                        Tabela
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 overflow-auto">
                            {statusRespostaHTML}
                            {htmlResponse}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    //#endregion
};

export default Postman;