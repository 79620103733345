import { Form } from '@unform/web';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { useAuth } from 'context/auth';
import useIcons from 'context/icons';
import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import Swal from 'sweetalert2';

function LogModels() {
    //#region VARIAVEIS
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const formRef = useRef(null);
    const usuario = useAuth();
    const rota = `/dashboard/${usuario.user.route_tipo_user}/logs`;
    const { id } = useParams();
    const { iconArray } = useIcons();
    const [data, setData] = useState();
    const [saveOldData, setSaveOldData] = useState();
    const [enabled, setEnabled] = useState();
    const [message, setMessage] = useState();
    const [modelNameOptions, setModelNameOptions] = useState([]);
    const [operationOptions, setOperationOptions] = useState([]);
    const [selectedModelName, setSelectedModelName] = useState(null);
    const [selectedSubModelName, setSelectedSubModelName] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [observedColumns, setObservedColumns] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState([]);
    const [columnsArray, setColumnsArray] = useState([]);
    const [clickedButton, setClickedButton] = useState(null);/* 0: Cadastra e edita, 1: Cadastra e sai, 2: Cadastra e limpa */
    const [editable, setEditable] = useState(true);
    /* LISTAGEM DE LOGS */
    const [logsSource, setLogsSource] = useState([]);
    const columns = useMemo(() => [
        {
            Header: 'Usuário',
            accessor: 'user.name',
        },
        {
            Header: 'IP',
            accessor: 'ip',
        },
        {
            Header: 'ID da Tabela',
            accessor: 'model_id',
        },
        {
            Header: 'Informação Antiga Salva',
            accessor: 'old_data',
            Cell: ({ cell }) => {
                if (cell.value) {
                    return Object.entries(cell.value).map(([key, valor], index) => {
                        const newValor = typeof valor === 'object' ? JSON.stringify(valor) : valor;
                        return <span key={index}>{key}: {newValor}</span>;
                    });
                } else {
                    return <span>Sem data</span>
                }
            }
        },
        {
            Header: 'Informação Salva',
            accessor: 'data',
            Cell: ({ cell }) => {
                if (cell.value) {
                    return (
                        <ul className='text-left'>
                            {
                                Object.entries(cell.value).map(([key, valor], index) => {
                                    const newValor = typeof valor === 'object' ? JSON.stringify(valor) : valor;
                                    return <li key={`sd:${index}|${cell.row.original.id}`}>{key}: {newValor}</li>;
                                })
                            }
                        </ul>
                    )
                } else {
                    return <span>Sem data</span>
                }
            }
        },
        {
            Header: 'Criação',
            accessor: 'created_at',
            Cell: ({ cell }) => <pre> {moment(cell.value).format('DD/MM/YYYY')} </pre>
        },
    ], []);
    //#endregion

    //#region HANDLES
    const handleSubmit = async () => {
        const retorno = (created_id) => {
            switch (Number(clickedButton)) {
                case 0:/* CADSATRA E EDITA */
                    if (created_id) {
                        navigate(`${rota}/logs-models/${created_id}`);
                    } else {
                        navigate(rota);
                    }
                    break;
                case 1:/* CADASTRA E SAI */
                    navigate(rota);
                    break;
                case 2:/* CADASTRA E LIMPA */
                    setSelectedModelName(null);
                    setSelectedSubModelName([]);
                    setSelectedOperation([]);
                    setEnabled();
                    setSaveOldData();
                    setObservedColumns([]);
                    setMessage('');
                    setSelectedColumns([]);
                    setColumnsArray([]);
                    break;
                default:
                    navigate(rota);
                    break;

            }
        }
        try {
            let created_id = null;
            if (!selectedModelName?.value && !selectedOperation?.value) {
                return Swal.fire('Há campos não preenchidos', 'Você precisa preencher todos os campos orbigatórios.', 'error');
            }
            const obj = {
                'model_name': selectedModelName?.value,
                'submodel_name': selectedSubModelName?.value ? selectedSubModelName?.value : '',
                'operation': selectedOperation?.value,
                'enabled': enabled,
                'save_old_data': saveOldData,
                'observed_columns': observedColumns.map((col) => col.value).join(', '),
                'data_to_save': {
                    message: message,
                    columns: selectedColumns.map((col) => col.value)
                },
            }
            if (id) {/* ATUALIZA */
                api.put(`api/logs-models/${id}?id=${id}`, obj)
                    .then((response) => {
                        created_id = response.data.id;
                        snackbar.displayMessage('Registrado com sucesso!', 'success');
                        retorno(created_id);
                    })
                    .catch((error) => {
                        console.error(error);
                        snackbar.displayMessage('Não foi possível registrar log!', 'error');
                    });
            } else {/* CRIA */
                api.post('api/logs-models', obj)
                    .then((response) => {
                        created_id = response.data.id;
                        snackbar.displayMessage('Registrado com sucesso!', 'success');
                        retorno(created_id);
                    })
                    .catch((error) => {
                        console.error(error);
                        snackbar.displayMessage('Não foi possível registrar log!', 'error');
                    });
            }
            setClickedButton(null);
        } catch (error) {
            setClickedButton(null);
            console.error('Erro ao registrar:', error);
            alert('Erro ao criar o registro.');
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (logsSource.length > 0) {
            setEditable(false);
        }
    }, [logsSource]);
    useEffect(() => {
        if (selectedModelName) {
            setColumnsArray(selectedModelName.columns);
        }
    }, [selectedModelName]);
    useEffect(() => {
        if (clickedButton === 0 || clickedButton === 1 || clickedButton === 2) handleSubmit();
    }, [clickedButton]);
    useEffect(() => {
        if (data && operationOptions && modelNameOptions) {
            const selectedOpe = operationOptions.find((filter) => Number(filter.value) === Number(data.operation));
            setSelectedOperation(selectedOpe);
            const selectedTab = modelNameOptions.find((find) => find.value.toUpperCase() === data.model_name.toUpperCase());
            setSelectedModelName(selectedTab);
            const selectedSubTab = modelNameOptions.find((find) => find.value.toUpperCase() === String(data.submodel_name).toUpperCase());
            setSelectedSubModelName(selectedSubTab);
            setMessage(data?.data_to_save?.message);
            setSaveOldData(data.save_old_data);
            setEnabled(data.enabled);
            const selectedColumns = selectedTab?.columns?.filter((filter) => {
                return data?.data_to_save?.columns?.find(find => find === filter.value)
            })
            setSelectedColumns(selectedColumns);
            const selectedObservedColumns = selectedTab?.columns?.filter((filter) => {
                return data?.observed_columns?.split(',')?.find(find => find === filter.value)
            })
            setObservedColumns(selectedObservedColumns);

        }
    }, [data, operationOptions, modelNameOptions]);
    useEffect(() => {
        if (id) {
            api.get(`api/logs-models/${id}?with=logs,logs.user`).then((response) => {
                if (response.data) {
                    setData(response.data);
                    setLogsSource(response.data.logs);
                }
            })
        }
        api.get('api/logs-models/get/operation/enum/')
            .then((response) => setOperationOptions(response.data))
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao coletar listagem de operações', 'error');
            });
        api.get('api/logs-models/get/models/name/')
            .then((response) => setModelNameOptions(response.data))
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao coletar listagem de tabelas', 'error');
            });
    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='p-0 m-0'>
            <div className='div-titulo w-100 mt-2 text-center align-items-center justify-content-center'>
                <label>{id ? 'Editar' : 'Cadastrar'} Modelo de Log</label>
            </div>
            <Form className='row p-0 m-0' ref={formRef} onSubmit={handleSubmit}>
                <div className='col-4 d-grid'>
                    <div className=''>
                        <Select type='text' title='Tabela de Referência:' id='model_name' name='model_name' value={selectedModelName} onChange={setSelectedModelName} options={modelNameOptions} readOnly={!editable} required />
                    </div>
                    {(selectedModelName) &&
                        <div className='card p-0 no-box-shadow border-input-default-i' style={{ marginTop: 12 }}>
                            <div className="card-body p-0">
                                <h5 className="card-title p-2 text-center" style={{ borderBottom: '1px solid #ccc' }}>{selectedModelName?.label}</h5>
                                <p className="card-text">
                                    <label className='font-14px px-2'>Descrição:</label>
                                    <p className='font-14px px-3 m-0 w-100per' style={{ borderBottom: '1px solid #ccc' }}>{selectedModelName?.description}</p>
                                    <label className='font-14px px-2 mt-2'>Colunas:</label>
                                    <table className='p-0 m-0 ps-2 w-100per'>
                                        <tbody className='overflow-y' style={{ maxHeight: '50vh' }}>
                                            {selectedModelName?.columns?.map((map) =>
                                                <tr>
                                                    <td className='ps-3' style={{ paddingBottom: 10 }}>
                                                        <span className='font-preto font-14px p-0 m-0'>{map.label}:</span><br></br>
                                                        <span className='font-cinza font-10px p-0 m-0' style={{ lineHeight: .2 }}>{map.value}</span>
                                                    </td>
                                                    <td className='font-12px ps-1 pb-2' style={{ marginTop: 2.5 }}>
                                                        {map?.description}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <div className='col-8'>
                    <div className="row">
                        <div className='col-sm-12 col-lg-6'>
                            <Select type='text' title='Nome da Sub Tabela' id='submodel_name' placeholder={'Selecione a tabela interna...'} name='submodel_name' value={selectedSubModelName} onChange={setSelectedSubModelName} isClearable={true} options={modelNameOptions.filter((filter) => selectedSubModelName ? filter.value === selectedSubModelName.value : true)} readOnly={!editable} />
                        </div>
                        <div className='col-sm-12 col-lg-6'>
                            <Select title='Operação' id='operation' name='operation' placeholder={'Selecione a operação realizada...'} value={selectedOperation} onChange={setSelectedOperation} options={operationOptions} readOnly={!editable} required />
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <Select title='Colunas observadas:' id='observed_columns' name='observed_columns' options={columnsArray} placeholder={'Selecione quais colunas em específico serão observadas pelo log...'} value={observedColumns} onChange={setObservedColumns} isMulti={true} readOnly={!editable} />
                        </div>
                        <div className='col-sm-12 col-lg-1'>
                            <Input type='checkbox' title='Antigos' id='save_old_data' name='save_old_data' defaultChecked={data?.save_old_data} onChange={(e) => setSaveOldData(e.target.checked)} disabled={!editable} />
                        </div>
                        <div className='col-sm-12 col-lg-1'>
                            <Input type='checkbox' title='Habilitado' id='enabled' name='enabled' defaultChecked={data?.enabled} onChange={(e) => setEnabled(e.target.checked)} />
                        </div>
                        <div className='col-sm-12 col-lg-10'>
                            <Input type='text' title='Mensagem' id='message' name='message' placeholder={'Digite a mensagem customizada que será salva no log...'} defaultValue={data?.data_to_save?.message} onChange={(e) => setMessage(e.target.value)} disabled={!editable} />
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <Select title='Colunas:' id='columns' name='columns' options={columnsArray} placeholder={'Selecione quais colunas em específico deseja salvar...'} value={selectedColumns} onChange={setSelectedColumns} isMulti={true} />
                        </div>
                        <div className='btn-space-40px d-flex pb-1 w-70vw-i text-center justify-content-between position-fixed bottom-0 center-horizontal pt-10px bg-branco border-radius-top-10px shadow-1'>
                            <Button type='button' onClick={() => navigate(rota)}>Voltar {React.cloneElement(iconArray['seta-esquerda'], { size: 20, className: 'ms-2 font-16px' })}</Button>
                            {data?.logs?.length > 0
                                ? <>
                                    <Button type='button' onClick={() => setClickedButton(1)}>{id ? 'Atualizar' : 'Cadastrar'} e Sair {React.cloneElement(iconArray['salvar'], { size: 20, className: 'ms-2 font-16px' })}</Button>
                                </>
                                : <>
                                    <Button type='button' onClick={() => setClickedButton(1)}>{id ? 'Atualizar' : 'Cadastrar'} e Sair {React.cloneElement(iconArray['salvar'], { size: 20, className: 'ms-2 font-16px' })}</Button>
                                    <Button type='button' onClick={() => setClickedButton(0)}>{id ? 'Atualizar' : 'Cadastrar'} e Editar {React.cloneElement(iconArray['editar'], { size: 20, className: 'ms-2 font-16px' })}</Button>
                                    <Button type='button' onClick={() => setClickedButton(2)}>{id ? 'Atualizar' : 'Cadastrar'} e Limpar {React.cloneElement(iconArray['limpar-geral'], { size: 20, className: 'ms-2 font-16px' })}</Button>
                                </>
                            }
                        </div>
                    </div>
                    {id
                        && <div className="row pt-3">
                            <label className='font-12px'>Listagem de Logs:</label>
                            <TableDefaultPagination source={logsSource} columns={columns} emptyPhrase={'Nenhum log baseado nesse modelo.'} />
                        </div>
                    }
                </div>
            </Form>
        </div>
    );
    //#endregion
}

export default LogModels;