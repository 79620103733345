import React, { useEffect, useMemo, useRef, useState } from 'react';
import Carregamento from '../../../../components/Carregamento';
import { useAuth } from '../../../../context/auth';
import api from '../../../../services/api';
import { Container } from '../styles';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { BsBlockquoteRight, BsEye } from 'react-icons/bs';
import Swal from 'sweetalert2';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { ChangeCase } from 'services/ChangeCase';
import { FiCheckCircle, FiEdit, FiEye, FiXCircle } from 'react-icons/fi';

export default function ListagemDeGlosas() {
    const { user } = useAuth();
    const [permissoes, setPermissoes] = useState([]);
    const [loading, setloading] = useState(false);
    const [glosasList, setGlosasList] = useState([]);
    const [glosasListOriginal, setGlosasListOriginal] = useState([]);
    const [selectedEvolucao, setSelectedEvolucao] = useState([]);
    const relatorioEdicaoModalRef = useRef();
    const columns = useMemo(() => [
        {
            Header: 'Terapeuta',
            accessor: 'terapeuta_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Paciente',
            accessor: 'paciente_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Status',
            accessor: 'status_de_glosa',
            filterable: true,
            Cell: (({ cell }) => {
                const style = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px', margin: 'auto' };
                const status = ChangeCase.toSnakeCase(String(cell.value).toLowerCase());
                switch (status) {
                    case 'aceito':
                        return (
                            <b style={{ ...style, backgroundColor: '#00ff00' }}>
                                Aprovado
                            </b>
                        );
                    case 'glosado':
                        return (
                            <b style={{ ...style, backgroundColor: '#ff0000', color: 'white' }}>
                                Glosado
                            </b>
                        );
                    case 'glosa_aceita':
                        return (
                            <b style={{ ...style, backgroundColor: '#0000ff', color: 'yellow' }}>
                                Glosa Aceita
                            </b>
                        );
                    case 'sem_evolução':
                        return (
                            <b style={{ ...style, backgroundColor: '#f0f0f0' }}>
                                Sem Evolução
                            </b>
                        );
                    case 'pendente':
                        return (
                            <b style={{ ...style, backgroundColor: '#ffff00' }}>
                                Pendente
                            </b>
                        );
                    default:
                        return (
                            <b style={{ ...style, backgroundColor: '#ccc' }}>
                                ...
                            </b>
                        );
                }
            })
        },
        {
            Header: 'Ações',
            Cell: (({ cell }) => {
                const id = cell.row.original.evolucao_paciente_id;
                setSelectedEvolucao(cell.row.original);
                return (
                    <div className="w-100per d-flex justify-content-center ">
                        {id &&
                            <button className="btn-icone" type="button" onClick={() => relatorioEdicaoModalRef?.current.openModal(id)}>
                                <BsEye size={20}></BsEye>
                            </button>
                        }
                        <button className='btn-icone' onClick={() => { }}>
                            <FiEye size={22} color='#7340BD' />
                        </button>
                        <button className='btn-icone' onClick={(e, row) => { }}>
                            <FiEdit size={22} color="#7340BD" />
                        </button>
                        <button title='Botão que define a linha como desistênte.' onClick={() => { }} color="#7340BD" className='btn-icone'>
                            <FiXCircle color="#7340BD" size={22} />
                        </button>
                        <button title='Botão que define a linha como aceita.' onClick={() => { }} color="#7340BD" className='btn-icone'>
                            <FiCheckCircle color="#7340BD" size={22} />
                        </button>
                    </div>
                );
            })
        }
    ]);

    /**
     * Função que coleta permissões do usuário para a página atual.
     * 
     * A função percorre a lista de permissões do usuário e verifica se a permissão
     * está relacionada à página atual. Se estiver, adiciona a permissão à lista de
     * permissões locais.
     * 
     * @returns {void}
     */
    const coletaPermissoes = () => {
        let permissoes = user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    };

    async function carregaAtendimentosEGlosas() {
        try {
            const { data } = await api.post('api/atendimento/relatorio/filtrado', {
                // Adicione os parâmetros necessários aqui
                paciente_id: null,
                tipo_atendimento_id: null,
                data_inicio: null,
                data_fim: null,
                terapeuta_id: null,
                status_atendimento_id: null,
                liberado: null,
                executado: null,
                especialidade_id: null,
                terapia_id: null,
                plano_saude_id: null,
                status_de_assinatura: null,
            });
            setGlosasList(data);
            setGlosasListOriginal(data);
        } catch (e) {
            console.error(e);
        }
    }

    const onLoad = async () => {
        setloading(true);
        await carregaAtendimentosEGlosas();
        await coletaPermissoes();
        setloading(false);
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Container>
            {loading ? <Carregamento></Carregamento> : ''}
            <div className='div-titulo w-100 text-center'>
                <label className='mx-auto'>Atendimentos e Glosas</label>
            </div>
            <TableDefaultPagination columns={columns} source={glosasList} searchBar={true} tableTitle='Atendimentos e Glosas'></TableDefaultPagination>
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} onClose={() => { }} tipo={'visualizacao'} />
        </Container>
    );
}