import React, { useMemo, useRef, useState } from 'react';
import { TableDefault } from '../../../../components/TableDefault';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { BsDownload, BsEye } from 'react-icons/bs';
import A4Page from 'components/Folha/A4-Retrato';
import api from 'services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export function TableUltimosAtendimentos({ source, statusAtendimento }) {

    const data = React.useMemo(() => source, [source]);
    const relatorioEdicaoModalRef = useRef();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: 'Data:',
                accessor: 'data_atendimento',
            },
            {
                Header: 'Status:',
                accessor: 'status',
            },
            {
                Header: 'Estação:',
                accessor: 'estacao',
                Cell: ({ cell }) => {
                    return (cell.row.original.estacao + ' - ' + cell.row.original.sala);
                }
            },
            {
                Header: 'Identificador:',
                accessor: 'identificador',
            },
            {
                Header: 'Plano de Saúde:',
                accessor: 'plano_saude',
            },
            {
                Header: 'Terapia:',
                accessor: 'terapia',
            },
            {
                Header: 'Especialidade:',
                accessor: 'especialidade',
            },
            {
                Header: 'Ações',
                accessor: 'evolucao_paciente',
                Cell: (({ cell }) => {
                    if (cell.row.original.evolucao_paciente) {
                        let id = cell.row.original.evolucao_paciente.id;
                        return (
                            <div className="w-100per">
                                <button className="btn-icone" type="button" onClick={() => {
                                    relatorioEdicaoModalRef?.current.openModal(id);
                                }}>
                                    <BsEye size={22}></BsEye>
                                </button>
                                <button className="btn-icone" type="button" onClick={() => navigate(`/dashboard/terapeuta/gerador-de-documento/${id}`)}>
                                    <BsDownload size={22} />
                                </button>
                            </div>
                        );
                    } else {
                        return ('');
                    }
                })
            }
        ]
    );

    return (
        <>
            {isGeraDocumentoOpen
                && <A4Page
                    onCloseClick={setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                />}
            <TableDefault source={data} columns={columns} />
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} tipo={'visualizacao'} />
        </>
    );
}