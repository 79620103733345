import { Form } from "@unform/web";
import { SimpleSelect } from "components/Select";
import moment from "moment";
import { ContagemPendentes } from "pages/TerapeutaDashboard/TerapeutaPacientes/styles";
import { FiFilter } from "react-icons/fi";
import { IoMdOpen } from "react-icons/io";
import { IoAddCircleOutline, IoAddOutline } from "react-icons/io5";
import { BsBell, BsEye } from 'react-icons/bs';
import React from "react";
import VirtualizedTable from "components/Virtualized/virtualized-table";

const SelfLibVirtualizedTable = ({
    atendimentos,
    tableClassName,
    AtendimentoService,
    statusAtendimento,
    getStatusSelecionadoByValue,
    permissoes,
    handleAbreSolicitacaoDeAlteracao,
    handleVerSolicitacao,
    linhasSolicitacao,
    handleOpenModalAtendimento,
    handleOpenSolicitacaoDeAlteracao,
    isEditable,
    usuario,
    pacienteSelecionado,
    handleFiltraPaciente,
    controleDiario,
    handleOpenModalCheckin,
    handleChangeStatusRecepcao,
    opcoesLiberado,
    handleChangeAtendimentoLiberado,
    pacienteEmExecucaoList,
    handleOpenModalPendencias,
    handleModalComentarios,
    tableHeader,
    tableFooter,
    containerHeight
}) => {
    //#region HTML
    return (
        <VirtualizedTable tableBodyClassName={tableClassName} tableHeadClassName={tableClassName} tableFootClassName={tableClassName} tableFoot={tableFooter} tableHeader={tableHeader} rows={atendimentos} rowHeight={66} containerHeight={containerHeight}
            rowMap={({ row, key, styles }) => {
                const atendimento = row;
                const hora = atendimento.hora_atendimento;
                const atendimento_alteracaos = atendimento.atendimento_alteracaos;
                let dados = [];
                dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
                dados['paciente'] = `(${atendimento.paciente_id}) ${atendimento.paciente_identificador}`;
                dados['paciente_nome'] = `${atendimento.paciente_nome}`;
                dados['paciente_id'] = atendimento.paciente_id;
                dados['terapia'] = atendimento.terapia;
                dados['especialidade'] = atendimento.especialidade;
                dados['plano_saude'] = atendimento.nome_reduzido;
                dados['sala'] = atendimento.sala;
                dados['estacao'] = atendimento.estacao;
                dados['terapia'] = atendimento.terapia;
                dados['especialidade'] = atendimento.especialidade;
                dados['sala'] = atendimento.sala;
                dados['estacao'] = atendimento.estacao;
                dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
                dados['modalidade'] = atendimento.modalidade;
                dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento;
                dados['usuario_acesso_execucao_id'] = atendimento.usuario_acesso_execucao_id;
                dados['ultimo_acesso_execucao'] = atendimento.ultimo_acesso_execucao;
                dados['id'] = atendimento.id;
                dados['terapeuta_status'] = atendimento.sugestao_terapeuta;
                dados['recepcao_status'] = atendimento.sugestao_recepcao;
                dados['terapeuta_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_terapeuta) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_terapeuta).label;
                dados['recepcao_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao).label;
                dados['liberado'] = atendimento.liberado;
                dados['checkin'] = atendimento.checkin;
                dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
                dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
                dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
                dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
                dados['comentario'] = atendimento.comentario;
                dados['guia'] = atendimento.guia;
                dados['executado'] = atendimento.executado;
                dados['codigo_1'] = atendimento.codigo_1;
                dados['codigo_2'] = atendimento.codigo_2;
                dados['modo_criacao'] = atendimento.modo_criacao;
                let horaDiferente = dados['data_inicio_final'] !== dados['data_final'];
                let statusValue = getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao);
                let contaSolicitacaoPendente = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Aguardando').length;
                let contaSolicitacaoReprovada = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Reprovado').length;
                let contaSolicitacaoAprovada = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Aprovado').length;
                const guiaStyle = !dados['guia'] ? { borderLeft: '1px #f00 solid' } : {};
                const subStyle = atendimento.substituido ? { borderRight: '1px #e16ff2 solid' } : {};
                return (
                    <tr key={key} styles={styles}>
                        <td className='td-hora p-0 m-0'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span style={{ width: 25, height: 25 }}></span>
                                <span>{hora.split(':')[0] + ':' + hora.split(':')[1]}</span>
                                <span className={`d-flex align-items-center justify-content-center ${String(dados['modo_criacao'])?.slice(0, 1)?.toUpperCase() === 'M' ? 'bg-roxo-dark font-branca' : 'bg-cinza-claro'}`} style={{ borderRadius: '50%', border: '1px solid purple', padding: '2px', width: 25, height: 25, marginRight: -12 }}>
                                    {String(dados['modo_criacao'])?.slice(0, 1)?.toUpperCase()}
                                </span>
                            </div>
                        </td>
                        {/*TERAPEUTA*/}
                        <td style={{ ...guiaStyle, ...subStyle }}
                            className={!horaDiferente ? 'td-content cd-col-1' : 'td-content cd-col-1 p-0 btlr-0 bblr-0'}>
                            {horaDiferente
                                ? <div className='div-nome-terapeuta'>
                                    <div className="hora-diferente">
                                        {dados['data_final']}
                                    </div>
                                    <div className='nome-terapeuta'>
                                        {dados['terapeuta']}
                                    </div>
                                </div>
                                : <div className='nome-terapeuta'>
                                    {dados['terapeuta']}
                                </div>
                            }
                        </td>
                        {/*TERAPIA/ESPECIA*/}
                        <td className='td-content cd-col-2'>
                            {dados['terapia']}
                            <br /> {dados['especialidade']}
                        </td>
                        {/*ALTERAÇÃO*/}
                        <td className='td-content cd-col-3 p-0' style={{ backgroundColor: atendimento.cor }}>
                            <div className='w-100 d-flex justify-content-end'>
                                {(atendimento.solicitacao_de_alteracaos && atendimento.solicitacao_de_alteracaos.length > 0) ?
                                    <>
                                        <ContagemPendentes type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                            style={{
                                                backgroundColor:
                                                    (contaSolicitacaoAprovada !== 0)
                                                        ? 'green'
                                                        : (contaSolicitacaoPendente === 0 && contaSolicitacaoReprovada === 0)
                                                            ? 'gray'
                                                            : contaSolicitacaoPendente > contaSolicitacaoReprovada
                                                                ? 'orange'
                                                                : 'gray'
                                            }}>
                                            {atendimento.solicitacao_de_alteracaos.length}
                                        </ContagemPendentes>
                                        <ul className="dropdown-menu dropdown-menu-end w-300px h-auto-i p-4">
                                            <li key={'solicitacao:title-atendimento:' + atendimento.id}><h6 className='font-preta'>Solicitações de Alteração</h6></li>
                                            {atendimento.solicitacao_de_alteracaos.map((solicitacao, index) => {
                                                let corSolicitacao = solicitacao.status.toLowerCase();
                                                return (
                                                    <li key={'solicitacao:' + index + '-atendimento:' + atendimento.id}>
                                                        <button type="button" className={`w-100 btn btn-${corSolicitacao === 'aguardando' ? 'warning' : corSolicitacao === 'aprovado' ? 'success' : '</li>danger'}`}
                                                            onClick={() => corSolicitacao === 'aguardando' ? handleAbreSolicitacaoDeAlteracao(solicitacao) : handleVerSolicitacao(solicitacao)}
                                                            style={linhasSolicitacao[corSolicitacao]}>
                                                            Hora: {moment.utc(solicitacao.created_at).format('HH:mm')}
                                                            <IoMdOpen size={20} className='ml-20px' />
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                    : <div style={{ width: '18px', height: '18px', margin: '0', padding: '0' }}></div>
                                }
                            </div>
                            <div className='w-100 d-flex justify-content-center m-0 p-0'>
                                {permissoes['criacao_edicao'] && usuario?.user?.tipo_user.toUpperCase() !== 'AGENDAMENTO'
                                    ? (atendimento_alteracaos)
                                        ? <button className="btn-outline font-12px m-0" title='Visualizar alteração.' onClick={() => handleOpenModalAtendimento(atendimento, false)}>
                                            <BsEye size={20}></BsEye>
                                        </button>
                                        : <button className="btn-outline font-12px m-0" title='Adicionar nova alteração.' onClick={() => handleOpenModalAtendimento(atendimento, isEditable)}>
                                            {
                                                isEditable
                                                    ? <IoAddCircleOutline size={20}></IoAddCircleOutline>
                                                    : <BsEye size={20}></BsEye>
                                            }
                                        </button>
                                    : <button
                                        className="btn-outline font-12px m-0 p-0"
                                        style={{
                                            position: 'relative',
                                            border: '1px #000 solid',
                                            borderRadius: '50%',
                                        }}
                                        title='Adicionar nova alteração.'
                                        onClick={() => handleOpenSolicitacaoDeAlteracao(atendimento)}>
                                        <IoAddOutline size={12} style={{ position: 'absolute', top: '4px', right: '4px', }} />
                                        <BsBell size={20} />
                                    </button>

                                }
                            </div>
                            <div className='w-100'>
                                <div style={{ width: '18px', height: '18px', margin: '0', padding: '0' }}></div>
                            </div>
                        </td>
                        {/*PACIENTE*/}
                        <td className='td-content tooltip-container cd-col-4' data-tooltip-id={'atendimento' + dados['id']}>
                            <div className='d-flex flex-nowrap w-100 justify-content-between align-items-center'>
                                <div style={{ width: '24px' }}>
                                    <FiFilter className={`btn-icone pointer${dados['paciente_id'] === pacienteSelecionado ? ' some' : ''}`} size={24} onClick={() => handleFiltraPaciente(dados['paci</div>ente_id'])}></FiFilter>
                                </div>
                                {permissoes['criacao_edicao']
                                    ? <p className='p-2 pointer font-azul text-sublinhado' onClick={() => controleDiario?.salvo === null && handleOpenModalCheckin(dados['paciente_id'])}>
                                        {dados['paciente']}
                                    </p>
                                    : <p className='p-2'>
                                        {dados['paciente']}
                                    </p>
                                }
                                <div style={{ width: '24px' }}>
                                </div>
                            </div>
                            <div id={'atendimento' + dados['id']} className="tooltip-left" style={{ marginRight: 20 }}>
                                <div className='tooltip-text text-left w-fit-content-i'>
                                    {dados['paciente_nome']}
                                </div>
                            </div>
                        </td>
                        {/*S</div>ALA/ESTAÇÃO*/}
                        <td className='td-content cd-col-5'>
                            {dados['sala']} - {dados['estacao']}
                        </td>
                        {/*TIPO/MODALIDADE*/}
                        <td className='td-content cd-col-6'>
                            {dados['tipo_atendimento']} <br /> {dados['modalidade']}
                        </td>
                        {/*STATUS TERAPEUTA*/}
                        <td className='td-content cd-col-7'>
                            {dados['terapeuta_status_nome']}
                        </td>
                        {/*STATUS*/}
                        <td className='td-content cd-col-8'>
                            {permissoes['criacao_edicao']
                                ? <Form>
                                    <SimpleSelect
                                        id={`status-recepcao${dados['id']}`}
                                        name={`status-recepcao${dados['id']}`}
                                        className='form-select'
                                        options={statusAtendimento}
                                        readOnly={!isEditable}
                                        onChange={(e) => { handleChangeStatusRecepcao(e, atendimento, false); }}
                                        defaultValue={Number(atendimento.sugestao_recepcao)}
                                    />
                                </Form>
                                : statusValue.label
                            }
                        </td>
                        {/*LIBERADO*/}
                        <td className='td-content cd-col-9'>
                            {permissoes['criacao_edicao']
                                ? <Form>
                                    <SimpleSelect
                                        id={`status-liberado${dados['id']}`}
                                        name={`status-liberado${dados['id']}`}
                                        className='form-select'
                                        options={opcoesLiberado}
                                        readOnly={!isEditable}
                                        emptyOption={false}
                                        onChange={(e) => handleChangeAtendimentoLiberado(e, atendimento.id, false)}
                                        value={atendimento.liberado}
                                    />
                                </Form>
                                : opcoesLiberado.filter((e) => (e.value === String(atendimento.liberado)))[0].label
                            }
                        </td>
                        {/*STATUS EXECUCAO*/}
                        <td style={
                            (pacienteEmExecucaoList.filter((filter) => {
                                return Number(filter.paciente_id) === Number(dados['paciente_id']);
                            }).length > 0)
                                ? { border: '6px #fff000 solid' }
                                : {}
                        }
                            className={
                                (dados['executado'] === 'pendente')
                                    ? 'p-pendende td-content cd-col-10'
                                    : (dados['executado'] === 'executado')
                                        ? 'p-executado td-content cd-col-10'
                                        : (dados['executado'] === 'pago')
                                            ? 'p-executado td-content cd-col-10'
                                            : 'td-content cd-col-10'
                            }>
                            {dados['executado']}
                        </td>
                        {/*COMENTARIO*/}
                        <td className='td-content cd-col-11'>
                            {permissoes['criacao_edicao']
                                ? <BsEye className='icone-padrao' size={22} onClick={() => handleModalComentarios(dados)} />
                                : dados['comentario']
                            }
                        </td>
                        {/*P*/}
                        <th onClick={() => (atendimento?.conflitos.liberado_realizado_sem_codigo ||
                            atendimento?.conflitos.liberado_realizado_sem_codigo2 ||
                            atendimento?.conflitos.realizado_nao_liberado ||
                            atendimento?.conflitos.sem_execucao ||
                            atendimento?.conflitos.sem_guia ||
                            atendimento?.conflitos.sem_liberacao ||
                            atendimento?.conflitos.sem_status ||
                            atendimento?.conflitos.sem_terapeuta ||
                            atendimento?.conflitos.horario_paciente ||
                            atendimento?.conflitos.executado_nao_realizado ||
                            atendimento?.conflitos.sala_terapeuta)
                            && handleOpenModalPendencias(atendimento.conflitos)}
                            className={
                                (atendimento?.conflitos.liberado_realizado_sem_codigo ||
                                    atendimento?.conflitos.liberado_realizado_sem_codigo2 ||
                                    atendimento?.conflitos.realizado_nao_liberado ||
                                    atendimento?.conflitos.sem_execucao ||
                                    atendimento?.conflitos.sem_guia ||
                                    atendimento?.conflitos.sem_liberacao ||
                                    atendimento?.conflitos.sem_status ||
                                    atendimento?.conflitos.sem_terapeuta
                                ) ? 'td-p-pendente cd-col-12 pointer'
                                    : atendimento?.conflitos.horario_paciente || atendimento?.conflitos.sala_terapeuta || atendimento?.conflitos.executado_nao_realizado
                                        ? 'cd-col-12 bg-laranja-i'
                                        : 'td-p-ok cd-col-12'
                            }>
                        </th>
                    </tr>
                )
            }} />
    )
    //#endregion
}

export default SelfLibVirtualizedTable;