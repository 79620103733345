import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../context/auth';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
import { ClipLoader } from 'react-spinners';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
const DadosComplementaresModal = forwardRef(({ id = 'agendaModal', paciente_id }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Dados Complementares do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [paciente, setPaciente] = useState([]);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '40vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            padding: '0px',
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaPaciente(id);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaPaciente = async (id) => {
        setloading(true);
        setPaciente(null);
        await api.get(`/api/paciente/${id}/dados-complementares`).then(({ data }) => {
            setPaciente(data[0]);
        });
        setloading(false);
    };
    //#endregion
    useEffect(() => {/* ONLOAD */

    }, []);
    return (
        <>
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className="p-4">
                    {loading
                        ? <ClipLoader className='m-auto align-center justify-center' />
                        : (paciente !== undefined && paciente !== null)
                            ?
                            <ul className='font-20px'>
                                <li><b>Gosto de:</b> {paciente.gosto_de}</li>
                                <li><b>Nao gosto de:</b> {paciente.nao_gosto_de}</li>
                                <li><b>Meus reforcadores:</b> {paciente.meus_reforcadores}</li>
                                <li><b>Meu hiperfoco:</b> {paciente.meu_hiperfoco}</li>
                                <li><b>Informacoes complementares:</b> {paciente.informacoes_complementares}</li>
                            </ul>
                            : 'Sem paciente selecionado.'
                    }
                </div>
            </Modal>
        </>
    );
});
export default DadosComplementaresModal;