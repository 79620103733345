import React from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ListagemTopicos } from './styles';
import { FaArrowRight, FaChevronDown, FaGripLines } from 'react-icons/fa';
import { forwardRef } from 'react';
/**
 * Animates a draggable element to a specified position.
 *
 * @param {object} draggableRef - A reference to the draggable element.
 * @param {number} startX - The starting X position.
 * @param {number} startY - The starting Y position.
 * @param {number} endX - The ending X position.
 * @param {number} endY - The ending Y position.
 * @param {number} [duration=50] - The duration of the animation in milliseconds.
 * @param {function} [onEndAnimation=() => {}] - Callback function to be called at the end of the animation.
 */
const animateTo = (draggableRef, startX, startY, endX, endY, duration = 50, onEndAnimation = () => { }, animationType = 'transform') => {
    const startTime = performance.now();

    const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        const currentX = startX + (endX - startX) * progress;
        const currentY = startY + (endY - startY) * progress;
        switch (animationType) {
            case 'absolute':
                draggableRef.current.style.left = `${currentX}px`;
                draggableRef.current.style.top = `${currentY}px`;
                break;
            case 'transform':
                draggableRef.current.style.transform = `translate(${currentX}px, ${currentY}px)`;
                break;
            default:
                draggableRef.current.style.transform = `translate(${currentX}px, ${currentY}px)`;
                break;
        }

        if (progress < 1) {
            requestAnimationFrame(animate);
        } else {
            onEndAnimation();
        }
    };

    requestAnimationFrame(animate);
};
//#region TOPICOS
const Topicos = forwardRef(({ steps, selectedIndex = 0, toggleSelect = () => { } }, ref) => {
    //#region VARIAVEIS
    const [expandedIndex, setExpandedIndex] = useState(selectedIndex);
    const [isDragging, setIsDragging] = useState(false);
    /* REFS */
    const contentRef = useRef([]);
    const draggableRef = useRef(null);
    const animationFrameRef = useRef(null);
    //#endregion
    //#region FUNCOES
    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const moveTo = (corner = 'top-left' || 'top-right' || 'bottom-left' || 'bottom-right') => {
        const rect = draggableRef.current.getBoundingClientRect();
        const currentLeft = rect.left;
        const currentTop = rect.top;
        const margin = 10;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        switch (corner) {
            case 'top-left':
                animateTo(draggableRef, currentLeft, currentTop, margin, margin, 100, () => { }, 'absolute');
                break;
            case 'top-right':
                animateTo(draggableRef, currentLeft, currentTop, windowWidth - rect.width - margin, margin, 100, () => { }, 'absolute');
                break;
            case 'bottom-left':
                animateTo(draggableRef, currentLeft, currentTop, margin, windowHeight - rect.height - margin, 100, () => { }, 'absolute');
                break;
            case 'bottom-right':
                animateTo(draggableRef, currentLeft, currentTop, windowWidth - rect.width - margin, windowHeight - rect.height - margin, 100, () => { }, 'absolute');
                break;
            default:
                break;
        }
    };
    //#endregion
    //#region HANDLES
    useImperativeHandle(ref, () => {
        return {
            moveTo: moveTo,
            getBoundingClientRect: () => draggableRef.current.getBoundingClientRect(),
        };
    }, []);
    const handleMouseDown = () => {
        const rect = draggableRef.current.getBoundingClientRect();

        draggableRef.current.style.position = 'absolute';
        draggableRef.current.style.left = `${rect.left}px`;
        draggableRef.current.style.top = `${rect.top}px`;
        draggableRef.current.style.willChange = 'transform';
        setIsDragging(true);
    };
    const handleMouseMove = (e) => {
        if (isDragging === true) {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            animationFrameRef.current = requestAnimationFrame(() => {
                const x = e.clientX;
                const y = e.clientY;

                draggableRef.current.style.left = `${x}px`;
                draggableRef.current.style.top = `${y}px`;
            });
        }
    };
    const handleMouseUp = () => {
        if (isDragging === false) return;
        setIsDragging(false);

        // Tamanho da tela
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Posição atual do componente
        const rect = draggableRef.current.getBoundingClientRect();
        const x = rect.left;
        const y = rect.top;

        // Calcula a distância até cada quina
        const distanceToTopLeft = Math.hypot(x, y);
        const distanceToTopRight = Math.hypot(windowWidth - (x + rect.width), y);
        const distanceToBottomLeft = Math.hypot(x, windowHeight - (y + rect.height));
        const distanceToBottomRight = Math.hypot(windowWidth - (x + rect.width), windowHeight - (y + rect.height));

        // Encontra a quina mais próxima
        const closestCorner = Math.min(distanceToTopLeft, distanceToTopRight, distanceToBottomLeft, distanceToBottomRight);

        // Pega valores numéricos para `left` e `top`
        const currentLeft = x;
        const currentTop = y;

        // Definir margens mínimas para manter o componente visível (10px)
        const margin = 10;

        // Alinha o componente à quina mais próxima com animação
        if (closestCorner === distanceToTopLeft) {
            animateTo(draggableRef, currentLeft, currentTop, margin, margin, 100, () => { }, 'absolute');
        } else if (closestCorner === distanceToTopRight) {
            animateTo(draggableRef, currentLeft, currentTop, windowWidth - rect.width - margin, margin, 100, () => { }, 'absolute');
        } else if (closestCorner === distanceToBottomLeft) {
            animateTo(draggableRef, currentLeft, currentTop, margin, windowHeight - rect.height - margin, 100, () => { }, 'absolute');
        } else if (closestCorner === distanceToBottomRight) {
            animateTo(draggableRef, currentLeft, currentTop, windowWidth - rect.width - margin, windowHeight - rect.height - margin, 100, () => { }, 'absolute');
        }

        // Remover eventos de mouse
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        setExpandedIndex(selectedIndex);
    }, [selectedIndex]);
    useEffect(() => {
        if (isDragging === true) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            if (isDragging === false) {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [isDragging, handleMouseMove]);
    //#endregion

    //#region HTML
    return (
        <ListagemTopicos
            ref={draggableRef}>
            <div className='w-100per d-flex justify-content-between align-items-start flex-row unselectable'>
                <span className='w-50px pointer unselectable' onMouseDown={handleMouseDown}>
                    <FaGripLines size={25} />
                </span>
                <h4 className='mx-auto text-center font-bold'>Tópicos ({selectedIndex + 1}/{steps.length})</h4>
                <span className='w-50px'></span>
            </div>
            {steps.map((step, index) => (
                <div className={`topico-step${expandedIndex === index ? 'expanded' : ''}`} key={index}
                    style={{
                        backgroundColor: selectedIndex === index ? '#71FF77' : 'transparent',
                        color: selectedIndex === index ? 'black' : 'white',
                    }}
                >
                    <h3 className='unselectable'>
                        <span className='h-100per pointer' onClick={() => toggleSelect(index)}>
                            <span className='me-2'>
                                {selectedIndex === index && <FaArrowRight />}
                            </span>
                            <span>
                                {index + 1}. {step.title}
                            </span>
                        </span>
                        <span className='h-100per pointer' style={{ marginLeft: '10px' }} onClick={() => toggleExpand(index)}>
                            <FaChevronDown
                                style={{
                                    transform: `rotate(${expandedIndex === index ? '180deg' : '0deg'})`,
                                    transition: 'transform .5s ease-in'
                                }}
                            />
                        </span>
                    </h3>
                    <div
                        ref={el => contentRef.current[index] = el}
                        style={{
                            height: expandedIndex === index ? contentRef.current[index]?.scrollHeight : '0px',
                            overflow: 'hidden',
                            transition: 'height 0.5s ease',
                            ...expandedIndex === index ? {} : { border: 'none' }
                        }}
                    >
                        <p>{step.text}</p>
                    </div>
                </div>
            ))
            }
        </ListagemTopicos >
    );
    //#endregion
});
Topicos.displayName = 'Topicos';
//#endregion
export default Topicos;