import api from './api';

class PlanoTerapeuticoService {
    static status() {
        return {
            PENDENTE: 'Pendente',
            APROVADO: 'Aprovado',
            RECUSADO: 'Recusado',
            ASSINADO: 'Assinado'
        };
    }

    static index = () => {
        return api.get('/api/plano-terapeutico');
    };
    static store = (obj) => {
        /* exemplo
        {"paciente_id":1,"responsavel_id":1,"terapeuta_id":1,"terapia_id":1,"objetivo_geral":"Iure odio quibusdam enim. Ut sed enim tenetur placeat consequatur id. Saepe laborum et nihil autem dolores quibusdam velit.","objetivo_especifico":"Fuga aut suscipit sit adipisci. Error qui est aut. Sunt perspiciatis veniam deleniti amet et odit enim.","metodologias":"Natus cum voluptatum id similique blanditiis quis. Similique voluptas rem eaque sint cumque. Suscipit earum voluptatem ea eum et. Pariatur aut natus rerum sed."}
        */

        return api.post('/api/plano-terapeutico', obj);
    };
    static show = (id) => {
        return api.get(`/api/plano-terapeutico/${id}`);
    };
    static update = (obj, id) => {
        return api.put(`/api/plano-terapeutico/${id}`, obj);
    };
    static updateStatus = (obj, id) => {
        return api.post(`/api/plano-terapeutico/recusar/${id}`, obj);
    };
    static destroy = (id) => {
        return api.delete(`/api/plano-terapeutico/${id}`);
    };
}

export default PlanoTerapeuticoService;
