import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';
moment().utc();
const DadosBasicosModal = forwardRef(({ id = 'agendaModal' }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Dados Básicos do Funcionário';
    const [isOpen, setIsOpen] = useState(false);
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [funcionario, setFuncionario] = useState([]);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '40vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            padding: '0px',
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaFuncionario(id);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaFuncionario = async (id) => {
        setloading(true);
        setFuncionario(null);
        await api.get(`/api/user/${id}?with=plano_saude`).then(({ data }) => {
            setFuncionario(data);
        });
        setloading(false);
    };
    //#endregion
    useEffect(() => {/* ONLOAD */

    }, []);
    return (
        <>
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} appElement={document.getElementById('root')} transparent>
                <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div></div>
                        <ModalTitulo> {title} </ModalTitulo>
                        <label className="btn-outline text-right pointer" onClick={() => closeModal()}>
                            <CgClose size={22} className="poiner" color="red" />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='p-4'>
                    {loading
                        ? <ClipLoader className='m-auto align-center justify-center' />
                        : (funcionario !== undefined && funcionario !== null)
                            ?
                            <ul className='font-20px'>
                                <li style={{ listStyle: 'none' }}><b><u>Dados</u></b></li>
                                <li><b>Nome:</b> {funcionario?.name}</li>
                                <li><b>Nome curto:</b> {funcionario?.nome_curto}</li>
                                <li><b>CPF:</b> {funcionario?.cpf}</li>
                                <li><b>RG:</b> {funcionario?.rg}</li>
                                <li><b>Data de nascimento:</b> {funcionario?.data_nascimento !== null && moment.utc(funcionario.data_nascimento).format('DD/MM/YYYY')}</li>
                                <li><b>Data de entrada:</b> {funcionario?.data_entrada !== null ? moment.utc(funcionario.data_entrada).format('DD/MM/YYYY') : 'Sem data de entrada'}</li>
                                <li style={{ listStyle: 'none' }}><b><u>Contatos</u></b></li>
                                <li><b>Email:</b> {funcionario?.email}</li>
                                <li><b>Telefone 01:</b> {funcionario?.telefone1}</li>
                                <li><b>Telefone 02:</b> {funcionario?.telefone2}</li>
                                <li style={{ listStyle: 'none' }}><b><u>Endereço</u></b></li>
                                <li><b>CEP:</b> {funcionario?.cep}</li>
                                <li><b>Endereço:</b> {funcionario?.rua} {funcionario?.numero} - {funcionario?.cidade} {funcionario?.estado} </li>
                                <li><b>Bairro:</b> {funcionario?.bairro}</li>
                                <li><b>Complemento:</b> {funcionario?.complemento}</li>
                                {funcionario?.tipo_user === 'auditor' &&
                                    < li > <b>Plano Saúde:</b> {funcionario?.plano_saude?.nome_reduzido}</li>
                                }
                            </ul>
                            : 'Sem functionário selecionado.'
                    }
                </div>
            </Modal >
        </>
    );
});
export default DadosBasicosModal;