import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import api from '../../../services/api';
import { TableTerapeutas } from './TableTerapeutas';
import { Container, TerapeutaFilter } from './styles';
import { useNavigate } from 'react-router-dom';
import FiltroTerapias from '../../../components/Filter';
import Carregamento from '../../../components/Carregamento';
import Button from '../../../components/Button';

export default function ListagemTerapeutas() {

    const navigate = useNavigate();
    const { user } = useAuth();
    const [loadingDadosTerapeutas, setLoadingDadosTerapeutas] = useState(false);
    const [terapeutas, setTerapeutas] = useState([]);
    const [filterValue, setFilter] = useState('');
    const [terapeutaOriginal, setTerapeutaOriginal] = useState([]);

    const usuario = useAuth();
    const [permissoes, setPermissoes] = useState([]);
    const coletaPermissoes = () => {
        let permissoes = usuario.user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    };

    const handleSeachTerapeuta = (data) => {
        setFilter(data);
        setTerapeutas(terapeutaOriginal);
        setTerapeutas((terapeuta) =>
            terapeuta.filter((item) => item.nome.toString().toLowerCase().includes(data.toString().toLowerCase()))
        );
    };

    async function loadTerapeutas() {
        setLoadingDadosTerapeutas(true);
        await api.get('api/terapeuta?with=terapeuta_terapia,terapeuta_especialidades,terapeuta_especialidades.especialidade,terapeuta_terapia.terapium').then(({ data }) => {
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setTerapeutaOriginal(data);
            setTerapeutas(data);
            setLoadingDadosTerapeutas(false);
        }).catch((e) => {
            console.error(e);
            setLoadingDadosTerapeutas(false);
        });
    }

    useEffect(() => {
        setLoadingDadosTerapeutas(true);
        loadTerapeutas();
        coletaPermissoes();
    }, []);

    return (
        <Container>
            {loadingDadosTerapeutas ? <Carregamento></Carregamento> : ''}
            <div className="row mb-2">
                <div className="col-12 div-titulo">
                    <label>Terapeutas</label>
                </div>
                <div className="col-6 align-items-center">
                    <div className="form-group">
                        <label htmlFor="terapeuta" className="mb-1">Pesquisar Terapeuta por Nome:</label>
                        <input style={TerapeutaFilter} placeholder={'Pesquisar Terapeuta por Nome:'} value={filterValue || ''} onChange={(e) => { handleSeachTerapeuta(e.target.value); }} />
                    </div>
                </div>
                <div className="col-6">
                    <label htmlFor="terapeuta" className="mb-1">Mais Filtros:</label>
                    <FiltroTerapias
                        resultRef={setTerapeutas}
                        listaTerapeutas={terapeutas}
                        listaOriginalTerapeutas={terapeutaOriginal}
                        filtroNome={filterValue}
                        sourceStatus={terapeutaOriginal}
                        source={terapeutaOriginal}
                        setTerapeutas={setTerapeutas}
                        callBack={loadTerapeutas}
                    />
                </div>
                <div className="col-12 mb-2">
                    {(loadingDadosTerapeutas === false && terapeutas && terapeutas?.length > 0)
                        ? <TableTerapeutas source={terapeutas} carregaTerapeuta={loadTerapeutas} tipo_usuario={user.tipo_user} permissoes={permissoes} />
                        : ''
                    }
                </div>
                {permissoes['criação'] &&
                    <div className="align-center justify-content-center d-flex mb-20px col-12">
                        <Button onClick={() => navigate(`/dashboard/${usuario.user.tipo_user.toLowerCase()}/terapeutas/novo`)} className="btn-padrao">
                            <AiOutlinePlusCircle size={22} style={{ marginRight: 5 }} />
                            Cadastrar Terapeuta
                        </Button>
                    </div>
                }
            </div>
        </Container>
    );
}
