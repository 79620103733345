import React, {useEffect, useMemo} from 'react';
import {useTable} from 'react-table';
import {AiOutlineDelete} from 'react-icons/ai';
import { TableDefault } from '../../../../../components/TableDefault';
import { OpenButtonTable } from '../../styles';

export function TableDiplomas({ source, terapeuta}) {

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => [
            {
                Header: 'Nome:',
                accessor: 'diploma.nome',
            },
            {
                Header: 'Registro:',
                accessor: 'diploma.registro',
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({cell}) => (
                    (
                        <div style={{ display: 'flex', alignItems: 'center',
                            justifyContent: 'center', backgroundColor: 'transparent'}}>
                            <div style={{width: '18%'}}>
                                <button
                                    onClick={{}}
                                    style={OpenButtonTable}>Abrir
                                </button>
                            </div>
                        </div>
                    )
                )
            }
        ],
        [terapeuta]
    );
    
    return (
       <TableDefault source={source} columns={columns}/>
    );
}