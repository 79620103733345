import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import api from 'services/api';
import Swal from 'sweetalert2';
import { ModalNotificacao } from './ModalNotificacao';
import { TableDefaultPagination } from 'components/TableDefaultPagination';

export default function Notificacoes() {
    //#region VARIAVEIS
    const [notificacoes, setNotificacoes] = useState([]);
    const modalNotificacaoRef = useRef();
    const columns = useMemo(() => [
        {
            Header: 'Usuário',
            accessor: 'user.name',
            filterable: true,
        },
        {
            Header: 'Título',
            accessor: 'titulo',
            filterable: true,
        },
        {
            Header: 'Nível de Urgência',
            accessor: 'nivel_urgencia',
            filterable: true,
        },
        {
            Header: 'Tipo',
            accessor: 'tipo',
            filterable: true,
        },
        {
            Header: 'Modo de Envio',
            accessor: 'modo_envio',
            filterable: true,
        },
        {
            Header: 'Visualizado',
            accessor: 'visualizado',
            filterable: true,
            Cell: ({ value }) => (value ? 'Sim' : 'Não'),
        },
        {
            Header: 'Visualizar',
            accessor: 'id',
            filterable: true,
            Cell: ({ row }) => (
                <BsEye className='pointer' size={25} onClick={() => handleVisualizar(row.original)} />
            ),
        }
    ], []);
    //#endregion

    const handleVisualizar = (notificacao) => {
        Swal.fire({
            title: notificacao.titulo + ' - (Preview)',
            html: notificacao.conteudo,
            icon: notificacao.tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        });
    };

    const getNotificacao = async () => {
        try {
            api.get('api/notificacao?with=user')
                .then(({ data }) => {
                    setNotificacoes(data);
                })
                .catch(error => console.error(error));
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getNotificacao();
    }, []);

    return (
        <div className='container'>
            <h1>Notificações</h1>
            <TableDefaultPagination columns={columns} source={notificacoes} />
            <ModalNotificacao ref={modalNotificacaoRef} getNotificacao={getNotificacao} />
        </div>
    );
};