var baseURL = '';
/* const urls = [
    { url: 'localhost', base: 'http://127.0.0.1:8000/' },
    { url: '192.168.200.50', base: 'http://127.0.0.1:8000/' },
    { url: '127.0.0.1', base: 'http://127.0.0.1:8000/' },
    { url: 'localhost', base: 'http://127.0.0.1:8000/' },
    { url: '*', base: 'http://ec2-54-88-196-144.compute-1.amazonaws.com/' },
    { url: 'sistema.neurointensiva', base: 'https://sistema.neurointensiva.com/' },
]; */

switch (window.location.hostname) {
    case 'localhost':
    case '127.0.0.1':
        baseURL = 'http://localhost:8000/';
        break;
    case 'sistema.neurointensiva.com':
        baseURL = 'https://sistema.neurointensiva.com/';
        break;
    default:
        baseURL = 'http://ec2-54-88-196-144.compute-1.amazonaws.com/';
        break;
}
export default baseURL;