import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from '../../Input';
import TerapiumService from '../../../services/TerapiumService';
import { ClipLoader } from 'react-spinners';
import Button from '../../Button';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '40%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};
const customStylesLabel = {
    fontWeight: 'bold',
    marginTop: '10px',
    fontSize: '13px'
};

const TerapiaModal = ({ modalIsOpen, setIsOpen, terapium = null, callback }, ref) => {
    let subtitle;

    const [terapia, setTerapia] = useState('');
    const [cor_titulo, setCorTitulo] = useState('#000000');
    const [loading, setLoading] = useState(false);

    const formRef = useRef(null);

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);


    useEffect(() => {
        if (terapium && terapium.id) {
            setTerapia(terapium.terapia);
            setCorTitulo(terapium.cor_titulo);
        } else {
            setTerapia('');
            setCorTitulo('#000000');
        }
    }, [terapium]);

    async function handleSubmit() {
        const data = {
            terapia,
            cor_titulo,
        };

        if (terapium && terapium.id)
            return update(data, terapium.id);
        else
            return store(data);
    }

    const store = async (data) => {
        try {
            setLoading(true);
            await TerapiumService.store(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    };

    const update = async (data, terapiumId) => {
        try {
            setLoading(true);
            await TerapiumService.update(data, terapiumId);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    };


    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div className='flex-col'>
                    <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>{terapium === null ? 'Cadastrar ' : 'Alterar '}Terapia</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} className='flex-col'>
                        <div className='row d-flex'>
                            <div className='col-lg-6 col-sm-12'>
                                <Input style={{color: cor_titulo}} name={'terapia'} title={'Terapia'} value={terapia} onChange={(e) => setTerapia(e.target.value)} required={'required'} />
                            </div>
                            <div className='col-lg-6 col-sm-12 flex-col d-flex'>
                                <label style={customStylesLabel} htmlFor='cor_titulo'>Cor do titutlo</label>
                                <input name={'cor_titulo'} id={'cor_titulo'} style={{width: '100%', height: '40px', borderRadius:'8px'}} type={'color'} title={'Cor do titutlo'} value={cor_titulo} onChange={(e) => setCorTitulo(e.target.value)} required={'required'} />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : (terapium && terapium.id)
                                    ? 'Adicionar'
                                    : 'Alterar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(TerapiaModal);
