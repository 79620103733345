import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import Input, { InputArquivo } from '../../../Input';
import { Modal, ModalContainer } from './styles';
import Button from 'components/Button';
import DocumentosGeraisService from 'services/DocumentosGeraisService';

const ModalDocumentoFuncionario = (props, ref) => {

    const [documento, setDocumento] = useState(0);
    const [path, setPath] = useState(null);

    const id = 'modal-documento-funcionario';
    const formRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (e.target.id === id) props.onClose();
    };

    const handleChangeDocument = (e) => {
        var arquivo = document.getElementById('arquivo');
        var file = e.target.files[0].name;
        var size = e.target.files[0].size;
        var extPermitidas = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'JPG', 'JPEG', 'PNG', 'TXT', 'PDF', 'DOC', 'DOCX'];
        if (extPermitidas.find((ext) => { return file.split('.').pop() === ext; }) === undefined) {
            Swal.fire('Extensão do arquivo inválida!' + '\n' + '(Permitidas: pdf, jpg, pgn, txt, doc, docx)', '', 'warning');
            arquivo.value = '';
        } else {
            if (size < 40000000) { //40MB         
                setDocumento(e.target.files[0]);
                setPath(e.target.value);
            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                arquivo.value = '';
            }
            e.preventDefault();
        }
    };

    const handleDeleteUpload = (e) => {
        setDocumento(e.target.files[0]);
        setPath(e.target.value);
        e.preventDefault();
    };

    const editDocumento = (documento, dados) => {
        let documentoLocal = [...props.documentos];
        const index = documentoLocal.indexOf(documento);
        documentoLocal.splice(index, 1, dados);
        props.setDocumentos(documentoLocal);
    };

    async function handleSubmit(data) {
        try {
            if (data.nome === '' || data.arquivo === '') {
                Swal.fire('Por favor prencha todos os campos!', '', 'warning');
            } else {
                let documentosLocal = [...props.documentosList];
                data.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
                data.arquivo = documento;
                data.user_id = props.user_id;
                DocumentosGeraisService.store(data).then(response => {
                    documentosLocal.push(response.data);
                    props.setDocumentos(documentosLocal);
                    props.setDocumentosList(documentosLocal);
                    props.onClose();
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (props.modalData) {
            const fileInput = document.getElementById('arquivo');
            const myFile = new File(['arquivo'], props.modalData.arquivo);
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(myFile);
            fileInput.files = dataTransfer.files;
        }
    }, []);


    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }

    return (
        <div>
            <Modal id={id} onClick={e => handleOutsideClick(e)}>
                <ModalContainer>
                    <div style={{ position: 'relative' }}>
                        <label style={{
                            opacity: 0.5, cursor: 'pointer', position: 'absolute',
                            right: '30px', fontSize: '25px', top: '-15px'
                        }} onClick={() => props.onClose()}>
                            x
                        </label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                        <h2 style={{ color: '#442953', fontWeight: 'bold' }}>Documento Geral</h2>
                        <Form ref={formRef} onSubmit={handleSubmit} enctype="multipart/form-data" id={'form_docs'} className='row p-2'>
                            <Input className='mt-2' title="Documento:" name="nome" required={'required'} defaultValue={props.modalData === null ? '' : props.modalData.nome} />
                            <InputArquivo id="arquivo" name="arquivo" title='Anexar Arquivo' path={path} onChange={handleChangeDocument} handleDeleteUpload={handleDeleteUpload} />
                            <Button className='w-200px mx-auto mt-2' type="button" onClick={e => functionThatSubmitsForm()}> Adicionar </Button>
                        </Form>
                    </div>
                </ModalContainer>
            </Modal>
        </div>
    );
};

export default forwardRef(ModalDocumentoFuncionario);
