import React, { useEffect, useMemo, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Form } from '@unform/web';
import Button from '../../../components/Button';
import { InputArquivo, RichTextEditor } from '../../../components/Input';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { useSnackbar } from '../../../context/snackbar';
import { TableDefaultPagination } from '../../../components/TableDefaultPagination';
import { BsLock, BsPencil, BsUnlock } from 'react-icons/bs';
import Select from '../../../components/Select';
import Arquivos from '../../../services/ArquivosService';
const tamanhoPagina = {
    width: 210,
    height: 297,
};
const styles = {
    page: {
        width: tamanhoPagina.width + 'mm',
        height: tamanhoPagina.height + 'mm',
        padding: '0',
        margin: '10mm auto',
        border: '1px #D3D3D3 solid',
        borderRadius: '5px',
        background: 'white',
        header: {
            textAlign: 'center',
            margin: '0 0 10px 0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
            padding: '10px 0px 0px 10px',
            logo: {
                width: '100px',
                height: 'auto',
            },
            title: {
                width: `calc(${tamanhoPagina.width}mm - 100px)`,
            },
            auxp: {
                width: '100px',
                margin: '0',
            },
        },
        content: {
            height: `calc(${tamanhoPagina.height}mm - 76px - 76px - 20px)`,
            margin: '10px 22mm 10px 22mm',
            textAlign: 'justify',
        },
        footer: {
            height: '76px',
            marginTop: '10px',
            width: '100%',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
        },
    },
};

const PadraoDocumento = () => {
    //#region VARIÁVEIS
    const [listagemPadroes, setListagemPadroes] = useState([]);
    const [title, setTitle] = useState('');
    const [footer, setFooter] = useState('');
    const [logo, setLogo] = useState('');
    const [fileName, setFileName] = useState(null);
    const [path, setPath] = useState('');
    const [padraoId, setPadraoId] = useState(null);
    const [estado, setEstado] = useState();
    const [tipo, setTipo] = useState('padrao');
    const [tiposOptions, setTipoOptions] = useState([
        { label: 'Geral', value: 'geral' },
        { label: 'Evolução', value: 'evolucao' },
        { label: 'Anaminese', value: 'anaminese' },
        /* { label: '', value: '' } */
    ]);
    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'id', width: 100 },
        { Header: 'Cabeçalho', accessor: 'cabecalho', width: 100 },
        { Header: 'Rodapé', accessor: 'rodape', width: 100 },
        { Header: 'Tipo', accessor: 'tipo', width: 100 },
        { Header: 'Status', accessor: 'status', width: 100 },
        {
            Header: 'Ações',
            accessor: 'acoes',
            Cell: ({ cell }) => {
                return (
                    <div className='d-flex justify-content-center'>
                        <button type='button' className='btn-icone hl-laranja' onClick={() => { setPadraoId(cell.row.original.id); setEstado('edicao'); }}>
                            <BsPencil size={22}></BsPencil>
                        </button>
                        {cell.row.original.status === 'Ativo'
                            ? <button type='button' className='btn-icone hl-vermelho' onClick={() => { }} title='Bloquear padrão.'>
                                <BsUnlock size={22} />
                            </button>
                            : <button type='button' className='btn-icone hl-verde' onClick={() => { }} title='Desloquear padrão.'>
                                <BsLock size={22} />
                            </button>
                        }
                    </div>
                );
            }
        },
    ], []);

    const snackbar = useSnackbar();
    //#endregion

    //#region FUNCOES
    const carregarPadroes = async () => {
        await api.get('api/padrao-documento')
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data);
                    if (response.data && response.data.length > 0) {
                        setListagemPadroes(response.data);
                    } else {
                        const fakeData = [
                            { id: 1, cabecalho: 'Fake Cabeçalho 1', rodape: 'Fake Rodapé 1', tipo: 'Tipo 1', status: 'Ativo', upload: { caminho: 'fake/path/pdf.png' } },
                            { id: 2, cabecalho: 'Fake Cabeçalho 2', rodape: 'Fake Rodapé 2', tipo: 'Tipo 2', status: 'Ativo', upload: { caminho: 'fake/path/pdf.png' } },
                            { id: 3, cabecalho: 'Fake Cabeçalho 3', rodape: 'Fake Rodapé 3', tipo: 'Tipo 3', status: 'Ativo', upload: { caminho: 'fake/path/pdf.png' } },
                            { id: 4, cabecalho: 'Fake Cabeçalho 4', rodape: 'Fake Rodapé 4', tipo: 'Tipo 4', status: 'Ativo', upload: { caminho: 'fake/path/pdf.png' } },
                        ];

                        setListagemPadroes(fakeData);
                    }
                    snackbar.displayMessage('Parâmetros carregados com sucesso!', 'success');
                }
            })
            .catch(err => {
                console.error(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao buscar os parâmetros!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
    };
    //#endregion

    //#region HANDLES
    const handleChange = (event) => {
        const { id, value } = event.target;
        if (id === 'title') {
            setTitle(value);
        } else if (id === 'footer') {
            setFooter(value);
        } else if (id === 'tipo') {
            let localTipo = tiposOptions.find(tipo => tipo.value === value);
            setTipo([localTipo]);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                let img = document.getElementById('logo-preview');
                img.src = e.target.result;
            };
            setLogo(file);
            setFileName(file.name.split('.').pop());
            reader.readAsDataURL(file);
            const path = event.target.files[0].name; // Get the name of the selected file
            setPath(path); // Store the path in state variable
        }
    };

    const handleDeleteUpload = () => {
        setLogo('');
        setPath('');
    };

    const handleSubmit = () => {
        if (!title || !footer) return Swal.fire({
            icon: 'error',
            title: 'Preencha todos os campos!'
        });
        let objUpload = {
            nome: fileName,
            arquivo: logo
        };

        const upload = Arquivos.store(objUpload, 'api/uploads', 'arquivo')
            .then((response) => {
                if (response.status !== 200) return Swal.fire({
                    icon: 'error',
                    title: 'Erro ao salvar o arquivo!'
                });
                return response.data;
            });

        console.log(upload, 'upload');

        let obj = {
            cabecalho: title,
            rodape: footer,
            tipo: tipo?.value,
            status: 'Ativo',
            upload_id: upload?.id
        };
        api.post('api/padrao-documento', obj)
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Parâmetros salvos com sucesso!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao salvar os parâmetros!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
            .catch(err => {
                console.error(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao salvar os parâmetros!',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .finally(() => {
                setEstado();
                setTitle('');
                setFooter('');
                setLogo('');
                setPath('');
                setFileName('');
                carregarPadroes();
                setPadraoId(null);
            });
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (padraoId) {
            const padrao = listagemPadroes.find(padrao => padrao.id === padraoId);
            setTitle(padrao.cabecalho);
            setFooter(padrao.rodape);
            setLogo(padrao.upload.caminho);
        }
    }, [padraoId]);
    useEffect(() => {
        console.log(fileName, 'fileName');
    }, [fileName]);
    useEffect(() => {
        if (estado === 'criacao') {
            setTitle('');
            setFooter('');
            setLogo('');
            setPath('');
            setPadraoId(null);
        }
    }, [estado]);
    useEffect(() => {
        carregarPadroes();
    }, []);
    //#endregion

    //#region HTML
    return (
        <div className='ps-5 ms-5 pe-5 me-5'>
            <div className='row'>
                <div className='div-titulo'>
                    <label>Padrão de Documentos</label>
                </div>
                <div className='col-12 d-flex justify-content-end'>
                    <Button onClick={() => {
                        if (estado === 'criacao') {
                            setEstado();
                        } else {
                            setEstado('criacao');
                        }
                    }} className='w-300px'>
                        {estado === 'criacao'
                            ? 'Voltar'
                            : 'Novo Padrão'
                        }
                    </Button>
                </div>
                <Form className='col-6' onSubmit={handleSubmit}>
                    {(listagemPadroes.length > 0 && estado !== 'criacao') &&
                        <div className='col-12 mt-4'>
                            <label className='font-roxo font-bold'>Padrões Cadastrados</label>
                            <TableDefaultPagination id='tabela-padroes' name='tabela-padroes' source={listagemPadroes} columns={columns} selectedRowId={padraoId} />
                        </div>
                    }
                    {estado && <div className='col-12'>
                        <div className='row font-roxo font-bold'>
                            <p>DADOS</p>
                        </div>
                        <div className='col-12 row h-fit-content-i'>
                            <div className='col-4 align-items-center'>
                                <label htmlFor="title" className='form-label'>Logo:</label>
                                <InputArquivo id='arquivo' name='arquivo' title='Anexar Arquivo:' path={path} handleChange={handleFileChange} handleDeleteUpload={handleDeleteUpload} />
                            </div>
                            <div className='col-8'>
                                <label htmlFor="title" className='form-label'>Header Padrão:</label>
                                <RichTextEditor name='title' label='Title:' value={title} onChange={(e) => handleChange({ target: { id: 'title', value: e } })} />
                            </div>
                            <div className='col-12 mt-5'>
                                <label htmlFor="footer" className='form-label'>Footer Padrão:</label>
                                <RichTextEditor name='footer' label='Footer:' value={footer} onChange={(e) => handleChange({ target: { id: 'footer', value: e } })} />
                            </div>
                            <div className='col-12 mt-5'></div>
                            <div className='col-12 mt-5'>
                                <label htmlFor="footer" className='form-label'>Tipo:</label>
                                <Select id='tipo' name='tipo' options={tiposOptions} value={tipo} onChange={(e) => handleChange({ target: { ...e, id: 'tipo' } })} />
                            </div>
                            <div className='col-12 d-flex justify-content-end'>
                                <Button type="submit" className='w-300px'> {padraoId ? 'Atualizar' : 'Cadastrar'} </Button>
                            </div>
                        </div>
                    </div>}
                </Form>
                <div className="col-6 border-left" style={{ borderLeft: '1px solid rgba(0,0,0,0.2)', borderTopLeftRadius: '10px' }}>
                    <div className='row font-roxo font-bold'>
                        <p>PREVIEW</p>
                    </div>
                    {estado ?
                        <div style={styles.page}>
                            <div style={styles.page.header}>
                                <img style={styles.page.header.logo} id='logo-preview' src={logo} alt="Logo" />
                                <div style={styles.page.header.title} id='header-preview' dangerouslySetInnerHTML={{ __html: title }}></div>
                                <p style={styles.page.header.auxp}></p>
                            </div>
                            <div style={styles.page.content}>
                                A frente um Texto Falso para melhor visualização do documento.
                                <br></br>
                                <br></br>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum sollicitudin, pulvinar turpis vel, viverra elit. Nam lobortis arcu id nunc fermentum, vel condimentum dolor commodo. Aliquam ac imperdiet urna. Etiam euismod diam ac nibh mattis malesuada. Donec id condimentum turpis. Etiam leo diam, finibus sed lectus volutpat, porttitor tincidunt ex. Mauris efficitur mollis dolor, ut luctus nisi ultricies at. Donec accumsan efficitur lacus quis sodales. Mauris volutpat enim in leo aliquam, non aliquam metus sagittis. Duis nec finibus nulla, sed maximus velit. Donec ultricies neque hendrerit, volutpat odio et, ornare est. Fusce et nisi quam. Maecenas ac elit risus.
                                <br></br>
                                Vivamus cursus lacus eget sagittis finibus. Mauris tempor nisl purus, id accumsan lacus viverra vitae. Donec malesuada mauris risus. Nullam aliquam magna nibh, eget euismod eros finibus laoreet. Curabitur a dolor quis massa elementum maximus. Pellentesque faucibus tellus mi. Nunc ultrices porttitor maximus. Ut leo tortor, pulvinar sit amet consectetur et, molestie eget urna. Curabitur sit amet maximus est, et gravida metus. Nullam dapibus ullamcorper diam sit amet cursus. Nulla varius, massa non facilisis consequat, nulla sapien commodo ligula, eu molestie lacus neque non neque. Nam id placerat nisl.
                                <br></br>
                                Morbi faucibus cursus lacus, sit amet semper nulla venenatis ut. Morbi tincidunt libero at ligula scelerisque commodo. Cras ante sapien, lobortis at fringilla quis, suscipit vitae augue. Duis gravida risus justo, non dapibus libero laoreet at. Aenean quis urna ut mi sodales euismod. Aenean ut efficitur sem, quis semper lectus. Donec quis justo at turpis maximus mollis non a sapien. Praesent imperdiet elit a orci hendrerit egestas. Duis leo enim, laoreet et eros ac, sodales tincidunt quam. Cras ut facilisis velit.
                                <br></br>
                                <br></br>
                                Phasellus lacinia enim enim, in molestie nibh bibendum a. Mauris id posuere dolor, eget luctus nibh. Ut at justo id tellus euismod vulputate. Fusce dictum eros a eros porttitor congue. Fusce commodo lacus leo, nec malesuada nisl feugiat id. Sed porta molestie nibh, eu sollicitudin ligula semper ac. Nam turpis ligula, lobortis quis lectus in, fermentum tincidunt arcu. Etiam nulla elit, malesuada ac nisi ut, consequat volutpat lectus. Quisque pellentesque pharetra gravida. Quisque non pharetra erat. Sed nec suscipit risus. Maecenas nisl risus, ultricies facilisis purus eu, elementum maximus metus. Nam quis dolor tortor.
                                Phasellus lacinia enim enim, in molestie nibh bibendum a. Mauris id posuere dolor, eget luctus nibh. Ut at justo id tellus euismod vulputate. Fusce dictum eros a eros porttitor congue. Fusce commodo lacus leo, nec malesuada nisl feugiat id. Sed porta molestie nibh, eu sollicitudin ligula semper ac. Nam turpis ligula, lobortis quis lectus in, fermentum tincidunt arcu. Etiam nulla elit, malesuada ac nisi ut, consequat volutpat lectus. Quisque pellentesque pharetra gravida. Quisque non pharetra erat. Sed nec suscipit risus.
                            </div>
                            <div style={styles.page.footer} dangerouslySetInnerHTML={{ __html: footer }}>
                            </div>
                        </div>
                        : 'Sem dados para exibição.'
                    }
                </div>
            </div>
        </div>
    );
    //#endregion
};

export default PadraoDocumento;