import api from './api';

class TerapeutaEspecialidadeService {
    static index = () => {
        return api.get('/api/terapeuta-especialidade');
    };
    static store = (obj) => {
        /* exemplo
        {"terapeuta_id":1,"especialidade_id":1,"data":"2013-08-22","arquivo":"perspiciatis"}
        */

        return api.post('/api/terapeuta-especialidade', obj);
    };
    static show = (id) => {
        return api.get(`/api/terapeuta-especialidade/${id}`);
    };
    static update = (obj, id) => {
        /* exemplo
        {"terapeuta_id":1,"especialidade_id":1,"data":"1986-07-03","arquivo":"similique"}
        */

        return api.put(`/api/terapeuta-especialidade/${id}`, obj);
    };
    static destroy = (data, id) => {
        return api.delete(`/api/terapeuta-especialidade/${id}`, {data});
    };
}

export default TerapeutaEspecialidadeService;