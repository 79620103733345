import api from './api';

class ModalidadeService {

    static index = () => {
        return api.get('api/modalidade');
    };

    static store = (obj) => {
        /* exemplo
        {"modalidade":"magnam"}
        */

        return api.post('api/modalidade', obj);
    };

    static show = (id) => {
        return api.get(`api/modalidade/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"modalidade":"facere"}
        */

        return api.put(`api/modalidade/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/modalidade/${id}`);
    };

}

export default ModalidadeService;
