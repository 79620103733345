import * as React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { CelulaCabecalho, LinhaTabela, LinhaTabelaVazia, MultiSelectFiltro } from './styles';
import TablePagination from '../TablePagination';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import moment from 'moment';
import useIcons from 'context/icons';
import Dropdown from 'components/Dropdown';
import { debounce, get as getObjectValue } from 'lodash';

export function TableDefaultPagination({
    id = '',
    source,
    columns,
    emptyPhrase,
    initialState = { pageIndex: 0, pageSize: 8 },
    enableFooter = true,
    selectedRowId = null,
    tableTitle = 'Listagem',
    prefix_id = 'default',
    hoverLineMark = true,
    searchBar,
    createButton = null,
    className
}) {
    //#region VARIAVEIS
    const { iconArray } = useIcons();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterSearchTerm, setFilterSearchTerm] = React.useState('');
    const [columnFilters, setColumnFilters] = React.useState({});
    const [openedFilter, setOpenedFilter] = React.useState(false);

    const data = React.useMemo(() => {
        return source.filter(row => {
            const matchesSearch = Object.values(row).some(value =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            );
            const matchesFilters = Object.keys(columnFilters).every(colId => {
                if (!columnFilters[colId] || columnFilters[colId].length === 0) return true;

                const rowValue = row[colId];
                if (Array.isArray(rowValue)) {
                    // Verifica se algum item do array corresponde ao filtro
                    return rowValue.some(item => columnFilters[colId].find(find => find === item.nome));
                }

                return columnFilters[colId].includes(rowValue);
            });

            return matchesSearch && matchesFilters;
        });
    }, [source, searchTerm, columnFilters]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: initialState
    },
        useSortBy,
        usePagination,
    );
    //#region

    const exportToExcel = () => {
        const exportableColumns = columns.filter(col => col.Header !== 'Ações' && col.Header !== 'Ações:');
        const tableData = source.map(row => {
            let rowData = {};
            exportableColumns.forEach(col => {
                const cellValue = typeof col.accessor === 'function'
                    ? col.accessor(row)
                    : row[col.accessor];
                rowData[col.Header] = cellValue;
            });
            return rowData;
        });

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TableData');
        XLSX.writeFile(workbook, `${tableTitle} ${moment().format('DD-MM-YYYY HH:mm')}.xlsx`);
    };

    const handleColumnFilterChange = (columnId, selectedValues) => {
        setColumnFilters(prev => ({ ...prev, [columnId]: selectedValues }));
    };

    const getUniqueValues = (columnId) => {
        const column = columns.find(col => col.accessor === columnId);
        const values = source.flatMap(row => {
            const rawValue = getObjectValue(row, column?.filterAccessor ?? columnId);
            // Se o valor for um array, mapeie cada item do array
            if (Array.isArray(rawValue)) {
                return rawValue.map(item => {
                    const rawValue = getObjectValue(item, column?.filterValue);
                    const renderedValue = getObjectValue(item, column?.filterLabel);

                    return { rawValue, renderedValue };
                });
            }
            try {
                // Processa o valor pelo Cell, se definido, exceto se for boolean
                const renderedValue = (column && column.Cell && column.filterType !== 'boolean')
                    ? column.Cell({ cell: { value: rawValue } })
                    : (column.filterType === 'boolean' ? (rawValue ? 'Sim' : 'Não') : rawValue);
                return { rawValue, renderedValue };
            } catch (error) {
                // Processa o valor pelo Cell, se definido
                const renderedValue = rawValue;
                return { rawValue, renderedValue };
            }
        });

        const returnValues = Array.from(new Map(values.map(item => [item.rawValue, item])).values());
        return returnValues;
    };

    const handleSelectAll = (columnId) => {
        let allValues = getUniqueValues(columnId);
        if (filterSearchTerm) {
            allValues = allValues.filter(({ rawValue, renderedValue }) => {
                const searchTerm = filterSearchTerm?.toLowerCase();
                return (
                    !searchTerm ||
                    String(rawValue).toLowerCase().includes(searchTerm) ||
                    String(renderedValue).toLowerCase().includes(searchTerm)
                );
            });
        }
        setColumnFilters(prev => ({ ...prev, [columnId]: allValues.map(({ rawValue }) => rawValue) }));
    };

    const handleClearFilter = (columnId) => {
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[columnId];
            return newFilters;
        });
    };

    const uniqueValuesFilter = ({ rawValue, renderedValue }) => {
        const searchTerm = filterSearchTerm?.toLowerCase();
        return (!searchTerm || String(rawValue).toLowerCase().includes(searchTerm) || String(renderedValue).toLowerCase().includes(searchTerm));
    }

    React.useEffect(() => {
        if (!openedFilter) {
            setFilterSearchTerm('');
        }
    }, [openedFilter]);

    return (
        <div id={id} className={className}>
            <div className="d-flex justify-content-between">
                {searchBar &&
                    <div className={`input-group w-100`}>
                        <input
                            type="text"
                            className='fake-input'
                            placeholder="Pesquisar na tabela..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            aria-label="Search Term" aria-describedby="search"
                        />
                        <span className="input-group-text btn-padrao" id="search">
                            {React.cloneElement(iconArray['pesquisar'], { color: 'white' })}
                        </span>
                    </div>
                }
                {createButton}
            </div>
            <table {...getTableProps()} className="TableListaEspera w-100" id={`${prefix_id}-table`}>
                <thead id={`${prefix_id}-thead`} className='pointer'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => {
                                const uniqueValues = getUniqueValues(column.id).filter(uniqueValuesFilter)
                                return (
                                    <CelulaCabecalho key={index} id={`${prefix_id}-col-${index}`}>
                                        <div className='position-relative d-flex justify-content-between align-items-center'>
                                            <span style={{ width: 36 }}>
                                                {column.filterable
                                                    ? <Dropdown
                                                        clickMode={true}
                                                        setMostrando={setOpenedFilter}
                                                        reserva={true}
                                                        button={
                                                            columnFilters[column?.filterAccessor ?? column.id]?.length
                                                                ? React.cloneElement(iconArray['filtrado'], { color: 'yellow', size: 18 })
                                                                : React.cloneElement(iconArray['filtrar'], { color: 'white', size: 18 })
                                                        }
                                                        content={
                                                            <MultiSelectFiltro>
                                                                <h1 className='title'>
                                                                    Filtros
                                                                </h1>
                                                                <div className='multiselect-container'>
                                                                    <ul className='toolbox'>
                                                                        <li className='font-branca'>
                                                                            <button className='btn-outline py-1 d-flex align-items-start font-branca' onClick={() => handleClearFilter(column?.filterAccessor ?? column.id)}>
                                                                                {React.cloneElement(iconArray['limpar-geral'], { size: 18, color: 'white', style: { marginRight: 10 } })} Limpar filtro
                                                                            </button>
                                                                        </li>
                                                                        <li className='font-branca'>
                                                                            <button className='btn-outline py-1 d-flex align-items-start font-branca' onClick={() => handleSelectAll(column?.filterAccessor ?? column.id)}>
                                                                                {React.cloneElement(iconArray['seleciona-quadrado'], { size: 18, color: 'white', style: { marginRight: 10 } })} Selecionar todos
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <div className='search-input'>
                                                                        <input className='form-control w-100 py-1 m-0' type="text" placeholder="Filtrar..." value={filterSearchTerm} onChange={(e) => setFilterSearchTerm(e.target.value)} />
                                                                    </div>
                                                                    <ul className='filter-list'>
                                                                        {uniqueValues.map(({ rawValue, renderedValue }) => (
                                                                            <li key={rawValue} className='p-0 m-0 list-style-none text-left'>
                                                                                <label className='w-100 font-normal unselectable'>
                                                                                    <input
                                                                                        className='me-2'
                                                                                        type="checkbox"
                                                                                        checked={columnFilters[column?.filterAccessor ?? column.id]?.includes(rawValue) || false}
                                                                                        onChange={(e) => {
                                                                                            const selectedValues = columnFilters[column?.filterAccessor ?? column.id] || [];
                                                                                            if (e.target.checked) {
                                                                                                handleColumnFilterChange(column?.filterAccessor ?? column.id, [...selectedValues, rawValue]);
                                                                                            } else {
                                                                                                handleColumnFilterChange(column?.filterAccessor ?? column.id, selectedValues.filter(v => v !== rawValue));
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {renderedValue}
                                                                                </label>
                                                                            </li>
                                                                        ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </MultiSelectFiltro>
                                                        }
                                                    />
                                                    : <></>
                                                }</span>
                                            <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                            </span>
                                            <span style={{ width: 36 }}>
                                                {columnFilters[column?.filterAccessor ?? column.id]?.length
                                                    && <button className='btn-outline p-0 d-flex align-items-start font-branca' onClick={() => handleClearFilter(column?.filterAccessor ?? column.id)}>
                                                        {React.cloneElement(iconArray['limpar-geral'], { size: 18, color: 'white' })}
                                                    </button>
                                                }
                                                {column.isSorted
                                                    ? (column.isSortedDesc
                                                        ? <AiOutlineCaretDown style={{ marginLeft: '10%' }} color='white' />
                                                        : <AiOutlineCaretUp style={{ marginLeft: '10%' }} color='white' />
                                                    )
                                                    : ''
                                                }
                                            </span>
                                        </div>
                                    </CelulaCabecalho>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody id={`${prefix_id}-tbody`} {...getTableBodyProps()} className="TableListaEsperaBody">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr className={hoverLineMark ? 'line-hover' : ''} {...row.getRowProps()}>
                                {row.cells.map((cell, index) =>
                                    <LinhaTabela key={index} id={`${prefix_id}-col-${i}-row-${index}`}
                                        style={{
                                            height: '14px',
                                            textAlign: 'center',
                                            backgroundColor: row.original.id === selectedRowId ? '#e2e2e2' : '',
                                        }}>
                                        {cell.render('Cell')}
                                    </LinhaTabela>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {page.length < 1 ? <LinhaTabelaVazia> {emptyPhrase ?? 'Nada encontrado.'} </LinhaTabelaVazia> : ''}
            <div id={`${prefix_id}-tfoot`} className='mt-2 w-100per'>
                {(enableFooter === true) ?
                    <TablePagination
                        prefix_id={prefix_id}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        linhas={source?.length}
                        exportToExcel={exportToExcel} />
                    : ''
                }
            </div>
        </div>
    );
}