import React from 'react';

import Container from '../components/Container';
import Header from '../components/Header';

const Recepcao = ({ children }) => (
    <>
        <Header />
        <Container>
            {children}
        </Container>
    </>
);

export default Recepcao;
