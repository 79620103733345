import { borderWidth } from 'polished';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import { Form } from '@unform/web';
import { ButtonAddStyle, CustomStyles, FormStyle } from './styles';
import Swal from 'sweetalert2';
import api from '../../../../services/api';

const ModalContatos = (props, ref) => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dados, setDados] = React.useState(null);

    const formRef = useRef(null);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const editContato = async (contato, dados) => {
        let contatosLocal = [...props.contatos];
        if (props.terapeutaId === null || props.terapeutaId === undefined) {
            let contatosLocal = [...props.contatos];
            const index = contatosLocal.indexOf(contato);
            contatosLocal.splice(index, 1, dados);
            props.setContatos(contatosLocal);
        } else {
            await api.put(`api/contato-terapeuta/${contato.id}?id=${contato.id}`, dados).then(({data}) => {
                Swal.fire('Contato editado com sucesso!', '', 'success');
                for (let index = 0; index < contatosLocal.length; index++) {
                    const element = contatosLocal[index];
                    if (element.id === data.id) {
                        contatosLocal[index] = { ...data };
                        break;
                    }
                }
                props.setContatos(contatosLocal);
            });
        }
    };

    async function handleSubmit(data) {
        try {
            let contatosLocal = [...props.contatos];
            if (props.modalData === null) {
                if (data.nome === '' || data.parentesco === '' || data.contato === '') {
                    Swal.fire('Por favor preencha todos os campos!', '', 'warning');
                } else {
                    contatosLocal.push(data);
                    props.setContatos(contatosLocal);
                    closeModal();
                }
            } else {
                editContato(props.modalData, data);
                closeModal();
            }
        } catch (error) {
            Swal.fire('Houve um problema ao cadastrar!', '', 'error');
            console.error(error);
        }
    }

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }

    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={CustomStyles} contentLabel="Example Modal" transparent>
            <div style={{ position: 'relative' }}>
                <label style={{
                    opacity: 0.5, cursor: 'pointer', position: 'absolute',
                    right: '10px', fontSize: '25px', top: '-10px'
                }} onClick={e => closeModal()}>
                    x
                </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ color: '#442953', fontWeight: 'bold', textAlign: 'center' }} ref={(_subtitle) => (subtitle = _subtitle)}>Contato</h2>
                <Form ref={formRef} onSubmit={handleSubmit} style={FormStyle} >
                    <div style={{ paddingTop: 30 }}>
                        <div style={{ display: 'flex' }}>
                            <input type='hidden' id='id' name='id' defaultValue={(props.modalData !== null && props.modalData.id !== undefined) ? props.modalData.id : null} />
                            <div style={{ width: '60%', paddingRight: 10 }}>
                                <Input title="Nome" name="nome" defaultValue={props.modalData === null ? '' : props.modalData.nome} />
                            </div>
                            <div style={{ width: '60%', paddingRight: 10 }}>
                                <Input title="Parentesco" name="parentesco" defaultValue={props.modalData === null ? '' : props.modalData.parentesco} />
                            </div>
                            <div style={{ width: '60%', paddingRight: 10 }}>
                                <InputMask title="Contato" name="contato" icon={null} maskFormat={'(99)99999-9999'}
                                    disabled={false} defaultValue={props.modalData === null ? '' : props.modalData.contato} />
                            </div>
                        </div>
                    </div>
                    <button className='btn-padrao' type="button" onClick={e => functionThatSubmitsForm()}> {props.modalData === null ? 'Adicionar' : 'Alterar'} </button>
                </Form>
            </div>
        </Modal>
    );
};

export default forwardRef(ModalContatos);
