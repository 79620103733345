import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Container } from './styles';
import user from 'assets/img_avatar.png';
import { Form } from '@unform/web';
import Input from '../../Input';
import Select from '../../Select';
import FuncionarioService from '../../../services/FuncionarioService';
import api from '../../../services/api';
import { useSnackbar } from '../../../context/snackbar';
import InputMask from '../../InputMask';
import Cep from '../../../services/cep';
import moment from 'moment';
import ModalDocumentoFuncionario from './DocumentoGeralFuncionario/index';
import { leftContentDivStyle } from '../../../pages/AdminDashboard/Terapeutas/Novo/styles';
import { AiOutlineDelete, AiOutlineForm } from 'react-icons/ai';
import Swal from 'sweetalert2';
import DocumentosGeraisFuncionarioService from '../../../services/DocGeraisFuncionariosService';
import { CPFService, UserServices } from '../../../services/CPFService';
import { BsDownload, BsPlusCircle, BsTrash } from 'react-icons/bs';
import Button from '../../Button';
import { FaDice } from 'react-icons/fa';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { FiEdit } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';
import ContatoUserModal from './ContatoUserModal';
import Arquivos from 'services/ArquivosService';
import Webcam from 'react-webcam';
import ModalDocumento from 'pages/RecepcaoDashboard/Pacientes/Perfil/ModalDocumento';
import DocumentoService from 'services/DocumentoService';
import PickList from 'components/PickList';
moment().utc();
/**
 * FuncionariosModal component.
 * 
 * @param {Object} props - Component props.
 * @param {Function} props.callback - Callback function.
 * @param {string} props.funcionarioId - Funcionario ID.
 * @param {Function} props.onClose - Close function.
 * @param {React.Ref} ref - Component ref.
 * @returns {JSX.Element} - FuncionariosModal component.
 */
const FuncionariosModal = ({ callback, funcionarioId, onClose }, ref) => {
    const [tipoUser, setTipoUser] = useState();
    const [statusUsuarioOpt, setStatusUsuarioOpt] = useState();
    const statusUsuarioOptList = [
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Ativo', label: 'Ativo' },
    ];
    const [grupos, setGrupos] = useState([]);
    const [planosSaude, setPlanosSaude] = useState([]);
    const [planoSelecionadoOpt, setPlanoSelecionadoOpt] = useState([]);

    const [permissoes, setPermissoes] = useState([]);
    const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);
    const [permissoesAlteradas, setPermissoesAlteradas] = useState([]);

    const documentoService = new DocumentoService('funcionario');
    const [documentosGerais, setDocumentosGerais] = useState([]);
    const [documentosList, setDocumentosList] = useState([]);
    const [documentoData, setDocumentoData] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [modalDocumento, setModalDocumento] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [cpf, setCpf] = useState(null);
    const [cep, setCep] = useState(null);
    const [telefone, setTelefone] = useState(null);
    const [telefone_2, setTelefone_2] = useState(null);
    const [fotoPerfil, setFotoPerfil] = useState(null);
    const [fotoPerfil64, setFotoPerfil64] = useState(null);
    const [videoConstraints, setVideoConstraints] = useState();
    //===REFS===
    const documentoRef = useRef(null);
    const pickListRef = useRef(null);
    //===FOTO PERFIL===
    const [alinhamento, setAlinhamento] = useState(false);
    const [alterarFotoPerfil, setAlterarFotoPerfil] = useState(false);
    const [modalFoto, setModalFoto] = useState(null);
    const webcamRef = useRef(null);
    /**
     * Capture the screenshot from the webcam and update the profile photo.
     */
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                const file = dataURLtoFile(imageSrc, 'webcam-photo.jpg');
                setFotoPerfil(file);
            } else {
                setFotoPerfil(imageSrc);
            }
            setFotoPerfil64(imageSrc);
            setModalFoto(false);
            setAlterarFotoPerfil(false);
        },
        [webcamRef]
    );
    /**
     * Converts a data URL to a File object.
     *
     * @param {string} dataurl - The data URL to convert.
     * @param {string} filename - The name of the file.
     * @returns {File} - The converted File object.
     */
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };
    //===VALIDADORES===
    const [validationEmailStyle, setValidationEmailStyle] = useState([]);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [validationStyle, setValidationStyle] = useState([]);
    const [isCPFValid, setIsCPFValid] = useState(false);
    const [loading, setloading] = useState(false);

    const [contatoUser, setContatosUser] = useState([]);
    const contatosUserColumns = React.useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'nome'
            },
            {
                Header: 'Parentêsco',
                accessor: 'parentesco'
            },
            {
                Header: 'Contato',
                accessor: 'contato'
            },
            {
                Header: 'Acoes',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        <div className='col-12'>
                            <button className='btn-outline'
                                title='Editar'
                                type='button'
                                onClick={(e) => handleEditContatoUser(cell.row.original)}>
                                <FiEdit size={20} />
                            </button>
                            <button className='btn-outline'
                                title='Editar'
                                type='button'
                                onClick={(e) => handleDeleteContatoUser(cell.row.original)}>
                                <BsTrash size={20} />
                            </button>
                        </div>
                    );
                }
            },
        ]
    );

    const formRef = useRef(null);
    const CPFRef = useRef(null);
    const telefoneRef = useRef(null);
    const telefone2Ref = useRef(null);
    const CEPRef = useRef(null);
    const modalContatoUserRef = useRef(null);
    const snackbar = useSnackbar();
    const id = 'modal-funcionarios';

    //#region METODOS
    /**
     * Gera uma senha aleatória de 8 caracteres e define os valores dos campos de senha e confirmação de senha.
     */
    const gerarSenha = () => {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let senha = '';
        for (let i = 0; i < 8; i++) {
            senha += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        document.getElementById('password').value = senha;
        document.getElementById('confirmed_password').value = senha;
    };
    /**
     * Updates a funcionario.
     * 
     * @param {Object} submitData - The data to be submitted for updating the funcionario.
     * @returns {Promise<void>} - A promise that resolves when the funcionario is updated.
     */
    const AtualizarFuncionario = async (submitData) => {
        const funcionarioResponse = await Arquivos.update(submitData, 'user', 'arquivo', fotoPerfil, false);

        if (documentosGerais.length > 0) {
            documentosGerais.forEach((documento) => {
                documento.user_id = funcionarioResponse.data.id;
                DocumentosGeraisFuncionarioService.store(documento);
            });
        }
    };
    /**
     * Renders a component for capturing webcam images.
     *
     * @returns {JSX.Element} The rendered component.
     */
    const WebcamCapture = () => {
        return (
            <>
                <div className={modalFoto ? 'flex-col align-items-center justify-center d-flex' : 'd-none'} >
                    <div className="h-auto">
                        <div className="flex-col some">
                            <input type="checkbox" id="alinhamento" className="p-2" onChange={(e) => setAlinhamento(e.target.checked)} />
                            <label htmlFor="alinhamento" className="p-2">Linhas de alinhamento</label>
                        </div>
                        <div className="webcam-background pointer" onClick={capture}>
                            <Webcam
                                audio={false}
                                style={{ height: '300px' }}
                                disablePictureInPicture={true}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        </div>
                        <div className={alinhamento ? 'lines' : 'none'}>
                            <div className={alinhamento ? 'linev' : 'none'}></div>
                            <div className={alinhamento ? 'lineh' : 'none'}></div>
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <button className="btn-padrao mt-10px mx-2" type="button" onClick={(e) => setModalFoto(false)}>Voltar</button>
                        <button className="btn-padrao mt-10px mx-2" type="button" onClick={capture}>Tirar Foto</button>
                    </div>
                </div>
            </>
        );
    };
    //#endregion

    //#region HANDLES
    const handleChangeTipoUsuario = (novoTipoUser) => {
        const atingoTipo = tipoUser;
        setTipoUser(novoTipoUser);
        if (atingoTipo !== novoTipoUser) {
            if (pickListRef?.current) {
                pickListRef?.current?.alteraSelecionados(
                    novoTipoUser.permissions.map(x => ({ value: x.id, permission_id: x.id, label: x.name, creation_type: 'user' })),
                    (map) => ({ ...map, creation_type: 'system' })
                );
            }
        }
    };
    /**
     * Opens the modal for handling documents.
     */
    const handleOpenModalDocumento = () => {
        if (documentoRef.current) {
            documentoRef.current.openModal();
        }
    };
    /**
     * Handles the outside click event.
     * 
     * @param {Event} e - The click event object.
     * @returns {void}
     */
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    /**
     * Handles the deletion of a contact user.
     * 
     * @param {Object} contato - The contact object to be deleted.
     * @returns {void}
     */
    const handleDeleteContatoUser = (contato) => {
        const contato_id = contato.id;
        Swal.fire({
            title: 'Deseja deletar esse contato?',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Deletar!',
            cancelButtonAriaLabel: 'Não deletar.'
        }).then(response => {
            if (response.isConfirmed === true) {
                api.delete(`api/contato-user/${contato_id}?id=${contato_id}`)
                    .then(() => {
                        let newList = contatoUser;
                        const index = newList.indexOf(contato);
                        newList.splice(index, 1);
                        snackbar.displayMessage('Contato deletado com sucesso!', 'success');
                    })
                    .catch(error => {
                        console.error(error);
                        snackbar.displayMessage('Não foi possível deletar o contato!', 'error');
                    });
            }
        });
    };

    /**
     * Opens the modal to edit a contact user.
     * 
     * @param {Object} contato - The contact object to be edited.
     */
    const handleEditContatoUser = (contato) => {
        if (modalContatoUserRef.current) {
            modalContatoUserRef.current.openModal(contato);
        }
    };
    /**
     * Opens the modal for contacting the user.
     */
    const handleOpenContatoUser = () => {
        if (modalContatoUserRef.current) {
            modalContatoUserRef.current.openModal();
        }
    };
    /**
     * Handles the deletion of a document.
     *
     * @param {Object} documento - The document to be deleted.
     */
    const handleDeleteDocumento = (documento) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (modalData === null || undefined) {
                    let documentosLocal = [...documentosList];
                    const index = documentosLocal.indexOf(documento);
                    documentosLocal.splice(index, 1);
                    setDocumentosList(documentosLocal);
                } else {
                    let documentosLocal = [...documentosList];
                    const index = documentosLocal.indexOf(documento);
                    documentosLocal.splice(index, 1);
                    documentoService.destroy(documento.id, { id: documento.id });
                    setDocumentosList(documentosLocal);
                }
            }
        });
    };

    function identifyChanges(previousSelectedItems, newSelectedItems) {
        // 1. Identificar os itens adicionados: estão na nova seleção, mas não na anterior
        const addedItems = newSelectedItems.filter(
            (newItem) => !previousSelectedItems.some((oldItem) => oldItem.value === newItem.value)
        );

        // 2. Identificar os itens excluídos: estão na seleção anterior, mas não na nova
        const removedItems = previousSelectedItems.filter(
            (oldItem) => !newSelectedItems.some((newItem) => newItem.value === oldItem.value)
        );

        return {
            addedItems,
            removedItems,
        };
    }

    /**
     * Handles the form submission.
     * 
     * @param {Object} data - The form data.
     * @returns {Promise<void>} - A promise that resolves when the submission is complete.
     */
    async function handleSubmit(data) {
        setloading(true);
        if (data.password !== data.confirmed_password) {
            Swal.fire('Verifique as senhas! As senhas não são iguais!', '', 'warning');
        } else {
            if (isEmailValid) {
                if (isCPFValid) {
                    let userData = {
                        'name': data.name,
                        'telefone': telefone,
                        'telefone_2': telefone_2,
                        'email': data.email,
                        'tipo_user': tipoUser?.label,
                        'group_id': tipoUser?.value,
                        'nome_curto': data.nome_curto,
                        'data_nascimento': data.data_nascimento,
                        'cpf': cpf,
                        'rg': data.rg,
                        'cep': cep,
                        'endereco': data.endereco,
                        'estado': data.estado,
                        'bairro': data.bairro,
                        'cidade': data.cidade,
                        'numero': data.numero,
                        'complemento': data.complemento,
                        'data_entrada': data.data_entrada,
                        'data_saida': data.data_saida,
                        'cargo': data.cargo,
                        'status': statusUsuarioOpt?.value,
                        'arquivo': fotoPerfil,
                        'plano_saude_id': planoSelecionadoOpt?.value ? planoSelecionadoOpt.value : null,
                    };
                    if (data.password) {
                        userData = { ...userData, 'password': data.password, 'password_confirmation': data.confirmed_password };
                    } else {
                        userData = { ...userData, 'password': null, 'password_confirmation': null };
                    }
                    try {
                        if (modalData === null) {
                            await FuncionarioService.store(userData).then(() => {
                                snackbar.displayMessage('Usuário criado com sucesso!', 'success');
                            });
                        } else {
                            await AtualizarFuncionario({ ...userData, 'id': modalData.id }).then(() => {
                                api.post('api/user-has-permission/atualiza/by/user/', { ...permissoesAlteradas, user_id: modalData.id })
                                    .catch(error => {
                                        console.error(error);
                                        snackbar.displayMessage('Não foi possível alterar as permissões desse usuário!', 'error');
                                    });
                                snackbar.displayMessage('Usuário alterado com sucesso!', 'success');
                            });
                        }
                        formRef.current.setData(data);
                        onClose();
                    } catch (error) {
                        snackbar.displayMessage('Houve um erro ao cadastrar/alterar!', 'error');
                        console.error(error);
                    } finally {
                        setloading(false);
                        callback();
                    }
                } else {
                    Swal.fire('Verifique o CPF!', 'O CPF informado não é valido, por favor, verifique o valor inserido, e tente novamente.', 'warning');
                }
            } else {
                Swal.fire('Verifique o E-mail!', 'O e-mail inserido já esta sendo usado por outro usuário do sistema. Por favor, verifique o email, e tente novamente.', 'warning');
            }
        }
    }
    /**
     * Handles the change event for the profile image input.
     *
     * @param {Event} e - The change event object.
     * @returns {void}
     */
    const handleChangePerfil = (e) => {
        /* CONSTANTES */
        const file = e.target.files[0];
        /* VARIÁVEIS */
        let ext = ['jpg', 'png', 'jpeg', 'img'];
        let inputImg = document.querySelector('#image');
        /* Verifica a exetensão do arquivo */
        if (Arquivos.validaArquivo(ext, 5242880, file)) {
            const reader = new FileReader();

            let base64;
            reader.addEventListener('load', (e) => {
                const readerTarget = e.target;
                inputImg.src = base64;
                base64 = readerTarget.result;
                setFotoPerfil64(base64);
                setFotoPerfil(file);
            });
            reader.readAsDataURL(file);
        } else {
            inputImg.value = '';
        }
        e.preventDefault();
    };
    //#endregion

    //#region GETTERS
    /**
     * Retrieves the default type of user based on the provided type.
     * 
     * @param {Array} tipo - The array of types to filter from.
     * @returns {void}
     */
    const getDefaultTpUser = (tipo) => {
        if (modalData === null) return;
        validaCPF(modalData.cpf);
        validaEmail(modalData.email, modalData.id);
        const tipoUser = tipo.filter(group => {
            return group.label.toUpperCase() === modalData.tipo_user.toUpperCase();
        })[0];
        setTipoUser(tipoUser);
    };

    /**
     * Retrieves the default planoSaudeId based on the given planos array and modalData.
     * 
     * @param {Array} planos - The array of planos.
     * @returns {void}
     */
    const getDefaultPlanoSaudeId = (planos) => {
        if (modalData === null) return;
        const planoSaude = planos.filter(plano => {
            return plano.value === modalData.plano_saude_id;
        })[0];
        setPlanoSelecionadoOpt(planoSaude);
    };

    /**
     * Retrieves address information based on a given CEP.
     * 
     * @param {string} cep - The CEP to retrieve address information for.
     * @returns {Promise<void>} - A promise that resolves when the address information is retrieved.
     */
    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro || '';
        document.getElementById('bairro').value = data.bairro || '';
        document.getElementById('estado').value = data.uf || '';
        document.getElementById('cidade').value = data.localidade || '';
    };

    /**
     * Retrieves the list of groups from the API.
     */
    const carregaGrupos = () => {
        api.get('/api/group?with=permissions').then(({ data }) => {
            const tiposUsuario = [];
            data.forEach((group) => {
                if (group.name !== 'terapeuta' && group.name !== 'responsavel') {
                    tiposUsuario.push({ value: group.id, label: group.name, permissions: group.permissions });
                };
            });
            setGrupos(tiposUsuario);
        });
    };

    /**
     * Retrieves the list of groups from the API.
     */
    const carregaPermissoes = () => {
        let permissoes = [];
        api.get('/api/permission?order_by=name:asc')
            .then(({ data }) => {
                data.forEach((x) => {
                    permissoes.push({ value: x.id, permission_id: x.id, label: x.title ? x.title : '', secondLabel: x.name ? x.name : '' });
                });
                setPermissoes(permissoes);
            });
    };
    /**
     * Retrieves the list of health plans from the API.
     */
    const carregaPlanos = () => {
        api.get('/api/plano-saude').then(({ data }) => {
            let planosSaude = [];
            data.forEach((plano) => {
                planosSaude.push({ value: plano.id, label: plano.nome_reduzido });
            });
            setPlanosSaude(planosSaude);
        });
    };
    //#endregion

    //#region VALIDADORES
    /**
     * Validates a given CPF (Cadastro de Pessoas Físicas) number.
     *
     * @param {string} cpf - The CPF number to be validated.
     * @returns {void}
     */
    const validaCPF = (cpf) => {
        const response = CPFService.ValidaCPF(cpf);
        setIsCPFValid(response.isValid);
        setValidationStyle(response.style);
    };

    /**
     * Validates an email address.
     * 
     * @param {string} email - The email address to be validated.
     * @param {string|null} id - The optional ID of the user.
     * @returns {Promise<void>} - A promise that resolves when the email validation is complete.
     */
    const validaEmail = async (email, id = null) => {
        const response = await UserServices.validaEmail(email, id);
        setIsEmailValid(response.isValid);
        setValidationEmailStyle(response.style);
        if (!response.isValid) {
            Swal.fire('Verifique o E-mail!', 'O e-mail inserido já esta sendo usado por outro usuário do sistema. Por favor, verifique o email, e tente novamente.', 'warning');
        }
    };

    const downloadDocumento = async (id) => {
        const download = (response, name = '') => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Use o nome do arquivo do cabeçalho da resposta
            const contentType = response.headers['content-type'];
            const contentDisposition = response.headers['content-disposition'];
            let fileName;
            if (contentType && contentDisposition) {
                const fileNameMatch = contentType.split('/');
                const name = contentDisposition.replace('attachment; filename="', '').split('.')[0];
                fileName = name + '.' + fileNameMatch[1];
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        };
        download(await documentoService.download(id).then(response => response), 'documento');
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (modalData !== null) {
            setCpf(modalData.cpf);
            setTelefone(modalData.telefone);
            setTelefone_2(modalData.telefone_2);
            setCep(modalData.cep);
            if (modalData?.documentos_gerais?.length > 0) {
                setDocumentosList(modalData.documentos_gerais);
            }
            setFotoPerfil64(modalData.foto_perfil_full ? modalData.foto_perfil_full : '');
        }
    }, [modalData]);
    useEffect(() => {
        if (permissoes && modalData) {
            const listaSelecionada = modalData?.permissoes?.filter((filter) => {
                return permissoes.filter((filter1) => filter1.value === filter.permission_id).length > 0;
            })?.map((map) => ({ value: map.permission_id, user_has_permission_id: map.id, label: map?.permission?.title ? map?.permission?.title : '', secondLabel: map?.permission?.name ? map?.permission?.name : '', creation_type: map?.creation_type }));
            setPermissoesSelecionadas(listaSelecionada ? listaSelecionada : []);
        }
    }, [permissoes]);

    useEffect(() => {
        if (modalData) {
            if (grupos) {
                getDefaultTpUser(grupos);
            }
            if (planosSaude) {
                getDefaultPlanoSaudeId(planosSaude);
            }
        }
    }, [grupos, planosSaude, modalData]);

    useEffect(() => {
        if (funcionarioId) {
            setloading(true);
            FuncionarioService.show(funcionarioId).then(({ data }) => {
                if (data) {
                    const statusUsuario = data.status;
                    if (data.contato_users) {
                        setContatosUser(data.contato_users);
                    }
                    data.data_nascimento = moment.utc(data.data_nascimento).format('YYYY-MM-DD');
                    data.data_entrada = moment.utc(data.data_entrada).format('YYYY-MM-DD');
                    data.data_saida = moment.utc(data.data_saida).format('YYYY-MM-DD');
                    const status = statusUsuarioOptList.filter((filter) => filter.value === statusUsuario);
                    if (status) {
                        setStatusUsuarioOpt(status);
                    } else {
                        setStatusUsuarioOpt({ value: 'Ativo', label: 'Ativo' });
                    }
                }
                setModalData(data);
                setloading(false);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [funcionarioId]);

    useEffect(() => {
        carregaGrupos();
        carregaPlanos();
        carregaPermissoes();
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <Modal id={id} onKeyUp={() => handleOutsideClick}>
                <Container className="p-0">
                    <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                        <div className="container-fluid d-flex align-items-center justify-content-between">
                            <div></div>
                            <ModalTitulo> {modalData === null ? 'Cadastrar Novo ' : 'Alterar '}Usuário {window.location.hostname === 'localhost' && `(${modalData?.id})`} </ModalTitulo>
                            <label className="btn-outline text-right pointer" onClick={() => onClose()}>
                                <CgClose size={22} className="poiner" color="red" />
                            </label>
                        </div>
                    </nav>
                    <br></br>
                    <Form ref={formRef} className="row pe-4 ps-4 pb-4 m-0" onSubmit={handleSubmit} autoComplete="off">
                        <fieldset className="ns-fieldset mt-4">
                            <legend>Dados Pessoais</legend>
                            <div className="row">
                                <div className="row col-3">
                                    <img className="perfil-image border p-0" src={fotoPerfil64 ? fotoPerfil64 : user} alt="" id="image" />
                                    <div className="col-12 d-flex align-center justify-center">
                                        <button className="btn-outline" type="button" onClick={() => setAlterarFotoPerfil(true)}>
                                            <AiOutlineForm style={{ marginRight: 3, marginBottom: 4 }} />
                                            Alterar foto de Perfil
                                        </button>
                                        <Modal className={alterarFotoPerfil ? '' : 'd-none'} onClick={(e) => {
                                            if (e.target.contains(e.currentTarget)) {
                                                setModalFoto(false); setAlterarFotoPerfil(false);
                                            }
                                        }}>
                                            <div className="div-modal p-0" style={{ height: '60vh' }}>
                                                <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                                                    <div className="container-fluid d-flex align-items-center justify-content-between">
                                                        <div></div>
                                                        <ModalTitulo> Alterar foto de perfil </ModalTitulo>
                                                        <label className="btn-outline text-right pointer" onClick={() => { setModalFoto(false); setAlterarFotoPerfil(false); }}>
                                                            <CgClose size={22} className="poiner" color="red" />
                                                        </label>
                                                    </div>
                                                </nav>
                                                <br></br>
                                                <div className="d-flex justify-center">
                                                    {!modalFoto &&
                                                        <div className="d-flex flex-row text-center justify-center align-center">
                                                            <label className="btn-padrao m-2" type="button" htmlFor="foto_perfil" style={{ cursor: 'pointer' }}>
                                                                Upload
                                                                <input type="file" name="foto_perfil" id="foto_perfil" className="some" onChange={(e) => { handleChangePerfil(e); setAlterarFotoPerfil(false); setModalFoto(false); }} />
                                                            </label>
                                                            <button className="btn-padrao m-2" type="button" onClick={() => { setModalFoto(true); }}>
                                                                Tirar Foto
                                                            </button>
                                                        </div>}
                                                    <div className="row justify-center">
                                                        {modalFoto && WebcamCapture()}
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div className="row col-9">
                                    <div className="col-6">
                                        <Input required={'required'} name={'name'} title={'Nome:'} defaultValue={modalData === null ? '' : modalData.name} autoComplete="off" />
                                    </div>
                                    <div className="col-6">
                                        <Input className="w-25" name={'nome_curto'} title={'Nome curto:'} defaultValue={modalData === null ? '' : modalData.nome_curto} autoComplete="off" required={'required'} />
                                    </div>
                                    <div className="col-4">
                                        <Input required={'required'} name={'data_nascimento'} title={'Data de Nascimento:'} defaultValue={modalData === null ? '' : modalData.data_nascimento} type="date" autoComplete="off" />
                                    </div>
                                    <div className="col-4">
                                        <InputMask ref={CPFRef} value={cpf || ''} onChange={(e) => { setCpf(e.target.value); validaCPF(e.target.value); }} containerStyle={validationStyle} title="CPF" name="cpf" icon={null} maskFormat={'999.999.999-99'} disabled={false} required={'required'} autoComplete="off" />
                                    </div>
                                    <div className="col-4">
                                        <Input required={'required'} name={'rg'} title={'RG:'} defaultValue={modalData === null ? '' : modalData.rg} autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset mt-4">
                            <legend>Contatos</legend>
                            <div className="row">
                                <div className="col-4">
                                    <Input containerStyle={validationEmailStyle} required={'required'} name={'email'} title={'Email:'} defaultValue={modalData === null ? '' : modalData.email} onBlur={(e) => { validaEmail(e.target.value, (modalData !== null && modalData.id !== undefined) ? modalData.id : null); }} autoComplete="off" />
                                </div>
                                <div className="col-4">
                                    <InputMask ref={telefoneRef} value={telefone || ''} onChange={(e) => setTelefone(e.target.value)} title="Telefone 01:" name="telefone" icon={null} maskFormat={'(99)99999-9999'} disabled={false} required={'required'} autoComplete="off" />
                                </div>
                                <div className="col-4">
                                    <InputMask ref={telefone2Ref} value={telefone_2 || ''} onChange={(e) => setTelefone_2(e.target.value)} title="Telefone 02:" name="telefone_2" icon={null} maskFormat={'(99)99999-9999'} disabled={false} autoComplete="off" />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset mt-4">
                            <legend>Acesso</legend>
                            <div className="row">
                                <div className={`row d-flex align-items-end${tipoUser?.label.toUpperCase() === 'AUDITOR' ? ' col-6' : ' col-4'}`}>
                                    <Input className="m-0" type={'text'} upperContainerStyle={{ maxWidth: '90%' }} placeholder="Defina uma Senha*" id={'password'} name={'password'} title={'Senha de Usuário:'} required={modalData === null ? 'required' : false} />
                                    <button type="button" className="btn-icone max-h-40px m-0 mb-2" style={{ maxWidth: '10%' }} onClick={(e) => { gerarSenha(); }}>
                                        <FaDice size={30} />
                                    </button>
                                </div>
                                <div className={tipoUser?.label.toUpperCase() === 'AUDITOR' ? 'some' : 'col-4'}>
                                    <Input type={'text'} id={'confirmed_password'} name={'confirmed_password'} title={'Confirmar Senha:'} placeholder="Confirme sua Senha*" required={modalData === null ? 'required' : false} />
                                </div>
                                <div className={`${tipoUser?.label.toUpperCase() === 'AUDITOR' ? ' col-6' : ' col-4'}`}>
                                    <Select label={'Status:'} id={'status'} name={'status'} value={statusUsuarioOpt} onChange={setStatusUsuarioOpt} options={[
                                        { value: 'Ativo', label: 'Ativo' },
                                        { value: 'Inativo', label: 'Inativo' },
                                    ]} />
                                </div>
                                {(modalData !== null && modalData.id !== undefined) ?
                                    <div className='col-12 flex-col mt-4'>
                                        <label className='font-12px font-bold text-center mx-auto'>Listagem de Permissões do Usuário</label>
                                        <PickList
                                            ref={pickListRef}
                                            className={'w-100per'}
                                            todosDefault={permissoes}
                                            selecionadosDefault={permissoesSelecionadas}
                                            onSelectedChange={setPermissoesAlteradas}
                                        />
                                    </div>
                                    : <></>
                                }
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset mt-4">
                            <legend>Endereço</legend>
                            <div className="row">
                                <div className="col-3">
                                    <InputMask ref={CEPRef} value={cep || ''} onChange={(e) => setCep(e.target.value)} title="CEP" name="cep" icon={null} maskFormat={'99999-999'} disabled={false} onBlur={(ev) => getCep(ev.target.value)} required={'required'} autoComplete="off" />
                                </div>
                                <div className="col-8">
                                    <Input title="Rua:" name="endereco" id="endereco" defaultValue={modalData === null ? '' : modalData.endereco} autoComplete="off" required={'required'} />
                                </div>
                                <div className="col-1">
                                    <Input title="Estado:" name="estado" id={'estado'} defaultValue={modalData === null ? '' : modalData.estado} autoComplete="off" required={'required'} />
                                </div>
                                <div className="col-3">
                                    <Input title="Bairro:" name="bairro" id="bairro" defaultValue={modalData === null ? '' : modalData.bairro} autoComplete="off" required={'required'} />
                                </div>
                                <div className="col-4">
                                    <Input title="Cidade:" name="cidade" id={'cidade'} defaultValue={modalData === null ? '' : modalData.cidade} autoComplete="off" required={'required'} />
                                </div>
                                <div className="col-2">
                                    <Input title="Número:" name="numero" id="numero" defaultValue={modalData === null ? '' : modalData.numero} autoComplete="off" required={'required'} />
                                </div>
                                <div className="col-3">
                                    <Input title="Complemento:" name="complemento" defaultValue={modalData === null ? '' : modalData.complemento} autoComplete="off" />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset mt-4">
                            <legend>Dados de Funcionário</legend>
                            <div className="row">
                                <div className="col-6">
                                    <Input title="Data de Entrada:" name="data_entrada" icon={null} type={'date'} disabled={false} defaultValue={modalData === null ? '' : modalData.data_entrada} autoComplete="off" />
                                </div>
                                <div className="col-6">
                                    <Input defaultValue={modalData === null ? '' : modalData.data_saida} title="Data de Saída:" name="data_saida" icon={null} type={'date'} disabled={false} autoComplete="off" />
                                </div>
                                <div className="col-4">
                                    <Input name={'cargo'} required={'required'} title={'Cargo:'} defaultValue={modalData === null ? '' : modalData.cargo} autoComplete="off" />
                                </div>
                                <div className="col-4">
                                    <Select onChange={handleChangeTipoUsuario} name={'tipo_user'} required={'required'} label={'Tipo de usuário:'} options={grupos} value={tipoUser || ''} />
                                </div>
                                {tipoUser?.label.toUpperCase() === 'AUDITOR' &&
                                    <div className="col-4">
                                        <Select onChange={setPlanoSelecionadoOpt} options={planosSaude} value={planoSelecionadoOpt} name={'plano_saude_id'} required={'required'} label={'Plano Saúde Vinculado:'}></Select>
                                    </div>
                                }
                            </div>
                        </fieldset>
                        {modalData?.id &&
                            <>
                                <fieldset className="ns-fieldset mt-4">
                                    <legend>Documentos Gerais</legend>
                                    <div className="row">
                                        {documentosList.length === 0 ?
                                            <div className="col-12 align-item-center justify-content-center">
                                                Nenhum documento encontrado
                                            </div>
                                            :
                                            documentosList.map((item, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div className="col-4">
                                                            <Input title={'Nome:'} name="nome" disabled={'disabled'} value={item === undefined ? '' : item.nome} />
                                                        </div>
                                                        <div className="col-4">
                                                            <Input title={'Data:'} name="data" disabled={'disabled'} value={item === undefined ? '' : moment(item.created_at).format('YYYY-MM-DD' + ', ' + 'HH:mm')} />
                                                        </div>
                                                        <div className="col-4">
                                                            <label style={{ fontSize: 13, fontWeight: 'bold', marginTop: 10 }}>Opções:</label>
                                                            <div style={{
                                                                display: 'flex', backgroundColor: '#ececec', padding: '8px 75px', borderRadius: 10,
                                                                borderStyle: 'solid', borderWidth: 2, borderColor: '#dddddd', justifyContent: 'center', alignItems: 'center'
                                                            }}>
                                                                <div className="d-flex">
                                                                    <BsDownload className="pointer" size={20} onClick={() => { downloadDocumento(item.id); }}></BsDownload>
                                                                    <AiOutlineDelete className="pointer" size={20} onClick={e => handleDeleteDocumento(item)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div style={leftContentDivStyle}>
                                        <button onClick={e => handleOpenModalDocumento()} type="button" className="btn-padrao">
                                            <BsPlusCircle className="mr-10px" size={20} fontWeight={'bolder'}></BsPlusCircle> Documento
                                        </button>
                                    </div>
                                </fieldset>
                                <fieldset className="ns-fieldset mt-4">
                                    <legend>Contatos de Emergência</legend>
                                    <div className="col-12">
                                        <TableDefaultPagination source={contatoUser} columns={contatosUserColumns} />
                                        <div style={leftContentDivStyle}>
                                            <button onClick={e => handleOpenContatoUser()} type="button" className="btn-padrao">
                                                <BsPlusCircle className="mr-10px" size={20} fontWeight={'bolder'}></BsPlusCircle> Contato de Emergência
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </>
                        }
                        <Button className="btn-padrao mt-4" type="submit" disabled={loading}> {modalData === null ? 'Cadastrar' : 'Alterar'} Funcionário</Button>
                    </Form>
                    <ContatoUserModal ref={modalContatoUserRef} contatoList={contatoUser} userId={modalData?.id} setContatosList={setContatosUser} />
                    {modalDocumento && <ModalDocumentoFuncionario onClose={() => setModalDocumento(false)} documentos={documentosGerais}
                        setDocumentos={setDocumentosGerais} modalData={documentoData} isEdit={isEdit} setIsEdit={setIsEdit}
                        documentosList={documentosList} setDocumentosList={setDocumentosList} />}
                </Container>
                <ModalDocumento ref={documentoRef} documentos={documentosList} user_id={modalData?.id} type='funcionario' setDocumentos={setDocumentosList} />
            </Modal>
        </>
    );
    //#endregion

};

export default forwardRef(FuncionariosModal);