import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import Input from '../../../../components/Input';
import { useNavigate, useParams } from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import { TableDocumentosGerais } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableDocumentosGerais';
import { TableHistoricoDeFerias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableHistoricoDeFerias';
import { TableTerapias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableTerapias';
import TableResponsavel from '../../../RecepcaoDashboard/Pacientes/Perfil/TableResponsavel';
import ModalResponsavel from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalResponsavel';
import ModalTerapia from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalTerapia';
import Select from '../../../../components/Select';
import ModalDocumento from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalDocumento';
import ModalFerias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalFerias';
import TerapiaPacienteService from '../../../../services/TerapiaPacienteService';
import ResponsavelService from '../../../../services/ResponsavelService';
import PacienteFeriasService from '../../../../services/PacienteFeriasService';
import DocumentoService from '../../../../services/DocumentoService';
import PacienteService from '../../../../services/PacienteService';
import api from '../../../../services/api';
import { TableDocumentosAssinadosResponsavel } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableDocumentosAssinadosResponsavel';
import Cep from '../../../../services/cep';
import moment from 'moment/moment';
import ModalInformacoesComplementares from '../../../../components/Modais/InformacoesComplementares';
import { Container, StatusAfastado, StatusAguardando, StatusAtivo, StatusFerias, StatusInativo } from '../../../AdminDashboard/Pacientes/Perfil/styles';
import { subHeader } from '../../../AdminDashboard/Terapeutas/Novo/styles';
import Responsavel from '../../../../layouts/Responsavel';
import { TableMedicosAssistentes } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableMedicos';
import ModalMedicos from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalMedicos';
import { TableMedicamentosPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableMedicamentos';
import ModalMedicamentos from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalMedicamentos';
import { TableEmergenciasPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableEmergencias';
import ModalEmergencias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalEmergencias';
import Swal from 'sweetalert2';
import { TableAlergiasPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableAlergiasPaciente';
import ModalAlergias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalAlergias';
import Formatter from 'services/Formatter';

export default function PerfilPacienteResponsavel() {

    const cepFormat = new Formatter('#####-###');
    const cpfFormat = new Formatter('###.###.###-##');
    const rgFormat = new Formatter('##.###.###-#');

    const [terapias, setTerapias] = useState([]);
    const [newTerapiaAdd, setNewTerapiaAdd] = useState(false);
    const [responsaveis, setResponsaveis] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [documentosAssinados, setDocumentosAssinados] = useState([]);
    const [feriasList, setFeriasList] = useState([]);
    const [feriasPaciente, setFeriasPaciente] = useState([]);
    const [pacienteId, setPacienteId] = useState(null);
    const [pacienteStatus, setPacienteStatus] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [statusColor, setStatusColor] = useState();
    const [emergencias, setEmergencias] = useState([]);
    const [medicos, setMedicos] = useState([]);
    const [medicamentos, setMedicamentos] = useState([]);
    const [alergias, setAlergias] = useState([]);
    const [imagemPerfil, setImagemPerfil] = useState(null);
    const [direitoImagem, setDireitoImagem] = useState(false);

    const [observacoes, setObservacoes] = useState('');
    const [infoComplementar, setInfoComplementar] = useState();
    const [comorbidades, setComorbidades] = useState('');
    const [infoComplModal, setInfoComplModal] = useState(false);
    const [identificadorList, setIdentificadorList] = useState();

    const [modalData, setModalData] = useState(null);
    const [modalResponsavelData, setModalResponsavelData] = useState(null);
    const [modalEmergenciasData, setModalEmergenciasData] = useState(null);
    const [modalMedicosData, setModalMedicosData] = useState(null);
    const [modalMedicamentosData, setModalMedicamentosData] = useState(null);
    const [modalAlergiasData, setModalAlergiasData] = useState(null);

    const modalResponsavelRef = useRef(null);
    const modalTerapiaRef = useRef(null);
    const modalDocumentoRef = useRef(null);
    const modalFeriasRef = useRef(null);
    const modalEmergenciasRef = useRef(null);
    const modalMedicosRef = useRef(null);
    const modalMedicamentosRef = useRef(null);
    const modalAlergiasRef = useRef(null);

    const { id } = useParams();
    const navigate = useNavigate();
    const day = new Date(moment().format('MM/DD/YYYY'));
    const documentoService = new DocumentoService('paciente');

    const formRef = useRef(null);

    const handleEditResponsavel = useCallback(() => {
        modalResponsavelRef.current.openModal();
    }, []);

    const handleEditTerapia = useCallback(() => {
        modalTerapiaRef.current.openModal();
    }, []);

    const handleEditAlergia = useCallback(() => {
        modalAlergiasRef.current.openModal();
    }, []);

    const handleEditEmergencias = useCallback(() => {
        modalEmergenciasRef.current.openModal();
    }, []);

    const handleEditModalMedicos = useCallback(() => {
        modalMedicosRef.current.openModal();
    }, []);

    const handleEditMedicamentos = useCallback(() => {
        modalMedicamentosRef.current.openModal();
    }, []);

    const handleOpenModalFerias = useCallback(() => {
        modalFeriasRef.current.openModal();
    }, []);

    const handleObservacoes = (e) => {
        setObservacoes(e.target.value);
    };

    const handleChangeDireito = (e) => {
        if (e.target.checked) {
            setDireitoImagem(true);
        } else {
            setDireitoImagem(false);
        }
    };

    const handleChangeStatus = (e) => {
        setStatusSelecionado(e);
        if (e.label === 'Ativo') setStatusColor(StatusAtivo);
        else if (e.label === 'Afastado') setStatusColor(StatusAfastado);
        else if (e.label === 'Inativo') setStatusColor(StatusInativo);
        else if (e.label === 'Férias') setStatusColor(StatusFerias);
        else {
            setStatusColor(StatusAguardando);
        }
    };

    const getDefaultStatus = (statusPaciente) => {
        if (!paciente) return;
        const status = statusPaciente.filter(data => {
            return data.label === paciente.status;
        })[0];
        setStatusSelecionado(status);
    };
    const cadastrar = async (data) => {
        data.id_plano_saude = 1;
        data.nascimento = data.nascimento.split('/').reverse().join('-');
        data.cpf = data.cpf ? data.cpf.replace(/[\D]/g, '') : '';
        data.cep = data.cpf ? data.cep.toString().replace(/[\D]/g, '') : '';
        data.status = statusSelecionado.label;
        data.foto_perfil = imagemPerfil;
        data.nome_curto = data.nome_curto;
        data.numero = data.numero;
        data.cidade = data.cidade;

        const pacienteResponse = await PacienteService.store(data);

        if (infoComplementar != []) {
            data.gosto_de = infoComplementar.gosto_de;
            data.nao_gosto_de = infoComplementar.nao_gosto_de;
            data.meus_reforcadores = infoComplementar.meus_reforcadores;
            data.meu_hiperfoco = infoComplementar.meu_hiperfoco;
            data.informacoes_complementares = infoComplementar.informacoes_complementares;
        }

        if (responsaveis.length > 0) {
            responsaveis.forEach((responsavel) => {
                responsavel.id_paciente = pacienteResponse.data.id;
                ResponsavelService.store(responsavel).catch(() => {
                    alert('Atenção! Houve um erro ao tentar cadastrar o responsável, por favor, edite o paciente e realize o cadastro do responsável novamente');
                });
            });
        }

        if (terapias.length > 0) {
            terapias.forEach((terapia) => {
                terapia.paciente_id = pacienteResponse.data.id;
                TerapiaPacienteService.store(terapia).catch(() => {
                    alert('Atenção! Houve um erro ao tentar cadastrar terapias, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente');
                });
            });
        }

        if (feriasList.length > 0) {
            feriasList.forEach((ferias) => {
                ferias.paciente_id = pacienteResponse.data.id;
                PacienteFeriasService.store(ferias);
            });
        }

        if (documentos.length > 0) {
            documentos.forEach((documento) => {
                documento.id_paciente = pacienteResponse.data.id;
                documentoService.store(documento);
            });
        }
    };

    const alterar = async (data) => {

        data.id_plano_saude = 1;
        data.nascimento = data.nascimento.split('/').reverse().join('-');
        data.cpf = data.cpf ? data.cpf.replace(/[\D]/g, '') : '';
        data.cep = data.cpf ? data.cep.toString().replace(/[\D]/g, '') : '';
        data.status = statusSelecionado.label;
        data.foto_perfil = imagemPerfil;
        data.nome_curto = data.nome_curto;
        data.numero = data.numero;
        data.cidade = data.cidade;

        const pacienteResponse = await PacienteService.update(data, pacienteId);

        if (responsaveis.length > 0) {
            responsaveis.forEach((responsavel) => {
                responsavel.id_paciente = pacienteResponse.data.id;
                ResponsavelService.store(responsavel);
            });
        }

        if (terapias.length > 0) {
            terapias.forEach((terapia) => {
                terapia.paciente_id = pacienteResponse.data.id;
                TerapiaPacienteService.store(terapia);
            });
        }

        if (feriasList.length > 0) {
            feriasList.forEach((ferias) => {
                ferias.paciente_id = pacienteResponse.data.id;
                PacienteFeriasService.store(ferias);
            });
        }

        if (documentos.length > 0) {
            documentos.forEach((documento) => {
                documento.id_paciente = pacienteResponse.data.id;
                documentoService.store(documento);
            });
        }
    };

    const checkIdentificador = (idPaciente) => {
        if (paciente === null && pacienteId === null) {
            const check = identificadorList.find(identificador => identificador.toString().toLowerCase() === idPaciente.toString().toLowerCase());
            if (check === undefined) return 0;
            else return 1;
        } else {
            const checkPaciente = identificadorList.find(identificador => identificador.toString().toLowerCase() === paciente.identificador.toString().toLowerCase());
            const checkData = identificadorList.find(identificador => identificador.toString().toLowerCase() === idPaciente.toString().toLowerCase());
            if (checkPaciente !== undefined && idPaciente === paciente.identificador) return 0;
            else if (checkPaciente === undefined && checkData === undefined) return 0;
            else if (idPaciente !== paciente.identificador && checkData !== undefined) return 1;
        }
    };

    async function handleSubmit(data) {
        data = {
            ...data,
            obs: observacoes,
            comorbidades: comorbidades,
        };

        try {
            if (checkIdentificador(data.identificador) === 1) {
                Swal.fire('Já existe um paciente utilizando este identificador! \n \n Por favor utilize outro!', '', 'warning');
            } else {
                data.identificador = data.identificador.toString().toUpperCase();
                if (pacienteId === null) {
                    cadastrar(data);
                } else {
                    alterar(data);
                }
                navigate('/dashboard/responsavel/pacientes');
            }
        } catch (error) {

            console.error(error);
        }
    }

    const carregaPaciente = () => {
        PacienteService.show(id, 'with=documentos,paciente_ferias,terapia_pacientes.especialidade,' +
            'terapia_pacientes.terapium,terapia_pacientes.plano_saude,paciente_contato_emergencia,paciente_medicamentos,' +
            'paciente_medicos_assistentes')
            .then(({ data }) => {

                if (data.responsavels.length > 0) {
                    setResponsaveis(data.responsavels);
                    api.get(`/api/responsavel-assinaturas?with=documentos_administrativo,responsavel&filter[responsavel_id]=
                        ${data.responsavels[data.responsavels.length - 1].id}`)
                        .then(({ data }) => {
                            data.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                            setDocumentosAssinados(data);
                        });

                }
                if (data.documentos.length > 0) {
                    const documentosData = data.documentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setDocumentos(documentosData);
                };
                if (data.paciente_ferias.length > 0) {
                    const feriasData = data.paciente_ferias.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setFeriasPaciente(feriasData);
                };
                if (data.terapia_pacientes.length > 0) {
                    const terapiasData = data.terapia_pacientes.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setTerapias(terapiasData);
                };
                if (data.paciente_contato_emergencia.length > 0) {
                    const emergenciasData = data.paciente_contato_emergencia.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setEmergencias(emergenciasData);
                };
                if (data.paciente_medicamentos.length > 0) {
                    const medicamentosData = data.paciente_medicamentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicamentos(medicamentosData);
                }
                if (data.paciente_medicos_assistentes.length > 0) {
                    const medicoData = data.paciente_medicos_assistentes.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicos(medicoData);
                }
                setPaciente(data);
                setPacienteId(data.id);
                delete data.responsavels;
                delete data.documentos;
                delete data.terapia_pacientes;

                data = {
                    ...data,
                    cpf: data.cpf ? cpfFormat.format(data.cpf.toString()) : '',
                    cep: data.cep ? cepFormat.format(data.cep.toString()) : '',
                    rg: data.rg ? rgFormat.format(data.rg.toString()) : '',
                    nascimento: moment.utc(data.nascimento).format('YYYY-MM-DD'),
                };
                setObservacoes(data.obs);
                setComorbidades(data.comorbidades);
                setInfoComplementar({
                    gosto_de: data.gosto_de,
                    nao_gosto_de: data.nao_gosto_de,
                    meus_reforcadores: data.meus_reforcadores,
                    meu_hiperfoco: data.meu_hiperfoco,
                    informacoes_complementares: data.informacoes_complementares,
                });
                formRef.current.setData({
                    ...data,
                });
            })
            .catch((e) => console.error('e', e));
    };

    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro;
        document.getElementById('bairro').value = data.bairro;
        document.getElementById('estado').value = data.uf;
        document.getElementById('cidade').value = data.localidade;
    };

    useEffect(() => {
        if (paciente !== null) {
            if (statusSelecionado.label === 'Ativo') setStatusColor(StatusAtivo);
            else if (statusSelecionado.label === 'Afastado') setStatusColor(StatusAfastado);
            else if (statusSelecionado.label === 'Inativo') setStatusColor(StatusInativo);
            else if (statusSelecionado.label === 'Férias') setStatusColor(StatusFerias);
            else {
                setStatusColor(StatusAguardando);
            }
        }
    }, [statusSelecionado]);

    useEffect(() => {
        getDefaultStatus(pacienteStatus);
        if (paciente !== null && paciente !== undefined) setPacienteId(paciente.id);
        if (feriasPaciente.length > 0) {
            let index = feriasPaciente.length - 1;
            let data_ida = new Date(feriasPaciente[index].dt_ida);
            let data_volta = new Date(feriasPaciente[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [paciente]);

    useEffect(() => {
        setFeriasPaciente(feriasList);
        if (feriasList.length > 0) {
            let index = feriasList.length - 1;
            let data_ida = new Date(feriasList[index].dt_ida);
            let data_volta = new Date(feriasList[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [feriasList]);

    useEffect(() => {
        let statusList = [];
        statusList.push({
            label: 'Ativo',
            value: 'ativo'
        });
        statusList.push({
            label: 'Inativo',
            value: 'inativo'
        });
        statusList.push({
            label: 'Afastado',
            value: 'afastado'
        });
        statusList.push({
            label: 'Férias',
            value: 'ferias'
        });
        statusList.push({
            label: 'Aguardando Liberação',
            value: 'aguardando'
        });
        setPacienteStatus(statusList);
        carregaPaciente();
    }, []);

    return (
        <Responsavel>
            <Container>
                <div className="div-submenu">
                    <label className="selecionado">
                        Perfil do Paciente
                    </label>
                </div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '0 15px' }}>
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <button className="btn-padrao" onClick={e => setInfoComplModal(true)} >
                                Informações Complementares
                            </button>
                        </div>
                        <div className="col-sm-12 col-lg-4 d-flex justify-center">
                            <img className="perfil-image" id='image' src={paciente?.foto_perfil ? paciente.foto_perfil : user} height={140} width={140} alt="" />
                        </div>
                        <div className="col-sm-12 col-lg-4 d-flex justify-content-end">
                            <div style={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                backgroundColor: '#fff', borderRadius: 8, boxShadow: '0 0 3px 0px silver', padding: '2px 15px',
                                maxWidth: '100px', maxHeight: '50px'
                            }}>
                                {paciente === null ? '' : <div style={statusColor} />}
                                <label style={{ margin: 5 }}>{paciente === null ? '' : statusSelecionado.label}</label>
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <div className="flex-col">
                            <div className="row">
                                <div className="col-lg-5 col-sm-12">
                                    <Input title="Nome:" name="nome" readOnly disabled />
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <Input title="Nome Curto" name="nome_curto" readOnly disabled />
                                </div>
                                <div className="col-lg-1 col-sm-12">
                                    <Input maxLength={5} title="Identificador:" name="identificador" readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="Data de Entrada:" name="data_de_entrada" type={'date'} maskFormat={'99/99/9999'} defaultValue={moment.utc().format('YYYY-MM-DD')} readOnly disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <Select label="Status:" name="status" options={pacienteStatus} value={statusSelecionado} onChange={e => handleChangeStatus(e)} readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="Data de Nascimento:" name="nascimento" type={'date'} readOnly disabled />
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <Input title="CPF" name="cpf" readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="RG:" name="rg" readOnly disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="CEP" name="cep" id="cep" readOnly disabled />
                                </div>
                                <div className="col-lg-8 col-sm-12">
                                    <Input title="Rua:" name="endereco" id="endereco" readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="Cidade:" name="cidade" id="cidade" readOnly disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <Input title="Estado:" name="estado" id="estado" readOnly disabled />
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <Input title="Complemento:" name="complemento" id="complemento" readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="Bairro:" name="bairro" id="bairro" readOnly disabled />
                                </div>
                                <div className="col-lg-2 col-sm-12">
                                    <Input title="Número:" name="numero" readOnly disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <label className="font-lighter font-12px mt-2">
                                Autorização de Uso de Imagem:
                            </label>
                            <div className="fake-input disabled">
                                <div>
                                    <input className="me-2" name="resp_fiscal" type={'checkbox'} defaultChecked={paciente?.uso_de_imagem} readOnly disabled />
                                    <label>
                                        Permite uso de Imagem
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <label style={subHeader}>Terapias do(a) Paciente:</label>
                                </div>
                                <TableTerapias source={terapias} callBack={carregaPaciente} setModalData={setModalData} handleOpen={handleEditTerapia} modalData={modalData} paciente_id={pacienteId} setTerapias={setTerapias} readOnly={true} />
                            </div>
                            <div>
                                <ModalTerapia terapias={terapias} setTerapias={setTerapias} paciente_id={pacienteId} callBack={carregaPaciente} ref={modalTerapiaRef} setNewTerapiaAdd={setNewTerapiaAdd} modalData={modalData} />
                            </div>
                            <div>
                                <ModalResponsavel responsaveis={responsaveis} setResponsaveis={setResponsaveis} modalData={modalResponsavelData} ref={modalResponsavelRef} paciente_id={pacienteId} callBack={carregaPaciente} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Responsáveis do(a) Paciente:</label>
                                <TableResponsavel source={responsaveis} setModalData={setModalResponsavelData} pacienteId={pacienteId} callBack={carregaPaciente} handleOpen={handleEditResponsavel} setResponsaveis={setResponsaveis} readOnly={true} />
                            </div>
                            <div>
                                <ModalEmergencias ref={modalEmergenciasRef} modalData={modalEmergenciasData} emergencias={emergencias} setEmergencias={setEmergencias} pacienteId={pacienteId} callBack={carregaPaciente} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Contatos de Emergência:</label>
                                <TableEmergenciasPaciente source={emergencias} setModalEmergenciasData={setModalEmergenciasData} setEmergencias={setEmergencias} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditEmergencias} readOnly={true} />
                            </div>
                            <div>
                                <ModalMedicamentos modalData={modalMedicamentosData} callBack={carregaPaciente} medicamentos={medicamentos} ref={modalMedicamentosRef} setMedicamentos={setMedicamentos} paciente_id={pacienteId} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Medicamentos do(a) Paciente:</label>
                                <TableMedicamentosPaciente source={medicamentos} setMedicamentos={setMedicamentos} setModalMedicamentosData={setModalMedicamentosData} handleOpen={handleEditMedicamentos} callBack={carregaPaciente} pacienteId={pacienteId} readOnly={true} />
                            </div>
                            <div>
                                <ModalAlergias modalData={modalAlergiasData} callBack={carregaPaciente} alergias={alergias} ref={modalAlergiasRef} setAlergias={setAlergias} paciente_id={pacienteId} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Alergias do(a) Paciente:</label>
                                <TableAlergiasPaciente source={alergias} setAlergias={setAlergias} setModalAlergiasData={setModalAlergiasData} handleOpen={handleEditAlergia} callBack={carregaPaciente} pacienteId={pacienteId} readOnly={true} />
                            </div>
                            <div>
                                <ModalMedicos modalData={modalMedicosData} paciente_id={pacienteId} setMedicos={setMedicos} medicos={medicos} callBack={carregaPaciente} ref={modalMedicosRef} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Médicos Assistentes do(a) Paciente:</label>
                                <TableMedicosAssistentes source={medicos} setMedicos={setMedicos} setModalMedicosData={setModalMedicosData} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditModalMedicos} readOnly={true} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Documentos Gerais do(a) Paciente:</label>
                                <ModalDocumento documentos={documentos} setDocumentos={setDocumentos} ref={modalDocumentoRef} paciente_id={pacienteId} callBack={carregaPaciente} />
                                <TableDocumentosGerais source={documentos} deleteCallback={carregaPaciente} setDocumentos={setDocumentos} pacienteId={pacienteId} readOnly={true} />
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Documentos Assinados Responsável:</label>
                                <TableDocumentosAssinadosResponsavel source={documentosAssinados}></TableDocumentosAssinadosResponsavel>
                            </div>
                            <div className="col-12 mb-4">
                                <label style={subHeader}>Histórico Férias do(a) Paciente: </label>
                                <TableHistoricoDeFerias source={feriasPaciente} feriasList={feriasList} setFeriasList={setFeriasList} paciente_id={pacienteId} readOnly={true} />
                                <ModalFerias feriasList={feriasList} setFeriasList={setFeriasList} ref={modalFeriasRef} />
                            </div>
                        </div>
                    </Form>
                </div>
                <>
                    {
                        infoComplModal
                        && <ModalInformacoesComplementares onClose={() => setInfoComplModal(false)} infoComplementar={infoComplementar} paciente={paciente} setInfoComplementar={setInfoComplementar} callBack={carregaPaciente} readOnly={true} />
                    }
                </>
            </Container>
        </Responsavel>
    );
}