import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import moment from 'moment';
import { AiOutlineUser } from 'react-icons/ai';

const PerfilUsuario = () => {
    const { user } = useAuth();
    const [tipoUserReal, setTipoUserReal] = useState(null);
    const [tipoUser, setTipoUser] = useState(null);
    const [listaTipoUser, setListaTipoUser] = useState([]);
    const [dados, setDados] = useState([]);

    function formatarString(valor, formato) {
        console.log(valor, formato);
        let valorFormatado = '';
        let j = 0;
        if (valor) {
            for (let i = 0; i < formato.length; i++) {
                if (formato[i] === '?') {
                    valorFormatado += valor[j];
                    j++;
                } else {
                    valorFormatado += formato[i];
                }
            }
            for (let i = j; i < valor.length; i++) {
                valorFormatado += valor[i];
            }
            return valorFormatado;
        } else {
            return '';
        }
    }

    const handleChangeTipoUser = async (tipo) => {
        if (tipo === '') return;
        let localUser = user;
        localUser.tipo_user = tipo;
        sessionStorage.setItem('@Neuro:user', JSON.stringify(localUser));
        setTipoUser(tipo);
    };

    useEffect(() => {
        async function coletaDados() {
            let extra = [];
            let fotoPerfil = null;
            switch (user.tipo_user) {
                case 'responsavel':
                    extra = await api.get('api/responsavel?filter[user_id]=' + user.id).then(({ data }) => data ? data[0] : []);
                    break;
                case 'terapeuta':
                    extra = await api.get('api/terapeuta?filter[user_id]=' + user.id).then(({ data }) => data ? data[0] : []);
                    fotoPerfil = extra?.foto_perfil_full;
                    break;
                default:
                    break;
            }
            let dadosLocal = {
                ...user,
                ...extra,
                fotoPerfil: fotoPerfil,
            };
            dadosLocal.data_nascimento = dadosLocal.data_nascimento ? moment.utc(dadosLocal.data_nascimento.split('T')[0]).format('DD/MM/YYYY') : moment.utc(dadosLocal.nascimento.split('T')[0]).format('DD/MM/YYYY');
            if (dadosLocal.telefone) {
                dadosLocal.telefone = dadosLocal.telefone.replace(/\D/g, '');
            }
            if (dadosLocal.telefone1) {
                dadosLocal.telefone1 = dadosLocal.telefone1.replace(/\D/g, '');
            }
            if (dadosLocal.telefone2) {
                dadosLocal.telefone2 = dadosLocal.telefone2.replace(/\D/g, '');
            }
            if (dadosLocal.telefone3) {
                dadosLocal.telefone3 = dadosLocal.telefone3.replace(/\D/g, '');
            }
            if (dadosLocal.cpf) {
                dadosLocal.cpf = dadosLocal.cpf.replace(/\D/g, '');
            }
            if (dadosLocal.rg) {
                dadosLocal.rg = dadosLocal.rg.replace(/\D/g, '');
            }
            setDados(dadosLocal);
            let listaTipoUserLocal = await api.get('api/group').then(({ data }) => {
                return data.map((item) => {
                    return {
                        value: item.name,
                        label: item.name,
                    };
                });
            });
            listaTipoUserLocal.sort((a, b) => a.label.localeCompare(b.label));
            setListaTipoUser(listaTipoUserLocal);

            let tipoUserRealLocal = await api.get('api/user/tipo_user/' + user.id).then(({ data }) => data);
            setTipoUserReal(tipoUserRealLocal);
        }
        coletaDados();
    }, [user]);

    return (
        <div className="container mt-4">
            <div className='div-titulo'>
                <label className="">Perfil do Usuário <AiOutlineUser /></label>
            </div>
            {dados && (
                <div>
                    <form className='row'>
                        <div className="accordion accordion-flush col-lg-3 col-sm-12" id="accordionFlushExample1">
                            <div className="accordion-item">
                                <h2 className="accordion-header d-flex align-center">
                                    <button className="accordion-button font-bold font-20px word-break p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="true" aria-controls="flush-collapseOne1">
                                        {dados?.nome ? dados?.nome : dados.name ? dados.name : 'Usuário'}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne1" className="accordion-collapse collapse show pb-4" data-bs-parent="#accordionFlushExample1">
                                    <div className="accordion-body text-center justify-content-center">
                                        <img className='perfil-image quadrado-150px m-auto' style={{ minHeight: '150px', minWidth: '150px' }} src={dados?.fotoPerfil} alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion accordion-flush col-lg-9 col-sm-12" id="accordionFlushExample1">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                        Dados Pessoais
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        <div className={user.tipo_user !== 'responsavel' ? 'mb-3 col-sm-12 col-lg-4' : 'mb-3 col-sm-12 col-lg-6'}>
                                            <label htmlFor="name" className="form-label">Nome:</label>
                                            <input type="text" id="name" className="form-control" value={dados.name ? dados.name : dados.nome} disabled />
                                        </div>
                                        {user.tipo_user !== 'responsavel' && (
                                            <div className="mb-3 col-sm-12 col-lg-4">
                                                <label htmlFor="nome_curto" className="form-label">Nome Curto:</label>
                                                <input type="text" id="nome_curto" className="form-control" value={dados.nome_curto} disabled />
                                            </div>
                                        )}
                                        <div className={user.tipo_user !== 'responsavel' ? 'mb-3 col-sm-12 col-lg-4' : 'mb-3 col-sm-12 col-lg-6'}>
                                            <label htmlFor="nascimento" className="form-label">Data de Nascimento:</label>
                                            <input type="text" id="nascimento" className="form-control" value={dados.data_nascimento} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-6">
                                            <label htmlFor="cpf" className="form-label">CPF:</label>
                                            <input type="text" id="cpf" className="form-control" value={formatarString(dados.cpf, '???.???.???-??')} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-6">
                                            <label htmlFor="rg" className="form-label">RG:</label>
                                            <input type="text" id="rg" className="form-control" value={formatarString(dados.rg, '????????-?')} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Dados de Contato
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        {user.tipo_user === 'responsavel'
                                            ? <>
                                                <div className="mb-3 col-sm-12 col-lg-6">
                                                    <label htmlFor="telefone" className="form-label">Telefone1:</label>
                                                    <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone1, '(??) ?????-????')} disabled />
                                                </div>
                                                <div className="mb-3 col-sm-12 col-lg-6">
                                                    <label htmlFor="telefone" className="form-label">Telefone2:</label>
                                                    <input type="text" id="telfefone" className="form-control" value={formatarString(dados.telefone2, '(??) ?????-????')} disabled />
                                                </div>
                                            </>
                                            : user.tipo_user === 'terapeuta'
                                                ? <>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone1:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone1, '(??) ?????-????')} disabled />
                                                    </div>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone2:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone2, '(??) ?????-????')} disabled />
                                                    </div>
                                                    <div className="mb-3 col-sm-12 col-lg-4">
                                                        <label htmlFor="telefone" className="form-label">Telefone3:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone3, '(??) ?????-????')} disabled />
                                                    </div>
                                                </>
                                                : <>
                                                    <div className="mb-3 col-12">
                                                        <label htmlFor="telefone" className="form-label">Telefone:</label>
                                                        <input type="text" id="telefone" className="form-control" value={formatarString(dados.telefone, '(??) ?????-????')} disabled />
                                                    </div>
                                                </>
                                        }
                                        <div className="mb-3 col-12">
                                            <label htmlFor="email" className="form-label">Email:</label>
                                            <input type="email" id="email" className="form-control" value={dados.email} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed font-20px p-3" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Dados Residênciais
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body row">
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="cep" className="form-label">CEP:</label>
                                            <input type="text" id="cep" className="form-control" value={dados.cep} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-4">
                                            <label htmlFor="endereco" className="form-label">Endereço:</label>
                                            <input type="text" id="endereco" className="form-control" value={dados.endereco} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-1">
                                            <label htmlFor="numero" className="form-label">Número:</label>
                                            <input type="text" id="numero" className="form-control" value={dados.numero} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="bairro" className="form-label">Bairro:</label>
                                            <input type="text" id="bairro" className="form-control" value={dados.bairro} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-2">
                                            <label htmlFor="cidade" className="form-label">Cidade:</label>
                                            <input type="text" id="cidade" className="form-control" value={dados.cidade} disabled />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-lg-1">
                                            <label htmlFor="estado" className="form-label">Estado:</label>
                                            <input type="text" id="estado" className="form-control" value={dados.estado} disabled />
                                        </div>
                                        <div className="mb-3 col-12">
                                            <label htmlFor="complemento" className="form-label">Complemento:</label>
                                            <textarea id="complemento" className="form-control" value={dados.complemento} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {tipoUserReal === 'DESENVOLVEDOR' &&
                        <div className='mb-3 mt-4'>
                            <div className='div-titulo'>
                                <label className="">Alterar Tipo Usuário</label>
                            </div>
                            <form>
                                <div className='mb-3'>
                                    <label className="form-label">Tipo de Usuário:</label>
                                    <select className="form-select" value={tipoUser} onChange={(e) => handleChangeTipoUser(e.target.value)}>
                                        {listaTipoUser.map((item, index) => (
                                            <option key={index} value={item.value} selected={user.tipo_user === item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default PerfilUsuario;
