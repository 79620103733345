import React, {useState, useEffect} from 'react';
import Terapeuta from '../../../../layouts/Terapeuta';
import {Container, ContainerSub, FormField} from '../styles';
import { useNavigate } from 'react-router-dom';
import { AiOutlineBell, AiOutlineUser } from 'react-icons/ai';
import UsuarioLogado from '../../../../components/UsuarioLogado';
import api from '../../../../services/api';
import Documentacao from '../../Dashboard/Modal/Documentacao';
import AtividadeExterna from '../../Dashboard/Modal/AtividadeExterna';
import Materiais from '../../Dashboard/Modal/Materiais';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useAuth } from '../../../../context/auth';
import { useRef } from 'react';
import { useSnackbar } from '../../../../context/snackbar';

function MinhasSolicitacoes() {
    
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [solicitacaoMaterial, setSolicitacaoMaterial] = useState([]);
    const [solicitacaoAtividadeExterna, setSolicitacaoAtividadeExterna] = useState([]);
    const [solicitacaoDocumentacao, setSolicitacaoDocumentacao] = useState([]);
    const [isModalSolicitacaoDeMateriaisVisible, setIsModalSolicitacaoDeMateriaisVisible] = useState(false);
    const [isModalAtividadeExternaVisible, setIsModalAtividadeExternaVisible] = useState(false);
    const [isModalDocumentacaoVisible, setIsModalDocumentacaoVisible] = useState(false);
    const [modalData, setModalData] = useState();

    let navigate = useNavigate();
    const user = useAuth();
    const snackbar = useSnackbar();

    const handleOpenMateral = (data) => {
        setModalData(data);
        setIsModalSolicitacaoDeMateriaisVisible(true);
    };

    const handleOpenModalAtividade = (data) => {
        setModalData(data);
        setIsModalAtividadeExternaVisible(true);
    };

    const handleOpenDocumentos = (data) => {
        setModalData(data);
        setIsModalDocumentacaoVisible(true);
    };

    const handleDeleteSolicitacao = (id, name) =>  {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                var texto_rota = (name=='Solicitação Documentos') ? `api/solicitacao-documentacao/${id}` : `api/solicitacao-material/${id}?id=${id}`;
                api.delete(texto_rota).then(() => {
                    snackbar.displayMessage('Solicitação removida com sucesso!', 'success');
                    getAllSolicitacoes();
                }).catch((error) => {
                    Swal.fire('Houve um erro ao tentar remover o cadastro!', '', 'error'); 
                });
                    
        }});
    };

    const getAllSolicitacoes = async () => {
        try {
            api.get('api/status-solicitacao');
            api.get('api/solicitacao-documentacao?&with=status_solicitacao,paciente,especialidade,terapium').then(({ data }) => {
                let solicitacaoDocumentacaoOptions = [];
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                data.forEach((solicitacao) => {
                    solicitacaoDocumentacaoOptions.push({ 
                        id: solicitacao.id,
                        nome: solicitacao.paciente.nome,
                        terapia: solicitacao.terapium.terapia,
                        especialidade: solicitacao.especialidade.especialidade,
                        status: solicitacao.status_solicitacao.status,
                        data_hora: solicitacao.created_at,
                        documento: solicitacao.documento,
                        tipo: solicitacao.tipo,
                        nome_solicitacao: 'Solicitação Documentos',
                        descricao: solicitacao.descricao
                        //...
                    });                        
                });
                setSolicitacaoDocumentacao(solicitacaoDocumentacaoOptions);
                console.log(solicitacaoDocumentacao);
            });
        } catch (e) {
            console.log('Erro ao obter lista de pacientes - ', e);
        }
        try {
            api.get(`api/solicitacao-atividade-externa?filter[user_id]=${user.user.id}&with=status_solicitacao,paciente,terapium,especialidade`).then(({ data }) => {
                let solicitacaoAtividadeExternaOptions = [];
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                data.forEach((solicitacao) => {
                    solicitacaoAtividadeExternaOptions.push({ 
                        id: solicitacao.id,
                        paciente: solicitacao.paciente.nome,
                        terapia: solicitacao.terapium.terapia,
                        especialidade: solicitacao.especialidade.especialidade,
                        status: solicitacao.status_solicitacao.status,
                        data_hora: solicitacao.created_at,
                        objetivo: solicitacao.objetivo,
                        local: solicitacao.local,
                        horario: solicitacao.horario,
                        nome_solicitacao: 'Solicitação Atividade',
                        descricao: solicitacao.descricao
                        //...
                    });                        
                });
                setSolicitacaoAtividadeExterna(solicitacaoAtividadeExternaOptions);
                console.log(solicitacaoAtividadeExterna);
            });
        } catch (e) {
            Swal.fire('Erro ao obter lista de pacientes', '', 'error');
        }
        try {
            api.get(`api/solicitacao-material?filter[user_id]=${user.user.id}&with=status_solicitacao,paciente,terapium,especialidade`).then(({ data }) => {
                let solicitacaoMaterialOptions = [];
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                data.forEach((solicitacao) => {
                    solicitacaoMaterialOptions.push({ 
                        id: solicitacao.id,
                        paciente: solicitacao.paciente.nome,
                        terapia: solicitacao.terapium.terapia,
                        especialidade: solicitacao.especialidade.especialidade,
                        status: solicitacao.status_solicitacao.status,
                        data_hora: solicitacao.created_at,
                        nome_solicitacao: 'Solicitação Material',
                        data_da_utilizacao: solicitacao.data_da_utilizacao,
                        tempo_de_uso: solicitacao.tempo_de_uso,
                        // tipo_solicitacao: solicitacao.tipo_solicitacao,
                        descricao: solicitacao.descricao
                        //...
                    });                        
                });
                setSolicitacaoMaterial(solicitacaoMaterialOptions);
                console.log(solicitacaoMaterial);

            });
        } catch (e) {
            Swal.fire('Erro ao obter lista de pacientes', '', 'error');
        };
    };

    const setAllSolicitacoes = () => {
        const dadosSolicitacoes = [];
        dadosSolicitacoes.push({
            solicitacao_documento: solicitacaoDocumentacao,
            solicitacao_material: solicitacaoMaterial,
            solicitacao_atividade: solicitacaoAtividadeExterna,
        });
        console.log(dadosSolicitacoes);
        setSolicitacoes(dadosSolicitacoes);
    };

    function handleOpenModal(solicitacao){
        //if tipo solicitacao... open modal respectiva
        setModalData(solicitacao);
    }

    useEffect(() => {
        getAllSolicitacoes();
        console.log(user.user.id);
    }, []);

    useEffect(() => {
        setAllSolicitacoes();
    }, [solicitacaoAtividadeExterna, solicitacaoDocumentacao, solicitacaoMaterial]);
    
    
    return (
        <Terapeuta>
            <Container>
                <div style={{
                    display: 'flex', justifyContent: 'space-between',
                    backgroundColor: 'white',
                    borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
                    alignItems: 'center', height: 58,
                    padding: '0px 20px', marginBottom: '4rem',
                    boxShadow: '0 0 10px 0px silver',
                }}>
                    <div>
                        <label onClick={() => navigate('/dashboard/terapeuta/solicitacoes')} style={{ marginRight: 20, cursor: 'pointer' }}>Solicitações</label>
                        <label onClick={() => navigate('/dashboard/terapeuta/minhas_solicitacoes')} style={{ marginRight: 20, color: '#7340BD', fontWeight: 'bold', cursor: 'pointer' }}>Minhas solicitações</label>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <AiOutlineBell color="silver" size={20} />
                        <AiOutlineUser color="silver" size={20} />
                        <div>
                            <UsuarioLogado />
                        </div>
                    </div>
                </div>
                <ContainerSub>
                    <FormField>
                        <span style={{ 
                        color: '#4906AB',
                        fontSize: '2.0rem', 
                        
                        fontWeight: '800', 
                        marginBottom: '2rem'}}>
                        Minhas solicitações
                        </span>
                        <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                            <div className='d-flex align-items-center justify-content-center w-100'>
                                <div className='col-2'><span style={{width: '200px', color: '#4906AB', fontWeight:'600', margin: '0 70px'}}>Paciente:</span></div>
                                <div className='col-2'><span style={{width: '200px', color: '#4906AB', fontWeight:'600', margin: '0 40px'}}>Tipo de solicitação:</span></div>
                                <div className='col-2'><span style={{width: '200px', color: '#4906AB', fontWeight:'600', margin: '0 20px'}}>Status:</span></div>
                                <div className='col-2'><span style={{width: '200px', color: '#4906AB', fontWeight:'600', margin: '0 -10px'}}>Data de solicitação:</span></div>
                                <div className='col-2'><span style={{width: '200px', color: '#4906AB', fontWeight:'600', margin: '0 10px'}}>Ações:</span></div>
                            </div>
                        <div style={{maxHeight: '400px', overflowY: 'auto'}}>
                            {solicitacoes[0] === undefined ? 'Não foi possível carregar os dados, ' : solicitacoes[0].solicitacao_material.map((item) => {
                                return(
                                <div className='d-flex align-items-center justify-content-center w-100 mb-1 mt-1'>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.paciente} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.nome_solicitacao} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.status} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={moment(item.data_hora).format('DD/MM/YYYY')} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'>
                                        <button style={{
                                            backgroundColor: '#7340BD',
                                            color: '#FFFFFF',
                                           
                                            border: 'none',
                                            padding: '7px 15px',
                                            borderRadius: '10px',
                                            marginLeft: 10,
                                        }} onClick={e => handleOpenMateral(item)}>
                                            Abrir
                                        </button>
                                        {item.status === 'Solicitado' ? 
                                        <button className='col-2' style={{ 
                                            backgroundColor: '#7340BD',
                                            color: '#FFFFFF',
                                           
                                            border: 'none',
                                            padding: '7px 10px',
                                            width: '45%',
                                            borderRadius: '10px',
                                            marginLeft: 5,
                                        }} onClick={e => handleDeleteSolicitacao(item.id)}>
                                            Cancelar
                                        </button> : ''}
                                    </div>
                                </div>
                            );
                            })}
                            {solicitacoes[0] === undefined ? 'verifique sua conexão com a internet' : solicitacoes[0].solicitacao_atividade.map((item) => {
                                return(
                                <div className='d-flex align-items-center justify-content-center w-100 mb-1'>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.paciente} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.nome_solicitacao} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.status} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={moment(item.data_hora).format('DD/MM/YYYY')} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'>
                                        <button style={{
                                            backgroundColor: '#7340BD',
                                            color: '#FFFFFF',
                                           
                                            border: 'none',
                                            padding: '7px 15px',
                                            borderRadius: '10px',
                                            marginLeft: 10,
                                        }} onClick={e => handleOpenMateral(item)}>
                                            Abrir
                                        </button>
                                        {item.status === 'Pendente' ? 
                                        <button className='col-2' style={{ 
                                            backgroundColor: '#7340BD',
                                            color: '#FFFFFF',
                                           
                                            border: 'none',
                                            padding: '7px 10px',
                                            width: '45%',
                                            borderRadius: '10px',
                                            marginLeft: 5,
                                        }} onClick={e => handleDeleteSolicitacao(item.id)}>
                                            Cancelar
                                        </button> : ''}
                                    </div>
                                </div>
                            );
                            })}
                            {solicitacoes[0] === undefined ? '' : solicitacoes[0].solicitacao_documento.map((item) => {
                                return(
                                <div className='d-flex align-items-center justify-content-center w-100 mb-1'>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.nome} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.nome_solicitacao} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.status} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'><input readOnly={'readOnly'} defaultValue={moment(item.data_hora).format('DD/MM/YYYY')} style={{ width: '97%', boxShadow: '0 0 10px 0px silver', borderRadius: 8, height: 36, border: 'none', padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                    <div className='col-2'>
                                        <button style={{
                                            backgroundColor: '#7340BD',
                                            color: '#FFFFFF',
                                           
                                            border: 'none',
                                            padding: '7px 15px',
                                            borderRadius: '10px',
                                            marginLeft: 10,
                                        }} onClick={e => handleOpenMateral(item)}>
                                            Abrir
                                        </button>
                                        {item.status === 'Pendente' ? 
                                            <button className='col-2' style={{ 
                                                backgroundColor: '#7340BD',
                                                color: '#FFFFFF',
                                               
                                                border: 'none',
                                                padding: '7px 10px',
                                                width: '45%',
                                                borderRadius: '10px',
                                                marginLeft: 5,
                                            }} onClick={e => handleDeleteSolicitacao(item.id, item.nome_solicitacao)}>
                                                Cancelar
                                            </button> : ''
                                        }
                                    </div>
                                </div>
                            );
                            })}
                            </div> 
                        </div>
                    </FormField>
                </ContainerSub>
            </Container>
            {isModalSolicitacaoDeMateriaisVisible ? <Materiais onClose={() => setIsModalSolicitacaoDeMateriaisVisible(false)} pacientes={modalData.paciente} especialidades={modalData.especialidade} terapias={modalData.terapia} modalData={modalData} /> : (<></>)}
            {isModalAtividadeExternaVisible ? <AtividadeExterna onClose={() => setIsModalAtividadeExternaVisible(false)} pacientes={modalData.paciente} especialidades={modalData.especialidade} terapias={modalData.terapia} modalData={modalData} /> : (<></>)}
            {isModalDocumentacaoVisible ? <Documentacao onClose={() => setIsModalDocumentacaoVisible(false)} pacientes={modalData.paciente} especialidades={modalData.especialidade} terapias={modalData.terapia} modalData={modalData}/> : (<></>)}
        </Terapeuta>
    );
}

export default MinhasSolicitacoes;