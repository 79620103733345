import React, { useEffect, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { Container } from './styles';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { TableDiagnosticos } from './TableDiagnosticos';
import DiagnosticosModal from '../../../../components/Modais/DiagnosticosModal';
import Admin from '../../../../layouts/Admin';
import api from '../../../../services/api';
import Input from '../../../../components/Input';
import { Form } from '@unform/web';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Diagnosticos() {

	const [loading, setLoading] = useState(true);
	const [color, setColor] = useState('#8ed4ae');
	const [modalIsOpen, setIsOpen] = useState(false);
	const [diagnosticos, setDiagnostico] = useState([]);
	const [diagnosticoFiltro, setDiagnosticoFiltro] = useState('');

	const [selectedDiagnostico, setSelectedDiagnostico] = useState(null);

	const loadDianostico = () => {
		api.get('api/diagnostico')
			.then(({ data }) => {
				data.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});
				setDiagnostico(data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		loadDianostico();
	}, []);

	const showEditModal = (data) => {
		setSelectedDiagnostico(data);
		setIsOpen(true);
	};

	return (
		<Container>
			<div>
				<button onClick={() => { setSelectedDiagnostico(null); setIsOpen(true); }} className="btn-padrao">
					<AiFillFileAdd style={{ marginRight: 5 }} />
					Novo Diagnóstico
				</button>
			</div>
			<Form className="">
				<Input id='diagnostico' name='diagnostico' placeholder="Pesquisar" onChange={(e) => setDiagnosticoFiltro(e.target.value)} />
			</Form>
			<div>
				{(diagnosticos.length !== 0 && loading === false)
					? <TableDiagnosticos callback={loadDianostico} showEditModal={showEditModal}
						source={diagnosticos.filter((a) => {
							if (diagnosticoFiltro === '') return true;
							if (a?.diagnostico?.toLowerCase()?.includes(diagnosticoFiltro?.toLowerCase())) return true;
							return false;
						})} />
					: <ClipLoader color={color} loading={loading} css={override} size={50} />}
			</div>
			<DiagnosticosModal
				modalIsOpen={modalIsOpen}
				setIsOpen={setIsOpen}
				callback={loadDianostico}
				especialidade={selectedDiagnostico}
			/>
		</Container>
	);
}
