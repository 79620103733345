import styled from 'styled-components';

export const Modal = styled.div`
flex-direction: column; 
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 10;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`;


export const Container = styled.div`
display: flex;
flex-direction: column; 
background-color: #FFFFFF;
align-items: stretch;
padding: 30px;
color: #000;
width: 50%;
overflow-y: auto;
height: auto;
border-radius: 10px`;

export const Delete = {
    color: '#FFFFFF', 
    cursor: 'pointer',
    backgroundColor: '#7340BD',
   
    marginLeft: 10,
    borderRadius: 20,
    padding: '3px 10px',
    display: 'flex', 
    justifyContent: 'center',
};