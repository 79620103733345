import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const TerapeutaFilter = {
    width: '100%', 
    borderRadius: 8, 
    height: 36, 
    boxShadow: '0 0 10px 0px silver', 
    border: 'none',
    padding: 12, 
    marginRight: 10 
};

export const SubMenuStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    height: 58,
    padding: '0px 20px', 
    marginBottom: '4rem',
    boxShadow: '0 0 10px 0px silver',
};

export const SubMenuHeader = {
   
    marginRight: 20, 
    color: '#7340BD', 
    fontWeight: 'bold', 
    cursor: 'pointer' 
};

export const ButtonRegister = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    border: 'none',
    padding: '2px 22px',
    borderRadius: 8,
    marginRight: 12,
    height: 50
};

export const OpenButtonTable = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    height: 30,
    fontWeight: 'bold',
    padding: '2px 22px',
};