import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { DivButtonStyle } from './styles';
import Swal from 'sweetalert2';
import { ListaEsperaService } from '../../../../../services/ListaEsperaService';
import { TableDefaultPagination } from '../../../../../components/TableDefaultPagination';
import { FiCheckCircle, FiEdit, FiEye, FiTrash, FiXCircle } from 'react-icons/fi';

export function TableListaDeEspera({ source, callback, setComentario = () => { }, setModalComentarioOpen = () => { }, openModal, setModalInfo, setReadOnly }) {
	const RowData = (data, readOnly) => {
		setReadOnly(readOnly);
		setModalInfo(data);
		openModal();
	};

	const handleAceita = useCallback((id, tipo) => {
		Swal.fire({
			title: 'Deseja realmente efetivar esse paciente?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonText: 'Confirmar',
			denyButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				ListaEsperaService.update({ 'status': '1' }, id)
					.then(() => {
						Swal.fire('Status da espera alterado com sucesso!', '', 'success');
						callback();
					})
					.catch(() => Swal.fire('Houve um erro ao tentar alterar o status deste paciente.', '', 'error'));
			}
		});
	}, [callback]);

	const handleDesistente = useCallback((id) => {
		Swal.fire({
			title: 'O paciente realmente deseja desistir?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonText: 'Desistir',
			denyButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				ListaEsperaService.update({ 'status': '2' }, id)
					.then(() => {
						Swal.fire('Status da espera alterado com sucesso!', '', 'success');
						callback();
					})
					.catch(() => Swal.fire('Houve um erro ao tentar alterar o status deste paciente.', '', 'error'));
			}
		});
	}, [callback]);

	const handleDelete = useCallback((id) => {
		Swal.fire({
			title: 'Deseja realmente excluir esta Espera do Paciente?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonText: 'Confirmar',
			denyButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				ListaEsperaService.destroy(id)
					.then(() => {
						Swal.fire('Registro removido com sucesso!', '', 'success');
						callback();
					})
					.catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
			}
		});
	}, [callback]);

	// Definindo as cores de acordo com a prioridade
	const getPriorityColor = (priority) => {
		switch (priority) {
			case 0: return '#FFFF00'; // Amarelo
			case 1: return '#FFCC00';
			case 2: return '#FF9900';
			case 3: return '#FF6600';
			case 4: return '#FF3300';
			case 5: return '#FF0000'; // Vermelho
			default: return '#FFFF00'; // Amarelo como padrão
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Prioridade',
				accessor: 'tipo_demanda.prioridade',
				minWidth: 100,
				maxWidth: 100,
				filterable: true,
				Cell: ({ cell }) => {
					if (cell.row.original.tipo_demanda) {
						const cor = getPriorityColor(cell.row.original.tipo_demanda.prioridade);
						return (
							<div style={{
								backgroundColor: cor,
								color: cor,
								width: '30px',
								height: '30px',
								margin: '0 auto',
								borderRadius: '4px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<span>
									{cell.row.original.tipo_demanda.prioridade}
								</span>
							</div>
						);
					}
					return null;
				},
				getCellProps: () => ({
					style: {
						width: '100px',
						maxWidth: '100px',
						overflow: 'hidden'
					}
				}),
			},
			{
				Header: 'Tipo',
				accessor: 'tipo',
				filterable: true,
				Cell: ({ cell }) => {
					if (cell.row.original.nome) {
						return (
							<p className='font-verde m-auto'>
								Novo
							</p>
						);
					}
					return <p className='font-amarelo m-auto'> Demanda </p>;
				}
			},
			{
				Header: 'Nome',
				accessor: 'nome',
				filterable: true,
				Cell: ({ cell }) => {
					if (cell.row.original.nome) {
						return <>
							{cell.row.original.nome}
						</>;
					}
					return cell.row.original.paciente.nome;
				}
			},
			{
				Header: 'Data',
				accessor: 'created_at',
				filterable: true,
				Cell: ({ cell }) => (moment(cell.row.original.created_at).format('DD/MM/YYYY'))
			},
			{
				Header: 'Quantas Demanda',
				accessor: 'lista-espera-demanda',
				filterable: true,
				Cell: ({ cell }) => (cell.row.original.lista_espera_demandas.length + (cell.row.original.lista_espera_demandas.length === 1 ? ' Terapia' : ' Terapias'))
			},
			{
				Header: 'Status',
				accessor: 'status_name',
				filterable: true,
				Cell: ({ cell }) => {
					let status = cell.row.original.status_name;
					return (
						<p className={status === 'Aprovado' ? 'font-verde m-auto' : status === 'Aguardando' ? 'font-amarelo m-auto' : 'font-vermelho m-auto'}>
							{status}
						</p>
					);
				}
			},
			{
				Header: 'Demanda',
				accessor: 'tipo_demanda.tipo_demanda',
				filterable: true,
			},
			{
				Header: 'Espera',
				accessor: 'dias_em_espera',
				filterable: true,
				Cell: ({ cell }) => (moment().diff(moment(cell.row.original.created_at), 'days') + ' dias')
			},
			{
				Header: 'Comentário',
				accessor: 'comentarios',
				filterable: true,
				Cell: ({ cell }) => {
					return <div className='pl-6px pr-6px'>
						<button className='btn-icone' onClick={() => {
							setComentario(cell.row.original.comentarios);
							setModalComentarioOpen(true);
						}}>
							<FiEye size={22} color='#7340BD' />
						</button>
					</div>;
				}
			},
			{
				Header: 'Ações',
				accessor: 'acoes',
				filterable: false,
				Cell: ({ cell }) => (
					(<div style={DivButtonStyle}>
						{Number(cell.row.original.status) === 0 ?
							<>
								<div>
									<button className='btn-icone' onClick={(e) => RowData(cell.row.original, true)}>
										<FiEye size={22} color='#7340BD' />
									</button>
								</div>
								<div>
									<button className='btn-icone' onClick={(e, row) => RowData(cell.row.original, false)}>
										<FiEdit size={22} color="#7340BD" />
									</button>
								</div>
								<div>
									<button title='Botão que define a linha como desistênte.' onClick={() => handleDesistente(cell.row.original.id)} color="#7340BD" className='btn-icone'>
										<FiXCircle color="#7340BD" size={22} />
									</button>
								</div>
								<div>
									<button title='Botão que define a linha como aceita.' onClick={() => handleAceita(cell.row.original.id, cell.row.original.nome ? 'Novo' : 'Demanda')} color="#7340BD" className='btn-icone'>
										<FiCheckCircle color="#7340BD" size={22} />
									</button>
								</div>
								<div>
									<button className='btn-icone' onClick={() => handleDelete(cell.row.original.id)} color="#7340BD">
										<FiTrash size={22} color="#7340BD" />
									</button>
								</div>
							</>
							: <>
								<div>
									<button className='btn-icone' onClick={(e) => RowData(cell.row.original, true)}>
										<FiEye size={22} color='#7340BD' />
									</button>
								</div>
							</>
						}
					</div >
					)
				)
			}
		]
		, [handleAceita, handleDelete, handleDesistente, RowData, setComentario, setModalComentarioOpen]);



	return (
		<TableDefaultPagination columns={columns} source={source} id={'created_at'} />
	);
}
