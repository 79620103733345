import React, { useState } from 'react';
import { Badge, bgActive, bgActiveHover, bgInactive, bgInactiveHover } from './styles';
import Tutorial from './tutorial';
import { useAuth } from 'context/auth';

//#region TUTORIAL BADGE
const TutorialBadge = ({ steps, startsOn, hasTopicos, standartFinal = false }) => {
    //#region VARIAVEIS
    const [showTutorial, setShowTutorial] = useState(startsOn !== undefined ? true : false);
    const { user } = useAuth();
    //#endregion

    //#region HANDLES
    const handleFinish = () => {
        setShowTutorial(prev => !prev);
    };
    //#endregion

    //#region HTML
    return (
        <>
            <Badge
                className='unselectable'
                onClick={() => setShowTutorial((prev) => !prev)}
                style={{
                    animation: showTutorial ? 'pulse 1.5s infinite' : 'none',
                    backgroundColor: showTutorial ? bgActive : bgInactive,
                    hover: {
                        backgroundColor: showTutorial ? bgActiveHover : bgInactiveHover,
                    },
                }}>?</Badge>
            {showTutorial && <Tutorial steps={[
                ...steps,
                standartFinal &&
                {
                    title: 'Finalização',
                    text: <span>
                        Esse é o final do tutorial dessa tela. Você sempre pode reacessar as informações, clicando novamente no simbulo de pergunta,
                        ou pode acessar a documentação do sistema apertando <a href={'documentacao'}>aqui</a>!
                    </span>
                }
            ]} onFinish={handleFinish} hasTopicos={hasTopicos} />}
            <style>
                {`
                @keyframes pulse {
                    0% {
                        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.9);
                    }
                    70% {
                        box-shadow: 0 0 0 20px rgba(76, 175, 80, 0);
                    }
                    100% {
                        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
                    }
                }
                `}
            </style>
        </>
    );
    //#endregion
};
//#endregion
export { TutorialBadge };