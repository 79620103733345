import React, {useState} from 'react';
import { BackButtonStyle, ButtonStyle1, Modal, ModalContainer } from './styles';
import { Form } from '@unform/web';
import AltaRelatorio from './RelatorioAlta/index';
import { useNavigate } from 'react-router-dom';
import OrientacaoRelatorio from './RelatorioOrientacao';
import RelatorioAcompanhamento from './RelatorioDeAcompanhamento';
import AdequacaoRelatorio from './RelatorioDeAdequacao';
import RelatorioDeFaltas from './RelatorioDeFaltas';
import RelatorioDevolutiva from './RelatorioDevolutiva';
import RelatorioSupervisao from './RelatorioDeSupervisao';

function ModalRelalatoriosEstrategicos({ onClose, paciente }) {

    const [altaModal, setAltaModal] = useState(false);
    const [orientacaoModal, setOrientacaoModal] = useState(false);
    const [modalAcompanhamento, setModalAcompanhamento] = useState(false);
    const [modalAdequacao, setModalAdequacao] = useState(false);
    const [modalFaltas, setModalFaltas] = useState(false);
    const [modalDevolutiva, setModalDevolutiva] = useState(false);
    const [modalSupervisao, setModalSupervisao] = useState(false);

    let navigate = useNavigate();
    const id = 'modal-relatorios-estrategicos';

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    
    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <ModalContainer style={{overflowY: 'auto'}}>
                <div style={{position: 'relative'}}>
                    <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
                        right: '25px', fontSize: '25px', top: '-10px' }} onClick={() => onClose()}>
                    x
                    </label>
                </div>
                <div className='d-flex align-items.center justify-content-center'>
                    <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Relatórios Estratégicos</label>
                </div>
                <Form>
                    <div className='d-flex flex-column align-items.center justify-content-evenly p-5'>
                        <button onClick={e => setModalFaltas(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Faltas de Pacientes
                        </button>
                        <button onClick={e => setOrientacaoModal(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Orientação
                        </button>
                        <button onClick={e => setModalAcompanhamento(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Acompanhamento
                        </button>
                        <button onClick={e => setModalAdequacao(true)} className='mb-2' style={ButtonStyle1} >
                            Relátorio de Adequação de Terapia
                        </button>
                        <button onClick={e => setAltaModal(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Alta
                        </button>
                        <button onClick={e => setModalDevolutiva(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Devolutiva
                        </button>
                        <button onClick={e => setModalSupervisao(true)} className='mb-2' style={ButtonStyle1} >
                            Relatório de Supervisão
                        </button>
                    </div>
                    <div className='d-flex align-items.center justify-content-center'>
                        <button onClick={e => onClose()} style={BackButtonStyle}>
                            Voltar
                        </button>
                    </div>
                </Form>
            </ModalContainer>
            {altaModal && <AltaRelatorio onClose={() => setAltaModal(false)}/>}
            {orientacaoModal && <OrientacaoRelatorio onClose={() => setOrientacaoModal(false)}/>}
            {modalAcompanhamento && <RelatorioAcompanhamento onClose={() => setModalAcompanhamento(false)}/>}
            {modalAdequacao && <AdequacaoRelatorio onClose={() => setModalAdequacao(false)} />}
            {modalFaltas && <RelatorioDeFaltas onClose={() => setModalFaltas(false)} />}
            {modalDevolutiva && <RelatorioDevolutiva onClose={() => setModalDevolutiva(false)}/>}
            {modalSupervisao && <RelatorioSupervisao onClose={() => setModalSupervisao(false)}/>}
        </Modal>
    );
}

export default ModalRelalatoriosEstrategicos;