import { useState } from 'react';
import api from 'services/api';

const useEspecialidadesData = () => {
    const [especialidadesOptions, setEspecialidadesOptions] = useState([]);

    const fetchEspecialidades = async () => {
        try {
            const { data } = await api.get('api/especialidade');
            const options = data.map((especialidade) => ({
                value: especialidade.id,
                label: especialidade.especialidade
            }));
            setEspecialidadesOptions(options);
        } catch (error) {
            console.error('Erro ao carregar especialidades:', error);
        }
    };

    return { especialidadesOptions, fetchEspecialidades };
};

export default useEspecialidadesData;