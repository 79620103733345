import { Form } from '@unform/web';
import Button from 'components/Button';
import A4Page from 'components/Folha/A4-Retrato';
import AssinaturaA4 from 'components/Folha/Modulos/Assinatura';
import Input from 'components/Input';
import Select from 'components/Select';
import useEspecialidadesData from 'context/especialidade';
import usePacienteData from 'context/paciente';
import usePlanoSaudeData from 'context/plano-saude';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTerapeutasData from 'context/terapeuta';
import useTerapiasData from 'context/terapia';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCheck, BsPrinter, BsSearch, BsSortDown, BsSortUp } from 'react-icons/bs';
import { CgClose, CgExport } from 'react-icons/cg';
import { FaBroom, FaFilePdf } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import { ChangeCase } from 'services/ChangeCase';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { TableComporovantePresencial } from './styles';
import { useAuth } from 'context/auth';

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

const RelatoriosAtendimentos = () => {
    //#region VARIAVEIS
    //#region LISTAS
    const usuario = useAuth();
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const statusDeAssinaturaOptions = [
        { value: null, label: <span className='font-cinza font-light'>(vasíl)</span> },
        { value: 0, label: 'Pendente' },
        { value: 1, label: 'Assinado' }
    ];
    const statusLiberacaoOptions = [
        { value: 0, label: <CgClose className='font-vermelho' size={22} /> },
        { value: 1, label: <BsCheck className='success' size={30} /> }
    ];
    const statusExecucao = [
        { value: 'pendente', label: 'Pendente' },
        { value: 'executado', label: 'Executado' },
        { value: 'pago', label: 'Pago' }
    ];
    //#endregion

    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);
    //#region DADOS do DOCUMENTO
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [orientation, setOrientation] = useState('portrait');
    //#endregion

    //#region DADOS de FILTRO
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [tipoAtendimentoId, setTipoAtendimentoId] = useState('');
    const [statusAtendimentoId, setStatusAtendimentoId] = useState('');
    const [terapeutaId, setTerapeutaId] = useState('');
    const [liberado, setLiberado] = useState('');
    const [executado, setExecutado] = useState('');
    const [especialidadeId, setEspecialidadeId] = useState('');
    const [terapiaId, setTerapiaId] = useState('');
    const [pacienteId, setPacienteId] = useState('');
    const [statusDeAssinatura, setStatusDeAssinatura] = useState(null);
    const [planoSaudeId, setPlanoSaudeId] = useState('');
    const [filters, setFilters] = useState({
        data_inicio: '',
        data_fim: '',
        tipo_atendimento_id: '',
        status_atendimento_id: '',
        terapeuta_id: '',
        liberado: '',
        executado: '',
        paciente_id: '',
        especialidade_id: '',
        terapia_id: '',
        plano_saude_id: '',
        status_de_assinatura: '',
    });
    //#endregion

    /* Listagem dos atendimentos consultados */
    const [atendimentos, setAtendimentos] = useState(null);
    const [loading, setloading] = useState(false);

    //#region ORDENAMENTO da TABELA
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [sortedAtendimentos, setSortedAtendimento] = useState([]);
    //#endregion

    //Referencia de exportação
    const toExportRef = React.createRef();
    //#endregion
    //#region HANDLES
    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        if (atendimentos || sortedAtendimentos) limpaResultado();

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                break;
            case 'data_fim':
                setDataFim(value);
                break;
            case 'tipo_atendimento_id':
                setTipoAtendimentoId(tipoAtendimentosOptions.find(option => option.value === value));
                break;
            case 'status_atendimento_id':
                setStatusAtendimentoId(statusAtendimentosOptions.find(option => option.value === value));
                break;
            case 'terapeuta_id':
                setTerapeutaId(terapeutasOptions.find(option => option.value === value));
                break;
            case 'liberado':
                setLiberado(statusLiberacaoOptions.find(option => option.value === value));
                break;
            case 'executado':
                setExecutado(statusExecucao.find(option => option.value === value));
                break;
            case 'especialidade_id':
                setEspecialidadeId(especialidadesOptions.find(option => option.value === value));
                break;
            case 'terapia_id':
                setTerapiaId(terapiasOptions.find(option => option.value === value));
                break;
            case 'plano_saude_id':
                setPlanoSaudeId(planoSaudeOptions.find(option => option.value === value));
                break;
            case 'paciente_id':
                setPacienteId(pacienteOptions.find(option => option.value === value));
                break;
            case 'status_de_assinatura':
                setStatusDeAssinatura(statusDeAssinaturaOptions.find(option => option.value === value));
                break;
            default:
                break;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };
    const handleSubmit = async () => {
        setloading(true);
        limpaResultado();
        const noFiltersApplied = Object.values(filters).every(value => value === '' || value === null);

        if (noFiltersApplied) {
            const confirmProceed = await Swal.fire({
                title: 'Nenhum filtro foi aplicado',
                text: 'Deseja continuar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            });
            if (!confirmProceed.isConfirmed) {
                setloading(false);
                return;
            }
        }

        setAtendimentos(null);
        try {
            const response = await api.post('api/atendimento/relatorio/filtrado', filters);
            setAtendimentos(response.data);
            setloading(false);
        } catch (error) {
            console.error(error);
            setloading(false);
        }
    };
    const handleSort = (key) => {
        setSortConfig((prev) => {
            let direction = 'asc';
            if (prev.key === key && prev.direction === 'desc') {
                key = null;
                direction = null;
            } else {
                if (prev.key === key && prev.direction === 'asc') {
                    direction = 'desc';
                }
            }
            return { key, direction };
        });
    };
    const handleExport = (mode) => {
        switch (mode) {
            case 'print':
                exportToPrint();
                break;
            case 'image':
                exportToImage();
                break;
            case 'excel':
                exportToExcel();
                break;
            case 'pdf':
                exportToPdf();
                break;
            case 'atendimentos':
                geraDocumento(mode);
                break;
            case 'assinados':
                geraDocumento(mode);
                break;
            case 'comparecimento':
                let responsaveis = [];
                api.get('api/responsavel/get/by/paciente/' + pacienteId?.value).then(response => {
                    responsaveis = response?.data;
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    const responsavelOptions = responsaveis.map(responsavel => responsavel.nome);
                    Swal.fire({
                        title: 'Nome do Acompanhante',
                        html: `
                            <input list="responsaveis-list" id="responsavel-input" class="swal2-input" placeholder="Digite o nome do responsável">
                            <datalist id="responsaveis-list">
                                ${responsavelOptions.map(nome => `<option value="${nome}"></option>`).join('')}
                            </datalist>
                        `,
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        preConfirm: () => {
                            const responsavelInput = Swal.getPopup().querySelector('#responsavel-input').value;
                            if (!responsavelInput) {
                                Swal.showValidationMessage('Você precisa digitar um nome!');
                            }
                            return responsavelInput;
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            geraDocumento(mode, result.value);
                        }
                    });
                });
                break;
            default:
                break;
        }
    };
    //#endregion
    //#region FUNCOES
    const geraDocumento = (mode, nome_responsavel = null) => {
        try {
            if (mode) {
                if (mode !== 'assinados') {
                    const title = (mode === 'atendimentos')
                        ? 'Declaração| de Realização de Atendimentos'
                        : (mode === 'comparecimento')
                            ? 'Declaração de Acompanhamento'
                            : '';
                    setTitle(title);
                    if (mode === 'atendimentos' || (nome_responsavel && mode === 'comparecimento' && dataInicio && pacienteId)) {
                        const chunkedAtendimentos = [];
                        const range = 16;/* Quantidade de atendimentos por coluna */
                        for (let i = 0; i < sortedAtendimentos.length; i += range) {
                            chunkedAtendimentos.push(sortedAtendimentos.slice(i, i + range));
                        }
                        const content = <div className='w-100'>
                            <p className='font-size-24px'>
                                Declaramos para os devidos fins que o(a) paciente: {pacienteId.label}
                                {
                                    mode === 'comparecimento' ? `compareceu na data de ${moment.utc(dataInicio).format('DD/MM/YYYY')} e realizou os atendimentos terapêuticos nos horários abaixo (cada atendimento tem duração de 40 minutos):`
                                        : mode === 'atendimentos'
                                            ? 'realizou os atendimentos terapeuticos nas datas e horários listados abaixo (cada atendimento tem duração de 40 minutos):'
                                            : ''
                                }
                            </p>
                            <b>Obs.: É obrigatório o acompanhamento de um responsável legal pelo paciente durante todo o período do atendimento terapêutico.</b>
                            {mode === 'comparecimento' && <p className='ms-2'>Responsável: <b>{nome_responsavel}</b></p>}
                            <div className='row'>
                                {chunkedAtendimentos.map((chunk, index) => {
                                    const thClass = 'font-12px';
                                    const tdClass = 'font-12px';
                                    return (
                                        <div className={chunkedAtendimentos.length > 1 ? 'col-6' : 'col-12'} key={`table-document-${index}`}>
                                            <table key={`table-document-${index}`} className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th className={thClass}>Data</th>
                                                        <th className={thClass}>Horário</th>
                                                        <th className={thClass}>Terapia</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {chunk.map((atendimento) => {
                                                        const arrHorario = atendimento?.horarios_atendimento.horario.split(':');
                                                        const horario = `${arrHorario[0]}:${arrHorario[1]}`;
                                                        return (
                                                            <tr key={atendimento.id}>
                                                                <td className={tdClass}>{moment.utc(atendimento?.data_atendimento_inicial).format('DD/MM/YYYY')}</td>
                                                                <td className={tdClass}>{horario}</td>
                                                                <td className={tdClass}>{atendimento?.terapia?.terapia}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })}
                                <div className='col-12'>
                                    <AssinaturaA4 />
                                </div>
                            </div>
                        </div>;
                        setContent(content);
                        const filename = `${title}${dataInicio ? ` ${moment.utc(dataInicio).format('DD/MM/YYYY')}` : ''}${dataFim ? ` a ${moment.utc(dataFim).format('DD/MM/YYYY')}` : ''}${pacienteId ? ` - ${pacienteId.label}` : ''}`;
                        setFilename(filename);
                        setOrientation('portrait');

                        setIsGeraDocumentoOpen(true);
                    }
                } else {
                    const title = <span className='font-15px'>GUIA COMPOROVANTE PRESENCIAL</span>;
                    setTitle(title);
                    setOrientation('landscape');
                    const thClass = 'font-12px font-bold';
                    const tdClass = 'font-10px';
                    const registro = '1234567890';
                    const numero_guia = '006354621';
                    const contratado = 'NeuroIntensiva - Instituto de Neurologia e Terapia Intensiva';
                    const content = <div className='row'>
                        <div className="row">
                            <TableComporovantePresencial className='col-12 mt-0 mb-0'>
                                <tr>
                                    <th className={thClass}><b>Registro ANS:</b><br></br>
                                        {registro}
                                    </th>
                                    <th className={thClass}><b>Nome do Beneficiário:</b><br></br>
                                        {sortedAtendimentos[0]?.paciente?.nome}
                                    </th>
                                    <th className={thClass}><b>Cartão Nacional de Saúde:</b><br></br>
                                        1234567890
                                    </th>
                                    <th className={thClass}><b>Plano de Saúde:</b><br></br>
                                        {sortedAtendimentos[0]?.plano_saude?.plano_saude}
                                    </th>
                                    <th className={thClass}><b>Número da Guia:</b><br></br>
                                        {numero_guia}
                                    </th>
                                </tr>
                            </TableComporovantePresencial>
                            <TableComporovantePresencial className='col-12 mt-0 mb-0 border-top-none'>
                                <tr>
                                    <td className={'border-top-none'}><b>Código na Operado: </b><br></br>
                                        62196564906
                                    </td>
                                    <td className={'border-top-none'}><b>Nome do Contratado: </b><br></br>
                                        {contratado}
                                    </td>
                                    <td className={'border-top-none'}><b>Código CNES: </b><br></br>
                                        21940610
                                    </td>
                                </tr>
                            </TableComporovantePresencial>
                        </div>
                        <div className='row'>
                            <TableComporovantePresencial className='table table-striped col-12'>
                                <thead>
                                    <tr>
                                        <th className={thClass}>Data</th>
                                        <th className={thClass}>Horário</th>
                                        <th className={thClass}>Terapeuta</th>
                                        <th className={thClass}>Atendimento</th>
                                        <th className={thClass}>Terapia</th>
                                        <th className={thClass}>Especialidade</th>
                                        <th className={thClass}>Plano de Saúde</th>
                                        <th className={thClass}>Assinatura</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedAtendimentos.map((atendimento) => {
                                        const arrHorario = atendimento?.horarios_atendimento.horario.split(':');
                                        const horario = `${arrHorario[0]}:${arrHorario[1]}`;
                                        return (
                                            <tr key={atendimento.id}>
                                                <td className={tdClass}>{moment.utc(atendimento?.data_atendimento_inicial).format('DD/MM/YYYY')}</td>
                                                <td className={tdClass}>{horario}</td>
                                                <td className={tdClass}>{atendimento?.terapeuta?.nome}</td>
                                                <td className={tdClass}>{atendimento?.tipo_atendimento?.tipo_atendimento}</td>
                                                <td className={tdClass}>{atendimento?.terapia?.terapia}</td>
                                                <td className={tdClass}>{atendimento?.especialidade?.especialidade}</td>
                                                <td className={tdClass}>{atendimento?.plano_saude?.plano_saude}</td>
                                                <td className={tdClass}>{atendimento?.plano_saude?.plano_saude}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={1} className={tdClass}>
                                            <b>Data</b><br></br>
                                            {moment.utc(dataInicio).format('DD/MM/YYYY')}
                                        </td>
                                        <td colSpan={7} className={tdClass}>
                                            <b>Assinatura do Beneficiário</b>
                                            <hr className='w-100 mb-0' />
                                        </td>
                                    </tr>
                                </tfoot>
                            </TableComporovantePresencial>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <AssinaturaA4 />
                            </div>
                        </div>
                    </div>;
                    setContent(content);
                    const filename = `${title}${dataInicio ? ` ${moment.utc(dataInicio).format('DD/MM/YYYY')}` : ''}${dataFim ? ` a ${moment.utc(dataFim).format('DD/MM/YYYY')}` : ''}${pacienteId ? ` - ${pacienteId.label}` : ''}`;
                    setFilename(filename);

                    setIsGeraDocumentoOpen(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const exportToPrint = () => {
        const printContents = toExportRef.current.outerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };
    const exportToImage = () => {
        toPng(toExportRef.current).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'relatorio.png';
            link.href = dataUrl;
            link.click();
        });
    };
    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(toExportRef.current);
        XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
        XLSX.writeFile(wb, 'relatorio.xlsx');
    };
    const exportToPdf = () => {
        const filename = `Relatório de Atendimento ${dataInicio ? moment.utc(dataInicio).format('DD/MM/YYYY') : ''} ${dataFim ? ` a ${moment.utc(dataFim).format('DD/MM/YYYY')}` : ''}`;
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${filename}.pdf`
        };
        html2PDF(toExportRef.current, newOptions);
    };
    const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

    const limpaResultado = () => {
        setAtendimentos(null);
        setSortedAtendimento([]);
        setSortConfig({ key: null, direction: 'asc' });
        setTitle('');
        setContent('');
        setFilename('');
    };
    const limpaFiltros = () => {
        setloading(false);
        setDataInicio('');
        setDataFim('');
        setTipoAtendimentoId('');
        setStatusAtendimentoId('');
        setTerapeutaId('');
        setLiberado('');
        setExecutado('');
        setPacienteId('');
        setFilters({
            data_inicio: '',
            data_fim: '',
            tipo_atendimento_id: '',
            status_atendimento_id: '',
            terapeuta_id: '',
            liberado: '',
            executado: '',
            paciente_id: ''
        });
        limpaResultado();
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (!atendimentos) return [];
        const sorted = [...atendimentos];
        if (sortConfig.key) {
            sorted.sort((a, b) => {
                const aValue = getNestedValue(a, sortConfig.key);
                const bValue = getNestedValue(b, sortConfig.key);

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedAtendimento(sorted);
    }, [atendimentos, sortConfig]);

    useEffect(() => {/* ON LOAD */
        fetchTipoAtendimentosData();
        fetchStatusAtendimentosData();
        fetchTerapeutasData();
        fetchEspecialidades();
        fetchTerapias();
        fetchPlanoSaude();
        fetchPacienteOptionsData();
    }, []);
    //#endregion
    //#region HTML
    return (
        <>
            {isGeraDocumentoOpen === true
                ? <A4Page
                    onCloseClick={() => setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                    hasScrollBar={false}
                    orientation={orientation}
                />
                : <div className={'w-80vw mx-auto'}>
                    <div className="div-titulo w-100per">
                        <label className="unselectable">Relatórios de Atendimentos</label>
                    </div>
                    <Form onSubmit={handleSubmit} className='row d-flex flex-wrap gy-4 p-4'>
                        <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                            <legend>Período</legend>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12'>
                                    <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChange} />
                                </div>
                                <div className='col-lg-6 col-sm-12'>
                                    <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChange} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset col-sm-12 col-lg-8">
                            <legend>Atendimento</legend>
                            <div className='row'>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="tipo_atendimento_id" name="tipo_atendimento_id" label="Tipo de Atendimento:" title="Tipo de Atendimento:" options={tipoAtendimentosOptions} value={tipoAtendimentoId} onChange={(e) => handleChange({ target: { id: 'tipo_atendimento_id', name: 'tipo_atendimento_id', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="status_atendimento_id" name="status_atendimento_id" label="Status de Atendimento:" title="Status de Atendimento:" options={statusAtendimentosOptions} isClearable={true} value={statusAtendimentoId} onChange={(e) => handleChange({ target: { id: 'status_atendimento_id', name: 'status_atendimento_id', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="liberado" name="liberado" label="Status de Liberação:" title="Status de Liberação:" options={statusLiberacaoOptions} isClearable={true} value={liberado} onChange={(e) => handleChange({ target: { id: 'liberado', name: 'liberado', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="executado" name="executado" label="Status de Execução:" title="Status de Execução:" options={statusExecucao} isClearable={true} value={executado} onChange={(e) => handleChange({ target: { id: 'executado', name: 'executado', value: e.value } })} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset col-sm-12 col-lg-6">
                            <legend>Terapeuta</legend>
                            <div className='row'>
                                <div className='col-12'>
                                    <Select id="terapeuta_id" name="terapeuta_id" label="Terapeuta:" title="Terapeuta:" options={terapeutasOptions} isClearable={true} value={terapeutaId} onChange={(e) => handleChange({ target: { id: 'terapeuta_id', name: 'terapeuta_id', value: e.value } })} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset col-sm-12 col-lg-6">
                            <legend>Paciente</legend>
                            <div className='row'>
                                <div className='col-12'>
                                    <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} isClearable={true} value={pacienteId} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="ns-fieldset col-12">
                            <legend>Outros</legend>
                            <div className='row'>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="especialidade_id" name="especialidade_id" label="Especialidade:" title="Especialidade:" options={especialidadesOptions} isClearable={true} value={especialidadeId} onChange={(e) => handleChange({ target: { id: 'especialidade_id', name: 'especialidade_id', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="terapia_id" name="terapia_id" label="Terapia:" title="Terapia:" options={terapiasOptions} isClearable={true} value={terapiaId} onChange={(e) => handleChange({ target: { id: 'terapia_id', name: 'terapia_id', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="plano_saude_id" name="plano_saude_id" label="Plano Saude:" title="Plano Saude:" options={planoSaudeOptions} isClearable={true} value={planoSaudeId} onChange={(e) => handleChange({ target: { id: 'plano_saude_id', name: 'plano_saude_id', value: e.value } })} />
                                </div>
                                <div className='col-lg-3 col-sm-12'>
                                    <Select id="status_de_assinatura" name="status_de_assinatura" label="Status de Assinatura:" title="Status de Assinatura:" options={statusDeAssinaturaOptions} isClearable={true} onChange={(e) => handleChange({ target: { id: 'status_de_assinatura', name: 'status_de_assinatura', value: e.value } })} />
                                </div>
                            </div>
                        </fieldset>
                        <div className='col-12 mt-2 d-flex justify-content-end'>
                            <Button type="submit" className={'w-fit-content-i searching'}>
                                <BsSearch className='icone' size={30} /> Pesquisar
                            </Button>
                            <Button type="button" onClick={limpaFiltros} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} >
                                <FaBroom size={30} type="button" className="icone" /> Limpar Filtros
                            </Button>

                        </div>
                    </Form>
                    {loading &&
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                    <BsSearch className='icone manual-searching' size={30} />
                                </div>
                            </div>
                        </div>
                    }
                    {sortedAtendimentos && sortedAtendimentos.length > 0 ? (
                        <>
                            <div className="div-titulo w-100per">
                                <label className="unselectable">Resultados</label>
                            </div>
                            <fieldset className="ns-fieldset mt-4 col-12 bg-white">
                                <legend>Caixa de Ferramentas</legend>
                                <div className='row justify-content-center'>
                                    <fieldset className={`ns-fieldset mt-4 col-${(statusAtendimentoId?.value === 1 && dataInicio) || (statusAtendimentoId?.value === 1) ? 4 : 6}`}>
                                        <legend>Funcionalidades</legend>
                                        <div className='row p-0 m-0 justify-content-center'>
                                            <Button className='d-flex flex-column align-items-center w-fit-content-i mx-4' onClick={() => handleExport('print')}>
                                                <BsPrinter size={30} />Imprimir
                                            </Button>
                                        </div>
                                    </fieldset>

                                    <fieldset className={`ns-fieldset mt-4 col-${(statusAtendimentoId?.value === 1 && dataInicio) || (statusAtendimentoId?.value === 1) ? 4 : 6}`}>
                                        <legend>Exportar</legend>
                                        <div className='row p-0 m-0 justify-content-center align-items-center'>
                                            <Button className='d-flex flex-column align-items-center w-fit-content-i mx-4' onClick={() => handleExport('image')}>
                                                <CgExport size={30} />Imagem
                                            </Button>
                                            <Button className='d-flex flex-column align-items-center w-fit-content-i mx-4' onClick={() => handleExport('excel')}>
                                                <RiFileExcel2Fill size={30} />Excel
                                            </Button>
                                            <Button className='d-flex flex-column align-items-center w-fit-content-i mx-4' onClick={() => handleExport('pdf')}>
                                                <FaFilePdf size={30} />PDF
                                            </Button>
                                        </div>
                                    </fieldset>
                                    {((statusAtendimentoId?.value === 1 && dataInicio) || (statusAtendimentoId?.value === 1) || statusDeAssinatura !== null) &&
                                        <fieldset className="ns-fieldset col-4">
                                            <legend>Relatórios</legend>
                                            {pacienteId && (
                                                <div className='row p-0 m-0 justify-content-center'>
                                                    {(statusAtendimentoId?.value === 1 && dataInicio) &&
                                                        <Button className='w-fit-content-i flex-column align-items-center mx-4' onClick={() => handleExport('comparecimento')}
                                                            title={!(dataInicio === moment().format('YYYY-MM-DD') || (usuario.user.tipo_user.toUpperCase() === 'ADMINISTRADOR' || usuario.user.tipo_user.toUpperCase() === 'DESENVOLVEDOR')) ? 'Apenas o dia atual pode ser gerado' : ''}
                                                            disabled={!(dataInicio === moment().format('YYYY-MM-DD') || (usuario.user.tipo_user.toUpperCase() === 'ADMINISTRADOR' || usuario.user.tipo_user.toUpperCase() === 'DESENVOLVEDOR'))}>
                                                            <FaFilePdf size={30} />Comparecimento
                                                        </Button>
                                                    }
                                                    {(statusAtendimentoId?.value === 1) &&
                                                        <Button className='w-fit-content-i flex-column align-items-center mx-4' onClick={() => handleExport('atendimentos')}>
                                                            <FaFilePdf size={30} />Atendimentos Feitos
                                                        </Button>
                                                    }
                                                </div>
                                            )}
                                            {statusDeAssinatura?.value === 1 && (
                                                <div className='row p-0 m-0 justify-content-center'>
                                                    <Button className='w-150px flex-column align-items-center mx-4' onClick={() => handleExport('assinados')}>
                                                        <FaFilePdf size={30} />Atendimentos Assinados
                                                    </Button>
                                                </div>
                                            )}
                                        </fieldset>
                                    }
                                </div>
                            </fieldset>
                            <div ref={toExportRef} className='row bg-branco p-0 m-0'>
                                <div className='col-4'>
                                    <h4>Informações do Relatório:</h4>
                                    <ul className='text-decoration-none'>
                                        {Object.entries(filters).map(([key, value]) => {
                                            if (key === 'data_inicio' || key === 'data_fim') {
                                                return value && <li key={key}><strong>{ChangeCase.toCapitalizedCase(key.replace(/_/g, ' '))}:</strong> {moment.utc(value).format('DD/MM/YYYY')}</li>;
                                            }
                                            const option = [
                                                ...tipoAtendimentosOptions,
                                                ...statusAtendimentosOptions,
                                                ...terapeutasOptions,
                                                ...statusLiberacaoOptions,
                                                ...statusExecucao,
                                                ...especialidadesOptions,
                                                ...terapiasOptions,
                                                ...planoSaudeOptions
                                            ].find(option => option.value === value);
                                            return value && <li key={key}>
                                                <strong>{ChangeCase.toCapitalizedCase(key.replace(/_/g, ' ').replace(' id', ''))}:</strong> {
                                                    option
                                                        ? option.label
                                                        : value
                                                }</li>;
                                        })}
                                    </ul>
                                </div>
                                <div className='col-4'>
                                    <h4>Dados:</h4>
                                    <p><strong>Quantidade de Resultados:</strong> {sortedAtendimentos.length}</p>
                                </div>
                                <div className='col-12' style={{ overflowX: 'auto' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='all-center font-12px'> </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('data_atendimento_inicial')}>
                                                    Data {sortConfig.key === 'data_atendimento_inicial' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('horarios_atendimento.horario')}>
                                                    Horário {sortConfig.key === 'horarios_atendimento.horario' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('paciente.identificador')}>
                                                    Identificador {sortConfig.key === 'paciente.identificador' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('paciente.nome')}>
                                                    Paciente {sortConfig.key === 'paciente.nome' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('terapeuta.nome')}>
                                                    Terapeuta {sortConfig.key === 'terapeuta.nome' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('tipo_atendimento.tipo_atendimento')}>
                                                    Tipo de Atendimento {sortConfig.key === 'tipo_atendimento.tipo_atendimento' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('terapia_id')}>
                                                    <div className=''>
                                                        Terapia
                                                    </div>
                                                    {sortConfig.key === 'terapia_id' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('especialidade_id')}>
                                                    <div className=''>
                                                        Especialidade
                                                    </div>
                                                    {sortConfig.key === 'especialidade_id' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('plano_saude_id')}>
                                                    <div className=''>
                                                        Plano
                                                    </div>
                                                    {sortConfig.key === 'plano_saude_id' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('status_atendimento.status')}>
                                                    Status de Atendimento {sortConfig.key === 'status_atendimento.status' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('liberado')}>
                                                    Status de Liberação {sortConfig.key === 'liberado' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('executado')}>
                                                    Status de Execução {sortConfig.key === 'executado' ? (sortConfig.direction === 'asc' ? <BsSortDown /> : <BsSortUp />) : ''}
                                                </th>
                                                <th className='all-center font-12px' onClick={() => handleSort('executado')}>
                                                    Assinado
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedAtendimentos.map((atendimento) => {
                                                const arrHorario = atendimento?.horarios_atendimento.horario.split(':');
                                                const horario = `${arrHorario[0]}:${arrHorario[1]}`;
                                                return (
                                                    <tr key={atendimento?.id}>
                                                        <td className='font-12px'>{String(atendimento?.modo_criacao).slice(0, 1).toUpperCase()}</td>
                                                        <td className='font-12px'>{moment.utc(atendimento?.created_at).format('DD/MM/YYYY')}</td>
                                                        <td className='font-12px'>{horario}</td>
                                                        <td className='font-12px'>{atendimento?.paciente?.identificador}</td>
                                                        <td className='font-12px'>{atendimento?.paciente?.nome}</td>
                                                        <td className='font-12px'>{atendimento?.terapeuta?.nome}</td>
                                                        <td className='font-12px'>{atendimento?.tipo_atendimento?.tipo_atendimento}</td>
                                                        <td className='font-12px'>{`${atendimento?.terapia?.terapia}`}</td>
                                                        <td className='font-12px'>{`${atendimento?.especialidade?.especialidade}`}</td>
                                                        <td className='font-12px'>{`${atendimento?.nome_reduzido}`}</td>
                                                        <td className='font-12px'>{atendimento?.status_atendimento?.status}</td>
                                                        <td className='font-12px'>{
                                                            atendimento?.liberado
                                                                ? <>
                                                                    Liberado
                                                                </>
                                                                : <>
                                                                    Não Liberado
                                                                </>
                                                        }</td>
                                                        <td className='font-12px'>{atendimento?.executado ? 'Executado' : 'Pendente'}</td>
                                                        <td className='font-12px'>{atendimento?.assinado ? 'Assinado' : 'Não Assinado'}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : ''}
                </div>
            }
        </>
    );
    //#endregion
};

export default RelatoriosAtendimentos;