import api from './api';

class AgendamentoService {
    static index = (params) => {
        return api.get(`api/agendamento?${params}`);
    };
    static store = (obj) => {
        return api.post('api/agendamento', obj);
    };
    /**
     * Função que tras via Identificador um agendamento especifico.
     * @param {Number} id Identificação numérica para encontrar o agendamento
     * @param {String} params Parâmetros para a consulta, tais como With entre outros. ? já inclusa na consulta.
     * @returns {*}
     */
    static show = (id, params = null) => {
        if (params === null) {
            return api.get(`api/agendamento/${id}`);
        } else {
            return api.get(`api/agendamento/${id}?${params}`);
        }
    };
    /**
     * Função que atualiza o registro no banco de dados de uma linha. Var obj precisa ter 'id' incluso.
     * @param {Array} obj Objeto contendo todos os dados do agendamento que serão atualizados
     * @returns {*} Retorna uma promessa com a resposta
     */
    static update = (obj) => {
        return api.put(`api/agendamento/${obj.id}?id=${obj.id}`, obj);
    };
    /**
     * 
     * @param {Number} id Identificador numérico do agendamento
     * @returns 
     */
    static destroy = (id) => {
        return api.delete(`api/agendamento/${id}`);
    };
    /**
     * Função que coleta uma lista de agendamentos do dia da semana enviado
     * @param {Number} weekDay Dia da semana
     * @param {String} with String de with para relacionar
     * @param {Array} where Array de filtros where
     * @returns {*}
     */
    static getByWeekDay = (weekDay, withParametros = null, withColunas = null, where = null) => {
        const colunas =
            'id' +
            ',paciente.identificador' +
            ',paciente.nome' +
            ',paciente.status' +
            ',paciente.aviso_agendamento_check' +
            ',terapeuta.status' +
            ',terapeuta.nome' +
            ',terapeuta.nome_curto' +
            ',horarios_atendimento_id' +
            ',paciente_id' +
            ',terapeuta_id' +
            ',estacao_id' +
            ',terapia_id' +
            ',especialidade_id' +
            ',tipo_atendimento_id' +
            ',reserva' +
            ',data_atendimento_inicial' +
            ',data_atendimento_final';
        return api.get(`api/agendamento/lista/simples?select=${colunas}${withParametros ? ',' + withColunas : ''}&with=paciente,terapeuta${withParametros ? ',' + withParametros : ''}&dia_semana=${weekDay}${where ? '&where=' + where : ''}`);
    };
}

export default AgendamentoService;
