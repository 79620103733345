import React, { useEffect, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { CgBlock } from 'react-icons/cg';
import api from '../../services/api';
import ModalRecados from './ModalRecados';
import moment from 'moment';

const Recados = () => {

    //#region VARIAVEIS
    //LISTAS
    const [recados, setRecados] = useState([]);
    //REFS
    const modalRecadoRef = useRef();
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {/* ONLOAD */
        api.get('api/recado?with=group')
            .then(({ data }) => {
                setRecados(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='container'>
            <div className='div-titulo'>
                <label>Recados</label>
            </div>
            <ModalRecados ref={modalRecadoRef}></ModalRecados>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tipo Usuário</th>
                        <th>Conteúdo</th>
                        <th>Validade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {recados.map((recado) => (
                        <tr key={recado.id}>
                            <td>{recado.id}</td>
                            <td>{recado.group.name}</td>
                            <td>{recado.conteudo}</td>
                            <td>{moment.utc(recado.valido_ate).format('DD/MM/YYYY HH:mm')}</td>
                            <td>
                                <BsEye size={22} />
                                <CgBlock size={22} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    //#endregion
};

export default Recados;