import api from './api';

class AreaCompartilhadaService {
  static index = () => {
    return api.get('api/area-compartilhada');
  };
  static store = (obj) => {
    /* exemplo
    {"area":"dolor"}
    */

    return api.post('api/area-compartilhada', obj);
  };
  static show = (id) => {
    return api.get(`api/area-compartilhada/${id}?id=${id}`);
  };
  static update = (obj, id) => {
    /* exemplo
    {"area":"adipisci"}
    */

    return api.put(`api/area-compartilhada/${id}`, obj);
  };
  static destroy = (id) => {
    console.log(`api/area-compartilhada/${id}?id=${id}`);
    return api.delete(`api/area-compartilhada/${id}?id=${id}`);
  };
}

export default AreaCompartilhadaService;
