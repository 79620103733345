import { useState } from 'react';
import api from 'services/api';

const useStatusAtendimentosData = () => {
    const [statusAtendimentosOptions, setStatusAtendimentosOptions] = useState([]);

    const fetchStatusAtendimentosData = async () => {
        try {
            const { data } = await api.get('api/status-atendimento');
            const options = data.map((statusAtendimento) => ({
                value: statusAtendimento.id,
                label: statusAtendimento.status,
                slug: statusAtendimento.slug,
            }));
            setStatusAtendimentosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar status atendimentos:', error);
        }
    };

    return { statusAtendimentosOptions, fetchStatusAtendimentosData };
};

export default useStatusAtendimentosData;