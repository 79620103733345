import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  } 

  body {
    background: #f6f6f9; 
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, label {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  
  button {
    cursor: pointer;
    border: none;
  }
  
  :root {
    --color-header: #572565;
    --color-background: #F5F7FA;
    --color-ad-text: #434649;
    --color-primary: #1B91FD;
    --color-cards: #FFFFFF;
  }
`;