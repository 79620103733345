import React, {useMemo} from 'react';
import {AiOutlineDelete,AiOutlineEdit} from 'react-icons/ai';
import Swal from 'sweetalert2';
import TerapiumService from '../../../../../services/TerapiumService';
import { TableDefaultPagination } from '../../../../../components/TableDefaultPagination';
import StatusAtendimentoService from '../../../../../services/StatusAtendimentoService';

export default function TableStatus({source, callback, editModal}) {

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        StatusAtendimentoService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
      }
    });
  };

  const columns = useMemo(
    () => [
          {
            Header: 'Status:',
            accessor: 'status',
          },
          {
            Header: 'Tipo de status:',
            accessor: 'slug',
          },
          {
            Header: 'Ações',
            accessor: 'acoes',
            Cell: ({cell}) => (
              (<div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent'
                }}>
                {cell.row.original.apagavel && <AiOutlineDelete style={{cursor: 'pointer'}} onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22}/>}
                  <AiOutlineEdit style={{cursor: 'pointer'}} onClick={() => editModal(cell.row.original)} color="#7340BD" size={22}/>
                </div>
              )
            )
          }
        ],
    []
  );

  return (
    <TableDefaultPagination columns={columns} source={source}/>
  );
}
