import React, { useState, useRef, useEffect } from 'react';

const DropdownCSS = ({
    button,
    substituicao,
    reserva,
    content,
    defaultOpen = false,
    dropdownZIndex,
    setMostrando = () => { }
}) => {
    const [isHovered, setIsHovered] = useState(defaultOpen ? true : false);
    const dropdownRef = useRef(null);

    // Verificação para ver se o conteúdo do dropdown é válido
    const isDropdownActive = content !== null && content !== undefined && content !== '';

    useEffect(() => {
        setMostrando(isHovered);
    }, [isHovered]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsHovered(false);
            }
        };

        if (isDropdownActive) {
            document.addEventListener('click', handleDocumentClick);
        }

        return () => {
            if (isDropdownActive) {
                document.removeEventListener('click', handleDocumentClick);
            }
        };
    }, [isDropdownActive]);

    const getDropdownPositionClass = () => {
        if (substituicao) {
            return 'ns-dropdown-menu-right';
        } else if (reserva) {
            return 'ns-dropdown-menu-left';
        }
        return 'ns-dropdown-menu-default';
    };

    // Função que retorna o botão do dropdown
    const dropdownButton = (
        <div type='button' className='ns-dropdown-btn-css p-0 m-0'>
            {button}
        </div>
    );

    // Função que retorna o conteúdo do dropdown apenas se estiver ativo
    const dropdownContent = isDropdownActive && (
        <div
            className={`ns-dropdown-menu-css ${getDropdownPositionClass()}`}
            style={{
                zIndex: dropdownZIndex || 'initial',
            }}
        >
            {content}
        </div>
    );

    return (
        <div className='ns-dropdown-parent-css' ref={dropdownRef}>
            {dropdownButton}
            {dropdownContent}
        </div>
    );
};

export default DropdownCSS;