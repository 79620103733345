import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../services/api';
import moment from 'moment';
import userGeneric from '../../../../assets/img_avatar.png';
import DetalhesDoAgendamento from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { listOfStyles } from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/styles';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import 'styles/reparticoes-css/agenda/agenda-modal.css';
import { toPng } from 'html-to-image';
import { useSnackbar } from 'context/snackbar';
import bgImage from 'assets/background-image.jpeg';
const AgendaModal = forwardRef(({ id = 'agendaModal', outSideSet, mode = 0 }, ref) => {
    //#region VARIÁVEIS
    /*===VARS TELA===*/
    const title = 'Agenda do Terapeuta';
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    /*===VARS snackbar===*/
    const snackbar = useSnackbar();
    /*===VARS VALIDACAO===*/
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    const [loading, setloading] = useState(false);

    /*===AGENDA REF===*/
    const elementRef = useRef();
    /*===VARS AGENDA===*/
    const [baseWidth, baseHeight] = [150, 100];
    const styles = listOfStyles(baseWidth, baseHeight);

    const [terapeuta, setTerapeuta] = useState(null);
    const [terapiasTerapeuta, setTerapiasTerapeuta] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedTerapeutaId, setSelectedTerapeutaId] = useState();
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'Segunda',
            short_label: 'S'
        },
        {
            value: 2,
            label: 'Terça',
            short_label: 'T'
        },
        {
            value: 3,
            label: 'Quarta',
            short_label: 'Q'
        },
        {
            value: 4,
            label: 'Quinta',
            short_label: 'Q'
        },
        {
            value: 5,
            label: 'Sexta',
            short_label: 'S'
        },
        {
            value: 6,
            label: 'Sábado',
            short_label: 'S'
        },
    ];
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 15
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '80vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            zIndex: 15,
            paddingTop: '0px',
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((terapeuta_id) => {
        setSelectedTerapeutaId(terapeuta_id);
        setIsOpen(true);
        outSideSet(true);
    }, []);
    const closeModal = useCallback(() => {
        setSelectedTerapeutaId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region HANDLES
    const handleExportToImage = (baixar) => {
        toPng(elementRef.current)
            .then((dataUrl) => {
                switch (baixar) {
                    case 'baixar':
                        snackbar.displayMessage('Baixando...');
                        const link = document.createElement('a');
                        link.download = `agenda-${moment().format('DD_MM_YYYY-HH_MM')}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        break;
                    case 'copiar':
                        fetch(dataUrl)
                            .then(res => res.blob())
                            .then(blob => {
                                navigator.clipboard.write([
                                    // eslint-disable-next-line no-undef
                                    new ClipboardItem({ 'image/png': blob })
                                ]).then(() => {
                                    snackbar.displayMessage('Imagem copiada para a área de transferência.');
                                }).catch((err) => {
                                    console.error('Erro ao copiar imagem: ', err);
                                });
                            })
                            .catch((err) => {
                                console.error('Erro ao gerar o blob da imagem: ', err);
                            });
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {
                console.error('Erro ao gerar imagem:', err);
            });
    };
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const getTerapeuta = () => {
        let terapeuta_id = selectedTerapeutaId;
        if (terapeuta_id !== undefined && terapeuta_id !== null) {
            api.get(`api/terapeuta/${terapeuta_id}?with=terapeuta_especialidades.especialidade,terapeuta_terapia.terapium`)
                .then(async ({ data }) => {
                    setTerapeuta(data);
                    setTerapiasTerapeuta(data.terapeuta_terapia);
                });
        }
    };
    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        });
    };
    const getAgendaDia = () => {
        let terapeuta_id = selectedTerapeutaId;
        api.get(`/api/agendamento?filter[terapeuta_id]=${terapeuta_id}&with=estacao,estacao.sala,terapium,especialidade`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        });
    };
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    //#endregion
    useEffect(async () => {
        await getAgendaHorarios();
    }, []);
    useEffect(async () => {
        if (selectedTerapeutaId !== undefined && selectedTerapeutaId !== null) {
            const carregaTudo = () => {
                getTerapeuta();
                getAgendaDia();
            };
            setloading(true);
            setTerapeuta(null);
            setTerapiasTerapeuta([]);
            setAtendimentos([]);
            await carregaTudo();
            setloading(false);
        }
    }, [selectedTerapeutaId]);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='d-flex justify-content-between'>
                    <div className='w-100vw'>
                        <div className='w-100 d-flex justify-content-between'>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('baixar')}>
                                Baixar Agenda
                            </button>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('copiar')}>
                                Copiar Agenda
                            </button>
                        </div>
                        <div className='w-100vw px-4 pb-4' style={{ backgroundImage: `url(${bgImage})`, }} ref={elementRef}>
                            <h4 className='text-center w-100per font-bold font-roxo pt-4 pb-2'>{terapeuta?.nome}</h4>
                            <table className='table-agenda bg-branco'>
                                <thead className='thead'>
                                    <tr>
                                        <td align={'center'} className='tr1'>Horário:</td>
                                        {diasDaSemanaArray.map(dia => (
                                            <td key={dia.value} align={'center'} className={diaSemanaIndex === dia.value ? 'tr2' : 'tr1'} style={{ width: baseWidth }}>{dia.label}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {horarios.map((horario, horarioIdx) => {
                                        const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                        return (
                                            <tr key={`linha-horario-${horarioIdx}`}>
                                                <td align={'center'} className='tr-hora' style={{ height: baseHeight }}>{horasExtenso}</td>
                                                {diasDaSemanaArray.map(dia => {
                                                    let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.reserva === 0 && (atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso));
                                                    const temAtendimento = atendimentoEncontrado.length > 0;
                                                    let legenda;
                                                    let content;
                                                    let div2style;
                                                    if (temAtendimento) {
                                                        atendimentoEncontrado = atendimentoEncontrado[0];
                                                        legenda = getLegendaAgendamento(atendimentoEncontrado, atendimentos);
                                                        div2style = {
                                                            ...styles[legenda]
                                                        };
                                                        content = <div className='table-detaild-agenda'>
                                                            <span>
                                                                {atendimentoEncontrado.estacao.sala.sala}/{atendimentoEncontrado.estacao.estacao}
                                                            </span>
                                                            <span>
                                                                {atendimentoEncontrado?.terapium?.terapia}
                                                            </span>
                                                            <span>
                                                                {atendimentoEncontrado?.especialidade?.especialidade}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoEncontrado.paciente.identificador}
                                                            </span>
                                                        </div>;
                                                    }
                                                    return (
                                                        <td onClick={() => temAtendimento ? modalDetalhesDoAtendimento(atendimentoEncontrado) : null}
                                                            key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                            className={`tr-vazio text-left d-grid font-size-12px ps-1 ${temAtendimento ? 'cursor-zoom' : ''}`}
                                                            style={{ ...div2style, width: baseWidth, height: baseHeight }}>
                                                            {content}
                                                        </td>

                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-content-start max-w-300px min-w-300px'>
                        <div className='d-flex flex-column align-items-center bg-white w-100' style={{ boxShadow: '0 0 10px 0px silver', borderRadius: 10, height: 'auto' }}>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-center'>
                                    <img src={
                                        (terapeuta === null)
                                            ? userGeneric
                                            : (terapeuta.foto_perfil_full === null || terapeuta.foto_perfil_full === '')
                                                ? userGeneric
                                                : terapeuta.foto_perfil_full
                                    } height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' alt='' />
                                </div>
                                <div className='d-flex flex-column m-2'>
                                    <span style={{}}>
                                        <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>Nome:</label>
                                        {terapeuta?.nome}
                                    </span>
                                    <span>
                                        <label style={{ color: '#707070', fontWeight: 'bold', }}>
                                            Terapias:
                                        </label>
                                        {terapiasTerapeuta &&
                                            <ul>
                                                {terapiasTerapeuta.map((value, index) => {
                                                    return <li key={index}>{value.terapium.terapia}</li>;
                                                })}
                                            </ul>
                                        }
                                    </span>
                                </div>
                            </div>
                            <button className='btn-padrao mb-2' onClick={() => { Navigate(`/dashboard/${user.tipo_user}/terapeutas/perfil/${id}`); }} >Cadastro</button>
                        </div>
                    </div>
                </div>
            </Modal>
            {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAgendamento
                agendamentoId={atendimentoId}
                setHouveExclusaoAgendamento={() => setHouveExclusaoAgendamento(true)}
                onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
            /> : (<></>)}
        </>
    );
});
export default AgendaModal;