import { useState } from 'react';
import api from 'services/api';

const usePacienteData = () => {
    const [pacientes, setPacientes] = useState([]);
    const [pacienteOptions, setPacienteOptions] = useState([]);
    /**
     * Fetches patient data and maps it to an options array for selection.
     * 
     * @param {Array} campos - Optional array specifying additional fields to include in the options. 
     *                         Default includes 'nome', 'identificador', 'aviso', and 'terapia_paciente'.
     * @param {Boolean} filterInativos - Parâmetro que define se o fetch ira retornar os invativos ou não. Por padrão, não retorna os inativos.
    **/
    const fetchPacienteData = async (campos = ['nome', 'identificador', 'aviso', 'terapia_paciente'], filterInativos = true) => {
        try {
            const com = `${campos.filter(filter => filter === 'terapia_paciente').length === 0 ? '?' : '?with=terapia_paciente&'}`;
            const selecionar = `${campos.filter(filter => filter === 'terapia_paciente').length === 0 ? '' : 'terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,' + campos['aviso_agendamento_check'] === undefined ? '' : 'aviso_agendamento_check,'}`;
            //const url = `api/paciente/lista/simples${com}select=${selecionar}id,nome,identificador,status`
            const url = 'api/paciente/lista/simples?with=terapia_paciente&select=terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,id,nome,identificador,status,aviso_agendamento_check';
            let { data } = await api.get(url);
            if (filterInativos) {
                data = data.filter((filter) => filter.status.toLowerCase() !== 'inativo');
            }
            const options = data.map((paciente, index) => {
                const option = {
                    value: paciente.id,
                    label: `(${paciente.identificador}) ${paciente.nome} ${paciente.status !== 'Ativo' ? `(${paciente.status})` : ''}`,
                    key: index,
                };
                campos.forEach(campo => {
                    if (paciente[campo] !== undefined) {
                        option[campo] = paciente[campo];
                    }
                });
                return option;
            });
            setPacienteOptions(options);
            setPacientes(data);
        } catch (error) {
            console.error('Erro ao carregar pacientes:', error);
        }
    };
    /**
     * Fetches patient data and maps it to an options array for selection.
     * 
     * @param {Array} campos - Optional array specifying additional fields to include in the options. 
     *                         Default includes 'nome', 'identificador', 'aviso', and 'terapia_paciente'.
     * @param {Boolean} filterInativos - Parâmetro que define se o fetch irá retornar os inativos ou não. Por padrão, não retorna os inativos.
    **/
    const fetchPacienteOptionsData = async (campos = ['nome', 'identificador', 'aviso', 'terapia_paciente'], filterInativos = true, agendamentos = null, hour = null, agendamentoId = null) => {
        try {
            const com = campos.includes('terapia_paciente') ? '?with=terapia_paciente&' : '?';
            const selecionar = `${campos.includes('terapia_paciente') ? 'terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,' : ''}id,nome,identificador,status,aviso_agendamento_check`;

            const url = `api/paciente/lista/simples${com}select=${selecionar}`;
            let { data } = await api.get(url);

            // Filtrar pacientes inativos
            if (filterInativos) {
                data = data.filter((filter) => filter.status.toLowerCase() !== 'inativo');
            }

            // Ordenar pacientes por nome
            data.sort((a, b) => a.nome.localeCompare(b.nome));

            const options = data.map((paciente, index) => {
                const isIndisponivel = agendamentos && hour && agendamentoId && agendamentos.some((agendamento) =>
                    agendamento.paciente_id === paciente.id && agendamento.data_atendimento_inicial === hour._i && agendamento.id !== agendamentoId
                );

                const addon = isIndisponivel ? '(Indisponível)' : paciente.status === 'Ativo' ? '' : `(${paciente.status})`;

                const option = {
                    value: paciente.id,
                    label: `(${paciente.identificador}) ${paciente.nome} ${addon}`,
                    key: index,
                };

                campos.forEach(campo => {
                    if (paciente[campo] !== undefined) {
                        option[campo] = paciente[campo];
                    }
                });

                return option;
            });

            setPacienteOptions(options);
            setPacientes(data);
        } catch (error) {
            console.error('Erro ao carregar pacientes:', error);
        }
    };

    
    return { pacientes, pacienteOptions, fetchPacienteData, fetchPacienteOptionsData };
};

export default usePacienteData;