import React from 'react';

const AssinaturaA4 = (props) => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).toUpperCase();

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }} {...props}>
            <p style={{ margin: 0 }}>CURITIBA, {formattedDate}</p>
            <br></br>
            <hr style={{ width: '50%', margin: '20px auto', borderTop: '1px solid black' }} />
            <p style={{ marginTop: -18, fontWeight: 'bold' }}>NEURO INTENSIVA</p>
        </div>
    );
};

export default AssinaturaA4;