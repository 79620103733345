import styled from 'styled-components';
import { css } from '@emotion/react';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;`;
 

export const ContainerCardsPacientes = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
`;


export const MainDivStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginBottom: 20,
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    height: 58,
    padding: '0px 20px',
    boxShadow: '0 0 10px 0px silver',
};

export const TdStyle = {
    padding: '10px',
    marginRight: '10px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 3px 0px silver'
};

export const ThStyle = {
    color: 'black', 
    fontWeight: 'bold',
    textAlign: 'left',
};