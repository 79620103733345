import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import userGeneric from '../../../assets/img_avatar.png';
import Responsavel from '../../../layouts/Responsavel';
import 'react-day-picker/dist/style.css';
import { Container, tr0, tr1, tr2, trHora, trOcupado, trVazio } from './styles';
import api from '../../../services/api';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import moment from 'moment';
import { useAuth } from '../../../context/auth';
import { BsEye, BsFileEarmarkPdf } from 'react-icons/bs';
import A4Page from 'components/Folha/A4-Retrato';
import Button from 'components/Button';
import AssinaturaA4 from 'components/Folha/Modulos/Assinatura';

export default function AgendaPacienteResponsavel(props, ref) {
    const { user } = useAuth();
    const { id } = useParams();
    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [horarios, setHorarios] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [pacientes, setPacientes] = useState(null);
    const [atendimentoId, setAtendimentoId] = useState();
    const [indexSelecionado, setIndexSelecionado] = useState(0);
    const [temDias, setTemDias] = useState();
    const [temHorarios, setTemHorarios] = useState();
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');

    const LongLabelBase = (props) => {
        return (
            <span>
                <b className='font-12px'>{props.dayName[0]}</b>
                <span className='font-8px'>{props.dayName.slice(1, props.dayName.length)}</span>
            </span>
        );
    }

    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'S',
            longLabel: <LongLabelBase dayName='Segunda-Feira' />,
        },
        {
            value: 2,
            label: 'T',
            longLabel: <LongLabelBase dayName='Terça-Feira' />,
        },
        {
            value: 3,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quarta-Feira' />,
        },
        {
            value: 4,
            label: 'Q',
            longLabel: <LongLabelBase dayName='Quinta-Feira' />,
        },
        {
            value: 5,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sexta-Feira' />,
        },
        {
            value: 6,
            label: 'S',
            longLabel: <LongLabelBase dayName='Sábado' />,
        },
    ];

    //#region FUNCOES
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    };
    function abreviarNome(nomeCompleto) {
        // Divide o nome completo em partes
        const partes = nomeCompleto.trim().split(' ');

        // Se houver apenas um nome, retorna-o como está
        if (partes.length === 1) {
            return nomeCompleto;
        }

        // Mantém o primeiro nome completo
        const primeiroNome = partes[0];

        // Abrevia os nomes intermediários e o último nome
        const nomesAbreviados = partes.slice(1).map(parte => parte.charAt(0) + '.');

        // Junta o primeiro nome com os nomes abreviados
        return [primeiroNome, ...nomesAbreviados].join(' ');
    }
    //#endregion
    //#region CARREGADORES
    const carregaPaciente = () => {
        api.get(`/api/paciente/${id}?with=agendamentos,agendamentos.estacao`)
            .then((response) => {
                let paciente = response.data;
                if (response.status === 200) {
                    let agendamentos = paciente.agendamentos.map((atendimento) => {
                        atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                        let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                        atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                        return atendimento;
                    });
                    let dias = [];
                    let horarios = [];
                    agendamentos.forEach(agenda => {
                        if (dias.filter((inc) => inc === moment.utc(agenda.data_atendimento_inicial).weekday()).length === -0) {
                            dias.push(moment.utc(agenda.data_atendimento_inicial).weekday());
                        }

                        if (horarios.filter(horario => horario === agenda.horarios_atendimento_id).length === 0) {
                            horarios.push(agenda.horarios_atendimento_id);
                        }
                    });
                    paciente.agendamentos = agendamentos;
                    setTemDias(dias);
                    setTemHorarios(horarios);
                }
                setIndexSelecionado(0);
                setPaciente(paciente);
            });
    };
    const carregaAgendaHorarios = () => {
        api.get(`/api/horario-atendimento?filter[dia_semana]=${moment().weekday()}`).then(({ data }) => {
            setHorarios(data);
        });
    };
    const carregaAgendaDia = () => {
        const dataSegundaFeira = moment().isoWeekday(1).format('YYYY-MM-DD');
        const dataSextaFeira = moment().isoWeekday(5).format('YYYY-MM-DD');
        api.get(`/api/agendamento?filter[paciente_id]=${id}&filter[between]=${dataSegundaFeira},${dataSextaFeira}&with=estacao`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        });
    };
    //#endregion
    const handleGeraRelatorioAgendamento = () => {
        setTitle(`Relatório de Agendamento ${paciente.nome} ${moment().format('DD/MM/YYYY')}`);
        setContent(
            <div className='d-flex flex-col align-items-start w-100'>
                <div className='w-100'>
                    <div className='w-100'>
                        <p className='font-size-24px'>Declaramos para os devidos fins que o(a) paciente: {paciente.nome} permanece em tratamento terapêutico na clínica Neuro Intensiva, sem previsão de alta, e possui a seguinte agenda semanal de atendimentos (cada atendimento tem duração de 40 minutos):</p>
                        <b>Obs.: É obrigatório o acompanhamento de um responsável legal pelo paciente durante todo o período do atendimento terapêutico.</b>
                    </div>
                    <br></br>
                    <br></br>
                    <table className='m-0 p-0 mx-auto w-100'>
                        <thead>
                            <tr className='d-flex'>
                                <th style={tr0} align={'center'}></th>
                                {diasDaSemanaArray.filter((filter) => temDias.filter(temDia => temDia === filter.value).length > 0)
                                    .map(dia => (
                                        <th key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? tr2 : tr1}>{dia.longLabel}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className='w-100 m-0 p-0 overflow-y-no-scroll h-auto'>
                            {horarios.filter((filter) => temHorarios.filter(temHorario => temHorario === filter.id).length > 0)
                                .map((horario, horarioIdx) => {
                                    const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                    const atendimentosHorario = paciente.agendamentos.filter(atendimento => atendimento.hora_inicial === horasExtenso);
                                    if (atendimentosHorario.length > 0) {
                                        return (
                                            <tr className='d-flex' key={`linha-horario-${horarioIdx}`}>
                                                <td align={'center'} style={trHora}>{horasExtenso}</td>
                                                {diasDaSemanaArray.filter((filter) => temDias.filter(temDia => temDia === filter.value).length > 0).map(dia => {
                                                    let atendimentoEncontrado = atendimentosHorario.filter(atendimento => atendimento.dia_semana_index === dia.value);
                                                    if (atendimentoEncontrado.length > 0) {
                                                        atendimentoEncontrado = atendimentoEncontrado[0];
                                                        return (
                                                            <td className='text-center p-0 m-0 d-grid cursor-default-i' onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                                key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                                style={trOcupado}>
                                                                <span className='d-flex justify-content-center flex-row'>
                                                                    Sala {atendimentoEncontrado.estacao.sala.sala}
                                                                    <br />
                                                                    Estação {atendimentoEncontrado.estacao.estacao}
                                                                </span>
                                                                {abreviarNome(atendimentoEncontrado.terapeutum.nome)}
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td style={trVazio} key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    } else {
                                        return null
                                    }
                                })}
                        </tbody>
                    </table>
                </div>
                <div className='w-100'>
                    <AssinaturaA4 />
                </div>
            </div>
        );
        setFilename(`Relatório de Agendamento ${paciente.nome} ${moment().format('DD-MM-YYYY')}`);

        setIsGeraDocumentoOpen(true);
    };
    //#region USE EFFECTS
    useEffect(() => {
        if (!houveExclusaoAgendamento) return;
        carregaAgendaDia();
        setHouveExclusaoAgendamento(false);
    }, [houveExclusaoAgendamento]);
    useEffect(() => {
        carregaAgendaHorarios();
        carregaPaciente();
    }, []);
    //#endregion
    //#region HTML
    return (
        <>{isGeraDocumentoOpen === true
            ? <A4Page
                onCloseClick={() => setIsGeraDocumentoOpen(false)}
                modalMode={true}
                title={title}
                content={content}
                filename={filename}
                hasScrollBar={false}
            />
            : <Responsavel>
                <Container>
                    <div className='d-flex justify-content-center'>
                        <Link to={'/dashboard/responsavel/agenda/paciente/' + paciente?.id}>
                            <label className='selecionado'>
                                {paciente?.nome}
                            </label>
                        </Link>
                    </div>
                    {/* AGENDA */}
                    <ul className='w-100 m-0 p-0'>
                        <li className='d-flex'>
                            <div style={tr0} align={'center'}></div>
                            {diasDaSemanaArray.map(dia => (
                                <div key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? tr2 : tr1}>{dia.label}</div>
                            ))}
                        </li>
                    </ul>
                    <ul className='w-100 m-0 p-0 overflow-y-no-scroll h-100vh-170px'>
                        {horarios.map((horario, horarioIdx) => {
                            const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                            return (
                                <li className='d-flex' key={`linha-horario-${horarioIdx}`}>
                                    <div align={'center'} style={trHora}>{horasExtenso}</div>
                                    {
                                        diasDaSemanaArray !== null && paciente !== null
                                            ? diasDaSemanaArray.map(dia => {
                                                let atendimentoEncontrado = paciente.agendamentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso);
                                                if (atendimentoEncontrado.length > 0) {
                                                    atendimentoEncontrado = atendimentoEncontrado[0];
                                                    return (
                                                        <div className='text-center p-0 m-0 d-grid' onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                            key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                            style={trOcupado}>
                                                            <span className='d-flex justify-content-center flex-row'>
                                                                Sala {atendimentoEncontrado.estacao.sala.sala}
                                                                <br />
                                                                Estação {atendimentoEncontrado.estacao.estacao}
                                                            </span>
                                                            {abreviarNome(atendimentoEncontrado.terapeutum.nome)}
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div style={trVazio} key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></div>
                                                );
                                            })
                                            : <></>
                                    }
                                </li>
                            );
                        })}
                        <li>
                            <Button onClick={handleGeraRelatorioAgendamento}>Relatório de Agendamento <BsFileEarmarkPdf style={{ marginLeft: 20 }} size={30} /></Button>
                        </li>
                    </ul>
                    {/* DETALHES DO PACIENTE */}
                    {false === true &&
                        <div className='col-lg-2 col-sm-12 mb-4'>
                            {/* BOX */}
                            <div className='d-flex align-items-center bg-white w-100per h-content-i p-2' style={{ boxShadow: '0 0 10px 0px silver', borderRadius: 10, height: 'auto' }}>
                                <div className='d-flex flex-lg-column flex-sm-row'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={paciente?.foto_perfil} alt={userGeneric} className='perfil-image mb-2 mt-2' />
                                    </div>
                                    <div className='d-flex flex-column m-2'>
                                        <span>
                                            <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                                Nome:
                                            </label> {paciente?.nome}
                                        </span>
                                        <span>
                                            <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                                Idade:
                                            </label> {moment(paciente?.nascimento).diff(moment(), 'years') * -1}
                                        </span>
                                        {paciente?.diagnostico1 &&
                                            <span>
                                                <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>
                                                    Diagnósticos:
                                                </label>
                                                <br></br>
                                                <ul>
                                                    {paciente?.diagnostico1 &&
                                                        <li key={0}>
                                                            {paciente?.diagnostico1?.diagnostico}
                                                        </li>
                                                    }
                                                    {paciente?.diagnostico2 &&
                                                        <li key={1}>
                                                            {paciente?.diagnostico2?.diagnostico}
                                                        </li>
                                                    }
                                                </ul>
                                            </span>}
                                    </div>
                                    <a className='btn-icone m-auto' href={`/dashboard/${user.tipo_user}/pacientes/perfil/${paciente?.id}`} target='blank'>
                                        <BsEye size={25}></BsEye>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                    {isModalDetalhesDoAtendimentoVisible
                        ? <DetalhesDoAgendamento
                            agendamentoId={atendimentoId}
                            setHouveExclusaoAgendamento={() => setHouveExclusaoAgendamento(true)}
                            onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
                        /> : (<></>)}
                </Container>
            </Responsavel>
        }</>
    );
    //#endregion
}
