import { Form } from '@unform/web';
import { TutorialBadge } from 'components/AjudanteInicial';
import Button from 'components/Button';
import Carregamento from 'components/Carregamento';
import Input from 'components/Input';
import ComentariosModal from 'components/Modais/Comentarios';
import Select from 'components/Select';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { useAuth } from 'context/auth';
import useEspecialidadesData from 'context/especialidade';
import usePacienteData from 'context/paciente';
import usePlanoSaudeData from 'context/plano-saude';
import { useSnackbar } from 'context/snackbar';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTerapeutasData from 'context/terapeuta';
import useTerapiasData from 'context/terapia';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { AiOutlineClear } from 'react-icons/ai';
import { BsCheck, BsEye, BsFilter, BsSearch } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { FaBroom, FaSave } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import ReactModal from 'react-modal';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: 'fit-content',
        width: '40vw',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

/**
 * PendenciasPagamento component is responsible for displaying and managing payment pending records.
 * 
 * This component includes:
 * - Various state variables to manage filters, data, and UI states.
 * - Functions to handle filtering, loading, and saving of payment pending records.
 * - Use of several hooks like useState, useEffect, useMemo, useCallback, and useRef.
 * - Integration with external APIs to fetch data and update records.
 * - UI elements including forms, tables, and modals for user interaction.
 * 
 * @component
 * @example
 * return (
 *   <PendenciasPagamento />
 * )
 */
const PendenciasPagamento = () => {
    //#region VARIAVEIS
    //INFOS DA TELA
    const title = 'Pendências de Pagamento';
    //OUTROS
    const snackbar = useSnackbar();
    //USUARIO
    const { user } = useAuth();
    //NAVEGAÇÃO
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    //REFERENCIAS
    const modalComentariosRef = useRef();
    //MODAL INICIAL
    const [modalInicialIsOpen, setModalInicialIsOpen] = useState(true);
    //#region LISTAS
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const memotipoAtendimentosOptions = useMemo(() => tipoAtendimentosOptions, [tipoAtendimentosOptions]);
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const memostatusAtendimentosOptions = useMemo(() => statusAtendimentosOptions, [statusAtendimentosOptions]);
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const memoterapeutasOptions = useMemo(() => terapeutasOptions, [terapeutasOptions]);
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const memoespecialidadesOptions = useMemo(() => especialidadesOptions, [especialidadesOptions]);
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const memoterapiasOptions = useMemo(() => terapiasOptions, [terapiasOptions]);
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const memoplanoSaudeOptions = useMemo(() => planoSaudeOptions, [planoSaudeOptions]);
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const memopacienteOptions = useMemo(() => pacienteOptions, [pacienteOptions]);
    const statusDeAssinaturaOptions = useMemo(() => [
        { value: null, label: <span className='font-cinza font-light'>(vazío)</span> },
        { value: 0, label: 'Pendente' },
        { value: 1, label: 'Assinado' }
    ], []);
    const statusLiberacaoOptions = useMemo(() => [
        { value: 0, label: <CgClose className='font-vermelho' size={22} /> },
        { value: 1, label: <BsCheck className='success' size={30} /> }
    ], []);
    const statusExecucao = useMemo(() => [
        { value: 'pendente', label: 'Pendente' },
        { value: 'executado', label: 'Executado' },
        { value: 'pago', label: 'Pago' }
    ], []);
    const statusExecucaoParticular = useMemo(() => [
        { value: '', label: <span className='font-cinza font-lighter font-small'>(Vazio)</span> },
        { value: 'pendente', label: 'Pendente' },
        { value: 'pago', label: 'Pago' }
    ], []);
    const periodos = useMemo(() => [
        { value: 'manha', label: 'Manhã' },
        { value: 'tarde', label: 'Tarde' }
    ], []);
    const diasDaSemana = [
        { value: 1, label: 'Segunda-feira' },
        { value: 2, label: 'Terça-feira' },
        { value: 3, label: 'Quarta-feira' },
        { value: 4, label: 'Quinta-feira' },
        { value: 5, label: 'Sexta-feira' },
        { value: 6, label: 'Sábado' },
        { value: 7, label: 'Domingo' }
    ];
    //#endregion
    //#region DADOS DE FILTRO
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [tipoAtendimentoId, setTipoAtendimentoId] = useState('');
    const [statusAtendimentoId, setStatusAtendimentoId] = useState('');
    const [terapeutaId, setTerapeutaId] = useState('');
    const [liberado, setLiberado] = useState('');
    const [executado, setExecutado] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [diaSemana, setDiaSemana] = useState('');
    const [especialidadeId, setEspecialidadeId] = useState('');
    const [terapiaId, setTerapiaId] = useState('');
    const [pacienteId, setPacienteId] = useState('');
    const [statusDeAssinatura, setStatusDeAssinatura] = useState(null);
    const [planoSaudeId, setPlanoSaudeId] = useState('');
    const [filters, setFilters] = useState({
        data_inicio: '',
        data_fim: '',
        tipo_atendimento_id: '',
        status_atendimento_id: '',
        terapeuta_id: '',
        liberado: '',
        executado: '',
        periodo: '',
        dia_semana: '',
        paciente_id: '',
        especialidade_id: '',
        terapia_id: '',
        plano_saude_id: '',
        status_de_assinatura: null,
        ok_financeiro: null,
    });
    const [filtroAlterado, setFiltroAlterado] = useState(false);
    const [dataAlterado, setDataAlterado] = useState(false);
    //#endregion
    //TABLE
    const [atendimentos, setAtendimentos] = useState([]);
    const memoAtendimentos = useMemo(() => atendimentos, [atendimentos]);
    const [atendimentosAlterados, setAtendimentosAlterados] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const memoAtendimentosOriginal = useMemo(() => atendimentosOriginal, [atendimentosOriginal]);
    const [salvando, setSalvando] = useState(false);
    const [loading, setloading] = useState(false);
    const [pendenteDeSave, setPendenteDeSave] = useState(false);
    //#endregion
    //#region FUNCOES
    /**
     * Filtra a lista de atendimentos com base nos filtros aplicados.
     *
     * Esta função aplica os filtros fornecidos pelo formulário aos atendimentos listados.
     * A filtragem é realizada com base nas seguintes condições:
     * - Data de início e fim do atendimento
     * - Tipo de atendimento
     * - Status de atendimento
     * - Terapeuta responsável pelo atendimento
     * - Status de liberação
     * - Status de execução (pendente, executado, pago)
     * - Paciente relacionado ao atendimento
     * - Especialidade do terapeuta
     * - Terapia realizada no atendimento
     * - Plano de saúde utilizado
     * - Status de assinatura do atendimento (pendente ou assinado)
     *
     * @param {Array} data - A lista de atendimentos que será filtrada.
     * @return {void}
     */
    const filtrar = useCallback((data = null) => {
        const atendimentosLocal = data ? data : memoAtendimentosOriginal;
        const filteredAtendimentos = atendimentosLocal.filter((item) => {
            /* DATA TIPO DE ATENDIMENTO */
            const matchesTipoAtendimentoId = filters.tipo_atendimento_id?.length > 0
                ? filters.tipo_atendimento_id.find((find) => Number(item.tipo_atendimento_id) === Number(find.value))
                : true;

            /* DATA SUGESTAO RECEPCAO */
            const matchesStatusAtendimentoId = filters.status_atendimento_id?.length > 0
                ? filters.status_atendimento_id.find((find) => Number(item.sugestao_recepcao) === Number(find.value))
                : true;

            /* DATA TERAPEUTA */
            const matchesTerapeutaId = filters.terapeuta_id?.length > 0
                ? filters.terapeuta_id.find((find) => Number(item.terapeuta_id) === Number(find.value))
                : true;

            /* DATA PACIENTE */
            const matchesPacienteId = filters.paciente_id?.length > 0
                ? filters.paciente_id.find((find) => Number(item.paciente_id) === Number(find.value))
                : true;

            /* DATA LIBERADO */
            const matchesLiberado = filters.liberado
                ? item.liberado === filters.liberado
                : true;

            /* DATA EXECUTADO */
            const matchesExecutado = filters.executado
                ? item.executado === filters.executado
                : true;

            /* DATA ESPECIALIDADE */
            const matchesEspecialidadeId = filters.especialidade_id?.length > 0
                ? filters.especialidade_id.find((find) => Number(item.especialidade_id) === Number(find.value))
                : true;

            /* DATA TERAPIA */
            const matchesTerapiaId = filters.terapia_id?.length > 0
                ? filters.terapia_id.find((find) => Number(item.terapia_id) === Number(find.value))
                : true;

            /* DATA DIA SEMANA */
            const matchesDiaSemana = filters.dia_semana?.length > 0
                ? filters.dia_semana.find((find) => item.paciente.periodos.find((find2) => String(find2.dia_semana).toUpperCase() === String(find.label).toUpperCase()))
                : true;

            /* DATA PERIODO */
            const matchesPeriodo = filters.periodo?.length > 0
                ? filters.periodo.find((find) => item.paciente.periodos.find((find2) => find2?.periodo?.includes(find.value)))
                : true;

            /* DATA PLANO DE SAÚDE */
            const matchesPlanoSaudeId = filters.plano_saude_id?.length > 0
                ? filters.plano_saude_id.find((find) => Number(item.plano_saude_id) === Number(find.value))
                : true;

            /* DATA STATUS DE ASSINATURA */
            const matchesStatusDeAssinatura = filters.status_de_assinatura !== null
                ? item.assinado === filters.status_de_assinatura
                : true;

            return (
                matchesTipoAtendimentoId &&
                matchesStatusAtendimentoId &&
                matchesTerapeutaId &&
                matchesLiberado &&
                matchesExecutado &&
                matchesPacienteId &&
                matchesEspecialidadeId &&
                matchesTerapiaId &&
                matchesPlanoSaudeId &&
                matchesDiaSemana &&
                matchesPeriodo &&
                matchesStatusDeAssinatura
            );
        });
        setAtendimentos(filteredAtendimentos);
        setFiltroAlterado(false);
    }, [filters, memoAtendimentosOriginal]);
    /**
     * Função assíncrona para carregar atendimentos com base em filtros específicos.
     * Define o estado de carregamento como verdadeiro, limpa a lista de atendimentos,
     * configura os filtros e faz uma requisição POST para obter os dados filtrados.
     * Após receber os dados, atualiza a lista de atendimentos original, aplica os filtros,
     * define o estado de carregamento como falso e marca que os dados não foram alterados.
     *
     * @async
     * @function carregarAtendimentos
     * @returns {Promise<void>} Uma promessa que resolve quando os atendimentos são carregados.
     */
    const carregarAtendimentos = useCallback(async () => {
        setloading(true);
        setAtendimentos([]);
        const carregaFiltros = {
            data_inicio: dataInicio,
            data_fim: dataFim,
            tipo_atendimento_id: '',
            status_atendimento_id: '',
            terapeuta_id: '',
            liberado: '',
            executado: '',
            paciente_id: '',
            especialidade_id: '',
            terapia_id: '',
            plano_saude_id: '',
            status_de_assinatura: '',
        };
        const response = await api.post('api/atendimento/relatorio/filtrado', carregaFiltros);
        const data = response?.data;
        setAtendimentosOriginal(data);
        filtrar(data);
        setloading(false);
        setDataAlterado(false);
    }, [dataInicio, dataFim, filtrar]);
    //#endregion
    //#region HANDLES
    /**
     * Função assíncrona que lida com o clique no botão de consulta.
     * Verifica se os parâmetros de data foram fornecidos e, se não, exibe um alerta ao usuário.
     * Se o usuário confirmar a consulta sem parâmetros de data, verifica se há alterações não salvas.
     * Se o usuário confirmar a consulta e não houver alterações não salvas, fecha o modal e carrega os atendimentos.
     *
     * @async
     * @function handleClickConsultar
     * @returns {Promise<void>}
     */
    const handleClickConsultar = useCallback(async () => {
        let passa = true;
        if (!dataInicio && !dataFim) {
            await Swal.fire({
                title: 'Tem certeza?',
                icon: 'warning',
                html: 'Nenhum parâmetro de data foi adicionado à consulta, o que significa, que todos os atendimentos seram consultados. Isso pode demorar um pouco...',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Consutlar mesmo assim',
                cancelButtonText: 'Retornar e adicionar parâmetros'
            }).then(async (response) => {
                if (pendenteDeSave && response.isConfirmed) {
                    passa = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
                } else {
                    passa = response.isConfirmed;
                }
            });
        }
        if (passa) {
            setModalInicialIsOpen(false);
            carregarAtendimentos();
        }
    }, [dataInicio, dataFim, pendenteDeSave]);
    /**
     * Função para salvar as alterações nos atendimentos financeiros.
     * Define o estado de salvando como verdadeiro e faz uma requisição PUT para atualizar os atendimentos alterados.
     * Em caso de sucesso, exibe uma mensagem de sucesso, recarrega os atendimentos, define o estado de pendente de salvar como falso e define o estado de salvando como falso.
     * Em caso de erro, define o estado de salvando como falso, exibe uma mensagem de erro e loga o erro no console.
     */
    const handleSalva = useCallback(() => {
        setSalvando(true);
        api.put('api/atendimento/atualiza/financeiro', atendimentosAlterados)
            .then((response) => {
                if (response.status === 200) {
                    snackbar.displayMessage('Atendimentos atualziados com sucesso!', 'success');
                    carregarAtendimentos();
                    setPendenteDeSave(false);
                    setSalvando(false);
                }
            }).catch((error) => {
                setSalvando(false);
                console.error(error);
                snackbar.displayMessage('Não foi possível atualizar os atendimentos.', 'error');
            });
    }, [atendimentosAlterados, carregarAtendimentos, snackbar]);
    /**
     * Manipula o evento de hover para elementos DOM com base no modo fornecido.
     *
     * @param {string} modo - O modo que determina o estilo a ser aplicado. Pode ser 'consultar' ou 'filtrar'.
     * @param {boolean} [enter=true] - Indica se o evento é de entrada (true) ou saída (false) do hover.
     */
    const handleHover = (modo, enter = true) => {
        const doms = document.getElementsByName(`parametros-${modo}`);
        const borda = modo === 'consultar' ? 'ffc500' : modo === 'filtrar' ? '00d1ff' : '00d1ff';
        const fundo = modo === 'consultar' ? 'fff5d1' : modo === 'filtrar' ? 'd3f7ff' : 'd3f7ff';
        if (enter) {
            doms.forEach((dom) => {
                dom.style.borderColor = `#${borda}`;
                dom.style.backgroundColor = `#${fundo}`;
            });
        } else {
            doms.forEach((dom) => {
                dom.style.borderColor = '#dee2e6';
                dom.style.backgroundColor = 'transparent';
            });
        }
    };
    /**
     * Manipula as mudanças nos inputs da tabela.
     * 
     * @function
     * @param {Object} event - O evento de mudança do input.
     * @param {Object} event.target - O alvo do evento.
     * @param {string} event.target.id - O ID do alvo do evento, no formato 'key=id'.
     * @param {boolean|string} event.target.checked - O valor booleano do checkbox, se o key for 'ok_financeiro'.
     * @param {string} event.target.value - O valor do input, se o key não for 'ok_financeiro'.
     * 
     * @throws {Error} - Lança um erro se ocorrer algum problema durante a execução.
     */
    const handleChangeTableInputs = useCallback((event) => {
        try {
            //variaveis
            const splitedId = event.target.id.split('=');
            const key = String(splitedId[0]);
            const id = Number(splitedId[1]);
            const value = key === 'ok_financeiro' ? Boolean(event.target.checked) : String(event.target.value);
            //Atualiza os Atendimentos
            let listaAF = atendimentosAlterados;
            const afIndex = listaAF.findIndex(find => find.id === id);
            if (afIndex !== -1) {
                listaAF[afIndex] = {
                    ...listaAF[afIndex],
                    [key]: value
                };
            } else {
                const newFinded = {
                    id: id,
                    [key]: value
                };
                listaAF.push(newFinded);
            }

            //returns
            setAtendimentosAlterados(listaAF);
            setPendenteDeSave(listaAF.length > 0);
        } catch (error) {
            console.error(error);
        }
    }, [atendimentosAlterados]);
    /**
     * Limpa todos os filtros de pesquisa, redefinindo os estados relacionados aos filtros para seus valores iniciais.
     * 
     * @function
     * @name handleLimpaFiltro
     */
    const handleLimpaFiltro = () => {
        unstable_batchedUpdates(() => {
            setTipoAtendimentoId('');
            setStatusAtendimentoId('');
            setTerapeutaId('');
            setLiberado('');
            setExecutado('');
            setPeriodo('');
            setDiaSemana('');
            setEspecialidadeId('');
            setTerapiaId('');
            setPacienteId('');
            setStatusDeAssinatura(null);
            setPlanoSaudeId('');
        });
    };
    /**
     * Limpa os parâmetros de data e os arrays de atendimentos.
     *
     * @param {boolean} [limpa_parametro=true] - Indica se os parâmetros de data devem ser limpos.
     */
    const handleLimpaParametros = (limpa_parametro = true) => {
        if (limpa_parametro) {
            setDataInicio('');
            setDataFim('');
        }
        setAtendimentosOriginal([]);
        setAtendimentos([]);
    };
    /**
     * Manipula a mudança de filtros de acordo com o evento recebido.
     * Atualiza o estado correspondente com o valor selecionado e limpa parâmetros quando necessário.
     *
     * @param {Object} e - O evento de mudança.
     * @param {Object} e.target - O alvo do evento.
     * @param {string} e.target.id - O id do alvo do evento.
     * @param {string} e.target.name - O nome do alvo do evento.
     * @param {string} e.target.value - O valor do alvo do evento.
     */
    const handleChangeFilters = useCallback((e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                handleLimpaParametros(false);
                break;
            case 'data_fim':
                setDataFim(value);
                handleLimpaParametros(false);
                break;
            case 'tipo_atendimento_id':
                setTipoAtendimentoId(memotipoAtendimentosOptions.find(option => option.value === value));
                break;
            case 'status_atendimento_id':
                setStatusAtendimentoId(memostatusAtendimentosOptions.find(option => option.value === value));
                break;
            case 'terapeuta_id':
                setTerapeutaId(memoterapeutasOptions.find(option => option.value === value));
                break;
            case 'liberado':
                setLiberado(statusLiberacaoOptions.find(option => option.value === value));
                break;
            case 'executado':
                setExecutado(statusExecucao.find(option => option.value === value));
                break;
            case 'periodo':
                setPeriodo(periodos.find(option => option.value === value));
                break;
            case 'dia_semana':
                setDiaSemana(diasDaSemana.find(option => option.value === value));
                break;
            case 'especialidade_id':
                setEspecialidadeId(memoespecialidadesOptions.find(option => option.value === value));
                break;
            case 'terapia_id':
                setTerapiaId(memoterapiasOptions.find(option => option.value === value));
                break;
            case 'plano_saude_id':
                setPlanoSaudeId(memoplanoSaudeOptions.find(option => option.value === value));
                break;
            case 'paciente_id':
                setPacienteId(memopacienteOptions.find(option => option.value === value));
                break;
            case 'status_de_assinatura':
                setStatusDeAssinatura(statusDeAssinaturaOptions.find(option => option.value === value));
                break;
            default:
                break;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, [memotipoAtendimentosOptions, memostatusAtendimentosOptions, memoterapeutasOptions, memoespecialidadesOptions, memoterapiasOptions, memoplanoSaudeOptions, memopacienteOptions]);
    /**
     * Manipula a abertura do modal de comentários com os dados do atendimento.
     *
     * @param {Object} atendimento - Objeto contendo os dados do atendimento.
     * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
     * @param {number} atendimento.paciente_id - ID do paciente.
     * @param {string} atendimento.paciente_identificador - Identificador do paciente.
     * @param {string} atendimento.paciente_nome - Nome do paciente.
     * @param {string} atendimento.terapia_nome - Nome da terapia.
     * @param {string} atendimento.especialidade_nome - Nome da especialidade.
     * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
     * @param {Object} atendimento.terapia - Objeto contendo informações da terapia.
     * @param {string} atendimento.terapia.terapia - Nome da terapia.
     * @param {Object} atendimento.especialidade - Objeto contendo informações da especialidade.
     * @param {string} atendimento.especialidade.especialidade - Nome da especialidade.
     * @param {string} atendimento.sala - Sala do atendimento.
     * @param {Object} atendimento.estacao - Objeto contendo informações da estação.
     * @param {string} atendimento.estacao.estacao - Nome da estação.
     * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
     * @param {Object} atendimento.tipo_atendimento - Objeto contendo informações do tipo de atendimento.
     * @param {string} atendimento.tipo_atendimento.tipo_atendimento - Tipo de atendimento.
     * @param {number} atendimento.id - ID do atendimento.
     * @param {string} atendimento.sugestao_terapeuta_name - Nome da sugestão do terapeuta.
     * @param {string} atendimento.sugestao_recepcao_name - Nome da sugestão da recepção.
     * @param {number} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
     * @param {number} atendimento.sugestao_recepcao - Sugestão da recepção.
     * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
     * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
     * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
     * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
     * @param {string} atendimento.comentario - Comentário do atendimento.
     * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
     * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
     * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
     */
    const handleVisualizaAtendimento = useCallback((atendimento) => {
        let dados = [];
        dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
        dados['paciente'] = `(${atendimento.paciente_id}) ${atendimento.paciente_identificador}`;
        dados['paciente_nome'] = `${atendimento.paciente_nome}`;
        dados['paciente_id'] = atendimento.paciente_id;
        dados['terapia'] = atendimento.terapia_nome;
        dados['especialidade'] = atendimento.especialidade_nome;
        dados['plano_saude'] = atendimento.nome_reduzido;
        dados['terapia'] = atendimento.terapia.terapia;
        dados['especialidade'] = atendimento.especialidade.especialidade;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao.estacao;
        dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['modalidade'] = atendimento.modalidade.modalidade;
        dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['id'] = atendimento.id;
        dados['terapeuta_status'] = atendimento.sugestao_terapeuta_name;
        dados['recepcao_status'] = atendimento.sugestao_recepcao_name;
        const status_recep = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_terapeuta);
        dados['terapeuta_status_nome'] = status_recep === undefined ? '-' : status_recep.label;
        const status_terap = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_recepcao);
        dados['recepcao_status_nome'] = status_terap === undefined ? '-' : status_terap.label;
        dados['liberado'] = atendimento.liberado;
        dados['checkin'] = atendimento.checkin;
        dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
        dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
        dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
        dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
        dados['comentario'] = atendimento.comentario;
        dados['executado'] = atendimento.executado;
        dados['codigo_1'] = atendimento.codigo_1;
        dados['codigo_2'] = atendimento.codigo_2;
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    }, []);
    //#endregion
    //#region COLUNAS DA TABELA
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Data Hora Atendimento',
                accessor: 'data_atendimento_inicial',
                Cell: ({ cell }) => {
                    const horario = cell.row.original.horarios_atendimento.horario;
                    const sliced = horario.slice(0, -3);
                    return (
                        <span>
                            {moment(cell.row.original.data_atendimento_inicial).format('DD/MM/YYYY') || 'vazio'}
                            <br></br>
                            {sliced || 'vazio'}
                        </span>
                    );
                }
            },
            {
                Header: 'Paciente',
                accessor: 'paciente_nome',
                Cell: ({ cell }) => (<div className="text-nowrap">{cell.value}</div>),
            },
            {
                Header: 'Períodos',
                accessor: 'paciente.periodos',
                Cell: ({ cell }) => {
                    return (
                        <ul className="p-0 m-0 text-left text-nowrap w-100" style={{ listStyle: 'none' }}>
                            {cell.value.map((map, index) => {
                                return <li key={`li:${index}|ul:${cell.row.original.id}`} className='text-nowrap'> {map.dia_semana}: {map.periodo} </li>
                            })}
                        </ul>
                    );
                }
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta_nome',
                Cell: ({ cell }) => (<div className="w-300px">{cell.value}</div>),
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_atendimento.tipo_atendimento',
            },
            {
                Header: <span>
                    <span> Terapia </span><br></br>
                    <span> Especialidade </span><br></br>
                    <span> Plano </span>
                </span>,
                accessor: 'terapia.terapia',
                Cell: ({ cell }) => (
                    <div className="w-150px font-12px">
                        {cell?.row?.original?.terapia.terapia}<br></br>
                        {cell?.row?.original?.especialidade.especialidade}<br></br>
                        {cell?.row?.original?.nome_reduzido}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'sugestao_recepcao_name',
            },
            {
                Header: 'Liberação',
                accessor: 'liberado',
                Cell: ({ cell }) => (statusLiberacaoOptions?.find((find) => find?.value === cell?.value)?.label || 'vazio')
            },
            {
                Header: 'Assinado',
                Cell: ({ cell }) => (statusDeAssinaturaOptions?.find((find) => find?.value === cell?.value)?.label || 'vazio')
            },
            {
                Header: 'Valor (R$)',
                accessor: 'terapia_paciente.valor',
                Cell: ({ cell }) => {
                    if (cell.row.original.plano_saude_id === 2) {
                        return <div className="m-0 p-0">
                            {cell.value}
                        </div>;
                    } else {
                        return <div className="m-0 p-0">
                            {cell.row.original.valor_terapia}
                        </div>;
                    }
                }
            },
            {
                Header: 'OK Financeiro',
                accessor: 'ok_financeiro',
                Cell: ({ cell }) => {
                    return (
                        <div className="text-center d-flex justify-content-center align-items-center">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch"
                                    id={`ok_financeiro=${cell.row.original.id}`} defaultChecked={cell?.value}
                                    onChange={(e) => handleChangeTableInputs(e)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                Header: 'Execução',
                accessor: 'executado',
                Cell: ({ cell }) => {
                    if (cell.row.original.nome_reduzido.toUpperCase() === 'PARTICULAR') {
                        return <div className='w-200px'>
                            <Select id={`executado=${cell.row.original.id}`}
                                name={'execucao[]'}
                                options={statusExecucaoParticular}
                                onChange={(e) => handleChangeTableInputs({ target: { id: `executado=${cell.row.original.id}`, name: 'execucao[]', value: e.value } })}
                                defaultValue={statusExecucaoParticular?.find((find) => find?.value === cell?.value)}
                            />
                        </div>
                    } else {
                        return (statusExecucao?.find((find) => find?.value === cell?.value)?.label || 'vazio');
                    }
                }
            },
            {
                Header: 'Nº NF',
                accessor: 'numero_nf',
                Cell: ({ cell }) => {
                    return <div className="m-0 p-0 w-200px">
                        <input className="form-control m-0" type="text" placeholder="Digite o numero da NF"
                            id={`numero_nf=${cell.row.original.id}`} defaultValue={cell.value}
                            onBlur={(e) => handleChangeTableInputs(e)} />
                    </div>;
                }
            },
            {
                Header: 'Detalhes',
                accessor: 'comentario',
                Cell: ({ cell }) => {
                    return <button className="btn-icone hl-laranja" onClick={() => handleVisualizaAtendimento(cell.row.original)}>
                        <BsEye size={22} />
                    </button>;
                }
            },
        ], [statusDeAssinaturaOptions, statusExecucao, statusLiberacaoOptions]);
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        if (!pendenteDeSave) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [pendenteDeSave, navigator]);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (pendenteDeSave) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pendenteDeSave]);
    useEffect(() => {//FILTER
        if (!filtroAlterado) {
            setFiltroAlterado(true);
        }
    }, [filters]);
    useEffect(() => {//FILTER
        if (!dataAlterado) {
            setDataAlterado(true);
        }
    }, [dataInicio, dataFim]);
    useEffect(() => {//ON LOAD
        fetchTipoAtendimentosData();
        fetchStatusAtendimentosData();
        fetchTerapeutasData();
        fetchEspecialidades();
        fetchTerapias();
        fetchPlanoSaude();
        fetchPacienteOptionsData();
    }, []);
    //#endregion
    //#region HTML
    return (
        <>
            <div className="row p-0 m-0">
                <h1 className="font-30px font-bold font-roxo text-center w-100 p-0 m-0">{title}</h1>
                <Form id="div-filtro" className='col-12 row flex-wrap p-0 m-0'>
                    <fieldset id="fieldset-periodo" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-6">
                        <legend className="mb-0">Período</legend>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12'>
                                <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                            </div>
                            <div className='col-lg-6 col-sm-12 d-flex'>
                                <div className="w-90per">
                                    <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                                </div>
                                <div className="mt-1">
                                    <button type={'button'} className="btn-icone mt-4" onClick={handleLimpaParametros}><AiOutlineClear className="icone" size={30} /></button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-terapeuta" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-3">
                        <legend className="mb-0">Terapeuta</legend>
                        <div className='row'>
                            <div className='col-12'>
                                <Select id="terapeuta_id" name="terapeuta_id" label="Terapeuta:" title="Terapeuta:" options={memoterapeutasOptions} isClearable={true} isMulti={true} value={terapeutaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapeuta_id', name: 'terapeuta_id', value: e } })} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-paciente" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-3">
                        <legend className="mb-0">Paciente</legend>
                        <div className='row'>
                            <div className='col-12'>
                                <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={memopacienteOptions} isClearable={true} isMulti={true} value={pacienteId} onChange={(e) => handleChangeFilters({ target: { id: 'paciente_id', name: 'paciente_id', value: e } })} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-atendimento" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-12">
                        <legend className="mb-0">Atendimento</legend>
                        <div className='row'>
                            <div className='col-lg-2 col-sm-12'>
                                <Select id="tipo_atendimento_id" name="tipo_atendimento_id" label="Tipo de Atendimento:" title="Tipo:" isMulti={true} options={memotipoAtendimentosOptions} value={tipoAtendimentoId} onChange={(e) => handleChangeFilters({ target: { id: 'tipo_atendimento_id', name: 'tipo_atendimento_id', value: e } })} />
                            </div>
                            <div className='col-lg-2 col-sm-12'>
                                <Select id="status_atendimento_id" name="status_atendimento_id" label="Status de Atendimento:" title="Status de Atendimento:" isMulti={true} options={memostatusAtendimentosOptions} isClearable={true} value={statusAtendimentoId} onChange={(e) => handleChangeFilters({ target: { id: 'status_atendimento_id', name: 'status_atendimento_id', value: e } })} />
                            </div>
                            <div className='col-lg-1 col-sm-12'>
                                <Select id="liberado" name="liberado" label="Status de Liberação:" title="Status de Liberação:" options={statusLiberacaoOptions} isClearable={true} value={liberado} onChange={(e) => handleChangeFilters({ target: { id: 'liberado', name: 'liberado', value: e } })} />
                            </div>
                            <div className='col-lg-3 col-sm-12'>
                                <Select id="executado" name="executado" label="Status de Execução:" title="Status de Execução:" options={statusExecucao} isClearable={true} value={executado} onChange={(e) => handleChangeFilters({ target: { id: 'executado', name: 'executado', value: e } })} />
                            </div>
                            <div className='col-lg-2 col-sm-12'>
                                <Select id="dia_semana" name="dia_semana" label="Dia da Semana:" options={diasDaSemana} isClearable={true} value={diaSemana} onChange={(e) => { handleChangeFilters({ target: { id: 'dia_semana', name: 'dia_semana', value: e } }) }} isMulti={true} />
                            </div>
                            <div className='col-lg-2 col-sm-12'>
                                <Select id="periodo" name="periodo" title="Período:" options={periodos} isClearable={true} value={periodo} onChange={(e) => { handleChangeFilters({ target: { id: 'periodo', name: 'periodo', value: e } }) }} isMulti={true} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset id="fieldset-outros" name="parametros-filtrar" className="ns-fieldset mt-4 col-12">
                        <legend className="mb-0">Outros</legend>
                        <div className='row mt-0'>
                            <div className='col-lg-3 col-sm-12'>
                                <Select id="especialidade_id" name="especialidade_id" label="Especialidade:" title="Especialidade:" isMulti={true} options={memoespecialidadesOptions} isClearable={true} value={especialidadeId} onChange={(e) => handleChangeFilters({ target: { id: 'especialidade_id', name: 'especialidade_id', value: e } })} />
                            </div>
                            <div className='col-lg-3 col-sm-12'>
                                <Select id="terapia_id" name="terapia_id" label="Terapia:" title="Terapia:" isMulti={true} options={memoterapiasOptions} isClearable={true} value={terapiaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapia_id', name: 'terapia_id', value: e } })} />
                            </div>
                            <div className='col-lg-3 col-sm-12'>
                                <Select id="plano_saude_id" name="plano_saude_id" label="Plano Saude:" title="Plano Saude:" isMulti={true} options={memoplanoSaudeOptions} isClearable={true} value={planoSaudeId} onChange={(e) => handleChangeFilters({ target: { id: 'plano_saude_id', name: 'plano_saude_id', value: e } })} />
                            </div>
                            <div className='col-lg-3 col-sm-12'>
                                <Select id="status_de_assinatura" name="status_de_assinatura" label="Status de Assinatura:" title="Status de Assinatura:" options={statusDeAssinaturaOptions} isClearable={true} value={statusDeAssinatura} onChange={(e) => handleChangeFilters({ target: { id: 'status_de_assinatura', name: 'status_de_assinatura', value: e } })} />
                            </div>
                        </div>
                    </fieldset>
                    <div className="col-12 mt-2 d-flex flex-row justify-content-between">
                        <div className='d-flex justify-content-start w-400px'>
                            <Button type="button" className={'w-fit-content-i me-2 rodando-hover'} onMouseEnter={() => handleHover('consultar', true)} onMouseLeave={() => handleHover('consultar', false)} onClick={() => carregarAtendimentos()}>
                                <FiRefreshCcw className='icone me-2' size={22} /> Atualizar
                            </Button>
                            <Button className={`w-fit-content-i searching${dataAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onMouseEnter={() => handleHover('consultar', true)} onMouseLeave={() => handleHover('consultar', false)} onClick={() => { handleClickConsultar(); }}>
                                <BsSearch size={22} className="icone me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                        {(loading || salvando) &&
                            <div className='p-0 m-0 mx-auto'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                    <BsSearch className='icone manual-searching' size={30} />
                                </div>
                            </div>
                        }
                        <div className='d-flex justify-content-end w-400px'>
                            <Button className={`w-fit-content-i${filtroAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onMouseEnter={() => handleHover('filtrar', true)} onMouseLeave={() => handleHover('filtrar', false)} onClick={() => filtrar()}>
                                <BsFilter className='icone me-2' size={30} /> Filtrar
                            </Button>
                            <Button type={'button'} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} onMouseEnter={() => handleHover('filtrar', true)} onMouseLeave={() => handleHover('filtrar', false)} onClick={handleLimpaFiltro} >
                                <FaBroom size={30} type="button" className="icone me-2" /> Limpar Filtros
                            </Button>
                        </div>
                    </div>
                </Form >
                <div className="col-12 p-0 m-0 d-flex justify-content-between mt-4">
                    <b></b>
                    <b className="font-roxo font-20px">Listagem de Atendimentos</b>
                    <Button className={`w-fit-content-i ${salvando ? 'cursor-block' : 'cursor-pointer'}`} type={'button'} onClick={() => handleSalva()} disabled={salvando}>
                        <FaSave className={`me-2 ${pendenteDeSave ? 'font-laranja' : ''}`} size={22} /> Salvar Alterações
                    </Button>
                </div>
                <Form className="col-12 p-0 m-0 overflow-auto">
                    <TableDefaultPagination source={memoAtendimentos} columns={columns} tableTitle={title} prefix_id={'pendencias-pagamento'} />
                </Form>
                {loading && <Carregamento />}
                <TutorialBadge
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Seja bem vindo à tela de pendências de pagamento.</span>
                                </div>
                            },
                            { title: 'Básicos', text: 'O básico que você precisa saber sobre essa tela, é que todos os atendimentos são consultados na base de dados ao abrir a tela.' },
                            { title: 'Filtros', selector: '#div-filtro', text: 'Essa é a area do filtro. Aqui você pode filtrar os atendimentos já consultados.' },
                            { title: '', selector: '#', text: '' },
                            { title: '', selector: '#', text: '' },
                            { title: '', selector: '#', text: '' },
                            { title: 'Finalização', text: <span>É basicamente isso, se você ainda tiver dúvidas, pode apertar novamente no botão de tutorial no canto inferior da tela, ou se preferir, <a href={`dashboard/${user.tipo_user}/documentacao`}>acesse nossa documentação</a>!</span> },
                        ]
                    }
                    hasTopicos={true}
                />
                <ComentariosModal ref={modalComentariosRef} getAgendaDia={carregarAtendimentos}></ComentariosModal>
            </div >
            <ReactModal id={'modal-inicial'} style={customStyles} isOpen={modalInicialIsOpen}
                onRequestClose={() => { }} afterOpenModal={() => { }} appElement={document.getElementById('root')} transparent
                shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}
            >
                <div className="row">
                    <h4 className="col-12 font-roxo text-center">Parâmetros Iniciais</h4>
                    <p className="col-12 text-justify">Selecionando duas datas, o todos os atendimentos com datas entre esse período sera retornado. Caso apenas uma das datas sejam selecionadas apenas os atendimentos daquela data em específico será retornado.</p>
                    <Form className="col-12 row">
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                        </div>
                        <div className="col-12 text-right d-flex justify-content-end mt-4">
                            <Button className="w-fit-content-i searching mt-4" type={'button'} onClick={() => handleClickConsultar()}>
                                <BsSearch size={22} className="me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                    </Form>
                </div>
            </ReactModal>
        </>
    );
    //#endregion
};

export default PendenciasPagamento;