import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../context/snackbar';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxHeight: '70vh',
        width: '40vw',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const ModalMedicamentosTerapeuta = (props, ref) => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [alergiaMedicamento, setAlergiaMedicamento] = useState(null);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const alergiaIsTrue = {
        width: '50%',
        marginRight: 10,
    };

    const alergiaISFalse = {
        width: '100%',
    };

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);


    const alergiasList = [
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
    ];

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const getDefaultAlergia = (alergia) => {
        if (!props.modalData) return;
        const alergiaPaciente = alergia.filter(data => {
            return data.value === props.modalData.medicamentos_alergia;
        })[0];
        setAlergiaMedicamento(alergiaPaciente);
    };

    const editNewPacienteEmergencias = (medicamento, dados) => {
        let medicamentosLocal = [...props.medicamentos];
        const index = medicamentosLocal.indexOf(medicamento);
        medicamentosLocal.splice(index, 1, dados);
        props.setMedicamentos(medicamentosLocal);
    };

    async function handleSubmit(data) {
        try {
            if (data.medicamentos === '' || data.medicamentos_horarios === '' || data.medicamentos_alergia === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                const dados = {
                    medicamentos: data.medicamentos,
                    medicamentos_horarios: data.medicamentos_horarios,
                    medicamentos_alergia: alergiaMedicamento.value,
                    medicamentos_alergias: data.medicamentos_alergias,
                };
                if (props.modalData === null) {
                    let medicamentosLocal = [...props.medicamentos];
                    if (props.paciente_id !== undefined && props.paciente_id !== null) {
                        dados.paciente_id = props.paciente_id;
                        //MedicamentosPacienteService.store(dados);
                        props.callBack();
                    } else {
                        medicamentosLocal.push(dados);
                    }
                    setIsOpen(false);
                    snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                    props.setMedicamentos(medicamentosLocal);
                } else {
                    let medicamentosLocal = [...props.medicamentos];
                    dados.index_terapia = medicamentosLocal.length;
                    if (props.paciente_id === undefined || props.paciente_id === null) {
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = medicamentosLocal.length;
                        }
                        editNewPacienteEmergencias(props.modalData, dados);
                        setIsOpen(false);
                        snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                    } else {
                        /* MedicamentosPacienteService.update(dados, props.modalData.id).then(() => {
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            setIsOpen(false);
                            props.callBack();
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
                        }) */
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
        } finally {

        }
    }

    useEffect(() => {
        getDefaultAlergia(alergiasList);
    }, [props.modalData]);

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={e => closeModal()}>
                        x
                    </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ color: '#442953', fontWeight: 'bold', textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>
                        {props.modalData === null ? 'Medicamentos do(a) Terapeuta' : 'Alterar Medicamentos do(a) Terapeuta'}
                    </h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '50%', paddingRight: 10 }}>
                                    <Input title="Medicamento(s) em Uso:" name="medicamentos"
                                        defaultValue={props.modalData === null ? '' : props.modalData.medicamentos} />
                                </div>
                                <div style={{ width: '50%', paddingRight: 10 }}>
                                    <Input title="Horario:" name="medicamentos_horarios"
                                        defaultValue={props.modalData === null ? '' : props.modalData.medicamentos_horarios} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={alergiaMedicamento === null ? alergiaISFalse : alergiaMedicamento.value === false ? alergiaISFalse : alergiaIsTrue}>
                                    <Select label="Alergia a algum medicamento?" options={alergiasList} name="medicamentos_alergia"
                                        defaultValue={props.modalData === null ? '' : alergiaMedicamento} onChange={e => setAlergiaMedicamento(e)} />
                                </div>
                                {alergiaMedicamento === null ? '' : alergiaMedicamento.value === false ? '' : <div style={{ width: '50%', paddingRight: 10 }}>
                                    <Input title="Qual(is)?" name="medicamentos_alergias" defaultValue={props.modalData === null ? '' :
                                        props.modalData.medicamentos_alergias} />
                                </div>}
                            </div>
                        </div>
                        <button className='btn-padrao' type="button" onClick={e => functionThatSubmitsForm(e)}>
                            {props.modalData === null ? 'Adicionar' : 'Alterar'}
                        </button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(ModalMedicamentosTerapeuta, null);
