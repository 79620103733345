import React, {useEffect, useMemo} from 'react';
import {AiOutlineDelete, AiOutlineEdit, AiOutlineCloudDownload} from 'react-icons/ai';
import { TableDefault } from '../../../../../components/TableDefault';
import { OpenButtonTable } from '../../styles';

export function TableCursos({ source, terapeuta }) {

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => [
            {
                Header: 'Data:',
                accessor: 'terapium.terapia',
            },
            {
                Header: 'Nome:',
                accessor: 'terapium.terapia.data',
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({cell}) => (
                    (   
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent'}}>
                            <div style={{width: '18%'}}>
                                <button
                                    onClick={{}}
                                    style={OpenButtonTable}>Abrir
                                </button>
                            </div>
                        </div>
                    )
                )
            }
        ],
        [terapeuta]
    );

    return (
       <TableDefault source={source} columns={columns}/>
    );
}