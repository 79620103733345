import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import TerapiaPacienteService from '../../../../../services/TerapiaPacienteService';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { TableDefault } from '../../../../../components/TableDefault';

export function TableTerapias({ source, callBack, setModalData, handleOpen, setTerapias, readOnly = false }) {
    const snackbar = useSnackbar();

    const removeTerapiaPaciente = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (data.paciente_id === null || data.paciente_id === undefined) {
                    let terapiasLocal = [...source];
                    const index = terapiasLocal.indexOf(data);
                    terapiasLocal.splice(index, 1);
                    setTerapias(terapiasLocal);
                    callBack();
                } else {
                    TerapiaPacienteService.destroy(data.id);
                    callBack();
                }
                snackbar.displayMessage('Cadastro removido com sucesso!', 'success')
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    };

    const handleEditTerapia = (data) => {
        setModalData(data);
        handleOpen();
    };

    const columns = useMemo(
        () =>
            readOnly
                ? [
                    {
                        Header: 'Terapia',
                        accessor: 'terapium.terapia',
                    },
                    {
                        Header: 'Plano de Saúde',
                        accessor: 'plano_saude.plano',
                    },
                    {
                        Header: 'Especialidade',
                        accessor: 'especialidade.especialidade',
                    }
                ]
                : [
                    {
                        Header: 'Terapia',
                        accessor: 'terapium.terapia',
                    },
                    {
                        Header: 'Plano de Saúde',
                        accessor: 'plano_saude.plano',
                    },
                    {
                        Header: 'Especialidade',
                        accessor: 'especialidade.especialidade',
                    },
                    {
                        Header: 'Ações',
                        accessor: 'acoes',
                        Cell: ({ cell }) => {
                            if (!readOnly) {
                                return (
                                    <div className="d-flex align-center justify-center m-1">
                                        <AiOutlineEdit className={'icone-padrao'} title={cell.row.original.id} onClick={e => handleEditTerapia(cell.row.original)} />
                                        <AiOutlineDelete className={'icone-padrao'} title={cell.row.original.id} onClick={e => removeTerapiaPaciente(cell.row.original)} />
                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        }
                    }
                ],
        [source]
    );

    return (
        <TableDefault columns={columns} source={source} />
    );
}