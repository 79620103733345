import styled from 'styled-components';
import { css } from '@emotion/react';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;

.table{
    table {
        border: 2px solid forestgreen;
        height: 200px;
        width: '100%'
      }
        
      th {
        border-bottom: 1px solid black;
      }
        
      td {
        text-align: center;
      }
}`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const DivHeaderStyle = {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center', 
    marginBottom: 50,
    marginTop: 10,
    color: '#7340BD'
};

export const TextFormat = {
    fontSize: 25, 
    fontWeight: 'bold',
};

export const DivLabelStyle = {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8, 
    paddingLeft: '10%',
    
    width: '80%',
    height: 30,
};

export const SpanTextFormat = {
    fontSize: '15px',  
    fontWeight: '600', 
    marginTop: 10
};

export const BoxContentStyle = {
    maxWidth: '580px', 
    height: 'auto', 
    borderRadius: '10px',
    boxShadow: '0 0 10px 0px silver',
    justifyContent: 'space-between',
};

export const ButtonAvaliar = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    border: 'none',
    padding: '2px 22px',
    borderRadius: '10px',
};

export const MainDivStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginBottom: 20,
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    height: 58,
    padding: '0px 20px',
    boxShadow: '0 0 10px 0px silver',
};
export const TdStyle = {
    padding: '10px',
    marginRight: '10px',
    background: 'white',
    borderRadius: '10px',
};

export const ThStyle = {
    color: 'black', 
    fontWeight: 'bold',
    textAlign: 'left',
};