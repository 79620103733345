import { HomeIcon } from 'components/Header/styles';
import { ConfigIcon } from 'components/HeaderRecepcao/styles';
import { useEffect, useState } from 'react';
import { AiFillFilter, AiFillHome, AiFillProject, AiOutlineClear, AiOutlineClockCircle, AiOutlineDelete, AiOutlineException, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSetting } from 'react-icons/ai';
import { BsBell, BsBellFill, BsBellSlash, BsBellSlashFill, BsCheck, BsCheck2All, BsCheck2Square, BsCheckAll, BsCheckSquare, BsDownload, BsEye, BsFullscreen, BsListCheck, BsPencil, BsQuestionCircle, BsSearch, BsXSquare } from 'react-icons/bs';
import { CgChart, CgClose } from 'react-icons/cg';
import { FaAngleDoubleDown, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDoubleUp, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaBroom, FaCalendarAlt, FaFileCsv, FaHeartbeat, FaRssSquare, FaSave, FaUser, FaUserAlt, FaUserMd, FaUserTie } from 'react-icons/fa';
import { FiDownload, FiFilter, FiRefreshCcw } from 'react-icons/fi';
import { IoAddCircle, IoAddCircleOutline } from 'react-icons/io5';
import { MdEmail, MdOutlineAssignment, MdOutlineDriveFolderUpload, MdOutlineFeedback, MdQueryStats } from 'react-icons/md';

const useIcons = (outSideIconSize) => {
    //#region VARIAVEIS
    const [iconSize, setIconSize] = useState(20);
    const [iconsList, setIconsList] = useState([]);

    // Array mapeando os ícones
    const iconArray = {
        'adicionar': <IoAddCircleOutline size={iconSize} />,
        'agenda': <FaCalendarAlt size={iconSize} />,
        'analise': <MdQueryStats size={iconSize} />,
        'atendimentos': <AiOutlineClockCircle size={iconSize} />,
        'atualizar': <FiRefreshCcw size={iconSize} />,
        'baixar': <BsDownload size={iconSize} />,
        'baixar1': <FiDownload size={iconSize} />,
        'config': <AiOutlineSetting size={iconSize} />,
        'conferir': <BsCheck size={iconSize} />,
        'deletar': <AiOutlineDelete size={iconSize} />,
        'dupla-seta-angulo-baixo': <FaAngleDoubleDown size={iconSize} />,
        'dupla-seta-angulo-cima': <FaAngleDoubleUp size={iconSize} />,
        'dupla-seta-angulo-direita': <FaAngleDoubleRight size={iconSize} />,
        'dupla-seta-angulo-esquerda': <FaAngleDoubleLeft size={iconSize} />,
        'editar': <BsPencil size={iconSize} />,
        'excluir': <AiOutlineDelete size={iconSize} />,
        'fechar': <CgClose size={iconSize} />,
        'filtrar': <FiFilter size={iconSize} />,
        'filtrado': <AiFillFilter size={iconSize} />,
        'fullscreen': <BsFullscreen size={iconSize} />,
        'funcionario': <FaUserTie size={iconSize} />,
        'graficos': <CgChart size={iconSize} />,
        'guias': <AiOutlineException size={iconSize} />,
        'home': <AiFillHome size={iconSize} />,
        'limpar': <AiOutlineClear size={iconSize} />,
        'limpar-geral': <FaBroom size={iconSize} />,
        'listaespera': <BsListCheck size={iconSize} />,
        'nao-observando': <AiOutlineEyeInvisible size={iconSize} />,
        'notificacao': <BsBellFill size={iconSize} />,
        'observando': <AiOutlineEye size={iconSize} />,
        'paciente': <FaUserAlt size={iconSize} />,
        'pendencias': <MdOutlineDriveFolderUpload size={iconSize} />,
        'perguntar': <BsQuestionCircle size={iconSize} />,
        'pesquisar': <BsSearch size={iconSize} />,
        'plano': <FaHeartbeat size={iconSize} />,
        'recados': <MdEmail size={iconSize} />,
        'relatorio': <FaFileCsv size={iconSize} />,
        'relatorios': <MdOutlineAssignment size={iconSize} />,
        'salvar': <FaSave size={iconSize} />,
        'seta-angulo-baixo': <FaAngleDown size={iconSize} />,
        'seta-angulo-cima': <FaAngleUp size={iconSize} />,
        'seta-angulo-direita': <FaAngleRight size={iconSize} />,
        'seta-angulo-esquerda': <FaAngleLeft size={iconSize} />,
        'seta-baixo': <FaArrowDown size={iconSize} />,
        'seta-cima': <FaArrowUp size={iconSize} />,
        'seta-direita': <FaArrowRight size={iconSize} />,
        'seta-esquerda': <FaArrowLeft size={iconSize} />,
        'sino': <BsBell size={iconSize} />,
        'sino-cheio': <BsBellFill size={iconSize} />,
        'sino-cheio-cortado': <BsBellSlashFill size={iconSize} />,
        'sino-cortado': <BsBellSlash size={iconSize} />,
        'solicitacoes': <MdOutlineFeedback size={iconSize} />,
        'status-diario': <AiFillProject size={iconSize} />,
        'terapeuta': <FaUserMd size={iconSize} />,
        'usuario': <FaUser size={iconSize} />,
        'visualizar': <BsEye size={iconSize} />,
        'seleciona-todos': <BsCheckAll size={iconSize} />,
        'seleciona-todos2': <BsCheck2All size={iconSize} />,
        'seleciona-quadrado': <BsCheck2Square size={iconSize} />,
        'deseleciona-quadrado': <BsXSquare size={iconSize} />,
    };
    //#endregion

    //#region FUNCOES
    // Função para retornar o ícone correspondente baseado no valor
    const getIcon = (icon, no_default = false) => {
        if (no_default) {
            return iconArray[icon];
        } else {
            return iconArray[icon] || <AiFillHome size={iconSize} />;
        }
    };

    // Função para retornar uma lista de ícones com seus valores
    const getIconsList = () => {
        const iconEntries = Object.entries(iconArray).map(([value, label]) => ({
            label,
            value
        }));
        setIconsList(iconEntries);
        return iconEntries;
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        if (outSideIconSize) {
            setIconSize(outSideIconSize);
        }
    }, [outSideIconSize]);

    useEffect(() => {
        getIconsList();
    }, [iconSize]);
    //#endregion

    //#region RETURN
    return { getIcon, getIconsList, iconsList, iconArray };
    //#endregion
};

export default useIcons;