import React, { useState, useEffect, useRef, useCallback } from 'react';

/**
 * Animates a draggable element to a specified position.
 *
 * @param {object} draggableRef - A reference to the draggable element.
 * @param {number} startX - The starting X position.
 * @param {number} startY - The starting Y position.
 * @param {number} endX - The ending X position.
 * @param {number} endY - The ending Y position.
 * @param {number} [duration=50] - The duration of the animation in milliseconds.
 * @param {function} [onEndAnimation=() => {}] - Callback function to be called at the end of the animation.
 */
const animateTo = (draggableRef, startX, startY, endX, endY, duration = 50, onEndAnimation = () => { }) => {
    const startTime = performance.now();

    const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        const currentX = startX + (endX - startX) * progress;
        const currentY = startY + (endY - startY) * progress;

        draggableRef.current.style.transform = `translate(${currentX}px, ${currentY}px)`;

        if (progress < 1) {
            requestAnimationFrame(animate);
        } else {
            onEndAnimation();
        }
    };

    requestAnimationFrame(animate);
};
/**
 * Snaps the given coordinates to a grid.
 *
 * @param {number} x - The X coordinate.
 * @param {number} y - The Y coordinate.
 * @param {number|object} gridSize - The size of the grid, or an object containing grid dimensions.
 * @returns {object} - The snapped X and Y coordinates.
 */
/* const snapToGrid = (x, y, gridSize) => {
    let size;
    if (typeof gridSize === 'object') {
        size = gridSize.height;
    } else {
        size = gridSize;
    }
    const snappedX = Math.round(x / size) * size;
    const snappedY = Math.round(y / size) * size;
    return { x: snappedX, y: snappedY };
}; */
/**
 * Centers a draggable element within a specified container.
 *
 * @param {HTMLElement} vazio - The container element to center within.
 * @param {object} draggableRef - A reference to the draggable element.
 * @returns {object} - The X and Y coordinates for centering the element.
 */
const centerInVasil = (vazio, draggableRef) => {
    const vazioRect = vazio.getBoundingClientRect();
    const draggableRect = draggableRef.current.getBoundingClientRect();

    const centerX = vazioRect.left + (vazioRect.width / 2) - (draggableRect.width / 2);
    const centerY = vazioRect.top + (vazioRect.height / 2) - (draggableRect.height / 2);

    return { x: centerX, y: centerY };
};
/**
 * Clones the content of a draggable element into a target DOM element.
 *
 * @param {object} draggableRef - A reference to the draggable element.
 * @param {HTMLElement} targetDom - The target DOM element where the content will be cloned.
 */
/* const cloneDraggable = (draggableRef, targetDom) => {
    const clone = draggableRef.current.innerHTML;
    targetDom.innerHTML = clone;
}; */

/**
 * DraggableItem component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content of the draggable item.
 * @param {Object} [props.gridSize={ height: 100, width: 100 }] - The size of the grid for the draggable item.
 * @param {any} [props.controlledState=null] - The controlled state of the draggable item.
 * @param {Object} [props.gridPos={ row: 0, col: 0 }] - The position of the draggable item in the grid.
 * @param {Object} [props.dragZoneStyle={}] - The style of the drag zone.
 * @param {Object} [props.draggableStyle={}] - The style of the draggable item.
 * @param {Object} [props.updateContent={}] - The content to update.
 * @param {Object} [props.hoveredStyle={ backgroundColor: '#ddeeff', border: '2px solid blue' }] - The style when the draggable item is hovered.
 * @param {Object} [props.draggableDragginStyle={ backgroundColor: '#ff0000', borderRadius: '10px' }] - The style when the draggable item is being dragged.
 * @param {boolean} [props.disabled=false] - Whether the draggable item is disabled or not.
 * @param {string} [props.blankContent=''] - The blank content of the draggable item.
 * @param {Function} [props.onDrop=async () => { return true; }] - The function to handle the drop action.
 * @returns {JSX.Element} The rendered DraggableItem component.
 */
const DraggableItem = React.memo(({
    children,
    gridSize = {
        height: 100,
        width: 100
    },
    controlledState = null,
    gridPos = { row: 0, col: 0 },
    dragZoneStyle = {},
    draggableStyle = {},
    updateContent = {},
    hoveredStyle = {
        backgroundColor: '#ddeeff',
        border: '2px solid blue'
    },
    draggableDragginStyle = {
        backgroundColor: '#ff0000',
        borderRadius: '10px',
    },
    disabled = false,
    blankContent = '',
    onDrop = async () => { return true; },
}) => {
    //#region VARIAVEIS
    const [isDragging, setIsDragging] = useState(null);
    const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });
    const [draggableState, setDraggableState] = useState('vazio');
    const [hoveringOverVasil, setHoveringOverVasil] = useState(false);
    const [defaultDragZoneStyle, setDefaultDragZoneStyle] = useState({});
    const [defaultDraggableStyle, setDefaultDraggableStyle] = useState({});
    //const [defaultDraggableDragginStyle, setDefaultDraggableDragginStyle] = useState({});
    const [defaultHoveredStyle, setDefaultHoveredStyle] = useState({});

    const dragzoneRef = useRef(null);
    const draggableRef = useRef(null);
    const animationFrameRef = useRef(null);
    const hoveringDomRef = useRef(null);
    //#endregion

    //#region HANDLES
    /**
     * Handles the mouse down event on the draggable element.
     *
     * @param {object} e - The mouse event object.
     */
    const handleMouseDown = (e) => {
        if (draggableState === 'vazio') return;
        if (!e.ctrlKey) return;

        setIsDragging(true);

        const rect = draggableRef.current.getBoundingClientRect();
        setOriginalPosition({
            x: rect.left,
            y: rect.top,
        });

        draggableRef.current.style.position = 'absolute';
        draggableRef.current.style.willChange = 'transform';
        draggableRef.current.style.width = (gridSize.width) + 'px';
        draggableRef.current.style.height = (gridSize.height) + 'px';
        draggableRef.current.style.zIndex = '400';

        animateTo(draggableRef, 0, 0, e.clientX - rect.left - 50, e.clientY - rect.top - 50);
    };
    /**
     * Handles the mouse move event while dragging.
     *
     * @param {object} e - The mouse event object.
     */
    const handleMouseMove = useCallback((e) => {
        if (isDragging === true) {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            animationFrameRef.current = requestAnimationFrame(() => {
                const x = e.clientX - originalPosition.x - 50;
                const y = e.clientY - originalPosition.y - 50;

                draggableRef.current.style.transform = `translate(${x}px, ${y}px)`;

                const dropZones = document.querySelectorAll('.drop-zone');
                let hoveringOverVasil = false;

                dropZones.forEach((zone) => {
                    const rect = zone.getBoundingClientRect();
                    const childZone = zone.firstChild;
                    if (zone.classList.contains('vazio') && e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom) {
                        hoveringDomRef.current = childZone;
                        hoveringOverVasil = true;
                        for (const [chave, valor] of Object.entries(defaultHoveredStyle)) {
                            childZone.style[chave] = valor; // Corrigido: usando colchetes para acessar a propriedade
                        }
                    } else {
                        for (const [chave, valor] of Object.entries(defaultDraggableStyle)) {
                            childZone.style[chave] = valor; // Corrigido: usando colchetes para acessar a propriedade
                        }
                    }
                });
                if (!hoveringOverVasil) {
                    hoveringDomRef.current = null;
                }
                setHoveringOverVasil(hoveringOverVasil);
            });
        }
    }, [isDragging, originalPosition.x, originalPosition.y]);
    /**
     * Handles the mouse up event to finish dragging.
     */
    const handleMouseUp = () => {
        if (isDragging === false) return;
        setIsDragging(false);

        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    //#endregion

    //#region FUNCOES
    /**
     * Handles the drop action for the draggable element.
     *
     * @returns {Promise<void>}
     */
    const dropItem = async () => {
        const rect = draggableRef.current.getBoundingClientRect();
        const parentRect = dragzoneRef.current.getBoundingClientRect();

        const startX = rect.left - parentRect.left;
        const startY = rect.top - parentRect.top;

        if (hoveringOverVasil && hoveringDomRef.current) {
            const { x: centerX, y: centerY } = centerInVasil(hoveringDomRef.current, draggableRef);

            animateTo(draggableRef, startX, startY, centerX - originalPosition.x, centerY - originalPosition.y, 150, async () => {
                let array = hoveringDomRef.current.id.split('|');
                const newGridPos = { col: array[0].split('=')[1], row: array[1].split('=')[1] };
                const UpdateContent = updateContent ? updateContent : draggableRef.current.innerHTML;

                await onDrop(gridPos, newGridPos, UpdateContent).then((result) => {
                    if (result) {
                        setDraggableState('vazio');
                        children = blankContent;
                        draggableRef.current.style.zIndex = '300';
                        draggableRef.current.style.transform = '';
                        draggableRef.current.style.position = 'static';
                        draggableRef.current.style.width = `${gridSize.width}px`;
                        draggableRef.current.style.height = `${gridSize.height}px`;
                    } else {
                        animateTo(draggableRef, startX, startY, 0, 0, 150, () => {
                            setOriginalPosition({ x: 0, y: 0 });
                            draggableRef.current.style.zIndex = '300';
                            draggableRef.current.style.transform = '';
                            draggableRef.current.style.position = 'static';
                            draggableRef.current.style.width = `${gridSize.width}px`;
                            draggableRef.current.style.height = `${gridSize.height}px`;
                        });
                    }
                });
            });
        } else {
            animateTo(draggableRef, startX, startY, 0, 0, 150, () => {
                setOriginalPosition({ x: 0, y: 0 });
                draggableRef.current.style.zIndex = '300';
                draggableRef.current.style.transform = '';
                draggableRef.current.style.position = 'static';
                draggableRef.current.style.width = `${gridSize.width}px`;
                draggableRef.current.style.height = `${gridSize.height}px`;
            });
        }
        setIsDragging(null);
    };
    /**
     * Checks the state of the draggable element and updates it accordingly.
     *
     * @param {boolean} test - The condition to test for updating the state.
     */
    const checkDraggableState = (test) => {
        if (test) {
            setDraggableState('ocupado');
        } else {
            setDraggableState('vazio');
        }
    };
    //#endregion

    //#region USE EFFECTS
    /**
     * Effect hook to manage dragging event listeners.
     */
    useEffect(() => {
        if (isDragging === true) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            if (isDragging === false) {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [isDragging, handleMouseMove]);
    /**
     * Effect hook to handle dropping logic when dragging stops.
     */
    useEffect(() => {
        if (isDragging === false) {
            dropItem();
        }
    }, [isDragging]);
    /**
     * Effect hook to check the state of the draggable element based on children prop.
     */
    useEffect(() => {
        if (controlledState === null) {
            checkDraggableState(children);
        }
    }, [children]);
    /**
     * Effect hook to check the state of the draggable element based on controlledState prop.
     */
    useEffect(() => {
        if (controlledState !== null) {
            checkDraggableState(controlledState);
        }
    }, [controlledState]);
    /**
     * Effect hook to initialize default styles based on props.
     */
    useEffect(() => {
        setDefaultDragZoneStyle({
            ...dragZoneStyle,
            minWidth: `${gridSize.width}px`,
            minHeight: `${gridSize.height}px`,
            maxWidth: `${gridSize.width}px`,
            maxHeight: `${gridSize.height}px`,
            padding: 0,
        });
        setDefaultDraggableStyle({
            ...draggableStyle,
            width: `${gridSize.width}px`,
            height: `${gridSize.height}px`,
            zIndex: '300',
        });
        /* setDefaultDraggableDragginStyle({
            ...draggableDragginStyle,
            width: `${gridSize.width}px`,
            height: `${gridSize.height}px`,
            zIndex: '300',
        }); */
        setDefaultHoveredStyle({
            ...hoveredStyle
        });
    }, [dragZoneStyle, draggableStyle, draggableDragginStyle, hoveredStyle]);
    //#endregion

    return (
        <div ref={dragzoneRef}
            className={`drop-zone unselectable ${draggableState}`}
            style={defaultDragZoneStyle}>
            <div ref={draggableRef}
                id={`col=${gridPos.col}|row=${gridPos.row}`}
                className='unselectable'
                onMouseDown={!disabled && handleMouseDown}
                style={{
                    ...defaultDraggableStyle,
                    cursor: draggableState === 'ocupado'
                        ? isDragging === true
                            ? 'grabbing'
                            : 'grab'
                        : 'pointer',
                }}>
                {children}
            </div>
        </div>
    );
});

DraggableItem.displayName = 'Draggable Item';

export default DraggableItem;