import React from 'react';
import { BotaoAcaoStyle, InputStyle, MainDivStyle, OptionsStyle, TextFormat } from './styles';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const TablePagination = ({
    prefix_id = 'default',
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    pageIndex,
    pageCount,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageSize,
    linhas,
    exportToExcel
}) => {
    return (
        <div id={`${prefix_id}-tfoot-inside`} style={MainDivStyle}>
            <div id={`${prefix_id}-tfoot-page-details`} className="w-400px-i d-flex justify-content-start align-items-center">
                <button id={`${prefix_id}-tfoot-export`} type={'button'} className='btn-outline' onClick={exportToExcel} style={{ marginBottom: '10px' }}>
                    <RiFileExcel2Fill className='font-roxo' size={26} />
                </button>
                <span style={TextFormat}>
                    Página:{' '}
                    <strong style={TextFormat}>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
                <span className="ms-2" style={TextFormat}>
                    Ir para página:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={InputStyle} />
                </span>
            </div>
            <div id={`${prefix_id}-tfoot-toolbox`} className="w-250px-i d-flex justify-content-start align-items-center">
                <button id={`${prefix_id}-tfoot-fast-back`} type={'button'} style={BotaoAcaoStyle} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <FaAngleDoubleLeft />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-back`} type={'button'} style={BotaoAcaoStyle} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FaAngleLeft />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-forward`} type={'button'} style={BotaoAcaoStyle} onClick={() => nextPage()} disabled={!canNextPage}>
                    <FaAngleRight />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-fast-forward`} type={'button'} style={BotaoAcaoStyle} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <FaAngleDoubleRight />
                </button>{' '}
            </div>
            <div id={`${prefix_id}-tfoot-line-details`} className="w-400px-i d-flex justify-content-end align-items-center">
                <span style={TextFormat}> Linhas: {linhas}</span>
                <select style={OptionsStyle}
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[4, 8, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
export default TablePagination;