import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from '../../Input';
import EspecialidadeService from '../../../services/EspecialidadeService';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../context/snackbar';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const EspecialidadeModal = ({ modalIsOpen, setIsOpen, especialidade = null, callback }, ref) => {
    let subtitle;
    // eslint-disable-next-line no-unused-vars
    const description = 'Modal de configuração de especialidades.';

    const [nomeEspecialidade, setNomeEspecialidade] = useState('');
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (especialidade && especialidade.id) {
            setNomeEspecialidade(especialidade.especialidade);
            setId(especialidade.id);
        } else {
            setNomeEspecialidade('');
            setId();
        }
    }, [especialidade]);

    async function handleSubmit() {
        const data = {
            especialidade: nomeEspecialidade,
            id: id
        };

        if (especialidade && especialidade.id)
            return update(data, id);

        return store(data);
    }

    const store = async (data) => {
        try {
            setLoading(true);
            await EspecialidadeService.store(data);
            snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
            setNomeEspecialidade('');
            setIsOpen(false);
            callback();
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao cadastrar!', '', 'warning');
        } finally {
            setLoading(false);
        }
    };

    const update = async (data, especialidadeId) => {
        try {
            setLoading(true);
            await EspecialidadeService.update(data, especialidadeId);
            setNomeEspecialidade('');
            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
        } catch (error) {
            Swal.fire('Houve um erro ao cadastrar!', '', 'error');
            console.error(error);
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    };


    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>Especialidade</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }} >
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 450 }}>
                                <Input
                                    name={'especialidade'}
                                    title={'Especialidade'}
                                    value={nomeEspecialidade}
                                    required={'required'}
                                    onChange={(e) => setNomeEspecialidade(e.target.value)}
                                />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : (especialidade && especialidade.id)
                                    ? 'Adicionar'
                                    : 'Alterar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(EspecialidadeModal);