import React, { useRef, useCallback, useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineForm } from 'react-icons/ai';
import { Container, StatusAfastado, StatusAguardando, StatusAtivo, StatusFerias, StatusInativo, } from './styles';
import { Form } from '@unform/web';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import { useNavigate, useParams } from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import { TableDocumentosGerais } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableDocumentosGerais';
import { TableHistoricoDeFerias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableHistoricoDeFerias';
import { TableTerapias } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableTerapias';
import { TableCarterinhaPlano } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableCarterinhaPlano';
import ModalResponsavel from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalResponsavel';
import ModalTerapia from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalTerapia';
import Select from '../../../../components/Select';
import ModalDocumento from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalDocumento';
import ModalFerias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalFerias';
import TerapiaPacienteService from '../../../../services/TerapiaPacienteService';
import ResponsavelService from '../../../../services/ResponsavelService';
import PacienteFeriasService from '../../../../services/PacienteFeriasService';
import DocumentoService from '../../../../services/DocumentoService';
import PacienteService from '../../../../services/PacienteService';
import api from '../../../../services/api';
import { TableDocumentosAssinadosResponsavel } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableDocumentosAssinadosResponsavel';
import Cep from '../../../../services/cep';
import moment from 'moment/moment';
import ModalInformacoesComplementares from '../../../../components/Modais/InformacoesComplementares';
import { subHeader } from '../../Terapeutas/Novo/styles';
import { TableEmergenciasPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableEmergencias';
import ModalEmergencias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalEmergencias';
import { TableMedicosAssistentes } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableMedicos';
import ModalMedicos from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalMedicos';
import { TableMedicamentosPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableMedicamentos';
import ModalMedicamentos from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalMedicamentos';
import Swal from 'sweetalert2';
import ModalAlergias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalAlergias';
import { TableAlergiasPaciente } from '../../../RecepcaoDashboard/Pacientes/Perfil/TableAlergiasPaciente';
import { useAuth } from '../../../../context/auth';
import Arquivos from '../../../../services/ArquivosService';
import { useSnackbar } from '../../../../context/snackbar';
import Carregamento from '../../../../components/Carregamento';
import { CPFService } from '../../../../services/CPFService';
import '../../../../styles/global.css';
import { MedicamentosPacienteService, MedicosAssistentesService } from '../../../../services/EmergenciasPacienteService';
import Webcam from 'react-webcam';
import { Modal } from '../../../../components/Modais/styles';
import TableResponsavel from '../../../RecepcaoDashboard/Pacientes/Perfil/TableResponsavel';
import ModalCarterinhaPlanoPaciente from 'pages/RecepcaoDashboard/Pacientes/Perfil/ModalCarterinhaPlanoPaciente';
import BlockedArea from 'components/BlockedArea';

export default function PerfilPacienteAdmin() {
    //================================
    //===VARIÁVEIS====================
    //================================
    /*==VARS INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'EDIÇÃO E CRIAÇÃO DO PACIENTE';
    // eslint-disable-next-line no-unused-vars
    const rota = 'dashboard/{modulo}/pacientes/novo';
    // eslint-disable-next-line no-unused-vars
    const debug = true;
    const usuario = useAuth();      /* Retorna todos os dado do usuário logado */
    const snackbar = useSnackbar(); /* Barra de notificação */
    const { id, lista_id } = useParams();     /* Parametros da URL */
    const navigate = useNavigate(); /* Navegador via URL */
    const [loading, setloading] = useState(false);      /* Define se a pagina esta carregando ou não */
    const [continuar, setContinuar] = useState(false);  /* Define se o submit ira continuar a execução da tela ou não */
    //===VARS DIA ATUAL================
    let day = new Date(moment.utc().format('MM/DD/YYYY'));
    //===VARS VETORES DE DADOS=========
    const [terapias, setTerapias] = useState([]);
    const [carterinhas, setCarterinhas] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [documentosAssinados, setDocumentosAssinados] = useState([]);
    const [feriasList, setFeriasList] = useState([]);
    const [feriasPaciente, setFeriasPaciente] = useState([]);
    const [emergencias, setEmergencias] = useState([]);
    const [medicos, setMedicos] = useState([]);
    const [medicamentos, setMedicamentos] = useState([]);
    const [alergias, setAlergias] = useState([]);
    const [comorbidades, setComorbidades] = useState('');
    const [newTerapiaAdd, setNewTerapiaAdd] = useState(false);
    const [videoConstraints, setVideoConstraints] = useState();
    //===VARS DADOS PACIENTE===========
    const [responsaveisFaturamento, setResponsaveisFaturamento] = useState([]);
    const [responsavelFaturamento, setResponsavelFaturamento] = useState(null);
    const [pacienteListEspera, setPacienteListaEspera] = useState(null);
    const [paciente, setPaciente] = useState(null);
    const [pacienteId, setPacienteId] = useState(null);
    const [pacienteStatus, setPacienteStatus] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [diagnostico1SelecionadoOpt, setDiagnostico1SelecionadoOpt] = useState([]);
    const [diagnostico2SelecionadoOpt, setDiagnostico2SelecionadoOpt] = useState([]);
    const [diagnosticosList, setDiagnosticosList] = useState([]);
    const [statusColor, setStatusColor] = useState();
    const [foto_perfil, setFotoPerfil] = useState(null);
    const [alterarFotoPerfil, setAlterarFotoPerfil] = useState(false);
    const [alinhamento, setAlinhamento] = useState(false);
    const [direitoImagem, setDireitoImagem] = useState(false);
    const [naoAceitaSubstituicao, setNaoAceitaSubstituicao] = useState(false);
    const [motivoNaoAceitaSubstituicao, setMotivoNaoAceitaSubstituicao] = useState('');
    const [avisoAgendamentoCheck, setAvisoAgendamentoCheck] = useState(false);
    const [avisoAgendamento, setAvisoAgendamento] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [infoComplementar, setInfoComplementar] = useState([]);
    const [identificadorList, setIdentificadorList] = useState();
    //===VARS VALIDADORES==============
    const [isCPFValid, setIsCPFValid] = useState(false);
    const [customStyleCPF, setCustomStyleCPF] = useState([]);
    const [isIdentValid, setIsIdentValid] = useState(true);
    //===VARS MODAIS==============
    const [modalData, setModalData] = useState(null);
    const [modalDataCarterinha, setModalDataCarterinha] = useState(null);
    const [infoComplModal, setInfoComplModal] = useState(false);
    const [modalResponsavelData, setModalResponsavelData] = useState(null);
    const [modalEmergenciasData, setModalEmergenciasData] = useState(null);
    const [modalMedicosData, setModalMedicosData] = useState(null);
    const [modalMedicamentosData, setModalMedicamentosData] = useState(null);
    const [modalAlergiasData, setModalAlergiasData] = useState(null);
    const [modalFoto, setModalFoto] = useState(null);
    //===VARS REFs=========================
    const modalResponsavelRef = useRef(null);
    const tableResponsavelRef = useRef(null);
    const modalTerapiaRef = useRef(null);
    const modalCarterinhaPlanoPacienteRef = useRef(null);
    const modalDocumentoRef = useRef(null);
    const modalFeriasRef = useRef(null);
    const modalEmergenciasRef = useRef(null);
    const modalMedicosRef = useRef(null);
    const modalMedicamentosRef = useRef(null);
    const modalAlergiasRef = useRef(null);
    const documentoService = new DocumentoService('paciente');
    const formRef = useRef(null);
    const webcamRef = useRef(null);
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setFotoPerfil(imageSrc);
            setModalFoto(false);
            setAlterarFotoPerfil(false);
        },
        [webcamRef]
    );
    //===VARS STYLES=========
    const errorStyle = {
        borderColor: 'rgb(255, 47, 47)',
        color: 'rgb(128, 0, 0)',
    };
    const successStyle = {
        borderColor: 'greenyellow',
        color: 'green',
    };
    //===NAVEGADOR de PAGINAS=========
    const NavigatePage = (route) => {
        navigate(route);
    };
    const WebcamCapture = () => {
        return (
            <>
                <div className={modalFoto ? 'flex-col w-100per align-items-center justify-center d-flex' : 'd-none'}>
                    <div className="webcam-alinhamento">
                        <div className="flex-col">
                            <input type="checkbox" id="alinhamento" className="p-2" onChange={(e) => setAlinhamento(e.target.checked)} />
                            <label htmlFor="alinhamento" className="p-2">Linhas de alinhamento</label>
                        </div>
                        <div className="webcam-background pointer" onClick={capture}>
                            <Webcam
                                audio={false}
                                disablePictureInPicture={true}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        </div>
                        <div className={alinhamento ? 'lines' : 'none'}>
                            <div className={alinhamento ? 'linev' : 'none'}></div>
                            <div className={alinhamento ? 'lineh' : 'none'}></div>
                        </div>
                    </div>
                    <button className="btn-padrao mt-10px" onClick={capture}>Tirar Foto</button>
                </div>
            </>
        );
    };
    /*===============================*/
    //#region VALIDADORES============*/
    /*===============================*/
    const validaCPF = (value = null) => {
        let response = CPFService.ValidaCPF(value);
        setIsCPFValid(response.isValid);
        setCustomStyleCPF(response.style);
    };
    //#endregion
    //================================
    //===MODAIS=======================
    //================================
    const handleOpenModalResponsavel = useCallback(() => {
        modalResponsavelRef.current.openModal();
        setModalResponsavelData(null);
    }, []);
    const handleEditResponsavel = useCallback(() => {
        modalResponsavelRef.current.openModal();
    }, []);
    const handleOpenModalTerapia = useCallback(() => {
        modalTerapiaRef.current.openModal();
        setModalData(null);
    }, []);
    const handleOpenModalCarterinhaPlano = useCallback(() => {
        modalCarterinhaPlanoPacienteRef.current.openModal();
        setModalDataCarterinha(null);
    }, []);
    const handleEditTerapia = useCallback(() => {
        modalTerapiaRef.current.openModal();
    }, []);
    const handleEditCarterinhaPlanoPaciente = useCallback(() => {
        modalCarterinhaPlanoPacienteRef.current.openModal();
    }, []);
    const handleOpenModalAlergia = useCallback(() => {
        modalAlergiasRef.current.openModal();
        setModalAlergiasData(null);
    }, []);
    const handleEditAlergia = useCallback(() => {
        modalAlergiasRef.current.openModal();
    }, []);
    const handleOpenModalDocumento = useCallback(() => {
        modalDocumentoRef.current.openModal();
    }, []);
    const handleOpenEmergencias = useCallback(() => {
        modalEmergenciasRef.current.openModal();
        setModalEmergenciasData(null);
    }, []);
    const handleEditEmergencias = useCallback(() => {
        modalEmergenciasRef.current.openModal();
    }, []);
    const handleOpenModalMedicos = useCallback(() => {
        modalMedicosRef.current.openModal();
        setModalMedicosData(null);
    }, []);
    const handleEditModalMedicos = useCallback(() => {
        modalMedicosRef.current.openModal();
    }, []);
    const handleOpenModalMedicamentos = useCallback(() => {
        modalMedicamentosRef.current.openModal();
        setModalMedicamentosData(null);
    }, []);
    const handleEditMedicamentos = useCallback(() => {
        modalMedicamentosRef.current.openModal();
    }, []);
    const handleOpenModalFerias = useCallback(() => {
        modalFeriasRef.current.openModal();
    }, []);
    //================================
    //===HANDLE CHANGES===============
    //================================
    const handleObs = (e) => {
        setObservacoes(e.target.value);
    };
    const handleAvisoAgendamento = (e) => {
        setAvisoAgendamento(e.target.value);
    };
    const handleMotivoNaoPermiteSubstituicao = (e) => {
        setMotivoNaoAceitaSubstituicao(e.target.value);
    };
    const handleChangeStatus = (e) => {
        setStatusSelecionado(e);
        if (e.label === 'Ativo') setStatusColor(StatusAtivo);
        else if (e.label === 'Afastado') setStatusColor(StatusAfastado);
        else if (e.label === 'Inativo') setStatusColor(StatusInativo);
        else if (e.label === 'Férias') setStatusColor(StatusFerias);
        else {
            setStatusColor(StatusAguardando);
        }
    };
    const handleChangeDireito = (e) => {
        if (e.target.checked) {
            setDireitoImagem(true);
        } else {
            setDireitoImagem(false);
        }
    };
    const handleChangeAvisoAgendamentoCheck = (e) => {
        if (e.target.checked) {
            setAvisoAgendamentoCheck(true);
        } else {
            setAvisoAgendamentoCheck(false);
        }
    };
    const handleChangeNaoAceitaSubstituicao = (e) => {
        if (e.target.checked) {
            setNaoAceitaSubstituicao(true);
        } else {
            setNaoAceitaSubstituicao(false);
        }
    };
    const handleChangePerfil = (e) => {
        /* CONSTANTES */
        const file = e.target.files[0];
        /* VARIÁVEIS */
        let ext = ['jpg', 'png', 'jpeg', 'img'];
        let inputImg = document.querySelector('#image');
        /* Verifica a exetensão do arquivo */
        if (Arquivos.validaArquivo(ext, 5242880, file)) {
            const reader = new FileReader();

            let base64;
            reader.addEventListener('load', (e) => {
                const readerTarget = e.target;
                inputImg.src = readerTarget.result;
                base64 = readerTarget.result;
                setFotoPerfil(base64);
            });
            reader.readAsDataURL(file);
        } else {
            inputImg.value = '';
        }
        e.preventDefault();
    };
    const handleChangeDiagnostico = (definidor, e) => {
        let diagnosticos = diagnosticosList;
        let result = diagnosticos.filter((a) => a.value === e.value);
        definidor(result);
    };
    const getPacientIdentList = async () => {
        let result;
        await api.get('api/paciente/ident/list?ids=' + id).then(({ data }) => {
            result = data;
        });
        return result;
    };
    const getDisgnosticosList = async () => {
        await api.get('api/diagnostico').then(({ data }) => {
            let newData = [];
            data.forEach((e) => {
                newData.push({
                    label: e.diagnostico,
                    value: e.id
                });
            });
            setDiagnosticosList(newData);
        });
    };
    //================================
    //===SUBMIT=======================
    //================================
    const cadastrarPaciente = async (dados) => {
        dados.id_plano_saude = 1;
        dados.nascimento = dados.nascimento.split('/').reverse().join('-');
        dados.cpf = dados.cpf ? dados.cpf.replace(/[\D]/g, '') : '';
        dados.cep = dados.cpf ? dados.cep.toString().replace(/[\D]/g, '') : '';
        dados.status = statusSelecionado.label;
        dados.foto_perfil = foto_perfil;
        dados.nome_curto = dados.nome_curto;
        dados.numero = dados.numero;
        dados.cidade = dados.cidade;
        if (infoComplementar) {
            dados.gosto_de = infoComplementar.gosto_de;
            dados.nao_gosto_de = infoComplementar.nao_gosto_de;
            dados.meus_reforcadores = infoComplementar.meus_reforcadores;
            dados.meu_hiperfoco = infoComplementar.meu_hiperfoco;
            dados.informacoes_complementares = infoComplementar.informacoes_complementares;
        }
        let pacienteIdLocal;
        await PacienteService.store(dados, true)
            .then((response) => {
                pacienteIdLocal = response?.id;
                if (responsaveis.length > 0) {
                    responsaveis.forEach((responsavel) => {
                        if (responsavel) {
                            if (responsavel.ja_registrado === true) {
                                responsavel.paciente_id = pacienteIdLocal;
                            } else {
                                responsavel.id_paciente = pacienteIdLocal;
                            }
                            ResponsavelService.store(responsavel, responsavel.ja_registrado === true ? 'vinculo' : 'responsavel').catch(() => {
                                snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar o responsável, por favor, edite o paciente e realize o cadastro do responsável novamente', 'error');
                            });
                        }
                    });
                }
                if (terapias.length > 0) {
                    terapias.forEach((terapia) => {
                        if (terapia) {
                            terapia.paciente_id = pacienteIdLocal;
                            TerapiaPacienteService.store(terapia).catch(() => {
                                snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar terapias, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                            });
                        }
                    });
                }
                if (feriasList.length > 0) {
                    feriasList.forEach((ferias) => {
                        if (ferias) {
                            ferias.paciente_id = pacienteIdLocal;
                            PacienteFeriasService.store(ferias).catch(() => {
                                snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar terapias, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                            });
                        }
                    });
                }
                if (documentos.length > 0) {
                    documentos.forEach((documento) => {
                        documento.id_paciente = pacienteIdLocal;
                        documentoService.store(documento);
                    });
                }
                if (alergias.length > 0) {
                    alergias.forEach((alergia) => {
                        alergia.paciente_id = pacienteIdLocal;
                        api.post('api/paciente-alergia', alergia).catch(() => {
                            snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar alergias, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                        });
                    });
                }
                if (emergencias.length > 0) {
                    emergencias.forEach((emergencia) => {
                        emergencia.paciente_id = pacienteIdLocal;
                        api.post('api/paciente-contato-emergencia', emergencia).catch(() => {
                            snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar contatos de emergência, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                        });
                    });
                }
                if (medicamentos.length > 0) {
                    medicamentos.forEach((medicamento) => {
                        medicamento.paciente_id = pacienteIdLocal;
                        MedicamentosPacienteService.store(medicamento).catch(() => {
                            snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar medicamentos, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                        });
                    });
                }
                if (medicos.length > 0) {
                    medicos.forEach((medico) => {
                        medico.paciente_id = pacienteIdLocal;
                        MedicosAssistentesService.store(medico).catch(() => {
                            snackbar.displayMessage('Atenção! Houve um erro ao tentar cadastrar médicos assistentes, por favor, edite o paciente e realize o cadastro da(s) terapia(s) novamente', 'error');
                        });
                    });
                }
            }).catch((error) => {
                console.error(error);
                if (error.response.data.codigo === 2332) {
                    Swal.fire('Já existe um paciente com esse CPF cadastrado!', '', 'warning');
                } else {
                    snackbar.displayMessage('Um erro ocorreu ao criar cadastro do paciente!', 'error');
                }
            });
        return pacienteIdLocal;
    };
    const salvarPaciente = async (data) => {
        data.id = pacienteId;
        data.id_plano_saude = 1;
        data.nascimento = data.nascimento.split('/').reverse().join('-');
        data.cpf = data.cpf ? data.cpf.replace(/[\D]/g, '') : '';
        data.cep = data.cpf ? data.cep.toString().replace(/[\D]/g, '') : '';
        data.status = statusSelecionado.label;
        data.nome_curto = data.nome_curto;
        data.numero = data.numero;
        data.cidade = data.cidade;
        data.foto_perfil = foto_perfil;
        data.emissao_nota_fiscal = responsavelFaturamento?.value;
        return await PacienteService.update(data, data.id)
            .then(() => {
                snackbar.displayMessage('Dados do Paciente Atualizados!', 'success');
                return data.id;
            }).catch((error) => {
                console.error(error);
                snackbar.displayMessage('Um erro ocorreu ao atualizar os dados!', 'error');
            });
    };
    async function handleSubmit(data) {
        setloading(true);
        data = {
            ...data,
            obs: observacoes,
            comorbidades: comorbidades,
            uso_de_imagem: direitoImagem,
            nao_aceita_substituicao: naoAceitaSubstituicao,
            motivo_nao_aceita_substituicao: motivoNaoAceitaSubstituicao,
            aviso_agendamento: avisoAgendamento,
            aviso_agendamento_check: avisoAgendamentoCheck,
            diagnostico1_id: diagnostico1SelecionadoOpt[0]?.value,
            diagnostico2_id: diagnostico2SelecionadoOpt[0]?.value,
        };
        try {
            if (isCPFValid) {
                if (checkIdentificador(data.identificador) === 1) {
                    Swal.fire('Já existe um paciente utilizando este identificador! \n \n Por favor utilize outro!', '', 'warning');
                } else {
                    data.identificador = data.identificador.toString().toUpperCase();
                    let result =
                        (pacienteId === null)
                            ? await cadastrarPaciente(data)
                            : await salvarPaciente(data);
                    if (result) {
                        if (continuar) {
                            if (pacienteId === null) {
                                setPacienteId(result);
                                navigate('/dashboard/' + usuario.user.tipo_user.toLowerCase() + '/pacientes/perfil/' + result);
                            } else {
                                carregaPaciente();
                            }
                        } else {
                            navigate('/dashboard/' + usuario.user.tipo_user.toLowerCase() + '/pacientes');
                        }
                    }
                }
            } else {
                Swal.fire('CPF inválido!', 'O CPF adicionado esta invalido. Por favor, verifique-o e tente novamente', 'error');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setloading(false);
        }
    }
    //================================
    //===GETTERS======================
    //================================
    const carregaPaciente = (outsideId = null) => {
        let insideId = outsideId ? outsideId : id;
        setloading(true);
        if (insideId != null) {
            PacienteService.show(insideId, 'with=documentos,'
                + 'paciente_ferias,'
                + 'terapia_pacientes.especialidade,'
                + 'terapia_pacientes.terapium,'
                + 'terapia_pacientes.plano_saude,'
                + 'paciente_contato_emergencia,'
                + 'paciente_medicamentos,'
                + 'carterinha_plano_pacientes,'
                + 'carterinha_plano_pacientes.plano_saude,'
                + 'paciente_medicos_assistentes,'
                + 'paciente_alergia'
            ).then(({ data }) => {
                setFotoPerfil(data.foto_perfil);
                setDireitoImagem(data.uso_de_imagem);
                setAvisoAgendamento(data.aviso_agendamento ? data.aviso_agendamento : '');
                setAvisoAgendamentoCheck(data.aviso_agendamento_check);
                setNaoAceitaSubstituicao(data.nao_aceita_substituicao);
                setMotivoNaoAceitaSubstituicao(data.motivo_nao_aceita_substituicao ? data.motivo_nao_aceita_substituicao : '');
                setCarterinhas(data.carterinha_plano_pacientes);
                data.responsavels = data?.todos_responsavels;
                if (data.responsavels.length > 0) {
                    let responsaveisFaturamentoList = [];
                    data.responsavels.forEach((e) => {
                        responsaveisFaturamentoList.push({
                            label: e.nome,
                            value: e.id
                        });
                    });
                    responsaveisFaturamentoList.push({
                        label: data.nome,
                        value: data.id
                    });
                    setResponsaveisFaturamento(responsaveisFaturamentoList);
                    if (responsaveisFaturamentoList) {
                        const respo = responsaveisFaturamentoList.filter((e) => {
                            return (
                                Number(e?.value) === Number(data?.emissao_nota_fiscal) ||
                                e?.label?.toLowerCase() === data?.emissao_nota_fiscal?.toLowerCase()
                            );
                        })[0];
                        setResponsavelFaturamento(respo);
                    }
                    setResponsaveis(data.responsavels);
                    api.get(`/api/responsavel-assinaturas?with=documentos_administrativo,responsavel&filter[responsavel_id]=${data.responsavels[data.responsavels.length - 1].id}`)
                        .then(({ data }) => {
                            data.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                            setDocumentosAssinados(data);
                        });
                }
                if (data.documentos.length > 0) {
                    const documentosData = data.documentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setDocumentos(documentosData);
                };
                if (data.paciente_ferias.length > 0) {
                    const feriasData = data.paciente_ferias.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setFeriasPaciente(feriasData);
                    setFeriasList(feriasData);
                };
                if (data.terapia_pacientes.length > 0) {
                    const terapiasData = data.terapia_pacientes.filter((e) => e.deleted_at === null).sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setTerapias(terapiasData);
                };
                if (data.paciente_contato_emergencia.length > 0) {
                    const emergenciasData = data.paciente_contato_emergencia.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setEmergencias(emergenciasData);
                };
                if (data.paciente_medicamentos.length > 0) {
                    const medicamentosData = data.paciente_medicamentos.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicamentos(medicamentosData);
                }
                if (data.paciente_medicos_assistentes.length > 0) {
                    const medicoData = data.paciente_medicos_assistentes.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setMedicos(medicoData);
                }
                if (data.paciente_alergia.length > 0) {
                    const alergiasData = data.paciente_alergia.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });
                    setAlergias(alergiasData);
                }
                setPaciente(data);
                setPacienteId(data.id);
                delete data.responsavels;
                delete data.documentos;
                delete data.terapia_pacientes;
                validaCPF(data.cpf);
                data = {
                    ...data,
                    cpf: data.cpf ? data.cpf.toString() : '',
                    cep: data.cep ? data.cep.toString() : '',
                    nascimento: moment.utc(data.nascimento).format('YYYY-MM-DD'),
                    data_entrada: moment.utc(data.data_entrada).format('YYYY-MM-DD'),
                };
                setObservacoes(data.obs ? data.obs : '');
                setComorbidades(data.comorbidades ? data.comorbidades : '');
                setInfoComplementar({
                    gosto_de: data.gosto_de ? data.gosto_de : '',
                    nao_gosto_de: data.nao_gosto_de ? data.nao_gosto_de : '',
                    meus_reforcadores: data.meus_reforcadores ? data.meus_reforcadores : '',
                    meu_hiperfoco: data.meu_hiperfoco ? data.meu_hiperfoco : '',
                    informacoes_complementares: data.informacoes_complementares ? data.informacoes_complementares : '',
                });
                formRef.current.setData({
                    ...data,
                });
                setloading(false);
            })
                .catch((e) => console.error('e', e));
        } else {
            if (lista_id != null) {
                api.get('api/lista-espera/' + lista_id + '?with=lista_espera_demandas,lista_espera_demandas.terapium,lista_espera_demandas.plano_saude,lista_espera_demandas.especialidade,lista_espera_responsavels')
                    .then(({ data }) => {
                        setPacienteListaEspera(data);
                        if (data.lista_espera_demandas?.length > 0) {
                            const terapiasData = data.lista_espera_demandas;
                            setTerapias(terapiasData);
                        }
                        if (data.lista_espera_responsavels?.length > 0) {
                            setResponsaveis(data.lista_espera_responsavels.map((e) => {
                                return (
                                    {
                                        nome: e.responsavel,
                                        parentesco: e.grau_parentesco,
                                        telefone1: e.contato,
                                    }
                                );
                            }));
                        }


                        formRef.current.setData({
                            ...data,
                            cep: '',
                            cpf: '',
                        });
                    });
            }
            setloading(false);
            validaCPF();
        }
    };
    const checkIdentificador = (idPaciente) => {
        if (paciente === null && pacienteId === null) {
            const check = identificadorList.find(identificador => identificador.toString().toLowerCase() === idPaciente.toString().toLowerCase());
            if (check === undefined) return 0;
            else return 1;
        } else {
            const checkPaciente = identificadorList.find(identificador => identificador.toString().toLowerCase() === paciente.identificador.toString().toLowerCase());
            const checkData = identificadorList.find(identificador => identificador.toString().toLowerCase() === idPaciente.toString().toLowerCase());
            if (checkPaciente !== undefined && idPaciente === paciente.identificador) return 0;
            else if (checkPaciente === undefined && checkData === undefined) return 0;
            else if (idPaciente !== paciente.identificador && checkData !== undefined) return 1;
        }
    };
    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep.replace(/\s/g, ''));
        document.getElementById('endereco').value = data.logradouro;
        document.getElementById('bairro').value = data.bairro;
        document.getElementById('estado').value = data.uf;
        document.getElementById('cidade').value = data.localidade;
    };
    const getDefaultStatus = (statusPaciente) => {
        if (!paciente) return;
        if (statusPaciente) {
            const status = statusPaciente.filter(data => {
                return data.label === paciente.status;
            })[0];
            setStatusSelecionado(status ? status : []);
        }
    };
    const getDefaultDiagnostico = () => {
        if (!paciente) return;
        let diagnosticosListL = diagnosticosList;
        const diagnostico1 = diagnosticosListL.filter(data => {
            return data.value === paciente.diagnostico1_id;
        });
        setDiagnostico1SelecionadoOpt(diagnostico1);
        const diagnostico2 = diagnosticosListL.filter(data => {
            return data.value === paciente.diagnostico2_id;
        });
        setDiagnostico2SelecionadoOpt(diagnostico2);
    };
    const validaIdentificador = (elemento) => {
        let ident = elemento.target.value;
        if (ident !== null && ident !== '') {
            api.get('api/paciente?filter[identificador]=' + ident).then(({ data }) => {
                if (data !== undefined && data !== null && data.length > 0) {
                    setIsIdentValid(false);
                } else {
                    setIsIdentValid(true);
                }
            });
        } else {
            setIsIdentValid(true);
        }
    };
    //================================
    //===USE EFFECTS==================
    //================================
    useEffect(() => {
        if (paciente !== null) {
            if (statusSelecionado.label === 'Ativo') setStatusColor(StatusAtivo);
            else if (statusSelecionado.label === 'Afastado') setStatusColor(StatusAfastado);
            else if (statusSelecionado.label === 'Inativo') setStatusColor(StatusInativo);
            else if (statusSelecionado.label === 'Férias') setStatusColor(StatusFerias);
            else {
                setStatusColor(StatusAguardando);
            }
        }
    }, [statusSelecionado]);
    useEffect(() => {
        getDefaultStatus(pacienteStatus);
        getDefaultDiagnostico();
        if (paciente !== null && paciente !== undefined) setPacienteId(paciente.id);
        if (feriasPaciente.length > 0) {
            let index = feriasPaciente.length - 1;
            let data_ida = new Date(feriasPaciente[index].dt_ida);
            let data_volta = new Date(feriasPaciente[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [paciente]);
    useEffect(() => {
        setFeriasPaciente(feriasList);
        if (feriasList.length > 0) {
            let index = feriasList.length - 1;
            let data_ida = new Date(feriasList[index].dt_ida);
            let data_volta = new Date(feriasList[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(pacienteStatus[3]);
            } else {
                setStatusSelecionado(pacienteStatus[0]);
            }
        }
    }, [feriasList]);
    const onload = async () => {/* ONLOAD */
        setloading(true);
        let statusList = [];
        statusList.push({
            label: 'Ativo',
            value: 'ativo'
        });
        statusList.push({
            label: 'Inativo',
            value: 'inativo'
        });
        statusList.push({
            label: 'Afastado',
            value: 'afastado'
        });
        statusList.push({
            label: 'Férias',
            value: 'ferias'
        });
        statusList.push({
            label: 'Aguardando Liberação',
            value: 'aguardando'
        });
        setPacienteStatus(statusList);
        await getDisgnosticosList();
        carregaPaciente();
        let identList = await getPacientIdentList();
        setIdentificadorList(identList);
    };
    useEffect(() => {/* ONLOAD */
        onload();
    }, []);
    return (
        <Container id="root">
            {loading && <Carregamento />}
            <div className="row">
                <div className="col-12 row d-flex justify-around m-0">
                    <div className="col-4 m-0">
                        <button className="btn-padrao" onClick={e => setInfoComplModal(true)} > Informações Complementares </button>
                    </div>
                    <div className="col-4 row d-flex m-0">
                        <div className="col-12 d-flex align-center justify-center">
                            <img className="perfil-image" id='image'
                                src={(foto_perfil)
                                    ? foto_perfil
                                    : (paciente === null)
                                        ? user
                                        : (paciente.foto_perfil === null)
                                            ? user
                                            : paciente.foto_perfil
                                } alt={''} />
                        </div>
                        <div className="col-12 d-flex align-center justify-center">
                            <button className="btn-outline" onClick={() => setAlterarFotoPerfil(true)}>
                                <AiOutlineForm style={{ marginRight: 3, marginBottom: 4 }} />
                                Alterar foto de Perfil
                            </button>
                            <Modal className={alterarFotoPerfil ? '' : 'd-none'} onClick={(e) => {
                                if (e.target.contains(e.currentTarget)) {
                                    setModalFoto(false); setAlterarFotoPerfil(false);
                                }
                            }}>
                                <div className="p-3 div-modal justify-center align-center h-auto">
                                    <div className='div-titulo w-100per d-flex mb-10px pb-10px justify-content-between'>
                                        <label></label>
                                        <label>Alterar foto de perfil</label>
                                        <label>
                                            <button className='btn-icone' onClick={() => { setModalFoto(false); setAlterarFotoPerfil(false); }}>
                                                <AiOutlineClose color='red' size={22} />
                                            </button>
                                        </label>
                                    </div>
                                    <div className="w-100per">
                                        <div className="d-flex flex-row text-center justify-center align-center">
                                            <label className="btn-padrao m-2" htmlFor="foto_perfil" style={{ cursor: 'pointer' }}>
                                                Upload
                                                <input type='file' name='foto_perfil' id='foto_perfil' className='some' onChange={(e) => { handleChangePerfil(e); setAlterarFotoPerfil(false); setModalFoto(false); }} />
                                            </label>
                                            <button className="btn-padrao m-2" onClick={() => { setModalFoto(true); }}>
                                                Tirar Foto
                                            </button>
                                        </div>
                                    </div>
                                    {modalFoto && WebcamCapture()}
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-end text-right m-0'>
                        <div className='d-flex align-center w-content h-content text-right' style={{ backgroundColor: '#fff', borderRadius: 8, boxShadow: '0 0 3px 0px silver', padding: '2px 15px' }}>
                            {paciente === null ? '' : <div style={statusColor} />}
                            <label style={{ margin: 5 }}>{paciente === null ? '' : statusSelecionado.label}</label>
                        </div>
                    </div>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className="row">
                        {/* LINHA */}
                        <div className="col-lg-4 col-sm-12">
                            <Input title="Nome:" name="nome" required={'required'} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input title="Nome Curto" name="nome_curto" />
                        </div>
                        <div className="col-lg-1 col-sm-12">
                            <Input style={isIdentValid ? successStyle : errorStyle} maxLength={3} minLength={2} title="Identificador:" name="identificador" required={'required'} onBlur={(e) => validaIdentificador(e)} />
                            <label id="identificador_error">
                                {!isIdentValid && 'O identificador já está em uso.'}
                            </label>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input title="Data de Entrada:" name="data_entrada" icon={null} type={'date'} required={'required'} disabled={false} defaultValue={statusSelecionado?.label !== 'Aguardando Liberação' ? moment.utc().format('YYYY-MM-DD') : ''} />
                        </div>
                        {/* LINHA */}
                        <div className="col-lg-3 col-sm-12">
                            <Select label="Status:" name="status" options={pacienteStatus} value={statusSelecionado} required={'required'} onChange={e => handleChangeStatus(e)} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input title="Data de Nascimento:" name="nascimento" icon={null} type={'date'} required={'required'} disabled={false} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <InputMask containerStyle={customStyleCPF} onChange={(e) => validaCPF(e.target.value)} title="CPF" name="cpf" icon={null} maskFormat={'999.999.999 99'} disabled={false} required={'required'} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input title="RG:" name="rg" required={'required'} />
                        </div>
                        {/* LINHA */}
                        <div className="col-lg-2 col-sm-12">
                            <InputMask onBlur={(ev) => getCep(ev.target.value)} title="CEP" name="cep" id="cep" icon={null} maskFormat={'99999 999'} disabled={false} required={'required'} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input title="Rua:" name="endereco" id="endereco" required={'required'} />
                        </div>
                        <div className="col-lg-1 col-sm-12">
                            <Input title="Número:" name="numero" required={'required'} />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            <Input title="Bairro:" name="bairro" id="bairro" required={'required'} />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            <Input title="Cidade:" name="cidade" id="cidade" required={'required'} />
                        </div>
                        <div className="col-lg-1 col-sm-12">
                            <Input title="Estado:" name="estado" id="estado" required={'required'} />
                        </div>
                        {/* LINHA */}
                        <div className="col-lg-12 col-sm-12">
                            <Input title="Complemento:" name="complemento" id="complemento" />
                        </div>
                        {/* LINHA */}
                        <div className={`col-lg-${pacienteId !== null ? '4' : '6'} col-sm-12`}>
                            <Select label={'Diagnóstico 1°'} options={diagnosticosList} value={diagnostico1SelecionadoOpt} name={'diagnostico1'} onChange={(e) => handleChangeDiagnostico(setDiagnostico1SelecionadoOpt, e)} isClearable></Select>
                        </div>
                        <div className={`col-lg-${pacienteId !== null ? '4' : '6'} col-sm-12`}>
                            <Select label={'Diagnóstico 2°'} options={diagnosticosList} value={diagnostico2SelecionadoOpt} name={'diagnostico2'} onChange={(e) => handleChangeDiagnostico(setDiagnostico2SelecionadoOpt, e)} isClearable></Select>
                        </div>
                        {pacienteId !== null &&
                            <div className="col-4">
                                <Select label={'Responsável do Faturamento'} options={responsaveisFaturamento} value={responsavelFaturamento} name={'responsavel_faturamento'} onChange={(e) => setResponsavelFaturamento(e)} isClearable></Select>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <label className="mt-10px label-padrao">
                                Autorização de Uso de Imagem:
                            </label>
                            <div className="fake-input">
                                <label htmlFor="uso_de_imagem">
                                    <input id="uso_de_imagem" name="uso_de_imagem" type={'checkbox'} value={direitoImagem} checked={direitoImagem} style={{ marginRight: 10, }} onChange={e => handleChangeDireito(e)} />
                                    Permite uso de Imagem
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {pacienteId !== null &&
                            <>
                                <div className="col-lg-6 col-sm-12">
                                    <label className="mt-10px label-padrao">
                                        Não permite Substituição:
                                    </label>
                                    <div className="fake-input">
                                        <label htmlFor="nao_aceita_substituicao">
                                            <input id="nao_aceita_substituicao" name="nao_aceita_substituicao" type={'checkbox'} value={naoAceitaSubstituicao} checked={naoAceitaSubstituicao} style={{ marginRight: 10, }} onChange={e => handleChangeNaoAceitaSubstituicao(e)} />
                                            Paciente não permite substituição
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <label className="mt-10px label-padrao">
                                        Alerta de agendamento:
                                    </label>
                                    <div className="fake-input">
                                        <label htmlFor="aviso_agendamento_check">
                                            <input id="aviso_agendamento_check" name="aviso_agendamento_check" type={'checkbox'} value={avisoAgendamentoCheck} checked={avisoAgendamentoCheck} style={{ marginRight: 10, }} onChange={e => handleChangeAvisoAgendamentoCheck(e)} />
                                            Paciente com alerta de agendamento
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <label style={subHeader}>Motivo do Paciente não Aceita Substituição:</label>
                                    <textarea name={'obs'} onChange={handleMotivoNaoPermiteSubstituicao} value={motivoNaoAceitaSubstituicao} className="text-area-padrao" />
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <label style={subHeader}>Motivo do alerta de Agendamento:</label>
                                    <textarea name={'obs'} onChange={handleAvisoAgendamento} value={avisoAgendamento} className="text-area-padrao" />
                                </div>
                            </>
                        }
                        <div className="col-sm-12 col-lg-6">
                            <label style={subHeader}>Observações Gerais do(a) Paciente:</label>
                            <textarea name={'obs'} onChange={handleObs} value={observacoes} className="text-area-padrao" />
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <label style={subHeader}> Comorbidades do(a) Paciente</label>
                            <textarea name={'comorbidades'} onChange={e => setComorbidades(e.target.value)} defaultValue={comorbidades} className="text-area-padrao" />
                        </div>
                    </div>
                    <BlockedArea text="Salve os dados básicos do paciente para adicionar as outras informações." blockStatus={pacienteId === null}>
                        <div className="col-12 pt-20px pb-20px">
                            <label style={subHeader}>Terapias do(a) Paciente</label>
                            <ModalTerapia terapias={terapias} setTerapias={setTerapias} paciente_id={pacienteId} callBack={carregaPaciente} ref={modalTerapiaRef} setNewTerapiaAdd={setNewTerapiaAdd} modalData={modalData} />
                            <TableTerapias source={terapias} callBack={carregaPaciente} setModalData={setModalData} handleOpen={handleEditTerapia} modalData={modalData} paciente_id={pacienteId} setTerapias={setTerapias} />
                            <div className="div-center">
                                <button onClick={e => handleOpenModalTerapia()} type="button" className='btn-padrao'> Adicionar Terapia</button>
                            </div>
                        </div>
                        <div className="col-12 pt-20px pb-20px">
                            <label style={subHeader}>Carterinhas do Plano</label>
                            <ModalCarterinhaPlanoPaciente ref={modalCarterinhaPlanoPacienteRef} carterinhas={carterinhas} modalData={modalDataCarterinha} paciente_id={pacienteId} setCarterinhas={setCarterinhas} />
                            <TableCarterinhaPlano source={carterinhas} callBack={carregaPaciente} setModalData={setModalDataCarterinha} handleOpen={handleEditCarterinhaPlanoPaciente} modalData={modalData} paciente_id={pacienteId} setCarterinhas={setCarterinhas} />
                            <div className="div-center">
                                <button onClick={e => handleOpenModalCarterinhaPlano()} type="button" className='btn-padrao'> Adicionar Carterinha do Plano</button>
                            </div>
                        </div>
                        <div className="col-12 pt-20px pb-20px">
                            <label style={subHeader}>Responsáveis do(a) Paciente</label>
                            <ModalResponsavel ref={modalResponsavelRef} responsaveis={responsaveis} setResponsaveis={setResponsaveis} modalData={modalResponsavelData} paciente_id={pacienteId} callBack={carregaPaciente} />
                            <TableResponsavel ref={tableResponsavelRef} source={responsaveis} setModalData={setModalResponsavelData} pacienteId={pacienteId} callBack={carregaPaciente} handleOpen={handleEditResponsavel} setResponsaveis={setResponsaveis} />
                            <div className='div-center'>
                                <button onClick={e => handleOpenModalResponsavel()} type="button" className='btn-padrao'> Adicionar Responsável</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Contatos de Emergência</label>
                            <ModalEmergencias ref={modalEmergenciasRef} modalData={modalEmergenciasData} emergencias={emergencias} setEmergencias={setEmergencias} pacienteId={pacienteId} callBack={carregaPaciente} />
                            <TableEmergenciasPaciente source={emergencias} setModalEmergenciasData={setModalEmergenciasData} setEmergencias={setEmergencias} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditEmergencias} />
                            <div className='div-center'>
                                <button type="button" onClick={e => handleOpenEmergencias()} className="btn-padrao"> Adicionar Contatos de Emergência</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Medicamentos do(a) Paciente</label>
                            <ModalMedicamentos modalData={modalMedicamentosData} callBack={carregaPaciente} medicamentos={medicamentos} ref={modalMedicamentosRef} setMedicamentos={setMedicamentos} paciente_id={pacienteId} />
                            <TableMedicamentosPaciente source={medicamentos} setMedicamentos={setMedicamentos} setModalMedicamentosData={setModalMedicamentosData} handleOpen={handleEditMedicamentos} callBack={carregaPaciente} pacienteId={pacienteId} />
                            <div className='div-center'>
                                <button type="button" onClick={e => handleOpenModalMedicamentos()} className="btn-padrao"> Adicionar Medicamentos</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Alergias do(a) Paciente:</label>
                            <ModalAlergias modalData={modalAlergiasData} callBack={carregaPaciente} alergias={alergias} ref={modalAlergiasRef} setAlergias={setAlergias} paciente_id={pacienteId} />
                            <TableAlergiasPaciente source={alergias} setAlergias={setAlergias} setModalAlergiasData={setModalAlergiasData} handleOpen={handleEditAlergia} callBack={carregaPaciente} pacienteId={pacienteId} />
                            <div className="div-center">
                                <button type="button" onClick={e => handleOpenModalAlergia()} className="btn-padrao"> Adicionar Alergias</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Médicos Assistentes do(a) Paciente</label>
                            <ModalMedicos modalData={modalMedicosData} paciente_id={pacienteId} setMedicos={setMedicos} medicos={medicos} callBack={carregaPaciente} ref={modalMedicosRef} />
                            <TableMedicosAssistentes source={medicos} setMedicos={setMedicos} setModalMedicosData={setModalMedicosData} callBack={carregaPaciente} pacienteId={pacienteId} handleOpen={handleEditModalMedicos} />
                            <div className="div-center">
                                <button type="button" onClick={e => handleOpenModalMedicos()} className="btn-padrao"> Adicionar Médicos Assistentes </button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Documentos Gerais do(a) Paciente</label>
                            <ModalDocumento documentos={documentos} setDocumentos={setDocumentos} ref={modalDocumentoRef} paciente_id={pacienteId} callBack={carregaPaciente} />
                            <TableDocumentosGerais source={documentos} deleteCallback={carregaPaciente} setDocumentos={setDocumentos} pacienteId={pacienteId} />
                            <div className='div-center'>
                                <button onClick={e => { handleOpenModalDocumento(); }} type="button" className='btn-padrao'> Adicionar Documentos Gerais </button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 pt-20px pb-20px">
                            <label style={subHeader}>Documentos Assinados Responsável:</label>
                            <TableDocumentosAssinadosResponsavel source={documentosAssinados}></TableDocumentosAssinadosResponsavel>
                        </div>
                        <div className="col-sm-12 col-lg-12 pb-50px">
                            <label style={subHeader}>
                                Histórico Férias do(a) Paciente:
                            </label>
                            {feriasList !== undefined && feriasList !== null && feriasList.length > 0
                                ? <TableHistoricoDeFerias source={feriasList} feriasList={feriasList} setFeriasList={setFeriasList} paciente_id={pacienteId} />
                                : <p className="m-auto text-center">
                                    Não tem férias
                                </p>
                            }
                            <ModalFerias feriasList={feriasList} setFeriasList={setFeriasList} ref={modalFeriasRef} paciente_id={pacienteId} />
                            <div className='div-center'>
                                <button onClick={e => { handleOpenModalFerias(); }} type="button" className={pacienteId ? 'btn-padrao' : 'btn-padrao btn-disabled'} disabled={pacienteId ? false : true}>
                                    {pacienteId
                                        ? 'Adicionar Histórico de Férias'
                                        : 'Adicionar apenas na edição.'
                                    }
                                </button>
                            </div>
                        </div>
                    </BlockedArea>
                    <div className='btn-space-40px d-flex pb-1 text-center justify-content-between position-fixed bottom-0 center-horizontal pt-10px bg-branco border-radius-top-10px shadow-1'>
                        <button className='btn-padrao laranja' style={{ minWidth: '175px' }} type="button" onClick={e => NavigatePage('/dashboard/' + usuario.user.tipo_user.toLowerCase() + '/pacientes')} disabled={loading}>
                            {'Voltar sem salvar'}
                        </button>
                        {id
                            ? <button className='btn-padrao laranja' style={{ minWidth: '175px' }} type="submit" onClick={e => setContinuar(true)} disabled={loading}>
                                {'Salvar e continuar'}
                            </button>
                            : <button className="btn-padrao laranja" style={{ minWidth: '175px' }} type="submit" onClick={e => setContinuar(true)} disabled={loading}>
                                {'Cadastrar e acessar'}
                            </button>
                        }
                        <button className='btn-padrao laranja' style={{ minWidth: '175px' }} type="submit" onClick={e => setContinuar(false)} disabled={loading}>
                            {`${pacienteId ? 'Salvar' : 'Cadastrar'} e sair`}
                        </button>
                    </div>
                </Form>
            </div>
            <>
                {infoComplModal && <ModalInformacoesComplementares onClose={() => setInfoComplModal(false)} infoComplementar={infoComplementar} paciente={paciente} setInfoComplementar={setInfoComplementar} callBack={carregaPaciente} />}
            </>
        </Container>
    );
}
