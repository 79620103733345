import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const Calendar = styled.aside`
  width: 380px;
  .DayPicker {
    background: #28262e;
    border-radius: 10px;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }
  .DayPicker-Day--today {
    font-weight: normal;
  }
  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: #ff9000 !important;
    border-radius: 10px;
    color: #232129 !important;
  }
`;

export const tr1 = {
    backgroundColor: ' #EAEAEA',
    color: '#000000',
    boxShadow: '0 0 3px 1px silver',
    padding: 5,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
    height: 50,
    width: 120
};

export const tr2 = {
    backgroundColor: '#7441BF',
    color: '#fff',
    padding: '5px 22px',
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderWidth: 2,
    borderStyle: 'solid',
    cursor: 'pointer',
    marginBottom: 5
};

export const tr3 = {
    backgroundColor: '#F5F7FA',
    color: '#000000',
    padding: 5,
    width: 100,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderWidth: 2,
    borderStyle: 'solid',
};

export const trHora = {
    backgroundColor: '#DFCDF8',
    color: '#9586AC',
    padding: 5,
    boxShadow: '0 0 2px 1px silver',
    width: 100,
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
};

export const trVazio = {
    backgroundColor: '#fff',
    padding: 5,
    width: 100,
    height: 80,
    boxShadow: '0 0 3px 1px silver',
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
};

export const trOcupado = {
    backgroundColor: '#35D058',
    fontSize: 14,
    color: '#fff',
    padding: 5,
    width: 100,
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
};

export const linha = {
    borderColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff'
};