import React, { useEffect, useState } from 'react';
import { AiOutlineUser, AiOutlineBell } from 'react-icons/ai';
import api from '../../../services/api';
import { TableTerapeutas } from './TableTerapeutas';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { ButtonRegister, Container, SubMenuHeader, SubMenuStyle, TerapeutaFilter } from './styles';
import { useNavigate } from 'react-router-dom';
import UsuarioLogado from '../../../components/UsuarioLogado';
import FiltroTerapias from '../../../components/Filter';
import Responsavel from '../../../layouts/Responsavel';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function TerapeutasResponsavel() {
    
    const navigate = useNavigate();
    const [loadingDadosTerapeutas, setLoadingDadosTerapeutas] = useState(false);
    const [terapeutas, setTerapeutas] = useState([]);
    const [filterValue, setFilter] = useState('');
    const [terapeutaOriginal, setTerapeutaOriginal] = useState([]);
    const [color, setColor] = useState('#8ed4ae');

    const sourceTerapias = 'api/terapeuta?with=terapeuta_terapia.terapium&filter[terapeuta_terapia.terapia_id]=';
    const sourceEspecialidades = 'api/terapeuta?with=terapeuta_especialidades.especialidade&filter[terapeuta_especialidades.especialidade_id]=';

    const handleSeachTerapeuta = (data) => {
        setFilter(data);
        setTerapeutas(terapeutaOriginal);
        setTerapeutas((terapeuta) =>
            terapeuta.filter((item) => item.nome.toString().toLowerCase().includes(data.toString().toLowerCase()))
        );
    };

    async function loadTerapeutas() {
        api.get('api/terapeuta?with=terapeuta_especialidades.especialidade,terapeuta_terapia.terapium').then(({data}) => {
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setTerapeutaOriginal(data);
            setTerapeutas(data);
        });
        setLoadingDadosTerapeutas(false);
    }

    useEffect(() => {
        setLoadingDadosTerapeutas(true);
        loadTerapeutas();
    }, []);

    return (
        <Responsavel>
            <Container>
                <div style={SubMenuStyle}>
                    <div>
                        <label style={SubMenuHeader}>Terapeutas</label>
                        {/* <label style={{ cursor: "pointer" }}>Perfil do Terapeutas</label> */}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <AiOutlineBell color="silver" size={20} />
                        <AiOutlineUser color="silver" size={20} />
                        <div>
                            <UsuarioLogado />
                        </div>
                    </div>
                </div>
                <div style={{marginBottom: 20}}>
                    <FiltroTerapias resultRef={setTerapeutas} sourceTerapias={sourceTerapias} sourceEspecialidades={sourceEspecialidades}
                    callBack={loadTerapeutas} sourceStatus={terapeutaOriginal}/>
                </div>
                <div style={{alignItems: 'center',}}>
                    <div style={{ width: '100%', paddingBottom: 20, paddingTop: 10, }}>
                        <input style={TerapeutaFilter} placeholder={'Pesquisar Terapeuta por Nome:'}
                         value={filterValue || ''} onChange={(e) => {handleSeachTerapeuta(e.target.value);}}/>
                    </div>
                </div>
                <div style={{marginBottom: 50}}>
                    {
                        (terapeutas.length != 0 && loadingDadosTerapeutas === false) ? <TableTerapeutas source={terapeutas} /> :
                        <ClipLoader color={color} loading={loadingDadosTerapeutas} css={override} size={50} />
                    }
                </div>
            </Container>
        </Responsavel >
    );
}
