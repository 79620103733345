import styled from 'styled-components';
import { css } from '@emotion/react';

export const Container = styled.div`
background: #F5F7FA;
margin: 0 auto;`;
 

export const ContainerCardsPacientes = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
`; 

export const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
`;


export const MainDivStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginBottom: 20,
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    marginRight: 80,
    marginLeft: 80,
    height: 58,
    padding: '0px 20px',
    boxShadow: '0 0 10px 0px silver',
};

export const TdStyle = {
    padding: '10px',
    marginRight: '10px',
    background: 'white',
    borderRadius: '10px',
};

export const ThStyle = {
    color: 'black', 
    fontWeight: 'bold',
    textAlign: 'left',
};

export const BotaoRelatorioStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    lineHeight: 1,
    border: 'none',
    padding: '2px 22px',
    borderRadius: 8,
    marginRight: 12,
    height: 40,
    width: '17%'
};

export const BotaoInserirRelatorio = {
    alignItems: 'center',
    justifyContent: 'center', 
    display: 'flex', 
    marginTop: 20, 
    paddingBottom: 50
};