import React, {useEffect, useState} from 'react';
import {AiFillCaretDown, AiOutlineBell, AiOutlineLeft, AiOutlineRight, AiOutlineUser} from 'react-icons/ai';
import 'react-day-picker/dist/style.css';
import {Container} from './styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import Terapeuta from '../../../../layouts/Terapeuta';
import UsuarioLogado from '../../../../components/UsuarioLogado';
import LegendaDefault from '../../../RecepcaoDashboard/Agenda/ControleDiario/Modal/Legenda';
import DetalhesDoAtendimento from '../../../RecepcaoDashboard/Agenda/AreaCompartilhada/Modal/DetalhesDoAtendimento';
import AgendaAreaCompartilhadaService from '../../../../services/AgendaAreaCompartilhadaService';
import AreaCompartilhadaService from '../../../../services/AreaCompartilhadaService';
import api from '../../../../services/api';
import { Link } from 'react-router-dom';
import AdicionarAgendamento from '../../../RecepcaoDashboard/Agenda/AreaCompartilhada/Modal/AdicionarAgendamento';

export default function AreaCompartilhadaTerapeuta() {

  const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
  const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
  const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
  const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);

  const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];

  const [hour, setHour] = useState('');
  const [date, setDate] = useState('');
  const [agendas, setAgendas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [estacaoObj, setEstacaoObj] = useState(null);
  const [expand, setExpand] = useState(false);
  const [filter, setFilter] = useState(false);
  const [atendimentos, setAtendimentos] = useState([]);
  const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
  const [atendimentoId, setAtendimentoId] = useState();

  const dateNow = new Date();

  const [dataAtual, setDataAtual] = useState(`${dayName[dateNow.getDay()]} - ${moment().format('DD/MM/YYYY')}`);
  const [diaAtual, setDiaAtual] = useState(dateNow.getDay());
  const [dataSave, setDataSave] = useState(moment());

  // const [nextDayEnable, setNextDayEnable] = useState(true);
  // const [prevDayEnable, setPrevDayEnable] = useState(true);

  const updateFilter = () => {
    let fields = document.getElementsByName('salas[]');
    let checks = [];
    fields.forEach((x) => {
      if (x.checked) {
        checks.push(x.value);
      }
    });

    return checks;
  };

  const atualizaComponenteData = (diaAtualLocal, dataSaveLocal) => {
    setDiaAtual(diaAtualLocal);
    setDataAtual(dataSaveLocal.format('dddd - DD/MM/YYYY'));
    setDataSave(dataSaveLocal);
    setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
    loadAtendimentos(dataSaveLocal.format('YYYY-MM-DD'));
    getAgendaHorarios(diaAtualLocal);
  };

  const handleClickNextDay = () => {
    let dataSaveLocal = moment(dataSave).add(1, 'days');
    let diaAtualLocal = dataSaveLocal.format('e');
    atualizaComponenteData(diaAtualLocal, dataSaveLocal);
  };

  const handleClickPrevDay = () => {
    let dataSaveLocal = moment(dataSave).subtract(1, 'days');
    let diaAtualLocal = dataSaveLocal.format('e');
    atualizaComponenteData(diaAtualLocal, dataSaveLocal);
  };

  const modalAddAtendimento = (hour, dateParam, area) => {
    setHour(hour);
    setEstacaoObj(area);
    setIsModalAdicionarAtendimentoVisible(true);
  };

  const modalLegendas = () => {
    setIsModalLegendaVisible(true);
  };

  const modalResponsavel = (sala, estacao) => {
    setIsModalResponsavelVisible(true);
  };

  const modalDetalhesDoAtendimento = (detalhes) => {
    setAtendimentoId(detalhes.id);
    setIsModalDetalhesDoAtendimentoVisible(true);
  };

  const expandFiltro = () => {
    setExpand(!expand);
  };

  const getAgendaDia = () => {
    let params = 'with=terapeutum,area_compartilhada,paciente&';
    let filters = updateFilter();
    if (filters.length > 0) {
      params += 'filter[area_compartilhada_id]=' + filters.join(',');
    }
    AgendaAreaCompartilhadaService.index(params).then(({data}) => {
      console.log('data', data);
      data.sort((a, b) => a.area_compartilhada_id < b.area_compartilhada_id ? -1 : a.area_compartilhada_id > b.area_compartilhada_id ? 1 : 0);
      setAgendas(data);
    });
  };

  const getAgendaHorarios = (diaAtualLocal) => {
    api.get('/api/horario-atendimento?filter[dia_semana]=' + diaAtualLocal).then(({data}) => {
      setHorarios(data);
    });
  };

  const loadAreasCompartilhadas = () => {
    AreaCompartilhadaService.index()
      .then(({data}) => setAreas(data));
  };

  const loadAtendimentos = (dataSaveLocal) => {
    AgendaAreaCompartilhadaService.index('with=terapeutum,area_compartilhada,paciente&filter[horario]=' + dataSaveLocal)
      .then(({data}) => {
        setAtendimentos(data);
      });
  };

  useEffect(() => {
    getAgendaDia();
    getAgendaHorarios(diaAtual);
    loadAreasCompartilhadas();
    loadAtendimentos(dataSelecionada);
  }, []);

  return (
    <Terapeuta>
      <Container>
        <div style={{
          display: 'flex', justifyContent: 'space-between',
          backgroundColor: 'white',
          borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
          alignItems: 'center', height: 58,
          padding: '0px 20px', marginBottom: '4rem',
          boxShadow: '0 0 10px 0px silver',
        }}>
            <div>
                <Link style={{textDecoration: 'none', alignItems: 'center', justifyContent: 'center', }}
                        to="/dashboard/terapeuta/agenda">
                    <label style={{ color: '#000000',
                    cursor: 'pointer', marginRight: 20, }}>
                        Agenda Semanal
                    </label>
                </Link>
                <label style={{ cursor: 'pointer', color: '#7340BD', fontWeight: 'bold',}}>
                    Áreas Compartilhadas
                </label>

            </div>
          <div style={{display: 'flex'}}>
            <AiOutlineBell color="silver" size={20}/>
            <AiOutlineUser color="silver" size={20}/>
            <div>
              <UsuarioLogado />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>

          <div style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <label style={{color: '#7441BF', fontWeight: 'bold', fontSize: '18px'}}>Filtro</label>
          </div>

          <div style={{
            backgroundColor: '#7441BF',
           
            color: '#FFFFFF',
            padding: '16px',
            borderRadius: '16px',
            marginTop: '10px',
            marginBottom: '10px'
          }}>

            <div style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <label>Selecionar Área Compartilhada:</label>
              <div style={{cursor: 'pointer'}}>
                <AiFillCaretDown color="white" size={22} onClick={() => expandFiltro()}/>
              </div>
            </div>
            {expand &&
              <div style={{display: 'flex'}}>
                {areas.map((area, index) => (
                  <div key={index} style={{paddingRight: '10px'}}>
                    <input onClick={(e) => getAgendaDia()} type="checkbox"
                           id={area.area} name="salas[]" value={area.id} style={{marginRight: '6px'}}/>
                    <span htmlFor={area.area}>{area.area}</span>
                  </div>
                ))}
              </div>
            }

          </div>

          <div style={{width: '100%', paddingBottom: 20, paddingTop: 4}}>
            <input style={{
              width: '80%',
              borderRadius: 8,
              height: 36,
              boxShadow: '0 0 10px 0px silver',
              border: 'none',
              padding: 12,
              margin: 20
            }} placeholder={'Pesquisar por Nome'}
                   value={''}
            />
            <button style={{
              backgroundColor: '#7340BD',
              color: '#FFFFFF',
             
              border: 'none',
              padding: '7px 30px',
              borderRadius: '10px',
            }}>
              Ir
            </button>
          </div>

          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignContent: 'center',
            alignItems: 'center',
            paddingBottom: '30px'
          }}>
            <div style={{
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
              <AiOutlineLeft color="black" size={22} style={{cursor: 'pointer'}}
                             onClick={() => handleClickPrevDay()}/>
              <span style={{
               
                color: '#000000',
                fontWeight: 'bold'
              }}>{`${dataAtual}`}</span>
              <AiOutlineRight color="black" size={22} style={{cursor: 'pointer'}}
                              onClick={() => handleClickNextDay()}/>
            </div>
            <button
              onClick={() => modalLegendas()}
              style={{
                backgroundColor: '#7441BF',
               
                color: '#FFFFFF',
                paddingRight: '32px',
                paddingLeft: '32px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '6px',
                marginTop: '10px',
                marginBottom: '10px',
                border: 0
              }}>Legenda
            </button>
          </div>

          <div style={{
            display: 'flex',
            // alignItems: "center",
            // justifyContent: "center",
            flexDirection: 'row',
            width: '99%',
            maxWidth: '100%',
            marginBottom: 50
          }}>
            <div>
              <table>
                <tr>
                  <td align={'center'} style={styles.tr1}>Área</td>
                  {areas.map((area) => {
                    return <td align={'center'} style={styles.tr2}>{area.area}</td>;
                  })}
                </tr>
                {horarios.map((horario) => {
                  let hourCalendar = moment.utc(horario.horario);
                  let hourCalendarF = hourCalendar.format('HH:mm');
                  let dateCalendarF = hourCalendar.format('YYYY-MM-DD');

                  return (
                    <tr>
                      <td align={'center'} style={styles.trHora}>{hourCalendarF}</td>
                      {areas.map((area) => {
                        if (atendimentos.length > 0) {
                          for (let i = 0; i < atendimentos.length; i++) {
                            let hourAtendimento = moment.utc(atendimentos[i].data_atendimento_inicial);
                            let hourAtendimentoF = hourAtendimento.format('HH:mm');
                            if (hourCalendarF === hourAtendimentoF && atendimentos[i].area_compartilhada_id === area.id) {
                              return (
                                <td
                                  onClick={() => modalDetalhesDoAtendimento(atendimentos[i])}
                                  style={styles.trOcupado}>
                                  {atendimentos[i].paciente.nome.substr(0, 7)}.
                                  <hr style={styles.linha}/>
                                  {atendimentos[i].terapeutum.nome.substr(0, 7)}.
                                </td>
                              );
                            }
                          }
                        }
                        return (
                          <td
                            onClick={() => modalAddAtendimento(hourCalendarF, dateCalendarF, area)}
                            style={styles.trVazio}></td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </Container>
      {isModalAdicionarAtendimentoVisible ? <AdicionarAgendamento
        hour={hour}
        area={estacaoObj}
        date={dataSave.format('YYYY-MM-DD')}
        onClose={() => setIsModalAdicionarAtendimentoVisible(false)}
        updateAgenda={() => {
          loadAtendimentos(dataSave.format('YYYY-MM-DD'));
          getAgendaHorarios(diaAtual);
        }}
      /> : (<></>)}

      {isModalLegendaVisible ? <LegendaDefault
        onClose={() => setIsModalLegendaVisible(false)}
      /> : (<></>)}

      {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAtendimento
        atendimentoId={atendimentoId}
        onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
      /> : (<></>)}
    </Terapeuta>
  );
}

const styles = {
  tr1: {
    backgroundColor: ' #EAEAEA',
    color: '#9586AC',
    boxShadow: '0 0 3px 1px silver',
    padding: 5,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  tr2: {
    backgroundColor: '#00B19D',
    color: '#fff',
    padding: '5px 22px',
    boxShadow: '0 0 3px 1px silver',
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
    marginBottom: 5
  },
  tr3: {
    backgroundColor: '#E6C833',
    color: '#fff',
    padding: 5,
    width: 100,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
  },
  trHora: {
    backgroundColor: '#DFCDF8',
    color: '#9586AC',
    padding: 5,
    boxShadow: '0 0 2px 1px silver',
    width: 100,
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
  },
  trVazio: {
    backgroundColor: '#fff',
    padding: 5,
    width: 100,
    height: 80,
    boxShadow: '0 0 3px 1px silver',
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  trOcupado: {
    backgroundColor: '#35D058',
    color: '#fff',
    padding: 5,
    width: 100,
    boxShadow: '0 0 3px 1px silver',
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  linha: {
    borderColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff'
  }
};
