import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirecionaParaDashboard = ({ user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/dashboard/' + user.tipo_user);
    }, [user]);

    return (<></>);
};

export default RedirecionaParaDashboard;