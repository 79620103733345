import { css } from '@emotion/react';
import { Form } from '@unform/web';
import Button from 'components/Button';
import PickList from 'components/PickList'; // Importando o seu componente personalizado
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import { useSnackbar } from '../../../context/snackbar';
import api from '../../../services/api';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';

/**
 * AssocPermissaoModal component handles the association of permissions to a group.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.modalIsOpen - Indicates if the modal is open.
 * @param {Function} props.setIsOpen - Function to set the modal open state.
 * @param {number} props.groupId - The ID of the group to associate permissions with.
 * @param {Object} ref - The reference to the modal.
 *
 * @returns {JSX.Element} The rendered component.
 */
/**
 * Componente de modal para associar permissões a um grupo.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {boolean} props.modalIsOpen - Indica se o modal está aberto.
 * @param {Function} props.setIsOpen - Função para alterar o estado de abertura do modal.
 * @param {number} props.groupId - ID do grupo ao qual as permissões serão associadas.
 * @param {Object} ref - Referência do componente.
 *
 * @returns {JSX.Element} O componente de modal para associar permissões.
 */
const AssocPermissaoModal = ({ modalIsOpen, setIsOpen, groupId }, ref) => {
    //#region VARIAVEIS
    // Referências
    const formRef = useRef(null);
    const pickListRef = useRef(null);
    let subtitle;

    // Estados
    const [permissoes, setPermissoes] = useState([]);
    const [permissaoSelecionada, setPermissaoSelecionada] = useState([]);
    const [permissaoAlterada, setPermissaoAlterada] = useState([]);
    const [loading, setLoading] = useState(true);

    // Constantes
    const color = '#8ed4ae';
    const snackbar = useSnackbar();

    // Estilos
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const customStyles = {
        content: {
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            height: 'auto',
            width: '70vw',
            display: 'grid',
            backgroundColor: '#FFFFFF',
            padding: '0',
        },
    };
    //#endregion

    //#region FUNCOES DE MODAL
    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    //#endregion

    //#region HANDLES
    /**
     * Função de callback para submissão de permissões.
     * Envia uma requisição POST para associar permissões ao grupo especificado.
     * Exibe uma mensagem de sucesso ou erro com base na resposta da requisição.
     * 
     * @function
     * @name handleSubmit
     * @returns {void}
     */
    const handleSubmit = useCallback(() => {
        setLoading(true);
        api.post(`api/permission/${groupId}/assoc`, { added: permissaoAlterada.added, removed: permissaoAlterada.removed })
            .then(() => snackbar.displayMessage('Permissões associadas com sucesso!', 'success'))
            .catch((error) => {
                snackbar.displayMessage('Erro ao associar permissões!', 'error');
                console.error(error);
            }).finally(() => {
                setLoading(false);
                closeModal();
            });
    }, [groupId, permissaoAlterada, snackbar, closeModal]);
    //#endregion

    //#region FUNCOES
    /**
     * Função que busca as permissões de um grupo específico a partir de seu ID.
     * Utiliza a API para obter as permissões associadas ao grupo e atualiza o estado com as permissões ordenadas por nome.
     * Em caso de erro, exibe uma mensagem de erro utilizando o snackbar.
     *
     * @function
     * @name getGroupPermissoes
     * @param {number} groupId - O ID do grupo cujas permissões serão buscadas.
     * @returns {void}
     */
    const getGroupPermissoes = useCallback(() => {
        api.get(`/api/group-has-permission?filter[group_id]=${groupId}&with=permission`)
            .then(({ data }) => {
                const defaults = data.map((x) => ({ value: x.permission_id, label: x.permission.name, group_id: x.group_id, permission_id: x.permission_id }));
                setPermissaoSelecionada(defaults.sort((a, b) => a.label.localeCompare(b.label)));
                setLoading(false);
            })
            .catch((e) => {
                snackbar.displayMessage('Erro ao carregar permissões!', 'error');
                console.error(e)
            });
    }, [groupId]);
    /**
     * Função para buscar permissões da API e atualizar o estado com as permissões obtidas.
     * Utiliza a função de callback `useCallback` para memorizar a função e evitar recriações desnecessárias.
     * 
     * @function getPermissoes
     * @returns {void}
     */
    const getPermissoes = useCallback(() => {
        let permissoes = [];
        api
            .get('/api/permission?order_by=name:asc')
            .then(({ data }) => {
                data.forEach((x) => {
                    permissoes.push({ value: x.id, label: x.name });
                });
                setPermissoes(permissoes);
                getGroupPermissoes();
            })
            .catch((e) => console.error(e));
    }, [getGroupPermissoes]);
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        getPermissoes();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Modal
            ref={ref}
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            transparent
        >
            <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div></div>
                    <ModalTitulo> Permissões </ModalTitulo>
                    <label className="btn-outline text-right pointer" onClick={() => closeModal()}>
                        <CgClose size={22} className="poiner" color="red" />
                    </label>
                </div>
            </nav>
            <br></br>
            <div className='col-12 p-0 m-0'>
                <Form ref={formRef} onSubmit={handleSubmit} >
                    {!loading ? (
                        <PickList
                            ref={pickListRef}
                            className={'w-100per'}
                            todosDefault={permissoes}
                            selecionadosDefault={permissaoSelecionada}
                            onSelectedChange={setPermissaoAlterada}
                        />
                    ) : (
                        <ClipLoader color={color} css={override} loading={loading} size={50} />
                    )}
                    <div className='w-100per d-flex justify-content-end pe-2 pb-2'>
                        <div className='w-300px'>
                            <Button type='submit'>Atualizar Lista de Permissões</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};

export default forwardRef(AssocPermissaoModal);