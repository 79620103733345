import { css } from 'styled-components';
import styled from 'styled-components';

export const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;

        &:hover {
            cursor: pointer;
}`;

export const ClearButton = styled.button`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: '#7441BF';
        border-width: 0
}`;

export const TdStyle = {
        padding: '10px',
        marginRight: '10px',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 3px 0px silver'
};

export const ThStyle = {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'left',
};

export const ButtonStyle = {
        backgroundColor: '#7340BD',
        color: '#FFFFFF',
       
        border: 'none',
        padding: '2px 22px',
        borderRadius: 8,
        marginRight: 5
};
export const DivButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
};

export const TableScroll = {
        overflowY: 'scroll',
        overflowX: 'auto',
        width: '100%',
        height: '20%'
};

export const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;