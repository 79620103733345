import React, { useState, useEffect, useRef } from 'react';
import AtendimentoModal from '../AtendimentoModal';
import { Form } from '@unform/web';
import { AtendimentoReposicaoProvider } from '../../../../context/atendimentoReposicao';
import api from '../../../../services/api';
import moment from 'moment';
import SolicitacaoDeAlteracaoModal from '../../../../components/Modais/Terapeuta/SolicitacaoModal';
import { CgClose } from 'react-icons/cg';
import { GoClock } from 'react-icons/go';
import { FaCheck } from 'react-icons/fa';

const TableStatusDiario = ({ atendimentos, controleDiarioId, horarios, atualizaAtendimentos = () => { }, handleChangeSugestao = (e, atendimentoId, status) => { } }) => {
    //#region VARIÁVEIS
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
    const [atendimentoModalSelecionado, setAtendimentoModalSelecionado] = useState(null);
    const [statusAtendimento, setStatusAtendimento] = useState(null);
    const solicitacaoRef = useRef();
    //#endregion
    //#region HANDLEs
    function handleOpenModalAtendimento(atendimento) {
        if (solicitacaoRef.current) {
            solicitacaoRef.current.open(atendimento);
        }
    }
    //#endregion
    //#region FUNCOEs
    const getStatusAtendimento = () => {
        api.get('/api/status-atendimento')
            .then(response => {
                let statusList = response.data;
                statusList = statusList.map(status => {
                    return { value: status.id, label: status.status };
                });
                setStatusAtendimento(statusList);
            });
    };
    const confereHorario = (hora) => {
        let time1 = moment().format('HH:mm').split(':');
        let time2 = hora.split(':');

        let decimalTime1 = Number(time1[0]) * 60 + time1[1];
        let decimalTime2 = Number(time2[0]) * 60 + time2[1];

        const differenceInMinutes = decimalTime1 - decimalTime2;
        return (differenceInMinutes < 20);
    };
    //#endregion
    //#region USE EFFECTs
    useEffect(() => {
        getStatusAtendimento();
    }, []);
    //#endregion
    //#region HTML
    return (
        <AtendimentoReposicaoProvider>
            {(controleDiarioId !== null && controleDiarioId !== undefined)
                ? <div className='col-lg-8 col-sm-12 m-auto m-0 no-box-shadow border-none' id='container-accordion'
                    style={{
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none',
                    }}>
                    {horarios?.map((horario_atendimento) => {
                        const atendimentosHora = atendimentos.filter(atendimento => {
                            return atendimento.horarios_atendimento_id === horario_atendimento.id;
                        });
                        const horario = moment.utc(horario_atendimento.horario).format('HH:mm');
                        const testeConfereHorario = confereHorario(horario);
                        const passou = testeConfereHorario || moment().isAfter(moment().set({ hour: 19, minute: 30 }));
                        if (atendimentosHora.length > 0) {
                            return (
                                <div className="card w-100 m-0">
                                    {atendimentosHora.map((atendimento, index) => {
                                        const atendimentoStyle = atendimento
                                            ? {
                                                backgroundColor: atendimento.cor,
                                                cursor: 'pointer',
                                                color: 'black',
                                                height: '80px'
                                            }
                                            : {};
                                        return (
                                            <div className="card-body w-100 p-0">
                                                <button className={'card-title w-100 border-bottom ms-0 me-0 p-1 font-18px d-flex w-100 justify-content-between align-items-center bg-roxo font-branca'} type="button" onClick={() => document.getElementById(`hora-${horario}id-${atendimento.id}-index`).classList.toggle('some')}>
                                                    <p className='d-flex align-items-center flex-wrap justify-content-start p-0 m-0 col-4'>
                                                        {index === 0 && <> <GoClock style={{ marginBottom: '2px', color: passou ? 'red' : 'lightgreen' }} /> {horario}</>}
                                                    </p>
                                                    <p className='p-0 m-0 col-4'> {atendimento.paciente_identificador} </p>
                                                    <p className='p-0 m-0 d-flex flex-row align-items-center justify-content-end col-4'>
                                                        <p className='p-0 m-0' style={{ width: '15px', height: '15px', borderRadius: '10px', backgroundColor: atendimento.cor, }}></p>
                                                        {(atendimento.liberado)
                                                            ? <FaCheck className='success p-0 m-0 ms-1' />
                                                            : <CgClose className='font-vermelho p-0 m-0 ms-1' />
                                                        }
                                                    </p>
                                                </button>
                                                <div id={`hora-${horario}id-${atendimento.id}-index`} className='some w-100'>
                                                    <div className='w-100per d-flex flex-wrap p-0 m-0'>
                                                        <div className='w-70per font-12px p-2 pt-0 d-flex flex-col flex-wrap align-items-between'>
                                                            <p className='p-0 m-0'>Horario: {horario}</p>
                                                            <p className='p-0 m-0'>Paciente: {atendimento.paciente_nome}</p>
                                                            <p className='p-0 m-0'>Status do Atendimento: {atendimento.sugestao_recepcao_name ? atendimento.sugestao_recepcao_name : '-'}</p>
                                                            <p className='p-0 m-0'>Tipo do Atendimento: {atendimento.tipo_atendimento ? atendimento.tipo_atendimento : '-'}</p>
                                                        </div>
                                                        <div className='w-30per d-flex justify-content-end p-0 m-0 pe-2'>
                                                            <button className='btn-padrao font-16px' style={atendimentoStyle} onClick={() => handleOpenModalAtendimento(atendimento)}>Alteração</button>
                                                        </div>
                                                        <div className='w-100per font-12px p-2 pt-0'>
                                                            Terapia/Especialidade: {atendimento.terapia} - {atendimento.especialidade}<br></br>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="card-text p-2 pt-0 m-0 font-12px d-flex justify-content-between align-items-center" style={{ height: '33px' }}>
                                                    <p className='p-0 m-0 col-4'>Sala/Estação: {atendimento.sala}/{atendimento.estacao}</p>
                                                    <p className='p-0 m-0 col-4'>{(atendimento && !passou) &&
                                                        <select className='p-1 w-100'
                                                            id='status_terapeuta'
                                                            name='status_terapeuta'
                                                            style={{ borderRadius: '5px' }}
                                                            defaultValue={atendimento?.sugestao_terapeuta}
                                                            onChange={(e) => handleChangeSugestao(e, atendimento.id, atendimento.sugestao_recepcao)}
                                                        >
                                                            <option value=''>Selecione</option>
                                                            {statusAtendimento
                                                                ?.filter(filter => (filter.value === 1 || filter.value === 2 || filter.value === 3 || filter.value === 4 || filter.value === 5 || filter.value === 5))
                                                                ?.map((e) => {
                                                                    if (atendimento.liberado === 1) {
                                                                        return (<option value={e.value} selected={atendimento?.sugestao_terapeuta === e.value}>{e.label}</option>);
                                                                    } else {
                                                                        if (e.value === 5 || e.value === 4 || e.value === 2 || e.value === 3) {
                                                                            return (<option value={e.value} selected={atendimento?.sugestao_terapeuta === e.value}>{e.label}</option>);
                                                                        }
                                                                    }
                                                                })}
                                                        </select>
                                                    }</p>
                                                    <p className='p-0 m-0 col-4 text-center'>Status: {atendimento.sugestao_recepcao_name ? atendimento.sugestao_recepcao_name : '-'}</p>
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        } else {
                            const atendimentoStyle = {
                                backgroundColor: 'rgb(188, 188, 188)',
                                cursor: 'pointer',
                                color: 'black',
                                height: '80px'
                            };
                            return (/* Espaço sem atendimento */
                                <div className="card w-100 m-0">
                                    <div className="card-body w-100 p-0">
                                        <button
                                            className={'card-title w-100 border-bottom ms-0 me-0 p-1 font-18px d-flex w-100 justify-content-between align-items-center bg-roxo font-branca'}
                                            style={{ height: '36.9px' }}
                                            type="button" onClick={() => document.getElementById(`hora-${horario}id-index`).classList.toggle('some')}>
                                            <p className='d-flex align-items-center flex-wrap justify-content-start p-0 m-0'><GoClock style={{ marginBottom: '2px', color: passou ? 'red' : 'lightgreen' }} /> {horario}</p>
                                            <p className='p-0 m-0 d-flex flex-row align-items-center'> </p>
                                        </button>
                                        <div id={`hora-${horario}id-index`} className='some w-100'>
                                            <div className='w-100 d-flex flex-row p-0 m-0'>
                                                <div className='font-12px p-2 pt-0' style={{ width: '70%' }}> </div>
                                                <div className='w-30 d-flex justify-content-end p-0 m-0 pe-2' style={{ width: '30%' }}>
                                                    <button className='btn-padrao font-16px' style={atendimentoStyle} onClick={() => handleOpenModalAtendimento({ horarios_atendimento_id: horario_atendimento.id, controle_diario_id: controleDiarioId })}>
                                                        Alteração
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="card-text p-2 pt-0 m-0 font-12px d-flex justify-content-between align-items-center" style={{ height: '33px' }}>
                                            <p className='p-0 m-0 col-4'></p>
                                            <p className='p-0 m-0 col-4'></p>
                                            <p className='p-0 m-0 col-4 text-center'></p>
                                        </p>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                : <p className='w-100 text-center'>O dia ainda não foi carregado, aguarde...</p>
            }
            {isModalAtendimentoOpen &&
                <AtendimentoModal
                    atendimentoId={atendimentoModalSelecionado.id}
                    onClose={() => setIsModalAtendimentoOpen(false)}
                    isAtendimentoEditavel={atendimentoModalSelecionado.editavel}
                />
            }
            <SolicitacaoDeAlteracaoModal ref={solicitacaoRef} controle_diario_id={controleDiarioId} onClose={atualizaAtendimentos} />
        </AtendimentoReposicaoProvider>
    );
    //#endregion
};

export default TableStatusDiario;