import styled from 'styled-components';

export const TableComporovantePresencial = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    border: 1px solid #000;
    th {
        border: 1px solid #000;
        padding: 5px;
        background-color: #f2f2f2;
        b {
            font-size: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
    td {
        border: 1px solid #000;
        padding: 5px;
        b {
            font-size: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
`;