import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 15vh;
`;

export const CelulaCabecalho = styled.th`
  background-color: #7441BF;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 2px;
  border-style: solid;
  font-size: 12px;
  text-align: center;
`;

export const LinhaTabela = styled.td`
  background-color: #fff;
  padding: 5px;
  width: 100px;
  height: 80px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 3px;
  border-style: solid;
  font-size: 14px;
`;

export const LinhaTabelaVazia = styled.div`
  background-color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-width: 3px;
  border-style: solid;
  font-size: 14px;
`;
