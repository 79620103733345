import api from './api';

class TipoAtendimentoService {
    static index = () => {
        return api.get('api/tipo-atendimento');
    };

    static store = (obj) => {
        /* exemplo
        {"tipo_atendimento":"eos"}
        */

        return api.post('api/tipo-atendimento', obj);
    };

    static show = (id) => {
        return api.get(`api/tipo-atendimento/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"tipo_atendimento":"placeat"}
        */

        return api.put(`api/tipo-atendimento/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/tipo-atendimento/${id}`);
    };
}

export default TipoAtendimentoService;
