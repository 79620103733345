import React, { forwardRef, useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from '../../Input';
import TipoAtendimentoService from '../../../services/TipoAtendimentoService';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const TipoAtendimentoModal = ({ modalIsOpen, setIsOpen, callback, modalData }, ref) => {
    let subtitle;

    const [loading, setLoading] = useState();

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(data) {
        try {
            setLoading(true);
            if (modalData === null) {
                await TipoAtendimentoService.store(data);
                snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                setIsOpen(false);
                callback();
            } else {
                await TipoAtendimentoService.update(data, modalData.id);
                snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                setIsOpen(false);
                callback();
            }
        } catch (error) {
            Swal.fire('Houve um problema ao cadastrar!', '', 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>Tipo de atendimento</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }} >
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 450 }}>
                                <Input name={'tipo_atendimento'} title={'Tipo de atendimento'} required={'required'}
                                    defaultValue={modalData === null ? '' : modalData.tipo_atendimento} />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : modalData === null
                                    ? 'Adicionar'
                                    : 'Alterar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(TipoAtendimentoModal);
