import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 20px;
    min-width: 20%;
    boxShadow: 0 0 10px 0px silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: #D4D6D8 1px solid;
`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div`
    background: silver; 
    grid-area: name;
    padding: 40px 0px;
`;
export const ContainerCards = styled.div`
    background: red; 
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
`;
export const ContainerSolicitacoes = styled.div`
    background: black;
    grid-area: footer;
`;

export const ContainerDiv = {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '20px',
    minWidth: '20%',
    boxShadow: '0 0 10px 0px silver',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '#D4D6D8 1px solid',
    height: '200px'
};

export const BoxStyle1 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#27DABE',
    height: '110px',
    width: '110px',
    borderRadius: '20%',
    wordWrap: 'break-word',
    textAlign: 'center',
    marginRight: 20,
    padding: 10
};

export const BoxStyle2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#F8A314', height: '110px', width: '110px', borderRadius: '20%',
    wordWrap: 'break-word',
    textAlign: 'center',
    marginRight: 20,
    padding: 10
};

export const BoxStyle3 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#F76397', height: '110px', width: '110px', borderRadius: '20%',
    wordWrap: 'break-word',
    textAlign: 'center',
    marginRight: 20,
    padding: 10
};

export const BoxStyle4 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#3EBFE5', height: '110px', width: '110px', borderRadius: '20%',
    wordWrap: 'break-word',
    textAlign: 'center',
    padding: 10
};