import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineBell, AiOutlineForm, AiOutlineUser } from 'react-icons/ai';
import { Form } from '@unform/web';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import Select from '../../../../components/Select';
import ModalDocumento from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalDocumento';
import { Link, useNavigate, useParams } from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import { TableDocumentosGerais } from '../../../RecepcaoDashboard/Terapeutas/TableDocumentosGerais';
import { TableCursos } from '../../../RecepcaoDashboard/Terapeutas/TableCursos';
import { TableHistoricoDeFerias } from '../../../RecepcaoDashboard/Terapeutas/TableHistoricoDeFerias';
import api from '../../../../services/api';
import Cep from '../../../../services/cep';
import HorariosAtendimentoService from '../../../../services/HorariosAtendimentoService';
import moment from 'moment';
import { TableDocumentosAssinadosTerapeuta } from '../../../RecepcaoDashboard/Terapeutas/TableDocumentosAssinadosTerapeuta';
import { TableTerapias } from '../../../RecepcaoDashboard/Terapeutas/TableTerapias';
import { TableEspecialidades } from '../../../RecepcaoDashboard/Terapeutas/TableEspecialidades';
import ModalDiplomas from '../../../RecepcaoDashboard/Terapeutas/ModalDiplomas';
import UsuarioLogado from '../../../../components/UsuarioLogado';
import TerapiumService from '../../../../services/TerapiumService';
import EspecialidadeService from '../../../../services/EspecialidadeService';
import TerapeutaEspecialidadeService from '../../../../services/TerapeutaTerapiaService';
import TerapeutaTerapiaService from '../../../../services/TerapeutaTerapiaService';
import ModalContatos from '../../../RecepcaoDashboard/Terapeutas/ModalContatos';
import { TableContatos } from '../../../RecepcaoDashboard/Terapeutas/TableContatos';
import ModalFerias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalFerias';
import { TableDiplomas } from '../../../RecepcaoDashboard/Terapeutas/TableDiplomas';
import Swal from 'sweetalert2';
import TerapeutaDiplomaService from '../../../../services/TerapeutaDiplomaService';
import { TableCertificacoes } from '../../../RecepcaoDashboard/Terapeutas/TableCertificacoes';
import TerapeutaCertificadoService from '../../../../services/TerapeutaCertificadosService';
import Recepcao from '../../../../layouts/Recepcao';
import { AddButtonStyle, buttonBack, buttonDiplomas, buttonStyle, buttonDefault, leftContentDivStyle, Container, MainDivStyle, subHeader } from '../../../AdminDashboard/Terapeutas/Novo/styles';
import { StatusAfastado, StatusAtivo, StatusFerias, StatusInativo } from '../../../AdminDashboard/Pacientes/Perfil/styles';
import { useSnackbar } from '../../../../context/snackbar';
import ModalContratosDeCursos from '../ModalContratosDeCursos';
import { TableMedicamentosTerapeuta } from '../TableMedicamentosTerapeuta';
import ModalMedicamentosTerapeuta from '../ModalMedicamentosTerapeuta';

moment.locale('pt-br');

export default function PerfilTerapeutaAdmin() {

    const formRef = useRef(null);

    const [isModalDiplomasVisible, setIsModalDiplomasVisible] = useState(false);
    const [modalCursos, setModalCursos] = useState(false);

    const [terapeuta, setTerapeuta] = useState(null);
    const [terapeutaId, setTerapeutaId] = useState(null);

    const [medicamentos, setMedicamentos] = useState([]);
    const [medicamentosData, setMedicamentosData] = useState(null);

    const [terapias, setTerapias] = useState([]);
    const [terapiasFiltradas, setTerapiasFiltradas] = useState([]);
    const [minhasTerapias, setMinhasTerapias] = useState([]);

    const [especialidades, setEspecialidades] = useState(null);
    const [especialidadesFiltradas, setEspecialidadesFiltradas] = useState(null);
    const [minhasEspecialidades, setMinhasEspecialidades] = useState([]);

    const [diplomas, setDiplomas] = useState([]);
    const [meusDiplomas, setMeusDiplomas] = useState([]);
    const [diplomasFiltrados, setDiplomasFiltrado] = useState(null);

    const [certificacoes, setCertificacoes] = useState([]);
    const [minhasCertificacoes, setMinhasCertificacoes] = useState([]);
    const [certificacoesFiltradas, setCertificacoesFiltradas] = useState(null);

    const [Horarios, setHorarios] = useState([]);
    const [dataEntrada, setDataEntrada] = useState(null);
    const [HorariosSelecionados, setHorariosSelecionados] = useState([]);
    const [HorariosSelecionadosOriginais, setHorariosSelecionadosOriginais] = useState([]);
    const [documentosAssinados, setDocumentosAssinados] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [contatos, setContatos] = useState([]);
    const [modalContatosData, setModalContatosData] = useState(null);
    const [contratosCursos, setContratosCursos] = useState([]);
    const [imagemPerfil, setImagemPerfil] = useState(null);

    const [feriasList, setFeriasList] = useState([]);
    const [feriasPaciente, setFeriasPaciente] = useState([]);

    const [statusSelectBox, setStatusSelectBox] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [statusColor, setStatusColor] = useState();

    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { id } = useParams();
    let day = new Date(moment().format('MM/DD/YYYY'));

    const diplomasRef = useRef(null);
    const documentoRef = useRef(null);
    const contatosRef = useRef(null);
    const modalFeriasRef = useRef(null);
    const contratosRef = useRef(null);
    const medicamentosRef = useRef(null);

    const handleOpenModalDiplomas = () => {
        setIsModalDiplomasVisible(true);
    };

    const NavigatePage = (route) => {
        navigate(route);
    };

    const handleEditContatos = useCallback(() => {
        contatosRef.current.openModal();
    }, []);

    const handleOpenContatos = useCallback(() => {
        contatosRef.current.openModal();
        setModalContatosData(null);
    }, []);

    const handleOpenContratos = useCallback(() => {
        contratosRef.current.openModal();
    }, []);

    const handleOpenModalDocumento = useCallback(() => {
        documentoRef.current.openModal();
    }, []);

    const handleOpenModalFerias = useCallback(() => {
        modalFeriasRef.current.openModal();
    }, []);

    const handleOpenMedicamentos = useCallback(() => {
        medicamentosRef.current.openModal();
    }, []);

    const getDefaultStatus = (statusTerapeuta) => {
        if (!terapeuta) return;
        const status = statusTerapeuta.filter(data => {
            return data.label === terapeuta.status;
        })[0];
        setStatusSelecionado(status);
    };

    const handleChangePerfil = (e) => {
        var extPermitidas = ['jpg','jpeg', 'png', 'gif', 'bmp'];
        let inputImg = document.querySelector('#image');
        let formData = new FormData();
        const file = e.target.files[0];
        var size = e.target.files[0].size;
        if (extPermitidas.find((ext) => { return file.name.split('.').pop() === ext; }) === undefined) {
            Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + 'Permitidas: ' + extPermitidas, '', 'warning');
            inputImg.value = '';
        } else {
            if (size < 5242880) { //5MB
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener('load', (e) => {
                        const readerTarget = e.target;
                        inputImg.src = readerTarget.result;
                    });
                    reader.readAsDataURL(file);
                    formData.append('foto_perfil', e.target.files[0]);
                    setImagemPerfil(formData);

                } else {
                    Swal.fire('Houve um problema ao enviar o arquivo', '', 'warning');
                }

            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 5MB)', '', 'warning');
                inputImg.value = '';
            }
            e.preventDefault();
        }
    };

    const diasUteis = [
        { value: '1', label: 'Segunda-feira' },
        { value: '2', label: 'Terça-feira' },
        { value: '3', label: 'Quarta-feira' },
        { value: '4', label: 'Quinta-feira' },
        { value: '5', label: 'Sexta-feira' },
    ];

    const getHorariosPadrao = async () => {
        const { data } = await HorariosAtendimentoService.index();
        setHorarios(data);
    };

    //===============================
    // TERAPIAS
    //===============================

    const filterOutMinhasTerapias = (listaTerapias, listaMinhasTerapias) => {
        const idsMinhasTerapias = listaMinhasTerapias.map((t) => t.terapia_id);

        const terapiasFiltradas = listaTerapias.filter((item) => {
            return !idsMinhasTerapias.includes(item.id);
        });

        return terapiasFiltradas;
    };

    const handleRemoveTerapia = (terapia) => {
        if (terapeuta !== undefined || terapeuta !== null) {
            const data = {
                terapeutaterapium: terapia.id,
            };
            TerapeutaTerapiaService.destroy(data, terapia.id);
            loadTerapeuta();
        } else {
            setMinhasTerapias((current) =>
                current.filter((e) => e.terapia_id !== terapia.terapia_id)
            );
        }
    };

    const handleAddNewTerapia = async (terapia) => {
        if (terapeuta && terapeuta.id) {
            try {
                await TerapeutaTerapiaService.store(terapia);
                loadTerapeuta();
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        } else {
            setMinhasTerapias([...minhasTerapias, terapia]);
        }
    };

    const submitAllNewTerapias = async (terapeutaId) => {
        const novasTerapias = minhasTerapias.filter((e) => !e.terapeuta_id);

        for (const e of novasTerapias) {
            try {
                e['terapeuta_id'] = terapeutaId;

                await TerapeutaTerapiaService.store(e);
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        }
        loadTerapeuta();
    };

    //===============================
    // ESPECIALIDADES
    //===============================

    const filterOutMinhasEspecialidades = (listaEspecialidades, listaMinhasEspecialidades) => {
        const idsMinhasEspecialidades = listaMinhasEspecialidades.map((t) => t.especialidade_id);

        const especialidadesFiltradas = listaEspecialidades.filter((item) => {
            return !idsMinhasEspecialidades.includes(item.id);
        });

        return especialidadesFiltradas;
    };

    const submitAllNewEspecialidades = async (terapeutaId) => {
        const novasEspecialidades = minhasEspecialidades.filter((e) => !e.terapeuta_id);

        for (const e of novasEspecialidades) {
            try {
                e['terapeuta_id'] = terapeutaId;

                await TerapeutaEspecialidadeService.store(e);
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        }
        loadTerapeuta();
    };

    const handleAddNewEspecialidade = (especialidade) => {
        if (terapeuta && terapeuta.id) {
            try {
                TerapeutaEspecialidadeService.store(especialidade);
                loadTerapeuta();
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            };
        } else {
            setMinhasEspecialidades([...minhasEspecialidades, especialidade]);
        }
    };

    const handleRemoveEspecialidade = (especialidade) => {
        if (terapeuta && terapeuta.id) {
            const data = {
                terapeutaespecialidade: especialidade.id,
            };
            TerapeutaEspecialidadeService.destroy(data, especialidade.id);
            loadTerapeuta();
        } else {
            setMinhasEspecialidades((current) =>
                current.filter((e) => e.especialidade_id !== especialidade.especialidade_id));
        };
    };

    //Especialidades tava aqui :)

    //Diplomas
    //===============================

    const filterOutMeusDiplomas = (listaDiplomas, listaMeusDiplomas) => {
        const idMeusDiplomas = listaMeusDiplomas.map((t) => t.id);

        const diplomasFiltrados = listaDiplomas.filter((item) => {
            return !idMeusDiplomas.includes(item.id);
        });
        return diplomasFiltrados;
    };

    const handleAddNewDiploma = (diploma) => {
        if (terapeuta && terapeuta.id) {
            try {
                TerapeutaDiplomaService.store(diploma);
                loadTerapeuta();
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        } else {
            setMeusDiplomas([...meusDiplomas, diploma]);
        };
    };

    const submitAllDiplomas = async (terapeutaId) => {
        const novosDiplomas = meusDiplomas.filter((e) => !e.terapeuta_id);

        for (const e of novosDiplomas) {
            try {
                e['terapeuta_id'] = terapeutaId;

                await TerapeutaDiplomaService.store(e);
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        }
        loadTerapeuta();
    };

    const handleRemoveDiploma = (diploma) => {
        if (terapeuta && terapeuta.id) {
            const data = {
                diplomaterapeuta: diploma.id,
            };
            TerapeutaDiplomaService.destroy(data, diploma.id);
            loadTerapeuta();
        } else {
            setMeusDiplomas((current) =>
                current.filter((e) => e.diploma.id !== diploma.id)
            );
        };
    };

    //Certificações
    //================================

    const filterOutMeusCertificados = (listaCertificados, listaMeusCertificados) => {
        const idMeusCertificados = listaMeusCertificados.map((t) => t.id);

        const certificadosFiltrados = listaCertificados.filter((item) => {
            return !idMeusCertificados.includes(item.id);
        });
        return certificadosFiltrados;
    };

    const handleNewCertificacao = (certificado) => {
        if (terapeuta && terapeuta.id) {
            try {
                TerapeutaCertificadoService.store(certificado);
                loadTerapeuta();
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        } else {
            setMinhasCertificacoes([...minhasCertificacoes, certificado]);
        };
    };

    const submitAllCertificacoes = async (terapeutaId) => {
        const novosCertificados = minhasCertificacoes.filter((e) => !e.terapeuta_id);

        for (const e of novosCertificados) {
            try {
                e['terapeuta_id'] = terapeutaId;

                await TerapeutaCertificadoService.store(e);
            } catch (error) {
                Swal.fire('Houve um problema ao cadastrar!', '', 'error');
                console.error(error);
            }
        }
        loadTerapeuta();
    };

    const handleRemoveCertificado = (certificado) => {
        if (terapeuta && terapeuta.id) {
            const data = {
                certificadoterapeuta: certificado.id,
            };
            TerapeutaCertificadoService.destroy(data, certificado.id);
            loadTerapeuta();
        } else {
            setMinhasCertificacoes((current) =>
                current.filter((e) => e.certificado.id !== certificado.id)
            );
        };
    };

    //================================

    const handleChangeCheckBox = (e) => {

        const horariosCheckBox = document.getElementsByName('check_input');
        if (e.target.checked === true) {
            var i = 0;
            while (i < horariosCheckBox.length) {
                horariosCheckBox[i].checked = e.target.checked;
                i++;
            };
        }
        if (e.target.checked === false) {
            var i = 0;
            while (i < horariosCheckBox.length) {
                horariosCheckBox[i].checked = e.target.checked;
                i++;
            };
            i = 0;
        }
    };

    const getHorariosSelecionadosByDia = (dia) => {
        return HorariosSelecionados.filter((horario) => {
            return Number(horario.dia_semana) === Number(dia);
        });
    };

    const geraLinhasDias = () => {
        return diasUteis.map((dia, index) => {
            return (
                <tr key={index + '-dia-' + dia.value}>
                    <td>{dia.label}:</td>
                    <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {geraLinhasHorarios(dia)}
                    </td>
                </tr>
            );
        });
    };

    const isHorarioSelecionado = (dia, hora) => {
        const arrayHorarioSelecionado = HorariosSelecionados.find(horario => Number(horario.dia_semana) === Number(dia) && moment.utc(horario.horario).format('HH:mm') === hora);
        return arrayHorarioSelecionado ? true : false;
    };

    const geraLinhasHorarios = (dia) => {
        return Horarios.filter((x) => parseInt(x.dia_semana) === parseInt(dia.value)).map((horario, index) => {
            let horaExtenso = moment.utc(horario.horario).format('HH:mm');
            const key = dia + '-' + horario.horario;
            return (
                <label key={key}>
                    <input type={'checkbox'} name={'check_input'} checked={isHorarioSelecionado(dia.value, horaExtenso)}
                        onChange={(e) => handleChangeHorariosSelecionados(e)} data-dia={dia.value}
                        data-hora={horario.horario} />
                    {horaExtenso}
                </label>);
        });
    };

    const handleChangeHorariosSelecionados = (e) => {
        let terapeutaDisponibilidadeObj = {
            id: '',
            terapeuta_id: id ? Number(id) : null,
            horario: '',
            dia_semana: '',
        };
        terapeutaDisponibilidadeObj.horario = e.target.dataset.hora;
        terapeutaDisponibilidadeObj.dia_semana = Number(e.target.dataset.dia);
        if (e.target.checked) {
            setHorariosSelecionados([...HorariosSelecionados, terapeutaDisponibilidadeObj]);
        } else {
            setHorariosSelecionados(HorariosSelecionados.filter((horarioSelecionado) => {
                return horarioSelecionado.horario !== terapeutaDisponibilidadeObj.horario
                    || Number(horarioSelecionado.dia_semana) !== Number(terapeutaDisponibilidadeObj.dia_semana);
            }));
        }
    };

    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro || '';
        document.getElementById('bairro').value = data.bairro || '';
        document.getElementById('estado').value = data.uf || '';
        document.getElementById('cidade').value = data.localidade || '';
    };


    async function handleSubmit(data) {
        try {
            const dataTerapeuta = {
                'numero': data.numero,
                'nome': data.nome,
                'nome_curto': data.nome_curto,
                'email': data.email,
                'nascimento': data.nascimento.split('/').reverse().join('-') + ' 00:00:00',
                'cpf': data.cpf.replaceAll('.', '').replace('-', ''),
                'rg': data.rg,
                'telefone1': data.telefone1,
                'telefone2': data.telefone2,
                'telefone3': data.telefone3,
                'endereco': data.endereco,
                'bairro': data.bairro,
                'cidade': data.cidade,
                'estado': data.estado,
                'status': statusSelecionado.label,
                'complemento': data.complemento,
                'cep': data.cep,
                'caminho_curriculo': '',
                'senha': data.senha,
            };

            if (id) {
                atualizaTerapeuta(dataTerapeuta);
            } else {
                adicionarNovoTerapeuta(dataTerapeuta);
            }

            navigate('/dashboard/recepcao/terapeutas');
        } catch (error) {
            console.error(error);
        }
    }

    async function adicionarNovoTerapeuta(data) {
        api.post('api/terapeuta', data)
            .then(({ data }) => {
                const terapeutaId = data.id;
                const horariosComId = HorariosSelecionados.map((horario) => {
                    return {
                        'dia_semana': horario.dia_semana,
                        'horario': horario.horario,
                        'terapeuta_id': terapeutaId,
                    };
                });

                horariosComId.map(async (horario) => {
                    api.post('api/terapeuta-disponibilidade', horario);
                });

                if (contatos.length > 0) {
                    contatos.forEach((contato) => {
                        contato.terapeuta_id = terapeutaId.data.id;
                        api.post('api/terapeuta-contatos').catch(() => {
                            alert('Atenção! Houve um erro ao tentar cadastrar o contato, por favor, edite e realize o cadastro do contato novamente');
                        });
                    });
                }
                if (feriasList.length > 0) {
                    feriasList.forEach((ferias) => {
                        ferias.terapeuta_id = terapeutaId.data.id;
                        api.post('api/terapeuta-ferias');
                    });
                }
                if (documentos.length > 0) {
                    documentos.forEach((documento) => {
                        documento.id_terapeuta = terapeutaId.data.id;
                        api.post('api/terapeuta-documentos');
                    });
                }
                submitAllNewEspecialidades(terapeutaId);
                submitAllNewTerapias(terapeutaId);
                submitAllCertificacoes(terapeutaId);
                submitAllDiplomas(terapeutaId);
            });
    }

    async function atualizaTerapeuta(data) {
        api.put(`api/terapeuta/${id}`, data)
            .then(({ data }) => {
                const terapeutaId = data.id;
                const horariosInseridosNovos = HorariosSelecionados.filter((horario) => {
                    return horario.id === '';
                });
                const horariosOriginaisDeletados = HorariosSelecionadosOriginais.filter((horarioOriginal) => {
                    return !HorariosSelecionados.find((horarioSelecionado) => {
                        return horarioSelecionado.id === horarioOriginal.id;
                    });
                });

                const arrayIdsDisponibilidadeParaDeletar = horariosOriginaisDeletados.map((horarioParaDeletar) => {
                    return horarioParaDeletar.id;
                });

                if (arrayIdsDisponibilidadeParaDeletar.length > 0) {
                    api.delete(`api/terapeuta-disponibilidade/from-ids?ids=${arrayIdsDisponibilidadeParaDeletar.join(',')}`);
                }

                horariosInseridosNovos.map(async (horario) => {
                    api.post('api/terapeuta-disponibilidade', horario);
                });

                if (contatos.length > 0) {
                    contatos.forEach((contato) => {
                        contato.terapeuta_id = terapeutaId.data.id;
                        api.post('api/terapeuta-contatos').catch(() => {
                            alert('Atenção! Houve um erro ao tentar cadastrar o responsável, por favor, edite o paciente e realize o cadastro do responsável novamente');
                        });
                    });
                }

                if (feriasList.length > 0) {
                    feriasList.forEach((ferias) => {
                        ferias.terapeuta_id = terapeutaId.data.id;
                        api.post('api/terapeuta-ferias');
                    });
                }

                if (documentos.length > 0) {
                    documentos.forEach((documento) => {
                        documento.id_terapeuta = terapeutaId.data.id;
                        api.post('api/terapeuta-documentos');
                    });
                }
            });
    }

    const loadTerapias = async () => {
        TerapiumService.index().then(({ data }) => {
            setTerapias(data);
        });
    };

    const loadEspecialidades = async () => {
        EspecialidadeService.index().then(({ data }) => {
            setEspecialidades(data);
        });
    };

    const loadTerapeuta = async () => {
        if (id) {
            const withQuery = 'terapeuta_terapia.terapium,terapeuta_especialidades.especialidade,terapeuta_diplomas';

            let { data } = await api.get(`api/terapeuta/${id}?with=${withQuery}`);

            data = {
                ...data,
                nascimento: moment(data.nascimento).format('YYYY-MM-DD'),
                cpf: data.cpf ? data.cpf.toString() : '',
                cep: data.cep ? data.cep.toString() : ''
            };
            formRef.current.setData(data);

            setTerapeuta(data);
            setTerapeutaId(data.id);

            if (data.terapeuta_terapia) {
                setMinhasTerapias(data.terapeuta_terapia);
            }

            if (data.terapeuta_diplomas) {
                setMeusDiplomas(data.terapeuta_diplomas);
            }

            if (data.terapeuta_especialidades) {
                setMinhasEspecialidades(data.terapeuta_especialidades);
            }

            api.get(`/api/terapeuta-assinaturas?with=terapeutum,documentos_administrativo&filter[terapeuta_id]=${id}`)
                .then(({ data }) => setDocumentosAssinados(data));

            const response = await api.get(`api/terapeuta-disponibilidade?filter[terapeuta_id]=${id}`);
            setHorariosSelecionados(response.data);
            setHorariosSelecionadosOriginais(response.data);
        }
    };

    useEffect(() => {
        getDefaultStatus(statusSelectBox);
        if (terapeuta !== null) {
            if (terapeuta.status === 'Ativo') setStatusColor(StatusAtivo);
            else if (terapeuta.status === 'Afastado') setStatusColor(StatusAfastado);
            else if (terapeuta.status === 'Inativo') setStatusColor(StatusInativo);
            else if (terapeuta.status === 'Férias') setStatusColor(StatusFerias);
        }
        if (feriasPaciente.length > 0) {
            let index = feriasPaciente.length - 1;
            let data_ida = new Date(feriasPaciente[index].dt_ida);
            let data_volta = new Date(feriasPaciente[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(statusSelectBox[3]);
            } else {
                setStatusSelecionado(statusSelectBox[0]);
            }
        }
    }, [terapeuta]);

    useEffect(() => {
        setFeriasPaciente(feriasList);
        if (feriasList.length > 0) {
            let index = feriasList.length - 1;
            let data_ida = new Date(feriasList[index].dt_ida);
            let data_volta = new Date(feriasList[index].dt_volta);
            if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
                setStatusSelecionado(statusSelectBox[3]);
            } else {
                setStatusSelecionado(statusSelectBox[0]);
            }
        }
    }, [feriasList]);

    useEffect(() => {
        let statusList = [];
        statusList.push({
            label: 'Ativo',
            value: 'ativo'
        });
        statusList.push({
            label: 'Inativo',
            value: 'inativo'
        });
        statusList.push({
            label: 'Afastado',
            value: 'afastado'
        });
        statusList.push({
            label: 'Férias',
            value: 'ferias'
        });
        setStatusSelectBox(statusList);
        loadTerapeuta();
        loadTerapias();
        loadEspecialidades();
        getHorariosPadrao();
    }, []);

    useEffect(() => {
        setFeriasPaciente(feriasList);
        if (feriasList.length === 0) {
            loadTerapeuta();
        }
    }, [feriasList]);

    useEffect(() => {
        geraLinhasDias();
    }, [Horarios]);

    useEffect(() => {
        if (especialidades && minhasEspecialidades) {
            const especialidadesFiltradas = filterOutMinhasEspecialidades(especialidades, minhasEspecialidades);
            setEspecialidadesFiltradas(especialidadesFiltradas);
        }
    }, [especialidades, minhasEspecialidades]);

    useEffect(() => {
        if (terapias && minhasTerapias) {
            const terapiasFiltradas = filterOutMinhasTerapias(terapias, minhasTerapias);
            setTerapiasFiltradas(terapiasFiltradas);
        }
    }, [terapias, minhasTerapias]);

    useEffect(() => {
        if (certificacoes && minhasCertificacoes) {
            const certificadosFiltrados = filterOutMeusCertificados(certificacoes, minhasCertificacoes);
            setCertificacoesFiltradas(certificadosFiltrados);
        }
    }, [certificacoes, minhasCertificacoes]);

    useEffect(() => {
        if (diplomas && meusDiplomas) {
            const diplomasFiltrados = filterOutMeusDiplomas(diplomas, meusDiplomas);
            setDiplomasFiltrado(diplomasFiltrados);
        }
    }, [diplomas, meusDiplomas]);

    return (
        <Recepcao>
            <Container>
                <div style={MainDivStyle}>
                    <div>
                        <Link
                            style={{
                                textDecoration: 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 20,
                            }}
                            to="/dashboard/recepcao/terapeutas"><label style={{
                               
                                cursor: 'pointer',
                                color: '#000000'
                            }}>Terapeutas</label></Link>

                        <label style={{
                           
                            color: '#7340BD',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}>{terapeuta === null ? 'Cadastro de Novo Terapeuta' : 'Perfil do Terapeuta: '} {terapeuta === null ? '' : terapeuta.id}</label>

                    </div>
                    <div style={{ display: 'flex' }}>
                        <AiOutlineBell color="silver" size={20} />
                        <AiOutlineUser color="silver" size={20} />
                        <div>
                            <UsuarioLogado />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '0 70px' }}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: 50
                    }}>
                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, maxWidth: 170, padding: 10, marginBottom: 10 }}>Informações Complementares</button>
                            <label>Último atendimento em: 00/00/0001</label>
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img style={{ borderRadius: '50%' }} id='image' src={terapeuta === null ? user : terapeuta.foto_perfil === null ? user : imagemPerfil} height={140} width={140}></img>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <label htmlFor="user_img" style={{ cursor: 'pointer' }}>
                                    <AiOutlineForm style={{ marginRight: 3, marginBottom: 4 }} />
                                    Alterar foto de Perfil
                                    <input type="file" name='user_img' id='user_img' style={{ display: 'none', cursor: 'none' }}
                                        onChange={(e) => handleChangePerfil(e)} />
                                </label>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 6 }}>Cadastrar Guias</button>
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, padding: 6, marginBottom: 4 }}>Editar Cadastro</button> */}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                    backgroundColor: '#fff', borderRadius: 8, boxShadow: '0 0 3px 0px silver', padding: '2px 15px'
                                }}>
                                    {terapeuta === null ? '' : <div style={statusColor} />}
                                    <label style={{ margin: 5 }}>{terapeuta === null ? '' : terapeuta.status}</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex' }}>

                                <div style={{ width: '10%', paddingRight: 10 }}>
                                    <Input title="ID do Terapeuta:" name="numero" disabled="disabled" value={terapeuta === null ? '' : terapeuta.id}
                                        style={{ width: '10%' }} />
                                </div>
                                <div style={{ width: '10%', paddingRight: 10 }}>
                                    <Input title="N° do Crachá:" name="cracha" defaultValue={terapeuta === null ? '' : terapeuta.id}
                                        style={{ width: '10%' }} />
                                </div>
                                <div style={{ width: '50%', paddingRight: 10 }}>
                                    <Input title="Nome:" name="nome" required={'required'} />
                                </div>
                                <div style={{ width: '30%', paddingRight: 10 }}>
                                    <Input title="Nome Curto" name="nome_curto" />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>

                                <div style={{ width: '30%', paddingRight: 10 }}>
                                    <Select name={'status'} label={'Status:'} options={statusSelectBox}
                                        onChange={setStatusSelecionado} value={statusSelecionado} required={'required'} />
                                </div>
                                <div style={{ width: '20%', paddingRight: 10 }}>
                                    <Input
                                        title="Data de Nascimento:"
                                        name="nascimento"
                                        icon={null}
                                        type={'date'}
                                        disabled={false}
                                        required={'required'} />
                                </div>
                                <div style={{ width: '20%', paddingRight: 10 }}>
                                    <InputMask
                                        title="CPF"
                                        name="cpf"
                                        icon={null}
                                        maskFormat={'999.999.999-99'}
                                        disabled={false}
                                        required={'required'} />
                                </div>
                                <div style={{ width: '30%', paddingRight: 10 }}>
                                    <Input title="RG:" name="rg" required={'required'} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '40%', paddingRight: 10 }}>
                                    <Input title="E-mail:" name="email" required={'required'} />
                                </div>
                                <div style={{ width: '20%', paddingRight: 10 }}>
                                    <InputMask
                                        title="Telefone 01:"
                                        name="telefone1"
                                        icon={null}
                                        //maskFormat={"(99)99999-9999"}
                                        disabled={false}
                                        required={'required'} />
                                </div>
                                <div style={{ width: '20%', paddingRight: 10 }}>
                                    <InputMask
                                        title="Telefone 02:"
                                        name="telefone2"
                                        icon={null}
                                        //maskFormat={"(99)99999-9999"}
                                        disabled={false} />
                                </div>
                                <div style={{ width: '20%', paddingRight: 10 }}>
                                    <InputMask
                                        title="Telefone 03:"
                                        name="telefone3"
                                        icon={null}
                                        //maskFormat={"(99)99999-9999"}
                                        disabled={false} />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '10%', paddingRight: 10 }}>
                                    <InputMask
                                        title="CEP"
                                        name="cep"
                                        icon={null}
                                        maskFormat={'99999-999'}
                                        disabled={false}
                                        onBlur={(ev) => getCep(ev.target.value)}
                                        required={'required'} />
                                </div>
                                <div style={{ width: '40%', paddingRight: 10 }}>
                                    <Input title="Rua:" name="endereco" id='endereco' required={'required'} />
                                </div>
                                <div style={{ width: '25%', paddingRight: 10 }}>
                                    <Input title="Bairro:" name="bairro" id='bairro' required={'required'} />
                                </div>
                                <div style={{ width: '25%', paddingRight: 10 }}>
                                    <Input title="Cidade:" name="cidade" id='cidade' required={'required'} />
                                </div>
                                <div style={{ width: '10%', paddingRight: 10 }}>
                                    <Input title="Número:" name="numero" id='numero' required={'required'} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '8%', paddingRight: 10 }}>
                                    <Input title="Estado:" name="estado" id={'estado'} required={'required'} />
                                </div>
                                <div style={{ width: '45%', paddingRight: 10 }}>
                                    <Input title="Complemento:" name="complemento" />
                                </div>
                                <div style={{ width: '16%', paddingRight: 10 }}>
                                    <Input
                                        title="Data de Entrada na Clínica:"
                                        name="data_entrada"
                                        icon={null}
                                        type={'date'}
                                        disabled={false}
                                        onChange={e => setDataEntrada(e.target.value)}
                                    />
                                </div>
                                <div style={{ width: '16%', paddingRight: 10 }}>
                                    <Input
                                        title="Data de Saída da Clínica:"
                                        name="data_saida"
                                        icon={null}
                                        type={'date'}
                                        disabled={false}
                                    />
                                </div>
                                <div style={{ width: '15%', paddingRight: 10 }}>
                                    <Input style={{ width: '15%' }} title="Tempo de Casa:" name="tempo_casa"
                                        value={dataEntrada ? moment().subtract(moment(dataEntrada).format('YY'), 'years').format('YY') + ' ano(s) e ' +
                                            moment().subtract(moment(dataEntrada).format('MM'), 'months').format('MM') + ' meses' : '0'} />
                                </div>
                            </div>
                            {terapeutaId === null ?
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '60%', paddingRight: 10 }}>
                                        <Input name="senha" placeholder="Defina uma Senha*" type="password" required={'required'}
                                            title={'Senha de Usuário:'} />
                                    </div>
                                    <div style={{ width: '60%', paddingRight: 10 }}>
                                        <Input name="confirmar" placeholder="Confirme sua Senha*" type="password" required={'required'}
                                            title={'Confirmar Senha:'} />
                                    </div>
                                </div> : <div style={{ display: 'flex' }}>
                                    <div style={{ width: '60%', paddingRight: 10 }}>
                                        <Input name="senha" placeholder="Defina uma Senha*" type="password" />
                                    </div>
                                    <div style={{ width: '60%', paddingRight: 10 }}>
                                        <Input name="confirmar" placeholder="Confirme sua Senha*" type="password" />
                                    </div>
                                </div>}
                        </div>
                        <div>
                            <div>
                                <label style={subHeader}>Disponibilidade</label>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: 20,
                            }}>
                                <table style={{
                                    width: '100%'
                                }}>
                                    <thead>
                                        <tr>
                                            <th>Dias:</th>
                                            <th>Horário:</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/*<DiasDisponibilidade dias={diasUteis} horarios={Horarios} handleChangeHorariosSelecionados={handleChangeHorariosSelecionados} />*/}
                                        {geraLinhasDias()}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <label style={{ marginRight: '3.7%' }}>Selecionar Todos: </label>
                                <input type="checkbox" onChange={e => handleChangeCheckBox(e)} />
                            </div>
                            {/* <TableTerapias /> */}
                        </div>
                        <label style={subHeader}>Emergências Terapeuta:</label>
                        <ModalMedicamentosTerapeuta ref={medicamentosRef} modalData={medicamentosData} medicamentos={medicamentos}
                            setMedicamentos={setMedicamentos} />
                        <div>
                            <TableMedicamentosTerapeuta source={medicamentos} setModalMedicamentosData={setMedicamentosData} terapeutaId={terapeutaId}
                                setMedicamentos={setMedicamentos} callBack={loadTerapeuta} handleOpen={handleOpenMedicamentos} />
                        </div>
                        <div style={leftContentDivStyle}>
                            <button
                                onClick={e => handleOpenMedicamentos()}
                                type="button"
                                style={AddButtonStyle}>
                                Adicionar
                            </button>
                        </div>
                        <div>
                            <ModalContatos contatos={contatos} modalData={modalContatosData} terapeutaId={terapeutaId}
                                setContatos={setContatos} ref={contatosRef} callBack={loadTerapeuta} />
                        </div>
                        <div style={{ paddingTop: 30 }}>
                            <label style={subHeader}>Contatos</label>
                            <TableContatos source={contatos} setContatos={setContatos} callBack={loadTerapeuta} terapeutaId={terapeutaId}
                                handleOpen={handleEditContatos} setModalContatosData={setModalContatosData} />
                            <div style={leftContentDivStyle}>
                                <button
                                    onClick={e => handleOpenContatos()}
                                    type="button"
                                    style={AddButtonStyle}>
                                    Adicionar
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}>
                                <label style={subHeader}>
                                    Terapias, Especialidades e Certificações:
                                </label>
                            </div>
                            <TableTerapias source={minhasTerapias} terapeutaId={terapeutaId} setTerapias={setMinhasTerapias} callBack={loadTerapeuta} />
                            <TableEspecialidades source={minhasEspecialidades} removeEspecialidade={handleRemoveEspecialidade} />
                            {/* <TableDiplomas source={meusDiplomas} removeDiploma={handleRemoveDiploma}/> */}
                            <TableCertificacoes source={minhasCertificacoes} removeCertificacao={handleRemoveCertificado} />
                            <div style={buttonStyle}>
                                <button
                                    onClick={e => handleOpenModalDiplomas()}
                                    type="button"
                                    style={buttonDiplomas}>
                                    Cadastrar Terapias, Especialidades e Certificações
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <label style={subHeader}>Documentos
                                    Gerais:</label>
                            </div>
                            <ModalDocumento documentos={documentos} setDocumentos={setDocumentos}
                                ref={documentoRef} />
                            <TableDocumentosGerais source={documentos} deleteCallback={loadTerapeuta} setDocumentos={setDocumentos} terapeutaId={terapeutaId} />
                            <div style={leftContentDivStyle}>
                                <button
                                    onClick={e => handleOpenModalDocumento()}
                                    type="button"
                                    style={AddButtonStyle}>
                                    Adicionar
                                </button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <label style={subHeader}>Documentos Assinados Terapeuta:</label>
                            </div>
                            <TableDocumentosAssinadosTerapeuta source={documentosAssinados}></TableDocumentosAssinadosTerapeuta>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                            </div>
                            <label style={subHeader}>Contrato de Realização
                                de Cursos:</label>

                            <TableCursos source={contratosCursos} />

                            <div style={leftContentDivStyle}>
                                <button onClick={e => handleOpenContratos()}
                                    type="button"
                                    style={AddButtonStyle}>
                                    Adicionar
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={subHeader}>
                                {feriasList.length === 0 ? 'Histórico Férias:' :
                                    'Novos Históricos a serem adicionados:'}
                            </label>
                            <TableHistoricoDeFerias source={feriasPaciente} feriasList={feriasList} setFeriasList={setFeriasList} />
                            <ModalFerias feriasList={feriasList} setFeriasList={setFeriasList} ref={modalFeriasRef} />
                            <div style={leftContentDivStyle}>
                                <button
                                    onClick={e => handleOpenModalFerias()}
                                    type="button"
                                    style={AddButtonStyle}>
                                    Adicionar
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <button style={buttonBack} type="button" onClick={e => NavigatePage('/dashboard/recepcao/terapeutas')}>
                                Voltar
                            </button>
                            <button type="submit" style={buttonDefault}>
                                {id ? 'Atualizar' : 'Cadastrar'}
                            </button>
                        </div>
                    </Form>
                </div>
                <div>
                    {/*  <ModalEspecialidade
                ref={modalEspecialidadeRef}
                terapeuta={terapeuta}
                especialidades={especialidadesFiltradas}
                minhasEspecialidades={minhasEspecialidades}
                handleCloseModalCallback={handleCloseModalCallback}
                addNew={handleAddNewEspecialidade}/> */}
                </div>
                <ModalContratosDeCursos ref={contratosRef} cursos={contratosCursos} setCursos={setContratosCursos} callBack={loadTerapeuta}
                />
            </Container>
            {isModalDiplomasVisible ? <ModalDiplomas ref={diplomasRef} terapeuta={terapeuta}
                minhasEspecialidades={minhasEspecialidades} addNewEspecialidade={handleAddNewEspecialidade}
                addNewTerapia={handleAddNewTerapia} addNewDiploma={handleAddNewDiploma}
                addNewCertificado={handleNewCertificacao}

                onClose={() => setIsModalDiplomasVisible(false)}
            /> : (<></>)}
        </Recepcao>
    );
}