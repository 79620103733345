import React, { useEffect, useState } from 'react';
import { AiOutlineUser, AiOutlineBell } from 'react-icons/ai';
import { Container } from './styles';
import api from '../../../services/api';
import UsuarioLogado from '../../../components/UsuarioLogado';
import { MainDivStyle, override } from './styles';
import ClipLoader from 'react-spinners/ClipLoader';
import { TableResponsavelPaciente } from './TableResponsavelPaciente';
import Responsavel from '../../../layouts/Responsavel';
import { useAuth } from '../../../context/auth';

export default function PacientesResponsavel() {

    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [paciente, setPaciente] = useState([]);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [filterValue, setFilter] = useState('');
    const [color, setColor] = useState('#8ed4ae');
    const [usuario, setUsuario] = useState('');

    const user = useAuth();
    
    useEffect(() => {
        setLoadingDadosPacientes(true);
        async function loadPacientes() {
            api.get(`api/paciente?with=&filter[responsavels.id]=${user.responsavels[0].id}`).then(({data}) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPacienteOriginal(data);
                setPaciente(data);
            });

            setLoadingDadosPacientes(false);
        }
        loadPacientes();
    }, []);

    const handleChangePacienteSearch = (data) => {
        setFilter(data);
        setPaciente(pacienteOriginal);
        setPaciente((pacientes) =>
            pacientes.filter((item) => item.nome.toString().toLowerCase().includes(data.toString().toLowerCase()))
        );
    };

    return (
        <Responsavel>
            <Container>
                <div style={MainDivStyle}>
                    <div>
                        <div
                        style={{
                            textDecoration: 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 20,}}>
                            <label style={{
                           
                            color: '#7340BD',
                            fontWeight: 'bold',
                            cursor: 'pointer'}}>
                                Pacientes
                            </label>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                    <AiOutlineBell color="silver" size={20}/>
                    <AiOutlineUser color="silver" size={20}/>
                    <div>
                        <UsuarioLogado />
                    </div>
                    </div>
                </div>
                <div style={{ width: '100%', paddingBottom: 40, paddingTop: 40 }}>
                    <input style={{ width: '90%', borderRadius: 8, height: 36, boxShadow: '0 0 10px 0px silver', 
                        border: 'none', padding: 12, margin: 20 }} placeholder={'Pesquisar Paciente por Nome:'}
                        value={filterValue || ''}
                        onChange={e => {
                            handleChangePacienteSearch(e.target.value); // Set undefined to remove the filter entirely
                        }}
                    />
                </div>
                <div style={{marginBottom: 50}}>
                    {
                        (paciente.length !== 0 && loadingDadosPacientes === false) ? <TableResponsavelPaciente source={paciente}/> : 
                            <ClipLoader color={color} loading={loadingDadosPacientes} css={override} size={50} />
                    }
                </div>
            </Container>
        </Responsavel>
    );
}
