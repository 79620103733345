import styled from 'styled-components';

export const Modal = styled.div`
flex-direction: column; 
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 10;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`;


export const Container = styled.div`
display: flex;
flex-direction: column; 
background-color: #FFFFFF;
align-items: stretch;
padding: 30px;
color: #000;
width: 30%;
height: auto;
border-radius: 10px`;

export const ButtonStyle = {
    fontWeight: '600',
    fontSize: '18', 
    backgroundColor: '#bd4040', 
    color: '#FFFFFF',
    border: 'none', 
    borderRadius: 8, 
    padding: '4px 40px',
};

export const ContainerStyle = {
    width: '30%', 
    alignItems: 'center', 
    height: '36%', 
    backgroundColor: '#FFFFFF', 
};

export const CenterDivStyle = {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center' 
};

export const RowDivStyle = {
    display: 'flex', 
    flexDirection: 'row',
    width: '100%', 
    paddingTop: 10,
};

export const ColumnDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
};

export const BackButtonStyle = {
    fontWeight: '600',
    fontSize: '18', 
    backgroundColor: '#6A3BAF', 
    color: '#FFFFFF',
    border: 'none', 
    borderRadius: 8, 
    padding: '4px 30px',
    margin: 20,
};

export const ButtonAlignStyle = {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 

};