import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiFillCaretDown, AiFillFileAdd, AiOutlineClear, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { Container } from './styles';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import GrupoPermissoesService from '../../../../services/GrupoPermissoesService';
import TableGrupoPermissao from './TablePermissoes';
import GrupoPermissaoModal from '../../../../components/Modais/GrupoPermissaoModal';
import AssocPermissaoModal from '../../../../components/Modais/AssocPermissaoModal';
import Admin from '../../../../layouts/Admin';
import api from '../../../../services/api';
import { BsCheck } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import PermissaoModal from '../../../../components/Modais/PermissaoModal';
import Button from '../../../../components/Button';
import { LinhaSpaceBetween, TituloTabelas } from '../../../../styles/global-style';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function GrupoPermissao() {
    //#region VARIAVEIS
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#8ed4ae');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalPermissaoIsOpen, setModalPermissaoIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalPermissaoData, setModalPermissaoData] = useState(null);
    const [modalAssocIsOpen, setModalAssocIsOpen] = useState(false);
    const [grupoPermissaoId, setGrupoPermissaoId] = useState(null);
    const [grupoPermissoes, setGrupoPermissoes] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const [permissoesOriginal, setPermissoesOriginal] = useState([]);

    /* VARS REF */
    const filterRef = useRef();

    /* VARS FILTRO */
    const [linkList, setLinkList] = useState();
    const [linkSelecionadoOpt, setLinkSelecionadoOpt] = useState(null);
    const [linkSelecionado, setLinkSelecionado] = useState(null);
    const [isLinkList, setIsLinkList] = useState(null);
    const [isLinkSelecionadoOpt, setLinkableSelecionadoOpt] = useState(null);
    const [linkableSelecionado, setLinkableSelecionado] = useState(null);
    const [isSubLinkList, setIsSubLinkList] = useState(null);
    const [isSubLinkSelecionadoOpt, setSublinkableSelecionadoOpt] = useState(null);
    const [sublinkableSelecionado, setSublinkableSelecionado] = useState(null);
    const [nameFilter, setNameFilter] = useState(null);
    const [hasSubmenuList, setHasSubmenuList] = useState(null);
    const [hasSubmenuSelecionadoOpt, setHasSubmenuSelecionadoOpt] = useState(null);
    const [hasSubmenuSelecionado, setHasSubmenuSelecionado] = useState(null);
    const [expand, setExpand] = useState();

    const snackbar = useSnackbar();
    const permissoesColumns = useMemo(
        () => [
            {
                Header: 'ID: ',
                accessor: 'id',
                filterable: false,
            },
            {
                Header: 'Permissão: ',
                accessor: 'name',
                filterable: true,
            },
            {
                Header: 'Link: ',
                accessor: 'link',
                filterable: true,
            },
            {
                Header: 'Titulo: ',
                accessor: 'title',
                filterable: true,
            },
            {
                Header: 'Link?',
                accessor: 'linkable',
                filterable: true,
                filterType: 'boolean',
                Cell: ({ cell }) => <div className="d-flex justify-content-center">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id={'linkable:' + cell.row.original.id} onChange={(e) => handleChangeLinkable(cell.row.original, e.target.cheched)} defaultChecked={cell.row.original.linkable} />
                        <label className="form-check-label" htmlFor={'linkable:' + cell.row.original.id}></label>
                    </div>
                </div>,
            },
            {
                Header: 'Sublink?',
                accessor: 'sublinkable',
                filterType: 'boolean',
                filterable: true,
                Cell: ({ cell }) => <div className="d-flex justify-content-center">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id={'sublinkable:' + cell.row.original.id} onChange={(e) => handleChangeSubLinkable(cell.row.original, e.target.cheched)} defaultChecked={cell.row.original.sublinkable} />
                        <label className="form-check-label" htmlFor={'sublinkable:' + cell.row.original.id}></label>
                    </div>
                </div>,
            },
            {
                Header: 'Tem Submenu?',
                accessor: 'has_submenu',
                filterType: 'boolean',
                filterable: true,
                Cell: ({ cell }) => <div className="d-flex justify-content-center">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id={'has_submenu:' + cell.row.original.id} onChange={(e) => handleChangeTemSubMenu(cell.row.original, e.target.cheched)} defaultChecked={cell.row.original.has_submenu} />
                        <label className="form-check-label" htmlFor={'has_submenu:' + cell.row.original.id}></label>
                    </div>
                </div>,
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                filterable: false,
                Cell: ({ cell }) => <div className='d-flex align-items-center justify-content-center' >
                    <button className="btn-icone" type="button" onClick={() => handleDeletePermissao(cell.row.original.id)} >
                        <AiOutlineDelete className="font-roxo" size={22} />
                    </button>
                    <button className="btn-icone" type="button" onClick={() => handleEditPermissaoModal(cell.row.original)} color="#7340BD" size={22} >
                        <AiOutlineEdit className="font-roxo" size={22} />
                    </button>
                </div>
            }
        ]
    );
    //#endregion

    //#region FUNCOES
    const carregaGrupoPermissoes = () => {
        GrupoPermissoesService.index()
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setGrupoPermissoes(data);
            })
            .finally(() => setLoading(false));
        api.get('api/permission?order_by=name:asc').then(({ data }) => {
            setPermissoes(data);
            setPermissoesOriginal(data);
            let linkList = [];
            let isLinkList = [];
            let isSubLinkList = [];
            let hasSubmenuList = [];
            for (let index = 0; index < data.length; index++) {
                const el = data[index];
                if (linkList.findIndex((e) => e.value === el.link) === -1) {
                    linkList.push({
                        label: el.link,
                        value: el.link
                    });
                }
                if (isLinkList.findIndex((e) => e.value === el.linkable) === -1) {
                    isLinkList.push({
                        label: el.linkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.linkable
                    });
                }
                if (isSubLinkList.findIndex((e) => e.value === el.sublinkable) === -1) {
                    isSubLinkList.push({
                        label: el.sublinkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.sublinkable
                    });
                }
                if (hasSubmenuList.findIndex((e) => e.value === el.has_submenu) === -1) {
                    hasSubmenuList.push({
                        label: el.has_submenu
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.has_submenu
                    });
                }
            }
            setLinkList(linkList);
            setIsLinkList(isLinkList);
            setIsSubLinkList(isSubLinkList);
            setHasSubmenuList(hasSubmenuList);
        }).catch((e) => {
            console.error(e);
        });
    };
    const filtrar = () => {
        let listaLocal = permissoesOriginal;
        const newListaFiltrada = listaLocal.filter((e) => {
            let link = false;
            let linkable = false;
            let sublinkable = false;
            let has_submenu = false;
            let name = false;

            if (linkSelecionado === null || linkSelecionado === undefined) {
                link = true;
            } else {
                if (String(e.link).toUpperCase().includes(String(linkSelecionado).toUpperCase())) {
                    link = true;
                }
            }
            if (linkableSelecionado === null || linkableSelecionado === undefined) {
                linkable = true;
            } else {
                if (Boolean(e.linkable) === Boolean(linkableSelecionado)) {
                    linkable = true;
                }
            }
            if (sublinkableSelecionado === null || sublinkableSelecionado === undefined) {
                sublinkable = true;
            } else {
                if (Boolean(e.sublinkable) === Boolean(sublinkableSelecionado)) {
                    sublinkable = true;
                }
            }
            if (hasSubmenuSelecionado === null || hasSubmenuSelecionado === undefined) {
                has_submenu = true;
            } else {
                if (Boolean(e.has_submenu) === Boolean(hasSubmenuSelecionado)) {
                    has_submenu = true;
                }
            }
            if (nameFilter === null && nameFilter === undefined) {
                name = true;
            } else {
                if (e.name.includes(nameFilter)) {
                    name = true;
                }
            }
            return (link && linkable && sublinkable && has_submenu && name);
        });
        setPermissoes(newListaFiltrada);
    };


    const associarAssoc = (permissao_id) => {
        setGrupoPermissaoId(permissao_id);
        setModalAssocIsOpen(true);
    };
    //#endregion

    //#region HANDLES
    const handleChangeLinkable = (link, check) => {
        Swal.fire({
            title: `Deseja ${check ? '' : 'des'}transformar esse link em um link de barra de Navegação?`,
            showCancelButton: true,
            cancelButtonAriaLabel: 'Votlar',
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Confirmar'
        }).then((response) => {
            if (response.isConfirmed) {
                api.patch(`api/permission/${link.id}`, { linkable: check })
                    .then(() => {
                        snackbar.displayMessage('Atualizado linkable com sucesso!', 'success');
                    })
                    .catch((error) => {
                        snackbar.displayMessage('Não foi possível atualizar o linkable da permissão', 'error');
                        console.error(error);
                    });
            }
        });

    };
    const handleChangeSubLinkable = (link, check) => {
        Swal.fire({
            title: `Deseja ${check ? '' : 'des'}transformar esse link em um link de barra de Navegação?`,
            showCancelButton: true,
            cancelButtonAriaLabel: 'Votlar',
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Confirmar'
        }).then((response) => {
            if (response.isConfirmed) {
                api.patch(`api/permission/${link.id}`, { sublinkable: check })
                    .then(() => {
                        snackbar.displayMessage('Atualizado sublinkable com sucesso!', 'success');
                    })
                    .catch((error) => {
                        snackbar.displayMessage('Não foi possível atualizar o sublinkable da permissão', 'error');
                        console.error(error);
                    });
            }
        });
    };
    const handleChangeTemSubMenu = (link, check) => {
        Swal.fire({
            title: `Deseja ${check ? '' : 'des'}transformar esse link em um link de barra de Navegação?`,
            showCancelButton: true,
            cancelButtonAriaLabel: 'Votlar',
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Confirmar'
        }).then((response) => {
            if (response.isConfirmed) {
                api.patch(`api/permission/${link.id}`, { has_submenu: check })
                    .then(() => {
                        snackbar.displayMessage('Atualizado has_submenu com sucesso!', 'success');
                    })
                    .catch((error) => {
                        snackbar.displayMessage('Não foi possível atualizar o has_submenu da permissão', 'error');
                        console.error(error);
                    });
            }
        });
    };
    const handleEditModal = (data) => {
        setModalData(data);
        setIsOpen(true);
    };

    const handleNewGrupoDePermissao = () => {
        setModalData(null);
        setIsOpen(true);
    };
    const handleNewPermissao = () => {
        setModalPermissaoData(null);
        setModalPermissaoIsOpen(true);
    };

    const handleEditPermissaoModal = (data) => {
        setModalPermissaoIsOpen(true);
        setModalPermissaoData(data);
    };

    const handleDeletePermissao = (id) => {
        Swal.fire({
            title: 'Deseja realmente remover esta permissão ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((e) => {
            if (e.isConfirmed) {
                api.delete('api/permission/' + id).then((e) => {
                    snackbar.displayMessage('Permissão excluída com sucesso!', 'success');
                }).catch((e) => {
                    Swal.fire('Houve um problema a excluír a permissão', '', 'error');
                    console.error(e);
                });
            }
        });
    };


    //===FUN FILTRO===\
    const expandFiltro = (handleClose = false) => {
        let text = document.getElementById('blank');
        if (handleClose) {
            setExpand(false);
            text.innerHTML = 'Expandir Filtro';
        } else {
            setExpand(!expand);
            if (expand === false) {
                text.innerHTML = 'Fechar Filtro';
            } else {
                text.innerHTML = 'Expandir Filtro';
            }
        }
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        carregaGrupoPermissoes();
        // Função para verificar se o clique foi fora do componente
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                // Feche o componente de filtro aqui
                expandFiltro(true);
            }
        }

        // Adiciona o ouvinte de evento ao documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Remove o ouvinte de evento ao desmontar
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (hasSubmenuSelecionado === null && linkableSelecionado === null && sublinkableSelecionado === null && linkSelecionado === null && nameFilter === null) {
            setPermissoes(permissoesOriginal);
        } else {
            filtrar();
        }
    }, [hasSubmenuSelecionado, linkableSelecionado, sublinkableSelecionado, linkSelecionado, nameFilter, permissoesOriginal]);
    //#endregion

    return (
        <Container>
            <LinhaSpaceBetween>
                <TituloTabelas className="mt-3">Grupo de Permissões</TituloTabelas>
            </LinhaSpaceBetween>
            {(grupoPermissoes.length !== 0 && loading === false)
                ?
                <div className="pe-4 ps-4 pb-2">
                    <TableGrupoPermissao callback={carregaGrupoPermissoes} source={grupoPermissoes} openModalAssoc={associarAssoc} editModal={handleEditModal} handleNewGrupoDePermissao={handleNewGrupoDePermissao} />
                </div>
                : <ClipLoader color={color} loading={loading} css={override} size={50} />
            }
            <LinhaSpaceBetween>
                <TituloTabelas className="mt-3 mb-3"> Listagem de Permissões </TituloTabelas>
            </LinhaSpaceBetween>
            {permissoesOriginal.length !== 0
                ?
                <Container className="pe-4 ps-4 pb-2">
                    {/* TABELA */}
                    <TableDefaultPagination className='mt-20px' source={permissoes} columns={permissoesColumns} searchBar={true}
                        createButton={
                            <Button type='button' onClick={() => handleNewPermissao()}>
                                <AiFillFileAdd style={{ marginRight: 5 }} />
                                Nova Permissão
                            </Button>
                        } />
                </Container>
                : <ClipLoader color={color} loading={loading} css={override} size={50} />
            }
            {modalIsOpen && <GrupoPermissaoModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                callback={carregaGrupoPermissoes}
                modalData={modalData}
            />}
            {modalPermissaoIsOpen && <PermissaoModal
                permissionsList={permissoesOriginal}
                modalData={modalPermissaoData}
                modalIsOpen={modalPermissaoIsOpen}
                setIsOpen={setModalPermissaoIsOpen}
                callback={carregaGrupoPermissoes}>
            </PermissaoModal>
            }
            {modalAssocIsOpen && <AssocPermissaoModal
                modalIsOpen={modalAssocIsOpen}
                setIsOpen={setModalAssocIsOpen}
                groupId={grupoPermissaoId}
            />}
        </Container>
    );
}