import React from 'react';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { AiOutlineEdit, AiOutlineEye, AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai';
import moment from 'moment';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useSnackbar } from '../../../../context/snackbar';
import { Tooltip } from 'react-tooltip';
import { diferencaEntreDatas } from 'helpers/data.helper';
import { depois } from 'helpers/data.helper';
import { hoje } from 'helpers/data.helper';
import { antes } from 'helpers/data.helper';

export function TableGuias({ source, setData, permissoes, handleOpen, callBack }) {
    const snackbar = useSnackbar();
    const agora = hoje();
    //#region HANDLES
    const handleOpenGuia = (data, view) => {
        setData(data);
        handleOpen(view, data);
    };
    const handleStatusGuia = (status, data) => {
        if (status === 'Ativo') {
            let inicio = moment(data.inicio).utc();
            let validade = moment(data.validade).utc();
            if (validade.isBefore(moment())) {
                return Swal.fire({
                    title: 'A guia já venceu, não é possivel reativa-la!',
                    html: 'Vigência de: ' + inicio.format('DD/MM/YYYY') + ' até: ' + validade.format('DD/MM/YYYY'),
                    icon: 'error',
                    timer: 2000
                });
            }
        }
        let texto = (status === 'Bloqueado') ? 'bloquear' : 'desbloquear';
        Swal.fire({
            title: `Deseja mesmo ${texto} essa guia?`,
            html: status === 'Bloqueado'
                ? 'Ao <b>bloquear</b> essa guia, ela não podera ser usada pelo Controle Diário para confirmar o atendimento.'
                : 'Ao <b>desbloquear</b> essa guia, você esta ciente, que ela podera ser usada para confirmar atendimentos.',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonAriaLabel: 'Cancelar'
        }).then(response => {
            if (response.isConfirmed) {
                if (data.status !== 'Vencido') {
                    api.patch('api/guia/' + data.id + '?id=' + data.id, { status: status })
                        .then(() => {
                            snackbar.displaMessage('Guia alterada com sucesso!.', 'success');
                        })
                        .catch(e => {
                            console.error(e);
                            snackbar.displaMessage('Não foi possível bloquear guia.', 'error');
                        })
                        .finally(() => {
                            callBack();
                        });
                } else {
                    Swal.fire('Não foi possível mudar status!', 'A guia em questão esta vencida, não é necessário bloquea-la.', 'info');
                }
            }
        });
    };
    //#endregion
    const columns = React.useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'Paciente',
                accessor: 'nome',
                Cell: ({ cell }) => {
                    return (
                        <div className="w-300px">
                            {cell.row.original.nome}
                        </div>
                    );
                },
                filterable: true,
            },
            {
                Header: 'Terapia',
                accessor: 'terapia',
                filterable: true,
            },
            {
                Header: 'Especialidade',
                accessor: 'especialidade',
                filterable: true,
            },
            {
                Header: 'Plano de Saúde',
                accessor: 'nome_reduzido',
                filterable: true,
            },
            {
                Header: 'Valor (R$)',
                accessor: 'valor',
                Cell: ({ cell }) => {
                    return cell.row.original.valor ? cell.row.original.valor : ' - ';
                },
                filterable: true,
            },
            {
                Header: 'Quantidade',
                accessor: 'quantidade_feita',
                Cell: ({ cell }) => {
                    const ql = Number(cell.row.original.quantidade_liberada);
                    const qf = Number(cell.row.original.quantidade_feita);
                    const saldo = ql - qf;
                    const porcentagem = 100 - Math.round(saldo * 100 / ql);
                    const stautsInterpolation =
                        (porcentagem < 50)
                            ? 'success'
                            : (porcentagem < 80)
                                ? 'warning'
                                : 'danger';
                    const status = `bg-${stautsInterpolation}`;
                    return (
                        <div className="w-200px h-46px p-1" style={{ verticalAlign: 'middle', alignContent: 'center', alignItems: 'center' }}>
                            <p className="p-0" style={{ position: 'absolute', margin: '15px 100px', transform: 'translate(-50%, -50%)' }}>
                                {cell.row.original.quantidade_feita} / {cell.row.original.quantidade_liberada}
                            </p>
                            <div data-tooltip-id={'liberado-usado-saldo-' + cell.row.original.id} className="progress h-30px" role="progressbar" aria-label="Porcentagem restante" aria-valuenow={porcentagem} aria-valuemin="0" aria-valuemax="100">
                                <div className={`progress-bar ${status}`} style={{ width: `${porcentagem}%` }}>{porcentagem}%</div>
                            </div>
                            <Tooltip id={'liberado-usado-saldo-' + cell.row.original.id} type='dark' effect='float' style={{ width: '180px' }} className="d-grid border-radius-10px justify-content-between flex-wrap">
                                <span className="w-100 text-left">Quantidade Liberada:<b className="text-right">{cell.row.original.quantidade_liberada}</b></span>
                                <span className="w-100 text-left">Quantidade Usada:<b className="text-right">{cell.row.original.quantidade_feita}</b></span>
                                <span className="w-100 text-left">Quantidade Disponível:<b className="text-right">{saldo}</b></span>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                Header: 'Dias a Vencer',
                accessor: 'validade',
                Cell: ({ cell }) => {
                    let status = cell.row.original.status;
                    let classe = '';
                    if (status === 'Vencido') {
                        classe = 'line-select-red';
                    } else {
                        if (status === 'Bloqueado') {
                            classe = 'line-select-yellow';
                        }
                    }
                    const diferenca = diferencaEntreDatas(agora, cell.row.original.validade) - 1;
                    const emVigencia = depois(new Date(agora), new Date(cell.row.original.inicio)) && antes(new Date(agora), new Date(cell.row.original.validade));
                    const inicio = moment.utc(cell.row.original.inicio).format('DD/MM/YYYY');
                    const validade = moment.utc(cell.row.original.validade).format('DD/MM/YYYY');
                    return (
                        <div className={classe}>
                            <span data-tooltip-id={'inicio-fim' + cell.row.original.id}> {emVigencia ? diferenca : 0} </span>
                            <Tooltip id={'inicio-fim' + cell.row.original.id} type='dark' effect='float' style={{ width: '180px' }} className="d-flex border-radius-10px justify-content-between flex-wrap">
                                <span className="w-50 text-left">Início:</span> <b className="w-50 text-right">{inicio}</b><br></br>
                                <span className="w-50 text-left">Validade:</span> <b className="w-50 text-right">{validade}</b><br></br>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        (
                            <div id={`acoes-guia-${cell.row.id}`} className="d-flex align-center justify-center">
                                <AiOutlineEye id={`visualiza-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleOpenGuia(cell.row.original, false)} title="Abrir detalhes da guia." />
                                {permissoes['edição'] &&
                                    <AiOutlineEdit id={`edita-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleOpenGuia(cell.row.original, true)} title="Editar guia." />
                                }
                                {permissoes['bloqueio'] ?
                                    cell.row.original.status === 'Bloqueado'
                                        ? <AiOutlineUnlock id={`trava-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleStatusGuia('Ativo', cell.row.original)} title="Desbloquear guia." />
                                        : <AiOutlineLock id={`trava-guia-${cell.row.id}`} className="icone-padrao" onClick={e => handleStatusGuia('Bloqueado', cell.row.original)} title="Bloquear guia." />
                                    : <></>
                                }
                            </div>
                        )
                    );
                }
            }
        ]
    );

    return (
        <TableDefaultPagination prefix_id={'guias'} id="tabela-guias" thead_id="tabela-thead-de-guias" tbody_id="tabela-tbody-de-guias" columns={columns} source={source} />
    );
}