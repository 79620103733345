//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Dropdown from 'components/Dropdown';
import LiveBadge from 'components/LiveBadge';
import SolicitacaoDeAlteracaoModal from 'components/Modais/Terapeuta/SolicitacaoModal';
import useHorarioAtendimentoData from 'context/horario-atendimento';
import useModalidadeData from 'context/modalidade';
import usePacienteData from 'context/paciente';
import useSalaData from 'context/sala';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { unstable_batchedUpdates } from 'react-dom';
import { AiFillCaretDown, AiFillFilter, AiOutlineClear, AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsArrowDownLeft, BsBell, BsBellFill, BsBellSlash, BsSearch } from 'react-icons/bs';
import { FaBroom, FaUser, FaUserCog, FaUserMd, FaUserTie } from 'react-icons/fa';
import { FiDownload, FiFilter, FiRefreshCcw } from 'react-icons/fi';
import { IoMdOpen } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { ScaleLoader } from 'react-spinners';
import SolicitacaoDeAlteracaoService from 'services/SolicitacaoDeAlteracaoService';
import Swal from 'sweetalert2';
import Calendario from '../../../../components/Calendario';
import ControleDeExecucaoModal from '../../../../components/Modais/Checkin';
import ComentariosModal from '../../../../components/Modais/Comentarios';
import Select from '../../../../components/Select';
import { AtendimentoReposicaoProvider } from '../../../../context/atendimentoReposicao';
import { useAuth } from '../../../../context/auth';
import usePermissoes from '../../../../context/permission';
import { useSnackbar } from '../../../../context/snackbar';
import api from '../../../../services/api';
import AtendimentoAlteracaoService from '../../../../services/AtendimentoAlteracaoService';
import AtendimentoService from '../../../../services/AtendimentoService';
import AdicionarAgendamento from '../../../TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import AtendimentoModal from '../../../TerapeutaDashboard/StatusDiario/AtendimentoModal';
import { ContagemPendentes } from '../../../TerapeutaDashboard/TerapeutaPacientes/styles';
import ConferirConflitos from './Modal/ConferirConflitos';
import Conflitos from './Modal/Conflitos';
import LegendaDefault from './Modal/Legenda';
import Responsavel from './Modal/Responsavel';
import SelfLibVirtualizedTable from './selflib-virtualized-tbody';
import { DivContentDisplay, linhasSolicitacao } from './styles';
import './styles.css';
import Tbody from './tbody';
//#endregion

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

export default function ControleDiario({ minimizedSubNavbar }) {
    //#region VARIÁVEIS
    /*==INFORMAÇÕES da TELA==*/
    const titulo = 'CONTROLE DIÁRIO';
    const rota = '/dashboard/recepcao/controlediario/';

    /*==USUARIO==*/
    const usuario = useAuth();

    /*==MODO TELA==*/
    const modoDoAtendimento = 'agrupado.nao';//'agrupado.horario'
    const modoTela = useMemo(() => usuario.user.tipo_user.toLowerCase(), [usuario]);

    /*==MENSAGEM==*/
    const snackbar = useSnackbar();
    const permissoes = usePermissoes();

    /*==PACIENTE==*/
    const { pacientes, pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const { salaEstacaoOptions, fetchSalaEstacaoData } = useSalaData();
    const { horarioAtendimentoComFimOptions, fetchHorarioAtendimentoComFimData } = useHorarioAtendimentoData();
    const { modalidadesOptions, fetchModalidadeData } = useModalidadeData();

    /*==OUTRAS==*/
    function customPacienteFilter(option, inputValue) {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (pacienteOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };

    const dayName = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const dateNow = new Date();

    /*==ESTADO==*/
    const [daysMark, setDaysMark] = useState({});
    const [quantidadeAtendimentos, setQuantidadeAtendimentos] = useState(0);
    const [quantidadeConflitos, setQuantidadeConflitos] = useState(0);
    const [quantidadeErros, setQuantidadeErros] = useState(0);
    const [quantidadeSucessos, setQuantidadeSucessos] = useState(0);
    const [contagemSolicitacoesPendentes, setContagemSolicitacoesPendentes] = useState(0);
    const [filtraSolicitacoesPendentes, setFiltraSolicitacoesPendentes] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [enableEdit, setEnableEdit] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [isFiltred, setIsFiltred] = useState(false);
    const [solicitacoesDeAlteracao, setSolicitacoesDeAlteracao] = useState(null);
    const [btnSolicitacaoHover, setBtnSolicitacaoHover] = useState(false);
    const [btnNotificacaoHover, setBtnNotificacaoHover] = useState(false);
    const [quantidadeDeAtualizacoes, setQuantidadeDeAtualizacoes] = useState(0);
    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
    const [isAtendimentoEditavel, setIsAtendimentoEditavel] = useState(false);
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    const [pacienteEmExecucaoList, setPacienteEmExecucaoList] = useState([]);
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(null);
    const [expand, setExpand] = useState(false);
    const [terapeutasList, setTerapeutasList] = useState([]);
    const [planosSaudeList, setPlanosSaude] = useState([]);
    const [statusAtendimento, setStatusAtendimento] = useState(null);
    const [tipoAtendimentos, setTipoAtendimentos] = useState(null);
    const [statusSelecionado, setStatusSelecionado] = useState(null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [habilitaSucesso, setHabilitaSucesso] = useState(true);
    const [habilitaErros, setHabilitaErros] = useState(true);
    const [habilitaConflitos, setHabilitaConflitos] = useState(true);
    const [statusSelecionadoOpt, setStatusSelecionadoOpt] = useState(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState(null);
    const [atendimentoModalSelecionado, setAtendimentoModalSelecionado] = useState(null);
    const [houveAlteracaoStatusEdicao, setHouveAlteracaoStatusEdicao] = useState(false);
    const [houveInclusaoAtendimento, setHouveInclusaoAtendimento] = useState(false);
    const [controleDiarioId, setControleDiarioId] = useState(null);
    const [controleDiario, setControleDiario] = useState(null);
    const [scrollTopInfo, setScrollTopInfo] = useState({ scrollTop: 0, scrollLeft: 0 });
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const [atendimentosNormal, setAtendimentosNormal] = useState([]);
    const [dataAtual, setDataAtual] = useState(`${dayName[dateNow.getDay()]} - ${moment.utc().format('DD/MM/YYYY')}`);
    const [dataOriginal, setDataOriginal] = useState(moment.utc());
    const [dataSelecionada, setDataSelecionada] = useState(moment.utc().format('YYYY-MM-DD'));
    const [carregandoControleDiario, setCarregandoControleDiario] = useState(false);
    const [carregandoAtendimentos, setCarregandoAtendimentos] = useState(false);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [temDiaCarregado, setTemDiaCarregado] = useState(null);

    /*==REFS==*/
    const modalComentariosRef = useRef();
    const filtroDivRef = useRef();
    const tableRef = useRef();
    const pendenciasRef = useRef();
    const calendarioRef = useRef();
    const conferirConflitosModalRef = useRef();
    const solicitacaoDeAlteracaoModalRef = useRef();
    const checkInModalRef = useRef(null);

    /*==Opções do select==*/
    const opcoesLiberado = [
        { value: null, label: '' },
        { value: '0', label: '❌' },
        { value: '1', label: '✅' }
    ];

    /*==TABLE==*/
    const tableHeaders = useMemo(() => [
        { key: 'l1', className: 'cd-col-0', title: 'Hora', label: 'Hora' },
        { key: 'l2', className: 'cd-col-1', title: 'Nome curto do terapeuta.', label: 'Terapeuta' },
        { key: 'l3', className: 'cd-col-2', title: 'Terapia e Especialidade do atendimento.', label: 'Terapia <br /> Especialidade' },
        { key: 'l4', className: 'cd-col-3', title: 'Local para criar, aprovar ou reprovar atendimento.', label: 'Alteração' },
        { key: 'l5', className: 'cd-col-4', title: 'Identificador do paciente.', label: 'Paciente' },
        { key: 'l6', className: 'cd-col-5', title: 'Sala e a estação do atendimento.', label: 'Sala <br /> Estação' },
        { key: 'l7', className: 'cd-col-6', title: 'Define qual a modalidade e o tipo do atendimento.', label: 'Tipo e Modalidade' },
        { key: 'l8', className: 'cd-col-7', title: 'Status que o terapeuta sugeriu para o atendimento.', label: 'Status <br /> Terapeuta' },
        { key: 'l9', className: 'cd-col-8', title: 'Define o status que o atendimento vai receber.', label: 'Status' },
        { key: 'l10', className: 'cd-col-9', title: 'Define se a guia foi liberada pelo plano.', label: 'Liberado' },
        { key: 'l11', className: 'cd-col-10', title: 'Define se o atendimento foi executado no plano.', label: 'Execução' },
        { key: 'l12', className: 'cd-col-11', title: 'Comentário registrado no atendimento.', label: '' },
        { key: 'l13', className: 'cd-col-12', title: 'Cor que define alguma incompatibilidade no no atendimento.', label: 'P' }
    ], []);
    //#endregion
    //#region FUNCOES
    function onLoad() {
        try {
            getControleDiarioId();
        } catch (error) {
            console.error(error);
        }
    }

    const filtrar = useCallback(async () => {
        const resultado = atendimentosOriginal.reduce((acc, atendimento) => {
            // Teste de cada condição para atendimento
            const paciente_id = Number(atendimento.paciente_id);
            const terapeuta_id = Number(atendimento.terapeuta_id);
            const plano_saude_id = Number(atendimento.plano_saude_id);
            const status_recepcao = Number(atendimento.sugestao_recepcao);

            //#region FILTRO
            // Teste de cada condição para atendimento.
            const status = (statusSelecionado === null || Number(statusSelecionado) === status_recepcao);
            const paciente = (pacienteSelecionado === null || Number(pacienteSelecionado) === paciente_id);
            const terapeuta = (terapeutaSelecionado === null || Number(terapeutaSelecionado) === terapeuta_id);
            const planoSaude = (planoSaudeSelecionado === null || Number(planoSaudeSelecionado) === plano_saude_id);
            //#endregion
            //#region CONFLITOS e ERROS
            // Teste de conflitos e erros.
            const erro = atendimento?.conflitos.liberado_realizado_sem_codigo || atendimento?.conflitos.liberado_realizado_sem_codigo2 || atendimento?.conflitos.realizado_nao_liberado || atendimento?.conflitos.sem_execucao || atendimento?.conflitos.sem_guia || atendimento?.conflitos.sem_liberacao || atendimento?.conflitos.sem_status || atendimento?.conflitos.sem_terapeuta;
            const conflito = atendimento?.conflitos.horario_paciente || atendimento?.conflitos.sala_terapeuta || atendimento?.conflitos.executado_nao_realizado;

            let erroConflitoTest = false;
            if (habilitaSucesso && !erro && !conflito) erroConflitoTest = true;
            if (habilitaErros && erro) erroConflitoTest = true;
            if (habilitaConflitos && conflito) erroConflitoTest = true;

            let solicitacoesPendentes = false;
            if (filtraSolicitacoesPendentes) {
                solicitacoesPendentes = atendimento.solicitacao_de_alteracaos?.some(filter => filter.status === 'Aguardando') || false;
            } else if (filtraSolicitacoesPendentes === false) {
                solicitacoesPendentes = !atendimento.solicitacao_de_alteracaos?.some(filter => filter.status === 'Aguardando');
            } else {
                solicitacoesPendentes = true;
            }

            const result = status && paciente && terapeuta && planoSaude && erroConflitoTest && solicitacoesPendentes;
            if (result) {
                if (erro) acc.qtdeErros++;
                if (conflito) acc.qtdeConflitos++;
                if (!erro && !conflito) acc.qtdeSucessos++;
                acc.qtdeAtendimento++;
                acc.newAtendimentosList.push(atendimento);
            }
            //#endregion
            return acc;
        }, {
            newAtendimentosList: [],
            qtdeAtendimento: 0,
            qtdeSucessos: 0,
            qtdeConflitos: 0,
            qtdeErros: 0
        });
        setQuantidadeAtendimentos(resultado.qtdeAtendimento);
        setQuantidadeSucessos(resultado.qtdeSucessos);
        setQuantidadeErros(resultado.qtdeErros);
        setQuantidadeConflitos(resultado.qtdeConflitos);
        setAtendimentos(modoDoAtendimento === 'agrupado.horario' ? agrupaPorHorario(resultado.newAtendimentosList) : resultado.newAtendimentosList);
    }, [atendimentosOriginal, statusSelecionado, pacienteSelecionado, terapeutaSelecionado, planoSaudeSelecionado, habilitaSucesso, habilitaErros, habilitaConflitos, filtraSolicitacoesPendentes]);
    /* SOCKET */
    /**
     * Função que envia um array de informações para atualizar todos os conectados no Controle Diário.
     * @param {Array} data Data para ser enviada ao socket, e atualizar todas as telas.
     */
    const atualizaSocket = (socketLocal = null, data) => {
        if (socketLocal && socketLocal.readyState === WebSocket.OPEN) {
            if (data) {
                socketLocal.send(JSON.stringify(data));
            }
        } else {
            console.error('Socket is not open or is null');
        }
    };
    const atualizaExecutandoList = (pessoa, setPacienteEmExecucaoList) => {
        setPacienteEmExecucaoList((oldList) => {
            let newList = [...oldList];
            if (pessoa.action === 'new_connection') {
                const index = newList.findIndex(p => p.id === pessoa.id);
                if (index !== -1) {
                    newList[index] = pessoa;
                } else {
                    newList.push(pessoa);
                }
            } else if (pessoa.action === 'update_connections_list') {
                newList = pessoa.connections ? pessoa.connections : [];
            } else if (pessoa.action === 'disconnect') {
                newList = newList.filter(filter => filter.id !== pessoa.id);
            }
            return newList; // Retorna a nova lista para atualizar o estado
        });
    };
    const selecionaData = async (dataSaveLocal) => {
        setDataAtual(dataSaveLocal.format('dddd - DD/MM/YYYY'));
        setDataOriginal(dataSaveLocal);
        setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
        adicionarParametroNaUrl('data', dataSaveLocal.format('YYYY-MM-DD'));
    };
    /* ==AGENDA== */
    /**
     * Agrupa os dados de atendimento por horário e conta solicitações pendentes.
     *
     * @param {Array} data - Array de objetos de atendimento.
     * @param {number} data[].hora_atendimento - Hora do atendimento.
     * @param {number} data[].terapeuta_id - ID do terapeuta.
     * @param {string} data[].terapeuta_nome - Nome do terapeuta.
     * @param {number} data[].id - ID do atendimento.
     * @param {Array} data[].solicitacao_de_alteracaos - Array de solicitações de alteração.
     * @param {string} data[].solicitacao_de_alteracaos[].status - Status da solicitação de alteração.
     * @returns {Object} Objeto contendo os dados agrupados por horário e a contagem de solicitações pendentes.
     * @returns {Array} return.data2 - Array de objetos agrupados por horário.
     * @returns {string} return.data2[].hora - Hora do atendimento.
     * @returns {Array} return.data2[].atendimentos - Array de atendimentos para a hora específica.
     * @returns {number} return.localContagemSolicitacoesPendentes - Contagem de solicitações pendentes.
     */
    const agrupaPorHorario = (data) => {
        let atendimentoPorHorario = [];
        let data2 = [];
        let localContagemSolicitacoesPendentes = 0;
        for (let index = 0; index < data.length; index++) {
            const el = data[index];
            const nextEl = data[index + 1];
            const horaAgora = el.hora_atendimento;
            const horaDepois = (nextEl !== undefined) ? nextEl.hora_atendimento : false;
            if (horaDepois) {
                if (horaAgora === horaDepois) {
                    atendimentoPorHorario.push(el);
                } else {
                    atendimentoPorHorario.push(el);
                    data2.push({
                        hora: horaAgora,
                        atendimentos: atendimentoPorHorario
                    });
                    atendimentoPorHorario = [];
                }
            } else {
                atendimentoPorHorario.push(el);
                data2.push({
                    hora: horaAgora,
                    atendimentos: atendimentoPorHorario
                });
            }

            if (el.solicitacao_de_alteracaos && el.solicitacao_de_alteracaos.filter(filter => filter.status === 'Aguardando').length > 0) {
                localContagemSolicitacoesPendentes = localContagemSolicitacoesPendentes + 1;
            }
        }
        return data2;
    };
    /**
     * Compara dois objetos com base no campo `hora_atendimento`.
     *
     * @param {Object} a - O primeiro objeto a ser comparado.
     * @param {Object} b - O segundo objeto a ser comparado.
     * @param {string} a.hora_atendimento - O horário de atendimento do primeiro objeto no formato 'HH:mm:ss'.
     * @param {string} b.hora_atendimento - O horário de atendimento do segundo objeto no formato 'HH:mm:ss'.
     * @returns {number} - Retorna -1 se `a.hora_atendimento` for anterior a `b.hora_atendimento`, 1 se for posterior, e 0 se forem iguais.
     */
    function compareHorario(a, b) {
        // Convertendo os horários para objetos Date para comparar corretamente
        const horarioA = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + a.hora_atendimento);
        const horarioB = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + b.hora_atendimento);

        // Comparando os horários e retornando o resultado da comparação
        if (horarioA < horarioB) {
            return -1;
        }
        if (horarioA > horarioB) {
            return 1;
        }
        return 0;
    }
    /**
     * Função para obter os agendamentos do controle diário.
     * 
     * @function
     * @param {number|null} [controleid=null] - ID do controle diário. Se for null, será utilizado o controleDiarioId do estado.
     * @param {boolean} [socketSend=false] - Indica se deve enviar dados via socket.
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando os agendamentos são obtidos e processados.
     * 
     * @example
     * getAgendamentosControleDiario(123, true);
     * 
     * @description
     * Esta função obtém os agendamentos do controle diário, processa os dados recebidos e atualiza o estado do componente com as informações necessárias.
     * 
     * - Define os estados de carregamento.
     * - Obtém o elemento DOM da tabela de controle e armazena as informações de scroll.
     * - Obtém o ID do controle diário.
     * - Faz uma chamada ao serviço AtendimentoService para obter os dados dos agendamentos.
     * - Processa os dados recebidos, incluindo a verificação de conflitos e a definição de cores e últimas alterações.
     * - Ordena os dados por horário de atendimento.
     * - Atualiza os estados do componente com os dados processados.
     * - Lida com erros e define o estado de carregamento como falso em caso de falha.
     */
    const getAgendamentosControleDiario = useCallback(async (controleid = null, socketSend = false) => {
        setCarregandoAtendimentos(true);
        setAlgoCarregando(true);
        try {
            const scrollDom = document.getElementById('tabela-controle');
            if (scrollDom) {
                const { scrollTop, scrollLeft } = scrollDom;
                setScrollTopInfo({ scrollTop: scrollTop, scrollLeft: scrollLeft });
            }
            const id = (controleid === null) ? controleDiarioId : controleid;
            getControleDiario(id);
            AtendimentoService.getByControleDiarioId(id)
                .then(({ data }) => {
                    let terapeutasListLocal = [];
                    data.map(atendimento => {
                        const el = atendimento;
                        if (terapeutasListLocal.filter((filter) => filter.value === el.terapeuta_id).length === 0) {
                            terapeutasListLocal.push({ value: el.terapeuta_id, label: `(${el.id}) ${el.terapeuta_nome}` });
                        }
                        atendimento.cor = AtendimentoAlteracaoService.getCorStatus(atendimento.atendimento_alteracaos);
                        atendimento.ultima_alteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
                        /* CONFLITOS */
                        const realizadoOuNulo = el.sugestao_recepcao === null || el.sugestao_recepcao === 1;
                        const horario_sala = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.estacao_id === el.estacao_id && filter.horarios_atendimento_id === el.horarios_atendimento_id;
                        }).length > 0;
                        const horario_terapeuta = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && (filter.terapeuta_id !== null && filter.terapeuta_id === el.terapeuta_id) && filter.horarios_atendimento_id === el.horarios_atendimento_id;
                        }).length > 0;
                        const horario_paciente = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.paciente_id === el.paciente_id && filter.horarios_atendimento_id === el.horarios_atendimento_id && filter.estacao_id === el.estacao_id;
                        }).length > 0;
                        const sem_terapeuta = realizadoOuNulo && !el.terapeuta_id;
                        const sem_status = realizadoOuNulo && !el.sugestao_recepcao;
                        const sem_liberacao = realizadoOuNulo && !el.liberado;
                        const realizado_nao_liberado = realizadoOuNulo && el.sugestao_recepcao === 1 && !el.liberado;
                        const liberado_realizado_sem_codigo = realizadoOuNulo && !realizado_nao_liberado && !el?.codigo_1;
                        const liberado_realizado_sem_codigo2 = false;
                        const sem_execucao = realizadoOuNulo && !realizado_nao_liberado && !el.executado;
                        const sem_guia = el.executado === 'executado' && !el.guia;
                        const executado_nao_realizado = el.executado === 'executado' && el.sugestao_recepcao !== 1;
                        const substituido = data.filter((filter) =>
                            filter.id !== el.id &&
                            el.tipo_atendimento_id !== 5 &&
                            filter.horarios_atendimento_id === el.horarios_atendimento_id &&
                            filter.estacao_id === el.estacao_id &&
                            filter.tipo_atendimento_id === 5).length > 0;
                        const erros = {

                        };
                        const conflitos = {
                            'id': atendimento.id,
                            'horario_sala': horario_sala,
                            'horario_terapeuta': horario_terapeuta,
                            'horario_paciente': horario_paciente,
                            'sem_terapeuta': sem_terapeuta,
                            'sem_status': sem_status,
                            'sem_liberacao': sem_liberacao,
                            'realizado_nao_liberado': realizado_nao_liberado,
                            'liberado_realizado_sem_codigo': liberado_realizado_sem_codigo,
                            'liberado_realizado_sem_codigo2': liberado_realizado_sem_codigo2,
                            'sem_execucao': sem_execucao,
                            'sem_guia': sem_guia,
                            'executado_nao_realizado': executado_nao_realizado,
                        };
                        atendimento.substituido = substituido;
                        atendimento.conflitos = conflitos;
                        return atendimento;
                    });
                    data.sort(compareHorario);
                    //#region SETTERS
                    setTerapeutasList(terapeutasListLocal.sort((a, b) => {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    }));
                    setAtendimentosNormal(data);
                    setAtendimentosOriginal(data);
                    setAlgoCarregando(false);
                    setCarregandoAtendimentos(false);
                    setQuantidadeDeAtualizacoes(0);
                    //#endregion
                });
        } catch (error) {
            setCarregandoAtendimentos(false);
            console.error(error);
        }
    }, [controleDiarioId]);
    const salvarControleDiario = useCallback(async (semTeste = false) => {
        let semBloqueio = true;
        let semAviso = true;
        for (let index = 0; index < atendimentosOriginal.length; index++) {
            const e = atendimentosOriginal[index];
            if (!semTeste) {
                if (semAviso) {
                    semAviso = !e.conflitos.sala_terapeuta && !e.conflitos.horario_paciente && !e.executado_nao_realizado;
                }
            }
            semBloqueio = !e.conflitos.sem_terapeuta &&
                !e.conflitos.sem_status &&
                !e.conflitos.sem_liberacao &&
                !e.conflitos.realizado_nao_liberado &&
                !e.conflitos.liberado_realizado_sem_codigo2 &&
                !e.conflitos.sem_guia &&
                !e.conflitos.sem_execucao;
            if (!semBloqueio) {
                break;
            }
        }

        if (!semAviso) {
            if (conferirConflitosModalRef.current) {
                conferirConflitosModalRef.current.openModal(atendimentosOriginal);
                return false;
            }
        }
        if (semBloqueio) {
            Swal.fire({
                title: 'Deseja mesmo salvar esse Controle Diário?',
                text: 'O Controle Diário não podera ser alterado após essa ação.',
                html: `<div style='width: 100%; display: grid'>
                    <label for='obs'>Observação (Opcional): </label>
                    <textarea style='width: 100%; border-radius: 10px; border: .2 solid grey' id='obs' name='obs' rows='5' placeholder='Digite alguma observação para o controle diário...'></textarea>
                </div>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Salvar',
                cancelButtonText: 'Cancelar ação',
                icon: 'question'
            }).then((e) => {
                if (e.isConfirmed) {
                    let obs = e?.value?.obs;
                    api.patch('api/controle-diario/' + controleDiarioId, { id: controleDiarioId, salvo: moment.utc(), salvo_por: usuario.user.id, obs: obs }).then((response) => {
                        if (response.status === 400) {
                            Swal.fire('Não foi possível salvar Controle Diário!', 'Houve um problema ao salvar o Controle Diário, porque algum atendimento não possue Guia válida, atualize a tela (F5) e confira novamente os Erros.', 'error');
                        }
                        if (response.status === 200) {
                            window.location.reload();
                        }
                    }).catch((e) => {
                        const errorMessage = e.response?.data?.message || 'Não foi possível salvar o Controle Diário';
                        Swal.fire(`Não foi possível salvar o Controle Diário(${e.response.status})`, errorMessage, 'error');
                    });
                }
            });
        } else {
            Swal.fire('Pendencias não Resolvidas!', 'Existem erros que impédem o Controle Diário de ser Salvo.', 'error');
        }
    }, [atendimentosOriginal, controleDiarioId, usuario.user.id]);
    const expandFiltro = (e) => {
        if (e.target === document.getElementById('div-expande-filtro')) {
            if (expand) {
                fechaFiltro(null);
            } else {
                abreFiltro();
            }
        }
    };
    const abreFiltro = () => {
        setExpand(true);
    };
    const fechaFiltro = (event) => {
        try {
            if (!event) {
                setExpand(false);
            } else {
                if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                    setExpand(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    function adicionarParametroNaUrl(chave, valor) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function removerParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Remove o parâmetro
        parametros.delete(chave);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function recuperarParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Retorna o valor do parâmetro
        return parametros.get(chave);
    }
    //#endregion
    //#region HANDLES
    const handleChangeEnableEdit = useCallback(() => {
        Swal.fire({
            title: 'Deseja mesmo habilitar a edição?',
            text: 'O processo de edição é muito delicado, e exige atenção. Depois de habilitado, é obrigatório salva-lo para sair.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Habilitar',
            cancelButtonText: 'Cancelar'
        }).then((e) => {
            if (e.isConfirmed) {
                setEnableEdit(true);
                Swal.fire('Edição Habilitada!', 'A edição do Controle Diário foi habilitada com sucesso!', 'success');
            }
        }
        );
    }, []);
    const handleCloseModalControleDeExecucao = useCallback((insideSocket = null, localControleDiarioId) => {
        if (localControleDiarioId) {
            getAgendamentosControleDiario(localControleDiarioId);
        }
        if (insideSocket && insideSocket.readyState === WebSocket.OPEN) {
            atualizaSocket(insideSocket, { action: 'contador' });
        } else {
            if (socket && socket.readyState === WebSocket.OPEN) {
                atualizaSocket(socket, { action: 'contador' });
            }
        }
    }, [getAgendamentosControleDiario, socket]);
    const handleOpenModalAtendimento = useCallback((atendimento, editavel = true) => {
        setIsAtendimentoEditavel(editavel);
        setIsModalAtendimentoOpen(true);
        setAtendimentoModalSelecionado(atendimento);
    }, []);
    function handleOpenModalPendencias(conflitos) {
        pendenciasRef.current.openModal(conflitos);
    }
    const handleLimpaFiltros = () => {
        setTerapeutaSelecionado(null);
        setTerapeutaSelecionadoOpt(null);
        setStatusSelecionado(null);
        setStatusSelecionadoOpt(null);
        setPacienteSelecionado(null);
        setPacienteSelecionadoOpt(null);
        setPlanoSaudeSelecionado(null);
        setPlanoSaudeSelecionadoOpt(null);
        setFiltraSolicitacoesPendentes(null);
    };
    const handleFiltraPaciente = (paciente_id) => {
        setPacienteSelecionado(paciente_id);
        setPacienteSelecionadoOpt(pacienteOptions.filter((el) => Number(el.value) === Number(paciente_id)));
    };
    const handleOpenSolicitacaoDeAlteracao = () => {
        if (solicitacaoDeAlteracaoModalRef.current) {
            solicitacaoDeAlteracaoModalRef.current.open({ controle_diario_id: controleDiarioId });
        }
    };
    /* ==MODAL CONTROLE DE EXECUÇÃO== */
    const handleOpenModalCheckin = (paciente_id) => {
        handleLimpaFiltros();
        setPacienteSelecionado(paciente_id);
        setPacienteSelecionadoOpt(pacienteOptions.filter((el) => Number(el.value) === Number(paciente_id)));
        if (checkInModalRef.current) {
            checkInModalRef.current.openModal(controleDiarioId, paciente_id);
        }
    };
    /* ==MODAL COMENTARIOS== */
    const handleModalComentarios = (dados) => {
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    };
    const handleChangeHabilitaSucesso = (event) => {
        setHabilitaSucesso(event.target.checked);
    };
    const handleChangeHabilitaErros = (event) => {
        setHabilitaErros(event.target.checked);
    };
    const handleChangeHabilitaConflitos = (event) => {
        setHabilitaConflitos(event.target.checked);
    };
    const handleClickDay = (day, month, year) => {
        let dia = moment.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
        let dataSaveLocal = moment.utc(dia);
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const handleToggleCalendario = () => {
        setIsCalendarioVisible(!isCalendarioVisible);
    };
    const handleFechaCalendario = (event) => {
        try {
            if (event === null) {
                setIsCalendarioVisible(false);
            } else {
                if (calendarioRef.current && !calendarioRef.current.contains(event.target)) {
                    setIsCalendarioVisible(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleExportToExcelControle = () => {
        api.get('api/controle-diario/exportar-para-excel/' + controleDiarioId).then(({ data }) => {
            // Crie um link para baixar o arquivo
            const link = document.createElement('a');
            link.href = data.file;
            link.download = `atendimentos_${controleDiarioId}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.error(error);
            snackbar.displayMessage('Erro ao exportar controle diário para excel!', 'error');
        });
    };
    const handleFiltraSolicitacaoPendente = () => {
        if (filtraSolicitacoesPendentes === null) {
            setFiltraSolicitacoesPendentes(true);
        } else {
            if (filtraSolicitacoesPendentes === false) {
                setFiltraSolicitacoesPendentes(null);
            } else {
                if (filtraSolicitacoesPendentes === true) {
                    setFiltraSolicitacoesPendentes(false);
                }
            }
        }
    };
    const handleVerSolicitacao = (solicitacao) => {
        SolicitacaoDeAlteracaoService.visualizar(solicitacao);
    };
    const handleAbreSolicitacaoDeAlteracao = (solicitacao) => {
        SolicitacaoDeAlteracaoService.aprovaReprova(solicitacao,
            () => {
                Swal.fire('Solicitação de alteração aprovada com sucesso!', '', 'success');
                getAgendamentosControleDiario(controleDiarioId);
                atualizaSocket(socket, { action: 'contador' });
            },
            () => {
                Swal.fire('Solicitação de alteração reprovada com sucesso!', '', 'success');
                getAgendamentosControleDiario(controleDiarioId);
                atualizaSocket(socket, { action: 'contador' });
            },
            () => {
                let dataSaveLocal = dataOriginal;
                selecionaData(dataSaveLocal);
            }
        );
    };
    const handleChangeAtendimentoLiberado = useCallback(async (e, atendimentoId) => {
        const atualiza = (atendimentoId, value) => {
            setAtendimentos((prevAtendimentos) =>
                prevAtendimentos.map((atendimento) =>
                    atendimento.id === atendimentoId ? { ...atendimento, liberado: e.value } : atendimento
                )
            );
            const url = `/api/atendimento/${atendimentoId}`;
            const updateObj = { id: atendimentoId, liberado: value };
            console.log('updateObj', updateObj);
            api.patch(url, updateObj).then(() => {
                snackbar.displayMessage('Status de liberação atualizado com sucesso!', 'success');
                getAgendamentosControleDiario(controleDiarioId);
                atualizaSocket(socket, { action: 'contador' });
            }).catch((e) => {
                snackbar.displayMessage('Erro ao atualizar status de liberação!', 'error');
                console.error(e);
            });
        };
        setAlgoCarregando(true);
        e = e.target ? e.target : e;
        const option = {
            title: 'Deseja confirma a liberação?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar',
        };
        if (Number(e.value) === 1) {
            await Swal.fire(option).then((result) => {
                if (!result.isConfirmed) {
                    let dataSaveLocal = dataOriginal;
                    selecionaData(dataSaveLocal);
                    setAlgoCarregando(false);
                } else {
                    atualiza(atendimentoId, e.value);
                }
            });
        } else {
            atualiza(atendimentoId, e.value);
        }
    }, [dataOriginal, controleDiarioId, snackbar, getAgendamentosControleDiario, atualizaSocket, socket]);
    const handleChangeStatusRecepcao = useCallback(async (e, atendimento, isUltimaAlteracao) => {
        setAlgoCarregando(true);
        unstable_batchedUpdates(() => {
            e = e.target ? e.target : e;
            /* variaveis */
            let url = '';

            const atualizaStatus = (url, atendimentoId, status) => {
                api.patch(url, { sugestao_recepcao: status }).then(({ data }) => {
                    snackbar.displayMessage('Status atualizado com sucesso!', 'success');
                    getAgendamentosControleDiario(controleDiarioId);
                    atualizaSocket(socket, { action: 'contador' });
                }).catch((e) => {
                    console.error(e);
                });
            };

            /* execuções */
            if (e.slug === 'falta-paciente' || e.slug === 'falta-terapeuta') {
                let option = {
                    title: 'Deseja confirma a falta?',
                    showDenyButton: true,
                    confirmButtonText: 'Confirmar',
                    denyButtonText: 'Cancelar',
                };
                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        url = `/api/atendimento/${atendimento.id}`;
                        atualizaStatus(url, atendimento.id, e.value);
                    } else {
                        let dataSaveLocal = dataOriginal;
                        selecionaData(dataSaveLocal);
                    }
                });
            } else {
                url = `/api/atendimento/${atendimento.id}`;
                atualizaStatus(url, atendimento.id, e.value);
            }
        });
    }, [dataOriginal, getAgendamentosControleDiario, controleDiarioId, snackbar, selecionaData, atualizaSocket, socket]);
    /*NAVEGADORES de DATA*/
    const handleClickNextDay = () => {
        let dataSaveLocal = moment.utc(dataOriginal).add(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = moment.utc(dataOriginal).subtract(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    //#endregion
    //#region GETTERS
    const getControleDiario = (id = null) => {
        if (id === null) {
            Swal.fire({
                title: 'Deseja carregar os atendimentos?',
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    api.get(`/api/controle-diario/carregar/${dataSelecionada}/${usuario.user.id}`)
                        .then((response) => {
                            switch (response.status) {
                                case 200:
                                    snackbar.displayMessage('Dia carregado com sucesso!', 'success');
                                    window.location.reload();
                                    break;
                                case 400:
                                    snackbar.displayMessage('Dia já foi carregado!', 'error');
                                    break;
                                case 401:
                                    Swal.fire('Existe um dia que não foi salvo', 'Por favor, confira os dias anteriores para salva-lo, e tente novamente!', 'error');
                                    break;
                                default:
                                    snackbar.displayMessage('Erro ao carregar dia!', 'error');
                                    break;
                            }
                        }).catch((error) => {
                            switch (error.response.status) {
                                case 200:
                                    snackbar.displayMessage('Dia carregado com sucesso!', 'success');
                                    break;
                                case 400:
                                    snackbar.displayMessage('Dia já foi carregado!', 'error');
                                    break;
                                case 401:
                                    Swal.fire('Existe um dia que não foi salvo', 'Por favor, confira os dias anteriores para salva-lo, e tente novamente!', 'error');
                                    break;
                                default:
                                    snackbar.displayMessage('Erro ao carregar dia!', 'error');
                                    break;
                            }
                            console.error(error);
                        });
                }
            });
        } else {
            api.get(`/api/controle-diario/${id}?id=${id}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento,solicitacao_de_alteracaos.user`)
                .then((response) => {
                    setControleDiario(response.data);
                    setSolicitacoesDeAlteracao(response.data.solicitacao_de_alteracaos);
                    setTemDiaCarregado(true);
                });
        }
    };
    /**
     * Função que coleta o controle diário do dia da semana atual, e salva o id.
     * @returns {boolean}
     */
    const getControleDiarioId = async (data = null) => {
        setCarregandoControleDiario(true);
        let dataTeste = data === null ? dataSelecionada : data;
        await api.get(`/api/controle-diario?filter[dia]=${dataTeste}&with=solicitacao_de_alteracaos,solicitacao_de_alteracaos.horarios_atendimento`)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario?.length < 1) {
                    setCarregandoControleDiario(false);
                    setTemDiaCarregado(false);
                    return false;
                }
                controleDiario = controleDiario[controleDiario?.length - 1];
                setControleDiarioId(controleDiario?.id);
                setControleDiario(controleDiario);
                setSolicitacoesDeAlteracao(controleDiario.solicitacao_de_alteracaos);
                setTemDiaCarregado(true);
                setCarregandoControleDiario(false);
                return controleDiario?.id;
            })
            .catch(e => {
                console.error(e);
                setCarregandoControleDiario(false);
                return false;
            });
    };
    /**
     * Função que define o status do atendimento via useState.
     * @returns {void}
     */
    const getStatusAtendimento = async () => {
        await api.get('/api/status-atendimento')
            .then(response => {
                let statusList = response.data;
                statusList = statusList.map(status => {
                    return {
                        value: status.id,
                        label: status.status,
                        slug: status.slug,
                    };
                });
                setStatusAtendimento(statusList);
            });
    };
    /**
     * Função que define o tipo do atendimento via useState.
     * @returns {void}
     */
    const getTipoAtendimento = async () => {
        await api.get('/api/tipo-atendimento')
            .then(response => {
                let tiposList = response.data;
                tiposList = tiposList.map(status => {
                    return {
                        value: status.id,
                        label: status.tipo_atendimento
                    };
                });
                setTipoAtendimentos(tiposList);
            });
    };
    /**
     * Função que define o status selecionado pelo valor.
     * @param {Array} statusList Array com a lista de status
     * @param {Number} value 
     * @returns {*}
     */
    const getStatusSelecionadoByValue = (statusList, value) => {
        if (!statusList || statusList.length < 1 || statusList === undefined || statusList === null) return;
        return statusList.filter(status => status.value === value)[0] || null;
    };
    /**
     * Função que coleta todos os planos cadastrados em uma lista de seleção
     */
    const getPlanosSaude = () => {
        api.get('api/plano-saude').then(({ data }) => {
            let planoSaudeOptions = [];
            data.forEach((planoSaude) => {
                planoSaudeOptions.push({ value: planoSaude.id, label: planoSaude.plano });
            });
            setPlanosSaude(planoSaudeOptions);
        });
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        if (controleDiarioId) {
            getAgendamentosControleDiario(controleDiarioId);
        }
    }, [controleDiarioId]);
    useEffect(() => {
        setContagemSolicitacoesPendentes(solicitacoesDeAlteracao?.filter(filter => filter.status === 'Aguardando')?.length ? solicitacoesDeAlteracao.length : 0);
    }, [solicitacoesDeAlteracao]);
    /* WEBSOCKET */
    useEffect(() => {
        /* Apenas vai iniciar a conexão com o socket, caso o controle diário seja não salvo e não tenha conexão ainda. */
        if (!socket) {
            if (controleDiario && controleDiario.id && controleDiario?.salvo === null) {
                /* Criação da URL do WS */
                const params = `?controle_diario_id=${controleDiario.id}`;
                const isHost = window.location.hostname === 'localhost';
                const urlWS = isHost
                    ? `ws://localhost:6001/ws/controle_diario${params}`
                    : `wss://sistema.neurointensiva.com/ws/controle_diario${params}`;

                /* Cria conexão */
                const socketLocal = new WebSocket(urlWS);

                /* Define o listener open do socket */
                socketLocal.addEventListener('open', function () {
                    /* Define o estado global do socket como verdadeiro(Ou seja, conectado) */
                    setSocketState(true);
                });

                /* Define o listener close do socket */
                socketLocal.addEventListener('close', function () {
                    /* Define o estado global do socket como falso(Ou seja, desconectado) */
                    setSocketState(false);
                });

                /* Define o listener message do socket */
                socketLocal.addEventListener('message', function (event) {
                    try {
                        /* Verifica se a data veio corretamente */
                        if (event.data !== null && event.data !== undefined && event.data !== '') {
                            /* Transforma o texto JSON em um objeto */
                            const data = (JSON.parse(event.data));
                            /* Verifica se tem data */
                            if (data) {
                                /* Verifica se tem id do paciente */
                                if (data.action === 'contador') {
                                    /* Se não tiver id do paciente, significa que não veio do Controle
                                    de Execução, então deve apenas adicionar ao contador de atualizaç~eos. */
                                    const newQuantidade = Number(quantidadeDeAtualizacoes) + 1;
                                    setQuantidadeDeAtualizacoes(newQuantidade);
                                } else {
                                    /* Chama a função que atualiza a lista */
                                    atualizaExecutandoList(data, setPacienteEmExecucaoList);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                });

                /* Define o listener error do socket */
                socketLocal.addEventListener('error', function (event) {
                    console.error('Erro: ', event);
                });

                /* Salva a variável para ser utilizada em outras partes do código */
                setSocket(socketLocal);
            }
        }
    }, [socket, controleDiario]);
    useEffect(() => {
        setAlgoCarregando(true);
        try {
            if (!houveAlteracaoStatusEdicao || !houveInclusaoAtendimento) return;
            getAgendamentosControleDiario(controleDiarioId);
            setHouveAlteracaoStatusEdicao(false);
            setHouveInclusaoAtendimento(false);
            setAlgoCarregando(true);
        } catch (error) {
            console.error(error);
        }
    }, [houveAlteracaoStatusEdicao, houveInclusaoAtendimento]);
    useEffect(() => {
        if (dataOriginal) {
            onLoad();
        }
    }, [dataOriginal]);
    useEffect(() => {
        setAlgoCarregando(true);
        if (atendimentosOriginal.length > 0) {
            filtrar().then(() => {
                let ele = document.getElementById('tabela-controle');
                if (ele) {
                    ele.scrollTop = scrollTopInfo?.scrollTop;
                    ele.scrollLeft = scrollTopInfo?.scrollLeft;
                }
                setAlgoCarregando(false);
            });
            if (planoSaudeSelecionado || pacienteSelecionado || statusSelecionado || terapeutaSelecionado || filtraSolicitacoesPendentes !== null) {
                setIsFiltred(true);
            } else {
                setIsFiltred(false);
            }
        }
    }, [terapeutaSelecionado, pacienteSelecionado, planoSaudeSelecionado, statusSelecionado, atendimentosOriginal, habilitaErros, habilitaConflitos, habilitaSucesso, pacienteEmExecucaoList, filtraSolicitacoesPendentes]);
    useEffect(() => {
        if (controleDiario && usuario) {
            setIsEditable(
                controleDiario?.salvo === null
                /* || !(
                    (usuario?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || usuario?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                    && enableEdit
                ) */
            );
        }
    }, [enableEdit, usuario, controleDiario]);
    useEffect(() => {/* ONLOAD */
        const dataFromUrl = recuperarParametroDaUrl('data');
        if (dataFromUrl) {
            selecionaData(moment.utc(dataFromUrl));
        } else {
            selecionaData(moment.utc());
        }
        api.get('api/controle-diario/is/nao/salvo').then(({ data }) => {
            if (data.length > 0) {
                const naoSalvo = data[0];
                if (moment.utc(naoSalvo.carregado).format('DD/MM/YYYY') !== moment.utc(dataFromUrl).format('DD/MM/YYYY')) {
                    Swal.fire({
                        title: 'Existe um dia que não foi salvo',
                        text: 'Por favor, confira os dias anteriores para salva-lo, e tente novamente! \n \n Dia: ' + moment.utc(naoSalvo.dia).format('DD/MM/YYYY'),
                        icon: 'error',
                        timer: 10000
                    });
                    const day = moment.utc(naoSalvo.dia).format('YYYY-MM-DD');
                    const newDayMark = [];
                    newDayMark[day] = 'Dia não salvo, clique aqui e salve-o.';
                    setDaysMark(newDayMark);
                }
            }
        }).catch((error) => {
            console.error(error);
        });
        getStatusAtendimento();
        getTipoAtendimento();
        fetchPacienteData();
        fetchSalaEstacaoData();
        fetchHorarioAtendimentoComFimData(moment.utc(dataFromUrl).weekday());
        fetchModalidadeData();
        fetchTipoAtendimentosData();
        getPlanosSaude();
        /* VERIFICADORES DE CLICK */
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Adiciona o listener de redimensionamento da janela
        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', (e) => {
            fechaFiltro(e);
            handleFechaCalendario(e);
        });
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
                handleFechaCalendario();
            }
        });
        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', (e) => {
                fechaFiltro(e);
                handleFechaCalendario(e);
            });
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                    handleFechaCalendario();
                }
            });
        };
    }, []);
    //#endregion
    //#region HTML
    return (
        <AtendimentoReposicaoProvider>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='d-flex justify-content-around flex-row'>
                        {(permissoes['criacao_edicao'] && temDiaCarregado === true)
                            ? <button className='btn-padrao w-200px h-38px m-2'
                                style={{
                                    backgroundColor:
                                        controleDiario?.salvo === null
                                            ? '#35CF58'
                                            : (usuario?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || usuario?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                                                ? 'lightgrey'
                                                : 'red',
                                    cursor:
                                        controleDiario?.salvo === null
                                            ? 'pointer'
                                            : (usuario?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || usuario?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                                                ? 'not-allowed'
                                                : 'context-menu',
                                    color:
                                        controleDiario?.salvo === null
                                            ? 'white'
                                            : (usuario?.tipo_user?.toUpperCase() === 'ADMINISTRADOR' || usuario?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR')
                                                ? 'black'
                                                : 'white'
                                }}
                                onClick={() => { controleDiario?.salvo === null && salvarControleDiario(); }}>
                                {controleDiario?.salvo === null ? 'Salvar' : 'Salvo'}
                            </button>
                            : <button className='btn-padrao w-200px h-38px m-2'
                                style={moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada
                                    ? { backgroundColor: '#40C5ED' }
                                    : {
                                        backgroundColor: controleDiario?.salvo === null ? '#35CF58' : 'lightgrey',
                                        cursor: controleDiario?.salvo === null ? 'pointer' : 'not-allowed',
                                        color: controleDiario?.salvo === null ? 'white' : 'black'
                                    }} onClick={() => moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada && getControleDiario()}>
                                {moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada ? 'Carregar' : 'Não carregado'}
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className='d-flex align-center justify-center w-100per mt-1 flex-col'>
                <div className='div-controlediario-cabecalho'>
                    {temDiaCarregado === true &&
                        <div className='div-filter-style'>
                            <div ref={filtroDivRef} style={{ position: 'relative', width: '100%' }}>
                                <div id='div-expande-filtro' className="w-100per pointer" onClick={(e) => expandFiltro(e)}>
                                    <div className='d-flex align-items-center' style={{ display: 'flex' }} onClick={(e) => abreFiltro()}>
                                        <p id="blank" style={{ marginBottom: -30 }}>
                                            {expand ? 'Fechar ' : 'Expandir '}Filtro
                                        </p>
                                    </div>
                                    <div className='pointer flex-nowrap d-flex flex-row align-items-center' style={{ marginLeft: '86%' }}>
                                        {isFiltred
                                            ? <FaBroom color="yellow" id="botao-limpa-filtro" size={32} onClick={(e) => { handleLimpaFiltros(); }} title='Conteúdo filtrado...' />
                                            : <div style={{ width: '25px', height: '25px' }}></div>
                                        }
                                        <AiFillCaretDown onClick={(e) => expand ? fechaFiltro() : abreFiltro()} id='botao-expande-filtro' color="white" size={26} style={{ marginLeft: '10px' }} />
                                    </div>
                                </div>
                                <Form className='div-expand-over' style={expand ? { width: '80%' } : { display: 'none' }}>
                                    <div style={DivContentDisplay} className="row">
                                        <div className="col-6">
                                            <label className="label-filtro">Terapeuta:</label>
                                            <div className="d-flex justify-around align-center w-100per">
                                                <Select id={'terapeutas'} name={'terapeutas'} options={terapeutasList} value={terapeutaSelecionadoOpt}
                                                    onChange={(e) => { setTerapeutaSelecionado(e.value); setTerapeutaSelecionadoOpt({ value: e.value, label: e.label }); }} />
                                                <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                    setTerapeutaSelecionado(null);
                                                    setTerapeutaSelecionadoOpt(null);
                                                }} size={25} />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <label className="label-filtro">Status de Atendimento:</label>
                                            <div className="d-flex justify-around align-center">
                                                <Select
                                                    name="status"
                                                    options={statusAtendimento}
                                                    value={statusSelecionadoOpt}
                                                    onChange={(e) => {
                                                        setStatusSelecionado(e.value);
                                                        setStatusSelecionadoOpt({ value: e.value, label: e.label });
                                                    }} />
                                                <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                    setStatusSelecionado(null);
                                                    setStatusSelecionadoOpt(null);
                                                }} size={25} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={DivContentDisplay} className="row">
                                        <div className="col-6">
                                            <label className="label-filtro">Paciente:</label>
                                            <div className="d-flex justify-around align-center">
                                                <Select
                                                    name={'pacientes'}
                                                    options={pacienteOptions}
                                                    value={pacienteSelecionadoOpt}
                                                    filterOption={customPacienteFilter}
                                                    onChange={(e) => {
                                                        setPacienteSelecionado(e.value);
                                                        setPacienteSelecionadoOpt({ value: e.value, label: e.label });
                                                    }} />
                                                <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                    setPacienteSelecionado(null);
                                                    setPacienteSelecionadoOpt(null);
                                                }} size={25} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="label-filtro">Plano de Saúde:</label>
                                            <div className="d-flex justify-around align-center">
                                                <Select
                                                    name={'planosSaude'}
                                                    options={planosSaudeList}
                                                    value={planoSaudeSelecionadoOpt}
                                                    onChange={(e) => {
                                                        setPlanoSaudeSelecionado(e.value);
                                                        setPlanoSaudeSelecionadoOpt({ value: e.value, label: e.label });
                                                    }} />
                                                <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                    setPlanoSaudeSelecionado(null);
                                                    setPlanoSaudeSelecionadoOpt(null);
                                                }} size={25} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    }
                    <div className={
                        (temDiaCarregado === true)
                            ? 'div-seletor-style'
                            : 'div-seletor-style-solo'
                    }>
                        <div className='w-100per d-flex flex-col'>
                            <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                                <button className='btn-icone'>
                                    <AiOutlineLeft className='pointer' color='black' size={22} onClick={() => handleClickPrevDay()} />
                                </button>
                                <span className='texto-navegacao me-2 ml-2' onClick={(e) => handleToggleCalendario()}>{`${dataAtual.charAt(0).toUpperCase() + dataAtual.slice(1)}`}</span>
                                <button className='btn-icone'>
                                    {moment.utc(dateNow).format('YYYY-MM-DD') !== dataSelecionada ?
                                        <AiOutlineRight className='pointer' color='black' size={22} onClick={() => handleClickNextDay()} />
                                        : <AiOutlineRight color='white' size={22} />
                                    }
                                </button>
                            </div>
                        </div>
                        <div ref={calendarioRef} className={isCalendarioVisible ? 'div-expand-over calendar-div p-0 w-fit-content-i bg-white' : 'some'}>
                            <div className='w-100 d-flex justify-content-end'>
                                <button className='btn-icone' onClick={(e) => handleToggleCalendario()}>
                                    <AiOutlineClose color='red' size={22} />
                                </button>
                            </div>

                            <Calendario dateLimit={true} style={{ borderTop: 'none' }} daysMark={daysMark} handleClickDay={handleClickDay} maxDate={moment.utc(dateNow).format('YYYY-MM-DD')} selectedDate={dataSelecionada}></Calendario>
                        </div>
                    </div>
                    {temDiaCarregado === true &&
                        <div className='div-buttons-style'>
                            {usuario.user.tipo_user.toUpperCase() === 'DESENVOLVEDOR'
                                && <div className="form-check-reverse form-switch me-2 ms-2" style={{ color: '' }}>
                                    <label className="form-check-label" htmlFor="habilita-edit" style={{ textDecoration: habilitaErros ? 'none' : 'line-through' }}>
                                        Habilitar Edição
                                    </label>
                                    <input className="form-check-input" type="checkbox" role="switch" id="habilita-edit-input" checked={enableEdit} onChange={() => handleChangeEnableEdit()} />
                                </div>
                            }
                            {(carregandoAtendimentos)
                                ? <span className='text-center d-flex flex-col justify-content-center align-center'>
                                    <div className='d-flex flex-row w-fit-content text-center align-items-center'>
                                        <ScaleLoader css={override} color={'#fd931a'} />
                                        <BsSearch className='icone manual-searching' size={30} />
                                    </div>
                                </span>
                                : <></>
                            }
                            {permissoes && permissoes['controle_diario'] && permissoes['controle_diario']['novo_atendimento'] &&
                                <button className='btn-padrao w-200px h-38px m-2' type='button' style={{ backgroundColor: '#00B19C' }} onClick={() => setIsModalAdicionarAtendimentoVisible(true)}>
                                    Novo Atendimento
                                </button>
                            }
                            <Dropdown
                                clickMode={true}
                                buttonOver={true}
                                substituicao
                                button={
                                    <button className='btn-padrao bg-transparente h-38px me-2' title='Filtrar por solicitações pendentes.' type='button' style={{ backgroundColor: '#7441BF', position: 'relative', zIndex: 1000 }} data-bs-toggle="dropdown" aria-expanded="false"
                                        onMouseEnter={(e) => setBtnSolicitacaoHover(true)}
                                        onMouseLeave={(e) => setBtnSolicitacaoHover(false)}>
                                        {contagemSolicitacoesPendentes > 0 &&
                                            <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px 20px 0 0', transform: 'translateX(30px)' }}>
                                                {contagemSolicitacoesPendentes}
                                            </ContagemPendentes>
                                        }
                                        <BsBell style={{ margin: 0 }} />
                                        <ContagemPendentes className={`d-flex justify-content-center align-items-center${btnSolicitacaoHover && ' bg-laranja-i'}`} type="button" style={{ position: 'absolute', margin: '30px 100px 0 0', transform: 'translateX(30px)', backgroundColor: '#7441BF' }}>
                                            <BsArrowDownLeft style={{
                                                margin: 0,
                                            }} />
                                        </ContagemPendentes>
                                    </button>
                                }
                                content={
                                    <ul className="card w-400px h-auto max-h-500px p-4 me-4 list-unstyled" style={{ position: 'relative' }}>
                                        <li className='d-flex justify-content-between align-items-center pt-2' key={'solicitacao:title-controle-diario:' + controleDiario.id}>
                                            <h6 className='font-preta p-0 m-0'>Solicitações de Alteração</h6>
                                            <button className='btn-icone p-0 m-0' type={'button'} onClick={handleOpenSolicitacaoDeAlteracao}>
                                                <IoAddCircleOutline size={30} />
                                            </button>
                                        </li>
                                        <li><hr className='w-100'></hr></li>
                                        {solicitacoesDeAlteracao?.length > 0 &&
                                            solicitacoesDeAlteracao.map((solicitacao, index) => {
                                                let corSolicitacao = solicitacao.status.toLowerCase();
                                                return <li className='ps-2' key={'solicitacao:' + index + '-controle_diario:' + controleDiarioId}>
                                                    <button
                                                        type="button"
                                                        className={`w-100 d-flex justify-content-between btn btn-${corSolicitacao === 'aguardando' ? 'warning' : corSolicitacao === 'aprovado' ? 'success' : 'danger'}`}
                                                        onClick={() => corSolicitacao === 'aguardando' ? handleAbreSolicitacaoDeAlteracao(solicitacao) : handleVerSolicitacao(solicitacao)}
                                                        style={linhasSolicitacao[corSolicitacao]}>
                                                        <span className='w-100 d-flex justify-content-between'>
                                                            <span>
                                                                Envio: {moment(solicitacao.created_at).format('HH:mm')}
                                                                <span className='ms-2'>
                                                                    {solicitacao?.user?.tipo_user?.toLowerCase() === 'terapeuta'
                                                                        ? <FaUserMd size={28} />
                                                                        : solicitacao?.user?.tipo_user?.toLowerCase() === 'agendamento'
                                                                            ? <FaUserTie size={28} />
                                                                            : solicitacao?.user?.tipo_user?.toLowerCase() === 'administrador' || solicitacao?.user?.tipo_user?.toLowerCase() === 'desenvolvedor'
                                                                                ? <FaUserCog size={28} />
                                                                                : <FaUser size={28} />
                                                                    }
                                                                </span>
                                                            </span>
                                                            {solicitacao.horarios_atendimento &&
                                                                <p className='p-0 m-0'>
                                                                    Horario: {moment.utc(solicitacao.horarios_atendimento.horario).format('HH:mm')}
                                                                </p>
                                                            }
                                                        </span>
                                                        <IoMdOpen size={20} className='ml-20px' />
                                                    </button>
                                                </li>;
                                            })
                                        }
                                    </ul>
                                }
                            />
                            <button className='btn-padrao h-38px me-2' title='Filtrar por solicitações pendentes.' type='button' style={{ backgroundColor: '#7441BF' }}
                                onClick={(e) => handleFiltraSolicitacaoPendente()}
                                onMouseEnter={(e) => setBtnNotificacaoHover(true)}
                                onMouseLeave={(e) => setBtnNotificacaoHover(false)}>
                                {filtraSolicitacoesPendentes === true
                                    ? <BsBellFill />
                                    : filtraSolicitacoesPendentes === false
                                        ? <BsBellSlash />
                                        : <BsBell />
                                }
                                <ContagemPendentes className={btnNotificacaoHover ? 'bg-laranja-i' : ''} type="button" style={{ position: 'absolute', margin: '30px 100px 0 0', transform: 'translateX(30px)', backgroundColor: '#7441BF' }}>
                                    {filtraSolicitacoesPendentes === true || filtraSolicitacoesPendentes === false
                                        ? <AiFillFilter />
                                        : <FiFilter />
                                    }
                                </ContagemPendentes>
                            </button>
                            <button className='btn-padrao h-38px me-2' title='Atualiza Controle Diário.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => { getAgendamentosControleDiario(controleDiarioId); }}>
                                {quantidadeDeAtualizacoes > 0 &&
                                    <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px -30px 0 0' }}>
                                        {quantidadeDeAtualizacoes}
                                    </ContagemPendentes>
                                }
                                <FiRefreshCcw size={18} className={`rotacao-padrao${algoCarregando ? ' rodando' : ''}`}></FiRefreshCcw>
                            </button>
                            <button className='btn-padrao h-38px me-2' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => handleExportToExcelControle()}>
                                <FiDownload size={18} />
                            </button>
                            <button className='btn-padrao h-38px help me-2' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={e => setIsModalLegendaVisible(true)}>
                                ?
                            </button>
                        </div>
                    }
                </div>
                {carregandoControleDiario
                    ? <span className='text-center d-flex flex-col justify-content-center align-center'>
                        Encontrando Controle Diário...
                        <div className='d-flex flex-row w-fit-content text-center align-items-center'>
                            <ScaleLoader css={override} size={150} color={'#fd931a'} />
                            <BsSearch className='icone manual-searching' size={30} />
                        </div>
                    </span>
                    : !carregandoControleDiario && !temDiaCarregado &&
                    <div className="justify-center w-100per align-center text-center p-4 font-vermelho">
                        Não existe controle diário carregado.
                    </div>
                }
                {temDiaCarregado === true
                    && <SelfLibVirtualizedTable
                        containerHeight={windowHeight - 325}
                        tableClassName={'table-controle-diario'}
                        tableHeader={() => (
                            <tr>
                                {tableHeaders.map(header => (
                                    <th key={header.key} className={header.className} title={header.title} dangerouslySetInnerHTML={{ __html: header.label }} />
                                ))}
                            </tr>
                        )}
                        tableFooter={() => (
                            <tr>
                                <th colSpan={12} className='td-footer d-flex justify-content-center align-items-center flex-wrap p-0 m-0 ps-4 pe-4'>
                                    <div className='d-flex'>
                                        <span className='p-0 m-0 me-2 d-flex align-items-center'>Total:
                                            <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                {quantidadeAtendimentos}
                                            </b>
                                        </span>
                                        <div className="form-check-reverse form-switch me-2">
                                            <label className="form-check-label" htmlFor="apenas-sucesso-input" style={{ textDecoration: habilitaSucesso ? 'none' : 'line-through' }}>
                                                <span className='font-sucesso p-0 m-0'>Sucessos</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeSucessos}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-sucesso" type="checkbox" role="switch" id="apenas-sucesso-input" checked={habilitaSucesso} onChange={(e) => handleChangeHabilitaSucesso(e)} />
                                        </div>
                                        <div className="form-check-reverse form-switch me-2 ms-2" style={{ color: '' }}>
                                            <label className="form-check-label" htmlFor="apenas-erros-input" style={{ textDecoration: habilitaErros ? 'none' : 'line-through' }}>
                                                <span className='font-pendente p-0 m-0'>Erros</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeErros}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-erro" type="checkbox" role="switch" id="apenas-erros-input" checked={habilitaErros} onChange={(e) => handleChangeHabilitaErros(e)} />
                                        </div>
                                        <div className="form-check-reverse form-switch me-2 ms-2">
                                            <label className="form-check-label" htmlFor="apenas-conflitos-input" style={{ textDecoration: habilitaConflitos ? 'none' : 'line-through' }}>
                                                <span className='font-laranja p-0 m-0'>Conflitos</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeConflitos}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-conflito" type="checkbox" role="switch" id="apenas-conflitos-input" checked={habilitaConflitos} onChange={(e) => handleChangeHabilitaConflitos(e)} />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        )}
                        atendimentos={atendimentos}
                        minimizedSubNavbar={minimizedSubNavbar}
                        windowHeight={windowHeight}
                        AtendimentoService={AtendimentoService}
                        statusAtendimento={statusAtendimento}
                        getStatusSelecionadoByValue={getStatusSelecionadoByValue}
                        permissoes={permissoes}
                        handleAbreSolicitacaoDeAlteracao={handleAbreSolicitacaoDeAlteracao}
                        handleVerSolicitacao={handleVerSolicitacao}
                        linhasSolicitacao={linhasSolicitacao}
                        handleOpenModalAtendimento={handleOpenModalAtendimento}
                        isEditable={isEditable}
                        handleOpenSolicitacaoDeAlteracao={handleOpenSolicitacaoDeAlteracao}
                        usuario={usuario}
                        pacienteSelecionado={pacienteSelecionado}
                        handleFiltraPaciente={handleFiltraPaciente}
                        controleDiario={controleDiario}
                        handleOpenModalCheckin={handleOpenModalCheckin}
                        handleChangeStatusRecepcao={handleChangeStatusRecepcao}
                        opcoesLiberado={opcoesLiberado}
                        handleChangeAtendimentoLiberado={handleChangeAtendimentoLiberado}
                        pacienteEmExecucaoList={pacienteEmExecucaoList}
                        handleOpenModalPendencias={handleOpenModalPendencias}
                        handleModalComentarios={handleModalComentarios}
                    />
                }
                {false === true &&
                    <table ref={tableRef} className='table-controle-diario'>
                        <thead key={'head'}>
                            {/* COLUNAS DA TABELA */}
                            <tr key={'head-line'}>
                                {tableHeaders.map(header => (
                                    <th key={header.key} className={header.className} title={header.title} dangerouslySetInnerHTML={{ __html: header.label }} />
                                ))}
                            </tr>
                        </thead>
                        <Tbody
                            atendimentos={atendimentos}
                            minimizedSubNavbar={minimizedSubNavbar}
                            windowHeight={windowHeight}
                            AtendimentoService={AtendimentoService}
                            statusAtendimento={statusAtendimento}
                            getStatusSelecionadoByValue={getStatusSelecionadoByValue}
                            permissoes={permissoes}
                            handleAbreSolicitacaoDeAlteracao={handleAbreSolicitacaoDeAlteracao}
                            handleVerSolicitacao={handleVerSolicitacao}
                            linhasSolicitacao={linhasSolicitacao}
                            handleOpenModalAtendimento={handleOpenModalAtendimento}
                            isEditable={isEditable}
                            handleOpenSolicitacaoDeAlteracao={handleOpenSolicitacaoDeAlteracao}
                            usuario={usuario}
                            pacienteSelecionado={pacienteSelecionado}
                            handleFiltraPaciente={handleFiltraPaciente}
                            controleDiario={controleDiario}
                            handleOpenModalCheckin={handleOpenModalCheckin}
                            handleChangeStatusRecepcao={handleChangeStatusRecepcao}
                            opcoesLiberado={opcoesLiberado}
                            handleChangeAtendimentoLiberado={handleChangeAtendimentoLiberado}
                            pacienteEmExecucaoList={pacienteEmExecucaoList}
                            handleOpenModalPendencias={handleOpenModalPendencias}
                            handleModalComentarios={handleModalComentarios} />
                        <tfoot>
                            <tr>
                                <th colSpan={12} className='td-footer d-flex justify-content-center align-items-center flex-wrap p-0 m-0 ps-4 pe-4'>
                                    <div className='d-flex'>
                                        <span className='p-0 m-0 me-2 d-flex align-items-center'>Total:
                                            <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                {quantidadeAtendimentos}
                                            </b>
                                        </span>
                                        <div className="form-check-reverse form-switch me-2">
                                            <label className="form-check-label" htmlFor="apenas-sucesso-input" style={{ textDecoration: habilitaSucesso ? 'none' : 'line-through' }}>
                                                <span className='font-sucesso p-0 m-0'>Sucessos</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeSucessos}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-sucesso" type="checkbox" role="switch" id="apenas-sucesso-input" checked={habilitaSucesso} onChange={(e) => handleChangeHabilitaSucesso(e)} />
                                        </div>
                                        <div className="form-check-reverse form-switch me-2 ms-2" style={{ color: '' }}>
                                            <label className="form-check-label" htmlFor="apenas-erros-input" style={{ textDecoration: habilitaErros ? 'none' : 'line-through' }}>
                                                <span className='font-pendente p-0 m-0'>Erros</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeErros}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-erro" type="checkbox" role="switch" id="apenas-erros-input" checked={habilitaErros} onChange={(e) => handleChangeHabilitaErros(e)} />
                                        </div>
                                        <div className="form-check-reverse form-switch me-2 ms-2">
                                            <label className="form-check-label" htmlFor="apenas-conflitos-input" style={{ textDecoration: habilitaConflitos ? 'none' : 'line-through' }}>
                                                <span className='font-laranja p-0 m-0'>Conflitos</span>:
                                                <b className='ms-1' style={{ color: isFiltred ? '#ccc000' : '#000' }}>
                                                    {quantidadeConflitos}
                                                </b>
                                            </label>
                                            <input className="form-check-input switch-conflito" type="checkbox" role="switch" id="apenas-conflitos-input" checked={habilitaConflitos} onChange={(e) => handleChangeHabilitaConflitos(e)} />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>}
            </div>
            {/* Modal de Controle de Execução */}
            <ControleDeExecucaoModal ref={checkInModalRef} user={usuario.user} controleDiarioSocket={socket} onClose={handleCloseModalControleDeExecucao}></ControleDeExecucaoModal>
            {/* Modal de Visualização de Conflitos e Erros do Atendimento */}
            <Conflitos ref={pendenciasRef} />
            {/* Modal de Confirmação de Conflitos do Atendimento */}
            <ConferirConflitos ref={conferirConflitosModalRef} salvarControleDiario={() => { salvarControleDiario(true); }} />
            {/* Modal de Legenda */}
            {isModalLegendaVisible && <LegendaDefault onClose={() => setIsModalLegendaVisible(false)} legendaMode={'controle-diario'} />}
            {/* Modal do Responsavel da Sala */}
            {isModalResponsavelVisible && <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />}
            {/* Modal de Visualização e Edição do Comentário */}
            <ComentariosModal ref={modalComentariosRef} atualizaSocket={() => atualizaSocket(socket, { action: 'contador' })} getAgendaDia={getAgendamentosControleDiario}></ComentariosModal>
            {/* Modal de Novo Atendimento */}
            {isModalAdicionarAtendimentoVisible
                && <AdicionarAgendamento
                    onClose={() => { setIsModalAdicionarAtendimentoVisible(false); }}
                    date={dataOriginal.format('YYYY-MM-DD')}
                    agendamentos={atendimentosNormal}
                    updateAgenda={() => getAgendamentosControleDiario(controleDiarioId)}
                    controleDiarioId={controleDiarioId}
                    atualizaSocket={() => atualizaSocket(socket, { action: 'contador' })}
                    incluirAtendimento={true}
                />}
            {/* Modal de Alteração do Atendimento */}
            {isModalAtendimentoOpen
                && <AtendimentoModal
                    atendimentoId={atendimentoModalSelecionado.id}
                    statusAtendimento={statusAtendimento}
                    controleDiarioId={controleDiarioId}
                    tipoAtendimentoOptions={tipoAtendimentosOptions}
                    horarioAtendimentoOptions={horarioAtendimentoComFimOptions}
                    pacienteOptionsOriginal={pacienteOptions}
                    modalidadesOptions={modalidadesOptions}
                    pacientes={pacientes}
                    estacaoOptions={salaEstacaoOptions}
                    atendimentos={atendimentosOriginal}
                    atendimento={atendimentoModalSelecionado}
                    setHouveAlteracaoStatusEdicao={() => setHouveAlteracaoStatusEdicao(true)}
                    onClose={() => [setIsModalAtendimentoOpen(false), getAgendamentosControleDiario()]}
                    isAtendimentoEditavel={isAtendimentoEditavel}
                    atualizaSocket={() => atualizaSocket(socket, { action: 'contador' })}
                    snackbar={snackbar} />
            }
            {/* Badge que mostra se esta online com os outros controles diários ou não. */}
            <LiveBadge sincronizado={socketState} texto={false} />
            <SolicitacaoDeAlteracaoModal ref={solicitacaoDeAlteracaoModalRef} user_id={usuario.user.id} controle_diario_id={controleDiarioId} onClose={() => {
                getAgendamentosControleDiario(controleDiarioId);
                atualizaSocket(socket, { action: 'contador' });
            }} solicitacaoMode={usuario?.user?.tipo_user?.toLowerCase()} />
        </AtendimentoReposicaoProvider>
    );
    //#endregion
}