import api from './api';

class TerapiaPacienteService {
    static index = () => {
        return api.get('api/terapia-paciente');
    };

    static store = (obj) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":97119}
        */
                
        return api.post('api/terapia-paciente', obj);
    };

    static show = (id) => {
        return api.get(`api/terapia-paciente/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":260}
        */
                
        return api.put(`api/terapia-paciente/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/terapia-paciente/${id}?id=${id}`);
    };
}

export default TerapiaPacienteService;