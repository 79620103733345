import Carregamento from 'components/Carregamento';
import A4Page from 'components/Folha/A4-Retrato';
import { useAuth } from 'context/auth';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import { ChangeCase } from 'services/ChangeCase';
const GeradorDeDocumento = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [filename, setFilename] = useState();
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        setloading(true);
        try {
            if (id) {
                api.get(`api/evolucao-paciente/${id}?id=${id}&with=paciente,atendimento`)
                    .then(({ data }) => {
                        if (user.tipo_user.toUpperCase() !== 'ADMINISTRADOR' || user.tipo_user.toUpperCase() !== 'DESENVOLVEDOR') {
                            if (user.terapeuta) {
                                if (user.terapeuta[0].id !== data.terapeuta.id) {
                                    navigate('dashboard/');
                                }
                            } else {
                                const userId = user.id;
                                api.get(`api/user?filter[plano_saude_id]=${data.plano_saude_id}&filter[tipo_user]=AUDITOR`)
                                    .then((response) => {
                                        const data2 = response.data2;
                                        if (!data2.filter((filter) => filter.id === userId)) {
                                            navigate('dashboard/');
                                        }
                                    })
                                    .catch((error) => console.error(error));
                            }
                        }
                        const newData = data;
                        const paciente_nome = newData.paciente.nome;
                        const dataCriacao = moment.utc(newData.atendimento.created_at);
                        const dataCriacaoFileFormated = dataCriacao.format('DD-MM-YYYY');
                        const dataCriacaoTitleFormated = dataCriacao.format('DD/MM/YYYY');
                        const relatorio = JSON.parse(newData.relatorio)[0];
                        delete (relatorio['']);
                        const pageTitle = `${paciente_nome} (${dataCriacaoTitleFormated})`;
                        let content = '';
                        Object.entries(relatorio).forEach(([key, value]) => {
                            content += `${ChangeCase.toTitleCase(key)}: ${value}</br>`;
                        });
                        const arrayContent = [
                            {
                                title: pageTitle,
                                content: content
                            }
                        ];
                        const localTitle = 'Evolução Diária';
                        const localFilename = `evolucao-${paciente_nome}-${dataCriacaoFileFormated}`;
                        setTitle(localTitle);
                        setContent(arrayContent);
                        setFilename(localFilename);
                        setloading(false);
                    });
            }
        } catch (error) {
            console.error(error);
        }
    }, [id]);
    return (
        <>
            {loading && <Carregamento />}
            <A4Page
                onCloseClick={handleGoBack}
                title={title}
                content={content}
                filename={filename}
            />
        </>
    );
};

export default GeradorDeDocumento;