import React, {useMemo} from 'react';

import {AiOutlineDownload} from 'react-icons/ai';
import moment from 'moment/moment';
import {TableDefault} from '../../../../components/TableDefault';
import {useAuth} from '../../../../context/auth';
import baseURL from '../../../../services/baseUrl';

export function TableDocumentosAssinadosTerapeuta({ source }) {

  const data = React.useMemo(() => source, [source]);
  const { user } = useAuth();

  const columns = useMemo(
      () => [
        {
          Header: 'Nome Documento',
          accessor: 'documentos_administrativo.nome_documento',
        },
        {
          Header: 'Data Assinatura',
          accessor: 'assinado_dia',
          Cell: ({cell}) => (
            (<div>
                    {moment(cell.row.original.assinado_dia).format('DD/MM/YYYY HH:ss')}
                </div>
            )
        ),
        },
        {
          Header: 'Download',
          accessor: 'name',
          Cell: ({ cell }) => (
              (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a href={baseURL + `api/documento-administrativo/${cell.row.original.id}/${user.id}/download`}
                       target='_blank' style={{ color: '#7340BD', cursor: 'pointer' }}
                       title={'Download do documento'} rel="noreferrer"><AiOutlineDownload color='#7340BD' size={22} /></a>
                  </div>
              )
          ),
        },
      ],
      [],
  );

  return (
      <TableDefault columns={columns} source={data} />
  );
}
