import React, { useRef } from 'react';
import { Modal } from '../styles';
import { Form } from '@unform/web';
import TextArea from '../../TextArea';
import PacienteService from '../../../services/PacienteService';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';

export default function ModalInformacoesComplementares({ onClose, paciente, setInfoComplementar, infoComplementar, callBack, readOnly = false }) {
    const formRef = useRef(null);
    const snackbar = useSnackbar();
    const id = 'modal-informacoes-complementares';

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleSubmit = (data) => {
        Swal.fire({
            title: 'Confirmar o cadastro das informações?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (paciente === null || paciente === undefined) {
                    setInfoComplementar(data);
                    snackbar.displayMessage('Informações complementares cadastradas!', 'success');
                    onClose();
                } else {
                    PacienteService.update(data, paciente.id);
                    snackbar.displayMessage('Informações complementares atualizadas!', 'success');
                    callBack();
                    onClose();
                }
            }
        }).catch((error) => {
            console.error(error);
            Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
        });
    };

    return (
        <Modal id={id} onClick={handleOutsideClick} appElement={document.getElementById('root')}>
            <div className='div-modal pt-0'>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div></div>
                        <ModalTitulo>Informações complementares</ModalTitulo>
                        <label className='btn-outline text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className='d-flex flex-column px-lg-5' style={{ fontSize: '15px', color: '#4906AB', fontWeight: '600' }}>
                        <TextArea title="Gosto de:" name="gosto_de" defaultValue={infoComplementar && infoComplementar.gosto_de} readOnly={readOnly} />
                        <TextArea title="Não gosto de:" name="nao_gosto_de" defaultValue={infoComplementar && infoComplementar.nao_gosto_de} readOnly={readOnly} />
                        <TextArea title="Meus Reforçadores são:" name="meus_reforcadores" defaultValue={infoComplementar && infoComplementar.meus_reforcadores} readOnly={readOnly} />
                        <TextArea title="Meu Hiperfoco é:" name="meu_hiperfoco" defaultValue={infoComplementar && infoComplementar.meu_hiperfoco} readOnly={readOnly} />
                        <TextArea title="Informações Complementares:" name="informacoes_complementares" defaultValue={infoComplementar && infoComplementar.informacoes_complementares} readOnly={readOnly} />

                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <button className='mt-3 w-25 align-self-center btn-padrao' onClick={() => onClose()}>
                                Voltar
                            </button>
                            {!readOnly &&
                                <button className='mt-3 w-25 align-self-center btn-padrao' type="submit">
                                    Confirmar
                                </button>
                            }
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}