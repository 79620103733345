import React, { useEffect, useState } from 'react';
import Recepcao from '../../../layouts/Recepcao';
import { AiFillFileAdd } from 'react-icons/ai';

import { Container } from './styles';
import TablePlanoSaude from './TablePlanoSaude';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import PlanoSaudeService from '../../../services/PlanoSaudeService';
import PlanoSaudeModal from '../../../components/Modais/PlanoSaudeModal';
import api from '../../../services/api';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function PlanoDeSaude() {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
        /*==VARS INFORMAÇÕES da TELA==*/
        const titulo = 'CADASTRO PLANO DE SAUDE';
        const rota = 'dashboard/{modulo}/planodesaude';
        const descricao = 'Tela que cadastra os planos de saúdes e suas terapias cobertas.';
        /*==VARS UTILITÁRIOS DA PÁGINA==*/
        const [loading, setLoading] = useState(true);
        const [color, setColor] = useState('#8ed4ae');
        const [id, setId] = useState(0);
        /*==VARS MODAL==*/
        const [modalIsOpen, setIsOpen] = useState(false);
        /*==VARS LISTAS==*/
        const [planos, setPlanos] = useState([]);
        const [terapias, setTerapias] = useState([]);
        const [especialidades, setEspecialidades] = useState([]);
        const [obj, setObj] = useState({});
    //#endregion

    const setEditObject = (objeto) => {
        setObj(objeto);
    };

    const handleNewPlano = () => {
        setObj(undefined);
        setIsOpen(true);
    };

    const loadPlanos = () => {
        api.get('api/terapia').then(({data})=>{
            setTerapias(data);
        });
        api.get('api/especialidade').then(({data})=>{
            setEspecialidades(data);
        });
        PlanoSaudeService.index('with=plano_saude_terapia,plano_saude_terapia.terapium,plano_saude_terapia.especialidades')
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPlanos(data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadPlanos();
    }, []);

    return (
        <Recepcao>
            <Container>
                <div className="div-titulo w-100per">
                    <label>Plano de Saúde</label>
                </div>
                <button onClick={() => handleNewPlano()} className="btn-padrao">
                    <AiFillFileAdd style={{ marginRight: 5 }} /> Novo Plano
                </button>
                {
                    (planos.length !== 0 && loading === false) ?
                        <TablePlanoSaude setObj={setEditObject} setIsOpen={setIsOpen} callback={loadPlanos} source={planos} /> :
                        <ClipLoader color={color} loading={loading} css={override} size={50} />
                }
            </Container>
            {modalIsOpen && <PlanoSaudeModal setIsOpen={setIsOpen} callback={loadPlanos} data={obj} terapias={terapias} especialidades={especialidades}/>}
        </Recepcao>
    );
}
