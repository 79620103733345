import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  forwardRef,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, Error } from './styles';
import ReactInputMask from 'react-input-mask';

const InputMask = forwardRef(({
  name,
  title,
  placeholder,
  maskFormat,
  containerStyle = {},
  required,
  icon: Icon,
  ...rest
}, ref) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  // Se uma ref externa foi passada, associe-a ao input interno
  const setInputRef = (node) => {
    inputRef.current = node;

    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label style={{ marginTop: 10, fontSize: 13 }}>{required ? '*' : ''}{title}</label>
      <Container
        style={{
          ...containerStyle,
          marginTop: 0,
        }}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid='input-container'
      >
        {Icon && <Icon size={20} />}
        <ReactInputMask
          ref={setInputRef}
          defaultValue={defaultValue}
          mask={maskFormat}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder={placeholder}
          autoComplete='off'
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color='#c53030' size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
});

InputMask.displayName = 'InputMask';

export default InputMask;
