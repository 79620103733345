import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from '../../Input';
import EstacaoService from '../../../services/EstacaoService';
import SalaService from '../../../services/SalaService';
import Select from '../../Select';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../context/snackbar';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const EstacaoModal = ({ modalIsOpen, setIsOpen, callback, modalData, estacoesList }, ref) => {
    let subtitle;
    // eslint-disable-next-line no-unused-vars
    const description = 'Modal de configuração de estações.';

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const [salas, setSalas] = useState([]);
    const [selectedSala, setSelectedSala] = useState(null);
    const [loading, setLoading] = useState(false);

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(data) {
        try {
            setLoading(true);
            if (selectedSala === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                let validador = true;
                for (let index = 0; index < estacoesList.length; index++) {
                    const e = estacoesList[index];
                    if (modalData === null) {
                        if (e.estacao == data.estacao) {
                            if (e.sala_id == selectedSala.value) {
                                validador = false;
                                break;
                            }
                        }
                    } else {
                        if (e.id !== modalData.id) {
                            if (e.estacao == data.estacao) {
                                if (e.sala_id == selectedSala.value) {
                                    validador = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (validador) {
                    if (modalData === null) {
                        await EstacaoService.store({
                            'sala_id': selectedSala.value,
                            'estacao': data.estacao
                        }).then(() => {
                            snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                        }).catch(e => {
                            snackbar.displayMessage('Houve um erro ao cadastrar!', 'error');
                            console.error(e);
                        });
                        setIsOpen(false);
                        setSelectedSala(null);
                        callback();
                    } else {
                        await EstacaoService.update({
                            'id': modalData.id,
                            'sala_id': selectedSala.value,
                            'estacao': data.estacao
                        }, modalData.id).then(() => {
                            snackbar.displayMessage('Cadastro atualizado com sucesso!', 'success');
                        }).catch(e => {
                            snackbar.displayMessage('Houve um erro ao atualizar cadastro!', 'error');
                            console.error(e);
                        });
                        setIsOpen(false);
                        setSelectedSala(null);
                        callback();
                    }
                } else {
                    Swal.fire('Erro ao Incluir ou Atualizar Estação', 'A estação que você esta tentando criar, ou alterar, esta com nome repetido. Tente outro nome.', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Houve um erro ao cadastrar!', '', 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const loadSalas = () => {
        SalaService.index()
            .then(({ data }) => {
                let s = [];
                data.forEach((x) => {
                    s.push({ value: x.id, label: x.sala },);
                });
                setSalas(s);
            });
    };

    const getDefaultEstacao = (sala) => {
        if (!modalData) return;
        const salaSelecionada = sala.filter(data => {
            return data.value === modalData.sala_id;
        })[0];
        setSelectedSala(salaSelecionada);
    };

    useEffect(() => {
        getDefaultEstacao(salas);
    }, [salas]);

    useEffect(() => {
        loadSalas();
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>Estação</h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}>
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 450 }}>
                                <Select name={'sala_id'} label={'Sala:'} options={salas} onChange={setSelectedSala} value={selectedSala} />
                            </div>
                        </div>

                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 450 }}>
                                <Input name={'estacao'} title={'Estação:'} required={'required'} defaultValue={modalData === null ? '' : modalData.estacao}
                                    onChange={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : (modalData && modalData.id)
                                    ? 'Alterar'
                                    : 'Adicionar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(EstacaoModal);
