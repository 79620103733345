import { Mostrador } from 'components/MostradoresColoridos';
import api from 'services/api';

const { AiOutlineReconciliation, AiOutlineSolution, AiOutlineUserAdd } = require('react-icons/ai');
const { BsBell } = require('react-icons/bs');
const { CgChart } = require('react-icons/cg');
const { FaUsers } = require('react-icons/fa');
const { MdQueryStats, MdEmail } = require('react-icons/md');

class Dashboard {
    constructor(tipoUsuario, user_id) {
        this.tipoUsuario = tipoUsuario.toLowerCase();
        this.informacoes = [];
        this.baseDados = this.definirBaseDeDados();
        this.user_id = user_id;
        this.atualizarDashboard();
    }

    definirBaseDeDados() {
        return {
            administrador: {
                titulo: 'Dashboard Administrador',
                descricao: 'Aqui você pode visualizar todas as funcionalidades do sistema.',
                tipo_titulo: 'padrao',

                enable_header: true,
                enable_acesso_rapido: true,
                enable_recados: true,
                enable_mostradores: true,

                acesso_rapido: [
                    [
                        {
                            titulo: 'Relatórios Estratégicos',
                            icone: <AiOutlineReconciliation size={25} />,
                            link: '/dashboard/administrador/relatorios'
                        },
                        {
                            titulo: 'Cadastro de Plano de Saúde',
                            icone: <AiOutlineSolution size={25} />,
                            link: '/dashboard/administrador/planodesaude'
                        },
                    ],
                    [
                        {
                            titulo: 'Cadastro de Usuário',
                            icone: <AiOutlineUserAdd size={25} />,
                            link: '/dashboard/administrador/funcionarios'
                        },
                        {
                            titulo: 'Atendimentos',
                            icone: <FaUsers size={25} />,
                            link: '/dashboard/administrador/atendimentos'
                        }
                    ]
                ],
                mostradores_url: 'api/estatisticas/administrador/',
                mostradores: [
                    {
                        titulo: 'Total Pacientes',
                        cor: 'turquesa_medio',
                        valor: this.informacoes[0] || 0,
                        geometria: 'quadrado'
                    },
                    {
                        titulo: 'Afastados',
                        cor: 'laranja_abobora',
                        valor: this.informacoes[1] || 0,
                        geometria: 'quadrado',
                    },
                    {
                        titulo: 'Em Férias',
                        cor: 'rosa_brilhante',
                        valor: this.informacoes[2] || 0,
                        geometria: 'quadrado',
                    },
                    {
                        titulo: 'Inativos',
                        cor: 'ceu_azul_claro',
                        valor: this.informacoes[3] || 0,
                        geometria: 'quadrado',
                    }
                ]
            },
            desenvolvedor: {
                titulo: 'Dashboard Desenvolvedor',
                descricao: 'Aqui você pode acessar todas as funcionalidades do sistema, prontas ou teste.',
                tipo_titulo: 'padrao',

                enable_header: true,
                enable_acesso_rapido: true,
                enable_recados: true,
                enable_mostradores: true,

                acesso_rapido: [
                    [
                        {
                            titulo: 'Notificações',
                            icone: <BsBell size={25} />,
                            link: '/dashboard/desenvolvedor/notificacoes'
                        },
                        {
                            titulo: 'Recados',
                            icone: <MdEmail size={25} />,
                            link: '/dashboard/desenvolvedor/notificacoes/recados'
                        },
                    ],
                    [
                        {
                            titulo: 'Postman',
                            icone: <MdQueryStats size={25} />,
                            link: '/dashboard/desenvolvedor/homem-postagem'
                        },
                        {
                            titulo: 'Gráficos',
                            icone: <CgChart size={25} />,
                            link: '/dashboard/desenvolvedor/relatorios/graficos'
                        }
                    ],
                    [
                        {
                            titulo: 'Atendimentos',
                            icone: <FaUsers size={25} />,
                            link: '/dashboard/administrador/atendimentos'
                        },
                        {
                            titulo: 'Gráficos',
                            icone: <CgChart size={25} />,
                            link: '/dashboard/desenvolvedor/relatorios/graficos'
                        }
                    ]
                ],
                recados: true,
                mostradores_url: 'api/estatisticas/desenvolvedor/',
                mostradores: [
                    {
                        titulo: 'Pacientes',
                        cor: 'turquesa_medio',
                        valor: this.informacoes[0] ? typeof this.informacoes[0] === 'number' ? this.informacoes[0] : this.informacoes[0]?.valor : 0,
                        geometria: 'quadrado',
                        detalhamento: this.informacoes[0]?.detalhamento
                    },
                    {
                        titulo: 'Terapeutas',
                        cor: 'laranja_abobora',
                        valor: this.informacoes[1] ? typeof this.informacoes[1] === 'number' ? this.informacoes[1] : this.informacoes[1]?.valor : 0,
                        geometria: 'quadrado',
                        detalhamento: this.informacoes[1]?.detalhamento
                    },
                    {
                        titulo: 'Atendimentos',
                        cor: 'rosa_brilhante',
                        valor: this.informacoes[2] ? typeof this.informacoes[2] === 'number' ? this.informacoes[2] : this.informacoes[2]?.valor : 0,
                        geometria: 'quadrado',
                        detalhamento: this.informacoes[2]?.detalhamento
                    },
                    {
                        titulo: 'Agendamentos',
                        cor: 'ceu_azul_claro',
                        valor: this.informacoes[3] ? typeof this.informacoes[3] === 'number' ? this.informacoes[3] : this.informacoes[3]?.valor : 0,
                        geometria: 'quadrado',
                        detalhamento: this.informacoes[3]?.detalhamento
                    }
                ]
            },
            geral: {
                titulo: 'Dashboard Geral',
                descricao: 'Essa é a dashboard genérica.',
                tipo_titulo: 'padrao',

                enable_header: true,
                enable_acesso_rapido: false,
                enable_recados: true,
                enable_mostradores: false
            }
        };
    }

    getTitulo() {
        return this.dashboard.titulo;
    }

    getDescricao() {
        return this.dashboard.descricao;
    }

    getAcessoRapido() {
        return this.dashboard.acesso_rapido;
    }

    getRecados() {
        return this.dashboard.recados;
    }

    getMostradores() {
        return this.dashboard.mostradores;
    }

    getMostradoresUrl() {
        return this.dashboard.mostradores_url;
    }

    getEnableHeader() {
        return this.dashboard.enable_header;
    }

    getEnableAcessoRapido() {
        return this.dashboard.enable_acesso_rapido;
    }

    getEnableRecados() {
        return this.dashboard.enable_recados;
    }

    getEnableMostradores() {
        return this.dashboard.enable_mostradores;
    }

    async setMostradoresInformacao() {
        try {
            if (this.getEnableMostradores()) {
                const { data } = await api.get(this.getMostradoresUrl() + this.user_id);
                let informacoes = [];
                switch (this.tipoUsuario) {
                    case 'agendamento':
                    case 'recepcao':
                    case 'desenvolvedor':
                        informacoes = [
                            {
                                valor: data?.pacientes.total,
                                detalhamento: [
                                    {
                                        nome: 'Ativos',
                                        valor: data?.pacientes.ativos
                                    },
                                    {
                                        nome: 'Inativos',
                                        valor: data?.pacientes.inativos
                                    },
                                    {
                                        nome: 'Ferias',
                                        valor: data?.pacientes.ferias
                                    },
                                    {
                                        nome: 'Afastados',
                                        valor: data?.pacientes.afastados
                                    },
                                    {
                                        nome: 'Total',
                                        valor: data?.pacientes.total
                                    },
                                ]
                            },
                            {
                                valor: data?.terapeutas.total,
                                detalhamento: [
                                    {
                                        nome: 'Ativos',
                                        valor: data?.terapeutas.ativos
                                    },
                                    {
                                        nome: 'Inativos',
                                        valor: data?.terapeutas.inativos
                                    },
                                    {
                                        nome: 'Bloqueados',
                                        valor: data?.terapeutas.bloqueados
                                    },
                                    {
                                        nome: 'Liberados',
                                        valor: data?.terapeutas.liberados
                                    },
                                    {
                                        nome: 'Total',
                                        valor: data?.terapeutas.total
                                    },
                                ]
                            },
                            {
                                valor: data?.atendimentos.total,
                                detalhamento: [
                                    {
                                        nome: 'Atendidos Hoje',
                                        valor: data?.atendimentos.atendidos_hoje
                                    },
                                    {
                                        nome: 'Não Atendidos',
                                        valor: data?.atendimentos.nao_atendidos
                                    },
                                    {
                                        nome: 'Faltas Terapeuta',
                                        valor: data?.atendimentos.faltas_terapeutas
                                    },
                                    {
                                        nome: 'Faltas Paciente',
                                        valor: data?.atendimentos.faltas_pacientes
                                    },
                                    {
                                        nome: 'Reagendados',
                                        valor: data?.atendimentos.reagendados
                                    },
                                    {
                                        nome: 'Total',
                                        valor: data?.atendimentos.total
                                    },
                                ]
                            },
                            {
                                valor: data?.agendamentos.total,
                                detalhamento: [
                                    {
                                        nome: 'Padrão',
                                        valor: data?.agendamentos.padrao
                                    },
                                    {
                                        nome: 'Avaliação',
                                        valor: data?.agendamentos.avaliacao
                                    },
                                    {
                                        nome: 'Avaliação Feita',
                                        valor: data?.agendamentos.avaliacao_feita
                                    },
                                    {
                                        nome: 'Reposição',
                                        valor: data?.agendamentos.reposicao
                                    },
                                    {
                                        nome: 'Reposição Feita',
                                        valor: data?.agendamentos.reposicao_feita
                                    },
                                    {
                                        nome: 'Subistituíção',
                                        valor: data?.agendamentos.substituicao
                                    },
                                    {
                                        nome: 'Subistituição Feita',
                                        valor: data?.agendamentos.substituicao_feita
                                    },
                                    {
                                        nome: 'Aguardando Terapeuta',
                                        valor: data?.agendamentos.aguardando_terapeuta
                                    },
                                    {
                                        nome: 'Total',
                                        valor: data?.agendamentos.total
                                    },
                                ]
                            }
                        ];
                        break;
                    case 'administrador':
                        informacoes = [data?.total_pacientes, data?.afastados, data?.ferias, data?.inativos];
                        break;
                    default:
                        break;
                }
                this.informacoes = informacoes;
                this.atualizarDashboard();
            }
        } catch (error) {
            console.error(error);
        }
    }

    atualizarDashboard() {
        this.dashboard = this.baseDados[this.tipoUsuario] || this.baseDados.geral;
        if (this.dashboard.mostradores) {
            this.dashboard.mostradores = this.dashboard.mostradores.map((mostrador, index) => {
                mostrador.valor = this.informacoes[index]?.valor || 0;
                mostrador.detalhamento = this.informacoes[index]?.detalhamento;
                return mostrador;
            })
                .map((e) => {
                    if (typeof e === 'object') {
                        return new Mostrador(e.titulo, e.cor, e.valor, e.geometria, null, e.detalhamento);
                    } else {
                        return e;
                    }
                });
        }
        this.salvarNoLocalStorage();
    }

    salvarNoLocalStorage() {
        //localStorage.setItem('dashboard', JSON.stringify(this.dashboard));
    }
}

export { Dashboard };