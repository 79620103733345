import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import EspecialidadeTerapeuta from './EspecialidadesTerapeuta';
import CertificadosTerapeuta from './CertificadosTerapeuta';
import Modal from 'react-modal';
import TerapiasTerapeuta from './TerapiasTerapeuta';

function ModalDiplomas({ terapeuta, id = 'modalDiplomas', onClose, addNewEspecialidade, addNewTerapia, addNewCertificado }, ref) {

    const dados = null;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalTerapia, setModalTerapia] = useState(false);
    const [modalEspecialidade, setModalEspecialidade] = useState(false);
    const [modalCertificados, setModalCertificados] = useState(false);
    let subtitle;

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return { openModal, dados };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
        setModalCertificados(false);
        setModalEspecialidade(false);
        setModalTerapia(false);
    }, []);

    const handleOutsideClick = (e) => {
        setModalCertificados(false);
        setModalEspecialidade(false);
        setModalTerapia(false);
        if (e.target.id === id) onClose();
    };

    const handleOpenTerapia = () => {
        setModalTerapia(true);
    };

    const handleOpenEspecialidade = () => {
        setModalEspecialidade(true);
    };

    const handleOpenCertificados = () => {
        setModalCertificados(true);
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px',
            maxHeight: '70vh',
            width: '50vw',
            backgroundColor: '#FFFFFF'
        },
    };

    return (
        <div>
            <Modal id={id} onClick={e => handleOutsideClick(e)} isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyle}>
                {!modalTerapia && !modalEspecialidade && !modalCertificados ?
                    <div>
                        <div style={{ position: 'relative' }}>
                            <label className={'btn-fecha-modal'} onClick={e => closeModal()}> x </label>
                        </div>
                        <div className='d-flex align-items.center justify-content-center'>
                            <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>
                                Cadastrar novas
                            </label>
                        </div>
                        <div className='d-flex flex-column align-items.center justify-content-evenly p-5 h-90per'>
                            <button onClick={e => handleOpenTerapia()} className='btn-padrao m-1'> Adicionar Nova Terapia </button>
                            <button onClick={e => handleOpenEspecialidade()} className='btn-padrao m-1'> Adicionar Nova Especialidade </button>
                            <button onClick={e => handleOpenCertificados()} className='btn-padrao m-1'> Adicionar Nova Certificação Adicional </button>
                        </div>
                    </div>
                    : <></>}
                {modalTerapia && <TerapiasTerapeuta callBack={onClose} onClose={() => setModalTerapia(false)} newTerapia={addNewTerapia} terapeuta={terapeuta} />}
                {modalEspecialidade && <EspecialidadeTerapeuta callBack={onClose} onClose={() => setModalEspecialidade(false)} newEspecialidade={addNewEspecialidade} terapeuta={terapeuta} />}
                {modalCertificados && <CertificadosTerapeuta callBack={onClose} onClose={() => setModalCertificados(false)} newCertificado={addNewCertificado} terapeuta={terapeuta} />}
            </Modal>
        </div>
    );
}

export default forwardRef(ModalDiplomas);