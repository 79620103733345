import React, { useMemo } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { TableDefault } from '../../../../../components/TableDefault';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import api from '../../../../../services/api';

export function TableMedicamentosPaciente({ source, setModalMedicamentosData, pacienteId, setMedicamentos, callBack, handleOpen, readOnly = false }) {

    const snackbar = useSnackbar();

    const removeEmergencia = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let medicamentosLocal = [...source];
                if (data.paciente_id === null || data.paciente_id === undefined) {
                    const index = medicamentosLocal.indexOf(data);
                    medicamentosLocal.splice(index, 1);
                } else {
                    api.delete(`api/paciente-medicamento/${data.id}`)
                        .then(() => {
                            snackbar.displayMessage('Cadastro removido com sucesso!', 'success');
                            for (let index = 0; index < medicamentosLocal.length; index++) {
                                const e = medicamentosLocal[index];
                                if (e.id == data.id) {
                                    medicamentosLocal.splice(index, 1);
                                    break;
                                }
                            }
                        })
                        .catch((e) => {
                            Swal.fire('Houve um problema ao remover o cadastro!', 'O medicamento do paciente não pode ser removido. Por favor aperte F12 para conferir o erro.', 'error');
                            console.error(e);
                        });
                }
                setMedicamentos(medicamentosLocal);
            }
        });
    };

    const handleEditEmergencia = (data) => {
        setModalMedicamentosData(data);
        handleOpen();
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => readOnly ? [
            {
                Header: 'Medicamentos:',
                accessor: 'medicamento',
            },
            {
                Header: 'Horário:',
                accessor: 'horario',
            }
        ] : [
            {
                Header: 'Medicamentos:',
                accessor: 'medicamento',
            },
            {
                Header: 'Horário:',
                accessor: 'horario',
            },
            {
                Header: 'Ações:',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        return <div className="d-flex justify-content-center align-items-center bg-transparente m-2">
                            <AiOutlineEdit className="pointer" color="#7340BD" size={22} onClick={e => handleEditEmergencia(cell.row.original)} />
                            <AiOutlineDelete className="pointer" color="#7340BD" size={22} onClick={e => removeEmergencia(cell.row.original)} />
                        </div>;
                    } else {
                        return <></>;
                    }

                }

            }
        ],
        [source]
    );

    return (
        <TableDefault columns={columns} source={data} />
    );
}
