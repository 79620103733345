import React, { useEffect, useState } from 'react';
import { Modal, Container } from './styles';
import { Form } from '@unform/web';

export default function Responsavel({
    id = 'modalResponsavel',

    onClose = () => { }
}) {

    useEffect(() => {

    }, []);

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <Container>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <label style={{ fontWeight: '900', fontSize: '18', color: '#6A3BAF' }}>Responsavel Sala:</label>
                </div>
                <div>

                    <div>
                        <label>Nome:</label>
                        <label>Horário:</label>
                    </div>
                    <div>
                        <input></input>
                        <input></input>
                    </div>
                    <div>
                        <button> OK </button>
                    </div>

                </div>

            </Container>
        </Modal>
    );
}
