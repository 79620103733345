import { Form } from '@unform/web';
import Button from 'components/Button';
import Carregamento from 'components/Carregamento';
import Input from 'components/Input';
import Select from 'components/Select';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { useSnackbar } from 'context/snackbar';
import useStatusAtendimentosData from 'context/status-atendimento';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { BsEye, BsSearch } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import ReactModal from 'react-modal';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import api from 'services/api';
import AtendimentoService from 'services/AtendimentoService';
import Swal from 'sweetalert2';

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: 'fit-content',
        width: '40vw',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const AtendimentosEGlosas = () => {
    //#region VARIAVEIS
    const title = 'Atendimentos e Glosas';
    const snackbar = useSnackbar();
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    const modalComentariosRef = useRef();
    const [modalInicialIsOpen, setModalInicialIsOpen] = useState(true);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [filters, setFilters] = useState({
        data_inicio: '',
        data_fim: '',
    });
    const statusDeGlosaOptions = useMemo(() => [
        { label: 'Glosado', value: 'glosado' },
        { label: 'Aceito', value: 'aceito' },
        { label: 'Pendente', value: 'pendente' },
    ], []);
    const [filtroAlterado, setFiltroAlterado] = useState(false);
    const [dataAlterado, setDataAlterado] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const memoAtendimentos = useMemo(() => atendimentos, [atendimentos]);
    const [atendimentosAlterados, setAtendimentosAlterados] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const memoAtendimentosOriginal = useMemo(() => atendimentosOriginal, [atendimentosOriginal]);
    const { statusAtendimentosOptions, fetchStatusAtendimentosData } = useStatusAtendimentosData();
    const memostatusAtendimentosOptions = useMemo(() => statusAtendimentosOptions, [statusAtendimentosOptions]);
    const [salvando, setSalvando] = useState(false);
    const [loading, setloading] = useState(false);
    const [pendenteDeSave, setPendenteDeSave] = useState(false);
    //#endregion

    //#region VARIAVEIS
    const filtrar = useCallback((data = null) => {
        const atendimentosLocal = data ? data : memoAtendimentosOriginal;
        const filteredAtendimentos = atendimentosLocal.filter((item) => {
            return true;
        });
        setAtendimentos(filteredAtendimentos);
        setFiltroAlterado(false);
    }, [memoAtendimentosOriginal]);

    const carregarAtendimentos = useCallback(async () => {
        setloading(true);
        setAtendimentos([]);
        const carregaFiltros = {
            data_inicio: dataInicio,
            data_fim: dataFim,
        };
        const response = await api.post('api/atendimento/relatorio/filtrado', carregaFiltros);
        const data = response?.data;
        setAtendimentosOriginal(data);
        filtrar(data);
        setloading(false);
        setDataAlterado(false);
    }, [dataInicio, dataFim, filtrar]);
    //#endregion

    //#region HANDLES
    const handleClickConsultar = useCallback(async () => {
        let passa = true;
        if (!dataInicio && !dataFim) {
            await Swal.fire({
                title: 'Tem certeza?',
                icon: 'warning',
                html: 'Nenhum parâmetro de data foi adicionado à consulta, o que significa, que todos os atendimentos seram consultados. Isso pode demorar um pouco...',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Consutlar mesmo assim',
                cancelButtonText: 'Retornar e adicionar parâmetros'
            }).then(async (response) => {
                if (pendenteDeSave && response.isConfirmed) {
                    passa = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
                } else {
                    passa = response.isConfirmed;
                }
            });
        }
        if (passa) {
            setModalInicialIsOpen(false);
            carregarAtendimentos();
        }
    }, [dataInicio, dataFim, pendenteDeSave]);

    const handleSalva = useCallback(() => {
        setSalvando(true);
        api.put('api/atendimento/atualiza/financeiro', atendimentosAlterados)
            .then((response) => {
                if (response.status === 200) {
                    snackbar.displayMessage('Atendimentos atualziados com sucesso!', 'success');
                    carregarAtendimentos();
                    setPendenteDeSave(false);
                    setSalvando(false);
                }
            }).catch((error) => {
                setSalvando(false);
                console.error(error);
                snackbar.displayMessage('Não foi possível atualizar os atendimentos.', 'error');
            });
    }, [atendimentosAlterados, carregarAtendimentos, snackbar]);

    const handleChangeTableInputs = useCallback((event) => {
        try {
            const splitedId = event.target.id.split('=');
            const key = String(splitedId[0]);
            const id = Number(splitedId[1]);
            const value = key === 'ok_financeiro' ? Boolean(event.target.checked) : String(event.target.value);
            let listaAF = atendimentosAlterados;
            const afIndex = listaAF.findIndex(find => find.id === id);
            if (afIndex !== -1) {
                listaAF[afIndex] = {
                    ...listaAF[afIndex],
                    [key]: value
                };
            } else {
                const newFinded = {
                    id: id,
                    [key]: value
                };
                listaAF.push(newFinded);
            }
            setAtendimentosAlterados(listaAF);
            setPendenteDeSave(listaAF.length > 0);
        } catch (error) {
            console.error(error);
        }
    }, [atendimentosAlterados]);

    const handleLimpaParametros = (limpa_parametro = true) => {
        if (limpa_parametro) {
            setDataInicio('');
            setDataFim('');
        }
        setAtendimentosOriginal([]);
        setAtendimentos([]);
    };

    const handleChangeFilters = useCallback((e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                handleLimpaParametros(false);
                break;
            case 'data_fim':
                setDataFim(value);
                handleLimpaParametros(false);
                break;
            default:
                break;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    /**
     * Manipula a abertura do modal de comentários com os dados do atendimento.
     *
     * @param {Object} atendimento - Objeto contendo os dados do atendimento.
     * @param {string} atendimento.terapeuta_nome - Nome do terapeuta.
     * @param {number} atendimento.paciente_id - ID do paciente.
     * @param {string} atendimento.paciente_identificador - Identificador do paciente.
     * @param {string} atendimento.paciente_nome - Nome do paciente.
     * @param {string} atendimento.terapia_nome - Nome da terapia.
     * @param {string} atendimento.especialidade_nome - Nome da especialidade.
     * @param {string} atendimento.nome_reduzido - Nome reduzido do plano de saúde.
     * @param {Object} atendimento.terapia - Objeto contendo informações da terapia.
     * @param {string} atendimento.terapia.terapia - Nome da terapia.
     * @param {Object} atendimento.especialidade - Objeto contendo informações da especialidade.
     * @param {string} atendimento.especialidade.especialidade - Nome da especialidade.
     * @param {string} atendimento.sala - Sala do atendimento.
     * @param {Object} atendimento.estacao - Objeto contendo informações da estação.
     * @param {string} atendimento.estacao.estacao - Nome da estação.
     * @param {boolean} atendimento.reposicao - Indica se é uma reposição.
     * @param {Object} atendimento.tipo_atendimento - Objeto contendo informações do tipo de atendimento.
     * @param {string} atendimento.tipo_atendimento.tipo_atendimento - Tipo de atendimento.
     * @param {number} atendimento.id - ID do atendimento.
     * @param {string} atendimento.sugestao_terapeuta_name - Nome da sugestão do terapeuta.
     * @param {string} atendimento.sugestao_recepcao_name - Nome da sugestão da recepção.
     * @param {number} atendimento.sugestao_terapeuta - Sugestão do terapeuta.
     * @param {number} atendimento.sugestao_recepcao - Sugestão da recepção.
     * @param {boolean} atendimento.liberado - Indica se o atendimento está liberado.
     * @param {boolean} atendimento.checkin - Indica se o check-in foi realizado.
     * @param {string} atendimento.data_atendimento_inicial - Data e hora inicial do atendimento.
     * @param {string} atendimento.data_atendimento_final - Data e hora final do atendimento.
     * @param {string} atendimento.comentario - Comentário do atendimento.
     * @param {boolean} atendimento.executado - Indica se o atendimento foi executado.
     * @param {string} atendimento.codigo_1 - Código 1 do atendimento.
     * @param {string} atendimento.codigo_2 - Código 2 do atendimento.
     */
    const handleOpenComentario = useCallback((atendimento) => {
        let dados = [];
        dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
        dados['paciente'] = `(${atendimento.paciente_id}) ${atendimento.paciente_identificador}`;
        dados['paciente_nome'] = `${atendimento.paciente_nome}`;
        dados['paciente_id'] = atendimento.paciente_id;
        dados['terapia'] = atendimento.terapia_nome;
        dados['especialidade'] = atendimento.especialidade_nome;
        dados['plano_saude'] = atendimento.nome_reduzido;
        dados['terapia'] = atendimento.terapia.terapia;
        dados['especialidade'] = atendimento.especialidade.especialidade;
        dados['sala'] = atendimento.sala;
        dados['estacao'] = atendimento.estacao.estacao;
        dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['modalidade'] = atendimento.modalidade.modalidade;
        dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
        dados['id'] = atendimento.id;
        dados['terapeuta_status'] = atendimento.sugestao_terapeuta_name;
        dados['recepcao_status'] = atendimento.sugestao_recepcao_name;
        const status_recep = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_terapeuta);
        dados['terapeuta_status_nome'] = status_recep === undefined ? '-' : status_recep.label;
        const status_terap = AtendimentoService.getStatusSelecionadoByValue(memostatusAtendimentosOptions, atendimento.sugestao_recepcao);
        dados['recepcao_status_nome'] = status_terap === undefined ? '-' : status_terap.label;
        dados['liberado'] = atendimento.liberado;
        dados['checkin'] = atendimento.checkin;
        dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
        dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
        dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
        dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
        dados['comentario'] = atendimento.comentario;
        dados['executado'] = atendimento.executado;
        dados['codigo_1'] = atendimento.codigo_1;
        dados['codigo_2'] = atendimento.codigo_2;
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    }, []);
    //#endregion

    //#region COLUNAS
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Data Hora Atendimento',
                accessor: 'data_atendimento_inicial',
                filtrable: true,
                Cell: ({ cell }) => {
                    const horario = cell.row.original.horarios_atendimento.horario;
                    const sliced = horario.slice(0, -3);
                    return (
                        <span>
                            {moment(cell.row.original.data_atendimento_inicial).format('DD/MM/YYYY') || 'vazio'}
                            <br></br>
                            {sliced || 'vazio'}
                        </span>
                    );
                }
            },
            {
                Header: 'Paciente',
                accessor: 'paciente_nome',
                filtrable: true,
                Cell: ({ cell }) => (<div className="text-nowrap">{cell.value}</div>),
            },
            {
                Header: 'Períodos',
                accessor: 'paciente.periodos',
                filtrable: true,
                Cell: ({ cell }) => {
                    return (
                        <ul className="p-0 m-0 text-left text-nowrap w-100" style={{ listStyle: 'none' }}>
                            {cell.value.map((map, index) => {
                                return <li key={`li:${index}|ul:${cell.row.original.id}`} className='text-nowrap'> {map.dia_semana}: {map.periodo} </li>
                            })}
                        </ul>
                    );
                }
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta_nome',
                filtrable: true,
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_atendimento.tipo_atendimento',
                filtrable: true,
            },
            {
                Header: <span>
                    <span> Terapia </span><br></br>
                    <span> Especialidade </span><br></br>
                    <span> Plano </span>
                </span>,
                accessor: 'terapia.terapia',
                filtrable: true,
                Cell: ({ cell }) => (
                    <div className="w-150px font-12px">
                        {cell?.row?.original?.terapia.terapia}<br></br>
                        {cell?.row?.original?.especialidade.especialidade}<br></br>
                        {cell?.row?.original?.nome_reduzido}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'sugestao_recepcao_name',
                filtrable: true,
            },
            {
                Header: 'Liberação',
                accessor: 'liberado',
                filtrable: true,
            },
            {
                Header: 'Assinado',
                accessor: 'assinado',
                filtrable: true,
            },
            {
                Header: 'Status de Glosa',
                accessor: 'status_de_glosa',
                Cell: ({ cell }) => {
                    return <div className="m-0 p-0">
                        <Select
                            id={`status_de_glosa=${cell.row.original.id}`}
                            name={`status_de_glosa=${cell.row.original.id}`}
                            options={statusDeGlosaOptions}
                            defaultValue={statusDeGlosaOptions.find((find) => find.value === cell.value)}
                            onChange={handleChangeTableInputs}
                        />
                    </div>;
                }
            },
            {
                Header: 'Detalhes',
                accessor: 'comentario',
                Cell: ({ cell }) => {
                    return <button className="btn-icone hl-laranja" onClick={() => handleOpenComentario(cell.row.original)}>
                        <BsEye size={22} />
                    </button>;
                }
            },
        ], [handleChangeTableInputs, handleOpenComentario, statusDeGlosaOptions, atendimentos]);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (!pendenteDeSave) return;

        const unblock = navigator.block((tx) => {
            const confirmLeave = window.confirm('Existem alterações não salvas. Deseja sair sem salvar?');
            if (confirmLeave) {
                unblock();
                tx.retry();
            }
        });

        return () => {
            unblock();
        };
    }, [pendenteDeSave, navigator]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (pendenteDeSave) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pendenteDeSave]);

    useEffect(() => {
        if (!filtroAlterado) {
            setFiltroAlterado(true);
        }
    }, [filters]);

    useEffect(() => {
        if (!dataAlterado) {
            setDataAlterado(true);
        }
    }, [dataInicio, dataFim]);

    useEffect(() => {
        fetchStatusAtendimentosData();
    }, []);
    //#endregion

    //#region HTML
    return (
        <>
            <div className="row p-0 m-0">
                <h1 className="font-30px font-bold font-roxo text-center w-100 p-0 m-0">{title}</h1>
                <Form id="div-filtro" className='col-12 row flex-wrap p-0 m-0'>
                    <fieldset id="fieldset-periodo" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-6 mx-auto">
                        <legend className="mb-0">Período</legend>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12'>
                                <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                            </div>
                            <div className='col-lg-6 col-sm-12 d-flex'>
                                <div className="w-90per">
                                    <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                                </div>
                                <div className="mt-1">
                                    <button type={'button'} className="btn-icone mt-4" onClick={handleLimpaParametros}><AiOutlineClear className="icone" size={30} /></button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="col-12 mt-2 d-flex flex-row justify-content-between">
                        <div className='d-flex justify-content-start w-400px'>
                            <Button type="button" className={'w-fit-content-i me-2 rodando-hover'} onClick={() => carregarAtendimentos()}>
                                <FiRefreshCcw className='icone me-2' size={22} /> Atualizar
                            </Button>
                            <Button className={`w-fit-content-i searching${dataAlterado ? ' btn-marca-vermelha' : ''}`} type={'button'} onClick={() => { handleClickConsultar(); }}>
                                <BsSearch size={22} className="icone me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                        {(loading || salvando) &&
                            <div className='p-0 m-0 mx-auto'>
                                <div className='d-flex flex-row w-150px text-center align-items-center'>
                                    <ScaleLoader css={override} size={150} color={'#fd931a'} />
                                    <BsSearch className='icone manual-searching' size={30} />
                                </div>
                            </div>
                        }
                    </div>
                </Form >
                <div className="col-12 p-0 m-0 d-flex justify-content-between mt-4">
                    <b></b>
                    <b className="font-roxo font-20px">Listagem de Atendimentos</b>
                    <Button className={`w-fit-content-i ${salvando ? 'cursor-block' : 'cursor-pointer'}`} type={'button'} onClick={() => handleSalva()} disabled={salvando}>
                        <FaSave className={`me-2 ${pendenteDeSave ? 'font-laranja' : ''}`} size={22} /> Salvar Alterações
                    </Button>
                </div>
                <Form className="col-12 p-0 m-0 overflow-auto">
                    <TableDefaultPagination source={memoAtendimentos} columns={columns} tableTitle={title} prefix_id={'pendencias-pagamento'} />
                </Form>
                {loading && <Carregamento />}
            </div >
            <ReactModal id={'modal-inicial'} style={customStyles} isOpen={modalInicialIsOpen}
                onRequestClose={() => { }} afterOpenModal={() => { }} appElement={document.getElementById('root')} transparent
                shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}
            >
                <div className="row">
                    <h4 className="col-12 font-roxo text-center">Parâmetros Iniciais</h4>
                    <p className="col-12 text-justify">Selecionando duas datas, o todos os atendimentos com datas entre esse período sera retornado. Caso apenas uma das datas sejam selecionadas apenas os atendimentos daquela data em específico será retornado.</p>
                    <Form className="col-12 row">
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                        </div>
                        <div className="col-12 text-right d-flex justify-content-end mt-4">
                            <Button className="w-fit-content-i searching mt-4" type={'button'} onClick={() => handleClickConsultar()}>
                                <BsSearch size={22} className="me-3" /> Consultar Atendimentos
                            </Button>
                        </div>
                    </Form>
                </div>
            </ReactModal>
        </>
    );
    //#endregion
};

export default AtendimentosEGlosas;
