import { useState, useEffect, useRef } from 'react';
import { DefaultModal, Container, BackButton, } from './styles';
import { Form } from '@unform/web';
import Select from '../../../Select';
import api from '../../../../services/api';
import Input from '../../../Input';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';

function TerapiasPlano({ onClose, planoSaudeId, setTerapiasListOriginal, modalData, terapiasList, setTerapiasList, terapias, especialidades, reloadTerapias }, ref) {
    //#region VARIAVEIS
    /*INFORMAÇÕES da TELA*/
    const titulo = 'CADASTRO DE TERAPIAS/ESPECIALDIADES COBERTAS';
    const rota = 'dashboard/{modulo}/planodesaude';
    const descricao = 'Modal que cadastra as terapias, com suas especialidades que são cobertas pelo plano, utilizando o id do plano, da especialidade e da terapia.';
    /*SELECT BOX*/
    const [terapiaSelectBox, setTerapiaSelectBox] = useState([]);
    const [especialidadeSelectBox, setEspecialidadeSelectBox] = useState([]);
    /*SELÇÃO*/
    const [terapiaEscolhida, setTerapiaEscolhida] = useState(null);
    const [especialidadeEscolhida, setEspecialidadeEscolhida] = useState(null);
    /*UTILITARIOS DA PAGINA*/
    const formRef = useRef(null);
    const snackbar = useSnackbar();
    const id = 'modal-terapias-terapeuta';
    //#endregion
    //#region GETTERS
    const getDefaultTerapia = (lista) => {
        if (!modalData) return;
        const terapia = lista.filter(data => {
            return Number(data.value) === Number(modalData.terapia_id);
        });
        setTerapiaEscolhida(terapia);
    };
    const getDefaultEspecialidade = (lista) => {
        if (!modalData) return;
        const especialidade = lista.filter(data => {
            return Number(data.value) === Number(modalData.especialidade_id);
        });
        setEspecialidadeEscolhida(especialidade);
    };
    //#endregion
    //#region FUNCOEs
    const editTerapiaPlano = (terapia, dados) => {
        let terapiasLocal = [...terapiasList];
        const index = terapiasLocal.indexOf(terapia);
        terapiasLocal.splice(index, 1, dados);
        setTerapiasList(terapiasLocal);
        setTerapiasListOriginal(terapiasLocal);
    };
    //#endregion
    //#region HANDLEs
    const handleSubmit = async (data) => {
        if (terapiaEscolhida === null || especialidadeEscolhida === null) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            const terapiasLocal = [...terapiasList];
            let dadosTerapiaEspecialidade = {
                terapia_id: terapiaEscolhida.value === undefined ? terapiaEscolhida[0].value : terapiaEscolhida.value,
                especialidade_id: especialidadeEscolhida.value === undefined ? especialidadeEscolhida[0].value : especialidadeEscolhida.value,
                valor: Number(data.valor.replace(' ', '').replace('R$', '').replace(',', '.')),
                codigo_plano: data.codigo_plano,
                codigo_tuss: data.codigo_tuss,
                terapium: {
                    terapia: terapiaEscolhida.label === undefined ? terapiaEscolhida[0].label : terapiaEscolhida.label,
                },
                especialidade: {
                    especialidade: especialidadeEscolhida.label === undefined ? especialidadeEscolhida[0].label : especialidadeEscolhida.label,
                }
            };
            let jaInserido = false;
            if (modalData === null) {
                for (let index = 0; index < terapiasList.length; index++) {
                    const e = terapiasList[index];
                    jaInserido = e.especialidade_id === dadosTerapiaEspecialidade.especialidade_id && e.terapia_id === dadosTerapiaEspecialidade.terapia_id;
                    if (jaInserido) {
                        break;
                    }
                }
            }
            if (jaInserido) {
                Swal.fire('Já adicionado', 'A terapia e especialidade que você esta tentando adicionar, já foi adicionada.', 'error');
            } else {
                try {
                    if (modalData === null) {
                        if (planoSaudeId === undefined || planoSaudeId === null) {
                            terapiasLocal.push(dadosTerapiaEspecialidade);
                            setTerapiasList(terapiasLocal);
                            setTerapiasListOriginal(terapiasLocal);
                            snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                        } else {
                            dadosTerapiaEspecialidade.plano_saude_id = planoSaudeId;
                            await api.post('api/plano-saude-terapias', dadosTerapiaEspecialidade).then(() => {
                                reloadTerapias();
                                snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                            }).catch((e) => {
                                snackbar.displayMessage(`Houve um erro ao criar a terapia/especialidade do Plano. ${e.message}`, 'error');
                                console.error(e);
                            });
                        }
                    } else {
                        if (modalData.id !== undefined && modalData.id !== null) {
                            dadosTerapiaEspecialidade.id = modalData.id;
                        }
                        if (planoSaudeId === undefined || planoSaudeId === null) {
                            if (dadosTerapiaEspecialidade.index_terapia === undefined) {
                                dadosTerapiaEspecialidade.index_terapia = terapiasLocal.length;
                            }
                            await editTerapiaPlano(modalData, dadosTerapiaEspecialidade);
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                        } else {
                            let updateObj = { ...dadosTerapiaEspecialidade, plano_saude_id: planoSaudeId };
                            delete (updateObj.terapium);
                            delete (updateObj.especialidade);
                            await api.put(`api/plano-saude-terapias/${dadosTerapiaEspecialidade.id}`, updateObj).then(() => {
                                reloadTerapias();
                                snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                            }).catch((e) => {
                                if (e.status === 400) {
                                    Swal.fire('Erro ao atualzar terapia!', 'A combinação de terapia especialidade que esta tentando cadatrasr, já existe!', 'error');
                                }
                                snackbar.displayMessage('Houve um erro ao criar a terapia/especialidade do Plano', 'error');
                                console.error(e);
                            });
                        }
                    }
                } catch (e) {
                    snackbar.displayMessage('Houve um erro ao criar a terapia/especialidade do Plano', 'error');
                    console.error(e);
                } finally {
                    onClose();
                }
            }
        }
    };
    const functionThatSubmitsForm = () => {
        formRef.preventDefault();
        formRef.current.submitForm();
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(async () => {
        const selectedTerapia = await terapias.map(d => ({
            'value': d.id,
            'label': d.terapia
        }));
        setTerapiaSelectBox(selectedTerapia);
        const selectedEspecialidade = await especialidades.map(d => {
            let newArray = {
                'value': d.id,
                'label': d.especialidade
            };
            return newArray;
        });
        setEspecialidadeSelectBox(selectedEspecialidade);
        if (modalData !== undefined && modalData !== null) {
            getDefaultTerapia(selectedTerapia);
            getDefaultEspecialidade(selectedEspecialidade);
        }
    }, []);
    //#endregion
    //#region HTML
    return (
        <DefaultModal id={id}>
            <Container style={{paddingTop: 0}}>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px 0px 0px 0px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <span className='w-200px'></span>
                        <ModalTitulo>{modalData === null ? 'Adicionar ' : 'Alterar '}Terapias e Especialidades</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <Form ref={formRef} onSubmit={handleSubmit} className="w-100 h-100">
                    <div className="row px-4">
                        <div className="col-5">
                            <Select options={terapiaSelectBox} label="Terapia:" name="terapia_id" isDisabled={modalData !== null} onChange={setTerapiaEscolhida} value={terapiaEscolhida} defaultValue={terapiaEscolhida} required={true} />
                        </div>
                        <div className="col-5">
                            <Select options={especialidadeSelectBox} label="Especialidade:" name="especialidade_id" isDisabled={modalData !== null} onChange={setEspecialidadeEscolhida} value={especialidadeEscolhida} defaultValue={especialidadeEscolhida} required={true} />
                        </div>
                        <div className="col-2">
                            <Input title="Valor:" name="valor" defaultValue={modalData === null ? '' : modalData.valor} onFocus={(e) => { e.target.select(); }} required={true} />
                        </div>
                        <div className="col-6">
                            <Input title="Código 1:" name="codigo_plano" defaultValue={modalData === null ? '' : modalData.codigo_plano} onFocus={(e) => { e.target.select(); }} onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')} required={true} />
                        </div>
                        <div className="col-6">
                            <Input title="Código 2:" name="codigo_tuss" defaultValue={modalData === null ? '' : modalData.codigo_tuss} onFocus={(e) => { e.target.select(); }} onKeyUp={(e) => e.target.value = e.target.value.replace(' ', '')} />
                        </div>
                        <div className='col-12 d-flex align-items.center justify-content-evenly mt-3 mb-4'>
                            <button className="btn-padrao" onClick={onClose}>
                                Voltar
                            </button>
                            <button className="btn-padrao" onClick={functionThatSubmitsForm}>
                                {modalData === null ? 'Adicionar' : 'Alterar'}
                            </button>
                        </div>
                    </div>
                </Form>
            </Container>
        </DefaultModal>
    );
    //#endregion
}
export default TerapiasPlano;