import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;

.table{
    table {
        border: 2px solid forestgreen;
        height: 200px;
        width: 100%;
      }
        
      th {
        border-bottom: 1px solid black;
      }
        
      td {
        text-align: center;
      }
}`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
`;

export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;

export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;

export const ContainerSolicitacoes = styled.div`
    grid-area: footer;
    padding: 24px 0px;
`;

export const Button = styled.button`
    background-color: #7340BD;
    color: #FFFFFF;
    border: none;
    padding: 2px 22px;
    border-radius: 8px;
    margin-right: 12px;
    height: 34px;
    width: 160px;
`;

export const TituloPagina = styled.p`
  color: #4906AB;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 12px;
`;
