import React, { useState, useEffect, useRef } from 'react';
import { Modal } from '../styles';
import InfoPacienteModal from '../InfoPacienteModal';
import { ModalContainer } from '../PacienteAltasModal/styles';
import moment from 'moment';
import '../../../styles/global.css';
import { BsPaperclip } from 'react-icons/bs';
import { FiEdit, FiEye } from 'react-icons/fi';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import { TableDefaultPagination } from '../../TableDefaultPagination';
import RelatorioEvolucaoPaciente from '../TerapeutaRelatorios/RelatorioEvolucaoPaciente';

function PacienteEvolucaoDiariaModal({ onClose, paciente }) {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*==VARS INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'MODAL EVOLUÇÃO DIÁRIA';
    // eslint-disable-next-line no-unused-vars
    const descricao = 'Tela modal que mostra todas as evoluções do paciente.';

    const id = 'modal-paciente-evolucao-diaria';
    const [loading, setloading] = useState(true);
    const [tipoModal, setTipoModal] = useState('visulizacao');
    const [evolucoes, setEvolucoes] = useState(null);
    const [evolucaoSelecionada, setEvolucaoSelecionada] = useState(null);
    const [relatorioEdicaoIsOpen, setRelatorioEdicaoIsOpen] = useState(false);

    const modalDetalhesEvolucoesRef = useRef();
    const relatorioEdicaoModalRef = useRef();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Data de Criação',
                accessor: 'data_criacao',
                Cell: ({ cell }) => {
                    return (moment(cell.row.original.created_at).format('DD/MM/YYYY'));
                }
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta.nome'
            },
            {
                Header: 'Terapia',
                accessor: 'terapium.terapia'
            },
            {
                Header: 'Acoes',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        <div className='col-12'>
                            <button className='btn-outline'
                                title={cell.row.original.id}
                                onClick={(e) => handleOpenModalEvolucao(cell)}>
                                <FiEye size={20} />
                            </button>
                            {moment.utc(cell.row.original.created_at).isSame(moment.utc(new Date()), 'day')
                                ? <button className='btn-outline'
                                    title='Editar'
                                    onClick={(e) => handleEditEvolucao(cell)}>
                                    <FiEdit size={20} />
                                </button>
                                : ''}
                        </div>
                    );
                }
            },
        ]
    );
    //#endregion

    /*===============================*/
    //#region HANDLES================*/
    /*===============================*/
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    function handleEditEvolucao(cell) {
        let relatorioLocal = cell.row.original;
        setTipoModal('edição');
        relatorioEdicaoModalRef.current.openModal(relatorioLocal.id);
        setRelatorioEdicaoIsOpen(true);
    }

    function handleOpenModalEvolucao(cell) {
        let relatorioLocal = cell.row.original;
        setTipoModal('visualizacao');
        relatorioEdicaoModalRef.current.openModal(relatorioLocal.id);
        setRelatorioEdicaoIsOpen(true);
    }
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/

    function listaArquivos() {
        let lista = [];
        for (let index = 0; index < evolucaoSelecionada.arquivo_evolucao_pacientes.length; index++) {
            const item = evolucaoSelecionada.arquivo_evolucao_pacientes[index];
            lista.push(
                <a href={item.caminho_full} target='blank' download>
                    <div>
                        <BsPaperclip size={25} />
                    </div>
                    <div>
                        Arquivo {index + 1}
                    </div>
                </a>
            );
        }
        return lista;
    }
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    useEffect(() => {
        setloading(true);
        if (paciente.id !== null) {
            let url = 'api/evolucao-paciente?with='
                + 'arquivo_evolucao_pacientes,'
                + 'terapium,'
                + 'especialidade,'
                + 'paciente'
                + `&filter[paciente_id]=${paciente.id}`;
            api.get(url).then(async ({ data }) => {
                setEvolucoes(data);
                setloading(false);
            }).catch((error) => {
                console.error(error);
            });
        }

    }, []);
    //#endregion
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <>
            {relatorioEdicaoIsOpen ?
                <></>
                : <Modal id={id} onClick={handleOutsideClick}>
                    <ModalContainer className='div-modal'>
                        <div className='position-relative'>
                            <label className='btn-x' onClick={() => onClose()}> x </label>
                        </div>
                        <div className='div-titulo'>
                            <label>Evoluções Diárias</label>
                        </div>
                        <InfoPacienteModal paciente={paciente} />
                        <div className='p-10px'>
                            <div className="div-titulo-tabela">Evoluções do Paciente</div>
                            {loading === true
                                ? <div className='d-flex w-100per justify-center'>
                                    <ClipLoader size={30} color='purple'></ClipLoader>
                                </div>
                                : <TableDefaultPagination columns={columns} source={evolucoes} initialState={{ pageSize: 4 }} />
                            }
                            {loading === true
                                ? <div className='d-flex w-100per justify-center'>
                                    <ClipLoader className="cliploader" color='purple'></ClipLoader>
                                </div>
                                : evolucoes?.map((item, index) => {
                                    return (
                                        <div key={index} className="card" style={{ width: '18rem' }}>
                                            <div className="card-body">
                                                <h5 className="card-title">{item.terapeuta.nome}</h5>
                                            </div>
                                            <div className='m-0 p-3'>
                                                <h6 className="card-subtitle mb-2 text-body-secondary">{moment(item.created_at).format('DD/MM/YYYY')}</h6>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="card-text">{item.terapium.terapia}</p>
                                                    <div>
                                                        {moment.utc(item.created_at).isSame(moment.utc(new Date()), 'day')
                                                            ? <button className='btn-icone'
                                                                title='Editar'
                                                                onClick={(e) => handleEditEvolucao(item)}>
                                                                <FiEdit size={20} />
                                                            </button>
                                                            : ''}
                                                        <button className='btn-icone card-link' onClick={() => {
                                                            setEvolucaoSelecionada(item);
                                                            modalDetalhesEvolucoesRef.current.openModal();
                                                        }}>
                                                            <FiEye size={20} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className='d-flex align-items-center justify-content-center mt-2'>
                                <button onClick={() => onClose()} className='btn-padrao'>Voltar</button>
                            </div>
                        </div>
                    </ModalContainer>
                </Modal >
            }
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} onClose={() => { setRelatorioEdicaoIsOpen(false); }} tipo={tipoModal} />
        </>
    );
    //#endregion
}

export default PacienteEvolucaoDiariaModal;
