import api from './api';

export class EmergenciasPacienteService {
    static index = () => {
        return api.get('api/paciente-contato-emergencia');
    };

    static store = (obj) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":97119}
        */
                
        return api.post('api/paciente-contato-emergencia', obj);
    };

    static show = (id) => {
        return api.get(`api/paciente-contato-emergencia/${id}?id=${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":260}
        */
                
        return api.put(`api/paciente-contato-emergencia/${id}?id=${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/paciente-contato-emergencia/${id}?id=${id}`);
    };
}

export class MedicamentosPacienteService {
    static index = () => {
        return api.get('api/paciente-medicamento');
    };

    static store = (obj) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":97119}
        */
                
        return api.post('api/paciente-medicamento', obj);
    };

    static show = (id) => {
        return api.get(`api/paciente-medicamento/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":260}
        */
                
        return api.put(`api/paciente-medicamento/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/paciente-medicamento/${id}`);
    };
}

export class MedicosAssistentesService {
    static index = () => {
        return api.get('api/paciente-medico-assistente');
    };

    static store = (obj) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":97119}
        */
                
        return api.post('api/paciente-medico-assistente', obj);
    };

    static show = (id) => {
        return api.get(`api/paciente-medico-assistente/${id}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"terapia_id":1,"plano_saude_id":1,"paciente_id":1,"paciente_id_plano_saude":260}
        */
                
        return api.put(`api/paciente-medico-assistente/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/paciente-medico-assistente/${id}`);
    };
}