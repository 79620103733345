import api from './api';

class StatusAtendimentoService {

  static index = () => {
    return api.get('api/status-atendimento');
  };
  static store = (obj) => {
    return api.post('api/status-atendimento', obj);
  };
  static show = (id) => {
    return api.get(`api/status-atendimento/${id}?id=${id}`);
  };
  static update = (obj, id) => {
    return api.put(`api/status-atendimento/${id}?id=${id}`, obj);
  };
  static destroy = (id) => {
    return api.delete(`api/status-atendimento/${id}?id=${id}`);
  };

  static getListaStatus() {
    return [
      {
        value: 1,
        label: 'Atendimento Padrão'
      },
      {
        value: 2,
        label: 'Falta Paciente'
      },
      {
        value: 3,
        label: 'Falta Terapeuta'
      },
    ];
  }

  static getStatusSelecionadoByValue(value) {
    return this.getListaStatus().filter(status => status.value === value)[0] || '-';
  }
}

export default StatusAtendimentoService;
