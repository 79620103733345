import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableDefaultPagination } from '../../../components/TableDefaultPagination';
import { FiEye } from 'react-icons/fi';

export function TablePacienteAuditor({ source, plano_saude_id = null }) {

  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: 'Nome:',
        accessor: 'nome',
      },
      {
        Header: 'Identificador:',
        accessor: 'identificador',
      },
      {
        Header: 'Ações',
        accessor: 'acoes',
        Cell: ({ cell }) => (
          (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
            <button className="btn-icone" onClick={() => navigate('/dashboard/auditor/paciente/perfil/' + cell.row.original.id, {state: {plano_saude_id: plano_saude_id}})} >
              <FiEye size={30}></FiEye>
            </button>
          </div>
          )
        )
      }
    ],
    []
  );

  return (
    <TableDefaultPagination columns={columns} source={source} />
  );
}
