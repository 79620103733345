import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from 'services/api';
import Snackbar from 'components/Snackbar';
import { useSnackbar } from 'context/snackbar';
import { BsEye } from 'react-icons/bs';
import useIcons from 'context/icons';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import Button from 'components/Button';

function Logs() {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { iconArray } = useIcons();
    const [logs, setLogs] = useState([]);
    const [source, setSource] = useState([]);
    const [operationOptions, setOperationOptions] = useState();

    const handleAlteraHabilitacao = (id, habilita = true) => {
        if (id) {
            Swal.fire({
                title: 'Desabilitar registro',
                text: `Deseja realmente ${habilita ? 'habilitado' : 'desabilitado'} esse log? Essa ação resultará em novos registros de log.`
            }).then((response) => {
                if (response.isConfirmed) {
                    const obj = {
                        enabled: habilita
                    }
                    api.put(`api/logs-models/${id}?id=${id}`, obj)
                        .then(() => {
                            snackbar.displayMessage(`Log ${habilita ? 'habilitado' : 'desabilitado'} com sucesso!`, 'success');
                        })
                        .catch((error) => {
                            console.error(error);
                            snackbar.displayMessage('Não foi possível alterar o log!', 'error');
                        })
                }
            })
        }
    };

    const acessarModeloDeLog = (id = null) => {
        if (id) {
            navigate(`logs-models/${id}`);
        } else {
            navigate('logs-models/');
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nome do Modelo',
                accessor: 'model_name',
            },
            {
                Header: 'Operação',
                accessor: 'operation',
                filterable: true,
                Cell: ({ cell }) => {
                    const returns = operationOptions?.find(find => cell.value === find.value)?.label;
                    if (returns) {
                        return returns;
                    }
                    return '';
                }
            },
            {
                Header: 'Nome do Submodelo',
                accessor: 'submodel_name',
            },
            {
                Header: 'Salvar Dados Antigos',
                accessor: 'save_old_data',
                Cell: ({ cell }) => (cell.value ? 'Sim' : 'Não'), // Exibe 'Sim' ou 'Não'
            },
            {
                Header: 'Dados a Salvar',
                accessor: 'data_to_save',
                Cell: ({ cell }) => <div className='tooltip-container'>
                    <a href='#'>Passe o mouse para ver</a>
                    <div className={'tooltip-bottom'}>
                        <pre className='tooltip-text text-left w-fit-content-i'>
                            {JSON.stringify(cell.value, null, 2)}
                        </pre>
                        <div className="tooltip-arrow"></div>
                    </div>
                </div>
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => <div className='d-flex align-items-center justify-content-center bg-transparent'>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="enabled" defaultChecked={cell.row.original.enabled === 1} onChange={(e) => handleAlteraHabilitacao(cell.row.original.id, e.target.checked)} />
                    </div>
                    <button type='button' className='btn-icone' onClick={() => acessarModeloDeLog(cell.row.original.id)}>
                        {iconArray['editar']}
                    </button>
                </div>
            }
        ], [operationOptions, source]
    );
    useEffect(() => {
        const carregaLogs = () => {
            try {
                api.get('/api/logs-models')
                    .then(response => {
                        if (response.data) {
                            setLogs(response.data);
                        }
                    });
            } catch (error) {
                console.error("Erro ao buscar logs:", error);
            }
        };

        carregaLogs();
        api.get('api/logs-models/get/operation/enum/')
            .then((response) => setOperationOptions(response.data))
            .catch((error) => {
                console.error(error);
                snackbar.displayMessage('Erro ao coletar listagem de operações', 'error');
            });
    }, []);
    useEffect(() => {
        setSource(logs);
    }, [logs]);

    return (
        <div className="container">
            <div className='row'>
                <div className='div-titulo col-12 mt-2 text-center align-items-center justify-content-center'>
                    <label>Modelos de Logs</label>
                </div>
                <div className='col-12'>
                    <TableDefaultPagination searchBar={true} createButton={
                        <div className='w-300px'>
                            <Button type={'button'} onClick={() => navigate('logs-models')}>Adicionar Modelo de Log {React.cloneElement(iconArray['adicionar'], { className: 'ms-2' })}</Button>
                        </div>
                    } columns={columns} source={source} />
                </div>
            </div>
        </div>
    );
}

export default Logs;