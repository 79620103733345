import React from 'react';
import { ContainerIcone, ContainerNome, ContainerSubCard } from './styles';

function ProntuarioSubCard({ nome, icone, onClick, disabled }) {
  return (
    <ContainerSubCard onClick={onClick} disabled={disabled}>
      <ContainerIcone>
        {icone}
      </ContainerIcone>
      <ContainerNome>
        {nome}
      </ContainerNome>
    </ContainerSubCard>
  );
}

export default ProntuarioSubCard;
