import React, {useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import {Form} from '@unform/web';
import Input from '../../../../../components/Input';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import {EmergenciasPacienteService} from '../../../../../services/EmergenciasPacienteService';
import InputMask from '../../../../../components/InputMask';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: 'auto',
        width: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const ModalEmergencias = (props, ref) => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [alergiaMedicamento, setAlergiaMedicamento] = useState(null);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const alergiaIsTrue = {
        width: '50%',
        marginRight: 10,
    };

    const alergiaISFalse = {
        width: '100%',
    };

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    
    const alergiasList = [
        {label: 'Sim', value: true},
        {label: 'Não', value: false},
    ];

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const getDefaultAlergia = (alergia) => {
        if (!props.modalData) return;
        const alergiaPaciente = alergia.filter(data => {
            return data.value === props.modalData.medicamentos_alergia;
        })[0];
        setAlergiaMedicamento(alergiaPaciente);
    };

    const editNewPacienteEmergencias = async (emergencia, dados) => {
        let emergenciaLocal = [...props.emergencias];
        const index = emergenciaLocal.indexOf(emergencia);
        emergenciaLocal.splice(index, 1, dados);
        props.setEmergencias(emergenciaLocal);
    };

    async function handleSubmit(data) {
        let id = props.modalData!==null ? props.modalData.id : null;
        try {
            if (data.nome === '' || data.telefone === '') {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                const dados = {
                    nome: data.nome,
                    telefone: data.telefone,
                };
                let emergenciasLocal = [...props.emergencias];
                if (props.modalData === null) {/* MODO DE CRIAÇÃO */
                    if (props.pacienteId !== undefined && props.pacienteId !== null) {/* PACIENTE não SELECIONADO */
                        dados.paciente_id = props.pacienteId;
                        await EmergenciasPacienteService.store(dados).then(({data})=>{
                            snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                            emergenciasLocal.push(data);
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
                        });
                    } else {/* PACIENTE SELECIONADO */
                        emergenciasLocal.push(dados);
                        snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                    }
                    props.setEmergencias(emergenciasLocal);
                    setIsOpen(false);
                } else {/* MODO DE EDIÇÃO */
                    let emergenciasLocal = [...props.emergencias];
                    dados.index_terapia = emergenciasLocal.length;
                    if (props.pacienteId === undefined || props.pacienteId === null) {/* PACIENTE não SELECIONADO */
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = emergenciasLocal.length;
                        }
                        await editNewPacienteEmergencias(props.modalData, dados);
                        snackbar.displayMessage('Cadastro atualizado com sucesso!', 'success');
                        setIsOpen(false);
                    } else {/* PACIENTE SELECIONADO */
                        EmergenciasPacienteService.update(dados, id).then(() => {
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            for (let index = 0; index < emergenciasLocal.length; index++) {
                                const e = emergenciasLocal[index];
                                if(e.id===id){
                                    emergenciasLocal[index]=dados;
                                    break;
                                }
                            }
                            props.setEmergencias(emergenciasLocal);
                            setIsOpen(false);
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar atualizar!', '', 'error');
                            console.error(e);
                        });
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
        } finally {
            
        }
    }

    useEffect(() => {
      getDefaultAlergia(alergiasList);
    }, [props.modalData]);

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent appElement={document.getElementById('root')}>
                <div style={{position: 'relative'}}>
                    <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
                      right: '10px', fontSize: '25px', top: '-10px' }} onClick={() => setIsOpen(false)}>
                      x
                    </label>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h2 style={{color: '#442953', fontWeight: 'bold', textAlign: 'center'}}
                        ref={(_subtitle) => (subtitle = _subtitle)}>
                        {props.modalData === null ? 'Contato de Emergência' : 'Alterar Contato de Emergência'}
                    </h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                          style={{display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: '50%', paddingRight: 10}}>
                                    <Input title="Nome:" name="nome"
                                    defaultValue={props.modalData === null ? '' : props.modalData.nome}/>
                                </div>
                                <div style={{width: '50%', paddingRight: 10}}>
                                    <InputMask style={{width: '30%'}} title="Telefone:" name="telefone" icon={null}
                                        maskFormat={'(99)99999-9999'} disabled={false}
                                        defaultValue={props.modalData === null ? '' : props.modalData.telefone}/>
                                </div>
                            </div>
                        </div>
                        <button className='btn-padrao' type="button" onClick={functionThatSubmitsForm} title={props.modalData === null ? '' : props.modalData.id }> {props.modalData === null ? 'Adicionar' : 'Alterar'} </button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(ModalEmergencias);
