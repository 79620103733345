import React from 'react';
import ContentLoader from 'react-content-loader';
import { BotaoAcessoRapido, GridAcesso, HeaderAcessoRapido, LinhaAcessoRapido } from './styles';
import { CardStyle } from '../../styles/global-style';
import { useNavigate } from 'react-router-dom';

function AcessoRapido({ id, name, style, className, acessos = [], cardHeight = '300px', cardClass = 'w-fit-content-i', buttonWidth = 110, buttonHeight = 80, loading, rest }) {
    const navigate = useNavigate();

    const LoadingAcessos = <>
        <LinhaAcessoRapido key={'acess-rapido-linha1'}>
            <ContentLoader
                key={'acess-rapido-item1'}
                speed={2}
                width={buttonWidth}
                height={buttonHeight}
                viewBox='0 0 100 100'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'
            >
                <rect x='0' y='0' rx='10' ry='10' width={buttonWidth} height={buttonHeight} />
            </ContentLoader>
            <ContentLoader
                key={'acess-rapido-item2'}
                speed={2}
                width={buttonWidth}
                height={buttonHeight}
                viewBox='0 0 100 100'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'
            >
                <rect x='0' y='0' rx='10' ry='10' width={buttonWidth} height={buttonHeight} />
            </ContentLoader>
        </LinhaAcessoRapido>
        <LinhaAcessoRapido key={'acess-rapido-linha2'}>
            <ContentLoader
                key={'acess-rapido-item3'}
                speed={2}
                width={buttonWidth}
                height={buttonHeight}
                viewBox='0 0 100 100'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'
            >
                <rect x='0' y='0' rx='10' ry='10' width={buttonWidth} height={buttonHeight} />
            </ContentLoader>
            <ContentLoader
                key={'acess-rapido-item4'}
                speed={2}
                width={buttonWidth}
                height={buttonHeight}
                viewBox='0 0 100 100'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'
            >
                <rect x='0' y='0' rx='10' ry='10' width={buttonWidth} height={buttonHeight} />
            </ContentLoader>
        </LinhaAcessoRapido>
    </>;
    return (
        <GridAcesso id={id} name={name} style={style} className={className + ' d-flex justify-content-end'} {...rest}>
            <CardStyle className={cardClass} style={{ minHeight: cardHeight }}>
                <HeaderAcessoRapido>Acesso Rápido</HeaderAcessoRapido>
                {loading
                    ? LoadingAcessos
                    : acessos.map((e, index) => {
                        return (
                            <LinhaAcessoRapido key={'acess-rapido-linha' + index}>{e.map((e2, index1) => {
                                return (
                                    <BotaoAcessoRapido key={'acess-rapido-botao' + index1} onClick={() => navigate(e2.link)}>
                                        {e2.icone}
                                        <span>{e2.titulo}</span>
                                    </BotaoAcessoRapido>
                                );
                            })}
                            </LinhaAcessoRapido>
                        );
                    })
                }
            </CardStyle>
        </GridAcesso>
    );
}

export default AcessoRapido;