import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const ButtonNewPlano = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    border: 'none',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
};

export const MainDivStylePlano = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    height: 58,
    padding: '0px 20px', 
    marginBottom: '4rem',
    boxShadow: '0 0 10px 0px silver',
};

export const LabelPlanoStyle = {
   
    marginRight: 20,
    color: '#7340BD',
    fontWeight: 'bold',
    cursor: 'pointer'
};