import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal } from '../styles';
import Formatter from 'services/Formatter';
import { useSnackbar } from 'context/snackbar';
import Swal from 'sweetalert2';
import api from 'services/api';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { Form } from '@unform/web';
import Input from 'components/Input';
const ContatoUserModal = forwardRef(({ contatoList, setContatosList, userId }, ref) => {
    //#region VARIÁVEIS
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const formatadorTelefone = new Formatter('(##) #####-####');
    const snackbar = useSnackbar();
    const formContatosRef = useRef();
    //#endregion

    const openModal = (contatoUser) => {
        setModalData(contatoUser);
        setIsOpen(true);
    };

    const closeModal = () => {
        setModalData([]);
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({ openModal, closeModal }));

    //#region HANDLES
    const handleOutsideClick = (e) => {
        if (e.target.id === 'contato-user-modal') closeModal();
    };

    const handleSubmit = (data, { reset }) => {
        if (!data.nome && !data.parentesco && !data.contato) {
            Swal.fire('Preencha todos os campos, antes de prosseguir!', '', 'error');
            return;
        }
        try {
            const newData = { ...data, user_id: userId };
            let newList = contatoList;
            const fim = (newList) => {
                setContatosList(newList);
                reset();
                closeModal();
            };
            if (modalData) {
                api.patch(`api/contato-user/${modalData.id}?id=${modalData.id}`, newData)
                    .then(response => {
                        if (response.status === 200) {
                            snackbar.displayMessage('Contato de Emergência atualizado com sucesso!', 'success');
                            newList = newList.map(contato => {
                                if (contato.id === modalData.id) {
                                    return newData;
                                } else {
                                    return contato;
                                }
                            });
                            fim(newList);
                        }
                    })
                    .catch(error => console.error(error));
            } else {
                api.post('api/contato-user', newData)
                    .then(response => {
                        newList.push(response.data);
                        snackbar.displayMessage('Contato de Emergência criado com sucesso!', 'success');
                        fim(newList);
                    })
                    .catch(error => console.error(error));
            }
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    return (
        <Modal id='contato-user-modal' style={{ zIndex: '1030' }} className={isOpen ? '' : 'some'} onMouseDown={handleOutsideClick}>
            <div className="div-modal p-0" style={{ width: '35vw', height: 'fit-content' }}>
                <nav className='navbar sticky-top bg-cinza-claro shadow'>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div></div>
                        <ModalTitulo>{modalData ? 'Alterar' : 'Adicionar'} Contato de Emergência</ModalTitulo>
                        <label className='btn-outline text-right pointer' onClick={closeModal}>
                            <CgClose size={22} className='pointer' color='red' />
                        </label>
                    </div>
                </nav>
                <br />
                <Form ref={formContatosRef} onSubmit={handleSubmit} className='row w-100 pe-4 ps-4 pb-4'>
                    <Input id='nome' name='nome' title='Nome:' upperContainerClassName='col-12' onKeyUp={(e) => e.target.value = e.target.value.toUpperCase()} defaultValue={modalData?.nome || ''} />
                    <Input id='parentesco' name='parentesco' title='Parentesco:' upperContainerClassName='col-12' onKeyUp={(e) => e.target.value = e.target.value.toUpperCase()} defaultValue={modalData?.parentesco || ''} />
                    <Input id='contato' name='contato' title='Contato:' upperContainerClassName='col-12' onKeyUp={(e) => e.target.value = formatadorTelefone.format(e.target.value)} defaultValue={modalData?.contato || ''} />
                    <button className='btn-padrao' type='submit'>{modalData ? 'Alterar' : 'Adicionar'}</button>
                </Form>
            </div>
        </Modal>
    );
});

export default ContatoUserModal;