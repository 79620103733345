import React, {useEffect, useRef, useState} from 'react';
import {Documento} from '../../components/Documento';
import Container from '../../components/Container';
import Assinatura from '../../components/Assinatura';
import {useAuth} from '../../context/auth';
import api from '../../services/api';
import moment from 'moment/moment';
import {useSnackbar} from '../../context/snackbar';
import {useParams, useNavigate} from 'react-router-dom';
import DocumentoAdministrativoService from '../../services/DocumentoAdministrativoService';
import {ParagrafoInformativoNumeroDocumentos} from './styles';
import Swal from 'sweetalert2';

const DocumentoAssinatura = () => {
  const {id} = useParams();
  const snackbar = useSnackbar();
  const sigCanvas = useRef({});
  const { user } = useAuth();
  const [documento, setDocumento] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDocumentoOptativo, setIsDocumentoOptativo] = useState(false);
  const [isDocumentoInformativo, setIsDocumentoInformativo] = useState(false);

  const navigate = useNavigate();

  const handleAssinarTermo = (isAceito) => {
    if (sigCanvas.current.isEmpty()) {
      Swal.fire('Por favor preencha com sua assinatura!', '', 'warning');
    } else {
      const assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      let data = {
        // responsavel_id: user.id,
        documento_administrativo_id: documento.id,
        assinatura: assinatura,
        assinado_dia: moment(),
        aceito: isAceito,
      };
      let id = 0;
      if (user?.terapeuta) {
        id = user.terapeuta[0].id;
      } else {
        id = user.responsavels[0].id;
      }
      data[user.tipo_user+'_id'] = id;
      api.post(`/api/${user.tipo_user}-assinaturas`, data)
        .then(() => {
          snackbar.displayMessage('Documento assinado com sucesso', 'success');
          let navUrl = '/dashboard';
          if(user.tipo_user === 'administrador'){
            navigate(navUrl+='/'+'administrador');
          } else {
            navigate(navUrl+= '/'+user.tipo_user);
          }
        })
        .catch(() => {
          snackbar.displayMessage('Houve um erro ao assinar o documento, tente novamente', 'error');
        });
    }
  };

  const handleReadDocument = () => {
    let data = {
      documento_administrativo_id: documento.id,
      assinatura: 'Visualizado',
      assinado_dia: moment(),
      aceito: true,
    };
    let id = 0;
    if (user?.terapeuta) {
      id = user.terapeuta[0].id;
    } else {
      id = user.responsavels[0].id;
    }
    data[user.tipo_user+'_id'] = id;
    api.post(`/api/${user.tipo_user}-assinaturas`, data).catch(() => {
          snackbar.displayMessage('Houve um erro ao assinar o documento, tente novamente', 'error');
        });
  };

  const handleUserDashboard = () => {
    snackbar.displayMessage('Documento visualizado', 'success');
    let navUrl = '/dashboard';
    if(user.tipo_user === 'administrador'){
      navigate(navUrl+='/'+'administrador');
    } else {
      navigate(navUrl+= '/'+user.tipo_user);
    }
  };

  useEffect(() => {
    DocumentoAdministrativoService.show(id)
        .then(({data}) => {
          setDocumento(data);
          setIsDocumentoOptativo(data.tipo_documento === 'optativo');
          setIsDocumentoInformativo(data.tipo_documento === 'informativo');
        });
  }, []);

  useEffect(() => {
    if (documento === null) return;
    setIsLoaded(true);
    if (documento.tipo_documento === 'informativo') handleReadDocument();
  }, [documento]);

  return (
        <div>
          <Container>
          {isLoaded &&
              <>
                <ParagrafoInformativoNumeroDocumentos style={{ display: 'flex', justifyContent: 'center'}}>
                  {documento.nome_documento}
                </ParagrafoInformativoNumeroDocumentos>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>
                  {documento !== null && <Documento documentoId={id} />}
                </div>
                <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    {!isDocumentoInformativo ?
                        <>
                          <div>
                            <Assinatura sigCanvas={sigCanvas} />
                          </div>
                          <div style={{ display: 'flex', width: '50%', justifyContent: 'space-evenly' }}>
                            <button style={{
                              backgroundColor: '#7340BD',
                              color: '#FFFFFF',
                             
                              border: 'none',
                              padding: '7px 30px',
                              borderRadius: '10px',
                            }} onClick={() => handleAssinarTermo(true)}>
                              Assinar e Aceitar
                            </button>
                            {isDocumentoOptativo &&
                                <button style={{
                                  backgroundColor: '#bd4040',
                                  color: '#FFFFFF',
                                 
                                  border: 'none',
                                  padding: '7px 30px',
                                  borderRadius: '10px',
                                }} onClick={() => handleAssinarTermo(false)}>
                                  Assinar e Recusar
                                </button>}
                          </div>
                        </>
                        :

                        <button style={{
                          backgroundColor: '#7340BD',
                          color: '#FFFFFF',
                         
                          border: 'none',
                          padding: '7px 30px',
                          borderRadius: '10px',
                        }} onClick={e => handleUserDashboard()}>
                          Confirmar
                        </button>
                    }
                  </div>
                </div>
              </>
            }
          </Container>
        </div>
        
  );
};

export default DocumentoAssinatura;
