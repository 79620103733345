import styled from 'styled-components';

export const DefaultModal = styled.div`
flex-direction: column; 
width: 100%;
height: 100%;
position: fixed;
top: 50;
left: 50;
transform: translate(-50%, -50%)
right: auto;
bottom: auto;
marginRight: -50%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`;

export const Container = styled.div`
display: flex;
flex-direction: column; 
background-color: #FFFFFF;
padding-top: 30px;
color: #000;
width: 55%;
height: auto;
border-radius: 10px;`;

export const MainContainer = styled.div`
display: flex;
flex-direction: column; 
background-color: #FFFFFF;
align-items: stretch;
padding: 30px;
color: #000;
width: 40%;
height: auto;
border-radius: 10px`;

export const CustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        minHeight: '500px',
    },
};

export const ModalDiplomaStyle = {
    margin: 'auto',
};

export const ButtonStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
    height: 25,
    width: '35%',
    fontWeight: 'bold',
};

export const DivButtonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: 8
};

export const DivBackButtonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
};

export const SubmitButton = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 40,
    marginTop: 5,
    height: 30,
    fontWeight: 'bold',
    width: '20%'
};

export const BackButton = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    padding: '6px 22px',
};

export const SaveButton = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 70,
    height: 30,
    fontWeight: 'bold',
    width: '13%'
};

export const OpenButton = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
   
    fontSize: 13,
    inputType: 'text',
    border: 'none',
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    height: 30,
    fontWeight: 'bold',
    width: '80%',
    marginTop: 30
};

export const MainHeader = {
   
    color: '#7441BF',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20
};

export const UploadIcon = {
    border: 'none', 
    cursor: 'pointer',
    borderRadius: 8,
};

export const LabelUpload = {
    color: '#7340BD', 
    cursor: 'pointer'
};

export const Upload = {
    marginTop: 20,
    color: '#7340BD', 
    cursor: 'pointer',
   
    display: 'flex',
    height: 25,
};

export const Delete = {
    color: '#FFFFFF', 
    cursor: 'pointer',
    backgroundColor: '#7340BD',
   
    height: 30,
    marginLeft: 10,
    borderRadius: 20,
    padding: '2px 8px',
    display: 'flex', 
    justifyContent: 'center',
};

export const InputUpload = {
    borderRadius: 8,
    height: 30,
    marginTop: 30,
    display: 'none'
};
