import React, { useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { TableDefault } from '../../../../../components/TableDefault';
import api from 'services/api';

export function TableCarterinhaPlano({ source, callBack, setModalData, handleOpen, setCarterinhasPlanoPaciente, readOnly = false }) {
    const snackbar = useSnackbar();

    const removeCarterinhaPlanoPaciente = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover a carterinha desse plano?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (data.paciente_id === null || data.paciente_id === undefined) {
                    let carterinhasPlanoPacienteLocal = [...source];
                    const index = carterinhasPlanoPacienteLocal.indexOf(data);
                    carterinhasPlanoPacienteLocal.splice(index, 1);
                    setCarterinhasPlanoPaciente(carterinhasPlanoPacienteLocal);
                    callBack();
                } else {
                    api.delete(`api/carterinha-plano-paciente/${data.id}?id=${data.id}`);
                    callBack();
                }
                snackbar.displayMessage('Cadastro removido com sucesso!', 'success')
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    };

    const handleEditCarterinhaPlanoPaciente = (data) => {
        setModalData(data);
        handleOpen();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Plano de Saúde',
                accessor: 'plano_saude.plano',
            },
            {
                Header: 'Numero Carterinha',
                accessor: 'numero_carterinha',
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        return (
                            <div className="d-flex align-center justify-center m-1">
                                <AiOutlineEdit className={'icone-padrao'} title={cell.row.original.id} onClick={e => handleEditCarterinhaPlanoPaciente(cell.row.original)} />
                                <AiOutlineDelete className={'icone-padrao'} title={cell.row.original.id} onClick={e => removeCarterinhaPlanoPaciente(cell.row.original)} />
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                }
            }
        ],
        [source]
    );

    return (
        <TableDefault columns={columns} source={source} />
    );
}