import React from 'react';

const Tabela = ({ array, handleClickExcluir, handleClickEditar }) => {
    return (<table>
        <thead>
            <tr>
                <th>Índice</th>
                <th>Valor</th>
                <th>Ações</th>
            </tr>
        </thead>
        <tbody>
            {array.map((item, index) => (
                <tr key={index}>
                    <td>{index}</td>
                    <td>{item}</td>
                    <td>
                        <button onClick={() => handleClickEditar(index)}>Editar</button>
                        <button onClick={() => handleClickExcluir(index)}>Excluir</button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>);
};

export default Tabela;