import React, {useMemo} from 'react';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../context/snackbar';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import baseURL from '../../../../services/baseUrl';
import moment from 'moment';


export function TableDocumentosAssinados({source, user_id, setOpenModalDocumento, setModalDocumento}) {

    const snackbar = useSnackbar();

    const handleOpenModalDocumento = (documento) => {
        setOpenModalDocumento(true);
        setModalDocumento(documento);
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => [
            {
                Header: 'Nome:',
                accessor: 'documentos_administrativo.nome_documento', // accessor is the "key" in the data
            },
            {
                Header: 'Data do Documento:',
                accessor: 'created_at',
                Cell: ({cell}) => {
                    return moment.utc(cell.row.original.created_at).format('DD/MM/YYYY');
                  }
            },
            {
                Header: 'Status:',
                accessor: 'status',
                Cell: ({cell}) => (
                    (   
                        <div style={{ display: 'flex', alignItems: 'center',
                            justifyContent: 'center', backgroundColor: 'transparent', margin: 5}}>
                                {cell.row.original.documentos_administrativo.obsoleto === false ? 'Ativo' : 'Inativo '}
                        </div>
                    )
                )
            },

            {
                Header: 'Ações:',
                accessor: 'acoes',
                Cell: ({cell}) => (
                    (   
                        <div style={{ display: 'flex', alignItems: 'center',
                            justifyContent: 'center', backgroundColor: 'transparent', margin: 5}}>
                            <button onClick={() => handleOpenModalDocumento(cell.row.original)} style={{
                                    backgroundColor: '#7340BD',
                                    color: '#FFFFFF',
                                    border: 'none',
                                    padding: '4px 8px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    boxShadow: '0 0 10px 0px silver',
                                    marginRight: 5
                                  }}>Visualizar</button>
                                  <a href={baseURL + `api/documento-administrativo/${cell.row.original.documentos_administrativo.id}/${user_id}/download`} style={{
                                    backgroundColor: '#7340BD',
                                    color: '#FFFFFF',
                                    border: 'none',
                                    padding: '6px 12px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    boxShadow: '0 0 10px 0px silver',
                                    marginLeft: 5
                                  }}>Download</a> 
                        </div>
                    )
                )
            }
        ],
        []
    );

  return (
      <TableDefaultPagination columns={columns} source={data} />
  );
}
