import api from './api';

class HorariosAtendimentoService {
    static index = (param) => {
        return api.get(`api/horario-atendimento?${param}`);
    };

    static store = (obj) => {
        /* exemplo
        {"id_plano_saude":1,"nome":"numquam","identificador":"odio","status":"eligendi","nascimento":"1971-06-21","cpf":"aut","rg":"quisquam","endereco":"voluptates","estado":"ad","complemento":"quod","bairro":"qui","cep":9061,"resp_endereco":true,"foto_perfil":"qui","historico":"Natus minima natus et eligendi omnis est. Voluptatem alias nesciunt nesciunt voluptatum qui hic aliquid. Est repellat dolorem id omnis fugiat.","desenvolvimento_social":"Impedit perferendis corrupti consequatur nobis deserunt officia provident veniam. Deserunt corrupti beatae laudantium id ut. Non qui quo similique at.","fala_linguagem":"Occaecati voluptatem quis porro hic earum omnis. Nulla quisquam necessitatibus asperiores cumque quo. Qui sed iste minima.","alimentacao":"Ullam eum nobis quasi. Aut consequatur unde blanditiis voluptates voluptatibus. Nisi eum deserunt perspiciatis incidunt voluptatum quisquam quas. Aspernatur qui esse accusantium quis laborum amet."}
        */
                
        return api.post('api/horario-atendimento', obj);
    };

    static show = (id, options='') => {
        return api.get(`api/horario-atendimento/${id}?${options}`);
    };

    static update = (obj, id) => {
        /* exemplo
        {"id_plano_saude":1,"nome":"autem","identificador":"recusandae","status":"velit","nascimento":"1978-07-17","cpf":"quod","rg":"fugiat","endereco":"molestiae","estado":"aut","complemento":"expedita","bairro":"aperiam","cep":73379221,"resp_endereco":false,"foto_perfil":"at","historico":"Rem quaerat aliquid ut ipsa. Nobis est quam ea ducimus. Et architecto incidunt cumque excepturi ullam ea eum.","desenvolvimento_social":"Placeat labore aut ipsum eveniet quia. Id sed eius rerum blanditiis distinctio quaerat. Tempore sunt ut illum. Voluptatem aut aut deleniti excepturi.","fala_linguagem":"Quia eos ut quidem laudantium beatae ut illum. Voluptate dolorem aut in qui. Quia sapiente itaque earum sed. Aut provident provident est molestias.","alimentacao":"Quae fugit consequatur fuga assumenda et in. Et dicta sed incidunt autem nisi id. Vitae voluptate ea sint iusto reiciendis."}
        */
                
        return api.put(`api/horario-atendimento/${id}`, obj);
    };

    static destroy = (id) => {
        return api.delete(`api/horario-atendimento/${id}`);
    };
}

export default HorariosAtendimentoService;