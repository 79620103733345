import React from 'react';
import {Container, Modal} from '../styles';
import InfoPacienteModal from '../InfoPacienteModal';
import {ModalContainer} from '../PacienteAltasModal/styles';
import moment from 'moment';

function PacienteInformacoesComplementaresModal({ onClose, paciente }) {
  const id = 'modal-paciente-info-complementares';

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  return (
      <Modal id={id} onClick={handleOutsideClick}>
        <ModalContainer>
          <div style={{position: 'relative'}}>
            <label style={{ opacity: 0.5, cursor: 'pointer', position: 'absolute', 
              right: '25px', fontSize: '25px', top: '-10px' }} onClick={() => onClose()}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Informações Complementares</label>
          </div>
          <InfoPacienteModal paciente={paciente} />
          <div className='d-flex flex-wrap overflow-auto px-2'>
            <div className='col-3 px-1'><span>Data:</span></div> 
            <div className='col-4 px-1'><span>Nome do documento:</span></div>
            <div className='col-4 px-1'><span>Terapia:</span></div>
            <div className='col-1'></div>
            {paciente.documentos.map((item) => {
              return(
              <>
              <div className='col-3 p-1'><input type="text" value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
              <div className='col-4 p-1'><input type="text" value={paciente.terapeuta} className='form-control' /></div>
              <div className='col-4 p-1'><input type="text" value={item.nome} className='form-control' /></div>
              
              <div className='col-1 p-1'> 
                <button style={{
                  fontWeight: '600',
                  fontSize: '18', backgroundColor: '#6A3BAF', color: '#FFFFFF',
                  border: 'none', borderRadius: 8, padding: '4px 10px'
                  }}>
                  Abrir
                </button>
              </div>
              </>
              );
            })}
          </div>
          <div className='d-flex align-items-center justify-content-center mt-2'>
            <button onClick={() => onClose()} style={{
                fontWeight: '600',
                fontSize: '18', backgroundColor: '#6A3BAF', color: '#FFFFFF',
                border: 'none', borderRadius: 8, padding: '4px 40px'
            }}>
                Voltar
            </button>
          </div>
        </ModalContainer>
      </Modal>
  );
}

export default PacienteInformacoesComplementaresModal;
