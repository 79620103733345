import api from './api';

class GrupoPermissoesService {
  static index = () => {
    return api.get('api/group');
  };
  static store = (obj) => {
    return api.post('api/group', obj);
  };
  static show = (id) => {
    return api.get(`api/group/${id}`);
  };
  static update = (obj, id) => {
    return api.put(`api/group/${id}`, obj);
  };
  static destroy = (id) => {
    return api.delete(`api/group/${id}`);
  };
}

export default GrupoPermissoesService;