import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../context/auth';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
import { ClipLoader } from 'react-spinners';
import { RiFileSearchLine, RiFolderAddLine } from 'react-icons/ri';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { VscGraphLine } from 'react-icons/vsc';
import PacienteEvolucaoDiariaModal from '../../PacienteEvolucaoDiariaModal';
import PacienteAltasModal from '../../PacienteAltasModal';
import PacienteRelatoriosModal from '../../PacienteRelatoriosModal';
import PacienteLaudosModal from '../../PacienteLaudosModal';
import PacienteInformacoesComplementaresModal from '../../PacienteInformacoesComplementaresModal';
import PacientePlanoTerapeuticoModal from '../PlanoTerapeuticoModal';
import moment from 'moment';
const DadosComplementaresModal = forwardRef(({ id = 'agendaModal', paciente_id }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Prontuário do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [paciente, setPaciente] = useState([]);
    /*===VARS MODAIS===*/
    const [modalData, setModalData] = useState([]);
    const [openModalAltas, setOpenModalAltas] = useState(false);
    const [openModalRelatorios, setOpenModalRelatorios] = useState(false);
    const [openModalLaudos, setOpenModalLaudos] = useState(false);
    const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
    const [openModalOutrosDocs, setOpenModalOutrosDocs] = useState(false);
    /* REFS */
    const pacientePlanoTerapeuticoModalRef = useRef(null);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '380px',
            height: '380px',
            borderRadius: '10px',
            boxShadow: '0 0 10px 0px silver'
        },
    };
    //#endregion
    //#region HANDLES=======
    const handleOpenModalPlanoTerapeutico = (item) => {
        if (pacientePlanoTerapeuticoModalRef.current) {
            pacientePlanoTerapeuticoModalRef.current.openModal(item);
        }
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL=======
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaPaciente(id);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaPaciente = async (id) => {
        setloading(true);
        setPaciente(null);
        await api.get(`api/paciente/${id}?with=plano_terapeuticos,evolucao_pacientes`)
            .then(({ data }) => { setPaciente(data); })
            .catch((e) => console.error(e))
            .finally(() => { setloading(false); });
    };
    //#endregion
    useEffect(() => {/* ONLOAD */

    }, []);
    return (
        <>
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} transparent>
                {
                    loading
                        ? <ClipLoader className='m-auto align-center justify-center' />
                        : (paciente !== undefined && paciente !== null)
                            ? <div className='bg-white'>
                                <div className='d-flex flex-column'>
                                    <span style={{ color: '#4906AB' }}>
                                        <div className='d-flex justify-content-between w-100per'>
                                            <label>
                                                <button className='btn-icone'>
                                                    <AiOutlineClose color='white' size={22} />
                                                </button>
                                            </label>
                                            <label style={{ fontSize: '20px', textAlign: 'center' }}>
                                                {'Prontuário'}
                                                <br></br>
                                                <b>{(paciente.nome ? paciente.nome : '')}</b>
                                            </label>
                                            <label>
                                                <button className='btn-icone' onClick={closeModal}>
                                                    <AiOutlineClose color='red' size={22} />
                                                </button>
                                            </label>
                                        </div>
                                    </span>
                                    <span className='text-muted align-self-center' style={{ fontSize: '20px', fontWeight: '900' }}>
                                        {paciente.idade ? moment().subtract(moment(paciente.idade).format('YY'), 'years').format('YY') : '0'} anos {paciente.diagnostico === null ? '' : ','}
                                        {paciente.diagnostico}
                                    </span>
                                </div>
                                <div className='d-flex flex-wrap justify-content-center'>
                                    <button className='btn-quadrado m-3' onClick={() => (setModalData(paciente), setOpenModalEvolucao(true))}>
                                        <div><VscGraphLine size={25} /></div>
                                        <div>Evolução</div>
                                    </button>
                                    <button className='btn-quadrado m-3' onClick={() => (setModalData(paciente), setOpenModalRelatorios(true))}>
                                        <div><HiOutlineNewspaper size={25} /></div>
                                        <div>Relatórios</div>
                                    </button>
                                    <button className='btn-quadrado m-3' onClick={() => (handleOpenModalPlanoTerapeutico(paciente))}>
                                        <div><RiFolderAddLine size={25} /></div>
                                        <div>Plano terapêutico</div>
                                    </button>
                                    <button className='btn-quadrado m-3' onClick={() => (setModalData(paciente), setOpenModalLaudos(true))}>
                                        <div><RiFileSearchLine size={25} /></div>
                                        <div>Laudos</div>
                                    </button>
                                </div>
                            </div>
                            : 'Sem paciente selecionado.'
                }
            </Modal>
            {openModalEvolucao && <PacienteEvolucaoDiariaModal onClose={() => { setOpenModalEvolucao(false); setModalData(null); }} paciente={modalData} />}
            {openModalAltas && <PacienteAltasModal onClose={() => { setOpenModalAltas(false); setModalData(null); }} paciente={modalData} />}
            {openModalRelatorios && <PacienteRelatoriosModal onClose={() => { setOpenModalRelatorios(false); setModalData(null); }} paciente={modalData} />}
            {openModalLaudos && <PacienteLaudosModal onClose={() => { setOpenModalLaudos(false); setModalData(null); }} paciente={modalData} />}
            {openModalOutrosDocs && <PacienteInformacoesComplementaresModal onClose={() => { setOpenModalOutrosDocs(false); setModalData(null); }} paciente={modalData} />}
            <PacientePlanoTerapeuticoModal ref={pacientePlanoTerapeuticoModalRef}></PacientePlanoTerapeuticoModal>
        </>
    );
});
export default DadosComplementaresModal;