import { keyframes } from 'styled-components';
import styled from 'styled-components';

export const snackbarAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
    `;

export const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background: #323232;
  color: #fff;
  z-index: 10;
  margin: 16px;
  border-radius: 4px;
  min-width: 344px;
  max-width: 772px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 0px;
  overflow: hidden;
  cursor: default;
  animation: 300ms ease-out forwards ${snackbarAnimation};
`;

export const SnackbarLabel = styled.div`
  padding: 14px 8px 14px 16px;
  line-height: 1.2rem;
  width: 100%;
  margin: 0;
  cursor: default;
`;

export const SnackbarDismiss = styled.div`
  color: #313131;
  font-size: 120%;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  padding: 8px;
`;


export const SnackbarProgress = styled.div`
  height: 6px;
  width: 100%;
  padding: 0px;
  border-radius: 0px;
`;