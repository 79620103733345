import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Swal from 'sweetalert2';
import EstacaoService from '../../../../../services/EstacaoService';
import { TableDefaultPagination } from '../../../../../components/TableDefaultPagination';

export default function TableEstacao({ source, callback, editModal }) {

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                EstacaoService.destroy(id)
                    .then(() => {
                        Swal.fire('Registro removido com sucesso!', '', 'success');
                        callback();
                    })
                    .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'));
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Estação:',
                accessor: 'estacao',
            },
            {
                Header: 'Sala:',
                accessor: 'sala.sala',
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    (<div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent'
                    }}>
                        <AiOutlineDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete(cell.row.original.id)} title={cell.row.original.id} color="#7340BD" size={22} />
                        <AiOutlineEdit style={{ cursor: 'pointer' }} onClick={() => editModal(cell.row.original)} title={cell.row.original.id} color="#7340BD" size={22} />
                    </div>
                    )
                )
            }
        ],
        []
    );

    return (
        <TableDefaultPagination columns={columns} source={source} />
    );
}
