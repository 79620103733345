import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Input from 'components/Input';
import Button from 'components/Button';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '40%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const customStylesLabel = {
    fontWeight: 'bold',
    marginTop: '10px',
    fontSize: '13px'
};

const TipoDemandaModal = ({ modalIsOpen, setIsOpen, tipoDemanda = null, callback }, ref) => {
    //#region VARIAVEIS
    let subtitle;
    const [tipoDemandaNome, setTipoDemandaNome] = useState('');
    const [prioridade, setPrioridade] = useState(0);
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    //#endregion
    //#region FUNCOES de MODAL
    function afterOpenModal() {
        subtitle.style.color = '#000';
    }
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    //#endregion
    //#region FUNCOES
    const limpaModal = () => {
        setPrioridade(0);
        setTipoDemandaNome(null);
    };
    const store = async (data) => {
        try {
            setLoading(true);
            await api.post('api/tipo-demanda', data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    };
    const update = async (data, tipoDemandaId) => {
        try {
            setLoading(true);
            await api.put(`api/tipo-demanda/${tipoDemandaId}&id=${tipoDemandaId}`, data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    };
    //#endregion
    //#region HANDLES
    async function handleSubmit() {
        const data = {
            tipo_demanda: tipoDemandaNome,
            prioridade: prioridade,
        };
        limpaModal();
        if (tipoDemanda && tipoDemanda.id)
            return update(data, tipoDemanda.id);
        else
            return store(data);
    }
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        if (tipoDemanda && tipoDemanda.id) {
            setTipoDemandaNome(tipoDemanda.tipo_demanda);
            setPrioridade(tipoDemanda.prioridade);
        } else {
            setTipoDemandaNome('');
            setPrioridade(0);
        }
    }, [tipoDemanda]);
    //#endregion
    //#region HTML
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Modal Tipo de Demanda"
                appElement={document.getElementById('root')}
                transparent
            >
                <div style={{ position: 'relative' }}>
                    <label style={{
                        opacity: 0.5, cursor: 'pointer', position: 'absolute',
                        right: '10px', fontSize: '25px', top: '-10px'
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div className='flex-col'>
                    <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>{tipoDemanda === null ? 'Cadastrar ' : 'Alterar '}Tipo de Demanda</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} className='flex-col'>
                        <div className='row d-flex'>
                            <div className='col-lg-6 col-sm-12'>
                                <Input name={'tipo_demanda'} title={'Tipo de Demanda'} value={tipoDemandaNome} onChange={(e) => setTipoDemandaNome(e.target.value.toUpperCase())} required={'required'} />
                            </div>
                            <div className='col-lg-6 col-sm-12 flex-col d-flex'>
                                <label style={customStylesLabel} htmlFor='prioridade'>Prioridade</label>
                                <Input name={'prioridade'} id={'prioridade'} type={'number'} min={0} max={5} value={prioridade} onFocus={(e) => e.target.select()} onChange={(e) => setPrioridade(e.target.value)} required={'required'} />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : (tipoDemanda && tipoDemanda.id)
                                    ? 'Alterar'
                                    : 'Cadastrar'
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
    //#endregion
};

export default forwardRef(TipoDemandaModal);