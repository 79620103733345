import React from 'react';
import { Tooltip } from 'react-tooltip';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 5px 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
`;

const LiveBadgeContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border-radius: 50px;
`;

const LiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  animation: ${pulse} 1.5s infinite;
`;

const OfflineIndicator = styled.div`
  width: 12px;
  height: 12px;
  background-color: gray;
  border-radius: 50%;
  margin-right: 10px;
`;

const LiveBadge = ({ sincronizado = false, texto = true, tooltip = null, posicao = 'fixada' }) => {
  if (posicao === 'fixada') {
    return (
      <LiveBadgeContainer data-tooltip-id={'live-tooltip'}>
        {sincronizado
          ? <><LiveIndicator /> {texto ? 'Sincronizado' : ''}</>
          : <><OfflineIndicator /> {texto ? 'Dessincronizado' : ''}</>
        }
        {tooltip &&
          <Tooltip id='live-tooltip' type='dark' effect='float'>
            {tooltip}
          </Tooltip>
        }
      </LiveBadgeContainer>

    );
  } else if (typeof posicao === 'object') {
    return (
      <LiveBadgeContainer data-tooltip-id={'live-tooltip'} style={{ position: 'absolute', top: posicao[0], left: posicao[1] }}>
        {sincronizado
          ? <><LiveIndicator /> {texto ? 'Sincronizado' : ''}</>
          : <><OfflineIndicator /> {texto ? 'Dessincronizado' : ''}</>
        }
        {tooltip &&
          <Tooltip id='live-tooltip' type='dark' effect='float'>
            {tooltip}
          </Tooltip>
        }
      </LiveBadgeContainer>

    );
  } else if (posicao === 'relativa') {
    return (
      <LiveBadgeContainer data-tooltip-id={'live-tooltip'} style={{ position: 'relative', top: posicao[0], left: posicao[1] }}>
        {sincronizado
          ? <><LiveIndicator /> {texto ? 'Sincronizado' : ''}</>
          : <><OfflineIndicator /> {texto ? 'Dessincronizado' : ''}</>
        }
        {tooltip &&
          <Tooltip id='live-tooltip' type='dark' effect='float'>
            {tooltip}
          </Tooltip>
        }
      </LiveBadgeContainer>
    );
  } else {
    return (
      <LiveBadgeContainer data-tooltip-id={'live-tooltip'}>
        {sincronizado
          ? <><LiveIndicator /> {texto ? 'Sincronizado' : ''}</>
          : <><OfflineIndicator /> {texto ? 'Dessincronizado' : ''}</>
        }
        {tooltip &&
          <Tooltip id='live-tooltip' type='dark' effect='float'>
            {tooltip}
          </Tooltip>
        }
      </LiveBadgeContainer>
    );
  }
};

export default LiveBadge;