import { Form } from '@unform/web';
import Input from 'components/Input';
import { borderRadius, borderWidth, padding } from 'polished';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import Modal from 'react-modal';
import api from 'services/api';
import { CPFService } from 'services/CPFService';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    },
};

const RecuperarSenhaModal = (props, ref) => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [nome, setNome] = React.useState('false');
    const [inputValue, setInputValue] = React.useState('');
    const [inputError, setInputError] = React.useState('');
    const [inputType, setInputType] = React.useState('');


    //#region FUNCOES MODAL
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);


    useImperativeHandle(ref, () => {
        return {
            openModal,
            nome
        };
    }, []);

    function afterOpenModal() {
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    //#endregion


    //#region FUNCOES
    const validateInput = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailTest = emailRegex.test(input);
        const cpfTest = CPFService.ValidaCPF(input).isValid;
        if (input === '') {
            setInputError('Por favor, digite o seu e-mail de acesso ou o seu CPF.');
            return 'invalid';
        }
        if (emailTest || cpfTest) {
            if (emailTest) {
                setInputType('email');
            } else {
                setInputType('cpf');
            }
            return 'valid';
        } else {
            setInputError('Por favor, insira um email ou CPF válido.');
            return 'invalid';
        }
    };
    //#endregion
    //#region HANDLES
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        const validationResult = validateInput(value);
        if (validationResult === 'invalid') {
            setInputError('Por favor, insira um CPF ou email válido.');
        } else {
            setInputError('');
        }
    };

    const handleSubmit = (e) => {
        const validationResult = validateInput(inputValue);
        if (validationResult === 'invalid') {
            setInputError('Por favor, insira um CPF ou email válido.');
            return;
        } else {
            setInputError('');
        }


        const sendObject = { [inputType]: inputValue };
        api.post('api/esqueci-senha', sendObject).then((response) => {
            if (response.status === 200) {
                closeModal();
            }
        }).catch((err) => {
            if (err.response.status === 400) {
                setInputError('Não foi possível encontrar um usuário com essas informações.');
            }
        });
    };
    //#endregion

    //#region HTML
    return (
        <div>
            <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent >
                <div className='row'>
                    <h2 className='font-roxo font-bold'>Recuperação de Senha</h2>
                    <Form className='rpw' onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}>
                        <span>Insira o número do seu CPF ou email cadastrado e enviaremos um link para a alteração de senha</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: '#442953', fontWeight: 'bold' }}>CPF ou email cadastrado</label>
                            <Input id='reset-info' name='reset-info' value={inputValue} onChange={handleInputChange} />
                            {inputError && <span className='font-vermelho'>{inputError}</span>}
                        </div>
                        <button className='btn-padrao pendente mt-4'>Enviar</button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
    //#endregion
};

export default forwardRef(RecuperarSenhaModal);
