import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import Input from 'components/Input';
import Select from 'components/Select';
import { Form } from '@unform/web';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import api from 'services/api';
import { useSnackbar } from 'context/snackbar';
import { Delete, InputUpload, LabelUpload, Upload, UploadIcon } from '../ModalDiplomas/styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const customStyles = {
    overlay: {
        zIndex: 11,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        maxHeight: '70vh',
        width: '40vw',
        padding: 0,
        zIndex: 12
    },
};

/**
 * ModalCarteirinhaConselho component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.terapeuta_id - The ID of the therapist.
 * @param {Array} [props.terapias=[]] - The list of therapies.
 * @param {Array} [props.terapiasCadastradas=[]] - The list of registered therapies.
 * @param {Array} [props.conselhos=[]] - The list of councils.
 * @param {Function} [props.setConselhos=()=>{}] - The function to set the councils.
 * @param {React.Ref} ref - The ref object.
 * @returns {JSX.Element} The ModalCarteirinhaConselho component.
 */
const ModalCarteirinhaConselho = ({ terapeuta_id, conselhos = [], setConselhos = () => { } }, ref) => {
    //#region VARIAVEIS
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [terapiaSelecionada, setTerapiaSelecionada] = useState(null);
    const [filteredTerapias, setFilteredTerapias] = useState(null);
    const [documento, setDocumento] = useState(null);
    const [path, setPath] = useState([]);
    const snackbar = useSnackbar();
    const url = 'api/terapeuta-carteira-conselho/';

    const formRef = useRef(null);
    //#endregion

    //#region FUNCOES DE MODAL
    const openModal = useCallback((modalData, terapias, conselhos, terapeuta_id, terapiasCadastradas) => {
        setModalData(modalData);
        if (terapias && terapeuta_id) {
            if (modalData?.terapia_id) {
                const terapiaEncontrado = terapias.filter((terapia) => terapia.value === modalData.terapia_id);
                setTerapiaSelecionada(terapiaEncontrado);
            }
            const filteredTerapiasLocal = terapias.filter((terapia) => {
                const terapiaId = terapia.value;
                const verificaConselhos = conselhos ? !conselhos.some((conselho) => conselho.terapia_id === terapiaId) : true;
                const verificaTerapias = terapiasCadastradas.some((terapiaCadastrada) => terapiaCadastrada.terapia_id === terapiaId);
                return verificaConselhos && verificaTerapias;
            });
            setFilteredTerapias(filteredTerapiasLocal);
        }
        setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            openModal,
        };
    }, []);
    function afterOpenModal() {

    }
    const closeModal = useCallback(() => {
        setModalData(null);
        setIsOpen(false);
    }, []);
    //#endregion
    //#region FUNCOES
    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }
    //#endregion
    //#region HANDLES
    async function handleSubmit(data) {
        try {
            if (!data.numero || !terapiaSelecionada) {
                Swal.fire('Por favor, preencha todos os campos!', '', 'warning');
            } else {
                let conselhosLocal = [...conselhos];
                data.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
                data.terapeuta_id = terapeuta_id;
                data.terapia_id = terapiaSelecionada?.value;

                const duplicateConselho = conselhosLocal.find(
                    (conselho) =>
                        conselho.terapeuta_id === data.terapeuta_id &&
                        conselho.terapia_id === data.terapia_id &&
                        conselho.id !== modalData?.id
                );

                if (duplicateConselho) {
                    Swal.fire('Já existe um conselho cadastrado para esse terapeuta e terapia!', '', 'warning');
                } else {
                    if (modalData && modalData.id) {
                        await api.patch(`${url}${modalData.id}/`, data).then((response) => {
                            const index = conselhosLocal.findIndex((conselho) => conselho.id === modalData.id);
                            conselhosLocal[index] = response.data;
                            snackbar.displayMessage('Conselho editado com sucesso!', 'success');
                            setConselhos(conselhosLocal);
                        }).catch((error) => {
                            Swal.fire('Erro ao editar conselho!', error?.data?.message, 'error');
                            throw error;
                        });
                    } else {
                        data.arquivo = documento;
                        const form = new FormData();
                        Object.keys(data).forEach((key) => {
                            if (key === 'arquivo') {
                                form.append(key, data[key], data[key].name);
                            } else {
                                form.append(key, data[key]);
                            }
                        });
                        await api.post(url, form).then((response) => {
                            conselhosLocal.push(response.data);
                            setConselhos(conselhosLocal);
                            snackbar.displayMessage('Conselho criado com sucesso!', 'success');
                        }).catch((error) => {
                            Swal.fire('Erro ao criar conselho!', error?.data?.message, 'error');
                            throw error;
                        });
                    }
                    closeModal();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleChangeDocument = (e) => {
        if (documento === null) {
            const arquivo = document.getElementById('arquivo');
            const file = e.target.files[0].name;
            const size = e.target.files[0].size;
            const extPermitidas = ['jpg', 'png', 'jpeg', 'pdf'];
            if (extPermitidas.find((ext) => { return file.split('.').pop() === ext; }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg ou pgn)', '', 'warning');
                arquivo.value = '';
            } else {
                if (size < 40000000) { //40MB
                    console.log(e.target.files[0]);
                    setDocumento(e.target.files[0]);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = '';
                }
                e.preventDefault();
            }
        } else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'warning');
        }
    };
    const handleDeleteUpload = () => {
        setDocumento(null);
        path.splice(-1);
    };
    //#endregion
    //#region HTML
    return (
        <Modal id='modal-carteirinha-conselho'
            style={customStyles}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            appElement={document.getElementById('root')}
            transparent>
            <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div></div>
                    <ModalTitulo> {modalData ? 'Editar' : 'Adicionar'} Carteirinha de Conselho </ModalTitulo>
                    <label className="btn-outline text-right pointer" onClick={() => closeModal()}>
                        <CgClose size={22} className="pointer" color="red" />
                    </label>
                </div>
            </nav>
            <div className="p-4">
                <Form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data" id={'form_conselho'} className='row'>
                    <div className='col-12'>
                        <Select
                            label="Terapia:"
                            name="terapia_id"
                            options={filteredTerapias}
                            onChange={e => setTerapiaSelecionada(e)}
                            value={terapiaSelecionada}
                            required
                        />
                    </div>
                    <div className='col-12'>
                        <Input title="Número do Conselho:" name="numero" defaultValue={modalData?.numero} required />
                    </div>
                    <div className='col-12'>
                        <Input title="Nome do Conselho:" name="nome" defaultValue={modalData?.nome} />
                    </div>
                    <div className='col-12'>
                        <label htmlFor="arquivo" style={LabelUpload} className='col-12 d-flex flex-row flex-nowrap align-center justify-center hl-laranja font-roxo'>
                            <AiOutlineCloudUpload className='mt-2 ms-2' size={38} style={UploadIcon} />
                            <span className='mt-2 ms-2'>Anexar Documento</span><input type="file" id="arquivo" name="arquivo" style={InputUpload} onChange={e => handleChangeDocument(e)} />
                        </label>
                        <div className='d-flex justify-center align-center flex-col mb-2'>
                            {path.map((item) => {
                                return (
                                    <span style={Upload}>{item}
                                        <div>
                                            <span style={Delete} onClick={handleDeleteUpload}> x </span>
                                        </div>
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                    <div className='col-12'>
                        <button className='btn-padrao w-100 mt-4' type="button" onClick={functionThatSubmitsForm}>
                            {modalData ? 'Salvar Alterações' : 'Adicionar'}
                        </button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
};
export default forwardRef(ModalCarteirinhaConselho);