import React, { useEffect, useState, useRef } from 'react';
import { SnackbarContainer, SnackbarDismiss, SnackbarLabel, SnackbarProgress } from './styles';
import { useSnackbar } from '../../context/snackbar';
import { CgClose } from 'react-icons/cg';

const Snackbar = ({ duration = 5000 }) => {
  const snackbar = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(duration); // Tempo restante do timer
  const timerRef = useRef(null); // Armazena o timer de fechamento
  const startTimeRef = useRef(null); // Armazena o tempo de início do timer
  const pausedTimeRef = useRef(null); // Armazena o tempo quando o timer é pausado

  let type = snackbar.type;

  const successStyle = {
    backgroundColor: '#36863a',
  };

  const errorStyle = {
    backgroundColor: '#c7312d',
  };

  const warningStyle = {
    backgroundColor: '#f6aa00',
    color: '#1e1e1e',
  };

  const infoStyle = {
    backgroundColor: '#1b9dab',
  };

  const successStyleP = {
    backgroundColor: '#4caf50',
  };

  const errorStyleP = {
    backgroundColor: '#e57373',
  };

  const warningStyleP = {
    backgroundColor: '#ffb74d',
    color: '#1e1e1e',
  };

  const infoStyleP = {
    backgroundColor: '#4dd0e1',
  };

  const successStylePFundo = {
    backgroundColor: '#81c784',
  };

  const errorStylePFundo = {
    backgroundColor: '#ff8a80',
  };

  const warningStylePFundo = {
    backgroundColor: '#ffe082',
    color: '#1e1e1e',
  };

  const infoStylePFundo = {
    backgroundColor: '#80deea',
  };

  const style = type === 'success' ? successStyle : type === 'error' ? errorStyle : type === 'warning' ? warningStyle : infoStyle;
  const progressStyle = type === 'success' ? successStyleP : type === 'error' ? errorStyleP : type === 'warning' ? warningStyleP : infoStyleP;
  const progressStyleFundo = type === 'success' ? successStylePFundo : type === 'error' ? errorStylePFundo : type === 'warning' ? warningStylePFundo : infoStylePFundo;

  const handleDismiss = () => {
    snackbar.close();
  };

  const startTimer = (time) => {
    setRemainingTime(time);
    startTimeRef.current = Date.now();

    timerRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTimeRef.current;
      const percentage = (elapsedTime * 100) / duration;
      setProgress(percentage);

      if (elapsedTime >= time) {
        handleDismiss();
      }
    }, 50);
  };

  const pauseTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      pausedTimeRef.current = remainingTime - (Date.now() - startTimeRef.current); // Calcula o tempo restante
    }
  };

  const resumeTimer = () => {
    startTimer(pausedTimeRef.current); // Retoma o timer com o tempo restante
  };

  useEffect(() => {
    startTimer(duration); // Inicia o temporizador

    return () => clearInterval(timerRef.current); // Limpa o timer ao desmontar
  }, [snackbar]);

  return (
    <SnackbarContainer
      className='row'
      style={{ ...style }}
      onMouseEnter={pauseTimer} // Pausa o timer quando o mouse entra
      onMouseLeave={resumeTimer} // Retoma o timer quando o mouse sai
    >
      <div className='col-12 d-flex justify-content-between align-items-center'>
        <SnackbarLabel>{snackbar.message}</SnackbarLabel>
        <SnackbarDismiss onClick={handleDismiss}>
          <CgClose size={20} color="white" />
        </SnackbarDismiss>
      </div>
      <SnackbarProgress className="progress" style={progressStyleFundo} role="progressbar" aria-label="Fechamento da Snackbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
        <div className="progress-bar" style={{ ...progressStyle, width: `${progress}%` }}></div>
      </SnackbarProgress>
    </SnackbarContainer>
  );
};

export default Snackbar;