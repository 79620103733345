import { useState } from 'react';
import api from 'services/api';

const useTipoAtendimentosData = () => {
    const [tipoAtendimentosOptions, setTipoAtendimentosOptions] = useState([]);

    const fetchTipoAtendimentosData = async () => {
        try {
            let options = [];
            const { data } = await api.get('api/tipo-atendimento');
            data.forEach((tipoAtendimento) => {
                if (tipoAtendimento.id !== 4 && tipoAtendimento.id !== 6 && tipoAtendimento.id !== 7)
                    options.push({ value: tipoAtendimento.id, label: tipoAtendimento.tipo_atendimento });
            });
            setTipoAtendimentosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar tipo atendimentos:', error);
        }
    };

    return { tipoAtendimentosOptions, fetchTipoAtendimentosData };
};

export default useTipoAtendimentosData;