//#region IMPORTAÇÕES
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import React, { useEffect } from 'react';
import HeaderPadrao from './layouts/HeaderPadrao';
import PerfilUsuario from './pages/Login/Perfil';
import { TerapeutaRoutes } from './module-routes/terapeuta-route';
import { ResponsavelRoutes } from './module-routes/responsavel-route';
import { AuditorRoutes } from './module-routes/auditor-route';
import { useAuth } from 'context/auth';
import RedirecionaParaDashboard from 'pages/RedirecionaParaDashboard';
import OfflineIndexDev from 'pages/Desenvolvedor/offline-index-dev';
import NotFound from 'components/ErrosTemplate/NotFound';
import FuncionariosRoutes from 'module-routes/funcionario-route';
//#endregion
function RoutesApp() {
	//#region VARIAVEIS
	/* TODO: Readicionar verificação de documentos pendentes, quando pronto */
	//const { verificaExisteDocumentoPendente } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuth();
	//#endregion
	//#region FUNCOES
	const verificaAcesso = () => {
		const localArray = location.pathname.split('/');
		if ((localArray[2] !== '' || localArray[2] !== null) && (localArray[2].toLowerCase() !== user.route_tipo_user)) {
			navigate('/');
		}
	};
	//#endregion
	//#region USE EFFECT
	useEffect(() => {
		//verificaExisteDocumentoPendente();
		if (location.pathname !== '/') {
			verificaAcesso();
		}
	}, [location.pathname]);
	//#endregion
	//#region HTML
	return (
		<HeaderPadrao>
			<Routes>
				<Route path='/' element={<Login />} />
				<Route path='/offline-testes' element={<OfflineIndexDev />} />
				<Route path='*' element={<NotFound />} />
				{user
					? <>
						<Route path='/dashboard/perfil' element={<PerfilUsuario />} />
						<Route path='/dashboard/' element={<RedirecionaParaDashboard user={user} />} />
						{/* ROTAS DOS FUNCIONÁRIOS */}
						{FuncionariosRoutes(user?.route_tipo_user).map((route, index) => React.cloneElement(route, { key: 'funcionario-route' + index }))}
						{/* ROTAS DOS TERAPEUTAS */}
						{TerapeutaRoutes().map((route, index) => React.cloneElement(route, { key: 'terapeuta-route' + index }))}
						{/* ROTAS DOS AUDITORES */}
						{AuditorRoutes().map((route, index) => React.cloneElement(route, { key: 'auditor-route' + index }))}
						{/* ROTAS DOS RESPONSAVEIS */}
						{ResponsavelRoutes().map((route, index) => React.cloneElement(route, { key: 'responsavel-route' + index }))}
					</>
					: <></>
				}
			</Routes>
		</HeaderPadrao>
	);
	//#endregion
}

export default RoutesApp;