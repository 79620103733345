import { createContext, useContext, useState, useRef, useEffect } from 'react';

const SnackbarContext = createContext({
    message: '',
    isDisplayed: false,
    displayMessage: () => { },
    type: 'info',
});

const SnackbarProvider = ({ children }) => {
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(5000);
    const [type, setType] = useState('info');

    // Usar useRef para manter a referência ao timer
    const timerRef = useRef(null);

    const displayHandler = (msg, type, time = 5000) => {
        setType(type);
        setMessage(msg);
        setDuration(time);
        setIsDisplayed(true);

        // Limpa o temporizador anterior antes de iniciar um novo
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            closeHandler();
        }, time);
    };

    const closeHandler = () => {
        // Limpa o temporizador quando a snackbar for fechada
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setIsDisplayed(false);
    };

    useEffect(() => {
        // Limpa o temporizador se o componente for desmontado
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <SnackbarContext.Provider
            value={{
                isDisplayed,
                message,
                duration,
                displayMessage: displayHandler,
                close: closeHandler,
                type,
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};

const useSnackbar = () => {
    const context = useContext(SnackbarContext);

    if (!context) {
        throw new Error('useSnackbar must be used within SnackbarProvider');
    }

    return context;
};

export { useSnackbar, SnackbarProvider };