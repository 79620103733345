import React from 'react';
import Container from '../components/Container';
import Header from '../components/Header';
import { useAuth } from 'context/auth';

const HeaderPadrao = ({ children }) => {
    const { isAuthenticated } = useAuth();
    return (
        <>
            {isAuthenticated && <Header />}
            <Container>
                {React.cloneElement(children, {})}
            </Container>
        </>
    );
};

export default HeaderPadrao;