import React, { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import { FiPower } from 'react-icons/fi';
import moment from 'moment';
import Carregamento from '../../../components/Carregamento';

function BloqueioAcesso() {
    /* =============================== */
    /* ====VARIÁVEIS================== */
    /* =============================== */
    const usuario = useAuth();      /* Retorna todos os dado do usuário logado */
    const { signOut } = useAuth();
    const [listaDeEvolucoes, setListaDeEvolucoes] = useState([]);
    const [lista, setLista] = useState([]);
    const [loading, setloading] = useState(false);

    const coletaBloqueados = async function () {
        setloading(true);
        await api.get('api/terapeuta/get/evolucoes/pendentes/' + usuario.user.terapeuta[0].id + '?tipo=vencida').then((response) => {
            setListaDeEvolucoes(response.data);
        });
        setloading(false);
    };
    useEffect(() => {
        coletaBloqueados();
    }, []);
    useEffect(() => {
        if (listaDeEvolucoes.length > 0) {
            let cria_lista = listaDeEvolucoes.map((item, index) => {
                if (item) {
                    const date1 = new Date();
                    const date2 = new Date(moment(item.date_create).format('YYYY/MM/DD'));
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                    const de = item.data_atendimento.split(' ')[1].split(':');
                    const ate = item.date_final.split(' ')[1].split(':');
                    return (
                        <tr key={'tr-' + index} className='border-top-0_5'>
                            <td key={'td-' + index + 'n'}>
                                {item.nome}
                            </td>
                            <td key={'td-' + index + 'di'}>
                                {moment(item.date_create).format('DD/MM/YYYY') + ' ' + de[0] + ':' + de[1]}
                            </td>
                            <td key={'td-' + index + 'df'}>
                                {moment(item.date_create).format('DD/MM/YYYY') + ' ' + ate[0] + ':' + ate[1]}
                            </td>
                            <td key={'td-' + index + 'diff'}>
                                {diffDays + ' dias'}
                            </td>
                        </tr>
                    );
                }
            });
            setLista(cria_lista);
        }
    }, [listaDeEvolucoes]);
    return (
        <>
            {loading ? <Carregamento></Carregamento> : ''}
            <div className='row'>
                <div className="col-11"> </div>
                <div className="col-1 text-right">
                    <button className='btn-outline p-4 pop-light-blue font-black' onClick={signOut}> <FiPower className='font-black' size={20} /> </button>
                </div>
            </div>
            <div className='div-center'>
                <div className='col-6 m-auto pt-2'>
                    <br />
                    <br />
                    <div className='div-titulo d-grid t-center'>
                        <svg className='m-auto font-vermelho' xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                        </svg>
                        <label className='font-vermelho'>Acesso Bloqueado</label>
                    </div>
                    <div className=''>
                        <p>Parece que existem atendimentos com evoluções não feitas, converse com a coordenação para liberar o seu acesso. E lembre-se de fazer as suas evoluções, no prazo limite de 24hrs após o horário final do atendimento.</p>
                        <table className='table-folha-horarios'>
                            <thead>
                                <tr>
                                    <th>Nome do Paciente</th>
                                    <th>Data Inicial do Atendimento</th>
                                    <th>Data Final do Atendimento</th>
                                    <th>Vencido á</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lista}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BloqueioAcesso;