import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';
const DadosBasicosModal = forwardRef(({ id = 'agendaModal', paciente_id }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Dados Básicos do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [paciente, setPaciente] = useState([]);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '70vh',
            width: '40vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            padding: '0px'
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaPaciente(id);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaPaciente = async (id) => {
        setloading(true);
        setPaciente(null);
        await api.get(`/api/paciente/${id}/dados-basicos`).then(({ data }) => {
            setPaciente(data);
        });
        setloading(false);
    };
    //#endregion
    useEffect(() => {/* ONLOAD */

    }, []);
    return (
        <>
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className="p-4">
                    {loading
                        ? <ClipLoader className='m-auto align-center justify-center' />
                        : (paciente !== undefined && paciente !== null)
                            ?
                            <ul className='font-20px p-4'>
                                <li><b>Status:</b> {paciente?.status}</li>
                                <li><b>Nome:</b> {paciente?.nome}</li>
                                <li><b>Nome curto:</b> {paciente?.nome_curto}</li>
                                <li><b>Identificador:</b> {paciente?.identificador}</li>
                                <li><b>Data de nascimento:</b> {paciente?.nascimento !== null && moment.utc(paciente?.nascimento).format('DD/MM/YYYY')}</li>
                                <li><b>Data de entrada:</b> {paciente?.data_entrada !== null ? moment.utc(paciente?.data_entrada).format('DD/MM/YYYY') : 'Sem data de entrada'}</li>
                                <li><b>Uso de imagem (sim ou não):</b> </li>
                                <li><b>Comorbidades:</b> {paciente?.comorbidades}</li>
                                <li><b>Plano, terapias e especialidades liberadas:</b>
                                    <ul>
                                        {paciente?.terapias?.split('|')?.filter(terapia => terapia.trim() !== '')?.map((terapia, index) => (
                                            <li key={index}>{terapia.trim()}</li>
                                        ))}
                                    </ul>
                                </li>
                                {
                                    (paciente?.responsavel !== undefined && paciente?.responsavel !== null && paciente?.responsavel.length > 0)
                                        ? paciente?.responsavel?.map((e) => (
                                            <>
                                                <li><b>Nome do responsável:</b> {e.nome}</li>
                                                <li><b>Parentesco:</b> {e.parentesco}</li>
                                            </>
                                        ))
                                        : <li style={{ listStyle: 'none' }}><i>Sem responsável cadastrado</i></li>
                                }
                                <li><b>Medicamentos:</b> {paciente?.medicamentos}</li>
                                <li><b>Alergias:</b> {paciente?.alergias}</li>
                                <li><b>Médicos:</b> {paciente?.medicos}</li>
                            </ul>
                            : 'Sem paciente selecionado.'
                    }
                </div>
            </Modal>
        </>
    );
});
export default DadosBasicosModal;