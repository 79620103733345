import api from './api';

class TerapeutaCertificadoService {
    static index = () => {
        return api.get('/api/terapeuta-certificacao-adicional');
    };
    static store = (obj) => {
        /* exemplo
        {"terapeuta_id":1,"especialidade_id":1,"data":"2013-08-22","arquivo":"perspiciatis"}
        */

        return api.post('/api/terapeuta-certificacao-adicional', obj);
    };
    static show = (id) => {
        return api.get(`/api/terapeuta-certificacao-adicional/${id}`);
    };
    static update = (obj, id) => {
        /* exemplo
        {"terapeuta_id":1,"especialidade_id":1,"data":"1986-07-03","arquivo":"similique"}
        */

        return api.put(`/api/terapeuta-certificacao-adicional/${id}`, obj);
    };
    static destroy = (id) => {
        return api.delete(`/api/terapeuta-certificacao-adicional/${id}?id=${id}`);
    };
}

export default TerapeutaCertificadoService;