import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const ContainerAtalhos = {
    height: 'auto', textDecoration: 'none', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '100%', 
    boxShadow: '0 0 10px 0px silver',
    backgroundColor: 'white', 
    borderRadius: 20, 
    margin: 10
};

export const CardButtonStyle1 = {
    height: 90,
   
    backgroundColor: '#6A3BAF', 
    cursor: 'pointer', 
    display: 'flex-grid', 
    alignItems: 'center',
    justifyContent: 'center', 
    borderRadius: 10,
    boxShadow: '0 0 10px 0px silver',
    color: 'white', 
    margin: 5, 
    width: 120, 
    padding: 10,
    display: 'flex', 
    flexDirection: 'column', 
    textAlign: 'center', 
};

export const CardButtonStyle2 = {
    height:'95px', 
    backgroundColor: '#6A3BAF', 
    cursor: 'pointer', 
    display: 'flex-grid', 
    alignItems: 'center',
    justifyContent: 'center', 
    borderRadius: 10, 
    color: 'white', 
    margin: 5, 
    width: '25%', 
    padding: 20
};

export const SubMenuConfigStyle = {
    display: 'flex', 
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: 'center', 
    height: 58,
    padding: '0px 20px', 
    marginBottom: '4rem',
    boxShadow: '0 0 10px 0px silver',
};

export const SubMenuConfigHeader = {
   
    marginRight: 20, 
    color: '#7340BD', 
    fontWeight: 'bold', 
    cursor: 'pointer' 
};