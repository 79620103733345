import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global';
import moment from 'moment';

import AppProvider from './context';
import { useSnackbar } from './context/snackbar';
import Snackbar from './components/Snackbar';
import RoutesApp from './Routes';
import { useAuth } from './context/auth';
import InactivityTimeout from 'components/InactivityTimeout/InactivityTimeout';

// Verifica se o usuário está logado e redireciona se necessário
const checkIsLoggedIn = () => {
	const isLoggedIn = sessionStorage.getItem('@Neuro:access_token');
	if (!isLoggedIn && window.location.pathname !== '/' && window.location.pathname !== '/offline-testes') {
		window.location.href = '/';
	}
};

const App = () => {
	const snackbar = useSnackbar();
	const { signOut, isAuthenticated } = useAuth();

	const logOut = () => {
		sessionStorage.removeItem('@Neuro:user');
		sessionStorage.removeItem('@Neuro:access_token');
		sessionStorage.removeItem('@Neuro:docs_pending');
		sessionStorage.removeItem('@Neuro:refresh_token');
		window.location.href = '/';
	};
	const handleTimeout = async () => {
		logOut();
	};

	useEffect(() => {
		// Verifica se o usuário está logado quando o componente é montado
		checkIsLoggedIn();

		// Configura o moment para UTC
		moment().utc();

		// Função para lidar com o unload da página
		const handleBeforeUnload = () => {
			if (isAuthenticated()) {
				logOut();
			}
		};

		// Verifica se estamos no iPhone
		const isIPhone = /iPhone/i.test(navigator.userAgent);
		if (!isIPhone) {
			window.addEventListener('beforeunload', handleBeforeUnload);
		}

		// Cleanup do event listener
		return () => {
			if (!isIPhone) {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			}
		};
	}, [isAuthenticated, signOut]);

	return (
		<Router>
			<InactivityTimeout timeoutDuration={1200000} onTimeout={handleTimeout} />
			<AppProvider>
				<RoutesApp />
				<GlobalStyle />
				{snackbar.isDisplayed && <Snackbar duration={snackbar.duration} />}
			</AppProvider>
		</Router>
	);
};

export default App;
