import React, { useState, useRef } from 'react';
import { Form } from '@unform/web';
import {  Container, Delete, InputUpload, LabelUpload, ModalDiplomaStyle, Upload, UploadIcon } from '../styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import Swal from 'sweetalert2';
import Input from '../../../../../components/Input';

const CertificadosTerapeuta = ({ terapeuta, callBack, newCertificado, onClose }, ref) => {
    let subtitle;

    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [docCertificado, setDocCertificado] = useState(null);
    const [path, setPath] = useState([]);


    const formRef = useRef(null);
    const id = 'modal-certificados-terapeuta';

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleUploadDiploma = (e) => {
        if (docCertificado === null) {
            var arquivo = document.getElementById('upload-certificado');
            var name = e.target.files[0].name;
            const file = e.target.files[0];
            var size = e.target.files[0].size;
            var extPermitidas = ['jpg', 'png', 'jpeg', 'pdf',];
            if (extPermitidas.find((ext) => { return name.split('.').pop() === ext; }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg, pgn,)', '', 'warning');
                arquivo.value = '';
            } else {
                if (size < 40000000) { //40MB         
                    setDocCertificado(file);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = '';
                }
                e.preventDefault();
            }
        }
        else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'error');
        }
    };

    const handleDeleteUpload = () => {
        setDocCertificado(null);
        path.splice(-1);
        setLoadingDadosIniciais(true);
    };

    async function handleSubmit(data) {
        if (data.nome === null || data.date === null || docCertificado === 0) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            const dados = {
                nome: data.nome,
                file: docCertificado,
                data: data.date,
            };
            if (terapeuta && terapeuta.id) {
                dados.terapeuta_id = terapeuta.id;
            };
            newCertificado(dados, data.nome);
            onClose();
            callBack();
        };
    };

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    };

    return (
        <Container className='w-100per'>
            <div className='d-flex align-items.center justify-content-between'>
                <label></label>
                <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}> Adicionar Nova Certificação Adicional </label>
                <label className='btn-fecha-modal' onClick={e => onClose()}> x </label>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit} style={{ height: '100%', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <div style={{ flexDirection: 'column', width: '50%', height: 'auto' }}>
                        <Input title="Nome:" name="nome" style={{ width: '50%' }} />
                    </div>
                    <div style={{ marginLeft: 5, flexDirection: 'column', width: '20%', height: 'auto' }}>
                        <Input containerStyle={ModalDiplomaStyle} title="Data:" name="date" type="date" style={{ width: '20%' }} />
                    </div>
                    <div style={{ marginTop: 5, flexDirection: 'column', marginLeft: 5, }}>
                        <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
                    </div>
                    <div style={{ flexDirection: 'column' }}>
                        <label htmlFor="upload-certificado" style={LabelUpload}>Anexar Certificado
                            <input type="file" id="upload-certificado" style={InputUpload} onChange={e => handleUploadDiploma(e)} />
                        </label>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {path.map((item) => {
                        return <span style={Upload}>{item}
                            <div style={{}}>
                                <span style={Delete} onClick={e => handleDeleteUpload()}> x </span>
                            </div>
                        </span>;
                    })}
                </div>
                <div className='d-flex align-items.center justify-content-evenly mt-3 mb-3'>
                    <button onClick={e => onClose()} className="btn-padrao"> Voltar </button>
                    <button onClick={e => functionThatSubmitsForm()} className="btn-padrao"> Adicionar </button>
                </div>
            </Form>
        </Container>
    );
};

export default CertificadosTerapeuta;
